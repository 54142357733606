import React, { useState, useEffect, useRef, useCallback } from "react";
import { Card } from "react-bootstrap";
import "../assets/styles/Home.css";
const Home = ({ content }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const totalSlides = 3;
  const sliderRef = useRef(null);
  const startX = useRef(0);
  const startY = useRef(0);
  const isDragging = useRef(false);
  const currentTranslateX = useRef(0);
  useEffect(() => {
    const handleResize = debounce(() => setWindowWidth(window.innerWidth), 200);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };
  const sliderStyle = {
    transform: `translate3d(-${currentIndex * windowWidth}px, 0, 0)`,
    transition: "transform 500ms ease",
    width: `${windowWidth * totalSlides}px`,
  };
  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex < totalSlides - 1 ? prevIndex + 1 : 0));
  }, [totalSlides]);

  const handlePrev = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : totalSlides - 1));
  }, [totalSlides]);

  const handleDotClick = useCallback((index) => {
    setCurrentIndex(index);
    if (sliderRef.current) {
      sliderRef.current.scrollTo({ left: index * sliderRef.current.offsetWidth, behavior: "smooth" });
    }
  }, []);
  useEffect(() => {
    const interval = setInterval(handleNext, 30000);
    return () => clearInterval(interval);
  }, [handleNext, handlePrev]);

  const goTo = (Link) => {
    window.location.href = Link;
  };

  useEffect(() => {
    const moveDrag = (clientX, clientY) => {
      if (!isDragging.current) return;
      const moveX = clientX - startX.current; // Calculate the distance moved
      const moveY = clientY - startY.current;
      if (Math.abs(moveY) > Math.abs(moveX)) {
        isDragging.current = false;
      }
      if (isDragging.current) {
        const newTranslateX = currentTranslateX.current + moveX; // Update translation
        sliderRef.current.style.transform = `translate3d(${newTranslateX}px, 0, 0)`;
      } // Apply immediate translation
    };
    const startDrag = (clientX, clientY, event) => {
      if (!event.touches) {
        event.preventDefault();
      }
      startX.current = clientX; // Save the initial position
      startY.current = clientY;
      currentTranslateX.current = -currentIndex * windowWidth; // Save current translation
      isDragging.current = true; // Set dragging state
    };
    const endDrag = () => {
      if (!isDragging.current) return;
      isDragging.current = false; // Reset dragging state
      const moveDistance = parseInt(sliderRef.current.style.transform.split("(")[1]) + currentIndex * windowWidth;
      if (moveDistance > windowWidth / 4) {
        handlePrev(); // If dragged right enough, go to previous slide
      } else if (moveDistance < -windowWidth / 4) {
        handleNext(); // If dragged left enough, go to next slide
      }
      sliderRef.current.style.transform = `translate3d(-${currentIndex * windowWidth}px, 0, 0)`;
    };
    const slider = sliderRef.current;
    const handleTouchStart = (e) => startDrag(e.touches[0].clientX, e.touches[0].clientY, e);
    const handleMouseDown = (e) => startDrag(e.clientX, e.clientX, e);
    const handleTouchMove = (e) => moveDrag(e.touches[0].clientX, e.touches[0].clientY);
    const handleMouseMove = (e) => moveDrag(e.clientX, e.clientX);
    const handleTouchEnd = endDrag;
    const handleMouseUp = endDrag;
    if (slider) {
      slider.addEventListener("touchstart", handleTouchStart);
      slider.addEventListener("touchmove", handleTouchMove);
      slider.addEventListener("touchend", handleTouchEnd);
      slider.addEventListener("mousedown", handleMouseDown);
      slider.addEventListener("mousemove", handleMouseMove);
      slider.addEventListener("mouseup", handleMouseUp);
      slider.addEventListener("mouseleave", handleMouseUp);
      return () => {
        slider.removeEventListener("touchstart", handleTouchStart);
        slider.removeEventListener("touchmove", handleTouchMove);
        slider.removeEventListener("touchend", handleTouchEnd);
        slider.removeEventListener("mousedown", handleMouseDown);
        slider.removeEventListener("mousemove", handleMouseMove);
        slider.removeEventListener("mouseup", handleMouseUp);
        slider.removeEventListener("mouseleave", handleMouseUp);
      };
    }
  }, [currentIndex, handlePrev, windowWidth, handleNext]);
  return (
    <div className="container-fluid">
      <div className="marquee">
        <div role="button" className="marquee-prev" onClick={handlePrev}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
          </svg>
        </div>
        <div className="marquee-slider" role="button" style={sliderStyle} ref={sliderRef}>
          <div className="marquee-wrapper" style={{ width: `${windowWidth}px` }}>
            <img src="assets/marquee/Sky1.jpg" alt="Sky  1" />
            <div className="marquee-text">Coding+ Its Coding plus me.</div>
          </div>
          <div className="marquee-wrapper" style={{ width: `${windowWidth}px` }}>
            <img src="assets/marquee/Sky2.jpg" alt="Sky  2" />
            <div className="marquee-text">Shot On iPhone 13 Pro</div>
          </div>
          <div className="marquee-wrapper" style={{ width: `${windowWidth}px` }}>
            <img src="assets/marquee/Sky3.jpg" alt="Sky  3" />
            <div className="marquee-text">Best place in the universe</div>
          </div>
        </div>
        <div role="button" className="marquee-next" onClick={handleNext}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
          </svg>
        </div>
        <ul className="marquee-dots">
          {[...Array(totalSlides)].map((_, index) => (
            <li key={index}>
              <button onClick={() => handleDotClick(index)} className={`${index === currentIndex ? "slick-active" : ""}`}>
                {index + 1}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="container">
        <h4 className="header-text">Pages</h4>
        <hr></hr>
        <div className=" d-flex flex-wrap justify-content-center">
          <div className="list  mx-1" style={{ cursor: "pointer" }} onClick={() => goTo("/Articles")}>
            <Card>
              <Card.Body>
                <Card.Title>Articles</Card.Title>
                <div className="card-img-wrapper">
                  <Card.Img src="assets/RC1.png" alt="Roy claudio website background." />
                </div>
                <Card.Text>A collection of articles covering topics I want to write about, from new science to appliance repair.</Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div key="spotify" className="list  mx-1" style={{ cursor: "pointer" }} onClick={() => goTo("/Services")}>
            <Card>
              <Card.Body>
                <Card.Title>Spotify</Card.Title>
                <div className="card-img-wrapper">
                  <Card.Img src="assets/pushpin.svg" alt="Roy claudio website background." />
                </div>
                <Card.Text>A Spotify tool that uses the artist’s genres to filter your songs, and Spotify's song traits to sort them.</Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
