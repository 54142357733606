import React from "react";

const Threads = () => {
    
    return (
        <span></span>
    );
};

export default Threads;
