export const generateRandomString = (length) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";
  for (let i = 0; i < length; i++) {
    randomString += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return randomString;
};
export const scope = [
  "ugc-image-upload",
  "user-read-playback-state",
  "user-modify-playback-state",
  "user-read-currently-playing",
  "app-remote-control",
  "streaming",
  "playlist-read-private",
  "playlist-read-collaborative",
  "playlist-modify-private",
  "playlist-modify-public",
  "user-follow-modify",
  "user-follow-read",
  "user-read-playback-position",
  "user-top-read",
  "user-read-recently-played",
  "user-library-modify",
  "user-library-read",
  "user-read-email",
  "user-read-private",
].join(" ");
export const Spotifylogin = () => {
  const scope = [
    "ugc-image-upload",
    "user-read-playback-state",
    "user-modify-playback-state",
    "user-read-currently-playing",
    "app-remote-control",
    "streaming",
    "playlist-read-private",
    "playlist-read-collaborative",
    "playlist-modify-private",
    "playlist-modify-public",
    "user-follow-modify",
    "user-follow-read",
    "user-read-playback-position",
    "user-top-read",
    "user-read-recently-played",
    "user-library-modify",
    "user-library-read",
    "user-read-email",
    "user-read-private",
  ].join(" ");
  const authUrl = `https://accounts.spotify.com/authorize?response_type=code&client_id=0a9995dd76d44757a722335e07ca7844&scope=${scope}&redirect_uri=https://royclaudio.com/Services&state=${generateRandomString(16)}`;
  window.location.replace(authUrl);
};
export const SpotifyDataHolder = [
  {
    id: "2dAtxRO4t3kYM387NCpPFL",
    name: "Never Come Down",
    artists: "Merkules, Millyz",
    duration_ms: 216025,
    popularity: 32,
    preview_url: "https://p.scdn.co/mp3-preview/057487cf7a211c5f06863ed68b9ac0c45386b660?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0201334b51420cd3ca18c154fe",
    danceability: 0.481,
    energy: 0.577,
    valence: 0.193,
    added_at: "2024-10-03T00:29:11Z",
    link: "https://open.spotify.com/track/2dAtxRO4t3kYM387NCpPFL",
    userID: "royclaud",
    genres: "bc underground hip hop,canadian hip hop,boston hip hop",
  },
  {
    id: "0TZsy6XheL9cSxuX7yYqCO",
    name: "Which Side are You On?",
    artists: "Pete Seeger",
    duration_ms: 129800,
    popularity: 38,
    preview_url: "https://p.scdn.co/mp3-preview/077f1af61f3e97dcefbc2917beba7d8641e5c599?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026528dcc259ef64aea8630ae3",
    danceability: 0.496,
    energy: 0.245,
    valence: 0.592,
    added_at: "2024-10-02T13:26:22Z",
    link: "https://open.spotify.com/track/0TZsy6XheL9cSxuX7yYqCO",
    userID: "royclaud",
    genres: "american folk revival,banjo,folk,old-time,singer-songwriter,traditional folk",
  },
  {
    id: "4rwpZEcnalkuhPyGkEdhu0",
    name: "Scary Monsters and Nice Sprites",
    artists: "Skrillex",
    duration_ms: 243353,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/9df66cfc3a6c32d051b286f47aba4890bbf5b297?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0281cfaf3b1359f7b579ea05e5",
    danceability: 0.523,
    energy: 0.944,
    valence: 0.324,
    added_at: "2024-10-01T14:17:24Z",
    link: "https://open.spotify.com/track/4rwpZEcnalkuhPyGkEdhu0",
    userID: "royclaud",
    genres: "brostep,complextro,edm,electro,pop dance",
  },
  {
    id: "1ZdhOMWyFR8Iv9eylMGYg2",
    name: "Mount Everest",
    artists: "Labrinth",
    duration_ms: 157497,
    popularity: 79,
    preview_url: "https://p.scdn.co/mp3-preview/002d61108f8dadbe8160849a01976f5e15f85985?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e4c03429788f0aff263a5fc6",
    danceability: 0.494,
    energy: 0.428,
    valence: 0.44,
    added_at: "2024-09-30T22:01:48Z",
    link: "https://open.spotify.com/track/1ZdhOMWyFR8Iv9eylMGYg2",
    userID: "royclaud",
    genres: "indie poptimism,pop",
  },
  {
    id: "1VutwUjHPwEELxHkwdhMkz",
    name: "Clandestina (feat. Emma Peters) - JVSTIN Remix",
    artists: "FILV, Edmofo, Emma Peters, Jvstin",
    duration_ms: 145233,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/94e0fac0b074d7358ed4486ed5538a5eeb6c5c54?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025f69558590bf91cd0eb860d7",
    danceability: 0.788,
    energy: 0.402,
    valence: 0.805,
    added_at: "2024-09-28T18:02:46Z",
    link: "https://open.spotify.com/track/1VutwUjHPwEELxHkwdhMkz",
    userID: "royclaud",
    genres: "russian dance,french pop",
  },
  {
    id: "0oay0G48d22WMU4hbFCEnH",
    name: "Wake Me Up",
    artists: "Anya Nami",
    duration_ms: 129817,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/ef8e4c81ac16f98ddec973ab4ca278f599c4feed?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a64354cca86bce5cd46bd482",
    danceability: 0.687,
    energy: 0.733,
    valence: 0.322,
    added_at: "2024-09-16T01:07:56Z",
    link: "https://open.spotify.com/track/0oay0G48d22WMU4hbFCEnH",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "5CPiiKRbI2UKPHKaSKhJg4",
    name: "I need",
    artists: "Lithe",
    duration_ms: 183750,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/624f067698c1dbc9d63c265c64c7d474037cc780?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020f56d55895b4f8909ce85212",
    danceability: 0.856,
    energy: 0.341,
    valence: 0.447,
    added_at: "2024-09-14T02:41:37Z",
    link: "https://open.spotify.com/track/5CPiiKRbI2UKPHKaSKhJg4",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "0oXLSuno5adlHcPzlzs8l6",
    name: "Duele El Amor",
    artists: "Aleks Syntek, Ana Torroja",
    duration_ms: 275373,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/3981417602d353524a48b664623cb1c10d5fc1f0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022ff95cfe681839038a35b115",
    danceability: 0.674,
    energy: 0.774,
    valence: 0.702,
    added_at: "2024-09-10T15:48:30Z",
    link: "https://open.spotify.com/track/0oXLSuno5adlHcPzlzs8l6",
    userID: "royclaud",
    genres: "latin arena pop,latin pop,mexican pop,latin arena pop,latin pop,mexican pop",
  },
  {
    id: "1uKjQoh8JZj9ryuYRhpd7E",
    name: "Ahora Te Puedes Marchar",
    artists: "Luis Miguel",
    duration_ms: 191973,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/ae32c231fe396675204a9be10ef413497bf208d7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0220e3b8f2b044117fe02790e1",
    danceability: 0.78,
    energy: 0.877,
    valence: 0.909,
    added_at: "2024-09-10T13:54:04Z",
    link: "https://open.spotify.com/track/1uKjQoh8JZj9ryuYRhpd7E",
    userID: "royclaud",
    genres: "bolero,latin pop",
  },
  {
    id: "39MK3d3fonIP8Mz9oHCTBB",
    name: "Annihilate (Spider-Man: Across the Spider-Verse) (Metro Boomin & Swae Lee, Lil Wayne, Offset)",
    artists: "Metro Boomin, Swae Lee, Lil Wayne, Offset",
    duration_ms: 231746,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/55f79595d431d76086fdbe4398935a66a758c1ca?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026ed9aef791159496b286179f",
    danceability: 0.591,
    energy: 0.5,
    valence: 0.201,
    added_at: "2024-09-07T18:33:26Z",
    link: "https://open.spotify.com/track/39MK3d3fonIP8Mz9oHCTBB",
    userID: "royclaud",
    genres: "rap,melodic rap,rap,trap,hip hop,new orleans rap,pop rap,rap,trap,atl hip hop,hip hop,pop rap,rap,trap",
  },
  {
    id: "4kjZGzoruRTzapDQA6H3Hj",
    name: "Moonlight Sonata 3rd Movement",
    artists: "Ludwig van Beethoven, Marioverehrer",
    duration_ms: 412675,
    popularity: 48,
    preview_url: "https://p.scdn.co/mp3-preview/55b5afa7db92c7d2cbffe5106004a39b553cf37d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025f74c5653dc98cfef62caed6",
    danceability: 0.332,
    energy: 0.0857,
    valence: 0.0855,
    added_at: "2024-09-03T00:23:13Z",
    link: "https://open.spotify.com/track/4kjZGzoruRTzapDQA6H3Hj",
    userID: "royclaud",
    genres: "classical,classical era,early romantic era,german romanticism,pixel",
  },
  {
    id: "1aRtduEHwvk2AMR87ji9Tg",
    name: "Intro",
    artists: "ODESZA",
    duration_ms: 63972,
    popularity: 38,
    preview_url: "https://p.scdn.co/mp3-preview/0890a6265476270fb754f288c4575781fa6c6470?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0245951a69fe39a6e163122eab",
    danceability: 0.555,
    energy: 0.0546,
    valence: 0.644,
    added_at: "2024-09-02T18:28:48Z",
    link: "https://open.spotify.com/track/1aRtduEHwvk2AMR87ji9Tg",
    userID: "royclaud",
    genres: "chillwave,edm,indietronica",
  },
  {
    id: "6futwkldbJdgkrYtAINhn9",
    name: "Like We Wrote",
    artists: "Lithe",
    duration_ms: 118709,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/a4b47739ef3ce04531342e90686ee135da8438d6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027a9ad813fd24d4f444d519a2",
    danceability: 0.77,
    energy: 0.156,
    valence: 0.34,
    added_at: "2024-08-28T22:27:15Z",
    link: "https://open.spotify.com/track/6futwkldbJdgkrYtAINhn9",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "3EWaU5exMayFzQMkIfTX7w",
    name: "either on or off the drugs",
    artists: "JPEGMAFIA",
    duration_ms: 140593,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/f6d34e008defcb824f3b773f8b6764a398fa6e5d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a3314be7ae643fefa32fbe08",
    danceability: 0.505,
    energy: 0.605,
    valence: 0.631,
    added_at: "2024-08-28T22:23:59Z",
    link: "https://open.spotify.com/track/3EWaU5exMayFzQMkIfTX7w",
    userID: "royclaud",
    genres: "alternative hip hop,experimental hip hop,industrial hip hop",
  },
  {
    id: "1j2M5ekufbxpzGYzuorgKt",
    name: "Moonlight Sonata",
    artists: "Ludwig van Beethoven",
    duration_ms: 447186,
    popularity: 52,
    preview_url: "https://p.scdn.co/mp3-preview/43786f18d285e2df697e62ba206a3d117407c6df?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0239403a909cadae42157cc6f6",
    danceability: 0.544,
    energy: 0.12,
    valence: 0.193,
    added_at: "2024-08-24T19:10:11Z",
    link: "https://open.spotify.com/track/1j2M5ekufbxpzGYzuorgKt",
    userID: "royclaud",
    genres: "classical,classical era,early romantic era,german romanticism",
  },
  {
    id: "2HbKqm4o0w5wEeEFXm2sD4",
    name: "Money Trees",
    artists: "Kendrick Lamar, Jay Rock",
    duration_ms: 386906,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/918a048521fe4c233f781943bd1194c20a12505a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d28d2ebdedb220e479743797",
    danceability: 0.716,
    energy: 0.531,
    valence: 0.344,
    added_at: "2024-08-24T04:48:23Z",
    link: "https://open.spotify.com/track/2HbKqm4o0w5wEeEFXm2sD4",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap,conscious hip hop,hip hop,rap,southern hip hop,trap",
  },
  {
    id: "4b7vk8SRcYgnxpk0JOIS7r",
    name: "Drugs You Should Try It",
    artists: "Travis Scott",
    duration_ms: 208487,
    popularity: 79,
    preview_url: "https://p.scdn.co/mp3-preview/87e34f06982367effdf9ad72f5d925652a8ed9a5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020fc93fe41791c5aa51ae9645",
    danceability: 0.593,
    energy: 0.559,
    valence: 0.0729,
    added_at: "2024-08-23T22:22:09Z",
    link: "https://open.spotify.com/track/4b7vk8SRcYgnxpk0JOIS7r",
    userID: "royclaud",
    genres: "rap,slap house",
  },
  {
    id: "47MhyGA0XUtF9lZJtny3Pd",
    name: "Roses",
    artists: "SAINt JHN",
    duration_ms: 173610,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/c0a56c42c84c8802e94cc7fcb7ae0a90193a5da3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022d71f631f901f9324541ae86",
    danceability: 0.782,
    energy: 0.5,
    valence: 0.28,
    added_at: "2024-08-11T23:30:01Z",
    link: "https://open.spotify.com/track/47MhyGA0XUtF9lZJtny3Pd",
    userID: "royclaud",
    genres: "melodic rap,slap house",
  },
  {
    id: "67mC1sqidIwycByzWmT96o",
    name: "Hasta Que Amanezca",
    artists: "Joan Sebastian",
    duration_ms: 180906,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/18d580f2d01dd8910d02c370f18cf14af17d2b05?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b952c132e116e3bb1d536e5e",
    danceability: 0.477,
    energy: 0.458,
    valence: 0.895,
    added_at: "2024-08-08T13:42:01Z",
    link: "https://open.spotify.com/track/67mC1sqidIwycByzWmT96o",
    userID: "royclaud",
    genres: "banda,grupera,musica mexicana,norteno,ranchera",
  },
  {
    id: "7HW5tUjaKxBUamSi7nnzPt",
    name: "Eso Y Más",
    artists: "Joan Sebastian",
    duration_ms: 159172,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/2ab7c6c66a071c7ded9a628e5e399227d01cf964?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021d88f9d9a2b6b85b290ca24e",
    danceability: 0.585,
    energy: 0.132,
    valence: 0.454,
    added_at: "2024-08-08T13:38:16Z",
    link: "https://open.spotify.com/track/7HW5tUjaKxBUamSi7nnzPt",
    userID: "royclaud",
    genres: "banda,grupera,musica mexicana,norteno,ranchera",
  },
  {
    id: "0PVgMC8LNX6igc9jaVtxQs",
    name: "Camino al Cielo",
    artists: "Vicente Fernández",
    duration_ms: 211666,
    popularity: 52,
    preview_url: "https://p.scdn.co/mp3-preview/a241f22c1c3640c13ef579778b3d7d6821a542d7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0232fd65055829f5f38bab9ec3",
    danceability: 0.481,
    energy: 0.387,
    valence: 0.626,
    added_at: "2024-08-06T17:48:57Z",
    link: "https://open.spotify.com/track/0PVgMC8LNX6igc9jaVtxQs",
    userID: "royclaud",
    genres: "mariachi,ranchera",
  },
  {
    id: "5HzT7FpMsxO23zG5oIkzUz",
    name: "Chasing the Present",
    artists: "Snorri Hallgrímsson",
    duration_ms: 182836,
    popularity: 40,
    preview_url: "https://p.scdn.co/mp3-preview/d0ac81e9cc48148fbe2c8221450149ec17a5d6a9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ab37b94e5d241fabf880e474",
    danceability: 0.177,
    energy: 0.0634,
    valence: 0.0393,
    added_at: "2024-08-05T22:36:50Z",
    link: "https://open.spotify.com/track/5HzT7FpMsxO23zG5oIkzUz",
    userID: "royclaud",
    genres: "compositional ambient,icelandic classical,neo-classical",
  },
  {
    id: "6nYJWAPKIJfXIKtiYc4P4O",
    name: "Big Jet Plane",
    artists: "Restricted",
    duration_ms: 150000,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/4b3be454e9f7c0ebbcea20d2a45e34b668d6c9c0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0205da2b226cda948badddbf2e",
    danceability: 0.701,
    energy: 0.477,
    valence: 0.174,
    added_at: "2024-08-01T23:50:03Z",
    link: "https://open.spotify.com/track/6nYJWAPKIJfXIKtiYc4P4O",
    userID: "royclaud",
    genres: "hypertechno",
  },
  {
    id: "48tI68zHn3r3uHmGxdkKDd",
    name: "Pullin Up",
    artists: "Soda",
    duration_ms: 115642,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/b8d3af02aecb96472791218bfeddf937801e9e08?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02744dc4a3997acced20d04cbb",
    danceability: 0.947,
    energy: 0.439,
    valence: 0.165,
    added_at: "2024-07-30T18:56:25Z",
    link: "https://open.spotify.com/track/48tI68zHn3r3uHmGxdkKDd",
    userID: "royclaud",
    genres: "g-house",
  },
  {
    id: "2vXOjiwfjobOqrezcuSBok",
    name: "34 Ghosts IV",
    artists: "Nine Inch Nails",
    duration_ms: 352467,
    popularity: 33,
    preview_url: "https://p.scdn.co/mp3-preview/8643509dac9d484f0334b6a2b4ea505ad0466dd7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02411b0a44c93304c49848fb15",
    danceability: 0.221,
    energy: 0.111,
    valence: 0.0657,
    added_at: "2024-07-30T17:53:09Z",
    link: "https://open.spotify.com/track/2vXOjiwfjobOqrezcuSBok",
    userID: "royclaud",
    genres: "alternative metal,alternative rock,cyberpunk,electronic rock,grunge,industrial,industrial metal,industrial rock,nu metal,post-grunge,rock",
  },
  {
    id: "1Rd5OVjqDAr5xC96MHt448",
    name: "Daydreaming",
    artists: "Radiohead",
    duration_ms: 384438,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/5ba28f0c38a4b8916d50915216553046cf9331d0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0245643f5cf119cbc9d2811c22",
    danceability: 0.214,
    energy: 0.263,
    valence: 0.113,
    added_at: "2024-07-30T17:51:23Z",
    link: "https://open.spotify.com/track/1Rd5OVjqDAr5xC96MHt448",
    userID: "royclaud",
    genres: "alternative rock,art rock,melancholia,oxford indie,permanent wave,rock",
  },
  {
    id: "1G89WGoZFROKfbm2e26YN8",
    name: "Focus Music",
    artists: "Alpha Brain Waves",
    duration_ms: 144168,
    popularity: 41,
    preview_url: "https://p.scdn.co/mp3-preview/561b6c81090f6effcc2e3e28f3269a357d0de959?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a0b489ec109f5191e3b7226e",
    danceability: 0.134,
    energy: 0.0087,
    valence: 0.0385,
    added_at: "2024-07-30T17:50:41Z",
    link: "https://open.spotify.com/track/1G89WGoZFROKfbm2e26YN8",
    userID: "royclaud",
    genres: "brain waves",
  },
  {
    id: "6kkwzB6hXLIONkEk9JciA6",
    name: "Weightless",
    artists: "Marconi Union",
    duration_ms: 480106,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/d4fe5a51fd242260552c8c98938c97cd05a2d522?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f399efa20097105e9db88560",
    danceability: 0.139,
    energy: 0.22,
    valence: 0.0789,
    added_at: "2024-07-30T17:49:59Z",
    link: "https://open.spotify.com/track/6kkwzB6hXLIONkEk9JciA6",
    userID: "royclaud",
    genres: "ambient",
  },
  {
    id: "33xLNtMvaTOQFE8qce9A4Z",
    name: "40 Hz Memory Recall - Binaural Beats",
    artists: "Miracle Tones, Binaural Beats MT",
    duration_ms: 126624,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/1fae4e9c4501187326fe00af641c5cce9ca421b8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e3e286773d577b80a78da7e4",
    danceability: 0.139,
    energy: 0.0404,
    valence: 0.21,
    added_at: "2024-07-30T17:33:56Z",
    link: "https://open.spotify.com/track/33xLNtMvaTOQFE8qce9A4Z",
    userID: "royclaud",
    genres: "healing hz,meditation,binaural",
  },
  {
    id: "1aHD6BlYi6rsJCetEWH9f4",
    name: "Volando",
    artists: "Lefty Sm",
    duration_ms: 218000,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/25e6f1027a7dddcb821085738a600bb7700fd0c7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028331f07480c48162b8b20a64",
    danceability: 0.918,
    energy: 0.649,
    valence: 0.476,
    added_at: "2024-07-30T16:02:42Z",
    link: "https://open.spotify.com/track/1aHD6BlYi6rsJCetEWH9f4",
    userID: "royclaud",
    genres: "mexican hip hop",
  },
  {
    id: "1YxgIliOknSsGt2LsbfNOV",
    name: "Quién No Llora",
    artists: "Lefty Sm",
    duration_ms: 236571,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/b4dd17f9eb89a19f5f346b4c8588923751c874eb?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a4a450c7e73c32c313285318",
    danceability: 0.726,
    energy: 0.675,
    valence: 0.9,
    added_at: "2024-07-30T15:51:26Z",
    link: "https://open.spotify.com/track/1YxgIliOknSsGt2LsbfNOV",
    userID: "royclaud",
    genres: "mexican hip hop",
  },
  {
    id: "3l1l25xMdLYYrIqna134w7",
    name: "4:44 Am",
    artists: "Kochetkovv",
    duration_ms: 121913,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/ff0e1dba8a12cd4bf36ccbc7ea0e728457a71d99?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021379eda1e0c8b3f742688e75",
    danceability: 0.786,
    energy: 0.299,
    valence: 0.774,
    added_at: "2024-07-26T01:26:35Z",
    link: "https://open.spotify.com/track/3l1l25xMdLYYrIqna134w7",
    userID: "royclaud",
    genres: "lo-fi chill,sad lo-fi,slowed and reverb",
  },
  {
    id: "6ZwTqNc7Ln6Dhn5UKNZJHy",
    name: "Losing Interest - Slowed Version",
    artists: "boy in skirt",
    duration_ms: 116920,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/16210a9f18da127bcab660a2a93b199236d0acbd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d2c076bf78a091d494dd7f3a",
    danceability: 0.796,
    energy: 0.0617,
    valence: 0.166,
    added_at: "2024-07-26T01:26:33Z",
    link: "https://open.spotify.com/track/6ZwTqNc7Ln6Dhn5UKNZJHy",
    userID: "royclaud",
    genres: "slowed and reverb",
  },
  {
    id: "6PKxJG1HapLGNqaKoRaIq4",
    name: "Paralyzed",
    artists: "Lenny Kravitz",
    duration_ms: 268421,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/45f7a4a427347979cb0576900311e049ec149fe8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0262957e14d5aa6a2f7fb366ab",
    danceability: 0.3,
    energy: 0.735,
    valence: 0.392,
    added_at: "2024-07-20T19:35:37Z",
    link: "https://open.spotify.com/track/6PKxJG1HapLGNqaKoRaIq4",
    userID: "royclaud",
    genres: "permanent wave,rock",
  },
  {
    id: "7BY005dacJkbO6EPiOh2wb",
    name: "House Of The Rising Sun",
    artists: "The Animals",
    duration_ms: 271280,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/da246d5f609a6133eacfbbbd01efef7f85988511?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023c534611bd3658006378a2d7",
    danceability: 0.295,
    energy: 0.584,
    valence: 0.228,
    added_at: "2024-07-17T14:16:25Z",
    link: "https://open.spotify.com/track/7BY005dacJkbO6EPiOh2wb",
    userID: "royclaud",
    genres: "album rock,british invasion,classic rock,hard rock,protopunk,psychedelic rock,rock",
  },
  {
    id: "7ccI9cStQbQdystvc6TvxD",
    name: "We Are The Champions - Remastered 2011",
    artists: "Queen",
    duration_ms: 179200,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/53f9595b73edaa7af3eac56494b0cad5221e8563?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0293c65b02f4a72cd6eccf446d",
    danceability: 0.268,
    energy: 0.459,
    valence: 0.172,
    added_at: "2024-07-17T14:16:22Z",
    link: "https://open.spotify.com/track/7ccI9cStQbQdystvc6TvxD",
    userID: "royclaud",
    genres: "classic rock,glam rock,rock",
  },
  {
    id: "5GjPQ0eI7AgmOnADn1EO6Q",
    name: "Eleanor Rigby - Remastered 2009",
    artists: "The Beatles",
    duration_ms: 126533,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/7ef39591e786ae25bdf67bb92abe2460ade35fbf?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0228b8b9b46428896e6491e97a",
    danceability: 0.581,
    energy: 0.28,
    valence: 0.813,
    added_at: "2024-07-17T14:16:03Z",
    link: "https://open.spotify.com/track/5GjPQ0eI7AgmOnADn1EO6Q",
    userID: "royclaud",
    genres: "british invasion,classic rock,merseybeat,psychedelic rock,rock",
  },
  {
    id: "1JbUMtVkQuaAPdHOvncVTm",
    name: "Between the Bars",
    artists: "Elliott Smith",
    duration_ms: 141200,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/3a998de91763a9e1d78e112772ad48af68e3369d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026f81db7bea8dee2fa4be1913",
    danceability: 0.523,
    energy: 0.0774,
    valence: 0.354,
    added_at: "2024-07-17T14:15:53Z",
    link: "https://open.spotify.com/track/1JbUMtVkQuaAPdHOvncVTm",
    userID: "royclaud",
    genres: "alternative rock,indie rock,melancholia,singer-songwriter",
  },
  {
    id: "6uBlQPWGFk48YlHRPyCdQy",
    name: "People Ain't No Good",
    artists: "Nick Cave & The Bad Seeds",
    duration_ms: 339933,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/4cb7516f6cadee4b361627e0926dd97f9c9ae80f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a895c616f899f06429bc8b4f",
    danceability: 0.434,
    energy: 0.159,
    valence: 0.21,
    added_at: "2024-07-17T14:15:49Z",
    link: "https://open.spotify.com/track/6uBlQPWGFk48YlHRPyCdQy",
    userID: "royclaud",
    genres: "alternative rock,permanent wave,singer-songwriter",
  },
  {
    id: "51SzRJVxgEakZYd6WSsdGL",
    name: "Little Drop Of Poison",
    artists: "Tom Waits",
    duration_ms: 191600,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/21cf0b4a09f7f36ee43ecb37ba605a4a06cc58b1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a895c616f899f06429bc8b4f",
    danceability: 0.632,
    energy: 0.416,
    valence: 0.84,
    added_at: "2024-07-17T14:15:47Z",
    link: "https://open.spotify.com/track/51SzRJVxgEakZYd6WSsdGL",
    userID: "royclaud",
    genres: "folk,singer-songwriter",
  },
  {
    id: "5zjD95M2XMZ5LJCDsJekkT",
    name: "I Need Some Sleep",
    artists: "Eels",
    duration_ms: 148000,
    popularity: 50,
    preview_url: "https://p.scdn.co/mp3-preview/c4ba236b2443e1ce31a51a2e743ca36f560135cd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a895c616f899f06429bc8b4f",
    danceability: 0.663,
    energy: 0.34,
    valence: 0.192,
    added_at: "2024-07-17T14:15:40Z",
    link: "https://open.spotify.com/track/5zjD95M2XMZ5LJCDsJekkT",
    userID: "royclaud",
    genres: "alternative rock,chamber pop,melancholia",
  },
  {
    id: "6AI3ezQ4o3HUoP6Dhudph3",
    name: "Not Like Us",
    artists: "Kendrick Lamar",
    duration_ms: 274192,
    popularity: 92,
    preview_url: "https://p.scdn.co/mp3-preview/821def30e24f965adb480145744e4ed2a0f37903?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021ea0c62b2339cbf493a999ad",
    danceability: 0.898,
    energy: 0.472,
    valence: 0.214,
    added_at: "2024-07-13T18:46:24Z",
    link: "https://open.spotify.com/track/6AI3ezQ4o3HUoP6Dhudph3",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "1DIXPcTDzTj8ZMHt3PDt8p",
    name: "Gangsta's Paradise",
    artists: "Coolio, L.V.",
    duration_ms: 240693,
    popularity: 85,
    preview_url: "https://p.scdn.co/mp3-preview/1454c63a66c27ac745f874d6c113270a9c62d28e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c31d3c870a3dbaf7b53186cc",
    danceability: 0.647,
    energy: 0.514,
    valence: 0.386,
    added_at: "2024-07-12T18:54:56Z",
    link: "https://open.spotify.com/track/1DIXPcTDzTj8ZMHt3PDt8p",
    userID: "royclaud",
    genres: "g funk,gangster rap,hip hop,west coast rap",
  },
  {
    id: "4rcHWl68ai6KvpXlc8vbnE",
    name: "Keep on Loving You",
    artists: "REO Speedwagon",
    duration_ms: 200293,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/3cdad676ebe5f7b2ff03b781834d7e596c279fe5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d514470784e3d02ee0bcdb80",
    danceability: 0.306,
    energy: 0.722,
    valence: 0.41,
    added_at: "2024-07-12T18:26:51Z",
    link: "https://open.spotify.com/track/4rcHWl68ai6KvpXlc8vbnE",
    userID: "royclaud",
    genres: "album rock,classic rock,glam metal,hard rock,mellow gold,rock,soft rock",
  },
  {
    id: "5NGtFXVpXSvwunEIGeviY3",
    name: "Gymnopédie No. 1",
    artists: "Erik Satie, Philippe Entremont",
    duration_ms: 205866,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/2fe1ebce461f9d07ba552a0df167b167f4cd589d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020d3d783124fd96e37b6feee4",
    danceability: 0.468,
    energy: 0.0127,
    valence: 0.356,
    added_at: "2024-07-10T17:39:38Z",
    link: "https://open.spotify.com/track/5NGtFXVpXSvwunEIGeviY3",
    userID: "royclaud",
    genres: "classical,early avant garde,early modern classical,impressionism,post-romantic era,classical performance,french classical piano",
  },
  {
    id: "4KWeGKChLKcnZsj3sIOSkW",
    name: "Tobey (feat. Big Sean and BabyTron)",
    artists: "Eminem, Big Sean, BabyTron",
    duration_ms: 284892,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/64482b1ebe7615a0361e9ec39364802024213bdc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02133d7c014b5bec0d7eea09b7",
    danceability: 0.649,
    energy: 0.663,
    valence: 0.208,
    added_at: "2024-07-05T22:29:29Z",
    link: "https://open.spotify.com/track/4KWeGKChLKcnZsj3sIOSkW",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,rap,detroit hip hop,hip hop,pop rap,r&b,rap,southern hip hop,trap,detroit hip hop,scam rap",
  },
  {
    id: "6MOjqs7ngSnZz3J5GZLAfm",
    name: "Arriba",
    artists: "Natanael Cano",
    duration_ms: 174642,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/caf958fbd6fca1dec4b7911899f7c23bcce5a2a0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02df7daf33626eb98f1a2a96f9",
    danceability: 0.579,
    energy: 0.577,
    valence: 0.184,
    added_at: "2024-06-30T20:26:24Z",
    link: "https://open.spotify.com/track/6MOjqs7ngSnZz3J5GZLAfm",
    userID: "royclaud",
    genres: "corrido,corridos tumbados,musica mexicana,sad sierreno,sierreno",
  },
  {
    id: "0akyEssGRVHstqCSWXusJL",
    name: "Fuentes de Ortiz",
    artists: "Ed Maverick",
    duration_ms: 207400,
    popularity: 75,
    preview_url: "https://p.scdn.co/mp3-preview/7170f5c8fb8b595d152379677e0722bd4fd4c882?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026c4c83779f5d3cd6ad3e1aec",
    danceability: 0.83,
    energy: 0.159,
    valence: 0.189,
    added_at: "2024-06-30T20:25:56Z",
    link: "https://open.spotify.com/track/0akyEssGRVHstqCSWXusJL",
    userID: "royclaud",
    genres: "chihuahua indie,indie triste,mexican indie",
  },
  {
    id: "4ZYM6R5nOZywxACY90Bsla",
    name: "Playaz Club",
    artists: "Rappin' 4-Tay",
    duration_ms: 266333,
    popularity: 53,
    preview_url: "https://p.scdn.co/mp3-preview/85657f397381d6d6e370ab34f4f40593cee8756e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028dab81702bf7dd707c8ba965",
    danceability: 0.868,
    energy: 0.372,
    valence: 0.666,
    added_at: "2024-06-28T17:20:31Z",
    link: "https://open.spotify.com/track/4ZYM6R5nOZywxACY90Bsla",
    userID: "royclaud",
    genres: "g funk",
  },
  {
    id: "3u9PAti8muEXXvEjeFpQK0",
    name: "WHARF TALK (feat. A$AP Rocky)",
    artists: "Tyler, The Creator, A$AP Rocky",
    duration_ms: 204109,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/94f47f194f773fade279e765b91d4c908e871bd6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02aa95a399fd30fbb4f6f59fca",
    danceability: 0.788,
    energy: 0.744,
    valence: 0.681,
    added_at: "2024-06-26T23:13:23Z",
    link: "https://open.spotify.com/track/3u9PAti8muEXXvEjeFpQK0",
    userID: "royclaud",
    genres: "east coast hip hop,hip hop,rap",
  },
  {
    id: "1Wh5LO3TWAvaeDrwp4FZgo",
    name: "King",
    artists: "XXXTENTACION",
    duration_ms: 112800,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/2bd9aaedb977baa8968b06c689d3c236e92b0c22?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025485ea186d6971e18fabcc2f",
    danceability: 0.421,
    energy: 0.154,
    valence: 0.0949,
    added_at: "2024-06-12T18:13:25Z",
    link: "https://open.spotify.com/track/1Wh5LO3TWAvaeDrwp4FZgo",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "1haJsMtoBhHfvuM7XWuT3W",
    name: "El Azul",
    artists: "Junior H, Peso Pluma",
    duration_ms: 187225,
    popularity: 79,
    preview_url: "https://p.scdn.co/mp3-preview/f9df136c3a2d3c0c5424e2c4b75a303f35c08af5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0233ed356efed99b158c4267c6",
    danceability: 0.561,
    energy: 0.643,
    valence: 0.842,
    added_at: "2024-06-11T22:14:55Z",
    link: "https://open.spotify.com/track/1haJsMtoBhHfvuM7XWuT3W",
    userID: "royclaud",
    genres: "corrido,corridos tumbados,musica mexicana,sad sierreno,sierreno,corridos tumbados,sad sierreno",
  },
  {
    id: "3FqUPvubYeRCMsdJ02QyZR",
    name: "TÚ NAME",
    artists: "Fuerza Regida",
    duration_ms: 152078,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/f90fcef8fe627f6c5c335fa4b246d3e62b98a158?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02abc2d11a541fa61795691697",
    danceability: 0.76,
    energy: 0.747,
    valence: 0.884,
    added_at: "2024-06-11T22:05:30Z",
    link: "https://open.spotify.com/track/3FqUPvubYeRCMsdJ02QyZR",
    userID: "royclaud",
    genres: "corrido,corridos tumbados,sad sierreno,sierreno",
  },
  {
    id: "1uxiaf3DUS4vpOqNGu4MzL",
    name: "LA DURANGO",
    artists: "Peso Pluma, Junior H, Eslabon Armado",
    duration_ms: 262883,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/131b15e7a72ca3e205c82ec3c4ddc4df74074c91?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d8eedd3e32682857322c06d4",
    danceability: 0.737,
    energy: 0.726,
    valence: 0.889,
    added_at: "2024-06-11T22:05:26Z",
    link: "https://open.spotify.com/track/1uxiaf3DUS4vpOqNGu4MzL",
    userID: "royclaud",
    genres: "corridos tumbados,sad sierreno,corrido,corridos tumbados,musica mexicana,sad sierreno,sierreno,corrido,corridos tumbados,sad sierreno,sierreno",
  },
  {
    id: "6o8nZVzweaEOLDLOgaogX5",
    name: "Just Another Nervous Wreck - 2010 Remastered",
    artists: "Supertramp",
    duration_ms: 265680,
    popularity: 42,
    preview_url: "https://p.scdn.co/mp3-preview/07017ee2b8bf77f8ffd4ec87fb395aa8ecd34d0b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025405ef9e393f5f1e53b4b42e",
    danceability: 0.352,
    energy: 0.786,
    valence: 0.523,
    added_at: "2024-06-01T21:38:40Z",
    link: "https://open.spotify.com/track/6o8nZVzweaEOLDLOgaogX5",
    userID: "royclaud",
    genres: "album rock,art rock,classic rock,glam rock,mellow gold,piano rock,progressive rock,rock,soft rock,symphonic rock",
  },
  {
    id: "4C397XtpxJdRNCEmVav0gX",
    name: "No Face",
    artists: "Ghostface Killah, Kanye West",
    duration_ms: 275750,
    popularity: 53,
    preview_url: "https://p.scdn.co/mp3-preview/cc4d9ed6bd714061b686f52031a2d0c74390da21?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e3df38544e7cc00a9490f7f6",
    danceability: 0.676,
    energy: 0.911,
    valence: 0.408,
    added_at: "2024-06-01T21:15:31Z",
    link: "https://open.spotify.com/track/4C397XtpxJdRNCEmVav0gX",
    userID: "royclaud",
    genres: "east coast hip hop,hardcore hip hop,chicago rap,hip hop,rap",
  },
  {
    id: "25EtWmcUALs9Gtfiv7Bcab",
    name: 'Mighty "O"',
    artists: "Outkast",
    duration_ms: 256226,
    popularity: 36,
    preview_url: "https://p.scdn.co/mp3-preview/2ebc6ef4b5a0e5ad3900000fbefe432053f25511?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023a6e47b30d9af72db1dba2da",
    danceability: 0.449,
    energy: 0.698,
    valence: 0.516,
    added_at: "2024-05-24T21:51:55Z",
    link: "https://open.spotify.com/track/25EtWmcUALs9Gtfiv7Bcab",
    userID: "royclaud",
    genres: "atl hip hop,dirty south rap,hip hop,old school atlanta hip hop,rap,southern hip hop",
  },
  {
    id: "4AtZRwSR8BOTTQg5ihSggt",
    name: "PRBLMS",
    artists: "6LACK",
    duration_ms: 246773,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/ef3073f59d504fce50c9b7a49ac55ff3280abfa1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e4f13cd87b3b1ead6735a520",
    danceability: 0.417,
    energy: 0.58,
    valence: 0.139,
    added_at: "2024-05-23T03:21:32Z",
    link: "https://open.spotify.com/track/4AtZRwSR8BOTTQg5ihSggt",
    userID: "royclaud",
    genres: "atl hip hop,melodic rap,r&b,rap,trap",
  },
  {
    id: "1aLpfH1M7Y4ZngOmSiHk0l",
    name: "ON THE RIVER",
    artists: "Offset",
    duration_ms: 96013,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/744c490cee03180a98f86a38fb182250e5f53d97?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028473cdc72d9c6f9a2787e070",
    danceability: 0.803,
    energy: 0.623,
    valence: 0.307,
    added_at: "2024-05-10T01:56:32Z",
    link: "https://open.spotify.com/track/1aLpfH1M7Y4ZngOmSiHk0l",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,pop rap,rap,trap",
  },
  {
    id: "0FG2BidxWdYecUegaWAnST",
    name: "Juan Martha",
    artists: "La Original Banda El Limón de Salvador Lizárraga",
    duration_ms: 190413,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/8b6893a12f13a1c1b191d3681d74c3d0a3f711df?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e81f50135df33ba6d423bbc5",
    danceability: 0.713,
    energy: 0.613,
    valence: 0.98,
    added_at: "2024-05-01T12:50:25Z",
    link: "https://open.spotify.com/track/0FG2BidxWdYecUegaWAnST",
    userID: "royclaud",
    genres: "banda,musica mexicana,norteno",
  },
  {
    id: "5NCBP3ivrIDcHEtzHSW3k0",
    name: "El Drip",
    artists: "Natanael Cano",
    duration_ms: 142910,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/98c38a44d3db1353738397fcb29d7d4a24243353?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02cda237a502031f4caa2c1bde",
    danceability: 0.73,
    energy: 0.702,
    valence: 0.868,
    added_at: "2024-05-01T12:49:11Z",
    link: "https://open.spotify.com/track/5NCBP3ivrIDcHEtzHSW3k0",
    userID: "royclaud",
    genres: "corrido,corridos tumbados,musica mexicana,sad sierreno,sierreno",
  },
  {
    id: "4Dx4e0X5dR6TW85dtFU42x",
    name: "Red Leather",
    artists: "Future, Metro Boomin, J. Cole",
    duration_ms: 414893,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/af4a6a44f538194f5f0c44df6790917d9b7fb5ae?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d353552c4c2932094456bbe9",
    danceability: 0.676,
    energy: 0.607,
    valence: 0.601,
    added_at: "2024-04-28T20:35:12Z",
    link: "https://open.spotify.com/track/4Dx4e0X5dR6TW85dtFU42x",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap,southern hip hop,trap,rap,conscious hip hop,hip hop,north carolina hip hop,rap",
  },
  {
    id: "1ihKji1UdshwukWYh3cQK2",
    name: "Chant of the Ever Circling Skeletal Family - 2016 Remaster",
    artists: "David Bowie",
    duration_ms: 126066,
    popularity: 30,
    preview_url: "https://p.scdn.co/mp3-preview/c9784ba74d340183a6eea2610aa41cc614544434?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ad22c83a6e1567f8453c32b3",
    danceability: 0.489,
    energy: 0.553,
    valence: 0.402,
    added_at: "2024-04-26T23:17:51Z",
    link: "https://open.spotify.com/track/1ihKji1UdshwukWYh3cQK2",
    userID: "royclaud",
    genres: "art rock,classic rock,glam rock,permanent wave,rock",
  },
  {
    id: "1OfSGTcLJwvun8KRLsg53d",
    name: "Somebody up There Likes Me - 2016 Remaster",
    artists: "David Bowie",
    duration_ms: 396453,
    popularity: 41,
    preview_url: "https://p.scdn.co/mp3-preview/4b784c4622615dc052d58580f1b5de91f8753bd0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b3bd6493ad6e29dcd2ad5a37",
    danceability: 0.282,
    energy: 0.835,
    valence: 0.461,
    added_at: "2024-04-26T16:43:25Z",
    link: "https://open.spotify.com/track/1OfSGTcLJwvun8KRLsg53d",
    userID: "royclaud",
    genres: "art rock,classic rock,glam rock,permanent wave,rock",
  },
  {
    id: "7MB0GpjxLIiqhPgubokq8F",
    name: "Win - 2016 Remaster",
    artists: "David Bowie",
    duration_ms: 287573,
    popularity: 36,
    preview_url: "https://p.scdn.co/mp3-preview/f4a00ea7d9aa47be5b5d862eeae851e22054403b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b3bd6493ad6e29dcd2ad5a37",
    danceability: 0.38,
    energy: 0.39,
    valence: 0.22,
    added_at: "2024-04-26T16:41:41Z",
    link: "https://open.spotify.com/track/7MB0GpjxLIiqhPgubokq8F",
    userID: "royclaud",
    genres: "art rock,classic rock,glam rock,permanent wave,rock",
  },
  {
    id: "7pyD032027SPw5Sc0xhfrI",
    name: "Big Brother - 2016 Remaster",
    artists: "David Bowie",
    duration_ms: 203080,
    popularity: 34,
    preview_url: "https://p.scdn.co/mp3-preview/88fa59170e6d87d2f2cc0a2fefda599ee8e17fc2?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ad22c83a6e1567f8453c32b3",
    danceability: 0.218,
    energy: 0.463,
    valence: 0.349,
    added_at: "2024-04-26T16:37:26Z",
    link: "https://open.spotify.com/track/7pyD032027SPw5Sc0xhfrI",
    userID: "royclaud",
    genres: "art rock,classic rock,glam rock,permanent wave,rock",
  },
  {
    id: "20jwmVY9TLNj9Yl8VbKuCh",
    name: "We Are the Dead - 2016 Remaster",
    artists: "David Bowie",
    duration_ms: 301773,
    popularity: 36,
    preview_url: "https://p.scdn.co/mp3-preview/f35e0e711f4aee0123717cb61c6e01197f7d7a6d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ad22c83a6e1567f8453c32b3",
    danceability: 0.373,
    energy: 0.279,
    valence: 0.287,
    added_at: "2024-04-26T16:35:50Z",
    link: "https://open.spotify.com/track/20jwmVY9TLNj9Yl8VbKuCh",
    userID: "royclaud",
    genres: "art rock,classic rock,glam rock,permanent wave,rock",
  },
  {
    id: "3ZFurUI2qESabUHHyIlxa8",
    name: "Lady Grinning Soul - 2013 Remaster",
    artists: "David Bowie",
    duration_ms: 235255,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/cfc6058fb81d34f982ac475ec8980ecd5f6bfed6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025db6dbaca8678527e643a866",
    danceability: 0.373,
    energy: 0.56,
    valence: 0.167,
    added_at: "2024-04-26T16:32:40Z",
    link: "https://open.spotify.com/track/3ZFurUI2qESabUHHyIlxa8",
    userID: "royclaud",
    genres: "art rock,classic rock,glam rock,permanent wave,rock",
  },
  {
    id: "3ZE3wv8V3w2T2f7nOCjV0N",
    name: "Life on Mars? - 2015 Remaster",
    artists: "David Bowie",
    duration_ms: 235986,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/2f790302db8b1fc1138a5570fcc169ac7b4a0aab?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e464904cc3fed2b40fc55120",
    danceability: 0.445,
    energy: 0.384,
    valence: 0.216,
    added_at: "2024-04-26T16:28:38Z",
    link: "https://open.spotify.com/track/3ZE3wv8V3w2T2f7nOCjV0N",
    userID: "royclaud",
    genres: "art rock,classic rock,glam rock,permanent wave,rock",
  },
  {
    id: "0LrwgdLsFaWh9VXIjBRe8t",
    name: "Changes - 2015 Remaster",
    artists: "David Bowie",
    duration_ms: 217746,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/169c24f8dec808a59b1d58d0611d269cc88175fc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e464904cc3fed2b40fc55120",
    danceability: 0.559,
    energy: 0.308,
    valence: 0.414,
    added_at: "2024-04-26T16:24:03Z",
    link: "https://open.spotify.com/track/0LrwgdLsFaWh9VXIjBRe8t",
    userID: "royclaud",
    genres: "art rock,classic rock,glam rock,permanent wave,rock",
  },
  {
    id: "0dXABNmo2aAdbmtRTzF1F2",
    name: "The Supermen - 2015 Remaster",
    artists: "David Bowie",
    duration_ms: 221226,
    popularity: 32,
    preview_url: "https://p.scdn.co/mp3-preview/e45d60e030df3b0956db5ffb1eeffac8f4ff68eb?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0246926e777bd6665f03128d29",
    danceability: 0.325,
    energy: 0.594,
    valence: 0.5,
    added_at: "2024-04-26T16:23:19Z",
    link: "https://open.spotify.com/track/0dXABNmo2aAdbmtRTzF1F2",
    userID: "royclaud",
    genres: "art rock,classic rock,glam rock,permanent wave,rock",
  },
  {
    id: "2ro1F3DDXLlj1P80xYki4K",
    name: "After All - 2015 Remaster",
    artists: "David Bowie",
    duration_ms: 240320,
    popularity: 34,
    preview_url: "https://p.scdn.co/mp3-preview/9ce42a5cf5574e62120007f76114a74858d94118?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0246926e777bd6665f03128d29",
    danceability: 0.381,
    energy: 0.154,
    valence: 0.109,
    added_at: "2024-04-26T16:21:01Z",
    link: "https://open.spotify.com/track/2ro1F3DDXLlj1P80xYki4K",
    userID: "royclaud",
    genres: "art rock,classic rock,glam rock,permanent wave,rock",
  },
  {
    id: "63OQupATfueTdZMWTxW03A",
    name: "Karma Police",
    artists: "Radiohead",
    duration_ms: 264066,
    popularity: 77,
    preview_url: "https://p.scdn.co/mp3-preview/0c770be37583bbf7f7da3febeb2bf11d1a621a80?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c8b444df094279e70d0ed856",
    danceability: 0.36,
    energy: 0.501,
    valence: 0.324,
    added_at: "2024-04-26T13:58:47Z",
    link: "https://open.spotify.com/track/63OQupATfueTdZMWTxW03A",
    userID: "royclaud",
    genres: "alternative rock,art rock,melancholia,oxford indie,permanent wave,rock",
  },
  {
    id: "7sVbKoBdhXtYCEOO6qC1SN",
    name: "We Still Don't Trust You",
    artists: "Future, Metro Boomin, The Weeknd",
    duration_ms: 252920,
    popularity: 76,
    preview_url: "https://p.scdn.co/mp3-preview/798fb1da51899b224882d70fe6eea92e1e0f4b1f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d353552c4c2932094456bbe9",
    danceability: 0.717,
    energy: 0.586,
    valence: 0.429,
    added_at: "2024-04-12T22:23:08Z",
    link: "https://open.spotify.com/track/7sVbKoBdhXtYCEOO6qC1SN",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap,southern hip hop,trap,rap,canadian contemporary r&b,canadian pop,pop",
  },
  {
    id: "4XeElJ6zvozrMsDQRbfLp1",
    name: "RIOD RAGE - GYM HARDSTYLE",
    artists: "TOTZ, ZYZZ HARDSTYLE, Anime Lifestyle",
    duration_ms: 108006,
    popularity: 44,
    preview_url: "https://p.scdn.co/mp3-preview/4ded3606065d5e89bcadc9c1ac8e2e98922d807c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025a8d68664a3b9959ab41556d",
    danceability: 0.292,
    energy: 0.906,
    valence: 0.47,
    added_at: "2024-04-11T23:25:21Z",
    link: "https://open.spotify.com/track/4XeElJ6zvozrMsDQRbfLp1",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "0Sgk9iiFGgjVxJfXv2L3iF",
    name: "LED",
    artists: "OmenXIII",
    duration_ms: 134451,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/30548b7fef8d77c976eacfa4377738a1cba99a3e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02839837732307e5556ebafe45",
    danceability: 0.663,
    energy: 0.66,
    valence: 0.606,
    added_at: "2024-04-11T23:21:45Z",
    link: "https://open.spotify.com/track/0Sgk9iiFGgjVxJfXv2L3iF",
    userID: "royclaud",
    genres: "cloud rap,dark trap",
  },
  {
    id: "1PTWicTyEUeYeWq4H25a4N",
    name: "leavemealone",
    artists: "Fred again.., Baby Keem",
    duration_ms: 222784,
    popularity: 15,
    preview_url: "https://p.scdn.co/mp3-preview/154e06b77aed19154a2b3e7dcfc9af91c74f4433?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02caa718aa1278c4016a17bc05",
    danceability: 0.68,
    energy: 0.881,
    valence: 0.105,
    added_at: "2024-04-11T23:17:37Z",
    link: "https://open.spotify.com/track/1PTWicTyEUeYeWq4H25a4N",
    userID: "royclaud",
    genres: "edm,house,stutter house,hip hop,rap",
  },
  {
    id: "0UKzG4fJ1LXf6DInew5vcV",
    name: "Mercury: Retrograde",
    artists: "Ghostemane",
    duration_ms: 124538,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/af26ee5652863fe74ad5f5b2c76694e7122d3678?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f45cd779255e379394d17df9",
    danceability: 0.826,
    energy: 0.816,
    valence: 0.43,
    added_at: "2024-04-11T23:16:20Z",
    link: "https://open.spotify.com/track/0UKzG4fJ1LXf6DInew5vcV",
    userID: "royclaud",
    genres: "cloud rap,dark trap,florida rap",
  },
  {
    id: "7I4ibsZgwuf46g0xHuTC8s",
    name: "HDMI",
    artists: "BONES",
    duration_ms: 139440,
    popularity: 73,
    preview_url: "https://p.scdn.co/mp3-preview/2a37182f00824a99764f814e5e58f4e711978816?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f7d60ae94e7b907a8a9ca3f3",
    danceability: 0.713,
    energy: 0.29,
    valence: 0.121,
    added_at: "2024-04-11T23:16:16Z",
    link: "https://open.spotify.com/track/7I4ibsZgwuf46g0xHuTC8s",
    userID: "royclaud",
    genres: "cloud rap,dark trap",
  },
  {
    id: "2zG8L9QM7FnlKkLQYirIxh",
    name: "Righteous",
    artists: "Mo Beats",
    duration_ms: 163910,
    popularity: 21,
    preview_url: "https://p.scdn.co/mp3-preview/7a8ee004fc0871e2bfcc15199d620d5cefcd718b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a2fcc0e3c3a094f5b37b3321",
    danceability: 0.426,
    energy: 0.78,
    valence: 0.133,
    added_at: "2024-04-11T23:16:10Z",
    link: "https://open.spotify.com/track/2zG8L9QM7FnlKkLQYirIxh",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "2WfaOiMkCvy7F5fcp2zZ8L",
    name: "Take on Me",
    artists: "a-ha",
    duration_ms: 225280,
    popularity: 87,
    preview_url: "https://p.scdn.co/mp3-preview/d1427dd0a300eeccfc53b99a2ebf3c664a67414a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e8dd4db47e7177c63b0b7d53",
    danceability: 0.573,
    energy: 0.902,
    valence: 0.876,
    added_at: "2024-04-09T22:19:20Z",
    link: "https://open.spotify.com/track/2WfaOiMkCvy7F5fcp2zZ8L",
    userID: "royclaud",
    genres: "new romantic,new wave,new wave pop,permanent wave,synthpop",
  },
  {
    id: "2ZRo7axmMPeSVUvDbGkJah",
    name: "Hope",
    artists: "XXXTENTACION",
    duration_ms: 110886,
    popularity: 82,
    preview_url: "https://p.scdn.co/mp3-preview/c4ef442e8854f5861e38967f01408e3cba9e1a7a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02806c160566580d6335d1f16c",
    danceability: 0.592,
    energy: 0.457,
    valence: 0.225,
    added_at: "2024-04-09T22:12:05Z",
    link: "https://open.spotify.com/track/2ZRo7axmMPeSVUvDbGkJah",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "10yBOZi6cIDeJOlb7Pbz1J",
    name: "Write This Down",
    artists: "SoulChef, Nieve",
    duration_ms: 189426,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/cff9e09c55ea2a73c744ea81b3e65e793f11cf96?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e2186b9f2e60f5acffbeb04a",
    danceability: 0.824,
    energy: 0.818,
    valence: 0.844,
    added_at: "2024-04-09T22:00:29Z",
    link: "https://open.spotify.com/track/10yBOZi6cIDeJOlb7Pbz1J",
    userID: "royclaud",
    genres: "ambeat,ambeat",
  },
  {
    id: "24A1MxM364lveyyDomwIYq",
    name: 'act i: stickerz "99"',
    artists: "4batz",
    duration_ms: 104468,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/34f7b98ffdb3136f50627d42b39d88b63f23219c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022b529e0d47c952cad0e5cf91",
    danceability: 0.483,
    energy: 0.22,
    valence: 0.326,
    added_at: "2024-03-31T01:17:10Z",
    link: "https://open.spotify.com/track/24A1MxM364lveyyDomwIYq",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "4HMop4Re0iucehmF7mgV27",
    name: "act ii: date @ 8",
    artists: "4batz",
    duration_ms: 113684,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/ce3a6bd474839a7516c36b8e8cceb573e5160505?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02aa22e6a9258dcc425a4a5019",
    danceability: 0.741,
    energy: 0.451,
    valence: 0.558,
    added_at: "2024-03-31T01:17:03Z",
    link: "https://open.spotify.com/track/4HMop4Re0iucehmF7mgV27",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "30uO152on2tYWoE99VaEL5",
    name: "act iii: on god? (she like)",
    artists: "4batz",
    duration_ms: 170440,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/6c8d7d1f897d4fb5f2e93e8e7fac1f44bbaa072d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a44bcd65bd6bf274d3d56f2c",
    danceability: 0.625,
    energy: 0.258,
    valence: 0.265,
    added_at: "2024-03-31T01:16:55Z",
    link: "https://open.spotify.com/track/30uO152on2tYWoE99VaEL5",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "57h06SZNL6LJ3YKZ1UGCeu",
    name: "Sadeness",
    artists: "Enigma",
    duration_ms: 255533,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/c7f55e023b146c357bb48c8bb721867bab293e8c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02251898236cf85ac01d71793a",
    danceability: 0.588,
    energy: 0.644,
    valence: 0.49,
    added_at: "2024-03-30T02:14:32Z",
    link: "https://open.spotify.com/track/57h06SZNL6LJ3YKZ1UGCeu",
    userID: "royclaud",
    genres: "downtempo,gregorian dance",
  },
  {
    id: "5VfEuwErhx6X4eaPbyBfyu",
    name: "Intro",
    artists: "The xx",
    duration_ms: 127920,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/f720524ac3d0fdae06db3f3c6384f70e3b791114?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0299f2c3ea4400725031a5d619",
    danceability: 0.617,
    energy: 0.778,
    valence: 0.152,
    added_at: "2024-03-30T02:11:28Z",
    link: "https://open.spotify.com/track/5VfEuwErhx6X4eaPbyBfyu",
    userID: "royclaud",
    genres: "downtempo,dream pop,indietronica",
  },
  {
    id: "05TrHslexQPuqW3bGjYnin",
    name: "lost boys",
    artists: "mgk, Trippie Redd",
    duration_ms: 161228,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/16f23a51ccdebe811d33940f7d6599876829b786?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02145eb25fb67e59a653f21ee8",
    danceability: 0.649,
    energy: 0.386,
    valence: 0.295,
    added_at: "2024-03-29T22:55:39Z",
    link: "https://open.spotify.com/track/05TrHslexQPuqW3bGjYnin",
    userID: "royclaud",
    genres: "ohio hip hop,pop rap,melodic rap,rap,trap",
  },
  {
    id: "2ylpbsHqGUAv3za4JkyMgz",
    name: "Xanadu",
    artists: "Ummet Ozcan",
    duration_ms: 229926,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/3ec2476b5b4d4fecd41d9a57e108bb1df3b9de88?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022998bd0500355d137c1f9ad1",
    danceability: 0.699,
    energy: 0.625,
    valence: 0.0701,
    added_at: "2024-03-27T22:02:30Z",
    link: "https://open.spotify.com/track/2ylpbsHqGUAv3za4JkyMgz",
    userID: "royclaud",
    genres: "big room,dutch house,edm,electro house,pop dance,progressive electro house",
  },
  {
    id: "19P4fwkPhE6yVAXApFWDoF",
    name: "A Life of Illusion",
    artists: "Joe Walsh",
    duration_ms: 210533,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/a515ecba89d009bbb6972ccd1c9c144b5c9adecc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e68242119b944c8503c0f573",
    danceability: 0.692,
    energy: 0.613,
    valence: 0.93,
    added_at: "2024-03-25T23:10:38Z",
    link: "https://open.spotify.com/track/19P4fwkPhE6yVAXApFWDoF",
    userID: "royclaud",
    genres: "album rock,blues rock,classic rock,country rock,hard rock,heartland rock,mellow gold,rock,singer-songwriter,soft rock,southern rock",
  },
  {
    id: "15WOWZpw9YnE4RQQnXHQqW",
    name: "President Carter",
    artists: "Lil Wayne",
    duration_ms: 255373,
    popularity: 53,
    preview_url: "https://p.scdn.co/mp3-preview/113460deb2f5670cee5d20043aa82545914d9400?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e80b258c7e0b318202870953",
    danceability: 0.671,
    energy: 0.618,
    valence: 0.468,
    added_at: "2024-03-25T18:56:39Z",
    link: "https://open.spotify.com/track/15WOWZpw9YnE4RQQnXHQqW",
    userID: "royclaud",
    genres: "hip hop,new orleans rap,pop rap,rap,trap",
  },
  {
    id: "0AQquaENerGps8BQmbPw14",
    name: "Big Iron",
    artists: "Marty Robbins",
    duration_ms: 235733,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/b80beb7f651dd44d498e70082e57bd7a96f9fe7d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02084051346f432cc68a1279cd",
    danceability: 0.726,
    energy: 0.474,
    valence: 0.57,
    added_at: "2024-03-23T04:47:42Z",
    link: "https://open.spotify.com/track/0AQquaENerGps8BQmbPw14",
    userID: "royclaud",
    genres: "cowboy western,nashville sound",
  },
  {
    id: "4UvkQecqZuZfQIJ2DZrZcR",
    name: "Doomsday (with Juice WRLD & Cordae)",
    artists: "Lyrical Lemonade, Juice WRLD, Cordae",
    duration_ms: 150967,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/ea071fc1b2e02093c0fde22b33f1374c88bf0160?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023011c33da8122f277da3b0e5",
    danceability: 0.73,
    energy: 0.703,
    valence: 0.853,
    added_at: "2024-03-16T19:22:06Z",
    link: "https://open.spotify.com/track/4UvkQecqZuZfQIJ2DZrZcR",
    userID: "royclaud",
    genres: "chicago rap,melodic rap,rap,hip hop,pop rap,rap,trap,underground hip hop",
  },
  {
    id: "5SGWCqheDXPlYnv9aQs795",
    name: "Cowboy Killer",
    artists: "That Mexican OT",
    duration_ms: 142205,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/c634a3231b15aba5ac85523771dc54ebd3ef07c3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022c1cf5576a9020a191c8d20f",
    danceability: 0.829,
    energy: 0.586,
    valence: 0.562,
    added_at: "2024-03-15T22:34:21Z",
    link: "https://open.spotify.com/track/5SGWCqheDXPlYnv9aQs795",
    userID: "royclaud",
    genres: "texas latin rap",
  },
  {
    id: "4QTlxavdQGUVitxAvQqqkU",
    name: "Mmhmm",
    artists: "BigXthaPlug",
    duration_ms: 119015,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/2f0692571f94cf8573032998a3b9ae7e40d4eba5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029afbbc313ea008b68cc5f40d",
    danceability: 0.93,
    energy: 0.682,
    valence: 0.962,
    added_at: "2024-03-15T21:53:38Z",
    link: "https://open.spotify.com/track/4QTlxavdQGUVitxAvQqqkU",
    userID: "royclaud",
    genres: "southern hip hop",
  },
  {
    id: "4qNYl4NkngYRqf6DtTyD9I",
    name: "Die Trying",
    artists: "Key Glock",
    duration_ms: 153106,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/5a606e305640057f7d86e5b75c5baba91d3663f5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e634bd400f0ed0ed5a6f0164",
    danceability: 0.893,
    energy: 0.712,
    valence: 0.685,
    added_at: "2024-03-15T21:51:03Z",
    link: "https://open.spotify.com/track/4qNYl4NkngYRqf6DtTyD9I",
    userID: "royclaud",
    genres: "memphis hip hop,rap,southern hip hop,tennessee hip hop,trap",
  },
  {
    id: "6vh6CREWHffp7HD838fD4T",
    name: "This Is Food",
    artists: "Merkules",
    duration_ms: 217898,
    popularity: 17,
    preview_url: "https://p.scdn.co/mp3-preview/4b79b3dfc3515d2db26f7ab446908f5a4771e951?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0295d3124155b96673a1104da1",
    danceability: 0.442,
    energy: 0.574,
    valence: 0.256,
    added_at: "2024-03-09T18:54:03Z",
    link: "https://open.spotify.com/track/6vh6CREWHffp7HD838fD4T",
    userID: "royclaud",
    genres: "bc underground hip hop,canadian hip hop",
  },
  {
    id: "6Nq5lE0CeOAAAqN4qhkbZU",
    name: "Suga Suga",
    artists: "Baby Bash, Frankie J",
    duration_ms: 239026,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/cdb8a4d89f6ebb2f5db5033c0cf0c569f8ec7ddd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0207acb67d4e71275637cd0a1b",
    danceability: 0.662,
    energy: 0.748,
    valence: 0.535,
    added_at: "2024-03-02T19:37:58Z",
    link: "https://open.spotify.com/track/6Nq5lE0CeOAAAqN4qhkbZU",
    userID: "royclaud",
    genres: "chicano rap,latin hip hop,pop rap,southern hip hop,texas latin rap,urban contemporary,hip pop,pop rap,southern hip hop,urban contemporary",
  },
  {
    id: "2tOZtdbkAMQASWokLvSlzj",
    name: "Overnight Celebrity (feat. Miri Ben-Ari)",
    artists: "Twista, Miri Ben-Ari",
    duration_ms: 233360,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/4de5b89a3dbe08366315022b30a6731e3c68455c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0294daf811f14ad48e6c5f9214",
    danceability: 0.828,
    energy: 0.792,
    valence: 0.777,
    added_at: "2024-02-28T23:54:21Z",
    link: "https://open.spotify.com/track/2tOZtdbkAMQASWokLvSlzj",
    userID: "royclaud",
    genres: "chicago rap,dirty south rap,gangster rap,trap,pop violin",
  },
  {
    id: "1cA2LExLTdhHLL0FmXoc57",
    name: "When It Rains It Pours",
    artists: "50 Cent",
    duration_ms: 242226,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/0b256022a2eebfedd00587d7204094c947efde88?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0269da799626c944aeef9b5e37",
    danceability: 0.727,
    energy: 0.74,
    valence: 0.221,
    added_at: "2024-02-28T23:51:38Z",
    link: "https://open.spotify.com/track/1cA2LExLTdhHLL0FmXoc57",
    userID: "royclaud",
    genres: "east coast hip hop,gangster rap,hip hop,pop rap,queens hip hop,rap",
  },
  {
    id: "5sSYvDVyP0CP9TUiqy3fuF",
    name: "Cradles",
    artists: "Sub Urban",
    duration_ms: 209829,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/584c63beb79d87d11ed0cefbf158914b9d8730ec?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a327368f2a7a3b8b56ce242e",
    danceability: 0.537,
    energy: 0.586,
    valence: 0.624,
    added_at: "2024-02-22T03:19:11Z",
    link: "https://open.spotify.com/track/5sSYvDVyP0CP9TUiqy3fuF",
    userID: "royclaud",
    genres: "modern indie pop",
  },
  {
    id: "76xfSfbw0byLqMHykJyPru",
    name: "GodLovesUgly - Remix",
    artists: "Atmosphere, Zeds Dead, Subtronics",
    duration_ms: 184421,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/162e0ce8fa45ae1405de01c720e295edca026b93?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e533458466f904df553365df",
    danceability: 0.679,
    energy: 0.861,
    valence: 0.536,
    added_at: "2024-02-22T00:10:42Z",
    link: "https://open.spotify.com/track/76xfSfbw0byLqMHykJyPru",
    userID: "royclaud",
    genres: "alternative hip hop,conscious hip hop,minnesota hip hop,brostep,canadian electronic,dubstep,edm,electro house,progressive electro house,brostep",
  },
  {
    id: "5p8WbMGPsP2TmcsInVn44u",
    name: "Sunshine",
    artists: "Atmosphere, Slug, Ant",
    duration_ms: 216546,
    popularity: 53,
    preview_url: "https://p.scdn.co/mp3-preview/6790d6f5bae764eb1cd569b2f5e01be8ee55b4d3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024beb66f56871bbde37f6892d",
    danceability: 0.853,
    energy: 0.572,
    valence: 0.569,
    added_at: "2024-02-21T23:42:41Z",
    link: "https://open.spotify.com/track/5p8WbMGPsP2TmcsInVn44u",
    userID: "royclaud",
    genres: "alternative hip hop,conscious hip hop,minnesota hip hop,abstract hip hop",
  },
  {
    id: "3QOmgrh6ElgcPKwOAnbwgu",
    name: "The Best Day",
    artists: "Atmosphere, Slug, Ant",
    duration_ms: 216893,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/582fe3c99ea156249fde709631bcfb756ddde14f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021a0163e2efba92fc1f1a3891",
    danceability: 0.882,
    energy: 0.624,
    valence: 0.899,
    added_at: "2024-02-21T23:40:47Z",
    link: "https://open.spotify.com/track/3QOmgrh6ElgcPKwOAnbwgu",
    userID: "royclaud",
    genres: "alternative hip hop,conscious hip hop,minnesota hip hop,abstract hip hop",
  },
  {
    id: "5JtgrxeDRTTcBsYwKoYb8v",
    name: "Late Nights & Heartbreak",
    artists: "Hannah Williams, The Affirmations",
    duration_ms: 255066,
    popularity: 52,
    preview_url: "https://p.scdn.co/mp3-preview/ee7a1926f44235e756b770627fe31e60a41fd163?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a8fca447cf1fccd5cccc49f2",
    danceability: 0.413,
    energy: 0.406,
    valence: 0.147,
    added_at: "2024-01-19T17:12:54Z",
    link: "https://open.spotify.com/track/5JtgrxeDRTTcBsYwKoYb8v",
    userID: "royclaud",
    genres: "retro soul",
  },
  {
    id: "77phWAt2l34OadUUUqi5ai",
    name: "Pixelados",
    artists: "Luis R Conriquez, Peso Pluma",
    duration_ms: 235061,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/66487d1f7c96745b1d2c46401c9baa585111074f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0282c47f52f8ee74aedca7bea3",
    danceability: 0.744,
    energy: 0.833,
    valence: 0.462,
    added_at: "2024-01-05T02:05:54Z",
    link: "https://open.spotify.com/track/77phWAt2l34OadUUUqi5ai",
    userID: "royclaud",
    genres: "corrido,sad sierreno,sierreno,corridos tumbados,sad sierreno",
  },
  {
    id: "0R6NfOiLzLj4O5VbYSJAjf",
    name: "La Diabla",
    artists: "Xavi",
    duration_ms: 172264,
    popularity: 82,
    preview_url: "https://p.scdn.co/mp3-preview/f3eeae4ae180d55cd3c83c4e4c1d657b46aa107e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021501c770de50a22b25d67611",
    danceability: 0.751,
    energy: 0.819,
    valence: 0.697,
    added_at: "2023-12-29T04:18:28Z",
    link: "https://open.spotify.com/track/0R6NfOiLzLj4O5VbYSJAjf",
    userID: "royclaud",
    genres: "sad sierreno",
  },
  {
    id: "6AMZm12a1B2kpgN7qOA7Cy",
    name: "Dopamin",
    artists: "Viktor Sheen",
    duration_ms: 164000,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/9d1c6d56efa5686dfd5dd967105f79a66904753f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c91b872e57d5bb5caa24b3c9",
    danceability: 0.81,
    energy: 0.667,
    valence: 0.4,
    added_at: "2023-12-29T04:17:57Z",
    link: "https://open.spotify.com/track/6AMZm12a1B2kpgN7qOA7Cy",
    userID: "royclaud",
    genres: "czech drill,czech hip hop,czsk hip hop",
  },
  {
    id: "7aPsseax6rNFyipHn9A5CR",
    name: "La Morocha",
    artists: "Luck Ra, BM",
    duration_ms: 134144,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/7c825fe5f91fa359939e2c6768d9be5a09e16cc7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023cd645cc0b41595ccdfd0a73",
    danceability: 0.753,
    energy: 0.77,
    valence: 0.971,
    added_at: "2023-12-29T04:17:13Z",
    link: "https://open.spotify.com/track/7aPsseax6rNFyipHn9A5CR",
    userID: "royclaud",
    genres: "trap argentino,trap triste,previa",
  },
  {
    id: "4yjeRuoVickg1G87LWeRes",
    name: "ROSA PASTEL",
    artists: "Peso Pluma, Jasiel Nuñez",
    duration_ms: 204253,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/fa3830037c80076b7c7e6b72bd64ffdd1b921608?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022fb583ed96f8f35cbf2897ba",
    danceability: 0.702,
    energy: 0.68,
    valence: 0.86,
    added_at: "2023-12-29T04:16:44Z",
    link: "https://open.spotify.com/track/4yjeRuoVickg1G87LWeRes",
    userID: "royclaud",
    genres: "corridos tumbados,sad sierreno,corrido",
  },
  {
    id: "391yrqiYT61ICI46vuIU2M",
    name: "אין עולם",
    artists: "Jasmin Moallem",
    duration_ms: 223333,
    popularity: 38,
    preview_url: "https://p.scdn.co/mp3-preview/0f132fed3ef14cbc085e7138ce51e3c5c8eb095a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0297b6ebf1c5983aa56f4260aa",
    danceability: 0.459,
    energy: 0.579,
    valence: 0.456,
    added_at: "2023-12-29T04:16:19Z",
    link: "https://open.spotify.com/track/391yrqiYT61ICI46vuIU2M",
    userID: "royclaud",
    genres: "israeli pop",
  },
  {
    id: "4ApCig0GTR4IEp7Ijsyo3r",
    name: "Arjan Vailly",
    artists: "Manan Bhardwaj, Bhupinder Babbal",
    duration_ms: 182170,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/e7cc726e8aae41677810a55c7c5f211b1d59f9ee?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025f3ede47954a93aa03efe5f9",
    danceability: 0.532,
    energy: 0.825,
    valence: 0.878,
    added_at: "2023-12-29T04:15:36Z",
    link: "https://open.spotify.com/track/4ApCig0GTR4IEp7Ijsyo3r",
    userID: "royclaud",
    genres: "desi pop,modern bollywood",
  },
  {
    id: "1QBwk6GTCxVdC2hoSw9tlM",
    name: "Lord Knows",
    artists: "Drake, Rick Ross",
    duration_ms: 307640,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/ec66c857f5ce44ce86fee9259706495bc71ec35b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c7ea04a9b455e3f68ef82550",
    danceability: 0.417,
    energy: 0.887,
    valence: 0.303,
    added_at: "2023-12-29T04:05:28Z",
    link: "https://open.spotify.com/track/1QBwk6GTCxVdC2hoSw9tlM",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap,dirty south rap,gangster rap,hip hop,rap,southern hip hop,trap",
  },
  {
    id: "3Osd3Yf8K73aj4ySn6LrvK",
    name: "No Church In The Wild",
    artists: "JAY-Z, Kanye West, Frank Ocean, The-Dream",
    duration_ms: 272506,
    popularity: 5,
    preview_url: "https://p.scdn.co/mp3-preview/b4de791375967e1f159abbe7b6794ab7512d46c7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025c837cc621c1ec82bf3c81ac",
    danceability: 0.654,
    energy: 0.75,
    valence: 0.557,
    added_at: "2023-12-29T04:05:18Z",
    link: "https://open.spotify.com/track/3Osd3Yf8K73aj4ySn6LrvK",
    userID: "royclaud",
    genres: "east coast hip hop,gangster rap,hip hop,pop rap,rap,chicago rap,hip hop,rap,lgbtq+ hip hop,neo soul,dirty south rap,hip pop,r&b,southern hip hop,trap,urban contemporary",
  },
  {
    id: "5zes2uxTNuqNlO6toDTZN5",
    name: "Primetime",
    artists: "JAY-Z, Kanye West",
    duration_ms: 199240,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/3d543466928a43250732832330b49652338eb81d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025c837cc621c1ec82bf3c81ac",
    danceability: 0.662,
    energy: 0.923,
    valence: 0.47,
    added_at: "2023-12-29T03:56:56Z",
    link: "https://open.spotify.com/track/5zes2uxTNuqNlO6toDTZN5",
    userID: "royclaud",
    genres: "east coast hip hop,gangster rap,hip hop,pop rap,rap,chicago rap,hip hop,rap",
  },
  {
    id: "3R1k8P1MrDdLSRhlTaaWAS",
    name: "Who Gon Stop Me",
    artists: "JAY-Z, Kanye West",
    duration_ms: 256706,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/0ea70e6a58c7956a0dbad04411271a4d6619c1b5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025c837cc621c1ec82bf3c81ac",
    danceability: 0.593,
    energy: 0.73,
    valence: 0.377,
    added_at: "2023-12-29T03:55:37Z",
    link: "https://open.spotify.com/track/3R1k8P1MrDdLSRhlTaaWAS",
    userID: "royclaud",
    genres: "east coast hip hop,gangster rap,hip hop,pop rap,rap,chicago rap,hip hop,rap",
  },
  {
    id: "7yfg0Eer6UZZt5tZ1XdsWz",
    name: "Make Me Proud",
    artists: "Drake, Nicki Minaj",
    duration_ms: 219893,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/206a9910606c7df94790eafe08ed35d34a1d6a76?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c7ea04a9b455e3f68ef82550",
    danceability: 0.684,
    energy: 0.486,
    valence: 0.355,
    added_at: "2023-12-29T03:44:45Z",
    link: "https://open.spotify.com/track/7yfg0Eer6UZZt5tZ1XdsWz",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap,hip pop,pop,queens hip hop,rap",
  },
  {
    id: "1ZyudLFv35SRvY5SvTZqJx",
    name: "Buried Alive Interlude",
    artists: "Drake, Kendrick Lamar",
    duration_ms: 150960,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/e126fbca2befb46d8b8510694be2e259d88fa742?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c7ea04a9b455e3f68ef82550",
    danceability: 0.711,
    energy: 0.543,
    valence: 0.801,
    added_at: "2023-12-29T03:42:31Z",
    link: "https://open.spotify.com/track/1ZyudLFv35SRvY5SvTZqJx",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap,conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "1WTdZjiHI4vYnENXLn69x0",
    name: "Are You Gone Already",
    artists: "Nicki Minaj",
    duration_ms: 270860,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/89174efe2a81eb68c84b7a0c2fd563e63787b5de?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02651e1dbc0b5218f2306181a1",
    danceability: 0.553,
    energy: 0.202,
    valence: 0.145,
    added_at: "2023-12-13T05:29:36Z",
    link: "https://open.spotify.com/track/1WTdZjiHI4vYnENXLn69x0",
    userID: "royclaud",
    genres: "hip pop,pop,queens hip hop,rap",
  },
  {
    id: "44gmXwbdHjwQsQvsiraj14",
    name: "Let Me Calm Down (feat. J. Cole)",
    artists: "Nicki Minaj, J. Cole",
    duration_ms: 244446,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/909235c66f630d4b1c665744ee6df3bc3da85661?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02651e1dbc0b5218f2306181a1",
    danceability: 0.773,
    energy: 0.634,
    valence: 0.521,
    added_at: "2023-12-13T05:24:00Z",
    link: "https://open.spotify.com/track/44gmXwbdHjwQsQvsiraj14",
    userID: "royclaud",
    genres: "hip pop,pop,queens hip hop,rap,conscious hip hop,hip hop,north carolina hip hop,rap",
  },
  {
    id: "0KzAbK6nItSqNh8q70tb0K",
    name: "Where Is My Mind?",
    artists: "Pixies",
    duration_ms: 229226,
    popularity: 73,
    preview_url: "https://p.scdn.co/mp3-preview/8414c9d0e363c390f38e74a2fc735bb0bca666cd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b17d34882944eaf0695153f2",
    danceability: 0.513,
    energy: 0.442,
    valence: 0.243,
    added_at: "2023-12-06T18:36:57Z",
    link: "https://open.spotify.com/track/0KzAbK6nItSqNh8q70tb0K",
    userID: "royclaud",
    genres: "alternative rock,boston rock,permanent wave,rock",
  },
  {
    id: "2OHpTAX64YR7wRI45tJMnT",
    name: "Ember",
    artists: "WhoMadeWho",
    duration_ms: 265538,
    popularity: 5,
    preview_url: "https://p.scdn.co/mp3-preview/b2158ab45182c7f0a60d1d49721d8221683d66fd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0230d646c17ef016a3822a61be",
    danceability: 0.687,
    energy: 0.603,
    valence: 0.407,
    added_at: "2023-11-29T17:48:56Z",
    link: "https://open.spotify.com/track/2OHpTAX64YR7wRI45tJMnT",
    userID: "royclaud",
    genres: "dance-punk,danish indie,electronica,experimental pop",
  },
  {
    id: "0ZfByLXCeKchuj7zi1CJ0S",
    name: "Stolen Dance",
    artists: "Milky Chance",
    duration_ms: 313626,
    popularity: 73,
    preview_url: "https://p.scdn.co/mp3-preview/c15971260dadad2779dd06e66386de0325fe2fa9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02266539b00fd32cbcb7579f6c",
    danceability: 0.879,
    energy: 0.565,
    valence: 0.685,
    added_at: "2023-11-20T21:17:41Z",
    link: "https://open.spotify.com/track/0ZfByLXCeKchuj7zi1CJ0S",
    userID: "royclaud",
    genres: "german pop,modern rock",
  },
  {
    id: "2fGRJzoiYJYAzlQAyBONMb",
    name: "Morning Sex",
    artists: "Ralph Castelli",
    duration_ms: 175312,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/b4afaa69abec480209b5404e42240f6e9e507a23?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022a5fa04e857a15bbab62f018",
    danceability: 0.81,
    energy: 0.353,
    valence: 0.731,
    added_at: "2023-11-20T16:57:47Z",
    link: "https://open.spotify.com/track/2fGRJzoiYJYAzlQAyBONMb",
    userID: "royclaud",
    genres: "alaska indie",
  },
  {
    id: "5mWNiGLS2S3rYqETHT9Pb1",
    name: "WOULDYOUWAITFORME",
    artists: "Moodring",
    duration_ms: 207090,
    popularity: 21,
    preview_url: "https://p.scdn.co/mp3-preview/9c39db1a81eb43916930f15d6d597404e2ea9cce?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f715145247312e83ba31cb59",
    danceability: 0.561,
    energy: 0.947,
    valence: 0.224,
    added_at: "2023-11-16T17:59:52Z",
    link: "https://open.spotify.com/track/5mWNiGLS2S3rYqETHT9Pb1",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "3HkrpYty07thx3Yx5nxho9",
    name: "One Of Us",
    artists: "Darke Complex",
    duration_ms: 200653,
    popularity: 15,
    preview_url: "https://p.scdn.co/mp3-preview/31d463769c591aedb69e9a7d314052ed79707c39?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e9d79671bdb5ec46d3a9fdee",
    danceability: 0.368,
    energy: 0.957,
    valence: 0.531,
    added_at: "2023-11-16T17:57:57Z",
    link: "https://open.spotify.com/track/3HkrpYty07thx3Yx5nxho9",
    userID: "royclaud",
    genres: "metallic hardcore,nu-metalcore",
  },
  {
    id: "2RHKzvzdxXANTOKtbZnuLo",
    name: "SCIENTISTS & ENGINEERS [Feat. Future, Eryn Allen Kane]",
    artists: "Killer Mike, André 3000, Eryn Allen Kane, Future",
    duration_ms: 253663,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/4c1fe7bebce012ab69a1c8ac1de0452ddf6b9f96?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02013f3ec69272a4706ed18f33",
    danceability: 0.522,
    energy: 0.725,
    valence: 0.495,
    added_at: "2023-11-16T04:15:18Z",
    link: "https://open.spotify.com/track/2RHKzvzdxXANTOKtbZnuLo",
    userID: "royclaud",
    genres:
      "alternative hip hop,atl hip hop,conscious hip hop,hip hop,old school atlanta hip hop,political hip hop,southern hip hop,atl hip hop,dirty south rap,hip hop,southern hip hop,alternative r&b,neo soul,atl hip hop,hip hop,rap,southern hip hop,trap",
  },
  {
    id: "3wlIAXSDtlD9iU8ysld06Z",
    name: "BIPOLAR",
    artists: "Peso Pluma, Jasiel Nuñez, Junior H",
    duration_ms: 265045,
    popularity: 76,
    preview_url: "https://p.scdn.co/mp3-preview/f24fad9ae90adcea8f9ce7456a174f2493c1c262?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e26340898110cbde4cb74e17",
    danceability: 0.776,
    energy: 0.701,
    valence: 0.603,
    added_at: "2023-11-07T14:42:25Z",
    link: "https://open.spotify.com/track/3wlIAXSDtlD9iU8ysld06Z",
    userID: "royclaud",
    genres: "corridos tumbados,sad sierreno,corrido,corrido,corridos tumbados,musica mexicana,sad sierreno,sierreno",
  },
  {
    id: "3NJG6vMH1ZsectZkocMEm0",
    name: "Tiimmy Turner",
    artists: "Desiigner",
    duration_ms: 239853,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/923fd59aab5bfab7a3cf90e32fdb76d140ce309e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0235243e14634fea3e3cfe3121",
    danceability: 0.602,
    energy: 0.725,
    valence: 0.304,
    added_at: "2023-10-29T02:20:44Z",
    link: "https://open.spotify.com/track/3NJG6vMH1ZsectZkocMEm0",
    userID: "royclaud",
    genres: "pop rap,rap,southern hip hop,trap,viral trap",
  },
  {
    id: "7JBLOSNCfXt0oN7ZtkgqNg",
    name: "Holy Ghost Spirit",
    artists: "Dance Gavin Dance",
    duration_ms: 230061,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/4386ca18685096e83839ddf320ddd83f7c67860d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b01942b135e9b5548a67ad13",
    danceability: 0.482,
    energy: 0.972,
    valence: 0.525,
    added_at: "2023-10-26T22:35:32Z",
    link: "https://open.spotify.com/track/7JBLOSNCfXt0oN7ZtkgqNg",
    userID: "royclaud",
    genres: "pixie,sacramento indie,screamo,swancore",
  },
  {
    id: "1ohbtwiq4p3HqfdEYaOWuA",
    name: "Synergy (feat. Rob Damiani)",
    artists: "Dance Gavin Dance, Rob Damiani",
    duration_ms: 197116,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/39588adc2b414276255e0751736d1e8ec59a43ac?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b01942b135e9b5548a67ad13",
    danceability: 0.622,
    energy: 0.987,
    valence: 0.738,
    added_at: "2023-10-26T22:34:14Z",
    link: "https://open.spotify.com/track/1ohbtwiq4p3HqfdEYaOWuA",
    userID: "royclaud",
    genres: "pixie,sacramento indie,screamo,swancore",
  },
  {
    id: "0LpZy8WZPTtyHHzqlsYjDq",
    name: "Cream Of The Crop",
    artists: "Dance Gavin Dance",
    duration_ms: 219929,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/96b29983b64ffa940bd9df3807af2e503c951561?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b01942b135e9b5548a67ad13",
    danceability: 0.509,
    energy: 0.976,
    valence: 0.478,
    added_at: "2023-10-26T22:30:47Z",
    link: "https://open.spotify.com/track/0LpZy8WZPTtyHHzqlsYjDq",
    userID: "royclaud",
    genres: "pixie,sacramento indie,screamo,swancore",
  },
  {
    id: "0njH70oSRQEpiACG5V3WpD",
    name: "Into The Sunset (feat. Bilmuri)",
    artists: "Dance Gavin Dance, Bilmuri",
    duration_ms: 270358,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/2aeea5da51ef353178e141937ca797a2b9af6e00?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0202d0198148ba9d52522456ab",
    danceability: 0.455,
    energy: 0.941,
    valence: 0.441,
    added_at: "2023-10-26T22:25:25Z",
    link: "https://open.spotify.com/track/0njH70oSRQEpiACG5V3WpD",
    userID: "royclaud",
    genres: "pixie,sacramento indie,screamo,swancore",
  },
  {
    id: "4MpXaXYhkGMw4gt3ZlS7sQ",
    name: "One in a Million",
    artists: "Dance Gavin Dance",
    duration_ms: 221241,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/6fca11d20f1f69db649e040eeeadb9e8f568d325?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0202d0198148ba9d52522456ab",
    danceability: 0.554,
    energy: 0.947,
    valence: 0.621,
    added_at: "2023-10-26T21:42:54Z",
    link: "https://open.spotify.com/track/4MpXaXYhkGMw4gt3ZlS7sQ",
    userID: "royclaud",
    genres: "pixie,sacramento indie,screamo,swancore",
  },
  {
    id: "4f1UHjDNVil4WUop0Nkp8m",
    name: "Three Wishes",
    artists: "Dance Gavin Dance",
    duration_ms: 208191,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/798ffd6245cc69d3e0c644239a1e347f175bde0c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0202d0198148ba9d52522456ab",
    danceability: 0.525,
    energy: 0.92,
    valence: 0.774,
    added_at: "2023-10-26T21:41:38Z",
    link: "https://open.spotify.com/track/4f1UHjDNVil4WUop0Nkp8m",
    userID: "royclaud",
    genres: "pixie,sacramento indie,screamo,swancore",
  },
  {
    id: "779ooI3rBd0CLqCiiJmtVo",
    name: "Hero (feat. Josey Scott)",
    artists: "Chad Kroeger, Josey Scott",
    duration_ms: 200480,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/e26284b2a387742bc250f455ea4ed7bfb8502f57?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02cab637c3978c4db066bfd16f",
    danceability: 0.427,
    energy: 0.843,
    valence: 0.304,
    added_at: "2023-10-16T17:21:19Z",
    link: "https://open.spotify.com/track/779ooI3rBd0CLqCiiJmtVo",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "6mHOcVtsHLMuesJkswc0GZ",
    name: "The Logical Song - Remastered 2010",
    artists: "Supertramp",
    duration_ms: 251253,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/40810a2f83247bea42ea6067ff47c183128e3c26?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025405ef9e393f5f1e53b4b42e",
    danceability: 0.625,
    energy: 0.758,
    valence: 0.695,
    added_at: "2023-10-09T21:02:50Z",
    link: "https://open.spotify.com/track/6mHOcVtsHLMuesJkswc0GZ",
    userID: "royclaud",
    genres: "album rock,art rock,classic rock,glam rock,mellow gold,piano rock,progressive rock,rock,soft rock,symphonic rock",
  },
  {
    id: "4a5pNRjwmzYQuEY1E7O6pj",
    name: "Breakfast In America - 2010 Remastered",
    artists: "Supertramp",
    duration_ms: 159346,
    popularity: 75,
    preview_url: "https://p.scdn.co/mp3-preview/ac3c12aa6a32db5b171f67b9d13a16adc431da3a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025405ef9e393f5f1e53b4b42e",
    danceability: 0.532,
    energy: 0.663,
    valence: 0.756,
    added_at: "2023-10-09T19:52:32Z",
    link: "https://open.spotify.com/track/4a5pNRjwmzYQuEY1E7O6pj",
    userID: "royclaud",
    genres: "album rock,art rock,classic rock,glam rock,mellow gold,piano rock,progressive rock,rock,soft rock,symphonic rock",
  },
  {
    id: "62nUU00mMeW7NsFcAzI17p",
    name: "The Man Who Sold The World - Rehearsal",
    artists: "Nirvana",
    duration_ms: 278417,
    popularity: 32,
    preview_url: "https://p.scdn.co/mp3-preview/84659b34bb75501953ba2d2bdc8c811f3bbcab46?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028ae2c8a304cfb62d0e029fb5",
    danceability: 0.55,
    energy: 0.629,
    valence: 0.543,
    added_at: "2023-10-05T15:29:26Z",
    link: "https://open.spotify.com/track/62nUU00mMeW7NsFcAzI17p",
    userID: "royclaud",
    genres: "grunge,permanent wave,rock",
  },
  {
    id: "4mWahKerLaVddUjb8d1Q4Q",
    name: "The Man Who Sold the World - 2015 Remaster",
    artists: "David Bowie",
    duration_ms: 241346,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/12e563f5be8f79c1a980695f84698035781737a8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0246926e777bd6665f03128d29",
    danceability: 0.474,
    energy: 0.387,
    valence: 0.545,
    added_at: "2023-10-05T15:29:15Z",
    link: "https://open.spotify.com/track/4mWahKerLaVddUjb8d1Q4Q",
    userID: "royclaud",
    genres: "art rock,classic rock,glam rock,permanent wave,rock",
  },
  {
    id: "09qJ4Mg46EmCp3P7w7YFEz",
    name: "La Luna Sabe",
    artists: "La Santa Grifa",
    duration_ms: 353232,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/ad84df2af7f303f206ffba70b1abc48fbfc9e59d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028376aa89ea34d239f18031aa",
    danceability: 0.81,
    energy: 0.646,
    valence: 0.663,
    added_at: "2023-10-05T14:44:47Z",
    link: "https://open.spotify.com/track/09qJ4Mg46EmCp3P7w7YFEz",
    userID: "royclaud",
    genres: "mexican hip hop,sad sierreno,tamaulipas rap",
  },
  {
    id: "3Fac6wn77bH534pIv3YAoU",
    name: "Volver a Ser",
    artists: "La Santa Grifa",
    duration_ms: 351589,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/601d5abbb203842dcc5a8074dff6c43c62a02a4c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c19316e35058c4e11046cb04",
    danceability: 0.797,
    energy: 0.693,
    valence: 0.792,
    added_at: "2023-10-04T14:18:08Z",
    link: "https://open.spotify.com/track/3Fac6wn77bH534pIv3YAoU",
    userID: "royclaud",
    genres: "mexican hip hop,sad sierreno,tamaulipas rap",
  },
  {
    id: "2l57cfmCnOkwNX1tky02n1",
    name: "Move Along",
    artists: "The All-American Rejects",
    duration_ms: 238880,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/67ca28e4fc6a8f898f171a504ceee3d9586c31bf?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02aaf8c068ffe217db825a1945",
    danceability: 0.518,
    energy: 0.916,
    valence: 0.646,
    added_at: "2023-10-03T01:03:46Z",
    link: "https://open.spotify.com/track/2l57cfmCnOkwNX1tky02n1",
    userID: "royclaud",
    genres: "alternative metal,modern rock,neo mellow,neon pop punk,pop punk,pop rock,post-grunge",
  },
  {
    id: "7D7e6hm2LiNd6nLuJF6K9Q",
    name: "Hex",
    artists: "80purppp",
    duration_ms: 122221,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/d6bd61c427db597fef13bda5edfe0ec8699a5d17?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026c0177e9b0c53a7587979ca2",
    danceability: 0.845,
    energy: 0.398,
    valence: 0.482,
    added_at: "2023-09-27T21:13:07Z",
    link: "https://open.spotify.com/track/7D7e6hm2LiNd6nLuJF6K9Q",
    userID: "royclaud",
    genres: "bedroom r&b",
  },
  {
    id: "7M3HuQRRAm1QD4kftmq0bb",
    name: "Amour plastique",
    artists: "Videoclub, Adèle Castillon, Mattyeux",
    duration_ms: 227026,
    popularity: 2,
    preview_url: "https://p.scdn.co/mp3-preview/79a6f788cf29b7f246bc9b1f289ce64bad3bc7bb?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fb35bd51df43765ef89a13ce",
    danceability: 0.7,
    energy: 0.354,
    valence: 0.245,
    added_at: "2023-09-27T13:05:05Z",
    link: "https://open.spotify.com/track/7M3HuQRRAm1QD4kftmq0bb",
    userID: "royclaud",
    genres: "french indie pop,french synthpop",
  },
  {
    id: "4GiVcDqNQI0fc0yYuRGH9m",
    name: "Inside Out",
    artists: "Eve 6",
    duration_ms: 219466,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/f65978eadd74049f4bb668bf39466cac0ef0bc86?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02859d6607e67a7bea86f9e924",
    danceability: 0.483,
    energy: 0.818,
    valence: 0.691,
    added_at: "2023-09-25T18:53:27Z",
    link: "https://open.spotify.com/track/4GiVcDqNQI0fc0yYuRGH9m",
    userID: "royclaud",
    genres: "alternative metal,pop rock,post-grunge",
  },
  {
    id: "597qlcmqAPi6cNRNIg4cQv",
    name: "Warrior",
    artists: "AURORA",
    duration_ms: 223040,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/21e1ddb78b8af8f392548a8cacd1fd4bbb475738?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02009516bc4f16759f46ded073",
    danceability: 0.362,
    energy: 0.544,
    valence: 0.191,
    added_at: "2023-09-25T14:07:40Z",
    link: "https://open.spotify.com/track/597qlcmqAPi6cNRNIg4cQv",
    userID: "royclaud",
    genres: "art pop,norwegian pop",
  },
  {
    id: "6TSDRzJGwbK9cajVbtqlPV",
    name: "Que Sera",
    artists: "Wax Tailor",
    duration_ms: 164093,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/dedfcd98cde42845a90410f39e45ecd0e531155b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ba54ef939a9b21c14111d1c2",
    danceability: 0.714,
    energy: 0.721,
    valence: 0.415,
    added_at: "2023-09-25T01:45:43Z",
    link: "https://open.spotify.com/track/6TSDRzJGwbK9cajVbtqlPV",
    userID: "royclaud",
    genres: "electronica,hip-hop experimental,nu jazz,trip hop",
  },
  {
    id: "6Fba9RZtC6vTY814JToDtP",
    name: "Jump",
    artists: "Van Halen",
    duration_ms: 239560,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/0de45863fbce47a92c48a9f04ee9183dd9de1729?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0243de9079ae02773c1fae9a26",
    danceability: 0.542,
    energy: 0.822,
    valence: 0.772,
    added_at: "2023-09-19T22:42:09Z",
    link: "https://open.spotify.com/track/6Fba9RZtC6vTY814JToDtP",
    userID: "royclaud",
    genres: "album rock,classic rock,hard rock,rock",
  },
  {
    id: "5mjYQaktjmjcMKcUIcqz4s",
    name: "Strangers",
    artists: "Kenya Grace",
    duration_ms: 172964,
    popularity: 79,
    preview_url: "https://p.scdn.co/mp3-preview/f8e667575db490b61ccc4d0948a6be922cfe1c5e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024756c2e9ae436437cd75e9f3",
    danceability: 0.628,
    energy: 0.523,
    valence: 0.416,
    added_at: "2023-09-19T14:33:35Z",
    link: "https://open.spotify.com/track/5mjYQaktjmjcMKcUIcqz4s",
    userID: "royclaud",
    genres: "singer-songwriter pop",
  },
  {
    id: "6Ja1mjO9WcJFX3LsH22gRk",
    name: "Road To Zion",
    artists: "Damian Marley, Nas",
    duration_ms: 317333,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/e691fa1bdc0015abc6521539b78293f5964a4eb3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02569c9bd53261cc7313fbc7bd",
    danceability: 0.724,
    energy: 0.696,
    valence: 0.433,
    added_at: "2023-09-19T12:44:00Z",
    link: "https://open.spotify.com/track/6Ja1mjO9WcJFX3LsH22gRk",
    userID: "royclaud",
    genres: "reggae,reggae fusion,conscious hip hop,east coast hip hop,gangster rap,hardcore hip hop,hip hop,queens hip hop,rap",
  },
  {
    id: "0Xd3LUIjRESt5rSAGzvAvA",
    name: "Living It Up",
    artists: "Damian Marley",
    duration_ms: 240293,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/54783ad2bef3cc9675e2f7421516477dc6060400?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029c7e95338d25747070ac39c7",
    danceability: 0.782,
    energy: 0.687,
    valence: 0.802,
    added_at: "2023-09-19T12:43:02Z",
    link: "https://open.spotify.com/track/0Xd3LUIjRESt5rSAGzvAvA",
    userID: "royclaud",
    genres: "reggae,reggae fusion",
  },
  {
    id: "3kfhS2L56Wj8fBcu7xE47H",
    name: "Turks & Caicos (feat. 21 Savage)",
    artists: "Rod Wave, 21 Savage",
    duration_ms: 203345,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/f200e74af77bc4641c95959d244b9e28441d1eb1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bbf846cc2675b6baabc42000",
    danceability: 0.541,
    energy: 0.659,
    valence: 0.301,
    added_at: "2023-09-17T02:28:53Z",
    link: "https://open.spotify.com/track/3kfhS2L56Wj8fBcu7xE47H",
    userID: "royclaud",
    genres: "florida rap,atl hip hop,hip hop,rap",
  },
  {
    id: "2Lhdl74nwwVGOE2Gv35QuK",
    name: "Cupid's Chokehold / Breakfast in America",
    artists: "Gym Class Heroes",
    duration_ms: 243773,
    popularity: 75,
    preview_url: "https://p.scdn.co/mp3-preview/512899019cfc95a1faf8ddeaaa6a584c81200755?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f335d8387c3658831112f914",
    danceability: 0.72,
    energy: 0.744,
    valence: 0.619,
    added_at: "2023-09-10T21:57:12Z",
    link: "https://open.spotify.com/track/2Lhdl74nwwVGOE2Gv35QuK",
    userID: "royclaud",
    genres: "dance pop,pop rap",
  },
  {
    id: "08JrWr1reQL9UusNHzpaF9",
    name: "Slow dancing in the dark",
    artists: "Federica Meloni",
    duration_ms: 209490,
    popularity: 18,
    preview_url: "https://p.scdn.co/mp3-preview/fe927a7e38b831c6bc864e8913e61c0b9aaa3963?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0286c7a6ac73d4f8c342419d18",
    danceability: 0.289,
    energy: 0.123,
    valence: 0.081,
    added_at: "2023-09-06T16:57:59Z",
    link: "https://open.spotify.com/track/08JrWr1reQL9UusNHzpaF9",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "6JtdBQyO9qyY4HnWJY1mnn",
    name: "Red Handed",
    artists: "Malz Monday",
    duration_ms: 235066,
    popularity: 18,
    preview_url: "https://p.scdn.co/mp3-preview/456d250fb7b215ca39ff713dd3847ee25764a6f3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b43162e14e7d648545c10c9b",
    danceability: 0.54,
    energy: 0.704,
    valence: 0.372,
    added_at: "2023-09-04T17:22:51Z",
    link: "https://open.spotify.com/track/6JtdBQyO9qyY4HnWJY1mnn",
    userID: "royclaud",
    genres: "indie hip hop",
  },
  {
    id: "6BQrmjAgPfPTwILoFEK3Hd",
    name: "I Wish you Roses",
    artists: "Kali Uchis",
    duration_ms: 225947,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/53eeb0be8527a6cd6e0a9fa7e8f8c4505cdbb370?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f531e2357a930219f20849f6",
    danceability: 0.478,
    energy: 0.629,
    valence: 0.315,
    added_at: "2023-09-02T21:03:50Z",
    link: "https://open.spotify.com/track/6BQrmjAgPfPTwILoFEK3Hd",
    userID: "royclaud",
    genres: "colombian pop",
  },
  {
    id: "1h6w4POXM2LfG4ZD4xUfZQ",
    name: "The Hillbillies",
    artists: "Baby Keem, Kendrick Lamar",
    duration_ms: 204800,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/878c8cf55d1eef4bf4cbe230e0ee42275c91d531?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c4441140f4483bb1c18fe7ae",
    danceability: 0.798,
    energy: 0.618,
    valence: 0.209,
    added_at: "2023-09-01T21:42:58Z",
    link: "https://open.spotify.com/track/1h6w4POXM2LfG4ZD4xUfZQ",
    userID: "royclaud",
    genres: "hip hop,rap,conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "79XrkTOfV1AqySNjVlygpW",
    name: "Energy",
    artists: "Drake",
    duration_ms: 181933,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/0d3414f734e3bb1f205dd5948b4c79cbe5d04a6c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022090f4f6cc406e6d3c306733",
    danceability: 0.747,
    energy: 0.571,
    valence: 0.553,
    added_at: "2023-09-01T18:48:54Z",
    link: "https://open.spotify.com/track/79XrkTOfV1AqySNjVlygpW",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "5oeOWXjH8NZFOWP0SpSXqV",
    name: "Chamber Of Reflection",
    artists: "Mac DeMarco",
    duration_ms: 231720,
    popularity: 27,
    preview_url: "https://p.scdn.co/mp3-preview/17a0b215c5cab8a53047fd6aa5c5dd54507efaec?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d29a6b42f592c433ee31104d",
    danceability: 0.535,
    energy: 0.554,
    valence: 0.512,
    added_at: "2023-09-01T18:39:38Z",
    link: "https://open.spotify.com/track/5oeOWXjH8NZFOWP0SpSXqV",
    userID: "royclaud",
    genres: "edmonton indie,lo-fi indie,pov: indie,slacker rock",
  },
  {
    id: "5xhQChGGhKLWqBqX4XhtYE",
    name: "Sail",
    artists: "AWOLNATION",
    duration_ms: 259102,
    popularity: 16,
    preview_url: "https://p.scdn.co/mp3-preview/f14574baf46e2fb574059f16f9d4e94774bd00b3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025a41b6c530b167ffba6a92f5",
    danceability: 0.825,
    energy: 0.435,
    valence: 0.243,
    added_at: "2023-09-01T18:38:57Z",
    link: "https://open.spotify.com/track/5xhQChGGhKLWqBqX4XhtYE",
    userID: "royclaud",
    genres: "la indie,modern alternative rock,modern rock,rock,stomp pop",
  },
  {
    id: "48X4D1FYOShPz2VF3YdfCF",
    name: "House Of Cards",
    artists: "Radiohead",
    duration_ms: 328293,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/327f4de399cb68a852a312462c4849cee881ebc6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02de3c04b5fc750b68899b20a9",
    danceability: 0.708,
    energy: 0.499,
    valence: 0.628,
    added_at: "2023-08-31T22:43:19Z",
    link: "https://open.spotify.com/track/48X4D1FYOShPz2VF3YdfCF",
    userID: "royclaud",
    genres: "alternative rock,art rock,melancholia,oxford indie,permanent wave,rock",
  },
  {
    id: "6SwRhMLwNqEi6alNPVG00n",
    name: "LOYALTY. FEAT. RIHANNA.",
    artists: "Kendrick Lamar, Rihanna",
    duration_ms: 227360,
    popularity: 76,
    preview_url: "https://p.scdn.co/mp3-preview/e7b22d623a262cf9abe86f7ad27a286e94518d7c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028b52c6b9bc4e43d873869699",
    danceability: 0.658,
    energy: 0.535,
    valence: 0.419,
    added_at: "2023-08-30T21:49:09Z",
    link: "https://open.spotify.com/track/6SwRhMLwNqEi6alNPVG00n",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap,barbadian pop,pop,urban contemporary",
  },
  {
    id: "4J5VFtuLhbgKhz6UKkABXR",
    name: "I'll Be You - 2008 Remaster",
    artists: "The Replacements",
    duration_ms: 209386,
    popularity: 37,
    preview_url: "https://p.scdn.co/mp3-preview/a2d217e9bdbceaa084d0845d61d2b05075899c32?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bac053b8dd859eb42c4a9b12",
    danceability: 0.591,
    energy: 0.86,
    valence: 0.6,
    added_at: "2023-08-30T13:46:20Z",
    link: "https://open.spotify.com/track/4J5VFtuLhbgKhz6UKkABXR",
    userID: "royclaud",
    genres: "alternative rock,permanent wave,post-punk,power pop,punk,singer-songwriter",
  },
  {
    id: "3R3HY6mOyGwxJ4dxGgg0Do",
    name: "OJOS AZULES",
    artists: "Blessd, Peso Pluma, SOG",
    duration_ms: 188571,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/45c51db15516eb6a5b562f4a47b6518340336343?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020bcfe30a8ac5fee4463fc6f1",
    danceability: 0.904,
    energy: 0.814,
    valence: 0.803,
    added_at: "2023-08-29T03:04:08Z",
    link: "https://open.spotify.com/track/3R3HY6mOyGwxJ4dxGgg0Do",
    userID: "royclaud",
    genres: "reggaeton,urbano latino,corridos tumbados,sad sierreno,reggaeton colombiano",
  },
  {
    id: "4S4Mfvv03M1cHgIOJcbUCL",
    name: "In My Room",
    artists: "Frank Ocean",
    duration_ms: 133680,
    popularity: 73,
    preview_url: "https://p.scdn.co/mp3-preview/a23f4faaefc0b1105bd70e3e46189bcf930dcd42?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02db974f9533dd9b362891b5db",
    danceability: 0.861,
    energy: 0.647,
    valence: 0.67,
    added_at: "2023-08-27T22:56:55Z",
    link: "https://open.spotify.com/track/4S4Mfvv03M1cHgIOJcbUCL",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "5bcb7UnSeKIfGNeNmxOG2L",
    name: "By Chance (feat. 21 Savage)",
    artists: "Giggs, 21 Savage",
    duration_ms: 202125,
    popularity: 37,
    preview_url: "https://p.scdn.co/mp3-preview/1abc8a03e74b95e727fefd367bc90aa9a0f58709?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021719a4fd31ecdf60eeccf227",
    danceability: 0.868,
    energy: 0.7,
    valence: 0.727,
    added_at: "2023-08-25T17:13:50Z",
    link: "https://open.spotify.com/track/5bcb7UnSeKIfGNeNmxOG2L",
    userID: "royclaud",
    genres: "grime,london rap,uk hip hop,atl hip hop,hip hop,rap",
  },
  {
    id: "2ukzJBTilioEVW34gL6b77",
    name: "Tetris (feat. Russ) - Remix",
    artists: "Derek King, Russ",
    duration_ms: 232803,
    popularity: 33,
    preview_url: "https://p.scdn.co/mp3-preview/877011f9b4cc18d8401b5d6e8006ad1024a890ce?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028ff1b7a3db588506ee7f0274",
    danceability: 0.764,
    energy: 0.619,
    valence: 0.145,
    added_at: "2023-08-25T16:06:40Z",
    link: "https://open.spotify.com/track/2ukzJBTilioEVW34gL6b77",
    userID: "royclaud",
    genres: "pop r&b,slow game,hawaiian hip hop,pop rap",
  },
  {
    id: "11o9dSwPFk66I8qUEvk8Aj",
    name: "Bobby Boucher",
    artists: "EARTHGANG, Benji., Spillage Village",
    duration_ms: 195200,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/baff3152a6af7c45075f29700a14e78fe09f7870?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02380415bb1860b5be8c6dcfe2",
    danceability: 0.868,
    energy: 0.675,
    valence: 0.366,
    added_at: "2023-08-25T15:58:59Z",
    link: "https://open.spotify.com/track/11o9dSwPFk66I8qUEvk8Aj",
    userID: "royclaud",
    genres: "atl hip hop,indie hip hop,psychedelic hip hop,rap,underground hip hop,pittsburgh rap,underground hip hop",
  },
  {
    id: "2OZfJcpzSFApBxUA0gXuJz",
    name: "Common Ground",
    artists: "Jack Harlow",
    duration_ms: 100424,
    popularity: 52,
    preview_url: "https://p.scdn.co/mp3-preview/10ea64b7bb83d22d93e806ab5033d8b88a0c51f6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ef51095309fee985f2bc6987",
    danceability: 0.807,
    energy: 0.681,
    valence: 0.571,
    added_at: "2023-08-25T14:38:02Z",
    link: "https://open.spotify.com/track/2OZfJcpzSFApBxUA0gXuJz",
    userID: "royclaud",
    genres: "deep underground hip hop,hip hop,kentucky hip hop,pop rap,rap",
  },
  {
    id: "1dDuMb4GQlB6ZRNmkRzG5M",
    name: "Tunnel Vision",
    artists: "Russ, Bibi Bourelly",
    duration_ms: 218162,
    popularity: 44,
    preview_url: "https://p.scdn.co/mp3-preview/855870bb4f41dd4106d376f4f303b149dd696eba?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02363ad7da8b4200875ced8e08",
    danceability: 0.67,
    energy: 0.651,
    valence: 0.302,
    added_at: "2023-08-25T14:36:04Z",
    link: "https://open.spotify.com/track/1dDuMb4GQlB6ZRNmkRzG5M",
    userID: "royclaud",
    genres: "hawaiian hip hop,pop rap",
  },
  {
    id: "4Bgh6Uv9851EcAMH8IRirR",
    name: "Cheerleader",
    artists: "Ashnikko",
    duration_ms: 132818,
    popularity: 45,
    preview_url: "https://p.scdn.co/mp3-preview/c84f928f3d94452767d861b1b2e5f79ad6f77f37?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02327d7f6490b677fcf02bb426",
    danceability: 0.644,
    energy: 0.837,
    valence: 0.311,
    added_at: "2023-08-24T17:31:17Z",
    link: "https://open.spotify.com/track/4Bgh6Uv9851EcAMH8IRirR",
    userID: "royclaud",
    genres: "alt z,escape room",
  },
  {
    id: "2mdEsXPu8ZmkHRRtAdC09e",
    name: "Cinnamon Girl",
    artists: "Lana Del Rey",
    duration_ms: 300683,
    popularity: 82,
    preview_url: "https://p.scdn.co/mp3-preview/e77692e884a30ed4d1ce3df27e408066e37c315b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02879e9318cb9f4e05ee552ac9",
    danceability: 0.294,
    energy: 0.337,
    valence: 0.135,
    added_at: "2023-08-24T17:28:38Z",
    link: "https://open.spotify.com/track/2mdEsXPu8ZmkHRRtAdC09e",
    userID: "royclaud",
    genres: "art pop,pop",
  },
  {
    id: "3RDcUlLGp3SLp2AmUbUbls",
    name: "Cookie Jar (feat. The-Dream)",
    artists: "Gym Class Heroes, The-Dream",
    duration_ms: 216317,
    popularity: 52,
    preview_url: "https://p.scdn.co/mp3-preview/f07be8b07f26c8e1fd25c6ef2405eb0df8b811a0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021f82ceb61ed03094ddc25a50",
    danceability: 0.687,
    energy: 0.668,
    valence: 0.418,
    added_at: "2023-08-24T17:28:00Z",
    link: "https://open.spotify.com/track/3RDcUlLGp3SLp2AmUbUbls",
    userID: "royclaud",
    genres: "dance pop,pop rap,dirty south rap,hip pop,r&b,southern hip hop,trap,urban contemporary",
  },
  {
    id: "2wiXEarKt55K8aW8elRqDW",
    name: "Carmen",
    artists: "Lana Del Rey",
    duration_ms: 248720,
    popularity: 52,
    preview_url: "https://p.scdn.co/mp3-preview/52f5c3e865aad4bb6fbfabe95284566594803153?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02cb76604d9c5963544cf5be64",
    danceability: 0.524,
    energy: 0.427,
    valence: 0.0379,
    added_at: "2023-08-24T17:27:24Z",
    link: "https://open.spotify.com/track/2wiXEarKt55K8aW8elRqDW",
    userID: "royclaud",
    genres: "art pop,pop",
  },
  {
    id: "1BxfuPKGuaTgP7aM0Bbdwr",
    name: "Cruel Summer",
    artists: "Taylor Swift",
    duration_ms: 178426,
    popularity: 91,
    preview_url: "https://p.scdn.co/mp3-preview/dba15da5409f3c808022cf927c0ff8581f717aa4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e787cffec20aa2a396a61647",
    danceability: 0.552,
    energy: 0.702,
    valence: 0.564,
    added_at: "2023-08-23T18:56:45Z",
    link: "https://open.spotify.com/track/1BxfuPKGuaTgP7aM0Bbdwr",
    userID: "royclaud",
    genres: "pop",
  },
  {
    id: "7x9aauaA9cu6tyfpHnqDLo",
    name: "Seven (feat. Latto)",
    artists: "Jung Kook, Latto",
    duration_ms: 184400,
    popularity: 82,
    preview_url: "https://p.scdn.co/mp3-preview/2f2b3f4eaf405ac3ba9792456cec93ae88942cfc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bf5cce5a0e1ed03a626bdd74",
    danceability: 0.788,
    energy: 0.841,
    valence: 0.904,
    added_at: "2023-08-23T18:53:41Z",
    link: "https://open.spotify.com/track/7x9aauaA9cu6tyfpHnqDLo",
    userID: "royclaud",
    genres: "k-pop,trap queen",
  },
  {
    id: "6Fe3Flc9SjE03pqwD6PVQl",
    name: "Amber",
    artists: "311",
    duration_ms: 206973,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/378d3264f5ecee54b813a17319d582bbe26c8061?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f5688d5a9e47a027a36c33fe",
    danceability: 0.663,
    energy: 0.697,
    valence: 0.744,
    added_at: "2023-08-22T14:11:33Z",
    link: "https://open.spotify.com/track/6Fe3Flc9SjE03pqwD6PVQl",
    userID: "royclaud",
    genres: "alternative metal,alternative rock,funk metal,funk rock,nu metal,pop rock,rap rock,reggae fusion",
  },
  {
    id: "29VdNzgJIxzlIDkIYjFQqS",
    name: "Life Letters",
    artists: "Never Get Used To People",
    duration_ms: 148040,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/743b8451325338e18b8d635f56b7e8e5168ba5c8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02363c9fa3f1a3e220b2167aa3",
    danceability: 0.441,
    energy: 0.692,
    valence: 0.417,
    added_at: "2023-08-22T13:50:03Z",
    link: "https://open.spotify.com/track/29VdNzgJIxzlIDkIYjFQqS",
    userID: "royclaud",
    genres: "weirdcore",
  },
  {
    id: "7iRr7nTViHVQIaDvK3UquS",
    name: "Gon Die (feat. Stevie Ross)",
    artists: "Merkules, Stevie Ross",
    duration_ms: 300962,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/4f1ed891a49accb1021fde10a612402f262fc44d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024e7c8cb716f3efa0a19afb58",
    danceability: 0.817,
    energy: 0.608,
    valence: 0.406,
    added_at: "2023-08-21T19:33:50Z",
    link: "https://open.spotify.com/track/7iRr7nTViHVQIaDvK3UquS",
    userID: "royclaud",
    genres: "bc underground hip hop,canadian hip hop,bc underground hip hop",
  },
  {
    id: "2IGMVunIBsBLtEQyoI1Mu7",
    name: "Paint The Town Red",
    artists: "Doja Cat",
    duration_ms: 231750,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/1d4dc1cd67ba78afec8cfb3e5258a9ab9b707dd8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027acee948ecac8380c1b6ce30",
    danceability: 0.868,
    energy: 0.538,
    valence: 0.732,
    added_at: "2023-08-21T19:30:24Z",
    link: "https://open.spotify.com/track/2IGMVunIBsBLtEQyoI1Mu7",
    userID: "royclaud",
    genres: "dance pop,pop",
  },
  {
    id: "0phjwOr5GM0hea7Ju5IDtN",
    name: "Los Angelenos",
    artists: "Billy Joel",
    duration_ms: 221773,
    popularity: 30,
    preview_url: "https://p.scdn.co/mp3-preview/3a8605b8d13728cb7bb4db61b77307a38b050041?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c709d60c4fa67bb4f28a1a59",
    danceability: 0.695,
    energy: 0.842,
    valence: 0.752,
    added_at: "2023-08-21T13:48:16Z",
    link: "https://open.spotify.com/track/0phjwOr5GM0hea7Ju5IDtN",
    userID: "royclaud",
    genres: "album rock,classic rock,mellow gold,piano rock,rock,singer-songwriter,soft rock",
  },
  {
    id: "6jgkEbmQ2F2onEqsEhiliL",
    name: "My Kind of Woman",
    artists: "Mac DeMarco",
    duration_ms: 190845,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/84a0a0a0a484047ef91d1841090bb04182e83692?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029d377496c6bc8724b521222d",
    danceability: 0.501,
    energy: 0.765,
    valence: 0.46,
    added_at: "2023-08-19T21:48:14Z",
    link: "https://open.spotify.com/track/6jgkEbmQ2F2onEqsEhiliL",
    userID: "royclaud",
    genres: "edmonton indie,lo-fi indie,pov: indie,slacker rock",
  },
  {
    id: "7bPWdJgx8vek7S5i5yAtvG",
    name: "Chemtrails Over The Country Club",
    artists: "Lana Del Rey",
    duration_ms: 271176,
    popularity: 79,
    preview_url: "https://p.scdn.co/mp3-preview/5d82428633150c38abe54878fcf4f1541cc9bc29?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ca929c6e766cb8591a286e0d",
    danceability: 0.334,
    energy: 0.276,
    valence: 0.429,
    added_at: "2023-08-19T21:48:12Z",
    link: "https://open.spotify.com/track/7bPWdJgx8vek7S5i5yAtvG",
    userID: "royclaud",
    genres: "art pop,pop",
  },
  {
    id: "6unndO70DvZfnXYcYQMyQJ",
    name: "Never Felt So Alone",
    artists: "Labrinth",
    duration_ms: 160166,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/d33db796d822aa77728af0f4abb06ccd596d2920?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021df535f5089e544a3cc86069",
    danceability: 0.436,
    energy: 0.409,
    valence: 0.363,
    added_at: "2023-08-19T21:48:11Z",
    link: "https://open.spotify.com/track/6unndO70DvZfnXYcYQMyQJ",
    userID: "royclaud",
    genres: "indie poptimism,pop",
  },
  {
    id: "5gDWsRxpJ2lZAffh5p7K0w",
    name: "Stargirl Interlude",
    artists: "The Weeknd, Lana Del Rey",
    duration_ms: 111626,
    popularity: 83,
    preview_url: "https://p.scdn.co/mp3-preview/7b75851afda8cdbcc8078c6cc68ff2d61f5957e6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a048415db06a5b6fa7ec4e1a",
    danceability: 0.715,
    energy: 0.47,
    valence: 0.426,
    added_at: "2023-08-19T21:48:09Z",
    link: "https://open.spotify.com/track/5gDWsRxpJ2lZAffh5p7K0w",
    userID: "royclaud",
    genres: "canadian contemporary r&b,canadian pop,pop,art pop,pop",
  },
  {
    id: "6pZ9Nj3vzj1RW4ZkR9GcQ9",
    name: "Living Legend",
    artists: "Lana Del Rey",
    duration_ms: 241426,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/67e135d8298430fbb95364009e83c28955f05cd7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026946deb6f548e464b385ee0e",
    danceability: 0.402,
    energy: 0.219,
    valence: 0.166,
    added_at: "2023-08-19T21:48:08Z",
    link: "https://open.spotify.com/track/6pZ9Nj3vzj1RW4ZkR9GcQ9",
    userID: "royclaud",
    genres: "art pop,pop",
  },
  {
    id: "4grUGQeAfNs6yQOdgEkncM",
    name: "Raise Your Weapon",
    artists: "deadmau5",
    duration_ms: 502909,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/5705a8bdb9b46ee07d1e2d1b68bfc1b6b20c28f7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02879f70b2bc0d498c0f3c9e97",
    danceability: 0.507,
    energy: 0.699,
    valence: 0.0744,
    added_at: "2023-08-18T19:43:14Z",
    link: "https://open.spotify.com/track/4grUGQeAfNs6yQOdgEkncM",
    userID: "royclaud",
    genres: "canadian electronic,complextro,edm,electro house,pop dance,progressive electro house,progressive house",
  },
  {
    id: "31nfdEooLEq7dn3UMcIeB5",
    name: "Vois sur ton chemin - Techno Mix",
    artists: "BENNETT",
    duration_ms: 178156,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/3354afe4296d2b889d36965804d414c5c2b3412b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021b96e645016c4d431842aa93",
    danceability: 0.634,
    energy: 0.824,
    valence: 0.371,
    added_at: "2023-08-17T13:34:49Z",
    link: "https://open.spotify.com/track/31nfdEooLEq7dn3UMcIeB5",
    userID: "royclaud",
    genres: "hypertechno",
  },
  {
    id: "7D0RhFcb3CrfPuTJ0obrod",
    name: "Sparks",
    artists: "Coldplay",
    duration_ms: 227093,
    popularity: 85,
    preview_url: "https://p.scdn.co/mp3-preview/4ffce9684e191005c15ab6ce31540fcec78eaacf?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029164bafe9aaa168d93f4816a",
    danceability: 0.371,
    energy: 0.268,
    valence: 0.165,
    added_at: "2023-08-17T03:07:03Z",
    link: "https://open.spotify.com/track/7D0RhFcb3CrfPuTJ0obrod",
    userID: "royclaud",
    genres: "permanent wave,pop",
  },
  {
    id: "7IQDEmE7TaADP1EmlPxv3T",
    name: "Strobe",
    artists: "deadmau5",
    duration_ms: 637080,
    popularity: 47,
    preview_url: "https://p.scdn.co/mp3-preview/ca8c59a46634f7fb07cf7970da8a99055edb5be3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0272123fd7f792d5d197d46e34",
    danceability: 0.437,
    energy: 0.342,
    valence: 0.0348,
    added_at: "2023-08-16T19:52:50Z",
    link: "https://open.spotify.com/track/7IQDEmE7TaADP1EmlPxv3T",
    userID: "royclaud",
    genres: "canadian electronic,complextro,edm,electro house,pop dance,progressive electro house,progressive house",
  },
  {
    id: "2JP1FK1XbgU95SaGBDE6sy",
    name: "My Final Symphony",
    artists: "Layto",
    duration_ms: 140629,
    popularity: 41,
    preview_url: "https://p.scdn.co/mp3-preview/3f9255ab5e3cb9366e18e4e9a22fb17dd568aa54?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0242996b7a3011bb4034b8d016",
    danceability: 0.731,
    energy: 0.524,
    valence: 0.552,
    added_at: "2023-08-16T16:52:44Z",
    link: "https://open.spotify.com/track/2JP1FK1XbgU95SaGBDE6sy",
    userID: "royclaud",
    genres: "dark r&b",
  },
  {
    id: "7FBRbot28s840lOggjH9l6",
    name: "Rocketship",
    artists: "Perrin Xthona, Maria Landi",
    duration_ms: 125289,
    popularity: 34,
    preview_url: "https://p.scdn.co/mp3-preview/07a350cb0ea8a8989804d7cb61716ce75f9f79b9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028b3b765e4e642815b98f0c55",
    danceability: 0.681,
    energy: 0.504,
    valence: 0.576,
    added_at: "2023-08-16T16:52:41Z",
    link: "https://open.spotify.com/track/7FBRbot28s840lOggjH9l6",
    userID: "royclaud",
    genres: "dark r&b",
  },
  {
    id: "3RcoIYZ9HOJ8nhKLRV9c4e",
    name: "Feel Real Pretty",
    artists: "Paper Idol",
    duration_ms: 183127,
    popularity: 41,
    preview_url: "https://p.scdn.co/mp3-preview/f0af73a129cdb59464d44b3c3e616b320e118239?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fd14012a1f28b8aa13a6f35c",
    danceability: 0.69,
    energy: 0.834,
    valence: 0.312,
    added_at: "2023-08-16T16:52:36Z",
    link: "https://open.spotify.com/track/3RcoIYZ9HOJ8nhKLRV9c4e",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "16Azhyv8164hYxI8Ax3F5k",
    name: "Blur",
    artists: "Stellar",
    duration_ms: 131428,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/153fb800b8d41393bedaa2ce87537598555e2eb9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a575bce980100d4b35227f54",
    danceability: 0.834,
    energy: 0.6,
    valence: 0.694,
    added_at: "2023-08-16T16:45:51Z",
    link: "https://open.spotify.com/track/16Azhyv8164hYxI8Ax3F5k",
    userID: "royclaud",
    genres: "social media pop",
  },
  {
    id: "5iDaEbPX8Lq2evx3ZpAgQU",
    name: "A Little Messed Up",
    artists: "june",
    duration_ms: 168000,
    popularity: 50,
    preview_url: "https://p.scdn.co/mp3-preview/265c56c6904456b0466eafa44f86243c4f6adece?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a5ccc769c8fbc837c44af9e3",
    danceability: 0.773,
    energy: 0.528,
    valence: 0.363,
    added_at: "2023-08-16T16:12:50Z",
    link: "https://open.spotify.com/track/5iDaEbPX8Lq2evx3ZpAgQU",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "7ImgIZf2vHCohO7YtIzgdY",
    name: "Ya Perdí la Cuenta",
    artists: "Adán Cruz, Samtwenty",
    duration_ms: 219069,
    popularity: 25,
    preview_url: "https://p.scdn.co/mp3-preview/ad0f72c65227fd35ec402c1834f27533be3ca125?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fa9d4bafd61f77f8894555ea",
    danceability: 0.719,
    energy: 0.561,
    valence: 0.379,
    added_at: "2023-08-13T17:35:31Z",
    link: "https://open.spotify.com/track/7ImgIZf2vHCohO7YtIzgdY",
    userID: "royclaud",
    genres: "trap mexicano",
  },
  {
    id: "3SsJ17EnPIu1B4GZshqjIS",
    name: "Feel The Fiyaaaah (with A$AP Rocky & feat. Takeoff)",
    artists: "Metro Boomin, A$AP Rocky, Takeoff",
    duration_ms: 189426,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/10b03077a8864c13fe9fee59ae41266aa4d870c7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c4fee55d7b51479627c31f89",
    danceability: 0.498,
    energy: 0.768,
    valence: 0.387,
    added_at: "2023-08-13T16:14:25Z",
    link: "https://open.spotify.com/track/3SsJ17EnPIu1B4GZshqjIS",
    userID: "royclaud",
    genres: "rap,east coast hip hop,hip hop,rap,atl hip hop,rap,trap",
  },
  {
    id: "3UNoPBNWST9mQleI5T53zL",
    name: "Smile",
    artists: "Isaiah Rashad",
    duration_ms: 203371,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/72575f1083ff33f703e2021d76961f97a28cab25?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020ecc57876382e8ce575ac07b",
    danceability: 0.783,
    energy: 0.563,
    valence: 0.517,
    added_at: "2023-08-13T16:12:07Z",
    link: "https://open.spotify.com/track/3UNoPBNWST9mQleI5T53zL",
    userID: "royclaud",
    genres: "hip hop,rap,tennessee hip hop,underground hip hop",
  },
  {
    id: "3oXsp44OaIdNIsFCqiGkDw",
    name: "Mandem",
    artists: "Giggs, Diddy",
    duration_ms: 241567,
    popularity: 33,
    preview_url: "https://p.scdn.co/mp3-preview/d7f31bba0fcab08baeecdfde2a77d14545f3eebc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c00e020fefd4aa9778b89440",
    danceability: 0.771,
    energy: 0.711,
    valence: 0.564,
    added_at: "2023-08-09T18:46:46Z",
    link: "https://open.spotify.com/track/3oXsp44OaIdNIsFCqiGkDw",
    userID: "royclaud",
    genres: "grime,london rap,uk hip hop,dance pop,east coast hip hop,hip hop,hip pop,pop rap,rap",
  },
  {
    id: "5DLTuH4A5VZB2HKpqHQR6t",
    name: "Patient",
    artists: "Post Malone",
    duration_ms: 194333,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/63240c1bba078c6d276d58036ebd878f76db7f5f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0255404f712deb84d0650a4b41",
    danceability: 0.743,
    energy: 0.662,
    valence: 0.552,
    added_at: "2023-08-07T14:12:05Z",
    link: "https://open.spotify.com/track/5DLTuH4A5VZB2HKpqHQR6t",
    userID: "royclaud",
    genres: "dfw rap,melodic rap,pop,rap",
  },
  {
    id: "5z4Yope4ol84bdZtw1Eo4D",
    name: "It's Only Us",
    artists: "Monophonics, Kelly Finnigan",
    duration_ms: 298973,
    popularity: 44,
    preview_url: "https://p.scdn.co/mp3-preview/030b13ece520efe6b0a306e2ca7205ae62251013?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020370215a89cb24645f49c2fa",
    danceability: 0.477,
    energy: 0.615,
    valence: 0.472,
    added_at: "2023-08-07T02:57:02Z",
    link: "https://open.spotify.com/track/5z4Yope4ol84bdZtw1Eo4D",
    userID: "royclaud",
    genres: "bay area indie,instrumental funk,psychedelic soul,neo soul,souldies",
  },
  {
    id: "0J1YRLYhTG1MBosjidD7OI",
    name: "Good Good",
    artists: "USHER, Summer Walker, 21 Savage",
    duration_ms: 247100,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/5c4da4a4cf896268e243f545f81575986c9d2948?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020a5b9c6778c775710b20cb44",
    danceability: 0.744,
    energy: 0.677,
    valence: 0.57,
    added_at: "2023-08-06T15:26:31Z",
    link: "https://open.spotify.com/track/0J1YRLYhTG1MBosjidD7OI",
    userID: "royclaud",
    genres: "atl hip hop,contemporary r&b,dance pop,pop,r&b,rap,south carolina hip hop,urban contemporary,r&b,rap,atl hip hop,hip hop,rap",
  },
  {
    id: "3AksCpZYdbBmm7fl9YzE0l",
    name: "What I Want",
    artists: "Aintfullyhere",
    duration_ms: 324455,
    popularity: 24,
    preview_url: "https://p.scdn.co/mp3-preview/26cf839acd44eef6f22029a85b8f8b19db6b3b2a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023ac4be466ecd14e32b1daf0b",
    danceability: 0.695,
    energy: 0.414,
    valence: 0.19,
    added_at: "2023-08-06T14:49:24Z",
    link: "https://open.spotify.com/track/3AksCpZYdbBmm7fl9YzE0l",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "2OKo7g3KfmCt3kyLvUAL0g",
    name: "The Search",
    artists: "NF",
    duration_ms: 248040,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/c000440d4208ed69f0d8ba31f6fa469f13a90327?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d30db7be0da60046d5f0d936",
    danceability: 0.789,
    energy: 0.786,
    valence: 0.39,
    added_at: "2023-08-05T16:25:56Z",
    link: "https://open.spotify.com/track/2OKo7g3KfmCt3kyLvUAL0g",
    userID: "royclaud",
    genres: "hip hop,pop rap",
  },
  {
    id: "3tBZ60j1jQ7NJm8IjelyQe",
    name: "emo girl (feat. WILLOW)",
    artists: "mgk, WILLOW",
    duration_ms: 159096,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/982e70a62469af10f21025f00b7372a9ae3ff301?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02da3769a95d849abbb4d73573",
    danceability: 0.412,
    energy: 0.881,
    valence: 0.359,
    added_at: "2023-08-05T16:13:13Z",
    link: "https://open.spotify.com/track/3tBZ60j1jQ7NJm8IjelyQe",
    userID: "royclaud",
    genres: "ohio hip hop,pop rap,afrofuturism,pop,post-teen pop,pov: indie",
  },
  {
    id: "7i9763l5SSfOnqZ35VOcfy",
    name: "Heavydirtysoul",
    artists: "Twenty One Pilots",
    duration_ms: 234813,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/1450b6e8ea0a5d0af01e132a7ab70d9016f6ae4d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022df0d98a423025032d0db1f7",
    danceability: 0.613,
    energy: 0.873,
    valence: 0.392,
    added_at: "2023-08-05T14:48:21Z",
    link: "https://open.spotify.com/track/7i9763l5SSfOnqZ35VOcfy",
    userID: "royclaud",
    genres: "modern rock,pop,pov: indie,rock",
  },
  {
    id: "1FyLgAmsBnZd72jbZlhGFK",
    name: "Deep Red Sea",
    artists: "Youth Lagoon",
    duration_ms: 204081,
    popularity: 26,
    preview_url: "https://p.scdn.co/mp3-preview/bc47b43fc85923588eda42452d4638596f205972?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02996c742c8d2ffde0b06ca1d1",
    danceability: 0.73,
    energy: 0.373,
    valence: 0.377,
    added_at: "2023-08-04T19:59:49Z",
    link: "https://open.spotify.com/track/1FyLgAmsBnZd72jbZlhGFK",
    userID: "royclaud",
    genres: "chamber pop,chillwave,dream pop,experimental pop,idaho indie,indie rock,indietronica,shimmer pop,stomp and holler",
  },
  {
    id: "0doTgRQa7qyw5JYPfwhPcK",
    name: "Crush - Stripped",
    artists: "Ethel Cain",
    duration_ms: 229565,
    popularity: 45,
    preview_url: "https://p.scdn.co/mp3-preview/21f7fb70f3d727983f1ff9fb67e6034304b09537?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0279da5369d7ff28d70bbd70f3",
    danceability: 0.279,
    energy: 0.342,
    valence: 0.348,
    added_at: "2023-08-04T16:00:51Z",
    link: "https://open.spotify.com/track/0doTgRQa7qyw5JYPfwhPcK",
    userID: "royclaud",
    genres: "countrygaze,indie pop,modern dream pop",
  },
  {
    id: "7HdNB8nvJOBwHa8hIkzvxp",
    name: "If I'm Lyin', I'm Flyin'",
    artists: "Kodak Black",
    duration_ms: 130929,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/db5916bbf397552ffb597e4a2eb9a72f12e0da94?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f9508eb3070b95ceeb82788b",
    danceability: 0.715,
    energy: 0.617,
    valence: 0.283,
    added_at: "2023-08-04T01:09:13Z",
    link: "https://open.spotify.com/track/7HdNB8nvJOBwHa8hIkzvxp",
    userID: "royclaud",
    genres: "florida drill,florida rap,melodic rap,miami hip hop,rap,trap,trap latino",
  },
  {
    id: "6HgWWaMu31KdOpEG5l28BG",
    name: "All My Life (feat. J. Cole)",
    artists: "Lil Durk, J. Cole",
    duration_ms: 223878,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/736c0ff37c1b6b7ca182ea6e78db36163e5e6eae?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027c173b0dc64913d4635e3594",
    danceability: 0.787,
    energy: 0.57,
    valence: 0.733,
    added_at: "2023-08-04T00:47:22Z",
    link: "https://open.spotify.com/track/6HgWWaMu31KdOpEG5l28BG",
    userID: "royclaud",
    genres: "chicago drill,chicago rap,drill,hip hop,pop rap,rap,trap,conscious hip hop,hip hop,north carolina hip hop,rap",
  },
  {
    id: "2AhulDUxnCyH5KogjFU4C6",
    name: "Fancy",
    artists: "Godlike Pariah",
    duration_ms: 170344,
    popularity: 25,
    preview_url: "null",
    image: "https://i.scdn.co/image/ab67616d00001e02a5bd117b1ad5ce7cf47f6dca",
    danceability: 0.712,
    energy: 0.46,
    valence: 0.261,
    added_at: "2023-08-04T00:07:12Z",
    link: "https://open.spotify.com/track/2AhulDUxnCyH5KogjFU4C6",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "5veJDT0MLsLbhYsx42GXUD",
    name: "Don't Stand So Close To Me",
    artists: "The Police",
    duration_ms: 242666,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/33fb1e343adb34e507be23ebcfd5f10f9c98d38b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fb1bc65edf4717e75fbc70ab",
    danceability: 0.799,
    energy: 0.506,
    valence: 0.518,
    added_at: "2023-08-03T22:59:36Z",
    link: "https://open.spotify.com/track/5veJDT0MLsLbhYsx42GXUD",
    userID: "royclaud",
    genres: "album rock,classic rock,permanent wave,rock",
  },
  {
    id: "1i9lZvlaDdWDPyXEE95aiq",
    name: "TELEKINESIS (feat. SZA & Future)",
    artists: "Travis Scott, SZA, Future",
    duration_ms: 353754,
    popularity: 79,
    preview_url: "https://p.scdn.co/mp3-preview/044ceba2a9451325fc9bf007447a2bdc333123b9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02881d8d8378cd01099babcd44",
    danceability: 0.458,
    energy: 0.535,
    valence: 0.216,
    added_at: "2023-08-03T02:27:08Z",
    link: "https://open.spotify.com/track/1i9lZvlaDdWDPyXEE95aiq",
    userID: "royclaud",
    genres: "rap,slap house,pop,r&b,rap,atl hip hop,hip hop,rap,southern hip hop,trap",
  },
  {
    id: "2BJ46GRF6szcLoimU71cYh",
    name: "I Am the Void",
    artists: "Entheos",
    duration_ms: 233798,
    popularity: 18,
    preview_url: "https://p.scdn.co/mp3-preview/7cf45191fb1c283d93e0b61c18db648c1fd0c182?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02553a097a16e17c59a5f320ae",
    danceability: 0.525,
    energy: 0.915,
    valence: 0.199,
    added_at: "2023-08-02T17:09:56Z",
    link: "https://open.spotify.com/track/2BJ46GRF6szcLoimU71cYh",
    userID: "royclaud",
    genres: "progressive death metal,slayer",
  },
  {
    id: "0rFdjHoldrbHIBeUIjxi5a",
    name: "The Razor's Apple",
    artists: "Fleshwater",
    duration_ms: 191236,
    popularity: 53,
    preview_url: "https://p.scdn.co/mp3-preview/7508b159c36f4ad41345536dd63216ee75a58bdc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025e6a9cd045fa8da9ba2fe81a",
    danceability: 0.336,
    energy: 0.943,
    valence: 0.394,
    added_at: "2023-08-02T17:07:26Z",
    link: "https://open.spotify.com/track/0rFdjHoldrbHIBeUIjxi5a",
    userID: "royclaud",
    genres: "grungegaze,zoomergaze",
  },
  {
    id: "6FgthnS6GYgLuELf7uRdDz",
    name: "Move Your Body - Hedex Remix",
    artists: "Öwnboss, Sevek, Hedex",
    duration_ms: 200239,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/d1366ba4652178c215f58980c4b7f4c66dbbc07a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b9cad54109b62b24dedcd8fc",
    danceability: 0.675,
    energy: 0.894,
    valence: 0.404,
    added_at: "2023-08-02T14:57:14Z",
    link: "https://open.spotify.com/track/6FgthnS6GYgLuELf7uRdDz",
    userID: "royclaud",
    genres: "brazilian edm,belgian dnb",
  },
  {
    id: "26lCNg8WekUoDbLhEbUyXZ",
    name: "Lawyers in Love",
    artists: "Jackson Browne",
    duration_ms: 259746,
    popularity: 41,
    preview_url: "https://p.scdn.co/mp3-preview/18f7d94269f1f155e65750a49985888e721c6c04?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02062554544f4011d1fb0b890c",
    danceability: 0.522,
    energy: 0.74,
    valence: 0.639,
    added_at: "2023-08-02T13:48:21Z",
    link: "https://open.spotify.com/track/26lCNg8WekUoDbLhEbUyXZ",
    userID: "royclaud",
    genres: "album rock,classic rock,country rock,folk,folk rock,heartland rock,mellow gold,piano rock,singer-songwriter,soft rock",
  },
  {
    id: "4dt6XKr0xKdPzjFhwB8dBm",
    name: "That's Life - 2008 Remastered",
    artists: "Frank Sinatra",
    duration_ms: 187226,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/245e39d047420821f55fd957152050e75f635da6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b81d66d1416afa139d12767b",
    danceability: 0.392,
    energy: 0.632,
    valence: 0.556,
    added_at: "2023-08-02T04:22:28Z",
    link: "https://open.spotify.com/track/4dt6XKr0xKdPzjFhwB8dBm",
    userID: "royclaud",
    genres: "adult standards,easy listening,lounge",
  },
  {
    id: "741UUVE2kuITl0c6zuqqbO",
    name: "Barbie World (with Aqua) [From Barbie The Album]",
    artists: "Nicki Minaj, Ice Spice, Aqua",
    duration_ms: 109750,
    popularity: 73,
    preview_url: "https://p.scdn.co/mp3-preview/f654928ccaa4e6a9fb09a6eb3f208383eccc12a9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027e8f938c02fac3b564931116",
    danceability: 0.77,
    energy: 0.58,
    valence: 0.753,
    added_at: "2023-08-02T04:22:20Z",
    link: "https://open.spotify.com/track/741UUVE2kuITl0c6zuqqbO",
    userID: "royclaud",
    genres: "hip pop,pop,queens hip hop,rap,bronx drill,bubblegum dance,eurodance,europop",
  },
  {
    id: "2kNUJ1NNlHUHPW4L852FwB",
    name: "It's a Man's Man's Man's World",
    artists: "Seal",
    duration_ms: 233053,
    popularity: 47,
    preview_url: "https://p.scdn.co/mp3-preview/1c16bc21ec7791dc288d098e688f8e00d9762d81?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0225d0f8817ec6285d93115da0",
    danceability: 0.481,
    energy: 0.532,
    valence: 0.398,
    added_at: "2023-08-02T04:16:09Z",
    link: "https://open.spotify.com/track/2kNUJ1NNlHUHPW4L852FwB",
    userID: "royclaud",
    genres: "british soul,new wave pop",
  },
  {
    id: "36ux3YuUsGTWPT8fXclS45",
    name: "E.T.",
    artists: "Katy Perry, Kanye West",
    duration_ms: 229573,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/3ae481944fdce085807e0d3aad1986ee50f00c48?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02937af329667311f4b2831616",
    danceability: 0.619,
    energy: 0.869,
    valence: 0.757,
    added_at: "2023-08-02T03:53:48Z",
    link: "https://open.spotify.com/track/36ux3YuUsGTWPT8fXclS45",
    userID: "royclaud",
    genres: "pop,chicago rap,hip hop,rap",
  },
  {
    id: "3cGHQbYCMhWkOlyZ2hxwEA",
    name: "SIRENS.",
    artists: "ESosa",
    duration_ms: 183066,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/d76c1b791ce8affea1d01cd02f26d019e4fb8ac5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0281b378c11297e828d55dcfb0",
    danceability: 0.731,
    energy: 0.787,
    valence: 0.824,
    added_at: "2023-08-01T22:41:19Z",
    link: "https://open.spotify.com/track/3cGHQbYCMhWkOlyZ2hxwEA",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "49sXkAcR5LvOrtq5Qcn5cf",
    name: "Superpower (feat. Frank Ocean)",
    artists: "Beyoncé, Frank Ocean",
    duration_ms: 276560,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/2a1d6c2cc3ca1506baccab03c37eda3b3f3e29e2?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020d1d6e9325275f104f8e33f3",
    danceability: 0.525,
    energy: 0.334,
    valence: 0.185,
    added_at: "2023-07-25T16:21:13Z",
    link: "https://open.spotify.com/track/49sXkAcR5LvOrtq5Qcn5cf",
    userID: "royclaud",
    genres: "pop,r&b,lgbtq+ hip hop,neo soul",
  },
  {
    id: "3Feec7M9kLXDHag4iZcBxU",
    name: "Go To Church",
    artists: "Ice Cube, Lil Jon, Snoop Dogg",
    duration_ms: 231933,
    popularity: 54,
    preview_url: "https://p.scdn.co/mp3-preview/99d71e14d15aa6ab9da946e92e693915b0271715?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020b2ff79ea103d440893152c3",
    danceability: 0.726,
    energy: 0.757,
    valence: 0.679,
    added_at: "2023-07-22T23:50:39Z",
    link: "https://open.spotify.com/track/3Feec7M9kLXDHag4iZcBxU",
    userID: "royclaud",
    genres: "conscious hip hop,g funk,gangster rap,hip hop,rap,west coast rap,atl hip hop,crunk,dance pop,dirty south rap,old school atlanta hip hop,pop rap,southern hip hop,trap,g funk,gangster rap,hip hop,pop rap,rap,west coast rap",
  },
  {
    id: "2hn2zOA2XFlv6DSmesylrL",
    name: "QUEMA",
    artists: "Ryan Castro, Peso Pluma, SOG",
    duration_ms: 150618,
    popularity: 75,
    preview_url: "https://p.scdn.co/mp3-preview/788238acd439da083fabc9d6b0d92eff3f4faf35?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fa2b0efdba3ba360927289d7",
    danceability: 0.791,
    energy: 0.892,
    valence: 0.916,
    added_at: "2023-07-19T23:49:07Z",
    link: "https://open.spotify.com/track/2hn2zOA2XFlv6DSmesylrL",
    userID: "royclaud",
    genres: "reggaeton,trap colombiano,trap latino,urbano latino,corridos tumbados,sad sierreno,reggaeton colombiano",
  },
  {
    id: "4cg1yakyRSIOjxKM2I7J1q",
    name: "Digits",
    artists: "Young Thug",
    duration_ms: 176386,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/ecca2f7e6cf2a6476273f9179139d88286f1b5fc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a875c3ec944b4f164ab5c350",
    danceability: 0.769,
    energy: 0.632,
    valence: 0.294,
    added_at: "2023-07-18T15:21:45Z",
    link: "https://open.spotify.com/track/4cg1yakyRSIOjxKM2I7J1q",
    userID: "royclaud",
    genres: "atl hip hop,atl trap,gangster rap,hip hop,melodic rap,rap,trap",
  },
  {
    id: "6MbH1QiphMCPTqVEVC7UYi",
    name: "Nothings Into Somethings",
    artists: "Drake",
    duration_ms: 153724,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/3b54868f4f7121c4c12a691c5e4c23e088ffca58?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024f0fd9dad63977146e685700",
    danceability: 0.766,
    energy: 0.234,
    valence: 0.256,
    added_at: "2023-07-18T14:57:20Z",
    link: "https://open.spotify.com/track/6MbH1QiphMCPTqVEVC7UYi",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "1h29y63PqkBoEXbyiumalC",
    name: "Palm Trees",
    artists: "Flatbush Zombies",
    duration_ms: 260680,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/09b4035f6a72e80b79f0429bf2832e6d95629361?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ab834bde1e431fbca9f7cfea",
    danceability: 0.606,
    energy: 0.708,
    valence: 0.407,
    added_at: "2023-07-17T22:35:21Z",
    link: "https://open.spotify.com/track/1h29y63PqkBoEXbyiumalC",
    userID: "royclaud",
    genres: "psychedelic hip hop,underground hip hop",
  },
  {
    id: "6tixmCEaH6ZH6k1MXxDB4J",
    name: "Police State (feat. Chairman Omali Yeshitela)",
    artists: "Dead Prez, Chairman Omali Yeshitela",
    duration_ms: 220200,
    popularity: 33,
    preview_url: "https://p.scdn.co/mp3-preview/60a50506e9481f9144f708cde2f04b79b732af4e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026be2fd3059b8ebc94eb85e0b",
    danceability: 0.638,
    energy: 0.789,
    valence: 0.563,
    added_at: "2023-07-17T15:39:09Z",
    link: "https://open.spotify.com/track/6tixmCEaH6ZH6k1MXxDB4J",
    userID: "royclaud",
    genres: "alternative hip hop,conscious hip hop,east coast hip hop,hardcore hip hop,political hip hop",
  },
  {
    id: "3Ph2TpDvrGMscHq9ZwsqBQ",
    name: "Dreams",
    artists: "Lo'fi Chield",
    duration_ms: 99428,
    popularity: 14,
    preview_url: "https://p.scdn.co/mp3-preview/0f6fbfd364a39cef2f662f35b297e756233b89c0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0261a029e629bb23c23388ab6c",
    danceability: 0.536,
    energy: 0.349,
    valence: 0.21,
    added_at: "2023-07-16T20:13:53Z",
    link: "https://open.spotify.com/track/3Ph2TpDvrGMscHq9ZwsqBQ",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "4c00fD972rl8ubRxZnyI2B",
    name: "Self Care",
    artists: "Mac Miller",
    duration_ms: 345133,
    popularity: 41,
    preview_url: "https://p.scdn.co/mp3-preview/27213e78b82c3bb6ca91eb40cd6146e883827b2b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0231d7d9e73feb6c8e82626dcb",
    danceability: 0.52,
    energy: 0.538,
    valence: 0.177,
    added_at: "2023-07-16T15:40:27Z",
    link: "https://open.spotify.com/track/4c00fD972rl8ubRxZnyI2B",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "1DKgGGbLAkBCOVDZZLFvSD",
    name: "Armando Aguirre",
    artists: "Chalino Sanchez, Los Amables Del Norte",
    duration_ms: 180025,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/19bf39e9cfc3ca4a0e965fc6fab7a784e12b76f8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0297c078aa9871933e29f5363b",
    danceability: 0.457,
    energy: 0.627,
    valence: 0.938,
    added_at: "2023-07-15T17:34:51Z",
    link: "https://open.spotify.com/track/1DKgGGbLAkBCOVDZZLFvSD",
    userID: "royclaud",
    genres: "banda,corrido,corridos clasicos,musica mexicana,norteno,sierreno,norteno",
  },
  {
    id: "1YsTvffZ63Yo1eHkajbeQF",
    name: "Alma Enamorada",
    artists: "Chalino Sanchez, Los Amables Del Norte",
    duration_ms: 175891,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/164990d1f2eb9d26f92af3490a6f38cc35b8c871?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c449ea3ee029b6fefe3c0cac",
    danceability: 0.493,
    energy: 0.61,
    valence: 0.935,
    added_at: "2023-07-15T04:34:03Z",
    link: "https://open.spotify.com/track/1YsTvffZ63Yo1eHkajbeQF",
    userID: "royclaud",
    genres: "banda,corrido,corridos clasicos,musica mexicana,norteno,sierreno,norteno",
  },
  {
    id: "1tGGJ4SRZnd2H0E2dV05iM",
    name: "SU CASA",
    artists: "Peso Pluma, Luis R Conriquez",
    duration_ms: 138559,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/084c7df0168f8e247bf83302eaad6ba9e8186c8a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022fb583ed96f8f35cbf2897ba",
    danceability: 0.809,
    energy: 0.87,
    valence: 0.903,
    added_at: "2023-07-14T19:32:10Z",
    link: "https://open.spotify.com/track/1tGGJ4SRZnd2H0E2dV05iM",
    userID: "royclaud",
    genres: "corridos tumbados,sad sierreno,corrido,sad sierreno,sierreno",
  },
  {
    id: "3B0swL7Qil9bB7eKzBREBK",
    name: "VVS",
    artists: "Peso Pluma, Edgardo Nuñez, Los Dareyes De La Sierra",
    duration_ms: 162676,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/b58bba2df694055d6c2bffe8c194c8be02b17598?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022fb583ed96f8f35cbf2897ba",
    danceability: 0.632,
    energy: 0.729,
    valence: 0.862,
    added_at: "2023-07-14T19:29:51Z",
    link: "https://open.spotify.com/track/3B0swL7Qil9bB7eKzBREBK",
    userID: "royclaud",
    genres: "corridos tumbados,sad sierreno,corrido,corridos tumbados,sad sierreno,sierreno,classic sierreno,corrido,corridos tumbados,musica mexicana,sierreno",
  },
  {
    id: "0xGZjxYqcNQ8WqxyeZ4eSN",
    name: "RUBICON",
    artists: "Peso Pluma",
    duration_ms: 238447,
    popularity: 76,
    preview_url: "https://p.scdn.co/mp3-preview/3c73f88a377d8d8f24b3c007896a054aaeb84a06?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022fb583ed96f8f35cbf2897ba",
    danceability: 0.758,
    energy: 0.816,
    valence: 0.915,
    added_at: "2023-07-14T19:11:56Z",
    link: "https://open.spotify.com/track/0xGZjxYqcNQ8WqxyeZ4eSN",
    userID: "royclaud",
    genres: "corridos tumbados,sad sierreno",
  },
  {
    id: "7mXuWTczZNxG5EDcjFEuJR",
    name: "LADY GAGA",
    artists: "Peso Pluma, Gabito Ballesteros, Junior H",
    duration_ms: 212878,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/ec439edcef8f1b71e88cfeb7d200db274901123b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022fb583ed96f8f35cbf2897ba",
    danceability: 0.646,
    energy: 0.736,
    valence: 0.873,
    added_at: "2023-07-14T18:55:17Z",
    link: "https://open.spotify.com/track/7mXuWTczZNxG5EDcjFEuJR",
    userID: "royclaud",
    genres: "corridos tumbados,sad sierreno,corrido,sierreno,corrido,corridos tumbados,musica mexicana,sad sierreno,sierreno",
  },
  {
    id: "6JH9yWhgbm0y9iAJhCslNc",
    name: "Handlebars",
    artists: "Flobots",
    duration_ms: 206813,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/35e8baf7da7f1cffdb5b960fa4409cfdd90a7d90?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029c42c35d57212a5920c42f6c",
    danceability: 0.704,
    energy: 0.571,
    valence: 0.524,
    added_at: "2023-07-14T18:47:21Z",
    link: "https://open.spotify.com/track/6JH9yWhgbm0y9iAJhCslNc",
    userID: "royclaud",
    genres: "conscious hip hop,nerdcore",
  },
  {
    id: "5n5uyFpfopa4mNdSN0gTaH",
    name: "Wing$",
    artists: "Macklemore & Ryan Lewis, Macklemore, Ryan Lewis",
    duration_ms: 298640,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/d65822efe2bf2cf444960eae72ccd914d2509675?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0298a02fef3a8b1d80a0f164ec",
    danceability: 0.4,
    energy: 0.602,
    valence: 0.0964,
    added_at: "2023-07-14T18:28:38Z",
    link: "https://open.spotify.com/track/5n5uyFpfopa4mNdSN0gTaH",
    userID: "royclaud",
    genres: "pop rap,seattle hip hop,pop rap",
  },
  {
    id: "1f5cbQtDrykjarZVrShaDI",
    name: "Drowning (feat. Kodak Black)",
    artists: "A Boogie Wit da Hoodie, Kodak Black",
    duration_ms: 209269,
    popularity: 75,
    preview_url: "https://p.scdn.co/mp3-preview/f770a1a0b3dfb5bc652f89848e45ab9d09f7dc69?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02cdba7ee22968991250725ce1",
    danceability: 0.839,
    energy: 0.81,
    valence: 0.814,
    added_at: "2023-07-14T13:49:28Z",
    link: "https://open.spotify.com/track/1f5cbQtDrykjarZVrShaDI",
    userID: "royclaud",
    genres: "melodic rap,rap,trap,florida drill,florida rap,melodic rap,miami hip hop,rap,trap,trap latino",
  },
  {
    id: "6UoScMkrhuw7FuElV8NYs3",
    name: "Alors On Danse - Extended Mix",
    artists: "Stromae",
    duration_ms: 258359,
    popularity: 42,
    preview_url: "https://p.scdn.co/mp3-preview/5c9d16ddd6c34e5e001352d97f603123503b9cea?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a5bf4ae55c75f4a1d9454e45",
    danceability: 0.774,
    energy: 0.747,
    valence: 0.672,
    added_at: "2023-07-14T13:10:56Z",
    link: "https://open.spotify.com/track/6UoScMkrhuw7FuElV8NYs3",
    userID: "royclaud",
    genres: "belgian pop,g-house",
  },
  {
    id: "19eIZmuXlBmLTha1lPHef7",
    name: "Alors On Danse - DubDogz Remix",
    artists: "Stromae, Dubdogz",
    duration_ms: 168576,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/09060ea9a7d0ee2cf71dfd4ddea6c1a0678732c0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025327653f3d3215a4e3fae684",
    danceability: 0.789,
    energy: 0.752,
    valence: 0.739,
    added_at: "2023-07-14T13:07:57Z",
    link: "https://open.spotify.com/track/19eIZmuXlBmLTha1lPHef7",
    userID: "royclaud",
    genres: "belgian pop,g-house,brazilian bass,brazilian edm",
  },
  {
    id: "0Xd7CMyvsN0spWDB2zbdwv",
    name: "Ese sonidito",
    artists: "Jdr",
    duration_ms: 215238,
    popularity: 47,
    preview_url: "https://p.scdn.co/mp3-preview/dd80149e606efac9aa5c4bebd602465043f4731c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c142322632337f71dcf8caa2",
    danceability: 0.806,
    energy: 0.834,
    valence: 0.235,
    added_at: "2023-07-13T13:36:14Z",
    link: "https://open.spotify.com/track/0Xd7CMyvsN0spWDB2zbdwv",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "4rXLjWdF2ZZpXCVTfWcshS",
    name: "fukumean",
    artists: "Gunna",
    duration_ms: 125040,
    popularity: 84,
    preview_url: "https://p.scdn.co/mp3-preview/f237ab921af697ba9b49e12fa167c2ce1a82d6b4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02017d5e26552345c4b1575b6c",
    danceability: 0.847,
    energy: 0.622,
    valence: 0.22,
    added_at: "2023-07-12T23:30:16Z",
    link: "https://open.spotify.com/track/4rXLjWdF2ZZpXCVTfWcshS",
    userID: "royclaud",
    genres: "atl hip hop,melodic rap,rap,trap",
  },
  {
    id: "0vSsymU4xBMJMRV5A8j8ws",
    name: "",
    artists: "",
    duration_ms: 0,
    popularity: 0,
    preview_url: "null",
    image: "unknown_image",
    danceability: 0.642,
    energy: 0.8,
    valence: 0.859,
    added_at: "2023-07-12T15:33:01Z",
    link: "https://open.spotify.com/track/0vSsymU4xBMJMRV5A8j8ws",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "7j31rVgGX9Q2blT92VBEA0",
    name: "Teenagers",
    artists: "My Chemical Romance",
    duration_ms: 161920,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/e6d0c0da5d86b2d19ee4456344ac9527a72b2a7d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0217f77fab7e8f18d5f9fee4a1",
    danceability: 0.463,
    energy: 0.857,
    valence: 0.856,
    added_at: "2023-07-11T13:51:48Z",
    link: "https://open.spotify.com/track/7j31rVgGX9Q2blT92VBEA0",
    userID: "royclaud",
    genres: "emo,modern rock,pop punk,pov: indie,rock",
  },
  {
    id: "1SJ4ycWow4yz6z4oFz8NAG",
    name: "Rainbow Connection",
    artists: "The Muppets",
    duration_ms: 188933,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/5b04e14eeb4fa0da65362f446026de2e436ac844?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0249975a26b5562064db3a552b",
    danceability: 0.526,
    energy: 0.413,
    valence: 0.211,
    added_at: "2023-07-09T19:44:35Z",
    link: "https://open.spotify.com/track/1SJ4ycWow4yz6z4oFz8NAG",
    userID: "royclaud",
    genres: "cartoon,children's music",
  },
  {
    id: "5hnyJvgoWiQUYZttV4wXy6",
    name: "Chasing Cars",
    artists: "Snow Patrol",
    duration_ms: 267960,
    popularity: 79,
    preview_url: "https://p.scdn.co/mp3-preview/fac686f4d67453548876570e48d99ad32b181294?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025da2756220da9b6f17924f8f",
    danceability: 0.561,
    energy: 0.595,
    valence: 0.144,
    added_at: "2023-07-05T18:02:09Z",
    link: "https://open.spotify.com/track/5hnyJvgoWiQUYZttV4wXy6",
    userID: "royclaud",
    genres: "irish rock,modern rock,neo mellow,permanent wave,pop rock",
  },
  {
    id: "5fEC5Fq8rxTsGwhFU00ITV",
    name: "Regular People.",
    artists: "Piff Marti",
    duration_ms: 74354,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/d4d54042f85fe67b20abe046c1ec161a540c4e50?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027cb9ee7f0674c5a8df66b01b",
    danceability: 0.845,
    energy: 0.454,
    valence: 0.619,
    added_at: "2023-07-03T20:59:29Z",
    link: "https://open.spotify.com/track/5fEC5Fq8rxTsGwhFU00ITV",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "5wT0AQeYT09WqR7r0eYZWs",
    name: "deadman",
    artists: "Souly Had",
    duration_ms: 130909,
    popularity: 39,
    preview_url: "https://p.scdn.co/mp3-preview/a4cc80ec54d58f42dd2d894e52e4874fb2929aed?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025dd43f9c93a6e31b741d08eb",
    danceability: 0.692,
    energy: 0.701,
    valence: 0.383,
    added_at: "2023-07-03T20:54:31Z",
    link: "https://open.spotify.com/track/5wT0AQeYT09WqR7r0eYZWs",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "2O0tYnL4ZgIntR1J7HWwh6",
    name: 'Serenade No. 13 in G Major, K. 525 "Eine kleine Nachtmusik": I. Allegro',
    artists: "Wolfgang Amadeus Mozart, Wiener Kammerphilharmonie",
    duration_ms: 345266,
    popularity: 25,
    preview_url: "https://p.scdn.co/mp3-preview/7fc67d7ac31085ae6cc693fb51ef7ec59cbb19b5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020c84f2f4d16f24036bbfcee4",
    danceability: 0.436,
    energy: 0.18,
    valence: 0.392,
    added_at: "2023-07-03T17:15:08Z",
    link: "https://open.spotify.com/track/2O0tYnL4ZgIntR1J7HWwh6",
    userID: "royclaud",
    genres: "classical,classical era,austrian orchestra",
  },
  {
    id: "292P4N0aFTqFEsVAyNd8wN",
    name: "Change That Back (feat. AKTHESAVIOR)",
    artists: "Azizi Gibson, AKTHESAVIOR",
    duration_ms: 194953,
    popularity: 18,
    preview_url: "https://p.scdn.co/mp3-preview/1620c7e94165cbfebbb3eea1e81719ba3647df88?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023774fdac2b0192a4b00178eb",
    danceability: 0.855,
    energy: 0.487,
    valence: 0.355,
    added_at: "2023-06-29T23:22:24Z",
    link: "https://open.spotify.com/track/292P4N0aFTqFEsVAyNd8wN",
    userID: "royclaud",
    genres: "canadian hip hop,underground hip hop,underground hip hop",
  },
  {
    id: "5ZdVYZaMffiDf1plaJ2Jib",
    name: "Patience",
    artists: 'Nas & Damian "Jr. Gong" Marley',
    duration_ms: 345093,
    popularity: 50,
    preview_url: "https://p.scdn.co/mp3-preview/ada989a58a2a9aa2b91a9c5b0db46743c3c5cb6c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026464d88f4a4cc33bac40030f",
    danceability: 0.794,
    energy: 0.636,
    valence: 0.304,
    added_at: "2023-06-29T16:55:52Z",
    link: "https://open.spotify.com/track/5ZdVYZaMffiDf1plaJ2Jib",
    userID: "royclaud",
    genres: "reggae fusion",
  },
  {
    id: "1H0VcYUyBxQGUeZ15DmfGS",
    name: "DualWield (feat. BONES)",
    artists: "Deergod, BONES",
    duration_ms: 80487,
    popularity: 24,
    preview_url: "https://p.scdn.co/mp3-preview/1ed1cb73f3a3308c67ec6fcddc59c8e51cbf6fed?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f2302c88f9ecb12fedeb2adf",
    danceability: 0.538,
    energy: 0.249,
    valence: 0.324,
    added_at: "2023-06-29T12:13:50Z",
    link: "https://open.spotify.com/track/1H0VcYUyBxQGUeZ15DmfGS",
    userID: "royclaud",
    genres: "cloud rap,cloud rap,dark trap",
  },
  {
    id: "5Y3ID1NKAg8qydyVCrM7ji",
    name: "Partin Ways",
    artists: "Polo G",
    duration_ms: 170322,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/5035c230623c50fefb9424aa7858bb4a9d5487f4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024f5212ccbffc94a609f0fb00",
    danceability: 0.744,
    energy: 0.439,
    valence: 0.39,
    added_at: "2023-06-29T12:13:41Z",
    link: "https://open.spotify.com/track/5Y3ID1NKAg8qydyVCrM7ji",
    userID: "royclaud",
    genres: "chicago rap,rap",
  },
  {
    id: "2gT2iF1YK5r54A2PDEXVv8",
    name: "Pretty Little Poison",
    artists: "Warren Zeiders",
    duration_ms: 218160,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/5706f3cc604fca07c55556bbac4fd5b89bdecad5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02af844dfd3d25f3b87a95d40f",
    danceability: 0.518,
    energy: 0.557,
    valence: 0.216,
    added_at: "2023-06-29T12:12:52Z",
    link: "https://open.spotify.com/track/2gT2iF1YK5r54A2PDEXVv8",
    userID: "royclaud",
    genres: "modern country pop",
  },
  {
    id: "2GThBgzZoZfz0lx1JjBwfe",
    name: "Those Eyes",
    artists: "New West",
    duration_ms: 220750,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/4c725ed45c535444fded71908c0a69120cbbfc89?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025e8c8b0d006aabc9a81c18ef",
    danceability: 0.597,
    energy: 0.351,
    valence: 0.243,
    added_at: "2023-06-28T22:56:53Z",
    link: "https://open.spotify.com/track/2GThBgzZoZfz0lx1JjBwfe",
    userID: "royclaud",
    genres: "toronto indie",
  },
  {
    id: "7tevfmj9mOyyLmgwEFTycQ",
    name: "Shadowlands",
    artists: "Revolt Production Music",
    duration_ms: 154095,
    popularity: 33,
    preview_url: "https://p.scdn.co/mp3-preview/73897e5613740f95ab06e8cca7e79fdab94b8058?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02939a3bd245308462ac5b0e1b",
    danceability: 0.41,
    energy: 0.609,
    valence: 0.146,
    added_at: "2023-06-28T20:27:34Z",
    link: "https://open.spotify.com/track/7tevfmj9mOyyLmgwEFTycQ",
    userID: "royclaud",
    genres: "epicore",
  },
  {
    id: "6JRLFiX9NJSoRRKxowlBYr",
    name: "Is This Love",
    artists: "Bob Marley & The Wailers",
    duration_ms: 232200,
    popularity: 76,
    preview_url: "https://p.scdn.co/mp3-preview/8bbaf3fbb1801c0b9a94fdec7d4eaa69f5b36a9f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02387799441ba867649dfbb702",
    danceability: 0.776,
    energy: 0.561,
    valence: 0.743,
    added_at: "2023-06-28T20:16:32Z",
    link: "https://open.spotify.com/track/6JRLFiX9NJSoRRKxowlBYr",
    userID: "royclaud",
    genres: "reggae,roots reggae",
  },
  {
    id: "2M06vOalzheqgascygGojn",
    name: "Slipstream",
    artists: "The Bright Light Social Hour",
    duration_ms: 176053,
    popularity: 25,
    preview_url: "https://p.scdn.co/mp3-preview/83ada3a60eda39b2660ef01dfa072353c5a72821?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027c97b0f4fe1cdfb94be6595b",
    danceability: 0.245,
    energy: 0.88,
    valence: 0.518,
    added_at: "2023-06-21T16:27:01Z",
    link: "https://open.spotify.com/track/2M06vOalzheqgascygGojn",
    userID: "royclaud",
    genres: "austin rock,austindie",
  },
  {
    id: "3DamFFqW32WihKkTVlwTYQ",
    name: "Fireflies",
    artists: "Owl City",
    duration_ms: 228346,
    popularity: 76,
    preview_url: "https://p.scdn.co/mp3-preview/43dc49fcebcb6d83f3dd0570044bc20b1d01f5a3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02785d4e702802da500fc78b32",
    danceability: 0.512,
    energy: 0.662,
    valence: 0.472,
    added_at: "2023-06-20T14:41:19Z",
    link: "https://open.spotify.com/track/3DamFFqW32WihKkTVlwTYQ",
    userID: "royclaud",
    genres: "indietronica,pop",
  },
  {
    id: "0X2bh8NVQ8svDQIn2AdCbW",
    name: "Consume (feat. Goon Des Garcons)",
    artists: "Chase Atlantic, GOON DES GARCONS*",
    duration_ms: 267920,
    popularity: 77,
    preview_url: "https://p.scdn.co/mp3-preview/943da532a4cca7c27091031c85d85a0d22360fc4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025a0c2870f4f309e382d1fad6",
    danceability: 0.556,
    energy: 0.714,
    valence: 0.434,
    added_at: "2023-06-19T20:02:49Z",
    link: "https://open.spotify.com/track/0X2bh8NVQ8svDQIn2AdCbW",
    userID: "royclaud",
    genres: "alternative r&b",
  },
  {
    id: "3M08lRNi7YgWBt92Q1IX6p",
    name: "i know what you need",
    artists: "adi., Shiloh Dynasty",
    duration_ms: 103600,
    popularity: 28,
    preview_url: "https://p.scdn.co/mp3-preview/989c8b8737b0695e912525f24681042678eb7fa8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02297eb8a3631bf58154a583fc",
    danceability: 0.555,
    energy: 0.233,
    valence: 0.397,
    added_at: "2023-06-15T23:23:03Z",
    link: "https://open.spotify.com/track/3M08lRNi7YgWBt92Q1IX6p",
    userID: "royclaud",
    genres: "lo-fi chill,sad lo-fi",
  },
  {
    id: "2LMkwUfqC6S6s6qDVlEuzV",
    name: "White Ferrari",
    artists: "Frank Ocean",
    duration_ms: 248807,
    popularity: 83,
    preview_url: "https://p.scdn.co/mp3-preview/bff77236970b7f90cdfc274b18b55ce7bf584bcc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c5649add07ed3720be9d5526",
    danceability: 0.501,
    energy: 0.0958,
    valence: 0.204,
    added_at: "2023-06-14T15:27:32Z",
    link: "https://open.spotify.com/track/2LMkwUfqC6S6s6qDVlEuzV",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "4k6Uh1HXdhtusDW5y8Gbvy",
    name: "Bad Habit",
    artists: "Steve Lacy",
    duration_ms: 232066,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/efe7c2fdd8fa727a108a1270b114ebedabfd766c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0268968350c2550e36d96344ee",
    danceability: 0.686,
    energy: 0.494,
    valence: 0.7,
    added_at: "2023-06-08T21:26:28Z",
    link: "https://open.spotify.com/track/4k6Uh1HXdhtusDW5y8Gbvy",
    userID: "royclaud",
    genres: "afrofuturism",
  },
  {
    id: "5k3yghc8OMFayi39woODXT",
    name: "Calling (Spider-Man: Across the Spider-Verse) (Metro Boomin & Swae Lee, NAV, feat. A Boogie Wit da Hoodie)",
    artists: "Metro Boomin, Swae Lee, NAV, A Boogie Wit da Hoodie",
    duration_ms: 219453,
    popularity: 44,
    preview_url: "https://p.scdn.co/mp3-preview/f4f13751297cc17a1f284bae8a13b30572c08770?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024a3cdc1e547b3d275d97cff8",
    danceability: 0.636,
    energy: 0.537,
    valence: 0.199,
    added_at: "2023-06-08T00:04:00Z",
    link: "https://open.spotify.com/track/5k3yghc8OMFayi39woODXT",
    userID: "royclaud",
    genres: "rap,melodic rap,rap,trap,canadian hip hop,canadian trap,melodic rap,rap,trap,melodic rap,rap,trap",
  },
  {
    id: "0grfvXep0gn5nXdQoHue1a",
    name: "Annihilate (Spider-Man: Across the Spider-Verse) (Metro Boomin & Swae Lee, Lil Wayne, Offset)",
    artists: "Metro Boomin, Swae Lee, Lil Wayne, Offset",
    duration_ms: 231746,
    popularity: 45,
    preview_url: "https://p.scdn.co/mp3-preview/55f79595d431d76086fdbe4398935a66a758c1ca?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024a3cdc1e547b3d275d97cff8",
    danceability: 0.591,
    energy: 0.5,
    valence: 0.201,
    added_at: "2023-06-08T00:03:52Z",
    link: "https://open.spotify.com/track/0grfvXep0gn5nXdQoHue1a",
    userID: "royclaud",
    genres: "rap,melodic rap,rap,trap,hip hop,new orleans rap,pop rap,rap,trap,atl hip hop,hip hop,pop rap,rap,trap",
  },
  {
    id: "2Fs8jJhivzFClYyrU6KTFm",
    name: "Nocturne in E Minor Op. 72, No. 1",
    artists: "Frédéric Chopin, Vladimir Feltsman",
    duration_ms: 214800,
    popularity: 20,
    preview_url: "https://p.scdn.co/mp3-preview/938d6e0c4e20e62c8ae183f178f4fcd8ad18f173?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b1583ff966f15a794f5b13ee",
    danceability: 0.328,
    energy: 0.0234,
    valence: 0.0564,
    added_at: "2023-06-06T13:16:54Z",
    link: "https://open.spotify.com/track/2Fs8jJhivzFClYyrU6KTFm",
    userID: "royclaud",
    genres: "classical,early romantic era,polish classical,classical piano,russian classical piano",
  },
  {
    id: "50Re2bLgXqG9qN7v2QHNTm",
    name: "Outside",
    artists: "Staind",
    duration_ms: 293133,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/dc571b1ba19ba798a28855543734968516fea44b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02437a8cfd8293ddf6d800c384",
    danceability: 0.515,
    energy: 0.594,
    valence: 0.218,
    added_at: "2023-06-06T02:28:39Z",
    link: "https://open.spotify.com/track/50Re2bLgXqG9qN7v2QHNTm",
    userID: "royclaud",
    genres: "alternative metal,nu metal,post-grunge",
  },
  {
    id: "1odExI7RdWc4BT515LTAwj",
    name: "Daylight",
    artists: "David Kushner",
    duration_ms: 212953,
    popularity: 83,
    preview_url: "https://p.scdn.co/mp3-preview/43c7e8d0c7af7fdddfeb56e2b8b8a091e2091a99?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0295ca6a9b4083a86c149934ae",
    danceability: 0.508,
    energy: 0.43,
    valence: 0.324,
    added_at: "2023-06-04T19:17:56Z",
    link: "https://open.spotify.com/track/1odExI7RdWc4BT515LTAwj",
    userID: "royclaud",
    genres: "gen z singer-songwriter,singer-songwriter pop",
  },
  {
    id: "6GGtHZgBycCgGBUhZo81xe",
    name: "Say Yes To Heaven",
    artists: "Lana Del Rey",
    duration_ms: 209156,
    popularity: 84,
    preview_url: "https://p.scdn.co/mp3-preview/ebc7b240dcbd586c553da04940c7f5c5112be917?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02aa27708d07f49c82ff0d0dae",
    danceability: 0.485,
    energy: 0.348,
    valence: 0.145,
    added_at: "2023-06-04T18:58:57Z",
    link: "https://open.spotify.com/track/6GGtHZgBycCgGBUhZo81xe",
    userID: "royclaud",
    genres: "art pop,pop",
  },
  {
    id: "7rWgGyRK7RAqAAXy4bLft9",
    name: "Angeleyes",
    artists: "ABBA",
    duration_ms: 260893,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/df743482a82573f3c13c632f30e04373dab7df8e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02aa22899360d8ba6704732dec",
    danceability: 0.719,
    energy: 0.922,
    valence: 0.964,
    added_at: "2023-05-27T04:06:35Z",
    link: "https://open.spotify.com/track/7rWgGyRK7RAqAAXy4bLft9",
    userID: "royclaud",
    genres: "europop,swedish pop",
  },
  {
    id: "0ZnMzSoVfqDRzXgagCOlKZ",
    name: "Venezuela",
    artists: "Tom Budin, Rave Radio",
    duration_ms: 156272,
    popularity: 52,
    preview_url: "https://p.scdn.co/mp3-preview/a6631fa09bdaf6edc7c9cfec7947daa13860d5a0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b5d7fe610ecccf95e72078b7",
    danceability: 0.733,
    energy: 0.84,
    valence: 0.675,
    added_at: "2023-05-23T13:52:50Z",
    link: "https://open.spotify.com/track/0ZnMzSoVfqDRzXgagCOlKZ",
    userID: "royclaud",
    genres: "deep groove house,slap house,tech house",
  },
  {
    id: "6ZixnWzzH1YxqBg9kLOPDy",
    name: "Sideways",
    artists: "Citizen Cope",
    duration_ms: 322186,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/f8a6240385c3341999930006c7caac0f6c22eb09?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c0e45135a8ae85990f8be25a",
    danceability: 0.573,
    energy: 0.431,
    valence: 0.187,
    added_at: "2023-05-13T18:35:28Z",
    link: "https://open.spotify.com/track/6ZixnWzzH1YxqBg9kLOPDy",
    userID: "royclaud",
    genres: "alternative rock",
  },
  {
    id: "1RJl5cZ8kFpoVFOS2x6scI",
    name: "Take Me Where Your Heart Is",
    artists: "Q",
    duration_ms: 192896,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/0e89c00273c98e0f3277fd65d4542f19fa3da825?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029ecba76ea135175ab5114fce",
    danceability: 0.858,
    energy: 0.426,
    valence: 0.419,
    added_at: "2023-05-07T02:13:21Z",
    link: "https://open.spotify.com/track/1RJl5cZ8kFpoVFOS2x6scI",
    userID: "royclaud",
    genres: "alternative r&b,indie r&b",
  },
  {
    id: "0SZPXD0uEl72YjUVY2K3E9",
    name: "The Business, Pt. II",
    artists: "Tiësto, Ty Dolla $ign",
    duration_ms: 164267,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/07942676eddeeedf2a69ac9d720004e47cc7ea6b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d1a8e3c4a0b0f1100ee27153",
    danceability: 0.658,
    energy: 0.747,
    valence: 0.326,
    added_at: "2023-05-06T00:53:04Z",
    link: "https://open.spotify.com/track/0SZPXD0uEl72YjUVY2K3E9",
    userID: "royclaud",
    genres: "big room,brostep,dutch edm,edm,house,pop dance,progressive electro house,slap house,trance,hip hop,pop rap,r&b,southern hip hop,trap,trap soul",
  },
  {
    id: "03hqMhmCZiNKMSPmVabPLP",
    name: "Groupie Love (feat. A$AP Rocky)",
    artists: "Lana Del Rey, A$AP Rocky",
    duration_ms: 264037,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/225407a19349a60359168f311a1960a0d14253c1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0295e2fd1accb339fa14878190",
    danceability: 0.359,
    energy: 0.6,
    valence: 0.195,
    added_at: "2023-05-04T23:57:27Z",
    link: "https://open.spotify.com/track/03hqMhmCZiNKMSPmVabPLP",
    userID: "royclaud",
    genres: "art pop,pop,east coast hip hop,hip hop,rap",
  },
  {
    id: "5QqyRUZeBE04yJxsD1OC0I",
    name: "Summer Bummer (feat. A$AP Rocky & Playboi Carti)",
    artists: "Lana Del Rey, A$AP Rocky, Playboi Carti",
    duration_ms: 260672,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/aeb292c9f842e92d5512be538384772cce507b43?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0295e2fd1accb339fa14878190",
    danceability: 0.76,
    energy: 0.574,
    valence: 0.137,
    added_at: "2023-05-04T23:57:25Z",
    link: "https://open.spotify.com/track/5QqyRUZeBE04yJxsD1OC0I",
    userID: "royclaud",
    genres: "art pop,pop,east coast hip hop,hip hop,rap,atl hip hop,plugg,pluggnb,rage rap,rap",
  },
  {
    id: "5YJ4BVvTFmVCxUyhKejHfR",
    name: "Pa' Que Retozen",
    artists: "Tego Calderón",
    duration_ms: 151666,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/ddc2230ae8946ec1e1e4a7b69a9e65bab76c9869?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0272b621a4c23f797307d35f11",
    danceability: 0.815,
    energy: 0.856,
    valence: 0.872,
    added_at: "2023-05-04T14:33:02Z",
    link: "https://open.spotify.com/track/5YJ4BVvTFmVCxUyhKejHfR",
    userID: "royclaud",
    genres: "latin hip hop,rap boricua,reggaeton,trap latino,urbano latino",
  },
  {
    id: "2JRf7wtm6dhBnfueyCoi7B",
    name: "I Got Summer On My Mind - Extended Version",
    artists: "Elli Eli, JJ, elin kastlander",
    duration_ms: 149985,
    popularity: 2,
    preview_url: "https://p.scdn.co/mp3-preview/d726f7e1f172ec5e54c3f95eb95a203a345f2e62?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0213055eb78519cb02c8691139",
    danceability: 0.654,
    energy: 0.652,
    valence: 0.465,
    added_at: "2023-05-01T18:40:17Z",
    link: "https://open.spotify.com/track/2JRf7wtm6dhBnfueyCoi7B",
    userID: "royclaud",
    genres: "balearic,swedish indie pop",
  },
  {
    id: "2NALyOC8GhCCqvnIVsBaFR",
    name: "Holy Toledo",
    artists: "Vundabar",
    duration_ms: 320075,
    popularity: 43,
    preview_url: "https://p.scdn.co/mp3-preview/b1d7407aa216276b348d268a641d04d51e3e3614?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02dcebb1016558937cbda27a04",
    danceability: 0.457,
    energy: 0.515,
    valence: 0.215,
    added_at: "2023-04-28T21:24:22Z",
    link: "https://open.spotify.com/track/2NALyOC8GhCCqvnIVsBaFR",
    userID: "royclaud",
    genres: "boston indie,indie garage rock,pov: indie",
  },
  {
    id: "1bL6fctRT13NffF2JHU5zQ",
    name: "Hear You Me",
    artists: "Jimmy Eat World",
    duration_ms: 282960,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/2be668655cdf69711fd0a9fc54becb78a5da2c3a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c42de59f6893457d392f4d88",
    danceability: 0.482,
    energy: 0.556,
    valence: 0.116,
    added_at: "2023-04-28T02:54:15Z",
    link: "https://open.spotify.com/track/1bL6fctRT13NffF2JHU5zQ",
    userID: "royclaud",
    genres: "alternative metal,alternative rock,emo,modern power pop,modern rock,neon pop punk,pop punk,pop rock,post-grunge,punk,rock",
  },
  {
    id: "6aK8hjLte9hxorICzb5mRF",
    name: "My Elastic Eye",
    artists: "The Chemical Brothers",
    duration_ms: 221800,
    popularity: 28,
    preview_url: "https://p.scdn.co/mp3-preview/c42809d8f41cf9bc6936d1248d2d6b260693cab7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a84e1d8c3a99de8e2e1e6c1a",
    danceability: 0.607,
    energy: 0.805,
    valence: 0.643,
    added_at: "2023-04-28T02:54:09Z",
    link: "https://open.spotify.com/track/6aK8hjLte9hxorICzb5mRF",
    userID: "royclaud",
    genres: "alternative dance,big beat,breakbeat,electronica,rave,trip hop",
  },
  {
    id: "0KeVjEtPP78JWOsmP7KtVm",
    name: "In My City",
    artists: "New West",
    duration_ms: 247562,
    popularity: 28,
    preview_url: "https://p.scdn.co/mp3-preview/bbee549b9d2429257eba2d2a04e34de598b44675?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a4e71da6b3244c6a3bc738ba",
    danceability: 0.556,
    energy: 0.572,
    valence: 0.255,
    added_at: "2023-04-27T14:03:53Z",
    link: "https://open.spotify.com/track/0KeVjEtPP78JWOsmP7KtVm",
    userID: "royclaud",
    genres: "toronto indie",
  },
  {
    id: "4yZq5QgUhKq0bO6ExHF2a0",
    name: "Un Millón",
    artists: "The Marías",
    duration_ms: 167386,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/9266a3fa37a9dc7cadeb7911bda57ad1fa78fc4d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a72fbfd4ed6bf32f35918267",
    danceability: 0.748,
    energy: 0.464,
    valence: 0.226,
    added_at: "2023-04-23T17:54:52Z",
    link: "https://open.spotify.com/track/4yZq5QgUhKq0bO6ExHF2a0",
    userID: "royclaud",
    genres: "bedroom pop,la indie",
  },
  {
    id: "6QFQqqYye5lAcnhCALvxKJ",
    name: "My Flaws Burn Through My Skin Like Demonic Flames from Hell",
    artists: "$uicideboy$",
    duration_ms: 167679,
    popularity: 74,
    preview_url: "https://p.scdn.co/mp3-preview/f548ce41ebded041c091b1f67867a165a387d444?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02774096669395b9d64fa2fcbe",
    danceability: 0.78,
    energy: 0.748,
    valence: 0.0663,
    added_at: "2023-04-23T02:27:01Z",
    link: "https://open.spotify.com/track/6QFQqqYye5lAcnhCALvxKJ",
    userID: "royclaud",
    genres: "cloud rap,dark trap,new orleans rap,underground hip hop",
  },
  {
    id: "4cf1yy01sMaBVd05P6pwnh",
    name: "Moodna, Once With Grace",
    artists: "Gus Dapperton",
    duration_ms: 198014,
    popularity: 42,
    preview_url: "https://p.scdn.co/mp3-preview/596f9f714e08ac3dfd7060785eec20fd5b1275b7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ae871c52286bf992400f4002",
    danceability: 0.576,
    energy: 0.418,
    valence: 0.169,
    added_at: "2023-04-22T02:56:59Z",
    link: "https://open.spotify.com/track/4cf1yy01sMaBVd05P6pwnh",
    userID: "royclaud",
    genres: "bedroom pop,indie poptimism,pov: indie",
  },
  {
    id: "1NPk0Y7AkLvirhSg4oI0bM",
    name: "Labios Rojos",
    artists: "LDNE",
    duration_ms: 241423,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/f6bfdcc2496ba4d98c0646d510ac1ded264ff8f9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02904bba5bbc19b802d4352470",
    danceability: 0.492,
    energy: 0.729,
    valence: 0.902,
    added_at: "2023-04-15T23:54:47Z",
    link: "https://open.spotify.com/track/1NPk0Y7AkLvirhSg4oI0bM",
    userID: "royclaud",
    genres: "corrido,corridos tumbados,sad sierreno,sierreno",
  },
  {
    id: "3kf0WdFOalKWBkCCLJo4mA",
    name: "El Gordo Trae El Mando",
    artists: "Chino Pacas",
    duration_ms: 112087,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/b7a2fc7c6d1a0954e5e793dcac7dc27755a96f41?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026a6ab689151163a1e9f60f36",
    danceability: 0.736,
    energy: 0.8,
    valence: 0.961,
    added_at: "2023-04-14T01:32:39Z",
    link: "https://open.spotify.com/track/3kf0WdFOalKWBkCCLJo4mA",
    userID: "royclaud",
    genres: "corrido",
  },
  {
    id: "0KUPRHjyvFMnl4XLN3q1S1",
    name: "Tan Solo Morro",
    artists: "Polo Gonzalez, Aldo Trujillo",
    duration_ms: 144545,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/d29cbea347e3a8a1a7ee5533aa5d38f4ae948831?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026772b322fe93e25a4d6a5333",
    danceability: 0.656,
    energy: 0.715,
    valence: 0.961,
    added_at: "2023-04-13T16:02:10Z",
    link: "https://open.spotify.com/track/0KUPRHjyvFMnl4XLN3q1S1",
    userID: "royclaud",
    genres: "corrido,corridos tumbados,sad sierreno,sierreno,corrido,corridos tumbados,musica mexicana,norteno,sad sierreno,sierreno",
  },
  {
    id: "3dnP0JxCgygwQH9Gm7q7nb",
    name: "Ella Baila Sola",
    artists: "Eslabon Armado, Peso Pluma",
    duration_ms: 165671,
    popularity: 76,
    preview_url: "https://p.scdn.co/mp3-preview/5d39f3e17f1e20e2711d033001e48b8d4249b992?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022071a0c79802d9375a53bfef",
    danceability: 0.668,
    energy: 0.758,
    valence: 0.834,
    added_at: "2023-04-13T15:03:45Z",
    link: "https://open.spotify.com/track/3dnP0JxCgygwQH9Gm7q7nb",
    userID: "royclaud",
    genres: "corrido,corridos tumbados,sad sierreno,sierreno,corridos tumbados,sad sierreno",
  },
  {
    id: "1VNWaY3uNfoeWqb5U8x2QX",
    name: "Lonely Day",
    artists: "System Of A Down",
    duration_ms: 167906,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/578e8890a2f1e3f61007394f18a76114eb121a8c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f5e7b2e5adaa87430a3eccff",
    danceability: 0.47,
    energy: 0.796,
    valence: 0.215,
    added_at: "2023-04-13T03:55:06Z",
    link: "https://open.spotify.com/track/1VNWaY3uNfoeWqb5U8x2QX",
    userID: "royclaud",
    genres: "alternative metal,nu metal,rap metal,rock",
  },
  {
    id: "77loZpT5Y5PRP1S451P9Yz",
    name: "The Reason",
    artists: "Hoobastank",
    duration_ms: 232800,
    popularity: 24,
    preview_url: "https://p.scdn.co/mp3-preview/31e2a53851fac8e61f947fd27da327c0493721dc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0276cc6eedca8eb381afcd1e46",
    danceability: 0.474,
    energy: 0.671,
    valence: 0.0682,
    added_at: "2023-04-13T00:54:01Z",
    link: "https://open.spotify.com/track/77loZpT5Y5PRP1S451P9Yz",
    userID: "royclaud",
    genres: "alternative metal,funk metal,nu metal,pop rock,post-grunge",
  },
  {
    id: "5odlY52u43F5BjByhxg7wg",
    name: "golden hour",
    artists: "JVKE",
    duration_ms: 209259,
    popularity: 77,
    preview_url: "https://p.scdn.co/mp3-preview/bcc17f30841d6fbf3cd7954c3e3cf669f11b6a21?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c2504e80ba2f258697ab2954",
    danceability: 0.515,
    energy: 0.593,
    valence: 0.153,
    added_at: "2023-04-11T18:35:10Z",
    link: "https://open.spotify.com/track/5odlY52u43F5BjByhxg7wg",
    userID: "royclaud",
    genres: "modern indie pop,pov: indie,singer-songwriter pop",
  },
  {
    id: "3hSsxO44l3UhkGN9bmR9Ba",
    name: "Family of Me",
    artists: "Ben Folds",
    duration_ms: 87133,
    popularity: 41,
    preview_url: "https://p.scdn.co/mp3-preview/8e38709cf2a73556ed315155a78b6755efc10205?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024869086caab391df286e4848",
    danceability: 0.443,
    energy: 0.452,
    valence: 0.298,
    added_at: "2023-04-11T17:58:40Z",
    link: "https://open.spotify.com/track/3hSsxO44l3UhkGN9bmR9Ba",
    userID: "royclaud",
    genres: "permanent wave,piano rock",
  },
  {
    id: "3jIEk8rfaM7ejBM3qsC2ob",
    name: "Still - Reprise",
    artists: "Ben Folds",
    duration_ms: 367666,
    popularity: 30,
    preview_url: "https://p.scdn.co/mp3-preview/d13673631d6b10769a9e646bd6c788d9cdc188bc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024869086caab391df286e4848",
    danceability: 0.329,
    energy: 0.455,
    valence: 0.138,
    added_at: "2023-04-11T17:58:29Z",
    link: "https://open.spotify.com/track/3jIEk8rfaM7ejBM3qsC2ob",
    userID: "royclaud",
    genres: "permanent wave,piano rock",
  },
  {
    id: "6urCAbunOQI4bLhmGpX7iS",
    name: "Don't Speak",
    artists: "No Doubt",
    duration_ms: 263560,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/895422f5af05f2213308a75a9bd24aebbda22b60?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026ebd5e789646a833b8f7d4ba",
    danceability: 0.52,
    energy: 0.709,
    valence: 0.536,
    added_at: "2023-04-06T20:33:56Z",
    link: "https://open.spotify.com/track/6urCAbunOQI4bLhmGpX7iS",
    userID: "royclaud",
    genres: "dance pop,dance rock,permanent wave,pop rock,rock",
  },
  {
    id: "2RlgNHKcydI9sayD2Df2xp",
    name: "Mr. Blue Sky",
    artists: "Electric Light Orchestra",
    duration_ms: 303373,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/2c3de8db9c9e07197796cafd81adc9eae8b3a25a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028c4e95986c803791125e8991",
    danceability: 0.388,
    energy: 0.338,
    valence: 0.478,
    added_at: "2023-04-06T15:46:55Z",
    link: "https://open.spotify.com/track/2RlgNHKcydI9sayD2Df2xp",
    userID: "royclaud",
    genres: "album rock,art rock,beatlesque,classic rock,glam rock,mellow gold,rock,soft rock,symphonic rock",
  },
  {
    id: "2FqoR7diymD1Eh7cVb3DMg",
    name: "Algo Me Gusta De Ti",
    artists: "Wisin & Yandel, Chris Brown, T-Pain",
    duration_ms: 274946,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/1c848f6e9e9d3851f1bfaaf0ae87d103287bd89e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026be6ef1173c9a2bb6705981c",
    danceability: 0.626,
    energy: 0.769,
    valence: 0.237,
    added_at: "2023-04-04T01:22:23Z",
    link: "https://open.spotify.com/track/2FqoR7diymD1Eh7cVb3DMg",
    userID: "royclaud",
    genres: "electro latino,reggaeton,trap latino,urbano latino,r&b,rap,dance pop,gangster rap,hip hop,pop rap,r&b,rap,southern hip hop,trap,urban contemporary",
  },
  {
    id: "0HOqINudNgQFpg1le5Hnqe",
    name: "Damn It Feels Good to Be a Gangsta",
    artists: "Geto Boys",
    duration_ms: 310600,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/ff685133e41610f58f630557f46ed33a19947698?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e91f3ec3f8d0fcbe8453639d",
    danceability: 0.785,
    energy: 0.793,
    valence: 0.655,
    added_at: "2023-04-02T18:22:18Z",
    link: "https://open.spotify.com/track/0HOqINudNgQFpg1le5Hnqe",
    userID: "royclaud",
    genres: "conscious hip hop,dirty south rap,g funk,gangster rap,golden age hip hop,hardcore hip hop,hip hop,old school hip hop",
  },
  {
    id: "7rTRyPvNnqgYCuWT8EcAlH",
    name: "Enter My Life",
    artists: "Two Another",
    duration_ms: 99128,
    popularity: 42,
    preview_url: "https://p.scdn.co/mp3-preview/e03c8438af4f6fb15b365402ca1596c2f7990099?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023d82fe4e589096112727af37",
    danceability: 0.55,
    energy: 0.295,
    valence: 0.355,
    added_at: "2023-03-16T23:09:36Z",
    link: "https://open.spotify.com/track/7rTRyPvNnqgYCuWT8EcAlH",
    userID: "royclaud",
    genres: "indie soul",
  },
  {
    id: "3Dp3tKFL5tn0h9LHnZAEKk",
    name: "Tenderness Kindness",
    artists: "Nate Traveller",
    duration_ms: 103282,
    popularity: 34,
    preview_url: "https://p.scdn.co/mp3-preview/02be3965d112466dfb1bcc730a473ea53421493d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b83c8c6c103bdf2890d0af19",
    danceability: 0.813,
    energy: 0.532,
    valence: 0.79,
    added_at: "2023-03-16T23:09:33Z",
    link: "https://open.spotify.com/track/3Dp3tKFL5tn0h9LHnZAEKk",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "1ifhyypscRNLhEvXkv0zEU",
    name: "Final Step",
    artists: "¿Téo?",
    duration_ms: 126413,
    popularity: 23,
    preview_url: "https://p.scdn.co/mp3-preview/02f3b1250b7f48668ef7ffe91fb8a825a5d7e01f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02206599cf04f5c318919bb547",
    danceability: 0.808,
    energy: 0.464,
    valence: 0.449,
    added_at: "2023-03-16T23:09:24Z",
    link: "https://open.spotify.com/track/1ifhyypscRNLhEvXkv0zEU",
    userID: "royclaud",
    genres: "latinx alternative",
  },
  {
    id: "5oady7OSE7dH7LHiqmb3Cr",
    name: "Dreams, Fairytales, Fantasies (feat. Brent Faiyaz & Salaam Remi)",
    artists: "A$AP Ferg, Brent Faiyaz, Salaam Remi",
    duration_ms: 222986,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/02788afc758468a99a8ed287118abb6a81217180?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0280dacac510e9d085a591f981",
    danceability: 0.737,
    energy: 0.597,
    valence: 0.449,
    added_at: "2023-03-16T21:03:34Z",
    link: "https://open.spotify.com/track/5oady7OSE7dH7LHiqmb3Cr",
    userID: "royclaud",
    genres: "hip hop,pop rap,rap,southern hip hop,trap,r&b,rap,hip hop",
  },
  {
    id: "3ruoIF2UnoXdzK8mR61ebq",
    name: "Rich Nigga Shit (feat. Young Thug)",
    artists: "21 Savage, Metro Boomin, Young Thug",
    duration_ms: 190331,
    popularity: 76,
    preview_url: "https://p.scdn.co/mp3-preview/a972368a3772747a599cfc62b86342ab0767429f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02aa57907bf0cb2ca0c8cc74bc",
    danceability: 0.825,
    energy: 0.733,
    valence: 0.62,
    added_at: "2023-03-16T20:59:55Z",
    link: "https://open.spotify.com/track/3ruoIF2UnoXdzK8mR61ebq",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap,rap,atl hip hop,atl trap,gangster rap,hip hop,melodic rap,rap,trap",
  },
  {
    id: "0DWdj2oZMBFSzRsi2Cvfzf",
    name: "TQG",
    artists: "KAROL G, Shakira",
    duration_ms: 197933,
    popularity: 82,
    preview_url: "https://p.scdn.co/mp3-preview/c520c20ae60af8c28865d865d34d8d05a65e7844?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0282de1ca074ae63cb18fce335",
    danceability: 0.587,
    energy: 0.647,
    valence: 0.686,
    added_at: "2023-03-16T19:10:30Z",
    link: "https://open.spotify.com/track/0DWdj2oZMBFSzRsi2Cvfzf",
    userID: "royclaud",
    genres: "reggaeton,reggaeton colombiano,urbano latino,colombian pop,dance pop,latin pop,pop",
  },
  {
    id: "0DX7twN59x00FtSHYPCVDt",
    name: "Phone",
    artists: "Prznt, Papithbk, NOGXNRE",
    duration_ms: 203080,
    popularity: 16,
    preview_url: "https://p.scdn.co/mp3-preview/42c315d70a50d0969dfdeec1f9a180dd301b81d2?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027e1b8285aba98bac13b16e9e",
    danceability: 0.731,
    energy: 0.468,
    valence: 0.36,
    added_at: "2023-03-16T15:24:25Z",
    link: "https://open.spotify.com/track/0DX7twN59x00FtSHYPCVDt",
    userID: "royclaud",
    genres: "australian trap,sad lo-fi,sad rap",
  },
  {
    id: "6osmPkzt0Ap9LA9lta3rOV",
    name: "Fish Maan",
    artists: "Hotel Ugly",
    duration_ms: 109714,
    popularity: 31,
    preview_url: "https://p.scdn.co/mp3-preview/9167390905fd3d14bffe266dd905fc537be35cfb?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02004c021c1dffd1b399088618",
    danceability: 0.87,
    energy: 0.603,
    valence: 0.88,
    added_at: "2023-03-03T17:40:15Z",
    link: "https://open.spotify.com/track/6osmPkzt0Ap9LA9lta3rOV",
    userID: "royclaud",
    genres: "modern indie pop",
  },
  {
    id: "60om0XhleyPKnNnmzkWR7l",
    name: "Embarrassed (feat. Travis Scott)",
    artists: "Don Toliver, Travis Scott",
    duration_ms: 192693,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/be40a23e12250a5fd161eef7934bf9738e36fefe?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02be3f799633252cc6c0c09885",
    danceability: 0.848,
    energy: 0.506,
    valence: 0.593,
    added_at: "2023-03-02T14:51:59Z",
    link: "https://open.spotify.com/track/60om0XhleyPKnNnmzkWR7l",
    userID: "royclaud",
    genres: "rap,rap,slap house",
  },
  {
    id: "26RjzokCjoe3jMXc3VnJkA",
    name: "All in My Head",
    artists: "Drex Carter",
    duration_ms: 119562,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/4962492d7806dea7c30953448ef1af9c5fb2a106?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bb7bd0518d10ed00c170fbad",
    danceability: 0.855,
    energy: 0.581,
    valence: 0.66,
    added_at: "2023-03-01T23:33:31Z",
    link: "https://open.spotify.com/track/26RjzokCjoe3jMXc3VnJkA",
    userID: "royclaud",
    genres: "sad lo-fi,sad rap",
  },
  {
    id: "364iH9PRTLwG7Lvr3MqPpa",
    name: "Real Ones",
    artists: "Kota the Friend, Statik Selektah",
    duration_ms: 228658,
    popularity: 30,
    preview_url: "https://p.scdn.co/mp3-preview/14ad49d26eb33d1a54d5e8b14400231e8595ed9e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024e0ef8014cf67f6ff7e29a25",
    danceability: 0.502,
    energy: 0.731,
    valence: 0.77,
    added_at: "2023-03-01T22:50:43Z",
    link: "https://open.spotify.com/track/364iH9PRTLwG7Lvr3MqPpa",
    userID: "royclaud",
    genres: "underground hip hop,alternative hip hop,boston hip hop",
  },
  {
    id: "78Sw5GDo6AlGwTwanjXbGh",
    name: "Here With Me",
    artists: "d4vd",
    duration_ms: 242484,
    popularity: 33,
    preview_url: "https://p.scdn.co/mp3-preview/b72e5734dd2d9382c5291d3f02f47a7d364dbe18?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02efbe96040319db682b438f11",
    danceability: 0.574,
    energy: 0.469,
    valence: 0.288,
    added_at: "2023-03-01T04:30:38Z",
    link: "https://open.spotify.com/track/78Sw5GDo6AlGwTwanjXbGh",
    userID: "royclaud",
    genres: "bedroom pop,bedroom r&b",
  },
  {
    id: "2LBqCSwhJGcFQeTHMVGwy3",
    name: "Die For You",
    artists: "The Weeknd",
    duration_ms: 260253,
    popularity: 85,
    preview_url: "https://p.scdn.co/mp3-preview/e2e1b4ba70ff241ce2ce7283a8f4c3cb086ac0bd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a048415db06a5b6fa7ec4e1a",
    danceability: 0.586,
    energy: 0.525,
    valence: 0.508,
    added_at: "2023-03-01T04:28:50Z",
    link: "https://open.spotify.com/track/2LBqCSwhJGcFQeTHMVGwy3",
    userID: "royclaud",
    genres: "canadian contemporary r&b,canadian pop,pop",
  },
  {
    id: "5mHdCZtVyb4DcJw8799hZp",
    name: "Escapism.",
    artists: "RAYE, 070 Shake",
    duration_ms: 272373,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/8a8fcaf9ddf050562048d1632ddc880c9ce7c972?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0294e5237ce925531dbb38e75f",
    danceability: 0.538,
    energy: 0.742,
    valence: 0.25,
    added_at: "2023-03-01T04:28:48Z",
    link: "https://open.spotify.com/track/5mHdCZtVyb4DcJw8799hZp",
    userID: "royclaud",
    genres: "uk contemporary r&b,uk pop,new jersey rap",
  },
  {
    id: "0WtM2NBVQNNJLh6scP13H8",
    name: "Calm Down (with Selena Gomez)",
    artists: "Rema, Selena Gomez",
    duration_ms: 239317,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/b61b32e2928081fc98d438fd8c72919ab4fb12a4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a3a7f38ea2033aa501afd4cf",
    danceability: 0.801,
    energy: 0.806,
    valence: 0.802,
    added_at: "2023-03-01T04:19:39Z",
    link: "https://open.spotify.com/track/0WtM2NBVQNNJLh6scP13H8",
    userID: "royclaud",
    genres: "afrobeats,nigerian pop,pop,post-teen pop",
  },
  {
    id: "1Qrg8KqiBpW07V7PNxwwwL",
    name: "Kill Bill",
    artists: "SZA",
    duration_ms: 153946,
    popularity: 86,
    preview_url: "https://p.scdn.co/mp3-preview/4bd2dc84016f3743add7eea8b988407b1b900672?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020c471c36970b9406233842a5",
    danceability: 0.644,
    energy: 0.735,
    valence: 0.418,
    added_at: "2023-02-28T23:22:10Z",
    link: "https://open.spotify.com/track/1Qrg8KqiBpW07V7PNxwwwL",
    userID: "royclaud",
    genres: "pop,r&b,rap",
  },
  {
    id: "4WuOWVnAqvEQxgSRrspBgt",
    name: "Niagara Falls (Foot or 2) [with Travis Scott & 21 Savage]",
    artists: "Metro Boomin, Travis Scott, 21 Savage",
    duration_ms: 207306,
    popularity: 74,
    preview_url: "https://p.scdn.co/mp3-preview/9ea22e3b26eddac6f90adaa9b4d35b38e0c3b038?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c4fee55d7b51479627c31f89",
    danceability: 0.667,
    energy: 0.648,
    valence: 0.254,
    added_at: "2023-02-28T14:45:08Z",
    link: "https://open.spotify.com/track/4WuOWVnAqvEQxgSRrspBgt",
    userID: "royclaud",
    genres: "rap,rap,slap house,atl hip hop,hip hop,rap",
  },
  {
    id: "0VO8gYVDSwM1Qdd2GsMoYK",
    name: "Moth To A Flame (with The Weeknd)",
    artists: "Swedish House Mafia, The Weeknd",
    duration_ms: 234000,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/1115f3c666a5ec714e6c3a29121c863720c4059c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bd6f8ac82dc5fe1028c14f7d",
    danceability: 0.553,
    energy: 0.659,
    valence: 0.105,
    added_at: "2023-02-27T20:15:10Z",
    link: "https://open.spotify.com/track/0VO8gYVDSwM1Qdd2GsMoYK",
    userID: "royclaud",
    genres: "edm,pop dance,progressive electro house,canadian contemporary r&b,canadian pop,pop",
  },
  {
    id: "5HiA7W5wYDWi06UZFUluRu",
    name: "Por las Noches - Remix",
    artists: "Peso Pluma, Nicki Nicole",
    duration_ms: 222551,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/6eea732dbc7f82211d27c9113c3734404b144dee?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e15a25559babbcd599af07d9",
    danceability: 0.656,
    energy: 0.652,
    valence: 0.222,
    added_at: "2023-02-27T19:32:05Z",
    link: "https://open.spotify.com/track/5HiA7W5wYDWi06UZFUluRu",
    userID: "royclaud",
    genres: "corridos tumbados,sad sierreno,argentine hip hop,r&b argentino,trap argentino,trap latino,urbano latino",
  },
  {
    id: "0Ss50OU9tCozI7JIywkv14",
    name: "Soul Survivor",
    artists: "Jeezy, Akon",
    duration_ms: 280013,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/0b8bdcaf946becbfa7f8f05c27d4c288e45bc54d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e647a3be0956a5d9769f0444",
    danceability: 0.66,
    energy: 0.483,
    valence: 0.107,
    added_at: "2023-02-27T01:32:24Z",
    link: "https://open.spotify.com/track/0Ss50OU9tCozI7JIywkv14",
    userID: "royclaud",
    genres: "atl hip hop,crunk,dirty south rap,gangster rap,old school atlanta hip hop,rap,southern hip hop,trap,dance pop",
  },
  {
    id: "2Grb4G6t9VIqo6moKUloom",
    name: "Don't Come Out The House (with 21 Savage)",
    artists: "Metro Boomin, 21 Savage",
    duration_ms: 168346,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/4245103b06494fe77d2e43ea6802030a608226e9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022887f8c05b5a9f1cb105be29",
    danceability: 0.87,
    energy: 0.35,
    valence: 0.175,
    added_at: "2023-02-26T20:25:56Z",
    link: "https://open.spotify.com/track/2Grb4G6t9VIqo6moKUloom",
    userID: "royclaud",
    genres: "rap,atl hip hop,hip hop,rap",
  },
  {
    id: "4t5lrf5rFX6JWAUPKCDlKk",
    name: "The Safety Dance",
    artists: "Men Without Hats",
    duration_ms: 165506,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/415b1287360b8b124431bda592769d9a50c5434f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02682a1a12fa1ffe348e03a328",
    danceability: 0.632,
    energy: 0.582,
    valence: 0.576,
    added_at: "2023-02-24T23:15:00Z",
    link: "https://open.spotify.com/track/4t5lrf5rFX6JWAUPKCDlKk",
    userID: "royclaud",
    genres: "classic canadian rock,new romantic,new wave pop,synthpop",
  },
  {
    id: "1ZMVzS6SHQa5awuQkM8VCj",
    name: "Control (feat. Gary Go)",
    artists: "Benny Benassi, Gary Go",
    duration_ms: 209280,
    popularity: 22,
    preview_url: "https://p.scdn.co/mp3-preview/1dce87ac7a61aa66ff4090a1cad104ee1f1f46e4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026cfb4fae18c381d2706b14d1",
    danceability: 0.5,
    energy: 0.863,
    valence: 0.362,
    added_at: "2023-02-23T02:09:04Z",
    link: "https://open.spotify.com/track/1ZMVzS6SHQa5awuQkM8VCj",
    userID: "royclaud",
    genres: "dutch house,edm,electro house,pop dance",
  },
  {
    id: "12A2FvmVADgyqNdC2rdg8m",
    name: "99 Luftballons",
    artists: "Nena",
    duration_ms: 232466,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/c4c8b07136c8d99f1f1ba95f43162e1774e8b30c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ea78625d10d2366d6b681f7e",
    danceability: 0.575,
    energy: 0.555,
    valence: 0.504,
    added_at: "2023-02-23T02:03:33Z",
    link: "https://open.spotify.com/track/12A2FvmVADgyqNdC2rdg8m",
    userID: "royclaud",
    genres: "german pop,new wave pop",
  },
  {
    id: "20bJBbPapGQ4bqs0YcA9xY",
    name: "Just What I Am",
    artists: "Kid Cudi, King Chip",
    duration_ms: 228026,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/1fc2d18bf7c50cfd2a15c124209238c0bb43d191?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b9ab5cc935d2327a7d2bc629",
    danceability: 0.654,
    energy: 0.949,
    valence: 0.352,
    added_at: "2023-02-21T02:32:08Z",
    link: "https://open.spotify.com/track/20bJBbPapGQ4bqs0YcA9xY",
    userID: "royclaud",
    genres: "hip hop,ohio hip hop,pop rap,rap,ohio hip hop",
  },
  {
    id: "4820SC1xnUaabzzYQTDkP2",
    name: "Gnossienne: No. 1",
    artists: "Erik Satie, Alexandre Tharaud",
    duration_ms: 214133,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/3b8cb3c40d0d47bd5df40463440b6d481653e20d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02949cc0e6728be2f6601eb7ea",
    danceability: 0.424,
    energy: 0.00798,
    valence: 0.0794,
    added_at: "2023-02-20T22:25:18Z",
    link: "https://open.spotify.com/track/4820SC1xnUaabzzYQTDkP2",
    userID: "royclaud",
    genres: "classical,early avant garde,early modern classical,impressionism,post-romantic era,classical piano,french classical piano",
  },
  {
    id: "6HSqyfGnsHYw9MmIpa9zlZ",
    name: "Forever",
    artists: "Drake, Kanye West, Lil Wayne, Eminem",
    duration_ms: 357346,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/9e070257ff65be4a2a1a67813528ae50cdd73c65?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027c22c8b9a5cfe27cd9914c4c",
    danceability: 0.415,
    energy: 0.926,
    valence: 0.542,
    added_at: "2023-02-20T21:12:41Z",
    link: "https://open.spotify.com/track/6HSqyfGnsHYw9MmIpa9zlZ",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap,chicago rap,hip hop,rap,hip hop,new orleans rap,pop rap,rap,trap,detroit hip hop,hip hop,rap",
  },
  {
    id: "5uDASfU19gDxSjW8cnCaBp",
    name: "Chill Bill (feat. J. Davi$ & Spooks)",
    artists: "Rob $tone, J. Davi$, J Spooks",
    duration_ms: 177184,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/2449fed0346a67a3590a5c9595ef7fa5b9210137?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026f3e9696889a2425338e0836",
    danceability: 0.886,
    energy: 0.427,
    valence: 0.23,
    added_at: "2023-02-20T17:18:31Z",
    link: "https://open.spotify.com/track/5uDASfU19gDxSjW8cnCaBp",
    userID: "royclaud",
    genres: "san diego rap,viral trap",
  },
  {
    id: "4yNk9iz9WVJikRFle3XEvn",
    name: "golden hour",
    artists: "JVKE",
    duration_ms: 209259,
    popularity: 76,
    preview_url: "https://p.scdn.co/mp3-preview/bcc17f30841d6fbf3cd7954c3e3cf669f11b6a21?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020af4476af141051c728ee8b9",
    danceability: 0.515,
    energy: 0.593,
    valence: 0.153,
    added_at: "2023-02-20T16:54:22Z",
    link: "https://open.spotify.com/track/4yNk9iz9WVJikRFle3XEvn",
    userID: "royclaud",
    genres: "modern indie pop,pov: indie,singer-songwriter pop",
  },
  {
    id: "2JmFRXaJrkAUD2cs6U20KG",
    name: "Jet Fuel",
    artists: "Mac Miller",
    duration_ms: 345213,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/83161ecabcd43f0e26a93d275c9558f3b5eeed37?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02175c577a61aa13d4fb4b6534",
    danceability: 0.791,
    energy: 0.557,
    valence: 0.284,
    added_at: "2023-02-18T22:58:56Z",
    link: "https://open.spotify.com/track/2JmFRXaJrkAUD2cs6U20KG",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "4V4hP1FCrFDuDOx7s5KKtY",
    name: "The One",
    artists: "Nippa, SHOW N PROVE",
    duration_ms: 136813,
    popularity: 30,
    preview_url: "https://p.scdn.co/mp3-preview/574b2a145ec79c7563b85b882e8e6357f287e383?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02594828a169fc977dc1e23d17",
    danceability: 0.571,
    energy: 0.549,
    valence: 0.779,
    added_at: "2023-02-18T02:55:47Z",
    link: "https://open.spotify.com/track/4V4hP1FCrFDuDOx7s5KKtY",
    userID: "royclaud",
    genres: "r&drill",
  },
  {
    id: "6egzJXHNyWR0wYFbVGhOF6",
    name: "Cinch",
    artists: "RIZ LA VIE",
    duration_ms: 122926,
    popularity: 26,
    preview_url: "https://p.scdn.co/mp3-preview/b86e41afcde1d1b34bbed2cd23a7e3ac5d2c901b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e38870feaefeb6b9a63d7633",
    danceability: 0.632,
    energy: 0.515,
    valence: 0.364,
    added_at: "2023-02-17T22:46:25Z",
    link: "https://open.spotify.com/track/6egzJXHNyWR0wYFbVGhOF6",
    userID: "royclaud",
    genres: "bedroom soul",
  },
  {
    id: "2ekvSQupVG7X88fjjdYScf",
    name: "Times Are Changing",
    artists: "Astrality, Thandi",
    duration_ms: 158819,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/d0840cd41bfee60c94510afbe0fb922e8ca456e1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02309cdb3f797f58db6e0465b2",
    danceability: 0.768,
    energy: 0.593,
    valence: 0.0713,
    added_at: "2023-02-17T22:41:46Z",
    link: "https://open.spotify.com/track/2ekvSQupVG7X88fjjdYScf",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "6qALKVVHrW0VU29ZGiA5uL",
    name: "Down",
    artists: "Dillistone",
    duration_ms: 167625,
    popularity: 40,
    preview_url: "https://p.scdn.co/mp3-preview/32a8d3dce013ff257a2b066c48ae6117200305ee?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0288131efaf05471eb4a6899c4",
    danceability: 0.787,
    energy: 0.455,
    valence: 0.315,
    added_at: "2023-02-17T22:36:46Z",
    link: "https://open.spotify.com/track/6qALKVVHrW0VU29ZGiA5uL",
    userID: "royclaud",
    genres: "pop edm",
  },
  {
    id: "5P874Jw02nufpahfrOlkik",
    name: "Juju Woman",
    artists: "Labrinth",
    duration_ms: 48053,
    popularity: 45,
    preview_url: "https://p.scdn.co/mp3-preview/ce1c5aba0abcb7c8ebd7431e54d2e36e6525923c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0243bc512b34c0dba811c70e12",
    danceability: 0.415,
    energy: 0.0851,
    valence: 0.451,
    added_at: "2023-02-17T22:35:30Z",
    link: "https://open.spotify.com/track/5P874Jw02nufpahfrOlkik",
    userID: "royclaud",
    genres: "indie poptimism,pop",
  },
  {
    id: "1SWC2QO7JQJdRH7QqFy3vl",
    name: "Saturday Night",
    artists: "Effemar, Nina Carr",
    duration_ms: 160000,
    popularity: 43,
    preview_url: "https://p.scdn.co/mp3-preview/72b8dd179cf977ddf03a3f1c575ec0c74e9b9672?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0233717e5272406b722eda41b2",
    danceability: 0.79,
    energy: 0.633,
    valence: 0.833,
    added_at: "2023-02-17T18:20:22Z",
    link: "https://open.spotify.com/track/1SWC2QO7JQJdRH7QqFy3vl",
    userID: "royclaud",
    genres: "chill house,tropical house",
  },
  {
    id: "4dtMOa6k8NX6ug6JIC8lmR",
    name: "• SOUL SEARCHER",
    artists: "Jazz Cartier",
    duration_ms: 124226,
    popularity: 35,
    preview_url: "https://p.scdn.co/mp3-preview/4bb9505d625345fb50d25d8458acd26ef76d1b83?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02946c290233d02b3b3b290952",
    danceability: 0.415,
    energy: 0.55,
    valence: 0.601,
    added_at: "2023-02-16T21:48:16Z",
    link: "https://open.spotify.com/track/4dtMOa6k8NX6ug6JIC8lmR",
    userID: "royclaud",
    genres: "canadian hip hop,canadian trap,underground hip hop",
  },
  {
    id: "3Te3gG6MPEDNqLGzWrjKC3",
    name: "Nothing Without You",
    artists: "The Weeknd",
    duration_ms: 198653,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/e67dcf5f325a0be6a9ec6244512a4ca2b95be2b7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a048415db06a5b6fa7ec4e1a",
    danceability: 0.599,
    energy: 0.635,
    valence: 0.194,
    added_at: "2023-02-16T20:14:07Z",
    link: "https://open.spotify.com/track/3Te3gG6MPEDNqLGzWrjKC3",
    userID: "royclaud",
    genres: "canadian contemporary r&b,canadian pop,pop",
  },
  {
    id: "1mhVXWduD8ReDwusfaHNwU",
    name: "Tell Your Friends",
    artists: "The Weeknd",
    duration_ms: 334333,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/f00eba2c9475e89b364878c31edcbdef21f0960a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027fcead687e99583072cc217b",
    danceability: 0.583,
    energy: 0.454,
    valence: 0.309,
    added_at: "2023-02-16T17:13:26Z",
    link: "https://open.spotify.com/track/1mhVXWduD8ReDwusfaHNwU",
    userID: "royclaud",
    genres: "canadian contemporary r&b,canadian pop,pop",
  },
  {
    id: "2gpWyfu7eZ01zzncHpxOtA",
    name: "Moth To A Flame (with The Weeknd)",
    artists: "Swedish House Mafia, The Weeknd",
    duration_ms: 234000,
    popularity: 16,
    preview_url: "https://p.scdn.co/mp3-preview/1115f3c666a5ec714e6c3a29121c863720c4059c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022751f15ff6c8174e540b6fb4",
    danceability: 0.542,
    energy: 0.659,
    valence: 0.109,
    added_at: "2023-02-16T17:00:28Z",
    link: "https://open.spotify.com/track/2gpWyfu7eZ01zzncHpxOtA",
    userID: "royclaud",
    genres: "edm,pop dance,progressive electro house,canadian contemporary r&b,canadian pop,pop",
  },
  {
    id: "3KM0368RZYg0MPr0wHDeqS",
    name: "Mutombo",
    artists: "Dwn2earth",
    duration_ms: 221806,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/02207b995f8934e83f694c8ac0c80221d02f6eaf?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028f7db6359fc5559a411bfa23",
    danceability: 0.603,
    energy: 0.581,
    valence: 0.163,
    added_at: "2023-02-16T15:23:07Z",
    link: "https://open.spotify.com/track/3KM0368RZYg0MPr0wHDeqS",
    userID: "royclaud",
    genres: "trap soul",
  },
  {
    id: "1170VohRSx6GwE6QDCHPPH",
    name: "Kilby Girl",
    artists: "The Backseat Lovers",
    duration_ms: 282205,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/2e1fb49dc9c9d3ec9b40d6fb1df6b8ca044cc33e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028386795a636d72e74c958590",
    danceability: 0.329,
    energy: 0.444,
    valence: 0.225,
    added_at: "2023-02-16T01:29:57Z",
    link: "https://open.spotify.com/track/1170VohRSx6GwE6QDCHPPH",
    userID: "royclaud",
    genres: "pov: indie,slc indie",
  },
  {
    id: "3iBgrkexCzVuPy4O9vx7Mf",
    name: "Glue Song",
    artists: "beabadoobee",
    duration_ms: 135066,
    popularity: 79,
    preview_url: "https://p.scdn.co/mp3-preview/2dbe14c459b8cf4414f3d5b21e1cb8cc6b85886f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e3862aeefcb2f0860ef017e4",
    danceability: 0.62,
    energy: 0.409,
    valence: 0.582,
    added_at: "2023-02-15T22:58:03Z",
    link: "https://open.spotify.com/track/3iBgrkexCzVuPy4O9vx7Mf",
    userID: "royclaud",
    genres: "bedroom pop,bubblegrunge,indie pop,pov: indie",
  },
  {
    id: "6gyeaab6u8qGxz1nbY4Jjh",
    name: "Slow Hours",
    artists: "weird inside",
    duration_ms: 147017,
    popularity: 35,
    preview_url: "https://p.scdn.co/mp3-preview/64aa6c76eb5695783bb69d9438c3d3c75424deba?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02281d82fc71f28f0c068d3f07",
    danceability: 0.487,
    energy: 0.398,
    valence: 0.0456,
    added_at: "2023-02-15T22:51:55Z",
    link: "https://open.spotify.com/track/6gyeaab6u8qGxz1nbY4Jjh",
    userID: "royclaud",
    genres: "chillhop,vapor twitch",
  },
  {
    id: "2FhEj9lvEIaJihjFprRrGt",
    name: "Snitching (feat. Quavo & Future)",
    artists: "Pop Smoke, Quavo, Future",
    duration_ms: 259346,
    popularity: 50,
    preview_url: "https://p.scdn.co/mp3-preview/4ea34e0e8ff922f89231e864d07926d3db9ff6bd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0277ada0863603903f57b34369",
    danceability: 0.944,
    energy: 0.579,
    valence: 0.769,
    added_at: "2023-02-15T22:51:23Z",
    link: "https://open.spotify.com/track/2FhEj9lvEIaJihjFprRrGt",
    userID: "royclaud",
    genres: "brooklyn drill,hip hop,rap,atl hip hop,melodic rap,rap,trap,atl hip hop,hip hop,rap,southern hip hop,trap",
  },
  {
    id: "2Y67qsABsPKMrvCxPCzL6r",
    name: "We Go Down Together (with Khalid)",
    artists: "Dove Cameron, Khalid",
    duration_ms: 184963,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/6075c2541463823004d722c0953707e6e5e12eb8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0201c4d0a569ce0b249012a99f",
    danceability: 0.224,
    energy: 0.387,
    valence: 0.0752,
    added_at: "2023-02-14T21:10:12Z",
    link: "https://open.spotify.com/track/2Y67qsABsPKMrvCxPCzL6r",
    userID: "royclaud",
    genres: "pop,pop,pop r&b",
  },
  {
    id: "595BZxt2BfMHilwFlgMRQW",
    name: "Stay",
    artists: "Papithbk, NOGXNRE, Adrian Chafer",
    duration_ms: 132153,
    popularity: 18,
    preview_url: "https://p.scdn.co/mp3-preview/405e49a9a16461a0b351a374567e38c148efe256?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bb3fb424aafdf02354f2bfb8",
    danceability: 0.833,
    energy: 0.621,
    valence: 0.797,
    added_at: "2023-02-14T17:02:46Z",
    link: "https://open.spotify.com/track/595BZxt2BfMHilwFlgMRQW",
    userID: "royclaud",
    genres: "australian trap,sad lo-fi,sad rap",
  },
  {
    id: "65HfR1BpotW0ES46KD6Gv8",
    name: "Escape",
    artists: "Kilgore Doubtfire",
    duration_ms: 280749,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/4eabfde2045930e527529afeb89dfa364435ed77?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02967f2ad7de0f141c1c0a355f",
    danceability: 0.364,
    energy: 0.16,
    valence: 0.0657,
    added_at: "2023-02-13T16:45:11Z",
    link: "https://open.spotify.com/track/65HfR1BpotW0ES46KD6Gv8",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "2DfRgLfkNb8nU1wMcMK0fD",
    name: "She's A 10 But...",
    artists: "ARTAN, Spencer Elmer",
    duration_ms: 165428,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/68efb8ba2ea0edf6bb874d8eb89116f06f89ad2f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02dac5f3a1545f4cdf8a94d7a3",
    danceability: 0.929,
    energy: 0.366,
    valence: 0.888,
    added_at: "2023-02-13T15:05:44Z",
    link: "https://open.spotify.com/track/2DfRgLfkNb8nU1wMcMK0fD",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "4yg2dkVMQX7HIqiM7c0iEZ",
    name: "LOLSMH II",
    artists: "WESTSIDE BOOGIE",
    duration_ms: 144147,
    popularity: 42,
    preview_url: "https://p.scdn.co/mp3-preview/f3b5b9e8be3101de83b69fc98e1a234ec6da8434?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c44272e5ff22c0b5256a1f83",
    danceability: 0.522,
    energy: 0.638,
    valence: 0.423,
    added_at: "2023-02-12T02:50:02Z",
    link: "https://open.spotify.com/track/4yg2dkVMQX7HIqiM7c0iEZ",
    userID: "royclaud",
    genres: "canadian hip hop,deep underground hip hop,indie hip hop,underground hip hop",
  },
  {
    id: "2mWlmAlggbl6aveaHRBSMu",
    name: "new info",
    artists: "redveil",
    duration_ms: 129350,
    popularity: 43,
    preview_url: "https://p.scdn.co/mp3-preview/0e1971c9cc081495d1f540d78a64a25fff21cc29?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022bdeda3cae04cb2e26b8ef63",
    danceability: 0.381,
    energy: 0.743,
    valence: 0.33,
    added_at: "2023-02-12T01:53:54Z",
    link: "https://open.spotify.com/track/2mWlmAlggbl6aveaHRBSMu",
    userID: "royclaud",
    genres: "chill abstract hip hop,indie hip hop",
  },
  {
    id: "2P1oNlBGmFudn3ZyUrj7hJ",
    name: "KAPITOL DENIM",
    artists: "LUCKI, Future",
    duration_ms: 117000,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/5a9cd3f3501d8cbdf775fd861215864d49f16e76?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ba1e08a3179fdf5a5125fe63",
    danceability: 0.79,
    energy: 0.457,
    valence: 0.783,
    added_at: "2023-02-12T01:22:54Z",
    link: "https://open.spotify.com/track/2P1oNlBGmFudn3ZyUrj7hJ",
    userID: "royclaud",
    genres: "plugg,atl hip hop,hip hop,rap,southern hip hop,trap",
  },
  {
    id: "5yY9lUy8nbvjM1Uyo1Uqoc",
    name: "Life Is Good (feat. Drake)",
    artists: "Future, Drake",
    duration_ms: 237735,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/f7e8ac5cc4f6b702718ac3e2e280e270ac16facc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028a01c7b77a34378a62f46402",
    danceability: 0.676,
    energy: 0.609,
    valence: 0.508,
    added_at: "2023-02-12T01:09:29Z",
    link: "https://open.spotify.com/track/5yY9lUy8nbvjM1Uyo1Uqoc",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap,southern hip hop,trap,canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "7vggqxNKwd6xdRoYS0pQtM",
    name: "Three Little Birds",
    artists: "Bob Marley & The Wailers",
    duration_ms: 180440,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/4c5ffb20837e7a3e4dd7915fa3ea513eed914370?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02413a6c2c7b296d98171e5e21",
    danceability: 0.825,
    energy: 0.494,
    valence: 0.627,
    added_at: "2023-02-10T03:20:23Z",
    link: "https://open.spotify.com/track/7vggqxNKwd6xdRoYS0pQtM",
    userID: "royclaud",
    genres: "reggae,roots reggae",
  },
  {
    id: "7fuKPn33GxRh62aC8n71M3",
    name: "A Rose Blooms in Chaos",
    artists: "Davishmar",
    duration_ms: 116819,
    popularity: 3,
    preview_url: "https://p.scdn.co/mp3-preview/05e23ed815a7c2f9cb48131479dc4a38434c6e2a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0241e6704110ce2a694d5bbee2",
    danceability: 0.311,
    energy: 0.444,
    valence: 0.734,
    added_at: "2023-02-10T02:01:46Z",
    link: "https://open.spotify.com/track/7fuKPn33GxRh62aC8n71M3",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "4ygQUye7obtqhSltSxp18H",
    name: "Pusha Man",
    artists: "Chance the Rapper, Nate Fox",
    duration_ms: 139406,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/fb7c4dffec2160c0f65edeff620dd57610d4b7e6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d95ab48a8a9de3c4a2cbfe80",
    danceability: 0.768,
    energy: 0.569,
    valence: 0.883,
    added_at: "2023-02-10T02:01:34Z",
    link: "https://open.spotify.com/track/4ygQUye7obtqhSltSxp18H",
    userID: "royclaud",
    genres: "chicago rap,conscious hip hop,hip hop,pop rap,rap,trap",
  },
  {
    id: "3Tj8mIbVyzqZpmsJQgI6zI",
    name: "ABRE SUS OJOS",
    artists: "ESosa",
    duration_ms: 117185,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/d124ab1e6280fa0924253c68f935898ff4d4eb5f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027a4945e504079738cf47711f",
    danceability: 0.521,
    energy: 0.692,
    valence: 0.614,
    added_at: "2023-02-08T23:40:00Z",
    link: "https://open.spotify.com/track/3Tj8mIbVyzqZpmsJQgI6zI",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "7bwRcQqvqAhA2W6uOiuQeF",
    name: "sorry i've been m.i.a",
    artists: "ESosa",
    duration_ms: 177214,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/7c9942a19a1a5fded9bca02abec6e988a51ec4fd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027a4945e504079738cf47711f",
    danceability: 0.669,
    energy: 0.598,
    valence: 0.495,
    added_at: "2023-02-08T23:38:13Z",
    link: "https://open.spotify.com/track/7bwRcQqvqAhA2W6uOiuQeF",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "6cuBPaQqwtELXv7sZiXCHe",
    name: "Soft",
    artists: "Marlon Craft",
    duration_ms: 170156,
    popularity: 27,
    preview_url: "https://p.scdn.co/mp3-preview/64d15f1e0ee51b233fad7bfe80ed19e4ab0d6cc3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020ebb66ea5a9efd5c769ba7e0",
    danceability: 0.651,
    energy: 0.567,
    valence: 0.758,
    added_at: "2023-02-06T20:10:58Z",
    link: "https://open.spotify.com/track/6cuBPaQqwtELXv7sZiXCHe",
    userID: "royclaud",
    genres: "nyc rap",
  },
  {
    id: "7st9RtO2cQvPrse3iedwlj",
    name: "Getover",
    artists: "j^p^n",
    duration_ms: 79448,
    popularity: 35,
    preview_url: "https://p.scdn.co/mp3-preview/d6555659c146df9acd1c9769eb18b4aa9f0bdb75?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02eea8172ccebc3bff952e92bf",
    danceability: 0.709,
    energy: 0.253,
    valence: 0.367,
    added_at: "2023-02-06T16:19:58Z",
    link: "https://open.spotify.com/track/7st9RtO2cQvPrse3iedwlj",
    userID: "royclaud",
    genres: "weirdcore",
  },
  {
    id: "05l9BiAJidDs9u6b0yVrxl",
    name: "Lock & Key",
    artists: "Demrick, DJ Hoppa",
    duration_ms: 148933,
    popularity: 30,
    preview_url: "https://p.scdn.co/mp3-preview/2e4783f2a192b1ae44ee8d3d0b6d4e8d3ff9132b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bf16d131a9da5ef80af70181",
    danceability: 0.652,
    energy: 0.758,
    valence: 0.746,
    added_at: "2023-02-06T16:17:52Z",
    link: "https://open.spotify.com/track/05l9BiAJidDs9u6b0yVrxl",
    userID: "royclaud",
    genres: "deep underground hip hop,deep underground hip hop",
  },
  {
    id: "6EiCu3kEJVj19JhS6Adsk6",
    name: "Risk Interlude",
    artists: "SORIANO",
    duration_ms: 125647,
    popularity: 29,
    preview_url: "https://p.scdn.co/mp3-preview/12662f0c6f1c3bea1344f883cb9c5f6f9a7e3264?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0220db4836bd1fafc071578d8a",
    danceability: 0.911,
    energy: 0.389,
    valence: 0.51,
    added_at: "2023-02-06T16:14:37Z",
    link: "https://open.spotify.com/track/6EiCu3kEJVj19JhS6Adsk6",
    userID: "royclaud",
    genres: "trap soul",
  },
  {
    id: "7BndlSmNYV6rpcoMbaM0rA",
    name: "comfy chair",
    artists: "Jaeden Camstra",
    duration_ms: 97781,
    popularity: 35,
    preview_url: "https://p.scdn.co/mp3-preview/8858f27249db4cf02688f713aa3dce7f7ab26480?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021292c8c9653dc76abb3884da",
    danceability: 0.801,
    energy: 0.402,
    valence: 0.536,
    added_at: "2023-02-06T03:23:22Z",
    link: "https://open.spotify.com/track/7BndlSmNYV6rpcoMbaM0rA",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "1vgH8Pxm88SZAtNDFj1pKT",
    name: "love",
    artists: "lofi.samurai",
    duration_ms: 81037,
    popularity: 50,
    preview_url: "https://p.scdn.co/mp3-preview/c527edfde9d905e3c3fc553fb1a2eee03232dd2a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028add0cf6339953dcfe407d78",
    danceability: 0.864,
    energy: 0.482,
    valence: 0.964,
    added_at: "2023-02-06T02:50:52Z",
    link: "https://open.spotify.com/track/1vgH8Pxm88SZAtNDFj1pKT",
    userID: "royclaud",
    genres: "japanese chillhop",
  },
  {
    id: "3VPgTDgaja02x2idg7CLZ3",
    name: "Fantasy",
    artists: "90sFlav",
    duration_ms: 102875,
    popularity: 47,
    preview_url: "https://p.scdn.co/mp3-preview/94b9424c0b7b37d8c24fb1d4f4e6b97561ac4cbe?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029f37b681f74515c0e2e49dcc",
    danceability: 0.794,
    energy: 0.287,
    valence: 0.247,
    added_at: "2023-02-06T02:46:37Z",
    link: "https://open.spotify.com/track/3VPgTDgaja02x2idg7CLZ3",
    userID: "royclaud",
    genres: "lo-fi beats",
  },
  {
    id: "1ODWooJNGtKhnzSR7ggEC7",
    name: "Fell in Love",
    artists: "Alx Beats",
    duration_ms: 141691,
    popularity: 34,
    preview_url: "https://p.scdn.co/mp3-preview/e30df157413bc60b157b5ff11463f3a67c941efe?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021ca92795cd8efedcf15bab81",
    danceability: 0.65,
    energy: 0.385,
    valence: 0.447,
    added_at: "2023-02-06T02:44:02Z",
    link: "https://open.spotify.com/track/1ODWooJNGtKhnzSR7ggEC7",
    userID: "royclaud",
    genres: "drift phonk,weirdcore",
  },
  {
    id: "5KMuRZCUZt6veUEJ6VbJep",
    name: "tranquility",
    artists: "Never Get Used To People",
    duration_ms: 122890,
    popularity: 9,
    preview_url: "https://p.scdn.co/mp3-preview/66d1996c025edb92706cebebf12864ff12839e43?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023bf41eac1836d53d903b135e",
    danceability: 0.671,
    energy: 0.3,
    valence: 0.587,
    added_at: "2023-02-06T02:08:38Z",
    link: "https://open.spotify.com/track/5KMuRZCUZt6veUEJ6VbJep",
    userID: "royclaud",
    genres: "weirdcore",
  },
  {
    id: "18kOgPYJgcMCpurna8P23T",
    name: "3AM in New Orleans",
    artists: "A$AP ANT, Curren$y",
    duration_ms: 133679,
    popularity: 53,
    preview_url: "https://p.scdn.co/mp3-preview/6aeb261e821e03b3e920e886154ef109d75d1761?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02767a4e74aaae42ec8f70772c",
    danceability: 0.649,
    energy: 0.647,
    valence: 0.844,
    added_at: "2023-02-04T04:02:07Z",
    link: "https://open.spotify.com/track/18kOgPYJgcMCpurna8P23T",
    userID: "royclaud",
    genres: "baltimore hip hop,drill,underground hip hop,dirty south rap,new orleans rap,southern hip hop,underground hip hop",
  },
  {
    id: "1Qkjv8kHlYsPrjDiilSmKx",
    name: "1997",
    artists: "Key Glock",
    duration_ms: 142960,
    popularity: 52,
    preview_url: "https://p.scdn.co/mp3-preview/97e98e085d60f45abf6f5e89c8e384f112e422ea?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0277d6678d66fd48ecfed2cfe8",
    danceability: 0.865,
    energy: 0.735,
    valence: 0.478,
    added_at: "2023-02-04T01:30:21Z",
    link: "https://open.spotify.com/track/1Qkjv8kHlYsPrjDiilSmKx",
    userID: "royclaud",
    genres: "memphis hip hop,rap,southern hip hop,tennessee hip hop,trap",
  },
  {
    id: "2E4INiVkDRBXXkFL4kB1Vk",
    name: "highway 95",
    artists: "Baby Keem",
    duration_ms: 91782,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/df6005e294d94dff2fa0468f56dc0dd72a1cb4e2?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c6b31d9de8df430efab41836",
    danceability: 0.77,
    energy: 0.836,
    valence: 0.425,
    added_at: "2023-02-03T23:44:27Z",
    link: "https://open.spotify.com/track/2E4INiVkDRBXXkFL4kB1Vk",
    userID: "royclaud",
    genres: "hip hop,rap",
  },
  {
    id: "2mmUoyPxzbxehpfm1TpTRK",
    name: "Star67",
    artists: "Drake",
    duration_ms: 294973,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/3c1ba33dd30baaf0c521de7f075eec1d8c31c448?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022090f4f6cc406e6d3c306733",
    danceability: 0.521,
    energy: 0.392,
    valence: 0.166,
    added_at: "2023-02-03T23:10:27Z",
    link: "https://open.spotify.com/track/2mmUoyPxzbxehpfm1TpTRK",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "30hwT0deSgqiaBMxijn35R",
    name: "Action Figures Fighting",
    artists: "Hotel Ugly",
    duration_ms: 163413,
    popularity: 37,
    preview_url: "https://p.scdn.co/mp3-preview/62a304af5f1eab94ccfb83792507302565e1d7e1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b2e808ad9d4c5e6442c405aa",
    danceability: 0.735,
    energy: 0.414,
    valence: 0.753,
    added_at: "2023-02-02T14:41:00Z",
    link: "https://open.spotify.com/track/30hwT0deSgqiaBMxijn35R",
    userID: "royclaud",
    genres: "modern indie pop",
  },
  {
    id: "7CAgak4ycIZvKqtLZjtIKW",
    name: "Palm Trees",
    artists: "¿Téo?",
    duration_ms: 226132,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/fcc9bd99e6ab8b225965268c35820f3564bb3d33?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d636df0dfaf90530035cfe1c",
    danceability: 0.719,
    energy: 0.499,
    valence: 0.0439,
    added_at: "2023-02-01T16:02:47Z",
    link: "https://open.spotify.com/track/7CAgak4ycIZvKqtLZjtIKW",
    userID: "royclaud",
    genres: "latinx alternative",
  },
  {
    id: "3A4S0o95OxSgRbqEnEMsn4",
    name: "Life We Live (feat. Namond Lumpkin & Edgar Fletcher)",
    artists: "Project Pat, Namond Lumpkin, Edgar Fletcher",
    duration_ms: 254373,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/de40ed442bafcee3d91876d3c4b1a85e4abcf49a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0231a9a92a652223ca76817a90",
    danceability: 0.883,
    energy: 0.633,
    valence: 0.241,
    added_at: "2023-02-01T14:45:42Z",
    link: "https://open.spotify.com/track/3A4S0o95OxSgRbqEnEMsn4",
    userID: "royclaud",
    genres: "crunk,dirty south rap,gangster rap,memphis hip hop,trap",
  },
  {
    id: "4xkWXlkjVuGOIDMrrePySE",
    name: "gang",
    artists: "mike.",
    duration_ms: 148000,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/93a119312ffe059a2ed438c7444e1437984f98f5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024021df765e3ab3a966dfaaec",
    danceability: 0.763,
    energy: 0.428,
    valence: 0.225,
    added_at: "2023-01-29T19:09:41Z",
    link: "https://open.spotify.com/track/4xkWXlkjVuGOIDMrrePySE",
    userID: "royclaud",
    genres: "indie pop rap,rhode island rap",
  },
  {
    id: "4b3nrmlLfQloDmY3fGq35S",
    name: "Heaven Interlude",
    artists: "SUMMER ALONE",
    duration_ms: 120048,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/7325ffa24f3278cb3d1d1977012f7fa9a9432c28?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0248daf13073fed21e0df3d911",
    danceability: 0.59,
    energy: 0.395,
    valence: 0.254,
    added_at: "2023-01-29T19:07:51Z",
    link: "https://open.spotify.com/track/4b3nrmlLfQloDmY3fGq35S",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "1mfirmxz5w1PFmOZNaU7sz",
    name: "Lonely",
    artists: "Rodionis",
    duration_ms: 129250,
    popularity: 30,
    preview_url: "https://p.scdn.co/mp3-preview/314772a8fb124ff1c1c300788048e07246d6407b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0281a999b6c862f23e6fcd41ce",
    danceability: 0.682,
    energy: 0.498,
    valence: 0.0708,
    added_at: "2023-01-29T18:57:51Z",
    link: "https://open.spotify.com/track/1mfirmxz5w1PFmOZNaU7sz",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "2fGiuK2cJ2efll99JN0mu5",
    name: "Facade",
    artists: "Lithe",
    duration_ms: 119124,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/5bc633803c4d051b177203494682b910ea7f3f5c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025887597c92b60252347cc8c7",
    danceability: 0.675,
    energy: 0.177,
    valence: 0.24,
    added_at: "2023-01-29T18:52:04Z",
    link: "https://open.spotify.com/track/2fGiuK2cJ2efll99JN0mu5",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "439EmunDAlipjDuejCQDjQ",
    name: "Skinny Ramen Freestyle",
    artists: "Erick the Architect",
    duration_ms: 118247,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/160671da8b3a00259561a80f2991da46b257f0fc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ee1210b94d270037cdefdf63",
    danceability: 0.647,
    energy: 0.702,
    valence: 0.493,
    added_at: "2023-01-29T18:49:45Z",
    link: "https://open.spotify.com/track/439EmunDAlipjDuejCQDjQ",
    userID: "royclaud",
    genres: "underground hip hop",
  },
  {
    id: "3HKT8s5kZ4NevGmgnm8rvt",
    name: "life’s a dream",
    artists: "d4vd",
    duration_ms: 130541,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/0b9dff3fc0a3ace8d0434f04170ad47ed9a2674c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021e80a7b9f35ef615129191d7",
    danceability: 0.442,
    energy: 0.17,
    valence: 0.0492,
    added_at: "2023-01-29T18:48:39Z",
    link: "https://open.spotify.com/track/3HKT8s5kZ4NevGmgnm8rvt",
    userID: "royclaud",
    genres: "bedroom pop,bedroom r&b",
  },
  {
    id: "2mpoNBkngUEwnaKoBhZ0vl",
    name: "PRC",
    artists: "Peso Pluma, Natanael Cano",
    duration_ms: 184066,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/04ebbfd99947c52464a0c31b25ccc02fcef69ae0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027be314e2f66a9f7ee732395e",
    danceability: 0.784,
    energy: 0.826,
    valence: 0.893,
    added_at: "2023-01-29T02:23:04Z",
    link: "https://open.spotify.com/track/2mpoNBkngUEwnaKoBhZ0vl",
    userID: "royclaud",
    genres: "corridos tumbados,sad sierreno,corrido,corridos tumbados,musica mexicana,sad sierreno,sierreno",
  },
  {
    id: "5WoaF1B5XIEnWfmb5NZikf",
    name: "Sky Walker (feat. Travis Scott)",
    artists: "Miguel, Travis Scott",
    duration_ms: 259333,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/c95b541168b5a8cb39c2d648060cee74cd68db7c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f654e8e9ac3c24881239ab7c",
    danceability: 0.674,
    energy: 0.506,
    valence: 0.183,
    added_at: "2023-01-28T20:57:09Z",
    link: "https://open.spotify.com/track/5WoaF1B5XIEnWfmb5NZikf",
    userID: "royclaud",
    genres: "r&b,urban contemporary,rap,slap house",
  },
  {
    id: "59l3fBi4LdnTafVZnvTknD",
    name: "Calling from Above",
    artists: "Raimu",
    duration_ms: 184047,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/b459dfbb1c3c9259e977b1049429afbf17bf7d22?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022151450a825f7feae6998f47",
    danceability: 0.506,
    energy: 0.093,
    valence: 0.142,
    added_at: "2023-01-27T01:27:14Z",
    link: "https://open.spotify.com/track/59l3fBi4LdnTafVZnvTknD",
    userID: "royclaud",
    genres: "anime lo-fi,japanese chillhop",
  },
  {
    id: "6ENqC7p7aVwI0UWQ1aAoUC",
    name: "I Know It Was Me, Ikikik",
    artists: "Faceless 1-7",
    duration_ms: 111961,
    popularity: 38,
    preview_url: "https://p.scdn.co/mp3-preview/0848c85f2d2287534eca92b29e8df38d473e99d7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0260b5655586253abc4d3904e4",
    danceability: 0.627,
    energy: 0.34,
    valence: 0.0378,
    added_at: "2023-01-25T22:38:39Z",
    link: "https://open.spotify.com/track/6ENqC7p7aVwI0UWQ1aAoUC",
    userID: "royclaud",
    genres: "dark trap",
  },
  {
    id: "2DoHygz0t3WBEgXQKeA2QL",
    name: "Monday Magic",
    artists: "Lil Cobaine",
    duration_ms: 146128,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/be3e7f024e1ad3294b13350c8ad3b9f456ea866a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0298928462ae38abc3c6a3487a",
    danceability: 0.756,
    energy: 0.469,
    valence: 0.617,
    added_at: "2023-01-25T20:16:08Z",
    link: "https://open.spotify.com/track/2DoHygz0t3WBEgXQKeA2QL",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "4R16AWzjiaEgZ5La13VF77",
    name: "Suspect",
    artists: "Lil Sleep",
    duration_ms: 128088,
    popularity: 36,
    preview_url: "https://p.scdn.co/mp3-preview/f77b75dfc0d8f4cf12d12132117f0843932d134e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0267cf2f2c9f7ace90fb8ab2f7",
    danceability: 0.861,
    energy: 0.327,
    valence: 0.113,
    added_at: "2023-01-25T17:33:20Z",
    link: "https://open.spotify.com/track/4R16AWzjiaEgZ5La13VF77",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "3iGxtTIbGtUJVMbnHyaPMM",
    name: "Trance Dance",
    artists: "ODIE",
    duration_ms: 225568,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/c1ff29bf01db187905bad47b92163dfe7ca87449?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02345245d01d00f0b3fc044f57",
    danceability: 0.623,
    energy: 0.558,
    valence: 0.455,
    added_at: "2023-01-25T04:10:09Z",
    link: "https://open.spotify.com/track/3iGxtTIbGtUJVMbnHyaPMM",
    userID: "royclaud",
    genres: "canadian contemporary r&b",
  },
  {
    id: "6LvgtLwg8y6PnESG1nAlqS",
    name: "Damage Dealt",
    artists: "Yunggoth✰",
    duration_ms: 116756,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/565d2de26c70bff32fd65fcdf3d230b16c9cc555?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028437065ce3143ee0659700e0",
    danceability: 0.868,
    energy: 0.37,
    valence: 0.145,
    added_at: "2023-01-25T04:07:47Z",
    link: "https://open.spotify.com/track/6LvgtLwg8y6PnESG1nAlqS",
    userID: "royclaud",
    genres: "cloud rap,dark trap,emo rap",
  },
  {
    id: "3s0avkwLU7HATBNRXH87fU",
    name: "Wasting Time",
    artists: "Rocci",
    duration_ms: 113861,
    popularity: 25,
    preview_url: "https://p.scdn.co/mp3-preview/3c8fc20a607203813b9b14b879333a596a6078ad?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02253fdce61c76e361e88b1efa",
    danceability: 0.56,
    energy: 0.449,
    valence: 0.519,
    added_at: "2023-01-25T04:01:10Z",
    link: "https://open.spotify.com/track/3s0avkwLU7HATBNRXH87fU",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "7rglbMK0JGU2YhPUkZmv2C",
    name: "Right At Night",
    artists: "RXLZQ",
    duration_ms: 144888,
    popularity: 45,
    preview_url: "https://p.scdn.co/mp3-preview/fe9498e38641152f5925286300136d94fff90b85?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02650546733fd8c452100bdd9b",
    danceability: 0.501,
    energy: 0.426,
    valence: 0.0345,
    added_at: "2023-01-25T03:58:23Z",
    link: "https://open.spotify.com/track/7rglbMK0JGU2YhPUkZmv2C",
    userID: "royclaud",
    genres: "drift phonk",
  },
  {
    id: "1vUqOaW7fxDloQKUrvmzJX",
    name: "SILK ROAD",
    artists: "Anfa Rose",
    duration_ms: 161000,
    popularity: 31,
    preview_url: "https://p.scdn.co/mp3-preview/316ecd7cb2191990f676de99d1d87fdf33b888e8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fb0e59cc5fdf3ab5437de468",
    danceability: 0.859,
    energy: 0.535,
    valence: 0.674,
    added_at: "2023-01-25T03:55:58Z",
    link: "https://open.spotify.com/track/1vUqOaW7fxDloQKUrvmzJX",
    userID: "royclaud",
    genres: "trap soul",
  },
  {
    id: "2IhbEQIn0Xq2icP5BwJLrx",
    name: "W.N.M",
    artists: "Boslen",
    duration_ms: 139013,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/94b40ec9748c6620688914c7a071aec66d52cdb4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0215a2ec1b4f6970f61371bf84",
    danceability: 0.918,
    energy: 0.634,
    valence: 0.364,
    added_at: "2023-01-24T22:13:33Z",
    link: "https://open.spotify.com/track/2IhbEQIn0Xq2icP5BwJLrx",
    userID: "royclaud",
    genres: "bc underground hip hop,indie hip hop",
  },
  {
    id: "7EAMXbLcL0qXmciM5SwMh2",
    name: "Heart To Heart",
    artists: "Mac DeMarco",
    duration_ms: 211140,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/e3fd4ac516e3e42cc98dea378274679ac9ac04c9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fa1323bb50728c7489980672",
    danceability: 0.901,
    energy: 0.136,
    valence: 0.643,
    added_at: "2023-01-24T22:09:26Z",
    link: "https://open.spotify.com/track/7EAMXbLcL0qXmciM5SwMh2",
    userID: "royclaud",
    genres: "edmonton indie,lo-fi indie,pov: indie,slacker rock",
  },
  {
    id: "63bAGRSSX2V1hhPSP2NpBC",
    name: "North Face",
    artists: "ODIE",
    duration_ms: 196799,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/9b8e9dd992b5fd3d4712afed2234ec63a083bcc0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e8f1f55f14d93b24f7cf8d5e",
    danceability: 0.802,
    energy: 0.382,
    valence: 0.581,
    added_at: "2023-01-24T22:08:05Z",
    link: "https://open.spotify.com/track/63bAGRSSX2V1hhPSP2NpBC",
    userID: "royclaud",
    genres: "canadian contemporary r&b",
  },
  {
    id: "6xniBg7UJ03jcyvOgfWVJk",
    name: "FELT",
    artists: "Nate Traveller",
    duration_ms: 105045,
    popularity: 39,
    preview_url: "https://p.scdn.co/mp3-preview/e56a42189880eeb8aeb41dea7ca26ea27a2cea92?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b83c8c6c103bdf2890d0af19",
    danceability: 0.573,
    energy: 0.559,
    valence: 0.62,
    added_at: "2023-01-24T21:28:52Z",
    link: "https://open.spotify.com/track/6xniBg7UJ03jcyvOgfWVJk",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "1toi8pP6yhA5q4DsEtWCUm",
    name: "Lavadichi",
    artists: "Natanael Cano, Dan Sanchez",
    duration_ms: 170277,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/e90a5fe7a29c82b198375a814f4ae224f0ccd8a8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0249c784494e55f63ffa6db70d",
    danceability: 0.631,
    energy: 0.712,
    valence: 0.886,
    added_at: "2023-01-24T16:47:27Z",
    link: "https://open.spotify.com/track/1toi8pP6yhA5q4DsEtWCUm",
    userID: "royclaud",
    genres: "corrido,corridos tumbados,musica mexicana,sad sierreno,sierreno,corrido,corridos tumbados,sierreno",
  },
  {
    id: "6ou4s6ejFgHcjpA1uPRoeN",
    name: "Play Your Roll",
    artists: "Norman Perry",
    duration_ms: 125901,
    popularity: 48,
    preview_url: "https://p.scdn.co/mp3-preview/a8bb1038ac34f0c436b57b74112afa6cbdebec05?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0264a645befebe82ed1ad6eae6",
    danceability: 0.812,
    energy: 0.266,
    valence: 0.298,
    added_at: "2023-01-24T16:08:43Z",
    link: "https://open.spotify.com/track/6ou4s6ejFgHcjpA1uPRoeN",
    userID: "royclaud",
    genres: "trap soul",
  },
  {
    id: "5rSZ4ioUjfgFHZleHG1vQc",
    name: "Take a Moment",
    artists: "Peebs The Prophet",
    duration_ms: 219130,
    popularity: 44,
    preview_url: "https://p.scdn.co/mp3-preview/d11bc410f12647b8cc4230efb7a8d32124aac384?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0250c9104f823d9de8c3897156",
    danceability: 0.808,
    energy: 0.565,
    valence: 0.452,
    added_at: "2023-01-24T00:05:19Z",
    link: "https://open.spotify.com/track/5rSZ4ioUjfgFHZleHG1vQc",
    userID: "royclaud",
    genres: "jazz boom bap",
  },
  {
    id: "0iwaWSpzPan5t1n7F50kuM",
    name: "DESIRES",
    artists: "Lil Rae, Isekai",
    duration_ms: 154242,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/9d67b6e7e009c49420a64a54fbbb70222936156a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02eb6c92f7d6a1f470798d71dd",
    danceability: 0.751,
    energy: 0.328,
    valence: 0.447,
    added_at: "2023-01-23T23:34:28Z",
    link: "https://open.spotify.com/track/0iwaWSpzPan5t1n7F50kuM",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "5fb8vhQ49xRw52pnsgYE4C",
    name: "Download My Conscious",
    artists: "Faceless 1-7",
    duration_ms: 92884,
    popularity: 54,
    preview_url: "https://p.scdn.co/mp3-preview/ce6011759c52770dd0bb0bb9d6d1fd57ffe1c3ee?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0260b5655586253abc4d3904e4",
    danceability: 0.696,
    energy: 0.552,
    valence: 0.363,
    added_at: "2023-01-23T23:06:13Z",
    link: "https://open.spotify.com/track/5fb8vhQ49xRw52pnsgYE4C",
    userID: "royclaud",
    genres: "dark trap",
  },
  {
    id: "0JIMT9gzLIIz0esKLyjbKf",
    name: "Telephones",
    artists: "Vacations",
    duration_ms: 212432,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/7ea842ef0f660e24bb4ac29af5c2b03e75f9d449?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023dd6e8bbc5b1ac43fd8a838c",
    danceability: 0.526,
    energy: 0.447,
    valence: 0.183,
    added_at: "2023-01-23T21:16:20Z",
    link: "https://open.spotify.com/track/0JIMT9gzLIIz0esKLyjbKf",
    userID: "royclaud",
    genres: "newcastle nsw indie,pov: indie",
  },
  {
    id: "5vVB6iuppIKP6rTQBtogUL",
    name: "X & O",
    artists: "Lil Cobaine",
    duration_ms: 102582,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/924c2485b44e2aa35e9c05c63df619310f235e79?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020be726c7ecb8510c7e24b3a3",
    danceability: 0.707,
    energy: 0.457,
    valence: 0.507,
    added_at: "2023-01-23T20:47:39Z",
    link: "https://open.spotify.com/track/5vVB6iuppIKP6rTQBtogUL",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "2sAJnce908UAzfZp6qIj1s",
    name: "The G.O.D.",
    artists: "[bsd.u]",
    duration_ms: 79434,
    popularity: 38,
    preview_url: "https://p.scdn.co/mp3-preview/5582a50baf2ca931c4e725165306e26571992511?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026ac497ecc210fbe652b990da",
    danceability: 0.679,
    energy: 0.458,
    valence: 0.642,
    added_at: "2023-01-23T20:23:10Z",
    link: "https://open.spotify.com/track/2sAJnce908UAzfZp6qIj1s",
    userID: "royclaud",
    genres: "japanese chillhop,lo-fi beats",
  },
  {
    id: "0eFKmL9q9yzRt1kzKcRjXz",
    name: "DON'T BLINK!",
    artists: "ELIOZIE",
    duration_ms: 113572,
    popularity: 39,
    preview_url: "https://p.scdn.co/mp3-preview/259d3277cfdd9c1d80293a8cdc230bb21fe53457?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d131e2cf1c4d2bbec9f0f271",
    danceability: 0.812,
    energy: 0.456,
    valence: 0.848,
    added_at: "2023-01-23T18:38:54Z",
    link: "https://open.spotify.com/track/0eFKmL9q9yzRt1kzKcRjXz",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "3dLpdQg0OL4ZqJuumFAy2y",
    name: "Denim Heart Man",
    artists: "Lil Cobaine",
    duration_ms: 113005,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/486a7377fe4f7482d8dca4cbd0cb3e18fe7bb3c6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020be726c7ecb8510c7e24b3a3",
    danceability: 0.774,
    energy: 0.255,
    valence: 0.502,
    added_at: "2023-01-23T16:53:07Z",
    link: "https://open.spotify.com/track/3dLpdQg0OL4ZqJuumFAy2y",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "3Z0qLOS0cqWKPHXkbTXmNF",
    name: "Gilded Lily",
    artists: "Cults",
    duration_ms: 212736,
    popularity: 76,
    preview_url: "https://p.scdn.co/mp3-preview/9d401cd5f69f6ccc59b883f946dad58a40f21015?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d0349cd631791ca446be337a",
    danceability: 0.4,
    energy: 0.571,
    valence: 0.146,
    added_at: "2023-01-23T15:43:11Z",
    link: "https://open.spotify.com/track/3Z0qLOS0cqWKPHXkbTXmNF",
    userID: "royclaud",
    genres: "experimental pop,indie surf,pov: indie",
  },
  {
    id: "3AVrVz5rK8Hrqo9YGiVGN5",
    name: "Apocalypse",
    artists: "Cigarettes After Sex",
    duration_ms: 290146,
    popularity: 7,
    preview_url: "https://p.scdn.co/mp3-preview/1f89f0156113dfb87572382b531459bb8cb711a1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026b701428ed4c6e053902174a",
    danceability: 0.369,
    energy: 0.467,
    valence: 0.174,
    added_at: "2023-01-22T23:10:04Z",
    link: "https://open.spotify.com/track/3AVrVz5rK8Hrqo9YGiVGN5",
    userID: "royclaud",
    genres: "ambient pop,dream pop,el paso indie,shoegaze",
  },
  {
    id: "1uzIAacATODXQVXuENo1ei",
    name: "JESUS IN BERLIN",
    artists: "nullzwei, Chris Sigl, BERTHAJU",
    duration_ms: 152343,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/186c22088854d50e9333bf970d1f0afa446369fc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028a7ffbc2da7145b22385d3aa",
    danceability: 0.791,
    energy: 0.43,
    valence: 0.275,
    added_at: "2023-01-22T03:45:59Z",
    link: "https://open.spotify.com/track/1uzIAacATODXQVXuENo1ei",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "7tJzIEqZV5tzuvol0Voq7Z",
    name: "dont be afraid",
    artists: "Knxwledge",
    duration_ms: 94380,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/025179011b5b8e9e808365ad028f942365fcfc6f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0239d5d182e08f18df9ca86243",
    danceability: 0.5,
    energy: 0.863,
    valence: 0.579,
    added_at: "2023-01-21T22:24:26Z",
    link: "https://open.spotify.com/track/7tJzIEqZV5tzuvol0Voq7Z",
    userID: "royclaud",
    genres: "abstract beats,alternative hip hop,indie soul,instrumental hip hop,wonky",
  },
  {
    id: "1rN9QoVxw5U7TJkyaUR8C1",
    name: "unravel",
    artists: "TK from Ling tosite sigure",
    duration_ms: 238360,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/63d4aacff92bb8f275ec03e92505b3d315d63327?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b11c3ab5cb818192b7a61fdd",
    danceability: 0.508,
    energy: 0.889,
    valence: 0.332,
    added_at: "2023-01-21T20:33:42Z",
    link: "https://open.spotify.com/track/1rN9QoVxw5U7TJkyaUR8C1",
    userID: "royclaud",
    genres: "anime,j-rock",
  },
  {
    id: "2hhFpD32iXUd4GaCu6T4wn",
    name: "Everyday Normal Guy 2",
    artists: "Jon Lajoie",
    duration_ms: 195693,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/f81d737dc34409653a189478eedc09138b0a62e8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ec0a9eea5aa9110401cebaf4",
    danceability: 0.823,
    energy: 0.731,
    valence: 0.876,
    added_at: "2023-01-21T03:16:51Z",
    link: "https://open.spotify.com/track/2hhFpD32iXUd4GaCu6T4wn",
    userID: "royclaud",
    genres: "comedy rap,comic,parody",
  },
  {
    id: "3S9pctFGXsXXwvlTT9dB15",
    name: "400 Years",
    artists: "CYNE",
    duration_ms: 216495,
    popularity: 38,
    preview_url: "https://p.scdn.co/mp3-preview/fd9f59857bf57c4515d20570fa232afa7a5c2032?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a1d5a4f7a04c507ba9b86bbb",
    danceability: 0.63,
    energy: 0.674,
    valence: 0.458,
    added_at: "2023-01-19T20:19:57Z",
    link: "https://open.spotify.com/track/3S9pctFGXsXXwvlTT9dB15",
    userID: "royclaud",
    genres: "jazz rap",
  },
  {
    id: "2ust13knx3fDulzN4QwegL",
    name: "Before I Wake",
    artists: "Demrick, Jarren Benton",
    duration_ms: 181676,
    popularity: 37,
    preview_url: "https://p.scdn.co/mp3-preview/8c5ef65faa47e1f5928681f63672a86a499da8f5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0245e62b323f07bc9af63391b7",
    danceability: 0.893,
    energy: 0.632,
    valence: 0.648,
    added_at: "2023-01-19T20:16:17Z",
    link: "https://open.spotify.com/track/2ust13knx3fDulzN4QwegL",
    userID: "royclaud",
    genres: "deep underground hip hop,atl hip hop,deep underground hip hop,indie pop rap",
  },
  {
    id: "5Z8YgNhAqCmTZObiM59Ods",
    name: "Let It Play",
    artists: "Lil Cobaine",
    duration_ms: 115215,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/3b3f91a504677636a78f89de75f04ea791b9421f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a647929c37e94379355361c9",
    danceability: 0.773,
    energy: 0.492,
    valence: 0.223,
    added_at: "2023-01-19T20:13:32Z",
    link: "https://open.spotify.com/track/5Z8YgNhAqCmTZObiM59Ods",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "6GFPH4i0jmbQ05OmuJnL2s",
    name: "May I",
    artists: "Chuuwee, Trizz",
    duration_ms: 200503,
    popularity: 36,
    preview_url: "https://p.scdn.co/mp3-preview/0d020337525e7932ee820c3448b14e39720f7315?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0292bb1a58bd52475ec7ab5601",
    danceability: 0.485,
    energy: 0.663,
    valence: 0.705,
    added_at: "2023-01-19T20:10:33Z",
    link: "https://open.spotify.com/track/6GFPH4i0jmbQ05OmuJnL2s",
    userID: "royclaud",
    genres: "indie hip hop,sacramento hip hop,underground hip hop,deep underground hip hop,soul flow",
  },
  {
    id: "53C1cEmECQhFybYyArzBBO",
    name: "Lost Boys",
    artists: "Sir Michael Rocks, Mac Miller, Trinidad James",
    duration_ms: 339999,
    popularity: 5,
    preview_url: "https://p.scdn.co/mp3-preview/c105d8fd0018c669a9c9749125833e2e0e98512d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021fe26e83b8457439109cfb77",
    danceability: 0.719,
    energy: 0.569,
    valence: 0.438,
    added_at: "2023-01-19T20:06:04Z",
    link: "https://open.spotify.com/track/53C1cEmECQhFybYyArzBBO",
    userID: "royclaud",
    genres: "soul flow,underground hip hop,hip hop,pittsburgh rap,rap,atl hip hop,dirty south rap,trap",
  },
  {
    id: "2SiqlgLMfKQbczSMRp8drY",
    name: "Into The Ocean",
    artists: "Blue October",
    duration_ms: 239946,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/0be5104a56aaab544f50a8acedf56b15c250adf1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0203c87a7a7fb22e536c6ea781",
    danceability: 0.758,
    energy: 0.606,
    valence: 0.361,
    added_at: "2023-01-19T17:57:13Z",
    link: "https://open.spotify.com/track/2SiqlgLMfKQbczSMRp8drY",
    userID: "royclaud",
    genres: "san marcos tx indie",
  },
  {
    id: "51R5mPcJjOnfv9lKY1u5sW",
    name: "Never Meant",
    artists: "American Football",
    duration_ms: 268333,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/fb8bc3fe5ac15f33cc702bc1cffc604a869657e0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d6dfafe62fef3ad433ff77d8",
    danceability: 0.436,
    energy: 0.819,
    valence: 0.405,
    added_at: "2023-01-19T17:56:19Z",
    link: "https://open.spotify.com/track/51R5mPcJjOnfv9lKY1u5sW",
    userID: "royclaud",
    genres: "alternative emo,dreamo,emo,math rock,midwest emo",
  },
  {
    id: "3LqnOFWrX9I5jTjA7cUWjD",
    name: "Perfect World",
    artists: "Broken Bells",
    duration_ms: 384426,
    popularity: 41,
    preview_url: "https://p.scdn.co/mp3-preview/7644bfcde95f80595c95d43e8d01f77e3639f668?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029c111e02e4ae7cc0d1caa91d",
    danceability: 0.553,
    energy: 0.885,
    valence: 0.767,
    added_at: "2023-01-19T17:55:45Z",
    link: "https://open.spotify.com/track/3LqnOFWrX9I5jTjA7cUWjD",
    userID: "royclaud",
    genres: "chamber pop,indie rock,indietronica,la indie,modern rock",
  },
  {
    id: "2JrttSXqvhuac7ppgNrnl9",
    name: "Plastic Dreams (feat. Johanna Fay)",
    artists: "G-Eazy, Johanna Fay",
    duration_ms: 233900,
    popularity: 39,
    preview_url: "https://p.scdn.co/mp3-preview/e511bb853798e4d503f9883b31fd94b3f93b354f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027181af52d89063ff89acf3e0",
    danceability: 0.514,
    energy: 0.7,
    valence: 0.305,
    added_at: "2023-01-19T17:55:30Z",
    link: "https://open.spotify.com/track/2JrttSXqvhuac7ppgNrnl9",
    userID: "royclaud",
    genres: "indie pop rap,oakland hip hop,pop rap,rap",
  },
  {
    id: "7IB8oAx4Q6lckzvStGQUsL",
    name: "Come Back",
    artists: "Sarcastic Sounds",
    duration_ms: 101052,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/f594c540098d92cd7ebc165445a58c81f0da03c3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bba373c50fe0fe73f9643760",
    danceability: 0.665,
    energy: 0.204,
    valence: 0.346,
    added_at: "2023-01-19T17:55:18Z",
    link: "https://open.spotify.com/track/7IB8oAx4Q6lckzvStGQUsL",
    userID: "royclaud",
    genres: "lo-fi chill,sad lo-fi",
  },
  {
    id: "6fx1fKIs5e0pBL5FpdC7RB",
    name: "MakeDamnSure",
    artists: "Taking Back Sunday",
    duration_ms: 208280,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/e638567e589ec50dcdb8ba358c7a42af41da17e7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0295fe4146184ee807c884326b",
    danceability: 0.553,
    energy: 0.837,
    valence: 0.432,
    added_at: "2023-01-19T17:54:45Z",
    link: "https://open.spotify.com/track/6fx1fKIs5e0pBL5FpdC7RB",
    userID: "royclaud",
    genres: "emo,neon pop punk,pop punk,post-hardcore,screamo",
  },
  {
    id: "74SUn8w4lYSwgOGPvVZiEo",
    name: "Gallowdance",
    artists: "Lebanon Hanover",
    duration_ms: 292600,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/bc15fb7d23b797c25f20f9e5eb1fd9bbcac04a75?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f134621be38f4fdabbf33442",
    danceability: 0.705,
    energy: 0.766,
    valence: 0.279,
    added_at: "2023-01-19T17:53:33Z",
    link: "https://open.spotify.com/track/74SUn8w4lYSwgOGPvVZiEo",
    userID: "royclaud",
    genres: "minimal wave",
  },
  {
    id: "6R4d9etNC58AKOlMiWlrJu",
    name: "From Dog to God",
    artists: "Prayers",
    duration_ms: 205080,
    popularity: 39,
    preview_url: "https://p.scdn.co/mp3-preview/343e428f009005d2c903c476c4aeb3ee8d1f6f99?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0274ad20ab51527c8fa44a937a",
    danceability: 0.704,
    energy: 0.896,
    valence: 0.927,
    added_at: "2023-01-19T17:52:57Z",
    link: "https://open.spotify.com/track/6R4d9etNC58AKOlMiWlrJu",
    userID: "royclaud",
    genres: "electronic rock",
  },
  {
    id: "7MvevYkYg3NtMp1kfDlxZ5",
    name: "Scarlett",
    artists: "Claud",
    duration_ms: 158218,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/d0db4df93ccc7cd2ffb85052fc8d3b351fd9dc35?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02dc1a0230f868af66fd05589f",
    danceability: 0.758,
    energy: 0.506,
    valence: 0.687,
    added_at: "2023-01-19T17:52:36Z",
    link: "https://open.spotify.com/track/7MvevYkYg3NtMp1kfDlxZ5",
    userID: "royclaud",
    genres: "bedroom pop,indie pop",
  },
  {
    id: "4gwdxrHHokQzWXjrD39mFf",
    name: "Kill the Rich",
    artists: "Terror Reid, Bobby Raps",
    duration_ms: 245106,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/662620535ebfc2f6eb13b058fddb87be506fb088?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bfff5fb0d686e3b7287fdd0c",
    danceability: 0.783,
    energy: 0.761,
    valence: 0.473,
    added_at: "2023-01-19T17:44:59Z",
    link: "https://open.spotify.com/track/4gwdxrHHokQzWXjrD39mFf",
    userID: "royclaud",
    genres: "dark trap,cloud rap,emo rap,minnesota hip hop",
  },
  {
    id: "20I8RduZC2PWMWTDCZuuAN",
    name: "Take Me Out",
    artists: "Franz Ferdinand",
    duration_ms: 237026,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/f91cfdf3dd5f7114cf47f8c8b2567b7a8a76d9ed?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0209a90531b85be7899c3234c4",
    danceability: 0.277,
    energy: 0.663,
    valence: 0.527,
    added_at: "2023-01-19T17:43:25Z",
    link: "https://open.spotify.com/track/20I8RduZC2PWMWTDCZuuAN",
    userID: "royclaud",
    genres: "alternative rock,dance rock,indie rock,modern rock,rock,scottish rock",
  },
  {
    id: "31mzt4ZV8C0f52pIz1NSwd",
    name: "Shut up My Moms Calling (Sped up)",
    artists: "Hotel Ugly",
    duration_ms: 135529,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/4417d6fbf78b2a96b067dc092f888178b155b6b7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027437083c2521a8c077b9cfd7",
    danceability: 0.653,
    energy: 0.474,
    valence: 0.361,
    added_at: "2023-01-19T15:36:23Z",
    link: "https://open.spotify.com/track/31mzt4ZV8C0f52pIz1NSwd",
    userID: "royclaud",
    genres: "modern indie pop",
  },
  {
    id: "28IEbk5a7twNTbUEvWslUb",
    name: "Paralyzer",
    artists: "Finger Eleven",
    duration_ms: 208106,
    popularity: 75,
    preview_url: "https://p.scdn.co/mp3-preview/38d80ad696391dd1c322f9bef722456d0bec3ac3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e9c3c16b480e1c5a84d7b188",
    danceability: 0.644,
    energy: 0.939,
    valence: 0.861,
    added_at: "2023-01-18T16:04:16Z",
    link: "https://open.spotify.com/track/28IEbk5a7twNTbUEvWslUb",
    userID: "royclaud",
    genres: "alternative metal,canadian rock,funk metal,nu metal,post-grunge",
  },
  {
    id: "0gmbgwZ8iqyMPmXefof8Yf",
    name: "How You Remind Me",
    artists: "Nickelback",
    duration_ms: 223840,
    popularity: 84,
    preview_url: "https://p.scdn.co/mp3-preview/94fd201045f5b682935957a8206075638dc4622d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02699a422d25adc550dc5aa11c",
    danceability: 0.446,
    energy: 0.764,
    valence: 0.543,
    added_at: "2023-01-18T15:56:29Z",
    link: "https://open.spotify.com/track/0gmbgwZ8iqyMPmXefof8Yf",
    userID: "royclaud",
    genres: "alternative metal,canadian rock,post-grunge",
  },
  {
    id: "6TfBA04WJ3X1d1wXhaCFVT",
    name: "You're Gonna Go Far, Kid",
    artists: "The Offspring",
    duration_ms: 177826,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/c98446a305d38e595073b7fe52b0202ea15532df?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02890ce61533a89e00ce593fcb",
    danceability: 0.55,
    energy: 0.917,
    valence: 0.601,
    added_at: "2023-01-18T15:51:37Z",
    link: "https://open.spotify.com/track/6TfBA04WJ3X1d1wXhaCFVT",
    userID: "royclaud",
    genres: "alternative metal,permanent wave,post-grunge,punk,rock,skate punk,socal pop punk",
  },
  {
    id: "48UPSzbZjgc449aqz8bxox",
    name: "Californication",
    artists: "Red Hot Chili Peppers",
    duration_ms: 329733,
    popularity: 82,
    preview_url: "https://p.scdn.co/mp3-preview/0fd595c5b63db10f4a99683f8248ea5d13700683?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0294d08ab63e57b0cae74e8595",
    danceability: 0.592,
    energy: 0.767,
    valence: 0.328,
    added_at: "2023-01-18T15:51:14Z",
    link: "https://open.spotify.com/track/48UPSzbZjgc449aqz8bxox",
    userID: "royclaud",
    genres: "alternative rock,funk metal,funk rock,permanent wave,rock",
  },
  {
    id: "1kTnBps67CwkDeAEMrIMth",
    name: "Kill Bill",
    artists: "SZA",
    duration_ms: 153946,
    popularity: 43,
    preview_url: "https://p.scdn.co/mp3-preview/f744acee0b7104c65dd35811eab19ee8f0c6d512?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ecbed2e314e559d78413d8d5",
    danceability: 0.643,
    energy: 0.729,
    valence: 0.391,
    added_at: "2023-01-18T14:52:03Z",
    link: "https://open.spotify.com/track/1kTnBps67CwkDeAEMrIMth",
    userID: "royclaud",
    genres: "pop,r&b,rap",
  },
  {
    id: "0sHW1jXe6Sou3437gJQNGA",
    name: "Space Bound",
    artists: "Eminem",
    duration_ms: 278640,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/fac43c52f6645cbccc6dcc4183b6ef313cc248e9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c08d5fa5c0f1a834acef5100",
    danceability: 0.668,
    energy: 0.886,
    valence: 0.503,
    added_at: "2023-01-18T03:23:14Z",
    link: "https://open.spotify.com/track/0sHW1jXe6Sou3437gJQNGA",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,rap",
  },
  {
    id: "73ekBE1ZHp8ySUaX0YdCtR",
    name: "Somethin Wrong In Heaven",
    artists: "Marlon Craft",
    duration_ms: 165939,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/c8be7082bcf129eaa7f8071ce30129a093488458?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0251e3e354561f52225551b3fb",
    danceability: 0.674,
    energy: 0.734,
    valence: 0.492,
    added_at: "2023-01-06T14:29:20Z",
    link: "https://open.spotify.com/track/73ekBE1ZHp8ySUaX0YdCtR",
    userID: "royclaud",
    genres: "nyc rap",
  },
  {
    id: "1qpHbfyMnaIKcJPzVxfnpO",
    name: "I'm Good Luv, Enjoy.",
    artists: "Aaron May",
    duration_ms: 134547,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/f4659d12eaec9ba4c074df134c08944dea154c16?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0223fec90bf517ded5e42e4ef2",
    danceability: 0.891,
    energy: 0.586,
    valence: 0.954,
    added_at: "2023-01-06T14:22:45Z",
    link: "https://open.spotify.com/track/1qpHbfyMnaIKcJPzVxfnpO",
    userID: "royclaud",
    genres: "conscious hip hop",
  },
  {
    id: "014CvkxnvQX6GhBqwzMK68",
    name: "Faya",
    artists: "ATTLAS",
    duration_ms: 310000,
    popularity: 27,
    preview_url: "https://p.scdn.co/mp3-preview/fad74296d6cc9d7c3e0e687643e0e419c9828f30?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028adfceaa9720a64389509bcd",
    danceability: 0.59,
    energy: 0.936,
    valence: 0.162,
    added_at: "2023-01-01T21:56:45Z",
    link: "https://open.spotify.com/track/014CvkxnvQX6GhBqwzMK68",
    userID: "royclaud",
    genres: "canadian electronic",
  },
  {
    id: "0jc2V2gXUJgjvn5ZzsOhV8",
    name: "American Beauty",
    artists: "Thutmose",
    duration_ms: 196780,
    popularity: 17,
    preview_url: "https://p.scdn.co/mp3-preview/2b46a4797241e2cd8d5f0eb2a16ce8e9c855f07c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02cd1fb706d46a8674ea8e5235",
    danceability: 0.625,
    energy: 0.525,
    valence: 0.515,
    added_at: "2022-12-29T02:03:15Z",
    link: "https://open.spotify.com/track/0jc2V2gXUJgjvn5ZzsOhV8",
    userID: "royclaud",
    genres: "indie pop rap",
  },
  {
    id: "6Ed1q0X8oSKSm4IIhiQbYg",
    name: "Daylight",
    artists: "Joji, Diplo",
    duration_ms: 163905,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/6271754d543a1d1f73c527effd7422376412aee0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020e991b59cee17246a5e604d0",
    danceability: 0.528,
    energy: 0.749,
    valence: 0.729,
    added_at: "2022-12-21T17:03:43Z",
    link: "https://open.spotify.com/track/6Ed1q0X8oSKSm4IIhiQbYg",
    userID: "royclaud",
    genres: "viral pop,dance pop,edm,electro house,moombahton,pop dance",
  },
  {
    id: "73F87Sqh6jQWucOOvz1WFx",
    name: "Genius",
    artists: "Sia, Diplo, Labrinth, LSD",
    duration_ms: 213040,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/32ada5d1cc7461708c1faac1a77350407ce191f0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f347c65ab0a4d85e7c547cee",
    danceability: 0.622,
    energy: 0.621,
    valence: 0.567,
    added_at: "2022-12-21T16:39:53Z",
    link: "https://open.spotify.com/track/73F87Sqh6jQWucOOvz1WFx",
    userID: "royclaud",
    genres: "pop,dance pop,edm,electro house,moombahton,pop dance,indie poptimism,pop,dance pop",
  },
  {
    id: "5sESpZsBpmPetQEO07B0tk",
    name: "Raindrops (Insane) [with Travis Scott]",
    artists: "Metro Boomin, Travis Scott",
    duration_ms: 188586,
    popularity: 18,
    preview_url: "https://p.scdn.co/mp3-preview/957314406a764002609cbe009f11b174a03dfac2?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028b1c1ed89416696a71a8ae4f",
    danceability: 0.804,
    energy: 0.539,
    valence: 0.12,
    added_at: "2022-12-20T17:22:45Z",
    link: "https://open.spotify.com/track/5sESpZsBpmPetQEO07B0tk",
    userID: "royclaud",
    genres: "rap,rap,slap house",
  },
  {
    id: "4SqWKzw0CbA05TGszDgMlc",
    name: "I Love You So",
    artists: "The Walters",
    duration_ms: 160239,
    popularity: 87,
    preview_url: "https://p.scdn.co/mp3-preview/83919b3d62a4ae352229c0a779e8aa0c1b2f4f40?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029214ff0109a0e062f8a6cf0f",
    danceability: 0.575,
    energy: 0.648,
    valence: 0.466,
    added_at: "2022-12-20T15:46:19Z",
    link: "https://open.spotify.com/track/4SqWKzw0CbA05TGszDgMlc",
    userID: "royclaud",
    genres: "chicago indie,pov: indie",
  },
  {
    id: "1XrSjpNe49IiygZfzb74pk",
    name: "What Once Was",
    artists: "Her's",
    duration_ms: 255067,
    popularity: 74,
    preview_url: "https://p.scdn.co/mp3-preview/22a45e7c38a60917bfd0df1e9946ce52cdeb1d40?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fc1bc1cf80c431c2bdbde601",
    danceability: 0.526,
    energy: 0.877,
    valence: 0.436,
    added_at: "2022-12-20T15:40:20Z",
    link: "https://open.spotify.com/track/1XrSjpNe49IiygZfzb74pk",
    userID: "royclaud",
    genres: "indie pop,liverpool indie,pov: indie",
  },
  {
    id: "2INGAkBBaIE4WskdmHt7Wg",
    name: "Libra",
    artists: "Pity Party (Girls Club)",
    duration_ms: 122019,
    popularity: 45,
    preview_url: "https://p.scdn.co/mp3-preview/be7c0a0f52ad4e4a87ddd2b5503575b15123134e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d28f30c82c08e9bcbcc781d0",
    danceability: 0.607,
    energy: 0.234,
    valence: 0.519,
    added_at: "2022-12-20T15:14:43Z",
    link: "https://open.spotify.com/track/2INGAkBBaIE4WskdmHt7Wg",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "1RtO2aDYHvfzjqcItbRAWU",
    name: "ilusm",
    artists: "gnash",
    duration_ms: 199005,
    popularity: 36,
    preview_url: "https://p.scdn.co/mp3-preview/9a03c4ed31f8221f685b402661eb2fb65add3ec1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d3835977cfe2eb5fd31acf95",
    danceability: 0.476,
    energy: 0.193,
    valence: 0.0929,
    added_at: "2022-12-18T18:07:44Z",
    link: "https://open.spotify.com/track/1RtO2aDYHvfzjqcItbRAWU",
    userID: "royclaud",
    genres: "alt z,pop",
  },
  {
    id: "4ZIDLfu3oPIoE2w9bb87fn",
    name: "Coulda Been",
    artists: "Lupe Fiasco",
    duration_ms: 258426,
    popularity: 14,
    preview_url: "https://p.scdn.co/mp3-preview/c2a3f2411fedadd04ba1a7390cd793043e0d64d1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02dc420b40b6bca8521713cf50",
    danceability: 0.374,
    energy: 0.81,
    valence: 0.505,
    added_at: "2022-12-18T05:39:33Z",
    link: "https://open.spotify.com/track/4ZIDLfu3oPIoE2w9bb87fn",
    userID: "royclaud",
    genres: "chicago rap,conscious hip hop,hip hop,political hip hop,pop rap,rap,southern hip hop",
  },
  {
    id: "3V8gULd6OJQ8myIO9ET23e",
    name: "Sad Boy",
    artists: "G-Eazy",
    duration_ms: 202506,
    popularity: 38,
    preview_url: "https://p.scdn.co/mp3-preview/789d14d5b2288d9d8877c1f69f65762d44d30728?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0298acfa8c055deedc25e6081d",
    danceability: 0.601,
    energy: 0.64,
    valence: 0.27,
    added_at: "2022-12-15T19:03:23Z",
    link: "https://open.spotify.com/track/3V8gULd6OJQ8myIO9ET23e",
    userID: "royclaud",
    genres: "indie pop rap,oakland hip hop,pop rap,rap",
  },
  {
    id: "4PKZmIXiIt54OQo1E51aTd",
    name: "rumours (feat. mark johns)",
    artists: "gnash, Mark Johns",
    duration_ms: 151109,
    popularity: 35,
    preview_url: "https://p.scdn.co/mp3-preview/457b15db3923b90100bcb549c7193dc04ed24ab5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0208862f843ea27f44f8e8cbd8",
    danceability: 0.844,
    energy: 0.262,
    valence: 0.274,
    added_at: "2022-12-15T19:03:19Z",
    link: "https://open.spotify.com/track/4PKZmIXiIt54OQo1E51aTd",
    userID: "royclaud",
    genres: "alt z,pop",
  },
  {
    id: "1P218qs7gwg24imW0ZTmg3",
    name: "World, Peace",
    artists: "Sahtyre",
    duration_ms: 180000,
    popularity: 24,
    preview_url: "https://p.scdn.co/mp3-preview/483a4e3d43ccf10fc324fa138ef12aa4a33e91ca?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02eb5c27cc136aba376ceb821c",
    danceability: 0.82,
    energy: 0.495,
    valence: 0.0907,
    added_at: "2022-12-15T19:00:11Z",
    link: "https://open.spotify.com/track/1P218qs7gwg24imW0ZTmg3",
    userID: "royclaud",
    genres: "battle rap",
  },
  {
    id: "5lxpUGTeahep1kbCnPqRUI",
    name: "Far Alone",
    artists: "G-Eazy, E-40, Jay Anthony",
    duration_ms: 270160,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/ba6e2dcdef7c42f56dfc3117d51c108ac5dbf901?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0215fbcf230472dd402256cd8c",
    danceability: 0.724,
    energy: 0.653,
    valence: 0.287,
    added_at: "2022-12-15T18:59:27Z",
    link: "https://open.spotify.com/track/5lxpUGTeahep1kbCnPqRUI",
    userID: "royclaud",
    genres: "indie pop rap,oakland hip hop,pop rap,rap,cali rap,gangster rap,hyphy,oakland hip hop,west coast rap,west coast trap",
  },
  {
    id: "2WiNuGZ8zSMYxBRI2QmEb1",
    name: "Phoenix",
    artists: "K.A.A.N.",
    duration_ms: 180000,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/2466cd60df7487fc64bcbf5e4db9b8e601483145?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02cb294390e4b7027912f69503",
    danceability: 0.75,
    energy: 0.81,
    valence: 0.122,
    added_at: "2022-12-15T18:59:03Z",
    link: "https://open.spotify.com/track/2WiNuGZ8zSMYxBRI2QmEb1",
    userID: "royclaud",
    genres: "deep underground hip hop,indie pop rap",
  },
  {
    id: "3VB7ex0LEw9fccnwesH5ha",
    name: "Long Journey",
    artists: "King Z3us",
    duration_ms: 256000,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/c990886d4fc8c4ec42b9dc0548cf53c1689e4f1a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/66fc38bf048cfd26806d18849f083b983a24d2db",
    danceability: 0.752,
    energy: 0.714,
    valence: 0.497,
    added_at: "2022-12-15T18:58:45Z",
    link: "https://open.spotify.com/track/3VB7ex0LEw9fccnwesH5ha",
    userID: "royclaud",
    genres: "deep underground hip hop",
  },
  {
    id: "3vZO25GdYuqFrR1kzZADnp",
    name: "Ready or Not",
    artists: "Fugees, Ms. Lauryn Hill, Wyclef Jean, Pras",
    duration_ms: 226933,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/d12fb072f6c4bbb371da8a199786bd5034706cae?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025b7865be7f7fcc05faec6137",
    danceability: 0.544,
    energy: 0.428,
    valence: 0.524,
    added_at: "2022-12-15T18:58:06Z",
    link: "https://open.spotify.com/track/3vZO25GdYuqFrR1kzZADnp",
    userID: "royclaud",
    genres: "east coast hip hop,hip hop,neo soul,new jersey rap,alternative r&b,conscious hip hop,hip hop,neo soul,new jersey rap,r&b,rap kreyol,new jersey rap",
  },
  {
    id: "6VSqofSuhBvJxbrWGXUmcG",
    name: "Gon Die (feat. Stevie Ross)",
    artists: "Merkules, Stevie Ross",
    duration_ms: 300962,
    popularity: 33,
    preview_url: "https://p.scdn.co/mp3-preview/4f1ed891a49accb1021fde10a612402f262fc44d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0283c0a3911d2b601df05d3caa",
    danceability: 0.817,
    energy: 0.608,
    valence: 0.406,
    added_at: "2022-12-15T18:57:54Z",
    link: "https://open.spotify.com/track/6VSqofSuhBvJxbrWGXUmcG",
    userID: "royclaud",
    genres: "bc underground hip hop,canadian hip hop,bc underground hip hop",
  },
  {
    id: "2BAsBkA10fTRZhF5iMs0Dh",
    name: "La Vie En Rose",
    artists: "Emily Watts",
    duration_ms: 157683,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/6c2f40804625309a74c3dcd7efd407366edc9217?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b02e07dc43c5c61e4aa3128c",
    danceability: 0.419,
    energy: 0.0842,
    valence: 0.246,
    added_at: "2022-12-15T18:25:56Z",
    link: "https://open.spotify.com/track/2BAsBkA10fTRZhF5iMs0Dh",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "77IhMuHt8dFcwdqeSuh6N9",
    name: "Filthy Rich",
    artists: "South Park Mexican",
    duration_ms: 284440,
    popularity: 52,
    preview_url: "https://p.scdn.co/mp3-preview/52b214dc04161a92a899ccd345bdf045687fa92f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d9fad756a57fa43002c5d860",
    danceability: 0.697,
    energy: 0.706,
    valence: 0.385,
    added_at: "2022-12-15T16:50:39Z",
    link: "https://open.spotify.com/track/77IhMuHt8dFcwdqeSuh6N9",
    userID: "royclaud",
    genres: "chicano rap,latin hip hop,texas latin rap",
  },
  {
    id: "3yUcJwYu7fXAfqMj9krY6l",
    name: "Thank You",
    artists: "Dido",
    duration_ms: 218360,
    popularity: 73,
    preview_url: "https://p.scdn.co/mp3-preview/9827bf18795cdecafe0bd571487f6fcd858cb805?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023e5cbf3b3ac5905cb68377d5",
    danceability: 0.725,
    energy: 0.583,
    valence: 0.762,
    added_at: "2022-12-14T19:01:10Z",
    link: "https://open.spotify.com/track/3yUcJwYu7fXAfqMj9krY6l",
    userID: "royclaud",
    genres: "dance pop,europop,lilith,neo mellow,pop rock",
  },
  {
    id: "0AZTpwagcDe4nH0mPcnYhS",
    name: "Musta Been a Ghost",
    artists: "Próxima Parada",
    duration_ms: 220096,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/19275b81979e974b885cc161e657dd83977019b5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f53b1e3167f82ceab593d370",
    danceability: 0.835,
    energy: 0.546,
    valence: 0.542,
    added_at: "2022-12-12T18:13:23Z",
    link: "https://open.spotify.com/track/0AZTpwagcDe4nH0mPcnYhS",
    userID: "royclaud",
    genres: "deep new americana",
  },
  {
    id: "6Dk5fHTvH897XrVzCO64Mx",
    name: "What You Won't Do for Love",
    artists: "Bobby Caldwell",
    duration_ms: 286493,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/506f4b71189caddd52150d8a7f4f0b1cfc23ef06?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020f98789111606f77a27d6f67",
    danceability: 0.642,
    energy: 0.396,
    valence: 0.797,
    added_at: "2022-12-12T18:13:16Z",
    link: "https://open.spotify.com/track/6Dk5fHTvH897XrVzCO64Mx",
    userID: "royclaud",
    genres: "yacht rock",
  },
  {
    id: "6BU1RZexmvJcBjgagVVt3M",
    name: "Count Me Out",
    artists: "Kendrick Lamar",
    duration_ms: 283642,
    popularity: 76,
    preview_url: "https://p.scdn.co/mp3-preview/b402ac68f8f32cac7c239dae38e6cf0ba219af80?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022e02117d76426a08ac7c174f",
    danceability: 0.776,
    energy: 0.431,
    valence: 0.495,
    added_at: "2022-12-12T16:05:10Z",
    link: "https://open.spotify.com/track/6BU1RZexmvJcBjgagVVt3M",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "3PKN5j2dKkzsLbrpcdyzLX",
    name: "Sentosa",
    artists: "Peso Pluma, Tornillo, Polo Gonzalez, Garena Free Fire",
    duration_ms: 151594,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/1a1e7c67a07071f034a926bb4d808ed0b23132a7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a0887b07800af74f7ecb6e1f",
    danceability: 0.824,
    energy: 0.636,
    valence: 0.672,
    added_at: "2022-12-10T07:14:31Z",
    link: "https://open.spotify.com/track/3PKN5j2dKkzsLbrpcdyzLX",
    userID: "royclaud",
    genres: "corridos tumbados,sad sierreno,mexican hip hop,corrido,corridos tumbados,sad sierreno,sierreno",
  },
  {
    id: "6x7j3TyqR2mUg17qsvMj9J",
    name: "SPM vs Los",
    artists: "South Park Mexican",
    duration_ms: 290693,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/ce593a340a92b998e3dd70ed00137531aba8c99d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d9fad756a57fa43002c5d860",
    danceability: 0.799,
    energy: 0.617,
    valence: 0.608,
    added_at: "2022-12-10T04:41:36Z",
    link: "https://open.spotify.com/track/6x7j3TyqR2mUg17qsvMj9J",
    userID: "royclaud",
    genres: "chicano rap,latin hip hop,texas latin rap",
  },
  {
    id: "5Y35SjAfXjjG0sFQ3KOxmm",
    name: "Nobody Gets Me",
    artists: "SZA",
    duration_ms: 180853,
    popularity: 83,
    preview_url: "https://p.scdn.co/mp3-preview/36cfe8fbfb78aa38ca3b222c4b9fc88cda992841?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0270dbc9f47669d120ad874ec1",
    danceability: 0.358,
    energy: 0.284,
    valence: 0.276,
    added_at: "2022-12-09T16:33:45Z",
    link: "https://open.spotify.com/track/5Y35SjAfXjjG0sFQ3KOxmm",
    userID: "royclaud",
    genres: "pop,r&b,rap",
  },
  {
    id: "4iZ4pt7kvcaH6Yo8UoZ4s2",
    name: "Snooze",
    artists: "SZA",
    duration_ms: 201800,
    popularity: 85,
    preview_url: "https://p.scdn.co/mp3-preview/8c53920b5fd2c3178afa36cac9eec68b5ee9204a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0270dbc9f47669d120ad874ec1",
    danceability: 0.559,
    energy: 0.551,
    valence: 0.392,
    added_at: "2022-12-09T16:11:53Z",
    link: "https://open.spotify.com/track/4iZ4pt7kvcaH6Yo8UoZ4s2",
    userID: "royclaud",
    genres: "pop,r&b,rap",
  },
  {
    id: "0F845nujLVqCb0XMZCh5Pc",
    name: "I Fall In Love Too Easily",
    artists: "Chet Baker",
    duration_ms: 201440,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/c03aa644efb72f4855079a50ed8cf365a330c1e3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bcaf6f097b310e20504f2de0",
    danceability: 0.532,
    energy: 0.0657,
    valence: 0.292,
    added_at: "2022-12-08T23:41:35Z",
    link: "https://open.spotify.com/track/0F845nujLVqCb0XMZCh5Pc",
    userID: "royclaud",
    genres: "cool jazz,jazz,jazz trumpet,vocal jazz",
  },
  {
    id: "3zjjzKg16tczCMwckGKGuH",
    name: "Happy Pills",
    artists: "Weathers",
    duration_ms: 203080,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/2a7f22af423aec820e2a6e24c3f3f68621950c9e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f40b377328f8614879cdd29d",
    danceability: 0.521,
    energy: 0.885,
    valence: 0.344,
    added_at: "2022-12-08T17:05:02Z",
    link: "https://open.spotify.com/track/3zjjzKg16tczCMwckGKGuH",
    userID: "royclaud",
    genres: "pov: indie",
  },
  {
    id: "1W3aFw910o9YJNoL6KlIX6",
    name: "A Nightingale Sang In Berkeley Square - 2001 Digital Remaster",
    artists: "Bobby Darin",
    duration_ms: 182160,
    popularity: 38,
    preview_url: "https://p.scdn.co/mp3-preview/104bfba8892f6a006c37d67074d2f11983a11488?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0299daa88aef4fb6ab639b4263",
    danceability: 0.56,
    energy: 0.286,
    valence: 0.498,
    added_at: "2022-12-08T06:46:27Z",
    link: "https://open.spotify.com/track/1W3aFw910o9YJNoL6KlIX6",
    userID: "royclaud",
    genres: "adult standards,easy listening,lounge,rock-and-roll,rockabilly,vocal jazz",
  },
  {
    id: "4XBJjreg2qHzLmuMe9ekYB",
    name: "Ain't She Sweet",
    artists: "Nicola Arigliano",
    duration_ms: 131280,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/3ae398b42c5951342767973de547465375a4332b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0261eb431b2f4fd493ca05473d",
    danceability: 0.59,
    energy: 0.319,
    valence: 0.469,
    added_at: "2022-12-08T06:45:42Z",
    link: "https://open.spotify.com/track/4XBJjreg2qHzLmuMe9ekYB",
    userID: "royclaud",
    genres: "classic italian pop,swing italiano",
  },
  {
    id: "2p46tDRZUM8zbdFtYomA2P",
    name: "She's a Rainbow",
    artists: "Jesse O'Mahoney, Theo Golding",
    duration_ms: 112677,
    popularity: 39,
    preview_url: "https://p.scdn.co/mp3-preview/3186f8deba626950b9a094c86306b5b21d2912bf?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0280bf5ff7871070006aa3080e",
    danceability: 0.645,
    energy: 0.156,
    valence: 0.415,
    added_at: "2022-12-08T06:45:27Z",
    link: "https://open.spotify.com/track/2p46tDRZUM8zbdFtYomA2P",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "7xEMzWZo71RZbrBki6ZraY",
    name: "I Been Drinking",
    artists: "Future",
    duration_ms: 137138,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/3a85144df505f87dba1bdd77386a71b8c53b81ab?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026c93b18e2b198ee3ece40a99",
    danceability: 0.519,
    energy: 0.679,
    valence: 0.307,
    added_at: "2022-12-08T05:27:34Z",
    link: "https://open.spotify.com/track/7xEMzWZo71RZbrBki6ZraY",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap,southern hip hop,trap",
  },
  {
    id: "7loEq2SFFcBnDMZgwO6A4v",
    name: "Baby, Won't You Please Come Home - Remastered 2002",
    artists: "Kay Starr",
    duration_ms: 142200,
    popularity: 35,
    preview_url: "https://p.scdn.co/mp3-preview/8e25b66c64ccaa6171cd7179b1bd9e1c17c9a8f8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02dcf460826a0ddd229b161f36",
    danceability: 0.693,
    energy: 0.1,
    valence: 0.357,
    added_at: "2022-12-07T19:08:13Z",
    link: "https://open.spotify.com/track/7loEq2SFFcBnDMZgwO6A4v",
    userID: "royclaud",
    genres: "torch song",
  },
  {
    id: "3HuJDcOWx0gE9Yng2uWY7K",
    name: "Dream A Little Dream Of Me",
    artists: "Louis Armstrong",
    duration_ms: 196840,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/7a70f1fdb60c83c365f94d4e5bfc0d3e8c6fe1c5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02845a5660b804e5f3e821fbed",
    danceability: 0.455,
    energy: 0.183,
    valence: 0.399,
    added_at: "2022-12-07T16:28:04Z",
    link: "https://open.spotify.com/track/3HuJDcOWx0gE9Yng2uWY7K",
    userID: "royclaud",
    genres: "adult standards,dixieland,harlem renaissance,jazz trumpet,lounge,new orleans jazz,soul,swing,vocal jazz",
  },
  {
    id: "1UH4viviUjZnS9aWgPGrk0",
    name: "La vie en rose - Single Version",
    artists: "Louis Armstrong",
    duration_ms: 204400,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/563e24d19dc81fffbedc005f2823e2f3a1aa68c7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026555e6252a13f769b1bfe082",
    danceability: 0.579,
    energy: 0.0766,
    valence: 0.271,
    added_at: "2022-12-07T16:16:18Z",
    link: "https://open.spotify.com/track/1UH4viviUjZnS9aWgPGrk0",
    userID: "royclaud",
    genres: "adult standards,dixieland,harlem renaissance,jazz trumpet,lounge,new orleans jazz,soul,swing,vocal jazz",
  },
  {
    id: "29U7stRjqHU6rMiS8BfaI9",
    name: "What A Wonderful World",
    artists: "Louis Armstrong",
    duration_ms: 139226,
    popularity: 77,
    preview_url: "https://p.scdn.co/mp3-preview/09b846edd2af989c00a282fe8a6d3fa6cd479500?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02845a5660b804e5f3e821fbed",
    danceability: 0.271,
    energy: 0.165,
    valence: 0.202,
    added_at: "2022-12-07T16:15:55Z",
    link: "https://open.spotify.com/track/29U7stRjqHU6rMiS8BfaI9",
    userID: "royclaud",
    genres: "adult standards,dixieland,harlem renaissance,jazz trumpet,lounge,new orleans jazz,soul,swing,vocal jazz",
  },
  {
    id: "4FmCUATNIarCQh72JYdvnm",
    name: "That's Life",
    artists: "Frank Sinatra",
    duration_ms: 187040,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/eb419b58b12e404258b4392bcc7b4e6de38fb64c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025c21d73934bb9760a2f791a2",
    danceability: 0.56,
    energy: 0.434,
    valence: 0.664,
    added_at: "2022-12-07T15:06:23Z",
    link: "https://open.spotify.com/track/4FmCUATNIarCQh72JYdvnm",
    userID: "royclaud",
    genres: "adult standards,easy listening,lounge",
  },
  {
    id: "1imfImVtet6EpUDi6WRDos",
    name: "All The Money (with Gunna) - Bonus",
    artists: "Metro Boomin, Gunna",
    duration_ms: 167400,
    popularity: 33,
    preview_url: "https://p.scdn.co/mp3-preview/1428908ca4fc78b3fa9b210ff520e9da6bb36ab0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c91ab2dd6fcff9b7228ff7ac",
    danceability: 0.837,
    energy: 0.594,
    valence: 0.249,
    added_at: "2022-12-07T00:11:04Z",
    link: "https://open.spotify.com/track/1imfImVtet6EpUDi6WRDos",
    userID: "royclaud",
    genres: "rap,atl hip hop,melodic rap,rap,trap",
  },
  {
    id: "6hPG0aMcXhZxIUcfBB3boz",
    name: "Thank God (feat. Nic Newsham & Kathleen Delano)",
    artists: "Strawberry Girls, Kathleen Delano, Nic Newsham",
    duration_ms: 210237,
    popularity: 27,
    preview_url: "https://p.scdn.co/mp3-preview/ecbbe712b34cb5d3b7ebd6531b32251342caaed2?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0228c574711d349e2074ca5472",
    danceability: 0.51,
    energy: 0.72,
    valence: 0.164,
    added_at: "2022-12-06T23:56:33Z",
    link: "https://open.spotify.com/track/6hPG0aMcXhZxIUcfBB3boz",
    userID: "royclaud",
    genres: "math rock,progressive post-hardcore",
  },
  {
    id: "4rc2WNOt2w8BHfQjBC8PO0",
    name: "We Own The Night",
    artists: "Dance Gavin Dance",
    duration_ms: 205706,
    popularity: 2,
    preview_url: "https://p.scdn.co/mp3-preview/a21cfd9ec5424099a876e2e7bf0cc90eb40e183b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fc52f6fc7dca9af16569fb2b",
    danceability: 0.224,
    energy: 0.905,
    valence: 0.485,
    added_at: "2022-12-06T22:57:04Z",
    link: "https://open.spotify.com/track/4rc2WNOt2w8BHfQjBC8PO0",
    userID: "royclaud",
    genres: "pixie,sacramento indie,screamo,swancore",
  },
  {
    id: "1pacwLXyRO47ka0v6LTIiY",
    name: "Raindrops (Insane) [with Travis Scott]",
    artists: "Metro Boomin, Travis Scott",
    duration_ms: 188586,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/957314406a764002609cbe009f11b174a03dfac2?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c4fee55d7b51479627c31f89",
    danceability: 0.804,
    energy: 0.539,
    valence: 0.12,
    added_at: "2022-12-06T02:28:57Z",
    link: "https://open.spotify.com/track/1pacwLXyRO47ka0v6LTIiY",
    userID: "royclaud",
    genres: "rap,rap,slap house",
  },
  {
    id: "27RDADqLHPALTugdGEyGtG",
    name: "Feel The Fiyaaaah (with A$AP Rocky & feat. Takeoff)",
    artists: "Metro Boomin, A$AP Rocky, Takeoff",
    duration_ms: 189426,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/10b03077a8864c13fe9fee59ae41266aa4d870c7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02cdce39ecb633bea9ef7705a6",
    danceability: 0.498,
    energy: 0.786,
    valence: 0.372,
    added_at: "2022-12-03T02:14:15Z",
    link: "https://open.spotify.com/track/27RDADqLHPALTugdGEyGtG",
    userID: "royclaud",
    genres: "rap,east coast hip hop,hip hop,rap,atl hip hop,rap,trap",
  },
  {
    id: "639ue9j47l2tnsXBdkKN0T",
    name: "Walk Em Down (Don't Kill Civilians) [with 21 Savage & feat. Mustafa]",
    artists: "Metro Boomin, 21 Savage, Mustafa",
    duration_ms: 310960,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/87dfd318995dd23b78f83fcc5f09890481b0a9f5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02cdce39ecb633bea9ef7705a6",
    danceability: 0.529,
    energy: 0.517,
    valence: 0.153,
    added_at: "2022-12-02T18:30:08Z",
    link: "https://open.spotify.com/track/639ue9j47l2tnsXBdkKN0T",
    userID: "royclaud",
    genres: "rap,atl hip hop,hip hop,rap,chill pop",
  },
  {
    id: "2AJ9m2GYvegfJul4GdERER",
    name: "On Time (with John Legend)",
    artists: "Metro Boomin, John Legend",
    duration_ms: 168920,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/c834b5de3798335bac9604513bfa450ae28a9dfb?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02cdce39ecb633bea9ef7705a6",
    danceability: 0.27,
    energy: 0.598,
    valence: 0.454,
    added_at: "2022-12-02T05:12:49Z",
    link: "https://open.spotify.com/track/2AJ9m2GYvegfJul4GdERER",
    userID: "royclaud",
    genres: "rap,neo soul,pop,pop soul,urban contemporary",
  },
  {
    id: "64BuLY7ggczC17w5LSUlSE",
    name: "Creepin' (with The Weeknd & 21 Savage)",
    artists: "Metro Boomin, The Weeknd, 21 Savage",
    duration_ms: 221520,
    popularity: 19,
    preview_url: "https://p.scdn.co/mp3-preview/adc5d059e4ccc40bbad75f3844eb03546d9ab05a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02cdce39ecb633bea9ef7705a6",
    danceability: 0.714,
    energy: 0.619,
    valence: 0.156,
    added_at: "2022-12-02T05:10:01Z",
    link: "https://open.spotify.com/track/64BuLY7ggczC17w5LSUlSE",
    userID: "royclaud",
    genres: "rap,canadian contemporary r&b,canadian pop,pop,atl hip hop,hip hop,rap",
  },
  {
    id: "6OrTKLtcF7EBayOV2QqkSK",
    name: "Hold That Heat (feat. Travis Scott)",
    artists: "Southside, Future, Travis Scott",
    duration_ms: 221538,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/d3ff37706ab059916026cb518f55fd7f64f87525?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024f410ee7ae9379cc30d07c50",
    danceability: 0.744,
    energy: 0.608,
    valence: 0.0692,
    added_at: "2022-11-30T17:55:07Z",
    link: "https://open.spotify.com/track/6OrTKLtcF7EBayOV2QqkSK",
    userID: "royclaud",
    genres: "atl hip hop,atl trap,atl hip hop,hip hop,rap,southern hip hop,trap,rap,slap house",
  },
  {
    id: "59nOXPmaKlBfGMDeOVGrIK",
    name: "WAIT FOR U (feat. Drake & Tems)",
    artists: "Future, Drake, Tems",
    duration_ms: 189893,
    popularity: 83,
    preview_url: "https://p.scdn.co/mp3-preview/38e12dbcba624463f1a360afd5dcceaf281b1ea5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0286badd635b69aea887862214",
    danceability: 0.463,
    energy: 0.642,
    valence: 0.339,
    added_at: "2022-11-30T17:54:58Z",
    link: "https://open.spotify.com/track/59nOXPmaKlBfGMDeOVGrIK",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap,southern hip hop,trap,canadian hip hop,canadian pop,hip hop,pop rap,rap,afro r&b,afrobeats,alte,nigerian pop",
  },
  {
    id: "0fX4oNGBWO3dSGUZcVdVV2",
    name: "N95",
    artists: "Kendrick Lamar",
    duration_ms: 195950,
    popularity: 77,
    preview_url: "https://p.scdn.co/mp3-preview/d5d77f460ea4285f30f1d06d455d45a3d984454c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022e02117d76426a08ac7c174f",
    danceability: 0.79,
    energy: 0.67,
    valence: 0.408,
    added_at: "2022-11-30T17:54:49Z",
    link: "https://open.spotify.com/track/0fX4oNGBWO3dSGUZcVdVV2",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "3Q5cLvkKsHJvYltf8k7HkO",
    name: "Ego Death (feat. Kanye West, FKA twigs & Skrillex)",
    artists: "Ty Dolla $ign, FKA twigs, Skrillex, Kanye West",
    duration_ms: 231618,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/aa0f8fb318c9b0ae0b73ec45506c57baa5126b70?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0228e596262150a696ff3a588f",
    danceability: 0.818,
    energy: 0.598,
    valence: 0.331,
    added_at: "2022-11-30T16:01:59Z",
    link: "https://open.spotify.com/track/3Q5cLvkKsHJvYltf8k7HkO",
    userID: "royclaud",
    genres: "hip hop,pop rap,r&b,southern hip hop,trap,trap soul,afrofuturism,alternative r&b,art pop,escape room,experimental r&b,trip hop,brostep,complextro,edm,electro,pop dance,chicago rap,hip hop,rap",
  },
  {
    id: "3tUJq43sUSw2zasgNjRY7S",
    name: "Cosmic Freeway",
    artists: "Yeek, Max of Homestead",
    duration_ms: 362666,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/e021d26a26c85081803f60be83061c180a8b33cd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f3630c9ab3c66f53abb51672",
    danceability: 0.45,
    energy: 0.244,
    valence: 0.271,
    added_at: "2022-11-29T17:11:32Z",
    link: "https://open.spotify.com/track/3tUJq43sUSw2zasgNjRY7S",
    userID: "royclaud",
    genres: "hyperpop,indie hip hop",
  },
  {
    id: "3XsNRi2cypsksscysYbyaF",
    name: "Drive - Edit",
    artists: "Black Coffee, David Guetta, Delilah Montagu",
    duration_ms: 191258,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/7eb6903dc4c631c3bd25a35430cc55f2d04b87e8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028b24033332bb5d78aaa7790d",
    danceability: 0.721,
    energy: 0.789,
    valence: 0.429,
    added_at: "2022-11-23T04:06:20Z",
    link: "https://open.spotify.com/track/3XsNRi2cypsksscysYbyaF",
    userID: "royclaud",
    genres: "south african house,south african pop dance,big room,dance pop,edm,pop,pop dance,british singer-songwriter",
  },
  {
    id: "3tTpvK7QgjjQCKGnHt5xn3",
    name: "Peso",
    artists: "A$AP Rocky",
    duration_ms: 169560,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/1fca6c578fe29d71f7f677e01cc8b4b7ce6d745b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c8ced8a4d6b6b61eb592f3dd",
    danceability: 0.415,
    energy: 0.831,
    valence: 0.583,
    added_at: "2022-11-22T21:35:55Z",
    link: "https://open.spotify.com/track/3tTpvK7QgjjQCKGnHt5xn3",
    userID: "royclaud",
    genres: "east coast hip hop,hip hop,rap",
  },
  {
    id: "6OSfa3MUmEnAPlFHbjro7d",
    name: "There Will Be Tears",
    artists: "Mr Hudson",
    duration_ms: 225626,
    popularity: 19,
    preview_url: "https://p.scdn.co/mp3-preview/8207b7124341d09b488fe59f75ce683c65be2b45?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0254593f73b313a6ec64dbd663",
    danceability: 0.719,
    energy: 0.759,
    valence: 0.298,
    added_at: "2022-11-21T17:08:44Z",
    link: "https://open.spotify.com/track/6OSfa3MUmEnAPlFHbjro7d",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "5CQ30WqJwcep0pYcV4AMNc",
    name: "Stairway to Heaven - Remaster",
    artists: "Led Zeppelin",
    duration_ms: 482830,
    popularity: 77,
    preview_url: "https://p.scdn.co/mp3-preview/337c8d45ac66899bc2db9ef3ed9438fe1d035ab1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c8a11e48c91a982d086afc69",
    danceability: 0.338,
    energy: 0.34,
    valence: 0.197,
    added_at: "2022-11-16T15:40:47Z",
    link: "https://open.spotify.com/track/5CQ30WqJwcep0pYcV4AMNc",
    userID: "royclaud",
    genres: "album rock,classic rock,hard rock,rock",
  },
  {
    id: "60TYdVs6TWIsAJ1MPRdv59",
    name: "Then Came the Last Days of May",
    artists: "Blue Öyster Cult",
    duration_ms: 210546,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/cd069484b5fa706fc921027b84b6227b26764a9d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a63fc9073db1233ea6c7ae74",
    danceability: 0.367,
    energy: 0.609,
    valence: 0.443,
    added_at: "2022-11-16T15:39:55Z",
    link: "https://open.spotify.com/track/60TYdVs6TWIsAJ1MPRdv59",
    userID: "royclaud",
    genres: "album rock,classic rock,glam metal,hard rock,progressive rock,rock",
  },
  {
    id: "1ju7EsSGvRybSNEsRvc7qY",
    name: "Simple Man",
    artists: "Lynyrd Skynyrd",
    duration_ms: 356626,
    popularity: 73,
    preview_url: "https://p.scdn.co/mp3-preview/e3cdaf6405fbc0518787a1a4de2777adad40ea11?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02128450651c9f0442780d8eb8",
    danceability: 0.422,
    energy: 0.752,
    valence: 0.404,
    added_at: "2022-11-16T15:39:14Z",
    link: "https://open.spotify.com/track/1ju7EsSGvRybSNEsRvc7qY",
    userID: "royclaud",
    genres: "album rock,classic rock,hard rock,rock,southern rock",
  },
  {
    id: "2OFrAsP5Vrcb0DtsPNsm54",
    name: "My Kind of Woman",
    artists: "Mac DeMarco",
    duration_ms: 190845,
    popularity: 3,
    preview_url: "https://p.scdn.co/mp3-preview/84a0a0a0a484047ef91d1841090bb04182e83692?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021e07e60dea6e2c877ea3b2b4",
    danceability: 0.501,
    energy: 0.765,
    valence: 0.46,
    added_at: "2022-11-14T20:43:15Z",
    link: "https://open.spotify.com/track/2OFrAsP5Vrcb0DtsPNsm54",
    userID: "royclaud",
    genres: "edmonton indie,lo-fi indie,pov: indie,slacker rock",
  },
  {
    id: "03eJ2DclFWXYU8GWgANdmZ",
    name: "bad vibes forever (feat. PnB Rock & Trippie Redd)",
    artists: "XXXTENTACION, PnB Rock, Trippie Redd",
    duration_ms: 150475,
    popularity: 76,
    preview_url: "https://p.scdn.co/mp3-preview/4e3dbca607cb61c2df213ae5b8dca748e7898c38?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fa96fd3251629605bb59345d",
    danceability: 0.8,
    energy: 0.526,
    valence: 0.746,
    added_at: "2022-11-14T20:39:02Z",
    link: "https://open.spotify.com/track/03eJ2DclFWXYU8GWgANdmZ",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap,melodic rap,rap,trap,melodic rap,rap,trap",
  },
  {
    id: "4QhWbupniDd44EDtnh2bFJ",
    name: "Pyramids",
    artists: "Frank Ocean",
    duration_ms: 592920,
    popularity: 77,
    preview_url: "https://p.scdn.co/mp3-preview/0fff01329318a30ba0b7c2a872c0113e6742a364?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027aede4855f6d0d738012e2e5",
    danceability: 0.4,
    energy: 0.65,
    valence: 0.15,
    added_at: "2022-11-14T00:17:12Z",
    link: "https://open.spotify.com/track/4QhWbupniDd44EDtnh2bFJ",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "4EDijkJdHBZZ0GwJ12iTAj",
    name: "Stargirl Interlude",
    artists: "The Weeknd, Lana Del Rey",
    duration_ms: 111640,
    popularity: 73,
    preview_url: "https://p.scdn.co/mp3-preview/ca25a8c9bd7f01f435435038c9a231f7c9b0af7a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024718e2b124f79258be7bc452",
    danceability: 0.638,
    energy: 0.474,
    valence: 0.441,
    added_at: "2022-11-14T00:16:47Z",
    link: "https://open.spotify.com/track/4EDijkJdHBZZ0GwJ12iTAj",
    userID: "royclaud",
    genres: "canadian contemporary r&b,canadian pop,pop,art pop,pop",
  },
  {
    id: "3e90JC8EKLsSDUHmPpxkfp",
    name: "9",
    artists: "WILLOW, SZA",
    duration_ms: 253800,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/1db13f38f99503dd116d0ea772d63c16c33f09ca?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022e2e393c5933ec6db7822508",
    danceability: 0.597,
    energy: 0.495,
    valence: 0.171,
    added_at: "2022-11-14T00:16:34Z",
    link: "https://open.spotify.com/track/3e90JC8EKLsSDUHmPpxkfp",
    userID: "royclaud",
    genres: "afrofuturism,pop,post-teen pop,pov: indie,pop,r&b,rap",
  },
  {
    id: "1H4Y9uW4N0LsxJUz0VnaPJ",
    name: "Just Pretend",
    artists: "Bad Omens",
    duration_ms: 204676,
    popularity: 75,
    preview_url: "https://p.scdn.co/mp3-preview/cfa450d92b8d50f4768d7562174acd84a9929dbe?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e5f6f7ec99735d7b870f18ae",
    danceability: 0.457,
    energy: 0.834,
    valence: 0.201,
    added_at: "2022-11-12T22:25:46Z",
    link: "https://open.spotify.com/track/1H4Y9uW4N0LsxJUz0VnaPJ",
    userID: "royclaud",
    genres: "american metalcore",
  },
  {
    id: "5xJlzQiPLYkvlqkRPKzBwD",
    name: "Emergency Contact",
    artists: "Pierce The Veil",
    duration_ms: 240000,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/0edca25de3fb036deaf6c68d433b51d54c13d159?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026e61cfcaf2d8c4a7b051d026",
    danceability: 0.542,
    energy: 0.828,
    valence: 0.368,
    added_at: "2022-11-12T22:19:41Z",
    link: "https://open.spotify.com/track/5xJlzQiPLYkvlqkRPKzBwD",
    userID: "royclaud",
    genres: "modern rock,pop punk,screamo",
  },
  {
    id: "3yfBq40ZhwQWs9xcdQMcmY",
    name: "Magic",
    artists: "Roy Woods",
    duration_ms: 203773,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/35fbdaced6b866b9a83ba19a82b078ee980fd28d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ba895f05221840751a45e3d5",
    danceability: 0.675,
    energy: 0.664,
    valence: 0.288,
    added_at: "2022-11-11T04:49:53Z",
    link: "https://open.spotify.com/track/3yfBq40ZhwQWs9xcdQMcmY",
    userID: "royclaud",
    genres: "canadian contemporary r&b,canadian hip hop",
  },
  {
    id: "1bDbXMyjaUIooNwFE9wn0N",
    name: "Rich Flex",
    artists: "Drake, 21 Savage",
    duration_ms: 239359,
    popularity: 76,
    preview_url: "https://p.scdn.co/mp3-preview/de60e5029a3488bfd6ce436beaca0472b7d20d9c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0202854a7060fccc1a66a4b5ad",
    danceability: 0.549,
    energy: 0.518,
    valence: 0.418,
    added_at: "2022-11-10T19:57:10Z",
    link: "https://open.spotify.com/track/1bDbXMyjaUIooNwFE9wn0N",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap,atl hip hop,hip hop,rap",
  },
  {
    id: "4NVNapccSX7E5JLiW0uQEy",
    name: "Pound Cake / Paris Morton Music 2",
    artists: "Drake, JAY-Z",
    duration_ms: 432853,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/44e1e2cb4bba1d2dade08d57216959e827f4c79d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a90d170c61fb7d063d47161d",
    danceability: 0.52,
    energy: 0.761,
    valence: 0.264,
    added_at: "2022-11-01T15:45:04Z",
    link: "https://open.spotify.com/track/4NVNapccSX7E5JLiW0uQEy",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap,east coast hip hop,gangster rap,hip hop,pop rap,rap",
  },
  {
    id: "1fewSx2d5KIZ04wsooEBOz",
    name: "Space Cadet (feat. Gunna)",
    artists: "Metro Boomin, Gunna",
    duration_ms: 203266,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/bc4e23d051aad9abdbb90183b4e1e682afd75308?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022887f8c05b5a9f1cb105be29",
    danceability: 0.901,
    energy: 0.464,
    valence: 0.638,
    added_at: "2022-10-29T16:42:47Z",
    link: "https://open.spotify.com/track/1fewSx2d5KIZ04wsooEBOz",
    userID: "royclaud",
    genres: "rap,atl hip hop,melodic rap,rap,trap",
  },
  {
    id: "4hoHiEUuFktA9ZcG2GulER",
    name: "Esta vida me encanta - COVER",
    artists: "Montserrat Alay",
    duration_ms: 135111,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/529bc85c1ad0c479a11063b4e95e8eca5d56dc9c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02845befad6bab6e8134fa1845",
    danceability: 0.826,
    energy: 0.489,
    valence: 0.382,
    added_at: "2022-10-27T16:38:00Z",
    link: "https://open.spotify.com/track/4hoHiEUuFktA9ZcG2GulER",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "0q1H8leLjzZvjHhgNBiVOx",
    name: "Desde Morro",
    artists: "Justin Morales",
    duration_ms: 146839,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/9380d4907f7f99139e5059e6a1d5935a415b4b76?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02eb5c7805b75031faf7381e2f",
    danceability: 0.79,
    energy: 0.688,
    valence: 0.815,
    added_at: "2022-10-26T22:53:39Z",
    link: "https://open.spotify.com/track/0q1H8leLjzZvjHhgNBiVOx",
    userID: "royclaud",
    genres: "corrido,corridos tumbados,sierreno",
  },
  {
    id: "5GkeaIeCEfB0LOqk7sIVHQ",
    name: "Tú",
    artists: "Los Elegantes de Jerez",
    duration_ms: 194266,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/20f5459ec0d2019aa66e560299678973d0f873d8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023899f861a65c1de680093d18",
    danceability: 0.767,
    energy: 0.701,
    valence: 0.888,
    added_at: "2022-10-26T22:41:37Z",
    link: "https://open.spotify.com/track/5GkeaIeCEfB0LOqk7sIVHQ",
    userID: "royclaud",
    genres: "huapango,norteno",
  },
  {
    id: "2pRTkMO8U7qlpCa8K8Pda3",
    name: "Flowers",
    artists: "AKTHESAVIOR",
    duration_ms: 171049,
    popularity: 24,
    preview_url: "https://p.scdn.co/mp3-preview/0252e49a4dd8de7d3eeb7898bbd2945343d5f5e1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c13d5e1e92cf206de3e7fb61",
    danceability: 0.617,
    energy: 0.431,
    valence: 0.235,
    added_at: "2022-10-26T02:06:06Z",
    link: "https://open.spotify.com/track/2pRTkMO8U7qlpCa8K8Pda3",
    userID: "royclaud",
    genres: "underground hip hop",
  },
  {
    id: "1oRxWYm6Vw7dwsnmewFuhx",
    name: "Ahora Piden Paros",
    artists: "Fuerza Regida, Los Gemelos De Sinaloa, Calle 24",
    duration_ms: 139625,
    popularity: 30,
    preview_url: "https://p.scdn.co/mp3-preview/5d9a7e550527d50a8bc680c67697cbaafa2de17e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0277e619ea0dc55a5f85fcae34",
    danceability: 0.639,
    energy: 0.88,
    valence: 0.965,
    added_at: "2022-10-25T23:22:09Z",
    link: "https://open.spotify.com/track/1oRxWYm6Vw7dwsnmewFuhx",
    userID: "royclaud",
    genres: "corrido,corridos tumbados,sad sierreno,sierreno,corrido,corridos tumbados,sad sierreno,sierreno,corrido,corridos tumbados,sad sierreno,sierreno",
  },
  {
    id: "1hHnCReCPe1FUkGLTeuCaC",
    name: "Me Acostumbre A Lo Bueno",
    artists: "Fuerza Regida",
    duration_ms: 140983,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/855e95393c0aab649f9cbb27c6806a867a7b8cae?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027d8afedc581ca940ed25207d",
    danceability: 0.715,
    energy: 0.739,
    valence: 0.963,
    added_at: "2022-10-25T23:10:17Z",
    link: "https://open.spotify.com/track/1hHnCReCPe1FUkGLTeuCaC",
    userID: "royclaud",
    genres: "corrido,corridos tumbados,sad sierreno,sierreno",
  },
  {
    id: "6rtsyfCs4GPlkcqCfu3y6W",
    name: "Soy Mejor",
    artists: "Grupo Accion",
    duration_ms: 159848,
    popularity: 12,
    preview_url: "https://p.scdn.co/mp3-preview/c41771bdf47456c688eab19894b352416a327a12?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023ca907db5e9eab7e9ae65c6e",
    danceability: 0.745,
    energy: 0.743,
    valence: 0.907,
    added_at: "2022-10-25T23:04:43Z",
    link: "https://open.spotify.com/track/6rtsyfCs4GPlkcqCfu3y6W",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "1kNC7b4zXbqoceKpkGdnD8",
    name: "Puro Campeón",
    artists: "Grupo Marca Registrada, Luis R Conriquez",
    duration_ms: 196437,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/f3f43238a4e00a9d839be6c12751825c469d7b5b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02cdf895c6fb352b2068826abc",
    danceability: 0.851,
    energy: 0.542,
    valence: 0.789,
    added_at: "2022-10-24T20:35:04Z",
    link: "https://open.spotify.com/track/1kNC7b4zXbqoceKpkGdnD8",
    userID: "royclaud",
    genres: "corrido,norteno,sad sierreno,corrido,sad sierreno,sierreno",
  },
  {
    id: "2xTLTU2K901snq53FLWZKE",
    name: "Consejos Gratis",
    artists: "Eden Muñoz",
    duration_ms: 142656,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/f8133b5292dbfdb23b917bce3b62e34e9f5466a1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023f9b0a2dbeee17eca2b9ebea",
    danceability: 0.559,
    energy: 0.574,
    valence: 0.969,
    added_at: "2022-10-24T20:34:59Z",
    link: "https://open.spotify.com/track/2xTLTU2K901snq53FLWZKE",
    userID: "royclaud",
    genres: "banda,corrido,musica mexicana,norteno,sierreno",
  },
  {
    id: "635YCTVjU5z9hMAv5wAooo",
    name: "Gracias A La Vida",
    artists: "Angel Carrillo, Brandon Reyes y Elvin",
    duration_ms: 203933,
    popularity: 14,
    preview_url: "https://p.scdn.co/mp3-preview/8346e662ece8e72ff45922ccf95e08693f134e2b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028a7196c22d74e17050d279d5",
    danceability: 0.671,
    energy: 0.752,
    valence: 0.783,
    added_at: "2022-10-23T19:15:53Z",
    link: "https://open.spotify.com/track/635YCTVjU5z9hMAv5wAooo",
    userID: "royclaud",
    genres: "corrido",
  },
  {
    id: "2qs0cwUs65Bi6PMV6WMy2u",
    name: "Otd Shit",
    artists: "GueroX, Angel Tumbado",
    duration_ms: 185409,
    popularity: 2,
    preview_url: "https://p.scdn.co/mp3-preview/a1a6f17e76e35fa5989cb065733bb81f044c6eb4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027787e4ae52c7e8500c8236e9",
    danceability: 0.765,
    energy: 0.757,
    valence: 0.575,
    added_at: "2022-10-23T17:03:27Z",
    link: "https://open.spotify.com/track/2qs0cwUs65Bi6PMV6WMy2u",
    userID: "royclaud",
    genres: "corridos tumbados,sad sierreno,sierreno,corrido,corridos tumbados,sierreno",
  },
  {
    id: "0e7ipj03S05BNilyu5bRzt",
    name: "rockstar (feat. 21 Savage)",
    artists: "Post Malone, 21 Savage",
    duration_ms: 218146,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/beb04a51a925ea2ed2fb7c2363b7b3fa37ebc89b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b1c4b76e23414c9f20242268",
    danceability: 0.585,
    energy: 0.52,
    valence: 0.129,
    added_at: "2022-10-22T20:40:13Z",
    link: "https://open.spotify.com/track/0e7ipj03S05BNilyu5bRzt",
    userID: "royclaud",
    genres: "dfw rap,melodic rap,pop,rap,atl hip hop,hip hop,rap",
  },
  {
    id: "48qgXl0CQUVYJVn6xv0rsP",
    name: "Nothing Changed",
    artists: "Quavo, Takeoff",
    duration_ms: 199111,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/78c979dfa5c3a986116c014eebf197aa5436a38d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e202633be974b7134282c6d1",
    danceability: 0.911,
    energy: 0.583,
    valence: 0.705,
    added_at: "2022-10-08T17:46:08Z",
    link: "https://open.spotify.com/track/48qgXl0CQUVYJVn6xv0rsP",
    userID: "royclaud",
    genres: "atl hip hop,melodic rap,rap,trap,atl hip hop,rap,trap",
  },
  {
    id: "2Z39qnGywUwFpKevyYSKPE",
    name: "Make It Right",
    artists: "Nessly, Joji",
    duration_ms: 227573,
    popularity: 8,
    preview_url: "https://p.scdn.co/mp3-preview/bb7035dd90612dd3b39eb1691a9362bac9df03c1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023b422489d2a5b8b48185243e",
    danceability: 0.762,
    energy: 0.541,
    valence: 0.719,
    added_at: "2022-10-06T14:57:13Z",
    link: "https://open.spotify.com/track/2Z39qnGywUwFpKevyYSKPE",
    userID: "royclaud",
    genres: "melodic rap,viral pop",
  },
  {
    id: "1qbXrxctv75pm1lWCn6Zw3",
    name: "Walking (feat. Swae Lee & Major Lazer)",
    artists: "88rising, Joji, Jackson Wang, Major Lazer, Swae Lee",
    duration_ms: 167080,
    popularity: 3,
    preview_url: "https://p.scdn.co/mp3-preview/8e63026a9028b0c8e38ca020453a5fe0e7a98d92?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02488df3d22b1f5c0ea15b686a",
    danceability: 0.701,
    energy: 0.663,
    valence: 0.334,
    added_at: "2022-10-06T14:57:11Z",
    link: "https://open.spotify.com/track/1qbXrxctv75pm1lWCn6Zw3",
    userID: "royclaud",
    genres: "asian american hip hop,viral pop,k-pop,dance pop,edm,electro house,moombahton,pop,pop dance,melodic rap,rap,trap",
  },
  {
    id: "0baNzeUcPQnQSagpe8T0mD",
    name: "worldstar money (interlude)",
    artists: "Joji",
    duration_ms: 126312,
    popularity: 74,
    preview_url: "https://p.scdn.co/mp3-preview/9fd17162cb7b1c64eedfca1e3263eec41579032f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028e947f66f6d4eeaa56a28767",
    danceability: 0.577,
    energy: 0.387,
    valence: 0.459,
    added_at: "2022-10-06T03:09:49Z",
    link: "https://open.spotify.com/track/0baNzeUcPQnQSagpe8T0mD",
    userID: "royclaud",
    genres: "viral pop",
  },
  {
    id: "7yNf9YjeO5JXUE3JEBgnYc",
    name: "Babydoll",
    artists: "Dominic Fike",
    duration_ms: 97960,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/bf5ab98492256120bd9c48658e9c899c8692f77f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027b1b6f41c1645af9757d5616",
    danceability: 0.762,
    energy: 0.604,
    valence: 0.847,
    added_at: "2022-09-23T04:19:06Z",
    link: "https://open.spotify.com/track/7yNf9YjeO5JXUE3JEBgnYc",
    userID: "royclaud",
    genres: "alternative pop rock,pov: indie",
  },
  {
    id: "6iUVHM3gPI58RmGCpR5Xy4",
    name: "SHADOW OF A CLOUD",
    artists: "Michael The Author",
    duration_ms: 227321,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/fee7503b770708e07c88d7d9c49cf8f7d2ced7be?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02853e099a32c31f755fff31e4",
    danceability: 0.752,
    energy: 0.642,
    valence: 0.569,
    added_at: "2022-09-23T04:17:22Z",
    link: "https://open.spotify.com/track/6iUVHM3gPI58RmGCpR5Xy4",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "3hxIUxnT27p5WcmjGUXNwx",
    name: "Shut up My Moms Calling",
    artists: "Hotel Ugly",
    duration_ms: 164571,
    popularity: 86,
    preview_url: "https://p.scdn.co/mp3-preview/7642409265c74b714a2f0f0dd8663c031e253485?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02350ab7a839c04bfd5225a9f5",
    danceability: 0.485,
    energy: 0.409,
    valence: 0.376,
    added_at: "2022-09-23T04:09:44Z",
    link: "https://open.spotify.com/track/3hxIUxnT27p5WcmjGUXNwx",
    userID: "royclaud",
    genres: "modern indie pop",
  },
  {
    id: "1NlGXcWeUSe1s3IgRKcqmB",
    name: "Antidote",
    artists: "Orion Sun",
    duration_ms: 169822,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/0b166bb00668fd2c83f0dbb564841cb797773198?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ee0ec368f9b61f602365d864",
    danceability: 0.645,
    energy: 0.786,
    valence: 0.452,
    added_at: "2022-09-19T16:24:50Z",
    link: "https://open.spotify.com/track/1NlGXcWeUSe1s3IgRKcqmB",
    userID: "royclaud",
    genres: "alternative r&b,bedroom pop",
  },
  {
    id: "4IBL3uksmg3kUOf7iUzkpg",
    name: "Really Something",
    artists: "Hector Gachan",
    duration_ms: 219100,
    popularity: 43,
    preview_url: "https://p.scdn.co/mp3-preview/8d0026e873a0a0f3580168ca3b02d619d6c5635e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027a94e5e285f5d4dbc21dbc96",
    danceability: 0.669,
    energy: 0.713,
    valence: 0.613,
    added_at: "2022-09-09T20:49:04Z",
    link: "https://open.spotify.com/track/4IBL3uksmg3kUOf7iUzkpg",
    userID: "royclaud",
    genres: "bedroom pop",
  },
  {
    id: "6OiRh4kttAs1YWglvTcYkB",
    name: "Alrighty Aphrodite",
    artists: "Peach Pit",
    duration_ms: 207813,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/7bb335ebd64d19bfa0e5679618806177d562b71a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0200b39b4a73d28536690b355c",
    danceability: 0.534,
    energy: 0.724,
    valence: 0.503,
    added_at: "2022-08-31T21:33:36Z",
    link: "https://open.spotify.com/track/6OiRh4kttAs1YWglvTcYkB",
    userID: "royclaud",
    genres: "pov: indie,vancouver indie",
  },
  {
    id: "1pRkGgpQGX6nr8PrO1NMIs",
    name: "Because (feat. Joey Bada$$, Russ, and Dylan Cartlidge)",
    artists: "Danger Mouse, Black Thought, Russ, Dylan Cartlidge, Joey Bada$$",
    duration_ms: 281360,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/19848d44481f321b8e794f1884c06481804f30ea?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027d79b9c2cacc84b20de67f80",
    danceability: 0.609,
    energy: 0.684,
    valence: 0.506,
    added_at: "2022-08-20T18:40:20Z",
    link: "https://open.spotify.com/track/1pRkGgpQGX6nr8PrO1NMIs",
    userID: "royclaud",
    genres: "alternative hip hop,mashup,alternative hip hop,east coast hip hop,hawaiian hip hop,pop rap,uk alternative hip hop,conscious hip hop,hip hop,rap",
  },
  {
    id: "4bZ7AZNl13T2t5VI6Dzn1m",
    name: "Love You Bad (feat. Malaya & Phoelix)",
    artists: "Terrace Martin, Robert Glasper, 9th Wonder, Kamasi Washington, Dinner Party, Malaya, Phoelix",
    duration_ms: 135160,
    popularity: 47,
    preview_url: "https://p.scdn.co/mp3-preview/69bb80531b762a5ba6df4188b9880dd20dc680f8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02448f76ef5c377b531cdec9d8",
    danceability: 0.394,
    energy: 0.641,
    valence: 0.445,
    added_at: "2022-08-12T00:32:51Z",
    link: "https://open.spotify.com/track/4bZ7AZNl13T2t5VI6Dzn1m",
    userID: "royclaud",
    genres:
      "alternative r&b,indie soul,contemporary jazz,indie jazz,indie soul,jazz,modern jazz piano,neo r&b,neo soul,alternative hip hop,hip hop,instrumental hip hop,north carolina hip hop,afrofuturism,contemporary jazz,indie soul,jazz,jazz saxophone,indie hip hop",
  },
  {
    id: "0jqJNqmrdryU1nnctIzu9n",
    name: "Xtasy",
    artists: "Ravyn Lenae",
    duration_ms: 178200,
    popularity: 34,
    preview_url: "https://p.scdn.co/mp3-preview/44a9625a60729e6c74af5cbd9f59f411dc5705ee?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a2a1ffef42c5c2ba1eb8c9a5",
    danceability: 0.615,
    energy: 0.759,
    valence: 0.717,
    added_at: "2022-08-10T21:07:21Z",
    link: "https://open.spotify.com/track/0jqJNqmrdryU1nnctIzu9n",
    userID: "royclaud",
    genres: "alternative r&b,indie soul",
  },
  {
    id: "1zigoX1Bxo39a9lIHPxFyR",
    name: "Mr. Carter",
    artists: "Lil Wayne, JAY-Z",
    duration_ms: 316266,
    popularity: 50,
    preview_url: "https://p.scdn.co/mp3-preview/aa0a78f4f75afbee2476d42fb3ee54f32cb35e2a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0202a999c3a283b5392e57737d",
    danceability: 0.517,
    energy: 0.714,
    valence: 0.461,
    added_at: "2022-08-03T17:25:32Z",
    link: "https://open.spotify.com/track/1zigoX1Bxo39a9lIHPxFyR",
    userID: "royclaud",
    genres: "hip hop,new orleans rap,pop rap,rap,trap,east coast hip hop,gangster rap,hip hop,pop rap,rap",
  },
  {
    id: "0X9tyUxH6fPN0C2BgESONf",
    name: "FEDS",
    artists: "Nick Grant, Tae Beast",
    duration_ms: 160879,
    popularity: 32,
    preview_url: "https://p.scdn.co/mp3-preview/4b0ba0ecf351b1ee08103e12db70ab99978c0258?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0242ab2e8a5fc1b9e1a678b7ac",
    danceability: 0.48,
    energy: 0.982,
    valence: 0.51,
    added_at: "2022-08-03T17:19:44Z",
    link: "https://open.spotify.com/track/0X9tyUxH6fPN0C2BgESONf",
    userID: "royclaud",
    genres: "atl hip hop",
  },
  {
    id: "2Y40huo5ewaKYxsFintYtF",
    name: "Heaven and Hell",
    artists: "Kanye West",
    duration_ms: 145301,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/e6e16b218a3d94d6e627b8589dfef82416606df7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02cad190f1a73c024e5a40dddd",
    danceability: 0.764,
    energy: 0.422,
    valence: 0.268,
    added_at: "2022-08-03T17:11:20Z",
    link: "https://open.spotify.com/track/2Y40huo5ewaKYxsFintYtF",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "0KwmQGPCDkAUc4isMOj7VD",
    name: "U Don't Know",
    artists: "JAY-Z",
    duration_ms: 199440,
    popularity: 37,
    preview_url: "https://p.scdn.co/mp3-preview/41067c0d7e14b1b8b0238de7db194de18aa125e3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029992c5d2c010ccb3f6fa6bb6",
    danceability: 0.549,
    energy: 0.913,
    valence: 0.669,
    added_at: "2022-08-03T17:05:35Z",
    link: "https://open.spotify.com/track/0KwmQGPCDkAUc4isMOj7VD",
    userID: "royclaud",
    genres: "east coast hip hop,gangster rap,hip hop,pop rap,rap",
  },
  {
    id: "1qMMYpVatbRITKCfq1gasi",
    name: "PUFFIN ON ZOOTIEZ",
    artists: "Future",
    duration_ms: 172933,
    popularity: 79,
    preview_url: "https://p.scdn.co/mp3-preview/a5bb6f511c845bd5f971e0fca5ba45f080835ca9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0286badd635b69aea887862214",
    danceability: 0.883,
    energy: 0.657,
    valence: 0.284,
    added_at: "2022-06-30T22:45:42Z",
    link: "https://open.spotify.com/track/1qMMYpVatbRITKCfq1gasi",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap,southern hip hop,trap",
  },
  {
    id: "4VnR7plUitZ788acvBHdP6",
    name: "I spoke to the devil in miami, he said everything would be fine",
    artists: "XXXTENTACION",
    duration_ms: 178880,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/071031a4059e8360d5a14879ad877c59ce1d7140?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0252cc5e3765a135c52e1bbbbc",
    danceability: 0.348,
    energy: 0.197,
    valence: 0.215,
    added_at: "2022-06-27T21:24:23Z",
    link: "https://open.spotify.com/track/4VnR7plUitZ788acvBHdP6",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "1QPreu0BNOrUfEb8HTd2qG",
    name: "Rich Spirit",
    artists: "Kendrick Lamar",
    duration_ms: 202285,
    popularity: 74,
    preview_url: "https://p.scdn.co/mp3-preview/69cc0da19e45393d6b78aaaaae2a147ee04daf98?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022e02117d76426a08ac7c174f",
    danceability: 0.852,
    energy: 0.421,
    valence: 0.457,
    added_at: "2022-06-23T21:27:09Z",
    link: "https://open.spotify.com/track/1QPreu0BNOrUfEb8HTd2qG",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "39ZCoAQVRkBsAIo0kO8f1f",
    name: "RAINCLOUDS",
    artists: "veggi, KhakiKid, Ben Beal",
    duration_ms: 163058,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/357dad1afc94339b84d8d29af63e97e3e548b723?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a29a7c2e7b13e6ed484b8650",
    danceability: 0.646,
    energy: 0.687,
    valence: 0.683,
    added_at: "2022-06-20T15:48:05Z",
    link: "https://open.spotify.com/track/39ZCoAQVRkBsAIo0kO8f1f",
    userID: "royclaud",
    genres: "irish hip hop,lo-fi rap",
  },
  {
    id: "513IPuqvC7V8XHTbIe6EWw",
    name: "Legal Sins",
    artists: "Kemba, Kota the Friend",
    duration_ms: 210125,
    popularity: 27,
    preview_url: "https://p.scdn.co/mp3-preview/5f33c01327a52c831e712c04c2b34c1f3f665d9b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c22d0653a3cc286ed6afffa4",
    danceability: 0.741,
    energy: 0.664,
    valence: 0.263,
    added_at: "2022-06-17T14:29:08Z",
    link: "https://open.spotify.com/track/513IPuqvC7V8XHTbIe6EWw",
    userID: "royclaud",
    genres: "alternative hip hop,indie hip hop,underground hip hop",
  },
  {
    id: "1BTECTa3NituN8LqXjrw2B",
    name: "Jupiter & Mars",
    artists: "ARDN",
    duration_ms: 196363,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/30a9852fdcb4323af11341adda224ca698466379?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02417099c5bf2389a4d9c0243a",
    danceability: 0.468,
    energy: 0.808,
    valence: 0.279,
    added_at: "2022-06-13T16:34:17Z",
    link: "https://open.spotify.com/track/1BTECTa3NituN8LqXjrw2B",
    userID: "royclaud",
    genres: "alberta hip hop",
  },
  {
    id: "39YXI3na4MIpVZhJj3GFi7",
    name: "VOODOO (feat. Kodak Black)",
    artists: "Future, Kodak Black",
    duration_ms: 211133,
    popularity: 47,
    preview_url: "https://p.scdn.co/mp3-preview/5ec4ae06af1271ea110ada1dae8ee48489b24192?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0286badd635b69aea887862214",
    danceability: 0.499,
    energy: 0.404,
    valence: 0.237,
    added_at: "2022-06-11T11:04:03Z",
    link: "https://open.spotify.com/track/39YXI3na4MIpVZhJj3GFi7",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap,southern hip hop,trap,florida drill,florida rap,melodic rap,miami hip hop,rap,trap,trap latino",
  },
  {
    id: "0j8ppsOOawdPCJnSTcXgOy",
    name: "GOSHA",
    artists: "$NOT",
    duration_ms: 120000,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/0c64d9bc5f483d6d939c4e2be435968b38756e77?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020566601aa12281a78204ffa7",
    danceability: 0.847,
    energy: 0.448,
    valence: 0.488,
    added_at: "2022-06-09T18:36:53Z",
    link: "https://open.spotify.com/track/0j8ppsOOawdPCJnSTcXgOy",
    userID: "royclaud",
    genres: "cloud rap,florida rap,underground hip hop",
  },
  {
    id: "4LS6xLmYwTUURADEIrmNaT",
    name: "VINTAGE DIOR",
    artists: "$NOT",
    duration_ms: 119682,
    popularity: 41,
    preview_url: "https://p.scdn.co/mp3-preview/e6d106f18c13e4b2de411fed7cc8704959120b8f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02282da2b257bc6b4bbbfffe53",
    danceability: 0.716,
    energy: 0.414,
    valence: 0.698,
    added_at: "2022-06-09T18:36:51Z",
    link: "https://open.spotify.com/track/4LS6xLmYwTUURADEIrmNaT",
    userID: "royclaud",
    genres: "cloud rap,florida rap,underground hip hop",
  },
  {
    id: "389jJeNsu2sZ9vBwbwZiMH",
    name: '"Life"',
    artists: "$NOT",
    duration_ms: 144960,
    popularity: 41,
    preview_url: "https://p.scdn.co/mp3-preview/45c3b922cc93e36569d875634cd96ba65ad4273f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c343fbd7aeb726a2c1e1bb97",
    danceability: 0.649,
    energy: 0.612,
    valence: 0.436,
    added_at: "2022-06-09T18:36:50Z",
    link: "https://open.spotify.com/track/389jJeNsu2sZ9vBwbwZiMH",
    userID: "royclaud",
    genres: "cloud rap,florida rap,underground hip hop",
  },
  {
    id: "706ZrLifsm0nwlucKr4kQg",
    name: "Like Me (feat. iann dior)",
    artists: "$NOT, iann dior",
    duration_ms: 167146,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/1db42abff1e34b975e51a73f62985cbd8ea631df?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c343fbd7aeb726a2c1e1bb97",
    danceability: 0.902,
    energy: 0.462,
    valence: 0.646,
    added_at: "2022-06-09T18:36:47Z",
    link: "https://open.spotify.com/track/706ZrLifsm0nwlucKr4kQg",
    userID: "royclaud",
    genres: "cloud rap,florida rap,underground hip hop,melodic rap",
  },
  {
    id: "1UGD3lW3tDmgZfAVDh6w7r",
    name: "Devil In A New Dress",
    artists: "Kanye West, Rick Ross",
    duration_ms: 351946,
    popularity: 76,
    preview_url: "https://p.scdn.co/mp3-preview/27da2905d952787da75df1b57038d0182e1f2e33?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d9194aa18fa4c9362b47464f",
    danceability: 0.435,
    energy: 0.76,
    valence: 0.206,
    added_at: "2022-06-07T13:24:42Z",
    link: "https://open.spotify.com/track/1UGD3lW3tDmgZfAVDh6w7r",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap,dirty south rap,gangster rap,hip hop,rap,southern hip hop,trap",
  },
  {
    id: "3oHkMCVJyOcjg5FhfLc2Rq",
    name: "A$AP Forever REMIX (feat. Moby, T.I. & Kid Cudi)",
    artists: "A$AP Rocky, Moby, T.I., Kid Cudi",
    duration_ms: 315773,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/9ab7a27fa0c1b8a91e578bf987fa124018d93f2e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029feadc48ab0661e9b3a9170b",
    danceability: 0.428,
    energy: 0.754,
    valence: 0.394,
    added_at: "2022-06-05T18:32:54Z",
    link: "https://open.spotify.com/track/3oHkMCVJyOcjg5FhfLc2Rq",
    userID: "royclaud",
    genres: "east coast hip hop,hip hop,rap,downtempo,electronica,atl hip hop,dirty south rap,gangster rap,hip hop,pop rap,rap,southern hip hop,trap,hip hop,ohio hip hop,pop rap,rap",
  },
  {
    id: "4m1mrlWu35x0trDuKE5XvX",
    name: "Fall In Love",
    artists: "Craig Xen, Diablo",
    duration_ms: 150073,
    popularity: 45,
    preview_url: "https://p.scdn.co/mp3-preview/11f91a301f2e6dfd9a24d9374e9fd203c00d82da?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024de00f07577b674f221889a0",
    danceability: 0.709,
    energy: 0.57,
    valence: 0.605,
    added_at: "2022-06-04T16:10:16Z",
    link: "https://open.spotify.com/track/4m1mrlWu35x0trDuKE5XvX",
    userID: "royclaud",
    genres: "cloud rap,dark trap",
  },
  {
    id: "64ydkbrt0xhdJXRaam06Mc",
    name: "Mile High",
    artists: "James Blake, Travis Scott, Metro Boomin",
    duration_ms: 193600,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/5c70671d300023689f92f565aa23cfa82c6daf39?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a7695725012f01292f2877e0",
    danceability: 0.754,
    energy: 0.37,
    valence: 0.228,
    added_at: "2022-06-03T14:17:34Z",
    link: "https://open.spotify.com/track/64ydkbrt0xhdJXRaam06Mc",
    userID: "royclaud",
    genres: "art pop,indie soul,indietronica,uk alternative pop,rap,slap house,rap",
  },
  {
    id: "3s7MCdXyWmwjdcWh7GWXas",
    name: "Violent Crimes",
    artists: "Kanye West",
    duration_ms: 215320,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/46bee66e9e81c3a3784069ba2fd9d2d24772760b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020cd942c1a864afa4e92d04f2",
    danceability: 0.669,
    energy: 0.419,
    valence: 0.0397,
    added_at: "2022-06-03T14:06:30Z",
    link: "https://open.spotify.com/track/3s7MCdXyWmwjdcWh7GWXas",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "7hmk7qI68wUFRe1yqWNNZ9",
    name: "Knocked Down",
    artists: "Slaine, The Arcitype",
    duration_ms: 165960,
    popularity: 28,
    preview_url: "https://p.scdn.co/mp3-preview/4b0552e7a157b7d576c696d750bdc7eace96b133?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02745c4f9ea21dad27b34e65f3",
    danceability: 0.612,
    energy: 0.905,
    valence: 0.738,
    added_at: "2022-06-02T14:23:29Z",
    link: "https://open.spotify.com/track/7hmk7qI68wUFRe1yqWNNZ9",
    userID: "royclaud",
    genres: "boston hip hop",
  },
  {
    id: "2M9ULmQwTaTGmAdXaXpfz5",
    name: "Billionaire (feat. Bruno Mars)",
    artists: "Travie McCoy, Bruno Mars",
    duration_ms: 211160,
    popularity: 74,
    preview_url: "https://p.scdn.co/mp3-preview/02f41843308e75503161cfce28114902b9e072fa?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0237ce3dd20c2318e13c6608ed",
    danceability: 0.633,
    energy: 0.673,
    valence: 0.659,
    added_at: "2022-05-27T13:57:48Z",
    link: "https://open.spotify.com/track/2M9ULmQwTaTGmAdXaXpfz5",
    userID: "royclaud",
    genres: "pop rap,dance pop,pop",
  },
  {
    id: "6OusKupi0NhfAmT8Ab06Tg",
    name: "Suga Suga, Best I Ever Had",
    artists: "vandalgamble",
    duration_ms: 245106,
    popularity: 0,
    preview_url: "null",
    image: "https://i.scdn.co/image/ab67616d00001e02e38f7803b95a5d91db3ef312",
    danceability: 0.622,
    energy: 0.709,
    valence: 0.611,
    added_at: "2022-05-26T16:25:20Z",
    link: "https://open.spotify.com/track/6OusKupi0NhfAmT8Ab06Tg",
    userID: "royclaud",
    genres: "slowed and reverb",
  },
  {
    id: "2oLwDI8tZUOh6SmTMRRfKs",
    name: "Elevators (Me & You)",
    artists: "Outkast",
    duration_ms: 265200,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/008a330983c6f9c31e9dcd01ff211df117a261b7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0284be563c5931d7ef35706148",
    danceability: 0.772,
    energy: 0.586,
    valence: 0.643,
    added_at: "2022-05-26T16:14:14Z",
    link: "https://open.spotify.com/track/2oLwDI8tZUOh6SmTMRRfKs",
    userID: "royclaud",
    genres: "atl hip hop,dirty south rap,hip hop,old school atlanta hip hop,rap,southern hip hop",
  },
  {
    id: "4dGuRldChjvboZktprNJFM",
    name: "Hush - Still Woozy Remix",
    artists: "The Marías, Still Woozy",
    duration_ms: 182346,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/413ba3645179bcbb1248b4412477b2f758fb94cf?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b2abc93d337f3e9829aaf0ae",
    danceability: 0.708,
    energy: 0.554,
    valence: 0.564,
    added_at: "2022-05-26T14:03:37Z",
    link: "https://open.spotify.com/track/4dGuRldChjvboZktprNJFM",
    userID: "royclaud",
    genres: "bedroom pop,la indie,bedroom pop,oakland indie,pov: indie",
  },
  {
    id: "2qOm7ukLyHUXWyR4ZWLwxA",
    name: "It Was A Good Day",
    artists: "Ice Cube",
    duration_ms: 260000,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/ea4eb0210dd50f7b3e1c43136adab0afc86e418f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02994c319841a923465d62e271",
    danceability: 0.798,
    energy: 0.744,
    valence: 0.794,
    added_at: "2022-05-26T05:57:01Z",
    link: "https://open.spotify.com/track/2qOm7ukLyHUXWyR4ZWLwxA",
    userID: "royclaud",
    genres: "conscious hip hop,g funk,gangster rap,hip hop,rap,west coast rap",
  },
  {
    id: "3hCPh4oVVjaM8tv9sFH8cx",
    name: "Suite bergamasque, L. 75: III. Clair de lune",
    artists: "Claude Debussy, Alice Sara Ott",
    duration_ms: 294960,
    popularity: 53,
    preview_url: "https://p.scdn.co/mp3-preview/e38908862981c198152a15c31f8b12a801bb9bc9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021c77de02f671a90a480f3b97",
    danceability: 0.32,
    energy: 0.00501,
    valence: 0.0383,
    added_at: "2022-05-24T21:46:22Z",
    link: "https://open.spotify.com/track/3hCPh4oVVjaM8tv9sFH8cx",
    userID: "royclaud",
    genres: "classical,impressionism,post-romantic era,classical performance,classical piano,german classical piano",
  },
  {
    id: "3jDdpx9PMlfMBS5tOBHFm9",
    name: "Return of the Mack",
    artists: "Mark Morrison",
    duration_ms: 213093,
    popularity: 76,
    preview_url: "https://p.scdn.co/mp3-preview/456989fb9b32c4525f0668676ad5067d20479b4a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0201841d493ec3808242042c0f",
    danceability: 0.715,
    energy: 0.833,
    valence: 0.612,
    added_at: "2022-05-24T17:39:49Z",
    link: "https://open.spotify.com/track/3jDdpx9PMlfMBS5tOBHFm9",
    userID: "royclaud",
    genres: "british soul",
  },
  {
    id: "2lD6AoA8qf2t4Dkf2TcmNK",
    name: "Rigamortus",
    artists: "Kendrick Lamar",
    duration_ms: 168591,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/f733ed980dbcf98188912198d02ae947159e85e8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02eddb2639b74ac6c202032ebe",
    danceability: 0.52,
    energy: 0.807,
    valence: 0.628,
    added_at: "2022-05-23T16:14:21Z",
    link: "https://open.spotify.com/track/2lD6AoA8qf2t4Dkf2TcmNK",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "5aYR2yKYdcWxX7gAO2CYps",
    name: "I'll Never Fall in Love Again",
    artists: "Burt Bacharach, Elvis Costello",
    duration_ms: 200000,
    popularity: 38,
    preview_url: "https://p.scdn.co/mp3-preview/1026fb04f7ca96e78f34553f4e624869991bd1f5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a1547401b3ae65b6be046326",
    danceability: 0.637,
    energy: 0.46,
    valence: 0.446,
    added_at: "2022-05-23T12:49:05Z",
    link: "https://open.spotify.com/track/5aYR2yKYdcWxX7gAO2CYps",
    userID: "royclaud",
    genres: "adult standards,brill building pop,easy listening,lounge,new wave,new wave pop,permanent wave,power pop,singer-songwriter",
  },
  {
    id: "6juVyvtP06MBOCG7XdidsM",
    name: "Empty",
    artists: "Cooliecut, Craig Xen, Kin$oul, Ski Mask The Slump God",
    duration_ms: 226285,
    popularity: 36,
    preview_url: "https://p.scdn.co/mp3-preview/f5a5ae43acfa71692b7a05bba00fb6e350ce6be7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0265ba84e2771cc90d8192a06a",
    danceability: 0.707,
    energy: 0.488,
    valence: 0.43,
    added_at: "2022-05-22T21:12:40Z",
    link: "https://open.spotify.com/track/6juVyvtP06MBOCG7XdidsM",
    userID: "royclaud",
    genres: "cloud rap,dark trap,hip hop,miami hip hop,pop rap,rap,trap,underground hip hop",
  },
  {
    id: "6vWEAOUSxohKxhp0K1BsxL",
    name: "Mixed Personalities (feat. Kanye West)",
    artists: "YNW Melly, Kanye West",
    duration_ms: 230400,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/18b02c04b398f015f4f15354d781d63090d9df9c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0218fe5f786c4a429f47b1b253",
    danceability: 0.661,
    energy: 0.44,
    valence: 0.352,
    added_at: "2022-05-22T15:55:19Z",
    link: "https://open.spotify.com/track/6vWEAOUSxohKxhp0K1BsxL",
    userID: "royclaud",
    genres: "melodic rap,rap,chicago rap,hip hop,rap",
  },
  {
    id: "2xi3mhJdVs358bQgqpHt9f",
    name: "Looking At Me (feat. Rich The Kid)",
    artists: "Flipp Dinero, Rich The Kid",
    duration_ms: 207517,
    popularity: 40,
    preview_url: "https://p.scdn.co/mp3-preview/10469a1ca8e4261a40023fa7dd90bf826c9a9782?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b164878e278ee736452836c7",
    danceability: 0.78,
    energy: 0.612,
    valence: 0.443,
    added_at: "2022-05-22T12:49:15Z",
    link: "https://open.spotify.com/track/2xi3mhJdVs358bQgqpHt9f",
    userID: "royclaud",
    genres: "melodic rap,trap,atl hip hop,melodic rap,plugg,rap,trap",
  },
  {
    id: "5GUYJTQap5F3RDQiCOJhrS",
    name: "Self Control",
    artists: "Frank Ocean",
    duration_ms: 249667,
    popularity: 77,
    preview_url: "https://p.scdn.co/mp3-preview/64db1616c8839309c68bafc68e32c45bcb4677c2?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c5649add07ed3720be9d5526",
    danceability: 0.581,
    energy: 0.21,
    valence: 0.452,
    added_at: "2022-05-22T12:44:53Z",
    link: "https://open.spotify.com/track/5GUYJTQap5F3RDQiCOJhrS",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "5tsI3xxDHDgzHhn30LTQNz",
    name: "These Days",
    artists: "mike.",
    duration_ms: 268565,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/419284c4b764fb938be6cff59c0bdc67db7d4270?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d924029ec452abb98e7a5612",
    danceability: 0.815,
    energy: 0.364,
    valence: 0.328,
    added_at: "2022-05-22T12:37:20Z",
    link: "https://open.spotify.com/track/5tsI3xxDHDgzHhn30LTQNz",
    userID: "royclaud",
    genres: "indie pop rap,rhode island rap",
  },
  {
    id: "4YZbVct8l9MnAVIROnLQdx",
    name: "Forrest Gump",
    artists: "Frank Ocean",
    duration_ms: 194840,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/3ad2d3669291b07237fba41998a0a9fc7ee02a52?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027aede4855f6d0d738012e2e5",
    danceability: 0.701,
    energy: 0.417,
    valence: 0.186,
    added_at: "2022-05-21T18:01:55Z",
    link: "https://open.spotify.com/track/4YZbVct8l9MnAVIROnLQdx",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "2pMPWE7PJH1PizfgGRMnR9",
    name: "Bad Religion",
    artists: "Frank Ocean",
    duration_ms: 175453,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/15cd6470fe16fa3123ad074262b5f87a7a5a884e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027aede4855f6d0d738012e2e5",
    danceability: 0.359,
    energy: 0.367,
    valence: 0.104,
    added_at: "2022-05-21T17:59:17Z",
    link: "https://open.spotify.com/track/2pMPWE7PJH1PizfgGRMnR9",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "7rbECVPkY5UODxoOUVKZnA",
    name: "I Wonder",
    artists: "Kanye West",
    duration_ms: 243440,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/22169e0212f8e77e0bb753c4d5cf1befae619997?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0226f7f19c7f0381e56156c94a",
    danceability: 0.542,
    energy: 0.466,
    valence: 0.127,
    added_at: "2022-05-19T21:18:10Z",
    link: "https://open.spotify.com/track/7rbECVPkY5UODxoOUVKZnA",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "3e0FzZjTXOUtiJGSClOBrI",
    name: "Fly Like An Eagle",
    artists: "Steve Miller Band",
    duration_ms: 282693,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/8bc426214d42a2578c461c77cbb222da6231a216?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022fc23e78e87d793054bba090",
    danceability: 0.561,
    energy: 0.754,
    valence: 0.642,
    added_at: "2022-05-19T18:33:23Z",
    link: "https://open.spotify.com/track/3e0FzZjTXOUtiJGSClOBrI",
    userID: "royclaud",
    genres: "album rock,classic rock,hard rock,heartland rock,mellow gold,rock,singer-songwriter,soft rock",
  },
  {
    id: "7cv28LXcjAC3GsXbUvXKbX",
    name: "I Will Survive - Single Version",
    artists: "Gloria Gaynor",
    duration_ms: 198066,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/dbb95f59bd69ddc2a024d2bec1478e20d3c5819c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a6479db910d22f5aa4546af1",
    danceability: 0.777,
    energy: 0.725,
    valence: 0.529,
    added_at: "2022-05-18T15:01:35Z",
    link: "https://open.spotify.com/track/7cv28LXcjAC3GsXbUvXKbX",
    userID: "royclaud",
    genres: "disco,soft rock",
  },
  {
    id: "4jPy3l0RUwlUI9T5XHBW2m",
    name: "Mood (feat. iann dior)",
    artists: "24kGoldn, iann dior",
    duration_ms: 140533,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/c425299d5996843fc24308b896f7ce60fd262c99?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0284c53fa832dfa265192419c5",
    danceability: 0.701,
    energy: 0.716,
    valence: 0.732,
    added_at: "2022-05-18T05:49:28Z",
    link: "https://open.spotify.com/track/4jPy3l0RUwlUI9T5XHBW2m",
    userID: "royclaud",
    genres: "cali rap,pop rap,melodic rap",
  },
  {
    id: "6f5ExP43esnvdKPddwKXJH",
    name: "Better Days (NEIKED x Mae Muller x Polo G)",
    artists: "NEIKED, Mae Muller, Polo G",
    duration_ms: 160656,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/d849e3ad098853384efd6562ea61fdde25519e78?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026b742298f7f36717855c4caf",
    danceability: 0.717,
    energy: 0.671,
    valence: 0.699,
    added_at: "2022-05-18T05:49:20Z",
    link: "https://open.spotify.com/track/6f5ExP43esnvdKPddwKXJH",
    userID: "royclaud",
    genres: "scandipop,uk contemporary r&b,uk pop,chicago rap,rap",
  },
  {
    id: "6kYGOFNUMxpab09L5Ukoke",
    name: "Rich Spirit",
    artists: "Kendrick Lamar",
    duration_ms: 202285,
    popularity: 20,
    preview_url: "https://p.scdn.co/mp3-preview/69cc0da19e45393d6b78aaaaae2a147ee04daf98?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f30764d5fa1e439ad6a7742f",
    danceability: 0.852,
    energy: 0.421,
    valence: 0.457,
    added_at: "2022-05-17T20:37:09Z",
    link: "https://open.spotify.com/track/6kYGOFNUMxpab09L5Ukoke",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "1j6kDJttn6wbVyMaM42Nxm",
    name: "Lord Pretty Flacko Jodye 2 (LPFJ2)",
    artists: "A$AP Rocky",
    duration_ms: 126960,
    popularity: 74,
    preview_url: "https://p.scdn.co/mp3-preview/246ee8e467fba5701f3b15a81a69194079b41f2a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0281a6a2cafab2e7a4066b5eec",
    danceability: 0.485,
    energy: 0.72,
    valence: 0.0471,
    added_at: "2022-05-11T20:57:27Z",
    link: "https://open.spotify.com/track/1j6kDJttn6wbVyMaM42Nxm",
    userID: "royclaud",
    genres: "east coast hip hop,hip hop,rap",
  },
  {
    id: "3o6d1KG4HJGWNCP08frPM8",
    name: "Colors and Shapes",
    artists: "Mac Miller",
    duration_ms: 331104,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/59480b73edf9728700e9f4a1e2d75e6430e49ff5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e4251cf5331ad74eaaeca36e",
    danceability: 0.645,
    energy: 0.458,
    valence: 0.397,
    added_at: "2022-05-09T00:00:18Z",
    link: "https://open.spotify.com/track/3o6d1KG4HJGWNCP08frPM8",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "23SZWX2IaDnxmhFsSLvkG2",
    name: "Gorgeous",
    artists: "Kanye West, Kid Cudi, Raekwon",
    duration_ms: 357653,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/60874f83016bc0c3cf4f71957c14c0cc8e711fc5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d9194aa18fa4c9362b47464f",
    danceability: 0.598,
    energy: 0.713,
    valence: 0.118,
    added_at: "2022-05-08T16:25:24Z",
    link: "https://open.spotify.com/track/23SZWX2IaDnxmhFsSLvkG2",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap,hip hop,ohio hip hop,pop rap,rap,east coast hip hop,hardcore hip hop",
  },
  {
    id: "3nAq2hCr1oWsIU54tS98pL",
    name: "Waves",
    artists: "Kanye West",
    duration_ms: 181573,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/0a3b5eb609d1fd54ee750d44b6830c1fbf38f3d6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022a7db835b912dc5014bd37f4",
    danceability: 0.557,
    energy: 0.586,
    valence: 0.0681,
    added_at: "2022-05-08T16:22:07Z",
    link: "https://open.spotify.com/track/3nAq2hCr1oWsIU54tS98pL",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "2BUP8KOWDlCsdEV8DJPQQn",
    name: "Keep It Light",
    artists: "Jack Harlow",
    duration_ms: 203500,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/200b34366932d5dde10aacda0cb6e93ef5585e88?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02aeb14ead136118a987246b63",
    danceability: 0.663,
    energy: 0.645,
    valence: 0.587,
    added_at: "2022-05-08T02:23:38Z",
    link: "https://open.spotify.com/track/2BUP8KOWDlCsdEV8DJPQQn",
    userID: "royclaud",
    genres: "deep underground hip hop,hip hop,kentucky hip hop,pop rap,rap",
  },
  {
    id: "2fw0DgUc8GTNxZ2BAv9Fih",
    name: "Long Kiss Goodnight - 2014 Remaster",
    artists: "The Notorious B.I.G.",
    duration_ms: 318466,
    popularity: 45,
    preview_url: "https://p.scdn.co/mp3-preview/41acba808d276cf116bb987f63ca5d59d4df27e4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fde79b88e2a659c394c5ae30",
    danceability: 0.479,
    energy: 0.892,
    valence: 0.348,
    added_at: "2022-05-07T06:32:57Z",
    link: "https://open.spotify.com/track/2fw0DgUc8GTNxZ2BAv9Fih",
    userID: "royclaud",
    genres: "east coast hip hop,gangster rap,hardcore hip hop,hip hop,rap",
  },
  {
    id: "0PyJRNmJvJJ5OmffwQf5we",
    name: "State Fair",
    artists: "Jack Harlow",
    duration_ms: 194526,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/7628fabab6c8150be4e323e732a8271aaefe4002?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028e55edb69ca44a25b52b17bb",
    danceability: 0.846,
    energy: 0.603,
    valence: 0.598,
    added_at: "2022-05-07T02:26:53Z",
    link: "https://open.spotify.com/track/0PyJRNmJvJJ5OmffwQf5we",
    userID: "royclaud",
    genres: "deep underground hip hop,hip hop,kentucky hip hop,pop rap,rap",
  },
  {
    id: "1hs54MGy1J69I52GvzsrbB",
    name: "Poison (feat. Lil Wayne)",
    artists: "Jack Harlow, Lil Wayne",
    duration_ms: 222642,
    popularity: 47,
    preview_url: "https://p.scdn.co/mp3-preview/904bfd1bcfea1f1891e30c6a32799e0aefef60e1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028e55edb69ca44a25b52b17bb",
    danceability: 0.549,
    energy: 0.485,
    valence: 0.626,
    added_at: "2022-05-07T02:21:14Z",
    link: "https://open.spotify.com/track/1hs54MGy1J69I52GvzsrbB",
    userID: "royclaud",
    genres: "deep underground hip hop,hip hop,kentucky hip hop,pop rap,rap,hip hop,new orleans rap,pop rap,rap,trap",
  },
  {
    id: "5mSVnabiC2NDVIGfW3Znc4",
    name: "Parent Trap (feat. Justin Timberlake)",
    artists: "Jack Harlow, Justin Timberlake",
    duration_ms: 189876,
    popularity: 45,
    preview_url: "https://p.scdn.co/mp3-preview/a215d6e15501d52e5c44add304598299396f9e6d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028e55edb69ca44a25b52b17bb",
    danceability: 0.878,
    energy: 0.523,
    valence: 0.422,
    added_at: "2022-05-07T02:17:50Z",
    link: "https://open.spotify.com/track/5mSVnabiC2NDVIGfW3Znc4",
    userID: "royclaud",
    genres: "deep underground hip hop,hip hop,kentucky hip hop,pop rap,rap,dance pop,pop",
  },
  {
    id: "5hNsSrWcClSp0tg4Jdd4UY",
    name: "I Got A Shot",
    artists: "Jack Harlow",
    duration_ms: 138781,
    popularity: 53,
    preview_url: "https://p.scdn.co/mp3-preview/6b8acb053fa5f9c0fad21db461be2c7913ce90cf?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028e55edb69ca44a25b52b17bb",
    danceability: 0.973,
    energy: 0.433,
    valence: 0.933,
    added_at: "2022-05-07T02:11:55Z",
    link: "https://open.spotify.com/track/5hNsSrWcClSp0tg4Jdd4UY",
    userID: "royclaud",
    genres: "deep underground hip hop,hip hop,kentucky hip hop,pop rap,rap",
  },
  {
    id: "6TT7B4MigCJCc0tqKYEpZC",
    name: "Moves",
    artists: "Suki Waterhouse",
    duration_ms: 193031,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/93e1b6bfd497cec0ef9c570b3f2e949d690d7278?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02499abd71f3d723ab3d3e0bfa",
    danceability: 0.574,
    energy: 0.44,
    valence: 0.365,
    added_at: "2022-05-06T17:51:16Z",
    link: "https://open.spotify.com/track/6TT7B4MigCJCc0tqKYEpZC",
    userID: "royclaud",
    genres: "indie pop",
  },
  {
    id: "3u8bblcJtOxxmGzJrviWLp",
    name: "Movie Star (feat. Pharrell Williams)",
    artists: "Jack Harlow, Pharrell Williams",
    duration_ms: 142590,
    popularity: 48,
    preview_url: "https://p.scdn.co/mp3-preview/b72e1528cc29bc44f9d9ae5bed00cc10f165b5ee?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028e55edb69ca44a25b52b17bb",
    danceability: 0.829,
    energy: 0.488,
    valence: 0.551,
    added_at: "2022-05-06T05:10:01Z",
    link: "https://open.spotify.com/track/3u8bblcJtOxxmGzJrviWLp",
    userID: "royclaud",
    genres: "deep underground hip hop,hip hop,kentucky hip hop,pop rap,rap,dance pop,pop",
  },
  {
    id: "3k3NWokhRRkEPhCzPmV8TW",
    name: "Ojitos Lindos",
    artists: "Bad Bunny, Bomba Estéreo",
    duration_ms: 258298,
    popularity: 82,
    preview_url: "https://p.scdn.co/mp3-preview/d9e32879becf10d4b28d66b55033bc91803d95f7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0249d694203245f241a1bcaa72",
    danceability: 0.647,
    energy: 0.686,
    valence: 0.268,
    added_at: "2022-05-06T04:37:57Z",
    link: "https://open.spotify.com/track/3k3NWokhRRkEPhCzPmV8TW",
    userID: "royclaud",
    genres: "reggaeton,trap latino,urbano latino,cumbia,latin alternative,latin rock,pop electronico,tropical alternativo",
  },
  {
    id: "5XcZRgJv3zMhTqCyESjQrF",
    name: "Poison",
    artists: "Alice Cooper",
    duration_ms: 270080,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/2a0faa69cc2b5a8b4ce4bdae3fe2fe7ba603330a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025d149c05419231f335e35bb7",
    danceability: 0.28,
    energy: 0.908,
    valence: 0.26,
    added_at: "2022-05-06T04:18:23Z",
    link: "https://open.spotify.com/track/5XcZRgJv3zMhTqCyESjQrF",
    userID: "royclaud",
    genres: "album rock,classic rock,detroit rock,glam metal,glam rock,hard rock,metal,protopunk,rock",
  },
  {
    id: "62Yo3FDddWY8ydu6PW2wyz",
    name: "Nail Tech",
    artists: "Jack Harlow",
    duration_ms: 206385,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/080cf575e59c6c309003e83eb88cef789ab0e70c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ddd85c9c585e663fc5431c2a",
    danceability: 0.677,
    energy: 0.557,
    valence: 0.1,
    added_at: "2022-05-01T22:13:25Z",
    link: "https://open.spotify.com/track/62Yo3FDddWY8ydu6PW2wyz",
    userID: "royclaud",
    genres: "deep underground hip hop,hip hop,kentucky hip hop,pop rap,rap",
  },
  {
    id: "1viUV5EGlKGCiSveBfFeQg",
    name: "I Won",
    artists: "Ty Dolla $ign, Jack Harlow, 24kGoldn",
    duration_ms: 175639,
    popularity: 44,
    preview_url: "https://p.scdn.co/mp3-preview/eb324c8e4ec78429c460b99beb4d75b05eb25f00?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fb9baf4194eda393b587c75d",
    danceability: 0.762,
    energy: 0.788,
    valence: 0.672,
    added_at: "2022-05-01T22:13:06Z",
    link: "https://open.spotify.com/track/1viUV5EGlKGCiSveBfFeQg",
    userID: "royclaud",
    genres: "hip hop,pop rap,r&b,southern hip hop,trap,trap soul,deep underground hip hop,hip hop,kentucky hip hop,pop rap,rap,cali rap,pop rap",
  },
  {
    id: "4LRPiXqCikLlN15c3yImP7",
    name: "As It Was",
    artists: "Harry Styles",
    duration_ms: 167303,
    popularity: 30,
    preview_url: "https://p.scdn.co/mp3-preview/c43dd07043b29e800c1a65b3a0102861fa3cf418?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b46f74097655d7f353caab14",
    danceability: 0.52,
    energy: 0.731,
    valence: 0.662,
    added_at: "2022-04-30T16:07:02Z",
    link: "https://open.spotify.com/track/4LRPiXqCikLlN15c3yImP7",
    userID: "royclaud",
    genres: "pop",
  },
  {
    id: "2hwOoMtWPtTSSn6WHV7Vp5",
    name: "Blue World",
    artists: "Mac Miller",
    duration_ms: 209440,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/6029a4927696bc51890533f79ed9c54194584388?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0226b7dd89810cc1a40ada642c",
    danceability: 0.742,
    energy: 0.553,
    valence: 0.508,
    added_at: "2022-04-27T14:00:09Z",
    link: "https://open.spotify.com/track/2hwOoMtWPtTSSn6WHV7Vp5",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "3X6YJcqTinaNraRvojF62K",
    name: "Z4L (with Bari & Jay2)",
    artists: "Smino, Young Pink, Jay2",
    duration_ms: 162590,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/be49ce0cad9be98d4c586c92f5870c7ac82be12b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02418c202dda058a337ddb0f92",
    danceability: 0.822,
    energy: 0.503,
    valence: 0.408,
    added_at: "2022-04-22T02:21:12Z",
    link: "https://open.spotify.com/track/3X6YJcqTinaNraRvojF62K",
    userID: "royclaud",
    genres: "alternative r&b,indie hip hop",
  },
  {
    id: "2BstRQGodshjGpeDGQiNgo",
    name: "Do It Again",
    artists: "Steely Dan",
    duration_ms: 356733,
    popularity: 2,
    preview_url: "https://p.scdn.co/mp3-preview/130e24ed87404b70023239e950a17d58278c941d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025a9b9e265814a9c9636a71a4",
    danceability: 0.682,
    energy: 0.537,
    valence: 0.963,
    added_at: "2022-04-21T21:53:43Z",
    link: "https://open.spotify.com/track/2BstRQGodshjGpeDGQiNgo",
    userID: "royclaud",
    genres: "album rock,classic rock,country rock,folk rock,mellow gold,rock,soft rock,yacht rock",
  },
  {
    id: "7m9OqQk4RVRkw9JJdeAw96",
    name: "Jocelyn Flores",
    artists: "XXXTENTACION",
    duration_ms: 119133,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/df466c4bb30333c2ee904e7c876920e418d41732?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02203c89bd4391468eea4cc3f5",
    danceability: 0.872,
    energy: 0.391,
    valence: 0.437,
    added_at: "2022-04-21T21:39:55Z",
    link: "https://open.spotify.com/track/7m9OqQk4RVRkw9JJdeAw96",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "7eqoqGkKwgOaWNNHx90uEZ",
    name: "Nights",
    artists: "Frank Ocean",
    duration_ms: 307151,
    popularity: 79,
    preview_url: "https://p.scdn.co/mp3-preview/478a51ff059dc1abb20548ac2f3d3d73d75c3cb7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c5649add07ed3720be9d5526",
    danceability: 0.457,
    energy: 0.551,
    valence: 0.428,
    added_at: "2022-04-21T21:39:44Z",
    link: "https://open.spotify.com/track/7eqoqGkKwgOaWNNHx90uEZ",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "0xdYzkbBBfTevSyOJqWUSX",
    name: "Sky's the Limit (feat. 112) - 2014 Remaster",
    artists: "The Notorious B.I.G., 112",
    duration_ms: 329426,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/5eabbcaee521d7f69747a61f696a4e306eb700b0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fde79b88e2a659c394c5ae30",
    danceability: 0.637,
    energy: 0.66,
    valence: 0.503,
    added_at: "2022-04-21T17:28:35Z",
    link: "https://open.spotify.com/track/0xdYzkbBBfTevSyOJqWUSX",
    userID: "royclaud",
    genres: "east coast hip hop,gangster rap,hardcore hip hop,hip hop,rap,atl hip hop,boy band,contemporary r&b,hip pop,r&b,urban contemporary",
  },
  {
    id: "4XizBlyqR7ZGVTX0Fyonm2",
    name: "Today - 2011 Remaster",
    artists: "The Smashing Pumpkins",
    duration_ms: 201173,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/4de893b84b4ec054bb0f8034b2760c46a141e5ec?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022d6d46ea0c000c11083f2158",
    danceability: 0.456,
    energy: 0.826,
    valence: 0.415,
    added_at: "2022-04-21T17:28:25Z",
    link: "https://open.spotify.com/track/4XizBlyqR7ZGVTX0Fyonm2",
    userID: "royclaud",
    genres: "alternative metal,alternative rock,grunge,permanent wave,rock,spacegrunge",
  },
  {
    id: "2M9okXXAS96uYT7qNjyMNM",
    name: "Lets Drive to Vegas",
    artists: "Larry June",
    duration_ms: 171000,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/8d4f1a4673e921683d2ac129ad79d63fb8f91528?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0267ba62a329fa39d2693697db",
    danceability: 0.499,
    energy: 0.715,
    valence: 0.462,
    added_at: "2022-04-21T17:28:21Z",
    link: "https://open.spotify.com/track/2M9okXXAS96uYT7qNjyMNM",
    userID: "royclaud",
    genres: "cali rap",
  },
  {
    id: "1ohxmwWvLNWIlt0kUo8kZF",
    name: "Oshun (with EARTHGANG & 6LACK feat. Jurdan Bryant)",
    artists: "Spillage Village, EARTHGANG, 6LACK, Jurdan Bryant",
    duration_ms: 242099,
    popularity: 40,
    preview_url: "https://p.scdn.co/mp3-preview/ed68ba1c55cef2100988d4dbe61d932ca948515e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02230d88bf27d6ca322fb59eb4",
    danceability: 0.792,
    energy: 0.511,
    valence: 0.552,
    added_at: "2022-04-11T15:09:56Z",
    link: "https://open.spotify.com/track/1ohxmwWvLNWIlt0kUo8kZF",
    userID: "royclaud",
    genres: "underground hip hop,atl hip hop,indie hip hop,psychedelic hip hop,rap,underground hip hop,atl hip hop,melodic rap,r&b,rap,trap,indie hip hop",
  },
  {
    id: "4aNfb0hz1CWy3B0X2jgECn",
    name: "Intuition",
    artists: "Sophia Rayne",
    duration_ms: 183142,
    popularity: 43,
    preview_url: "https://p.scdn.co/mp3-preview/c6d458e03cfa903ade70b2345bd506141cdd972b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ed778b710c0c81fa38db6d1b",
    danceability: 0.611,
    energy: 0.267,
    valence: 0.408,
    added_at: "2022-04-09T20:13:57Z",
    link: "https://open.spotify.com/track/4aNfb0hz1CWy3B0X2jgECn",
    userID: "royclaud",
    genres: "high vibe",
  },
  {
    id: "0s1aSsYlLIEiy16LjFWbdp",
    name: "Dirty Work",
    artists: "Steely Dan",
    duration_ms: 187400,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/a7276baa6d5b6e9c3bb0f8004c27ebe6e0604386?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025a9b9e265814a9c9636a71a4",
    danceability: 0.631,
    energy: 0.354,
    valence: 0.522,
    added_at: "2022-04-09T19:44:17Z",
    link: "https://open.spotify.com/track/0s1aSsYlLIEiy16LjFWbdp",
    userID: "royclaud",
    genres: "album rock,classic rock,country rock,folk rock,mellow gold,rock,soft rock,yacht rock",
  },
  {
    id: "0NrtwAmRAdLxua31SzHvXr",
    name: "Everything I Am",
    artists: "Kanye West, DJ Premier",
    duration_ms: 227893,
    popularity: 73,
    preview_url: "https://p.scdn.co/mp3-preview/cd7c85a242b759c26cce4eb6f81a3c29c5038482?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0226f7f19c7f0381e56156c94a",
    danceability: 0.631,
    energy: 0.623,
    valence: 0.765,
    added_at: "2022-04-06T22:48:34Z",
    link: "https://open.spotify.com/track/0NrtwAmRAdLxua31SzHvXr",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap,east coast hip hop,instrumental hip hop,jazz boom bap,turntablism",
  },
  {
    id: "0ofHAoxe9vBkTCp2UQIavz",
    name: "Dreams - 2004 Remaster",
    artists: "Fleetwood Mac",
    duration_ms: 257800,
    popularity: 85,
    preview_url: "https://p.scdn.co/mp3-preview/30d63954de3ee9c0bc3600a4560260cb252d4fbd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e52a59a28efa4773dd2bfe1b",
    danceability: 0.828,
    energy: 0.492,
    valence: 0.789,
    added_at: "2022-04-06T14:04:02Z",
    link: "https://open.spotify.com/track/0ofHAoxe9vBkTCp2UQIavz",
    userID: "royclaud",
    genres: "album rock,classic rock,rock,soft rock,yacht rock",
  },
  {
    id: "2ZeQp8pc5sKyjLPBhn3DZj",
    name: "Grow - A COLORS ENCORE",
    artists: "FACESOUL",
    duration_ms: 207498,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/d948544594eaf532480b93f39c388d8e51f51e39?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029e5a358edfeb737f420b3776",
    danceability: 0.551,
    energy: 0.0258,
    valence: 0.0775,
    added_at: "2022-04-01T00:10:15Z",
    link: "https://open.spotify.com/track/2ZeQp8pc5sKyjLPBhn3DZj",
    userID: "royclaud",
    genres: "high vibe",
  },
  {
    id: "4xb8JHIN56xixSl6vkSPjm",
    name: "Siren",
    artists: "Kailee Morgue",
    duration_ms: 200266,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/5a992bf1e41944372da7d9943e904475bf9b2676?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027b0571f90fc7aae21b833571",
    danceability: 0.603,
    energy: 0.512,
    valence: 0.271,
    added_at: "2022-03-29T20:53:16Z",
    link: "https://open.spotify.com/track/4xb8JHIN56xixSl6vkSPjm",
    userID: "royclaud",
    genres: "alt z,dark pop",
  },
  {
    id: "4eYBU6KQTotMrr0QbB7OLI",
    name: "Witchcraft",
    artists: "Vian Izak",
    duration_ms: 109552,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/922e1197251379bc83554dc03fbf40892871c3ab?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a6b130da31e06fbd665f5855",
    danceability: 0.424,
    energy: 0.169,
    valence: 0.205,
    added_at: "2022-03-28T18:07:17Z",
    link: "https://open.spotify.com/track/4eYBU6KQTotMrr0QbB7OLI",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "2wG9CUSBZaF15R2ohLAnoe",
    name: "Juice",
    artists: "iyla",
    duration_ms: 208024,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/3cbac7317f6c5803ae70357bc0371a0703bc67be?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02436ad3ad4d81d4e4edb41c42",
    danceability: 0.744,
    energy: 0.428,
    valence: 0.378,
    added_at: "2022-03-28T18:04:14Z",
    link: "https://open.spotify.com/track/2wG9CUSBZaF15R2ohLAnoe",
    userID: "royclaud",
    genres: "alternative r&b,pop soul",
  },
  {
    id: "7K1zPTdKr4LjkAK1l35EGA",
    name: "Caught A Body",
    artists: "Iniko",
    duration_ms: 192374,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/68f2085cc61026981cc49caa4339f76f1b35b70a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a16094f9b321ad95b5181693",
    danceability: 0.636,
    energy: 0.57,
    valence: 0.127,
    added_at: "2022-03-26T20:07:57Z",
    link: "https://open.spotify.com/track/7K1zPTdKr4LjkAK1l35EGA",
    userID: "royclaud",
    genres: "high vibe",
  },
  {
    id: "2vwvGMStv1nEuoOTd4xgMu",
    name: "Tiimmy Turner - Remix",
    artists: "Desiigner, Kanye West",
    duration_ms: 141613,
    popularity: 47,
    preview_url: "https://p.scdn.co/mp3-preview/ec45319be4f5960bc755b33682e37081425fbe81?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021061b6188cc2c2c703fa3fe8",
    danceability: 0.792,
    energy: 0.451,
    valence: 0.365,
    added_at: "2022-03-21T15:27:38Z",
    link: "https://open.spotify.com/track/2vwvGMStv1nEuoOTd4xgMu",
    userID: "royclaud",
    genres: "pop rap,rap,southern hip hop,trap,viral trap,chicago rap,hip hop,rap",
  },
  {
    id: "5AE9DzJr8jhTFgcbkCvrPq",
    name: "SAME HANDS (feat. Lil Durk)",
    artists: "BIA, Lil Durk",
    duration_ms: 206400,
    popularity: 35,
    preview_url: "https://p.scdn.co/mp3-preview/a0aa10b2e61aad84d1a6af2ec3e5eead222ce890?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02519992354b6dd38590baec10",
    danceability: 0.88,
    energy: 0.61,
    valence: 0.32,
    added_at: "2022-03-17T19:02:53Z",
    link: "https://open.spotify.com/track/5AE9DzJr8jhTFgcbkCvrPq",
    userID: "royclaud",
    genres: "rap latina,trap queen,chicago drill,chicago rap,drill,hip hop,pop rap,rap,trap",
  },
  {
    id: "1DoS5ql0TW3ejjJGHbsAmz",
    name: "Tadow",
    artists: "Masego, FKJ",
    duration_ms: 301639,
    popularity: 2,
    preview_url: "https://p.scdn.co/mp3-preview/a485ad4d45c8ad76e2722e25f66590de6cd1d4cf?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02383ee7319d30024145173544",
    danceability: 0.719,
    energy: 0.487,
    valence: 0.345,
    added_at: "2022-02-10T00:09:22Z",
    link: "https://open.spotify.com/track/1DoS5ql0TW3ejjJGHbsAmz",
    userID: "royclaud",
    genres: "alternative r&b,indie soul,filter house,indie soul",
  },
  {
    id: "6tqIes4TjudsjfIpK4P2Jh",
    name: "Long Beach",
    artists: "Kota the Friend, Hello O'shay, Alex Banin",
    duration_ms: 252778,
    popularity: 54,
    preview_url: "https://p.scdn.co/mp3-preview/d9b7a8527e39449d584c4d8eca139313dbadd9c8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0227298ef27ce2c39f8177d1af",
    danceability: 0.809,
    energy: 0.613,
    valence: 0.562,
    added_at: "2022-02-10T00:03:26Z",
    link: "https://open.spotify.com/track/6tqIes4TjudsjfIpK4P2Jh",
    userID: "royclaud",
    genres: "underground hip hop",
  },
  {
    id: "0gEcmyKlIUoi3sHTFVO1bE",
    name: "I Was Sad Last Night I'm OK Now",
    artists: "tobi lou",
    duration_ms: 190560,
    popularity: 50,
    preview_url: "https://p.scdn.co/mp3-preview/8471a253224b43a45ea0b4201673881fc6ccc424?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024f68236f949491574f670590",
    danceability: 0.87,
    energy: 0.637,
    valence: 0.78,
    added_at: "2022-02-09T21:09:26Z",
    link: "https://open.spotify.com/track/0gEcmyKlIUoi3sHTFVO1bE",
    userID: "royclaud",
    genres: "chicago rap,indie hip hop,indie r&b",
  },
  {
    id: "6BczFLHW9aIf9aWn5NFuNL",
    name: "Don't Play That",
    artists: "King Von, 21 Savage",
    duration_ms: 133613,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/47a721ebff5229b640f93de4757b2b5ce627d9a6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022e00657fb57e03072174067b",
    danceability: 0.947,
    energy: 0.605,
    valence: 0.656,
    added_at: "2022-02-04T18:26:06Z",
    link: "https://open.spotify.com/track/6BczFLHW9aIf9aWn5NFuNL",
    userID: "royclaud",
    genres: "chicago rap,rap,atl hip hop,hip hop,rap",
  },
  {
    id: "42GcjriRK6srwHkfbkBqVl",
    name: "Blood On The Leaves",
    artists: "Kanye West",
    duration_ms: 359800,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/172563aa487e421720be37b6b84e69a349e3a7d9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021dacfbc31cc873d132958af9",
    danceability: 0.397,
    energy: 0.69,
    valence: 0.36,
    added_at: "2022-01-20T13:18:30Z",
    link: "https://open.spotify.com/track/42GcjriRK6srwHkfbkBqVl",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "2gQPv5jvVPqU2a9HhMNO1v",
    name: "Toxic",
    artists: "BoyWithUke",
    duration_ms: 168020,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/722b7b8d26bdc5dffc530ba9105825d0c3faf4a4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0282a18aa0d3374049c6b78f6a",
    danceability: 0.594,
    energy: 0.612,
    valence: 0.687,
    added_at: "2022-01-18T21:58:23Z",
    link: "https://open.spotify.com/track/2gQPv5jvVPqU2a9HhMNO1v",
    userID: "royclaud",
    genres: "sad rap",
  },
  {
    id: "0IajnzlicUA89rZSkqfLPS",
    name: "She Wants My Money",
    artists: "Dominic Fike",
    duration_ms: 133560,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/a3ee0bc1ccbb57a5fee9df287194c57010c60cf8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027b1b6f41c1645af9757d5616",
    danceability: 0.704,
    energy: 0.478,
    valence: 0.42,
    added_at: "2022-01-18T21:44:54Z",
    link: "https://open.spotify.com/track/0IajnzlicUA89rZSkqfLPS",
    userID: "royclaud",
    genres: "alternative pop rock,pov: indie",
  },
  {
    id: "6PwDTjmJXS2uMZDrjRZAXK",
    name: "Jet",
    artists: "Citizen",
    duration_ms: 223784,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/4c395b6998b4638b2c19d34b56cd43baaa0fffc3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0293341fcb50748b7a69df5e3c",
    danceability: 0.625,
    energy: 0.858,
    valence: 0.719,
    added_at: "2022-01-16T00:08:54Z",
    link: "https://open.spotify.com/track/6PwDTjmJXS2uMZDrjRZAXK",
    userID: "royclaud",
    genres: "alternative emo,dreamo,emo,pop punk",
  },
  {
    id: "0wBVIJrD6tvVnBIQTEQpBt",
    name: "Gauze",
    artists: "Gleemer",
    duration_ms: 297501,
    popularity: 40,
    preview_url: "https://p.scdn.co/mp3-preview/ea4c65a5f68652dd5a73263a4e62eb82785c1d8e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025fd7a2720ee2a46195c2d7eb",
    danceability: 0.567,
    energy: 0.539,
    valence: 0.372,
    added_at: "2022-01-16T00:08:50Z",
    link: "https://open.spotify.com/track/0wBVIJrD6tvVnBIQTEQpBt",
    userID: "royclaud",
    genres: "5th wave emo,american shoegaze,dreamo,grungegaze",
  },
  {
    id: "7yYZgtCupYfTpnUQBmXsIf",
    name: "Peaches",
    artists: "Vacations",
    duration_ms: 240074,
    popularity: 29,
    preview_url: "https://p.scdn.co/mp3-preview/f3a4c5d40010caf9d7ee48544333376b5cdbf89d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023e4874a1d60cf1eb18aebe66",
    danceability: 0.631,
    energy: 0.645,
    valence: 0.37,
    added_at: "2022-01-16T00:08:05Z",
    link: "https://open.spotify.com/track/7yYZgtCupYfTpnUQBmXsIf",
    userID: "royclaud",
    genres: "newcastle nsw indie,pov: indie",
  },
  {
    id: "3YU9X8ryOR20beT7wOlDIJ",
    name: "I'm Eighteen",
    artists: "Alice Cooper",
    duration_ms: 180760,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/32dd55521b6938a6c097acbecc288beadff8d892?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a686891e44bcadacb68ee3de",
    danceability: 0.618,
    energy: 0.413,
    valence: 0.531,
    added_at: "2022-01-16T00:07:41Z",
    link: "https://open.spotify.com/track/3YU9X8ryOR20beT7wOlDIJ",
    userID: "royclaud",
    genres: "album rock,classic rock,detroit rock,glam metal,glam rock,hard rock,metal,protopunk,rock",
  },
  {
    id: "4pBfU4RSvH0NzjEr2OgjWn",
    name: "Understanding in a Car Crash",
    artists: "Thursday",
    duration_ms: 264546,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/82bb38f24a4f7ee20938b7320070057f7a2de34c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025b0e33f7eda72733568457a0",
    danceability: 0.4,
    energy: 0.907,
    valence: 0.413,
    added_at: "2022-01-16T00:07:20Z",
    link: "https://open.spotify.com/track/4pBfU4RSvH0NzjEr2OgjWn",
    userID: "royclaud",
    genres: "emo,new jersey hardcore,pop punk,post-hardcore,screamo",
  },
  {
    id: "6r7JRfsZHTz9HTjGXAO385",
    name: "I'll Be Your Host",
    artists: "Touché Amoré",
    duration_ms: 207459,
    popularity: 10,
    preview_url: "https://p.scdn.co/mp3-preview/37839b016b1b9a8e03c0c57e004be1c369ad9424?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0220a95958db940740605e32bb",
    danceability: 0.376,
    energy: 0.914,
    valence: 0.196,
    added_at: "2022-01-13T13:47:37Z",
    link: "https://open.spotify.com/track/6r7JRfsZHTz9HTjGXAO385",
    userID: "royclaud",
    genres: "alternative emo,chaotic hardcore,dreamo,emo,melodic hardcore,modern melodic hardcore,post-hardcore",
  },
  {
    id: "249E7AgSyA4vhtXNEjQYb5",
    name: "Wicked Games",
    artists: "The Weeknd",
    duration_ms: 323733,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/b8d2bdeeb8fb2b7bd104a3bbb49dd4fd006ef634?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b2a59ce36f34f9e0957d4eb1",
    danceability: 0.659,
    energy: 0.606,
    valence: 0.325,
    added_at: "2022-01-09T00:47:30Z",
    link: "https://open.spotify.com/track/249E7AgSyA4vhtXNEjQYb5",
    userID: "royclaud",
    genres: "canadian contemporary r&b,canadian pop,pop",
  },
  {
    id: "2Y0wPrPQBrGhoLn14xRYCG",
    name: "Come & Go (with Marshmello)",
    artists: "Juice WRLD, Marshmello",
    duration_ms: 205484,
    popularity: 74,
    preview_url: "https://p.scdn.co/mp3-preview/56bf05555aca85738ac99236d8fd58a936c47e36?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023e0698e4ae5ffb82a005aeeb",
    danceability: 0.625,
    energy: 0.814,
    valence: 0.535,
    added_at: "2021-12-30T13:31:54Z",
    link: "https://open.spotify.com/track/2Y0wPrPQBrGhoLn14xRYCG",
    userID: "royclaud",
    genres: "chicago rap,melodic rap,rap,brostep,edm,pop,progressive electro house",
  },
  {
    id: "4AO1XhrgJczQ9bNVxdfKQe",
    name: "WHAT TO DO? (feat. Don Toliver)",
    artists: "JACKBOYS, Travis Scott, Don Toliver",
    duration_ms: 250141,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/46d9f66dde2b0d6f1b6bbeb3b8004de6d51795e9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02dfc2f59568272de50a257f2f",
    danceability: 0.643,
    energy: 0.594,
    valence: 0.195,
    added_at: "2021-12-14T01:43:40Z",
    link: "https://open.spotify.com/track/4AO1XhrgJczQ9bNVxdfKQe",
    userID: "royclaud",
    genres: "pop rap,rap,slap house,rap",
  },
  {
    id: "0QwKxajohg1rAMMmv1AWe1",
    name: "Burn",
    artists: "Juice WRLD",
    duration_ms: 217222,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/6754950c4745ebaaa4b2bc0a2280178c75ee17b6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c820e86be3bcbc65e5b88ef0",
    danceability: 0.354,
    energy: 0.475,
    valence: 0.0393,
    added_at: "2021-12-11T01:23:58Z",
    link: "https://open.spotify.com/track/0QwKxajohg1rAMMmv1AWe1",
    userID: "royclaud",
    genres: "chicago rap,melodic rap,rap",
  },
  {
    id: "7oM9y6V3ObY10VVauGOLOo",
    name: "The Business",
    artists: "Tiësto",
    duration_ms: 164000,
    popularity: 36,
    preview_url: "https://p.scdn.co/mp3-preview/e516723e41dab08c241fc981aeed21407f322d72?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a147b2c67547fc30cec1ffc7",
    danceability: 0.798,
    energy: 0.62,
    valence: 0.235,
    added_at: "2021-12-10T20:47:19Z",
    link: "https://open.spotify.com/track/7oM9y6V3ObY10VVauGOLOo",
    userID: "royclaud",
    genres: "big room,brostep,dutch edm,edm,house,pop dance,progressive electro house,slap house,trance",
  },
  {
    id: "0WnLpQOHjfVwPw2zbrIsPN",
    name: "Choose",
    artists: "Lil Peep, Harry Fraud",
    duration_ms: 212280,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/c554099a75426a36c2a49510a4ea6127a48780b9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d3f4280fb9a18bc942a9d597",
    danceability: 0.385,
    energy: 0.567,
    valence: 0.32,
    added_at: "2021-12-03T14:50:06Z",
    link: "https://open.spotify.com/track/0WnLpQOHjfVwPw2zbrIsPN",
    userID: "royclaud",
    genres: "cloud rap,emo rap,lgbtq+ hip hop,boom bap,instrumental hip hop",
  },
  {
    id: "4zkouyxQq5FVoYrwFyhFff",
    name: "MyHeart,ItHurts (feat. Chsr)",
    artists: "BONES, chsr",
    duration_ms: 144048,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/ba62aedec6c4b392a6f03c665515faa80f60314a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a4d510a89d93fe4b9d87631f",
    danceability: 0.663,
    energy: 0.618,
    valence: 0.15,
    added_at: "2021-11-18T04:03:52Z",
    link: "https://open.spotify.com/track/4zkouyxQq5FVoYrwFyhFff",
    userID: "royclaud",
    genres: "cloud rap,dark trap",
  },
  {
    id: "5qC17VMNoZdF2gAw28FtsV",
    name: "Monday Loop",
    artists: "Tomppabeats",
    duration_ms: 91504,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/ebe5fd789ac2552ef69022439e3544de870c91a1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d5800f8720e1714bce93cad7",
    danceability: 0.742,
    energy: 0.384,
    valence: 0.354,
    added_at: "2021-11-12T17:50:27Z",
    link: "https://open.spotify.com/track/5qC17VMNoZdF2gAw28FtsV",
    userID: "royclaud",
    genres: "chillhop,japanese chillhop,lo-fi beats",
  },
  {
    id: "1W79dmbrVIEE78Nwnm9Fqy",
    name: "No Hay Mañana",
    artists: "Héctor Oaks, COCO-PALOMA",
    duration_ms: 236559,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/cdae431479f71995212f95d4a0f9b58d856531d0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0224d241302f4212e96f37de83",
    danceability: 0.655,
    energy: 0.834,
    valence: 0.386,
    added_at: "2021-10-28T19:34:38Z",
    link: "https://open.spotify.com/track/1W79dmbrVIEE78Nwnm9Fqy",
    userID: "royclaud",
    genres: "minimal dub,nu gabber",
  },
  {
    id: "2xUsXYIiK18wSnv2SXFGtj",
    name: "Invisible - Paul Kalkbrenner Remix",
    artists: "NTO, Paul Kalkbrenner",
    duration_ms: 457036,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/65068153245b8aeff058d9e2d30c83fce1209eaf?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ae98507273e5432097299a0d",
    danceability: 0.705,
    energy: 0.597,
    valence: 0.465,
    added_at: "2021-10-27T19:36:34Z",
    link: "https://open.spotify.com/track/2xUsXYIiK18wSnv2SXFGtj",
    userID: "royclaud",
    genres: "deep euro house,minimal melodic techno,new french touch,electronica,german techno,leipzig electronic,minimal techno",
  },
  {
    id: "6U0FIYXCQ3TGrk4tFpLrEA",
    name: "SUGAR",
    artists: "BROCKHAMPTON",
    duration_ms: 204533,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/ef4f27cb1fb8f10ffe933fc772a936f32de95181?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0246f07fa4f28bf824840ddacb",
    danceability: 0.453,
    energy: 0.538,
    valence: 0.516,
    added_at: "2021-10-15T20:40:56Z",
    link: "https://open.spotify.com/track/6U0FIYXCQ3TGrk4tFpLrEA",
    userID: "royclaud",
    genres: "boy band,rap",
  },
  {
    id: "4V3N5LvUOh3yDPP16cwAhE",
    name: "Aquemini",
    artists: "Outkast",
    duration_ms: 319240,
    popularity: 54,
    preview_url: "https://p.scdn.co/mp3-preview/70d525f4150aedea9a746a65b2a499a0f1b504ff?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02377a6d2051b23afea65ee41a",
    danceability: 0.707,
    energy: 0.661,
    valence: 0.157,
    added_at: "2021-10-14T22:55:18Z",
    link: "https://open.spotify.com/track/4V3N5LvUOh3yDPP16cwAhE",
    userID: "royclaud",
    genres: "atl hip hop,dirty south rap,hip hop,old school atlanta hip hop,rap,southern hip hop",
  },
  {
    id: "6EOKwO6WaLal58MSsi6U4W",
    name: "Eleanor Rigby",
    artists: "The Beatles",
    duration_ms: 125226,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/38a4f03854bd1c7b4421557524a98609375fd3a1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d807dd713cdfbeed142881e2",
    danceability: 0.521,
    energy: 0.301,
    valence: 0.621,
    added_at: "2021-10-08T18:26:44Z",
    link: "https://open.spotify.com/track/6EOKwO6WaLal58MSsi6U4W",
    userID: "royclaud",
    genres: "british invasion,classic rock,merseybeat,psychedelic rock,rock",
  },
  {
    id: "2fQrGHiQOvpL9UgPvtYy6G",
    name: "Bank Account",
    artists: "21 Savage",
    duration_ms: 220306,
    popularity: 14,
    preview_url: "https://p.scdn.co/mp3-preview/35e50885ea3c7a1fc34adc245d3600d9f2156bb6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e83b061a735d3851dc27f745",
    danceability: 0.884,
    energy: 0.347,
    valence: 0.376,
    added_at: "2021-09-30T22:37:35Z",
    link: "https://open.spotify.com/track/2fQrGHiQOvpL9UgPvtYy6G",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap",
  },
  {
    id: "6l8McJSn8BgCTTewysSusR",
    name: "Fingers Blue (feat. Travis Scott)",
    artists: "Smokepurpp, Travis Scott",
    duration_ms: 194771,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/58ad8e649a9dcee478cf3ca4cfbe52e6109c9b6c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0229c5837c324085ceb2bbc948",
    danceability: 0.879,
    energy: 0.882,
    valence: 0.837,
    added_at: "2021-09-29T17:12:45Z",
    link: "https://open.spotify.com/track/6l8McJSn8BgCTTewysSusR",
    userID: "royclaud",
    genres: "melodic rap,miami hip hop,trap,underground hip hop,rap,slap house",
  },
  {
    id: "1ARJhjuI6TNYZCxYygFQ4F",
    name: "Right Now (Na Na Na)",
    artists: "Akon",
    duration_ms: 240746,
    popularity: 2,
    preview_url: "https://p.scdn.co/mp3-preview/9afbca2abc9ae3b395de39d11635d975af9093a2?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029394f4d49f1598a13a580ebc",
    danceability: 0.832,
    energy: 0.87,
    valence: 0.566,
    added_at: "2021-09-27T18:11:23Z",
    link: "https://open.spotify.com/track/1ARJhjuI6TNYZCxYygFQ4F",
    userID: "royclaud",
    genres: "dance pop",
  },
  {
    id: "0RdUX4WE0fO30VnlUbDVL6",
    name: "Still Loving You",
    artists: "Scorpions",
    duration_ms: 403453,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/5d829ba6cb52bd503f451f21d19522494059768a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029f2023d391c3bd4acf4d51c6",
    danceability: 0.282,
    energy: 0.605,
    valence: 0.0783,
    added_at: "2021-09-27T17:58:07Z",
    link: "https://open.spotify.com/track/0RdUX4WE0fO30VnlUbDVL6",
    userID: "royclaud",
    genres: "album rock,german hard rock,german metal,german rock,hard rock,rock",
  },
  {
    id: "0dGe86ljF6Xi19IxYc7aBn",
    name: "2hrs",
    artists: "tobi lou",
    duration_ms: 64722,
    popularity: 53,
    preview_url: "https://p.scdn.co/mp3-preview/8a1a45d2e148a5d5421251b2861e930040436cdf?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026aa660dab5b95449d54c88dc",
    danceability: 0.844,
    energy: 0.424,
    valence: 0.434,
    added_at: "2021-09-27T17:48:07Z",
    link: "https://open.spotify.com/track/0dGe86ljF6Xi19IxYc7aBn",
    userID: "royclaud",
    genres: "chicago rap,indie hip hop,indie r&b",
  },
  {
    id: "3SEd8nPd8MpGwk6ZZ8tk2j",
    name: "Mamacita (feat. Rich Homie Quan & Young Thug)",
    artists: "Travis Scott, Rich Homie Quan, Young Thug",
    duration_ms: 274320,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/e3a954e55d60f969fd099d7657193474761a4276?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a53a4cb046216d7ac049b5bf",
    danceability: 0.823,
    energy: 0.768,
    valence: 0.216,
    added_at: "2021-09-25T02:49:51Z",
    link: "https://open.spotify.com/track/3SEd8nPd8MpGwk6ZZ8tk2j",
    userID: "royclaud",
    genres: "rap,slap house,atl hip hop,rap,southern hip hop,trap,atl hip hop,atl trap,gangster rap,hip hop,melodic rap,rap,trap",
  },
  {
    id: "50ceCGZ3oD3U5caQV5bP6f",
    name: "You Only Live Twice (with Lil Wayne & Rick Ross)",
    artists: "Drake, Lil Wayne, Rick Ross",
    duration_ms: 213103,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/abdd211fbc36d49d5ea72a122455fff3ca9041f7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02cd945b4e3de57edd28481a3f",
    danceability: 0.519,
    energy: 0.851,
    valence: 0.711,
    added_at: "2021-09-24T19:35:32Z",
    link: "https://open.spotify.com/track/50ceCGZ3oD3U5caQV5bP6f",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap,hip hop,new orleans rap,pop rap,rap,trap,dirty south rap,gangster rap,hip hop,rap,southern hip hop,trap",
  },
  {
    id: "1sSuFkO3m3Cr6YN24NYol5",
    name: "Are You That Somebody",
    artists: "Aaliyah",
    duration_ms: 265666,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/12ec6bd01076a1404520c70cb0595b0f6595d0f1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ec38cecba59cd397ce8a9846",
    danceability: 0.85,
    energy: 0.657,
    valence: 0.843,
    added_at: "2021-09-18T21:32:55Z",
    link: "https://open.spotify.com/track/1sSuFkO3m3Cr6YN24NYol5",
    userID: "royclaud",
    genres: "contemporary r&b,dance pop,hip pop,r&b,urban contemporary",
  },
  {
    id: "1ITpqT1kDATCtMBwOK7sYn",
    name: "vent",
    artists: "Baby Keem",
    duration_ms: 136572,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/e2364b72a659dcb9ce59f01f95406dcbc9c04f86?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021bfa23b13d0504fb90c37b39",
    danceability: 0.896,
    energy: 0.377,
    valence: 0.255,
    added_at: "2021-09-17T19:39:18Z",
    link: "https://open.spotify.com/track/1ITpqT1kDATCtMBwOK7sYn",
    userID: "royclaud",
    genres: "hip hop,rap",
  },
  {
    id: "7m7zZuRVyRsQunMbcTc4e6",
    name: "booman",
    artists: "Baby Keem",
    duration_ms: 155153,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/6c554a2357aeb37ce0f142a4df83923cf45ebf2d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021bfa23b13d0504fb90c37b39",
    danceability: 0.585,
    energy: 0.754,
    valence: 0.666,
    added_at: "2021-09-17T19:39:12Z",
    link: "https://open.spotify.com/track/7m7zZuRVyRsQunMbcTc4e6",
    userID: "royclaud",
    genres: "hip hop,rap",
  },
  {
    id: "7MmBhKBQnaa0I8TaDJs9ia",
    name: "issues",
    artists: "Baby Keem",
    duration_ms: 219813,
    popularity: 48,
    preview_url: "https://p.scdn.co/mp3-preview/678baad737bea3d8336c1b1ae7d54bb43fce0ba3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021bfa23b13d0504fb90c37b39",
    danceability: 0.833,
    energy: 0.542,
    valence: 0.237,
    added_at: "2021-09-17T18:54:45Z",
    link: "https://open.spotify.com/track/7MmBhKBQnaa0I8TaDJs9ia",
    userID: "royclaud",
    genres: "hip hop,rap",
  },
  {
    id: "0UTyLmkQgPOEIz95OVAJTR",
    name: "range brothers (with Kendrick Lamar)",
    artists: "Baby Keem, Kendrick Lamar",
    duration_ms: 316733,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/8088fc96887aa539b540147359f022da9a2834c0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021bfa23b13d0504fb90c37b39",
    danceability: 0.667,
    energy: 0.582,
    valence: 0.141,
    added_at: "2021-09-17T18:53:59Z",
    link: "https://open.spotify.com/track/0UTyLmkQgPOEIz95OVAJTR",
    userID: "royclaud",
    genres: "hip hop,rap,conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "156LzfvMNKuXuiot4uzhGD",
    name: "No Complaints (feat. Offset & Drake) (Bonus)",
    artists: "Metro Boomin, Offset, Drake",
    duration_ms: 265480,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/7e77efedb5dcf9fbb2cc57d621421e4a789dbcc6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022887f8c05b5a9f1cb105be29",
    danceability: 0.913,
    energy: 0.571,
    valence: 0.203,
    added_at: "2021-09-16T15:23:35Z",
    link: "https://open.spotify.com/track/156LzfvMNKuXuiot4uzhGD",
    userID: "royclaud",
    genres: "rap,atl hip hop,hip hop,pop rap,rap,trap,canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "4cEqoGTqPRZy76Yl3ymj3V",
    name: "Only 1 (Interlude) (with Travis Scott)",
    artists: "Metro Boomin, Travis Scott",
    duration_ms: 80466,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/92fc18f6aa13df2530b91e3844590d8162e4766f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022887f8c05b5a9f1cb105be29",
    danceability: 0.317,
    energy: 0.239,
    valence: 0.391,
    added_at: "2021-09-16T15:22:05Z",
    link: "https://open.spotify.com/track/4cEqoGTqPRZy76Yl3ymj3V",
    userID: "royclaud",
    genres: "rap,rap,slap house",
  },
  {
    id: "4RGacGFT2ztXhGgzeaYzIR",
    name: "Up To Something (feat. Travis Scott & Young Thug)",
    artists: "Metro Boomin, Travis Scott, Young Thug",
    duration_ms: 184360,
    popularity: 54,
    preview_url: "https://p.scdn.co/mp3-preview/adad679402d44b342c1ac743d7bbe1a3b2bed76a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022887f8c05b5a9f1cb105be29",
    danceability: 0.799,
    energy: 0.617,
    valence: 0.667,
    added_at: "2021-09-16T15:21:58Z",
    link: "https://open.spotify.com/track/4RGacGFT2ztXhGgzeaYzIR",
    userID: "royclaud",
    genres: "rap,rap,slap house,atl hip hop,atl trap,gangster rap,hip hop,melodic rap,rap,trap",
  },
  {
    id: "4UG2Fm0E98LhE2dlNxiAXx",
    name: "this is what falling in love feels like",
    artists: "JVKE",
    duration_ms: 120308,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/8df55f28995b2d829543029b2a615b9d3ad7442f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027bc333ff94bcb174a817e346",
    danceability: 0.424,
    energy: 0.438,
    valence: 0.325,
    added_at: "2021-09-13T18:59:33Z",
    link: "https://open.spotify.com/track/4UG2Fm0E98LhE2dlNxiAXx",
    userID: "royclaud",
    genres: "modern indie pop,pov: indie,singer-songwriter pop",
  },
  {
    id: "3NqBxTOMCJ3zW9CIP51td4",
    name: "Jugaste y Sufrí",
    artists: "Eslabon Armado, DannyLux",
    duration_ms: 275194,
    popularity: 74,
    preview_url: "https://p.scdn.co/mp3-preview/374cba35baeceb20d48a6a1145dfa17ac316f140?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02657fa1079d4f17b7e06dd86e",
    danceability: 0.39,
    energy: 0.499,
    valence: 0.34,
    added_at: "2021-09-07T15:48:43Z",
    link: "https://open.spotify.com/track/3NqBxTOMCJ3zW9CIP51td4",
    userID: "royclaud",
    genres: "corrido,corridos tumbados,sad sierreno,sierreno,sad sierreno",
  },
  {
    id: "40WxFAbt8UEK7jKLmIxRNY",
    name: "Offended (feat. Young Thug & 21 Savage)",
    artists: "Meek Mill, Young Thug, 21 Savage",
    duration_ms: 255483,
    popularity: 48,
    preview_url: "https://p.scdn.co/mp3-preview/23a95c19033c60dffdff745a88e74ecac4863670?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020a6f4e78a01fd779ebaace83",
    danceability: 0.92,
    energy: 0.53,
    valence: 0.363,
    added_at: "2021-09-03T03:57:13Z",
    link: "https://open.spotify.com/track/40WxFAbt8UEK7jKLmIxRNY",
    userID: "royclaud",
    genres: "hip hop,philly rap,pop rap,rap,southern hip hop,trap,atl hip hop,atl trap,gangster rap,hip hop,melodic rap,rap,trap,atl hip hop,hip hop,rap",
  },
  {
    id: "7up5MwPUHQdv4DkzQRHRWP",
    name: "Pure Souls",
    artists: "Kanye West",
    duration_ms: 358621,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/fbe8d7ebb138af14b88cc8213081168401cc0978?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026ba1cffc9b2c5469503430b3",
    danceability: 0.381,
    energy: 0.528,
    valence: 0.256,
    added_at: "2021-08-30T00:23:51Z",
    link: "https://open.spotify.com/track/7up5MwPUHQdv4DkzQRHRWP",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "42ZrMq4BKsWDrrbMnMRYaK",
    name: "Jail",
    artists: "Kanye West",
    duration_ms: 297000,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/5c3e48cac1d9ac8f1d236452856c7125ce9bd0dc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026ba1cffc9b2c5469503430b3",
    danceability: 0.458,
    energy: 0.571,
    valence: 0.586,
    added_at: "2021-08-30T00:22:04Z",
    link: "https://open.spotify.com/track/42ZrMq4BKsWDrrbMnMRYaK",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "2gbMPBrBVj3CuNTLp2dHYs",
    name: "Off The Grid",
    artists: "Kanye West",
    duration_ms: 339249,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/b387a63512de3e1aa279cc1fb6471b1a45cb7895?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026ba1cffc9b2c5469503430b3",
    danceability: 0.546,
    energy: 0.64,
    valence: 0.63,
    added_at: "2021-08-30T00:21:57Z",
    link: "https://open.spotify.com/track/2gbMPBrBVj3CuNTLp2dHYs",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "7Clj1VaR0Vww6bUuM3nBk1",
    name: "World on Fire (with Slightly Stoopid)",
    artists: "Stick Figure, Slightly Stoopid",
    duration_ms: 240028,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/3bd3010f962bcdaa0a5b8285c0a97d9e102e8c36?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027c1deda851c11a26beb89bad",
    danceability: 0.866,
    energy: 0.518,
    valence: 0.644,
    added_at: "2021-08-29T01:35:42Z",
    link: "https://open.spotify.com/track/7Clj1VaR0Vww6bUuM3nBk1",
    userID: "royclaud",
    genres: "reggae fusion,reggae rock,reggae fusion,reggae rock",
  },
  {
    id: "4jvjzW7Hm0yK4LvvE0Paz9",
    name: "Falling Down - Bonus Track",
    artists: "Lil Peep, XXXTENTACION",
    duration_ms: 196400,
    popularity: 83,
    preview_url: "https://p.scdn.co/mp3-preview/9404f45725ef5d46e29a539c7da1fc4315cf5a69?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0255e36b0dc5b0ef008fc85319",
    danceability: 0.669,
    energy: 0.574,
    valence: 0.273,
    added_at: "2021-08-27T03:24:27Z",
    link: "https://open.spotify.com/track/4jvjzW7Hm0yK4LvvE0Paz9",
    userID: "royclaud",
    genres: "cloud rap,emo rap,lgbtq+ hip hop,emo rap,miami hip hop,rap",
  },
  {
    id: "4at3d5QWnlibMVN75ECDrp",
    name: "Pretty Little Fears (feat. J. Cole)",
    artists: "6LACK, J. Cole",
    duration_ms: 240341,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/4bda22e1bb78bc7a0d1d9e87bc35fa2dd6648f5b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e1530b42603367fdb2208d88",
    danceability: 0.61,
    energy: 0.458,
    valence: 0.246,
    added_at: "2021-08-27T03:20:46Z",
    link: "https://open.spotify.com/track/4at3d5QWnlibMVN75ECDrp",
    userID: "royclaud",
    genres: "atl hip hop,melodic rap,r&b,rap,trap,conscious hip hop,hip hop,north carolina hip hop,rap",
  },
  {
    id: "41MCdlvXOl62B7Kv86Bb1v",
    name: "Empty",
    artists: "Juice WRLD",
    duration_ms: 248431,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/804cea05a488e04a89cb445299a1f55a8578ed44?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02af149eb4002f65f83afc63c4",
    danceability: 0.745,
    energy: 0.561,
    valence: 0.449,
    added_at: "2021-08-27T03:19:48Z",
    link: "https://open.spotify.com/track/41MCdlvXOl62B7Kv86Bb1v",
    userID: "royclaud",
    genres: "chicago rap,melodic rap,rap",
  },
  {
    id: "4mUIe9gfsdtsVP2zFIl74x",
    name: "Maze",
    artists: "Juice WRLD",
    duration_ms: 144090,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/26ef11a8c4f51612cfb1a2115aa902736a4eaf6f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02af149eb4002f65f83afc63c4",
    danceability: 0.674,
    energy: 0.726,
    valence: 0.443,
    added_at: "2021-08-26T03:46:47Z",
    link: "https://open.spotify.com/track/4mUIe9gfsdtsVP2zFIl74x",
    userID: "royclaud",
    genres: "chicago rap,melodic rap,rap",
  },
  {
    id: "44ShclXTOOnYtt5CnCDeGV",
    name: "Robbery",
    artists: "Juice WRLD",
    duration_ms: 240527,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/cf64da8095afffc6c6feca25c90e96487f1eceb1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b3d1baeaddfa1e2a3def3e35",
    danceability: 0.708,
    energy: 0.691,
    valence: 0.535,
    added_at: "2021-08-26T03:26:47Z",
    link: "https://open.spotify.com/track/44ShclXTOOnYtt5CnCDeGV",
    userID: "royclaud",
    genres: "chicago rap,melodic rap,rap",
  },
  {
    id: "0aFFcpD0ecGbE0RzllC6qK",
    name: "Used To",
    artists: "Juice WRLD",
    duration_ms: 176443,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/20171d6779ccb2811e7da2292aef503b5fea72e9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029b3d16ce73080214dd7b07c7",
    danceability: 0.631,
    energy: 0.709,
    valence: 0.2,
    added_at: "2021-08-26T03:20:33Z",
    link: "https://open.spotify.com/track/0aFFcpD0ecGbE0RzllC6qK",
    userID: "royclaud",
    genres: "chicago rap,melodic rap,rap",
  },
  {
    id: "5de9Ho64dovuQI8Uhn5gPD",
    name: "I Don't Wanna Do This Anymore",
    artists: "XXXTENTACION",
    duration_ms: 87560,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/dcf35a063fda888374d1c72a8999b06ce6557375?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025485ea186d6971e18fabcc2f",
    danceability: 0.433,
    energy: 0.778,
    valence: 0.764,
    added_at: "2021-08-26T03:17:47Z",
    link: "https://open.spotify.com/track/5de9Ho64dovuQI8Uhn5gPD",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "7BhZDvzJV21Dd5bY9xvtII",
    name: "Between the Bars",
    artists: "Elliott Smith",
    duration_ms: 141200,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/3a998de91763a9e1d78e112772ad48af68e3369d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021ff20b364a76426805da6618",
    danceability: 0.519,
    energy: 0.0774,
    valence: 0.353,
    added_at: "2021-08-24T03:51:02Z",
    link: "https://open.spotify.com/track/7BhZDvzJV21Dd5bY9xvtII",
    userID: "royclaud",
    genres: "alternative rock,indie rock,melancholia,singer-songwriter",
  },
  {
    id: "25CMmGsl22APKhfuj4Tp7j",
    name: "It's Been Awhile",
    artists: "Staind",
    duration_ms: 264706,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/556736a51750be67c6a0f53211efadc248fd9938?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e78596fafee30d0dd305396f",
    danceability: 0.509,
    energy: 0.774,
    valence: 0.0824,
    added_at: "2021-08-24T03:50:41Z",
    link: "https://open.spotify.com/track/25CMmGsl22APKhfuj4Tp7j",
    userID: "royclaud",
    genres: "alternative metal,nu metal,post-grunge",
  },
  {
    id: "4woTEX1wYOTGDqNXuavlRC",
    name: "Superman",
    artists: "Eminem, Dina Rae",
    duration_ms: 350320,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/8625034728d0e1a6971ab17f003fecaad74f63b4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026ca5c90113b30c3c43ffb8f4",
    danceability: 0.802,
    energy: 0.755,
    valence: 0.58,
    added_at: "2021-08-21T15:53:07Z",
    link: "https://open.spotify.com/track/4woTEX1wYOTGDqNXuavlRC",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,rap",
  },
  {
    id: "5qOg2p4SrnSXDk1LF5bSIc",
    name: "Still Young",
    artists: "Felly",
    duration_ms: 152662,
    popularity: 16,
    preview_url: "https://p.scdn.co/mp3-preview/ebc21056c90e10f8ee6b4d41679720287bf46565?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02dffd59247bde7e1f9e907c7d",
    danceability: 0.775,
    energy: 0.694,
    valence: 0.485,
    added_at: "2021-08-20T09:08:04Z",
    link: "https://open.spotify.com/track/5qOg2p4SrnSXDk1LF5bSIc",
    userID: "royclaud",
    genres: "indie pop rap",
  },
  {
    id: "6GnhWMhgJb7uyiiPEiEkDA",
    name: "Weekend (feat. Miguel)",
    artists: "Mac Miller, Miguel",
    duration_ms: 208080,
    popularity: 75,
    preview_url: "https://p.scdn.co/mp3-preview/b2ba953f5ad2e0a28839468cb63921194c6e381d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ee0f38410382a255e4fb15f4",
    danceability: 0.843,
    energy: 0.435,
    valence: 0.19,
    added_at: "2021-08-18T02:06:02Z",
    link: "https://open.spotify.com/track/6GnhWMhgJb7uyiiPEiEkDA",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap,r&b,urban contemporary",
  },
  {
    id: "3xKsf9qdS1CyvXSMEid6g8",
    name: "Pink + White",
    artists: "Frank Ocean",
    duration_ms: 184516,
    popularity: 87,
    preview_url: "https://p.scdn.co/mp3-preview/0e3ca894e19c37cbbbd511d9eb682d8bee030126?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c5649add07ed3720be9d5526",
    danceability: 0.545,
    energy: 0.545,
    valence: 0.549,
    added_at: "2021-08-18T02:05:44Z",
    link: "https://open.spotify.com/track/3xKsf9qdS1CyvXSMEid6g8",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "6TnLGwBYhlTQFn7gzaxgHG",
    name: "LOST IN LOVE",
    artists: "BROCKHAMPTON",
    duration_ms: 260806,
    popularity: 31,
    preview_url: "https://p.scdn.co/mp3-preview/6b9c413fdc172950bef21206a52e390b9713f3ac?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0246fc3effbd1b9af4c69019ac",
    danceability: 0.395,
    energy: 0.259,
    valence: 0.0461,
    added_at: "2021-08-18T02:05:43Z",
    link: "https://open.spotify.com/track/6TnLGwBYhlTQFn7gzaxgHG",
    userID: "royclaud",
    genres: "boy band,rap",
  },
  {
    id: "66dQdXAbtuPdSasezCQVZE",
    name: "SUMMER",
    artists: "BROCKHAMPTON",
    duration_ms: 204939,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/411493512d3facbf8aed5b7f554c73848b087fec?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027d15fb20303a589acc1ab98b",
    danceability: 0.497,
    energy: 0.55,
    valence: 0.542,
    added_at: "2021-08-18T02:05:42Z",
    link: "https://open.spotify.com/track/66dQdXAbtuPdSasezCQVZE",
    userID: "royclaud",
    genres: "boy band,rap",
  },
  {
    id: "5JRMqkR82k2fdDEAim9SCN",
    name: "Peach",
    artists: "Kevin Abstract",
    duration_ms: 176732,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/1aaf30001acf34f7c6c0d9aee23c354d05923fc4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02595101c281d7e229e1f0e6c4",
    danceability: 0.646,
    energy: 0.682,
    valence: 0.421,
    added_at: "2021-08-18T02:05:41Z",
    link: "https://open.spotify.com/track/5JRMqkR82k2fdDEAim9SCN",
    userID: "royclaud",
    genres: "afrofuturism,lgbtq+ hip hop",
  },
  {
    id: "0SA0mMXWh23raZ6xzOCU2V",
    name: "She",
    artists: "Tyler, The Creator, Frank Ocean",
    duration_ms: 253386,
    popularity: 74,
    preview_url: "https://p.scdn.co/mp3-preview/1968c42a10568b1704f37d368c14e7e1e7ee7260?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0228ae9a159d24cb0ab2552712",
    danceability: 0.35,
    energy: 0.621,
    valence: 0.459,
    added_at: "2021-08-18T02:05:37Z",
    link: "https://open.spotify.com/track/0SA0mMXWh23raZ6xzOCU2V",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "7KA4W4McWYRpgf0fWsJZWB",
    name: "See You Again (feat. Kali Uchis)",
    artists: "Tyler, The Creator, Kali Uchis",
    duration_ms: 180386,
    popularity: 89,
    preview_url: "https://p.scdn.co/mp3-preview/c703198293891e3b276800ea6b187cf7951d3d7d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028940ac99f49e44f59e6f7fb3",
    danceability: 0.558,
    energy: 0.559,
    valence: 0.62,
    added_at: "2021-08-18T02:05:36Z",
    link: "https://open.spotify.com/track/7KA4W4McWYRpgf0fWsJZWB",
    userID: "royclaud",
    genres: "colombian pop",
  },
  {
    id: "1otG6j1WHNvl9WgXLWkHTo",
    name: "After The Storm (feat. Tyler, The Creator & Bootsy Collins)",
    artists: "Kali Uchis, Tyler, The Creator, Bootsy Collins",
    duration_ms: 207454,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/a0c9242375aa4dd8c68337de976841d1c9166a03?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0290b4e1905b1fc48c537ec053",
    danceability: 0.702,
    energy: 0.659,
    valence: 0.843,
    added_at: "2021-08-18T02:05:35Z",
    link: "https://open.spotify.com/track/1otG6j1WHNvl9WgXLWkHTo",
    userID: "royclaud",
    genres: "colombian pop,funk,p funk,soul",
  },
  {
    id: "2P3SLxeQHPqh8qKB6gtJY2",
    name: "Poetic Justice",
    artists: "Kendrick Lamar, Drake",
    duration_ms: 300160,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/685f1ece966b1437dd5548a86c95d97987246361?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d58e537cea05c2156792c53d",
    danceability: 0.778,
    energy: 0.569,
    valence: 0.642,
    added_at: "2021-08-18T02:05:34Z",
    link: "https://open.spotify.com/track/2P3SLxeQHPqh8qKB6gtJY2",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap,canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "0JXXNGljqupsJaZsgSbMZV",
    name: "Sure Thing",
    artists: "Miguel",
    duration_ms: 195373,
    popularity: 86,
    preview_url: "https://p.scdn.co/mp3-preview/d337faa4bb71c8ac9a13998be64fbb0d7d8b8463?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d5a8395b0d80b8c48a5d851c",
    danceability: 0.684,
    energy: 0.607,
    valence: 0.498,
    added_at: "2021-08-18T02:05:32Z",
    link: "https://open.spotify.com/track/0JXXNGljqupsJaZsgSbMZV",
    userID: "royclaud",
    genres: "r&b,urban contemporary",
  },
  {
    id: "0dWOFwdXrbBUYqD9DLsoyK",
    name: "BLEACH",
    artists: "BROCKHAMPTON",
    duration_ms: 273151,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/1f433af550f03c17190b9d2d334a9d4b94a7566e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ef51817e6a6563b6f7ce5872",
    danceability: 0.595,
    energy: 0.657,
    valence: 0.718,
    added_at: "2021-08-13T21:06:52Z",
    link: "https://open.spotify.com/track/0dWOFwdXrbBUYqD9DLsoyK",
    userID: "royclaud",
    genres: "boy band,rap",
  },
  {
    id: "0vAb3U3NJhiuib2B7sJdeJ",
    name: "Money",
    artists: "The Drums",
    duration_ms: 233655,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/c5428296d5ce1b42027ab8523e14c5ed04f6a068?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0228704b8e73877747d2c71659",
    danceability: 0.603,
    energy: 0.918,
    valence: 0.452,
    added_at: "2021-08-13T20:23:38Z",
    link: "https://open.spotify.com/track/0vAb3U3NJhiuib2B7sJdeJ",
    userID: "royclaud",
    genres: "brooklyn indie,indie surf",
  },
  {
    id: "5UsLjwBaTHBX4ektWIr4XX",
    name: "Forever",
    artists: "Drake, Kanye West, Lil Wayne, Eminem",
    duration_ms: 357706,
    popularity: 73,
    preview_url: "https://p.scdn.co/mp3-preview/3f6dd233fd87d673838e16846846f9fed5f66c23?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02506c4cc93e5a6234164125e1",
    danceability: 0.457,
    energy: 0.906,
    valence: 0.54,
    added_at: "2021-08-11T20:52:15Z",
    link: "https://open.spotify.com/track/5UsLjwBaTHBX4ektWIr4XX",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap,chicago rap,hip hop,rap,hip hop,new orleans rap,pop rap,rap,trap,detroit hip hop,hip hop,rap",
  },
  {
    id: "4wVOKKEHUJxHCFFNUWDn0B",
    name: "Chicago Freestyle (feat. Giveon)",
    artists: "Drake, Giveon",
    duration_ms: 220487,
    popularity: 77,
    preview_url: "https://p.scdn.co/mp3-preview/b185a4dc8c70ad2fe9a8846fab83241ea3418921?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bba7cfaf7c59ff0898acba1f",
    danceability: 0.735,
    energy: 0.449,
    valence: 0.0397,
    added_at: "2021-08-10T14:12:16Z",
    link: "https://open.spotify.com/track/4wVOKKEHUJxHCFFNUWDn0B",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap,r&b",
  },
  {
    id: "7nVERWQXlgZRQsYw4fHf3b",
    name: "Lose",
    artists: "KSI, Lil Wayne",
    duration_ms: 205653,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/5fe42cd2843c1742a18fb13dac58b9e9fc0c0fbc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d9a5dfd24ef123b18c086aa6",
    danceability: 0.682,
    energy: 0.721,
    valence: 0.248,
    added_at: "2021-08-10T13:56:36Z",
    link: "https://open.spotify.com/track/7nVERWQXlgZRQsYw4fHf3b",
    userID: "royclaud",
    genres: "social media pop,uk hip hop,hip hop,new orleans rap,pop rap,rap,trap",
  },
  {
    id: "4IiI9lpobMyEbhV5VstNs7",
    name: "Nothin' At All (feat. Skoolie 300)",
    artists: "Chuuwee, Trizz, Skoolie 300",
    duration_ms: 271777,
    popularity: 39,
    preview_url: "https://p.scdn.co/mp3-preview/bab0ff1e0bf0eb0a4fa7c9ab41f31374669e2ce3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d855a3370bfa123256d6bb38",
    danceability: 0.628,
    energy: 0.588,
    valence: 0.491,
    added_at: "2021-08-10T02:30:42Z",
    link: "https://open.spotify.com/track/4IiI9lpobMyEbhV5VstNs7",
    userID: "royclaud",
    genres: "indie hip hop,sacramento hip hop,underground hip hop,deep underground hip hop,soul flow",
  },
  {
    id: "1jsJAtrQtTWD44LW9QokDR",
    name: "guap",
    artists: "lilbubblegum, Letoa",
    duration_ms: 133746,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/35cb20bdfbddd30757fa097e8c0593bfcacdf55d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024583f97464d6d2fbf1db5752",
    danceability: 0.687,
    energy: 0.408,
    valence: 0.146,
    added_at: "2021-07-20T01:55:41Z",
    link: "https://open.spotify.com/track/1jsJAtrQtTWD44LW9QokDR",
    userID: "royclaud",
    genres: "aesthetic rap,aesthetic rap",
  },
  {
    id: "09jg3DYAdspePvHcJPjSAh",
    name: "Dancing with Myself",
    artists: "Generation X",
    duration_ms: 228080,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/98fb58e8eab254e59d11f5daa564b3c07ccf7eff?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02dfafa4a0f94e4b75f0069d23",
    danceability: 0.385,
    energy: 0.947,
    valence: 0.831,
    added_at: "2021-07-16T15:48:18Z",
    link: "https://open.spotify.com/track/09jg3DYAdspePvHcJPjSAh",
    userID: "royclaud",
    genres: "glam punk,punk",
  },
  {
    id: "4TIJ7zSBNejpoIPaWpWRKc",
    name: "Rebel Yell",
    artists: "Billy Idol",
    duration_ms: 288533,
    popularity: 73,
    preview_url: "https://p.scdn.co/mp3-preview/07db3a3ea4fa92bf94cba38680ea4f8931a3f9d6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ea07dca8b4ca808c1e5b17fb",
    danceability: 0.531,
    energy: 0.864,
    valence: 0.485,
    added_at: "2021-07-16T00:45:29Z",
    link: "https://open.spotify.com/track/4TIJ7zSBNejpoIPaWpWRKc",
    userID: "royclaud",
    genres: "album rock,classic rock,dance rock,hard rock,new romantic,new wave,new wave pop,rock,soft rock",
  },
  {
    id: "4UCyMBJFx1PyxstcGIiyQj",
    name: "Horizon",
    artists: "Malz Monday",
    duration_ms: 232040,
    popularity: 23,
    preview_url: "https://p.scdn.co/mp3-preview/7d020d1c4d5d5daec5cb30c74460002d4710fc98?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023332aa574cb3f1132edd2649",
    danceability: 0.929,
    energy: 0.56,
    valence: 0.535,
    added_at: "2021-07-14T20:40:45Z",
    link: "https://open.spotify.com/track/4UCyMBJFx1PyxstcGIiyQj",
    userID: "royclaud",
    genres: "indie hip hop",
  },
  {
    id: "6whuf8DUASaSQy0SGVRaDn",
    name: "She So Fine",
    artists: "Adán Cruz, Synesthetic Nation, MC Dharta",
    duration_ms: 188882,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/91b184597fed6c22e8d4f4c0dd44ee4dfd2b477a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0223d88a8cf76497caf17f2a73",
    danceability: 0.873,
    energy: 0.556,
    valence: 0.824,
    added_at: "2021-07-05T19:36:19Z",
    link: "https://open.spotify.com/track/6whuf8DUASaSQy0SGVRaDn",
    userID: "royclaud",
    genres: "trap mexicano",
  },
  {
    id: "3WQilym5Vi4ypxb70vx0eY",
    name: "Mente Positiva",
    artists: "Junior H",
    duration_ms: 192390,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/4f2e4abeb36ce378635f987bec06e95b0d652683?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029c076fb69a52578d6441d57e",
    danceability: 0.576,
    energy: 0.604,
    valence: 0.734,
    added_at: "2021-07-01T23:05:53Z",
    link: "https://open.spotify.com/track/3WQilym5Vi4ypxb70vx0eY",
    userID: "royclaud",
    genres: "corrido,corridos tumbados,musica mexicana,sad sierreno,sierreno",
  },
  {
    id: "1cuYCAodLWgzhwE2KFZ8rC",
    name: "WASTE",
    artists: "BROCKHAMPTON",
    duration_ms: 154406,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/cc6d64af60c4b0c2f2ceb49ce95b7bad225ab203?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a0c4a585a7d73b4943b9bf13",
    danceability: 0.416,
    energy: 0.412,
    valence: 0.409,
    added_at: "2021-07-01T04:42:02Z",
    link: "https://open.spotify.com/track/1cuYCAodLWgzhwE2KFZ8rC",
    userID: "royclaud",
    genres: "boy band,rap",
  },
  {
    id: "15hjsiQDarS8GJKG4dWXlk",
    name: "Give a Fuck How You Feel",
    artists: "Bravo the Bagchaser",
    duration_ms: 136542,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/79b00903f80c55aaa890cc85ff787e799d9fc8da?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021aa28726785daf571fde66e6",
    danceability: 0.742,
    energy: 0.212,
    valence: 0.354,
    added_at: "2021-06-29T20:38:44Z",
    link: "https://open.spotify.com/track/15hjsiQDarS8GJKG4dWXlk",
    userID: "royclaud",
    genres: "cali rap",
  },
  {
    id: "7z4xW9WY86uH3gd1V9pfCM",
    name: "JUGGERNAUT (feat. Lil Uzi Vert & Pharrell Williams)",
    artists: "Tyler, The Creator, Lil Uzi Vert, Pharrell Williams",
    duration_ms: 146419,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/5b4b9926cf18122d90f8146e14e2d8408dfa6eba?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02696b4e67423edd64784bfbb4",
    danceability: 0.827,
    energy: 0.571,
    valence: 0.62,
    added_at: "2021-06-26T02:03:04Z",
    link: "https://open.spotify.com/track/7z4xW9WY86uH3gd1V9pfCM",
    userID: "royclaud",
    genres: "hip hop,melodic rap,philly rap,rage rap,rap,trap,dance pop,pop",
  },
  {
    id: "4DuUwzP4ALMqpquHU0ltAB",
    name: "Tyler Herro",
    artists: "Jack Harlow",
    duration_ms: 156497,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/12ac9d153a8aa06e9584e56447ee2d268a99fb19?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d12510170b4c55664e96e9a5",
    danceability: 0.794,
    energy: 0.756,
    valence: 0.775,
    added_at: "2021-06-13T03:04:39Z",
    link: "https://open.spotify.com/track/4DuUwzP4ALMqpquHU0ltAB",
    userID: "royclaud",
    genres: "deep underground hip hop,hip hop,kentucky hip hop,pop rap,rap",
  },
  {
    id: "2vsOQZpmbNbO3zDXiTILqp",
    name: "WHAT'S THE OCCASION?",
    artists: "BROCKHAMPTON",
    duration_ms: 225026,
    popularity: 27,
    preview_url: "https://p.scdn.co/mp3-preview/3c93fe618a7839ce274d38f83ecc221a1817d65c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0230959bc8585f7289ad732ac9",
    danceability: 0.614,
    energy: 0.553,
    valence: 0.156,
    added_at: "2021-06-04T19:42:02Z",
    link: "https://open.spotify.com/track/2vsOQZpmbNbO3zDXiTILqp",
    userID: "royclaud",
    genres: "boy band,rap",
  },
  {
    id: "5ZQ3emIPETWpZH9Q9sRF6F",
    name: "I'LL TAKE YOU ON (feat. Charlie Wilson)",
    artists: "BROCKHAMPTON, Charlie Wilson",
    duration_ms: 254653,
    popularity: 27,
    preview_url: "https://p.scdn.co/mp3-preview/211e9c7b89aca385ab1c62f913efae3c50ec2c43?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0230959bc8585f7289ad732ac9",
    danceability: 0.685,
    energy: 0.542,
    valence: 0.572,
    added_at: "2021-06-04T19:41:43Z",
    link: "https://open.spotify.com/track/5ZQ3emIPETWpZH9Q9sRF6F",
    userID: "royclaud",
    genres: "boy band,rap,contemporary r&b,r&b,urban contemporary",
  },
  {
    id: "3DpkZtewkU0L76iT6j5gvV",
    name: "BANKROLL (feat. A$AP Rocky & A$AP Ferg)",
    artists: "BROCKHAMPTON, A$AP Rocky, A$AP Ferg",
    duration_ms: 202560,
    popularity: 29,
    preview_url: "https://p.scdn.co/mp3-preview/02119fde73c9c39e8612a2b5fc00dbc6042d766c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0230959bc8585f7289ad732ac9",
    danceability: 0.785,
    energy: 0.539,
    valence: 0.582,
    added_at: "2021-06-04T19:35:06Z",
    link: "https://open.spotify.com/track/3DpkZtewkU0L76iT6j5gvV",
    userID: "royclaud",
    genres: "boy band,rap,east coast hip hop,hip hop,rap,hip hop,pop rap,rap,southern hip hop,trap",
  },
  {
    id: "1Gu6GxGcH3D0abQDS2IOyF",
    name: "drowning.",
    artists: "Eden Project",
    duration_ms: 257776,
    popularity: 45,
    preview_url: "https://p.scdn.co/mp3-preview/e648ee2232fc66157ac4f71c7ab09cdb896c741d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02616450de17b5a6ecf625b37e",
    danceability: 0.505,
    energy: 0.751,
    valence: 0.277,
    added_at: "2021-06-04T12:25:12Z",
    link: "https://open.spotify.com/track/1Gu6GxGcH3D0abQDS2IOyF",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "1eLU27PPFxBhbp1CBjPh9J",
    name: "1Train (feat. Kendrick Lamar, Joey Bada$$, Yelawolf, Danny Brown, Action Bronson & Big K.R.I.T.)",
    artists: "A$AP Rocky, Kendrick Lamar, Joey Bada$$, Yelawolf, Danny Brown, Action Bronson, Big K.R.I.T.",
    duration_ms: 372173,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/f69b990fcdf7b991681d42508ef93277e6a77f9d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022ae92030b51fb8135d694af9",
    danceability: 0.489,
    energy: 0.878,
    valence: 0.775,
    added_at: "2021-06-03T23:28:34Z",
    link: "https://open.spotify.com/track/1eLU27PPFxBhbp1CBjPh9J",
    userID: "royclaud",
    genres:
      "east coast hip hop,hip hop,rap,conscious hip hop,hip hop,rap,west coast rap,conscious hip hop,hip hop,rap,alabama rap,alternative hip hop,detroit hip hop,escape room,hip hop,underground hip hop,alternative hip hop,east coast hip hop,hip hop,nyc rap,queens hip hop,conscious hip hop,mississippi hip hop,southern hip hop,trap",
  },
  {
    id: "7jmTA4qUoE3powcTpw3dvF",
    name: "Zendaya (feat. J. Cole)",
    artists: "Cozz, J. Cole",
    duration_ms: 258165,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/cb5c461899e72759a729c4166d8fd19d9bef9f4b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028332ef6f54af77c789e58cbe",
    danceability: 0.605,
    energy: 0.887,
    valence: 0.542,
    added_at: "2021-06-02T21:52:40Z",
    link: "https://open.spotify.com/track/7jmTA4qUoE3powcTpw3dvF",
    userID: "royclaud",
    genres: "underground hip hop,conscious hip hop,hip hop,north carolina hip hop,rap",
  },
  {
    id: "0wQbk9sAkZflH2ht0FC5G7",
    name: "Makeout",
    artists: "Faze Wave",
    duration_ms: 190749,
    popularity: 36,
    preview_url: "https://p.scdn.co/mp3-preview/47d74d7d7a3bfa535b965840a7cd079240a587f2?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d2338c149ad8eb3f7ed25241",
    danceability: 0.504,
    energy: 0.533,
    valence: 0.295,
    added_at: "2021-05-26T16:46:26Z",
    link: "https://open.spotify.com/track/0wQbk9sAkZflH2ht0FC5G7",
    userID: "royclaud",
    genres: "jacksonville indie",
  },
  {
    id: "1Y2583xYzwiEXDetAB7H1Q",
    name: "Sex with a Ghost",
    artists: "Teddy Hyde",
    duration_ms: 240229,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/1c0c336cda1e67ad0b405fb178ffb6693879bd93?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b0cb0a0d42edaa23b1f4c8f0",
    danceability: 0.48,
    energy: 0.329,
    valence: 0.385,
    added_at: "2021-05-26T16:11:15Z",
    link: "https://open.spotify.com/track/1Y2583xYzwiEXDetAB7H1Q",
    userID: "royclaud",
    genres: "modern indie pop,pov: indie",
  },
  {
    id: "3cLc5HfEVkD78OA8Z6IEet",
    name: "a m a r i",
    artists: "J. Cole",
    duration_ms: 148421,
    popularity: 40,
    preview_url: "https://p.scdn.co/mp3-preview/4c6e8c56d9a4afaa31f6731385dfedfe32912cb0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0298174a97206614a1e1cf8631",
    danceability: 0.657,
    energy: 0.694,
    valence: 0.244,
    added_at: "2021-05-18T22:43:08Z",
    link: "https://open.spotify.com/track/3cLc5HfEVkD78OA8Z6IEet",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,north carolina hip hop,rap",
  },
  {
    id: "2c3gn2uL85jNcQDO8FWiJo",
    name: "h u n g e r . o n . h i l l s i d e (with Bas)",
    artists: "J. Cole, Bas",
    duration_ms: 238971,
    popularity: 34,
    preview_url: "https://p.scdn.co/mp3-preview/c96a94697917a7536677bf49014ac208d1fccf8e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0298174a97206614a1e1cf8631",
    danceability: 0.417,
    energy: 0.679,
    valence: 0.291,
    added_at: "2021-05-15T00:34:19Z",
    link: "https://open.spotify.com/track/2c3gn2uL85jNcQDO8FWiJo",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,north carolina hip hop,rap,queens hip hop,sudanese hip hop,sudanese pop,underground hip hop",
  },
  {
    id: "3djNBlI7xOggg7pnsOLaNm",
    name: "California Love - Original Version",
    artists: "2Pac, Roger, Dr. Dre",
    duration_ms: 284480,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/2e9235c2e016ca5ef80d0bd28a53f5ed6cd7fa65?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c1c10d28c1887c2bb1f6b2fa",
    danceability: 0.761,
    energy: 0.847,
    valence: 0.765,
    added_at: "2021-04-19T04:19:09Z",
    link: "https://open.spotify.com/track/3djNBlI7xOggg7pnsOLaNm",
    userID: "royclaud",
    genres: "g funk,gangster rap,hip hop,rap,west coast rap,funk,new jack swing,p funk,post-disco,quiet storm,g funk,gangster rap,hip hop,rap,west coast rap",
  },
  {
    id: "3X9MydsGl2B8LbY8rpANJT",
    name: "Funky Worm",
    artists: "Ohio Players",
    duration_ms: 160986,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/931e21db4ac0b1376191ed3094463d7b428d1ff7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d08d2bac12bb6f1fce063b6f",
    danceability: 0.681,
    energy: 0.651,
    valence: 0.96,
    added_at: "2021-04-19T04:17:43Z",
    link: "https://open.spotify.com/track/3X9MydsGl2B8LbY8rpANJT",
    userID: "royclaud",
    genres: "classic soul,disco,funk,p funk,quiet storm,soul,southern soul",
  },
  {
    id: "53HL9X1ZFXUvN8OS7HsHCX",
    name: "Between the Sheets",
    artists: "The Isley Brothers",
    duration_ms: 338133,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/7501dcde24632a431b01e2ac87fe40835a688e92?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022f596da45f5255357671bee0",
    danceability: 0.718,
    energy: 0.31,
    valence: 0.561,
    added_at: "2021-04-19T04:17:27Z",
    link: "https://open.spotify.com/track/53HL9X1ZFXUvN8OS7HsHCX",
    userID: "royclaud",
    genres: "classic soul,funk,motown,quiet storm,soul",
  },
  {
    id: "2kPQbvKYxJV2z5q2wNps7n",
    name: "Chase The Devil",
    artists: "Max Romeo, The Upsetters",
    duration_ms: 207666,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/ba3ad488921d3d5e54814a82da18cd298b60c3cf?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0216e6c892bf8743fce23ff247",
    danceability: 0.838,
    energy: 0.34,
    valence: 0.836,
    added_at: "2021-04-19T04:16:07Z",
    link: "https://open.spotify.com/track/2kPQbvKYxJV2z5q2wNps7n",
    userID: "royclaud",
    genres: "reggae,roots reggae,dub,reggae,rocksteady,roots reggae",
  },
  {
    id: "0bvpSvOYt4KTfZIZJmyFOs",
    name: "White Wedding",
    artists: "Billy Idol",
    duration_ms: 506226,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/04e3c3444aa82e4b0e1337d1f303d05d5e8e1b94?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02de3a001606041808a9217c40",
    danceability: 0.677,
    energy: 0.62,
    valence: 0.672,
    added_at: "2021-04-17T19:50:05Z",
    link: "https://open.spotify.com/track/0bvpSvOYt4KTfZIZJmyFOs",
    userID: "royclaud",
    genres: "album rock,classic rock,dance rock,hard rock,new romantic,new wave,new wave pop,rock,soft rock",
  },
  {
    id: "503OTo2dSqe7qk76rgsbep",
    name: "Still D.R.E.",
    artists: "Dr. Dre, Snoop Dogg",
    duration_ms: 270586,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/7a1ea34238e6d08c87fc17a5c7e30321d79a7aaa?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029b19c107109de740bad72df5",
    danceability: 0.816,
    energy: 0.775,
    valence: 0.527,
    added_at: "2021-04-16T22:53:25Z",
    link: "https://open.spotify.com/track/503OTo2dSqe7qk76rgsbep",
    userID: "royclaud",
    genres: "g funk,gangster rap,hip hop,rap,west coast rap,g funk,gangster rap,hip hop,pop rap,rap,west coast rap",
  },
  {
    id: "15MJ5NThPjj6xhPcts8MiY",
    name: 'Take My Breath Away - Love Theme from "Top Gun"',
    artists: "Berlin",
    duration_ms: 255733,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/78d34bdf4463c73eaf49fd2255a45c8b9eaa7444?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fa183fdd680f1d249ba2f893",
    danceability: 0.497,
    energy: 0.719,
    valence: 0.547,
    added_at: "2021-04-16T22:34:13Z",
    link: "https://open.spotify.com/track/15MJ5NThPjj6xhPcts8MiY",
    userID: "royclaud",
    genres: "new romantic,new wave pop,synthpop",
  },
  {
    id: "4QaEs6elipvDmcBVEVGrAH",
    name: "Got It All",
    artists: "Blxst, Dom Kennedy",
    duration_ms: 159130,
    popularity: 42,
    preview_url: "https://p.scdn.co/mp3-preview/64161bd28d060e72d64f10a1999319eb3034fc11?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fa128a48366625008c80497b",
    danceability: 0.605,
    energy: 0.583,
    valence: 0.534,
    added_at: "2021-03-31T00:22:30Z",
    link: "https://open.spotify.com/track/4QaEs6elipvDmcBVEVGrAH",
    userID: "royclaud",
    genres: "pop rap,westcoast flow,soul flow,underground hip hop",
  },
  {
    id: "5JycxhApZmzbA4xSwvqh6k",
    name: "All To Me",
    artists: "Giveon",
    duration_ms: 127807,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/acc37cdbaf00bef7427508300154b721d5da7ff7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0295b3744a4e137e68051cf809",
    danceability: 0.523,
    energy: 0.543,
    valence: 0.318,
    added_at: "2021-03-12T05:32:20Z",
    link: "https://open.spotify.com/track/5JycxhApZmzbA4xSwvqh6k",
    userID: "royclaud",
    genres: "r&b",
  },
  {
    id: "7IL8PSVwLOJxqYne6azxQv",
    name: "Pretty Boy",
    artists: "The Neighbourhood",
    duration_ms: 234093,
    popularity: 74,
    preview_url: "https://p.scdn.co/mp3-preview/c89449141f03ea62c8357f494e29c32a564152b8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021a721f3757e5b67f87e1acd3",
    danceability: 0.578,
    energy: 0.386,
    valence: 0.569,
    added_at: "2021-03-12T04:26:42Z",
    link: "https://open.spotify.com/track/7IL8PSVwLOJxqYne6azxQv",
    userID: "royclaud",
    genres: "modern alternative rock,modern rock,pop",
  },
  {
    id: "0rBMP6VVGRgwnzZCLpijyl",
    name: "Canal St. (feat. Bones)",
    artists: "A$AP Rocky, BONES",
    duration_ms: 227426,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/40de4e1578050ef4820e1acc932507269f393ba0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0281a6a2cafab2e7a4066b5eec",
    danceability: 0.696,
    energy: 0.583,
    valence: 0.524,
    added_at: "2021-03-08T16:39:37Z",
    link: "https://open.spotify.com/track/0rBMP6VVGRgwnzZCLpijyl",
    userID: "royclaud",
    genres: "east coast hip hop,hip hop,rap,cloud rap,dark trap",
  },
  {
    id: "3W4x7fZhMHw2pE6wyBzzF8",
    name: "Weight of Sound",
    artists: "Stick Figure, TJ O'Neill",
    duration_ms: 443379,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/8ef71c7b78c0533974964119d76ade29ff4e57be?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d05956a2233c7be9340ae85b",
    danceability: 0.857,
    energy: 0.713,
    valence: 0.869,
    added_at: "2021-02-24T19:22:56Z",
    link: "https://open.spotify.com/track/3W4x7fZhMHw2pE6wyBzzF8",
    userID: "royclaud",
    genres: "reggae fusion,reggae rock",
  },
  {
    id: "47Bg6IrMed1GPbxRgwH2aC",
    name: "Best Friend",
    artists: "Rex Orange County",
    duration_ms: 262231,
    popularity: 2,
    preview_url: "https://p.scdn.co/mp3-preview/1b45899b060b09fb16d8a56ca1d22a684b9129de?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0233a6b45fa8354efe37633964",
    danceability: 0.575,
    energy: 0.714,
    valence: 0.32,
    added_at: "2021-02-05T22:53:44Z",
    link: "https://open.spotify.com/track/47Bg6IrMed1GPbxRgwH2aC",
    userID: "royclaud",
    genres: "bedroom pop",
  },
  {
    id: "3ImJurxjtL8FutkeQ6uPRK",
    name: "CANNON",
    artists: "BROCKHAMPTON",
    duration_ms: 280234,
    popularity: 38,
    preview_url: "https://p.scdn.co/mp3-preview/89aba27ebcb11149465b1c8a7a71b62fc26bd216?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d275443e69bee0526cdc85de",
    danceability: 0.522,
    energy: 0.613,
    valence: 0.309,
    added_at: "2021-02-05T22:24:52Z",
    link: "https://open.spotify.com/track/3ImJurxjtL8FutkeQ6uPRK",
    userID: "royclaud",
    genres: "boy band,rap",
  },
  {
    id: "5lbCjKgdT9pvYjrtmSiRvL",
    name: "HOTTIE",
    artists: "BROCKHAMPTON",
    duration_ms: 197147,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/d731c4032096be0c3f37f2b55caaab7884e5d318?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ef51817e6a6563b6f7ce5872",
    danceability: 0.856,
    energy: 0.712,
    valence: 0.774,
    added_at: "2021-02-05T03:20:16Z",
    link: "https://open.spotify.com/track/5lbCjKgdT9pvYjrtmSiRvL",
    userID: "royclaud",
    genres: "boy band,rap",
  },
  {
    id: "1hGy2eLcmC8eKx7qr1tOqx",
    name: "Beautiful Girls",
    artists: "Sean Kingston",
    duration_ms: 225373,
    popularity: 76,
    preview_url: "https://p.scdn.co/mp3-preview/65d63a1abee5ff1727c1cd5f717a9044d5c24ee7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026a45d20e414fbc456ecea553",
    danceability: 0.762,
    energy: 0.661,
    valence: 0.769,
    added_at: "2021-02-05T03:19:20Z",
    link: "https://open.spotify.com/track/1hGy2eLcmC8eKx7qr1tOqx",
    userID: "royclaud",
    genres: "dance pop,miami hip hop,pop",
  },
  {
    id: "4RJvjnfvcS5LWoX5L1Wrn9",
    name: "robert downey jr.",
    artists: "Brandon Bury",
    duration_ms: 122946,
    popularity: 3,
    preview_url: "https://p.scdn.co/mp3-preview/9f0094f634806829f116d28787b2b3647b66ef73?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bff3f0bab6e896b501c90167",
    danceability: 0.785,
    energy: 0.5,
    valence: 0.146,
    added_at: "2021-02-05T03:16:21Z",
    link: "https://open.spotify.com/track/4RJvjnfvcS5LWoX5L1Wrn9",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "3Xm9jQDd8my7ui5DUtEHYf",
    name: "In My Dreams (Cudder Anthem)",
    artists: "Kid Cudi",
    duration_ms: 199000,
    popularity: 32,
    preview_url: "https://p.scdn.co/mp3-preview/caa2c42ad0ee951350b0df7cf126c14d95298d42?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02aab2c3c3f1f3207137d915c9",
    danceability: 0.675,
    energy: 0.418,
    valence: 0.467,
    added_at: "2021-02-04T00:27:34Z",
    link: "https://open.spotify.com/track/3Xm9jQDd8my7ui5DUtEHYf",
    userID: "royclaud",
    genres: "hip hop,ohio hip hop,pop rap,rap",
  },
  {
    id: "0pt0wjZNeFOMIeCudmXRrl",
    name: "1004 Kilómetros",
    artists: "Junior H",
    duration_ms: 277167,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/4071bd20a63c345e97045700c1591d82188a2dd6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024eb8ff6781d1f056c22ef377",
    danceability: 0.651,
    energy: 0.567,
    valence: 0.521,
    added_at: "2021-01-21T01:54:05Z",
    link: "https://open.spotify.com/track/0pt0wjZNeFOMIeCudmXRrl",
    userID: "royclaud",
    genres: "corrido,corridos tumbados,musica mexicana,sad sierreno,sierreno",
  },
  {
    id: "1QOqNh2GvfN0ToramLPw5R",
    name: "Epiphany (feat. NF)",
    artists: "Futuristic, NF",
    duration_ms: 249168,
    popularity: 41,
    preview_url: "https://p.scdn.co/mp3-preview/eb468b05a1d14aed3874f34ac2f6e3e3c0dea3cf?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020d8ff8fff07a43c494d17d08",
    danceability: 0.754,
    energy: 0.823,
    valence: 0.548,
    added_at: "2021-01-14T22:48:46Z",
    link: "https://open.spotify.com/track/1QOqNh2GvfN0ToramLPw5R",
    userID: "royclaud",
    genres: "deep underground hip hop,indie pop rap,hip hop,pop rap",
  },
  {
    id: "7hxHWCCAIIxFLCzvDgnQHX",
    name: "Lemonade (feat. Gunna, Don Toliver & NAV)",
    artists: "Internet Money, Gunna, Don Toliver, NAV",
    duration_ms: 195428,
    popularity: 5,
    preview_url: "https://p.scdn.co/mp3-preview/2c70ae554b0019f3780af30177c211e40aea0512?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029ca0c952f130e28025209cf0",
    danceability: 0.8,
    energy: 0.658,
    valence: 0.462,
    added_at: "2021-01-14T15:53:51Z",
    link: "https://open.spotify.com/track/7hxHWCCAIIxFLCzvDgnQHX",
    userID: "royclaud",
    genres: "pop rap,trap,atl hip hop,melodic rap,rap,trap,rap,canadian hip hop,canadian trap,melodic rap,rap,trap",
  },
  {
    id: "5EYi2rH4LYs6M21ZLOyQTx",
    name: "Loving Is Easy (feat. Benny Sings)",
    artists: "Rex Orange County, Benny Sings",
    duration_ms: 155720,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/974a2d5b5bc9e767e270ba7d9f13c9b03ab5a60a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020165d0189ec5f74ca96c3137",
    danceability: 0.756,
    energy: 0.609,
    valence: 0.537,
    added_at: "2021-01-07T05:11:41Z",
    link: "https://open.spotify.com/track/5EYi2rH4LYs6M21ZLOyQTx",
    userID: "royclaud",
    genres: "bedroom pop,dutch pop,indie soul",
  },
  {
    id: "6rsuqpfWuNMpaektrYV906",
    name: "Imagine U",
    artists: "Omar Apollo",
    duration_ms: 189396,
    popularity: 36,
    preview_url: "https://p.scdn.co/mp3-preview/f2f17c05442962ee0fba6c3bf3b6e783708548df?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fabdc3643c3da196705f15aa",
    danceability: 0.956,
    energy: 0.366,
    valence: 0.919,
    added_at: "2021-01-07T05:11:39Z",
    link: "https://open.spotify.com/track/6rsuqpfWuNMpaektrYV906",
    userID: "royclaud",
    genres: "bedroom pop,bedroom soul",
  },
  {
    id: "3omXshBamrREltcf24gYDC",
    name: "First",
    artists: "Cold War Kids",
    duration_ms: 200360,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/619b9443b59edacddbf2e5ccbe394ac83ec36c05?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b1c18903012347d9991613da",
    danceability: 0.468,
    energy: 0.692,
    valence: 0.561,
    added_at: "2021-01-02T04:54:41Z",
    link: "https://open.spotify.com/track/3omXshBamrREltcf24gYDC",
    userID: "royclaud",
    genres: "indie rock,modern alternative rock,modern rock,piano rock,stomp and holler",
  },
  {
    id: "6ZpR2XFuQJSHAQwg9495KZ",
    name: "Mind (feat. Kai)",
    artists: "Jack Ü, Skrillex, Diplo, kai",
    duration_ms: 242068,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/0d83df9b54be8bf61c7b2fd7c40d23350c8e7cf3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0257fc4730e06c9ab20c1e073b",
    danceability: 0.502,
    energy: 0.777,
    valence: 0.356,
    added_at: "2021-01-02T04:52:42Z",
    link: "https://open.spotify.com/track/6ZpR2XFuQJSHAQwg9495KZ",
    userID: "royclaud",
    genres: "edm,electro house,pop dance,brostep,complextro,edm,electro,pop dance,dance pop,edm,electro house,moombahton,pop dance",
  },
  {
    id: "2lwwrWVKdf3LR9lbbhnr6R",
    name: "Float On",
    artists: "Modest Mouse",
    duration_ms: 208466,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/9241188a00b7ae5feb607bf10c21a067c83ed487?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02cc68329bfbf34037df965dc1",
    danceability: 0.649,
    energy: 0.888,
    valence: 0.553,
    added_at: "2021-01-02T04:52:38Z",
    link: "https://open.spotify.com/track/2lwwrWVKdf3LR9lbbhnr6R",
    userID: "royclaud",
    genres: "alternative rock,chamber pop,indie rock,indietronica,modern rock,washington indie",
  },
  {
    id: "5T38ywOoK6b29fpbTrhwx3",
    name: "This is Our Life",
    artists: "Des Rocs",
    duration_ms: 224306,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/1851efdb4a758596498582ae8347a3958cf806b1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d7e0ca6710c4fb56043e57c0",
    danceability: 0.367,
    energy: 0.877,
    valence: 0.395,
    added_at: "2021-01-02T03:26:26Z",
    link: "https://open.spotify.com/track/5T38ywOoK6b29fpbTrhwx3",
    userID: "royclaud",
    genres: "modern alternative rock,rebel blues",
  },
  {
    id: "30bqVoKjX479ab90a8Pafp",
    name: "Star Shopping",
    artists: "Lil Peep",
    duration_ms: 142000,
    popularity: 3,
    preview_url: "https://p.scdn.co/mp3-preview/d415dfca7346912ad65b904e06c8996a2ead8e06?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e813c020ab6245eae154ec16",
    danceability: 0.585,
    energy: 0.471,
    valence: 0.323,
    added_at: "2020-12-31T19:37:35Z",
    link: "https://open.spotify.com/track/30bqVoKjX479ab90a8Pafp",
    userID: "royclaud",
    genres: "cloud rap,emo rap,lgbtq+ hip hop",
  },
  {
    id: "6lnP51cMlqpZ8bwmyVlgvG",
    name: "She's A Beauty",
    artists: "The Tubes",
    duration_ms: 237240,
    popularity: 45,
    preview_url: "https://p.scdn.co/mp3-preview/747c09213b29073aaffa1ba12832966596afbd1e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d7506a0abc106ef5491e8796",
    danceability: 0.694,
    energy: 0.787,
    valence: 0.667,
    added_at: "2020-12-21T03:23:57Z",
    link: "https://open.spotify.com/track/6lnP51cMlqpZ8bwmyVlgvG",
    userID: "royclaud",
    genres: "album rock,new wave,synthpop,zolo",
  },
  {
    id: "5CFJRZRq6sdKKtRwNPWbYv",
    name: "Show Out (with Skepta & Pop Smoke)",
    artists: "Kid Cudi, Skepta, Pop Smoke",
    duration_ms: 174960,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/9709fa7d22b76e8b9be818cb64ce454c4da8ddaf?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026ff25c2481eeaf6e7284d461",
    danceability: 0.619,
    energy: 0.589,
    valence: 0.158,
    added_at: "2020-12-16T18:07:34Z",
    link: "https://open.spotify.com/track/5CFJRZRq6sdKKtRwNPWbYv",
    userID: "royclaud",
    genres: "hip hop,ohio hip hop,pop rap,rap,birmingham grime,grime,instrumental grime,uk hip hop,brooklyn drill,hip hop,rap",
  },
  {
    id: "2elA6JLRaQ6iB7hxiuTKN4",
    name: "I Tried",
    artists: "Bone Thugs-N-Harmony, Akon",
    duration_ms: 287480,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/afd394b8cde6dd64cbf9c9c6f36f40f18564bc3e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020c078eab10f525a0bd0eb6b3",
    danceability: 0.727,
    energy: 0.71,
    valence: 0.378,
    added_at: "2020-12-16T00:42:28Z",
    link: "https://open.spotify.com/track/2elA6JLRaQ6iB7hxiuTKN4",
    userID: "royclaud",
    genres: "gangster rap,hip hop,ohio hip hop,rap,dance pop",
  },
  {
    id: "4GXH9A6fzuE0MNtqtzjhLg",
    name: "My Life",
    artists: "The Game, Lil Wayne",
    duration_ms: 320893,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/8554b03982256007c2319843636484473915eddf?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02894bbc901fcca94a2e27729b",
    danceability: 0.673,
    energy: 0.766,
    valence: 0.382,
    added_at: "2020-12-16T00:32:36Z",
    link: "https://open.spotify.com/track/4GXH9A6fzuE0MNtqtzjhLg",
    userID: "royclaud",
    genres: "detroit hip hop,gangster rap,hip hop,pop rap,rap,southern hip hop,trap,hip hop,new orleans rap,pop rap,rap,trap",
  },
  {
    id: "0YImOCkIJ2PWhCXaURCZnY",
    name: "I Know What You Want (feat. Flipmode Squad)",
    artists: "Busta Rhymes, Mariah Carey, Flipmode Squad",
    duration_ms: 324306,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/f9c37797c6aaf348026d20b455a6c773a0b1d0bc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ac8e6d7dae57f0c1c87e230c",
    danceability: 0.648,
    energy: 0.759,
    valence: 0.518,
    added_at: "2020-12-16T00:26:48Z",
    link: "https://open.spotify.com/track/0YImOCkIJ2PWhCXaURCZnY",
    userID: "royclaud",
    genres: "east coast hip hop,hardcore hip hop,hip hop,pop rap,rap,dance pop,pop,urban contemporary,east coast hip hop",
  },
  {
    id: "0526DaOuV7mRlPKxhMSJKw",
    name: "My Collection",
    artists: "Future",
    duration_ms: 255556,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/b5c83bd0aad2078948d055a8310e6056cab111e9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027567ec7d3c07783e4f2111e0",
    danceability: 0.697,
    energy: 0.459,
    valence: 0.409,
    added_at: "2020-12-15T23:14:11Z",
    link: "https://open.spotify.com/track/0526DaOuV7mRlPKxhMSJKw",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap,southern hip hop,trap",
  },
  {
    id: "3mhOmh4tRKsMfnRmgZfeBm",
    name: "Far Behind",
    artists: "Candlebox",
    duration_ms: 299733,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/6c3ff3bac221e951b49fd2ec8957b303e2bf09d1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02dbb1223a6220632f14872c46",
    danceability: 0.552,
    energy: 0.645,
    valence: 0.588,
    added_at: "2020-12-03T18:14:34Z",
    link: "https://open.spotify.com/track/3mhOmh4tRKsMfnRmgZfeBm",
    userID: "royclaud",
    genres: "alternative metal,alternative rock,grunge,nu metal,post-grunge",
  },
  {
    id: "6n6CmyLTCIXJCPZnv7h2rn",
    name: "Accepting My Flaws",
    artists: "Future",
    duration_ms: 259070,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/a7ab7fbd31b5f0f3f5638ab247d9b37998cd5a50?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02935d8d5369bc55e74a39303e",
    danceability: 0.769,
    energy: 0.586,
    valence: 0.137,
    added_at: "2020-11-29T06:34:06Z",
    link: "https://open.spotify.com/track/6n6CmyLTCIXJCPZnv7h2rn",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap,southern hip hop,trap",
  },
  {
    id: "1m8WpLYXEiNVZchsWEcCSy",
    name: "Come Through and Chill (feat. J. Cole & Salaam Remi)",
    artists: "Miguel, J. Cole, Salaam Remi",
    duration_ms: 322000,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/87b0e9f913b83f97ba5618a281f65c35eea9b99f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f654e8e9ac3c24881239ab7c",
    danceability: 0.461,
    energy: 0.653,
    valence: 0.588,
    added_at: "2020-11-29T06:10:10Z",
    link: "https://open.spotify.com/track/1m8WpLYXEiNVZchsWEcCSy",
    userID: "royclaud",
    genres: "r&b,urban contemporary,conscious hip hop,hip hop,north carolina hip hop,rap,hip hop",
  },
  {
    id: "24YmWQgunJGAU8El3ndkyn",
    name: "In the Flesh?",
    artists: "Pink Floyd",
    duration_ms: 198221,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/576722e511c23475768a170de8b2ddb1d6eab920?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025d48e2f56d691f9a4e4b0bdf",
    danceability: 0.316,
    energy: 0.49,
    valence: 0.267,
    added_at: "2020-11-29T06:09:56Z",
    link: "https://open.spotify.com/track/24YmWQgunJGAU8El3ndkyn",
    userID: "royclaud",
    genres: "album rock,art rock,classic rock,progressive rock,psychedelic rock,rock,symphonic rock",
  },
  {
    id: "1snNAXmmPXCn0dkF9DaPWw",
    name: "You & Me - Flume Remix",
    artists: "Disclosure, Eliza Doolittle, Flume",
    duration_ms: 282812,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/e517e690f1499c386fb1a5e82061a69f7323b022?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027cc97670c72e565337cfb3e0",
    danceability: 0.593,
    energy: 0.392,
    valence: 0.25,
    added_at: "2020-11-28T01:22:06Z",
    link: "https://open.spotify.com/track/1snNAXmmPXCn0dkF9DaPWw",
    userID: "royclaud",
    genres: "edm,house,indietronica,uk dance,lilith,australian dance,australian electropop,australian indie,downtempo,edm,indietronica",
  },
  {
    id: "5SWnsxjhdcEDc7LJjq9UHk",
    name: "Runnin",
    artists: "21 Savage, Metro Boomin",
    duration_ms: 195906,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/743b07834a095402147d26a45f26688c77f1b6a3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02aa57907bf0cb2ca0c8cc74bc",
    danceability: 0.819,
    energy: 0.626,
    valence: 0.415,
    added_at: "2020-11-27T21:07:18Z",
    link: "https://open.spotify.com/track/5SWnsxjhdcEDc7LJjq9UHk",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap,rap",
  },
  {
    id: "5wHOClb8KQCOxn993cCU8X",
    name: "Hopeless Boy",
    artists: "King Lil G, David Ortiz",
    duration_ms: 193786,
    popularity: 0,
    preview_url: "null",
    image: "https://i.scdn.co/image/ab67616d00001e020f12ddac5743c874b230e2e7",
    danceability: 0.575,
    energy: 0.609,
    valence: 0.392,
    added_at: "2020-11-25T05:13:29Z",
    link: "https://open.spotify.com/track/5wHOClb8KQCOxn993cCU8X",
    userID: "royclaud",
    genres: "chicano rap,west coast rap",
  },
  {
    id: "5aJ1M43pinROpwaPnO8KRj",
    name: "Amor Tumbado",
    artists: "Natanael Cano",
    duration_ms: 221553,
    popularity: 77,
    preview_url: "https://p.scdn.co/mp3-preview/9c4d0416f5391c4bc165d9c13f62548d6767b3bc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a181306e44fc25b48f4a3e39",
    danceability: 0.576,
    energy: 0.715,
    valence: 0.497,
    added_at: "2020-11-24T23:04:52Z",
    link: "https://open.spotify.com/track/5aJ1M43pinROpwaPnO8KRj",
    userID: "royclaud",
    genres: "corrido,corridos tumbados,musica mexicana,sad sierreno,sierreno",
  },
  {
    id: "1v24T2ug4TlssYZvI3aL4O",
    name: "Lust For Life",
    artists: "Drake",
    duration_ms: 176179,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/f5cbb8b610fa4be4139331618a064c2fded70e78?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0254ef13c30a024a7f1ba79efd",
    danceability: 0.462,
    energy: 0.422,
    valence: 0.0853,
    added_at: "2020-11-24T17:48:16Z",
    link: "https://open.spotify.com/track/1v24T2ug4TlssYZvI3aL4O",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "1B0cZZLVgAxDYzMNnsZDuF",
    name: "Book Of Saturday",
    artists: "King Crimson",
    duration_ms: 175720,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/459bcab6e474228aeda1d335c69fde392d7a3965?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a3580595cf9ef6ef46ba1566",
    danceability: 0.332,
    energy: 0.0818,
    valence: 0.202,
    added_at: "2020-11-24T01:23:47Z",
    link: "https://open.spotify.com/track/1B0cZZLVgAxDYzMNnsZDuF",
    userID: "royclaud",
    genres: "art rock,instrumental rock,jazz rock,progressive rock,psychedelic rock,symphonic rock,zolo",
  },
  {
    id: "0O7lENhqOySbsL743G7PqD",
    name: "Vermilion, Pt. 2",
    artists: "Slipknot",
    duration_ms: 224320,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/b53055a34dd79946b2c1512e473c3b02d6e0c736?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026b3463e7160d333ada4b175a",
    danceability: 0.589,
    energy: 0.442,
    valence: 0.0904,
    added_at: "2020-11-22T00:43:07Z",
    link: "https://open.spotify.com/track/0O7lENhqOySbsL743G7PqD",
    userID: "royclaud",
    genres: "alternative metal,nu metal,rap metal,rock",
  },
  {
    id: "2FUNBaa5DwItJtYEBgAblU",
    name: "monster",
    artists: "21 Savage",
    duration_ms: 233039,
    popularity: 54,
    preview_url: "https://p.scdn.co/mp3-preview/0e2fd70be869187962a8b35940d046d830a5e985?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02280689ecc5e4b2038bb5e4bd",
    danceability: 0.89,
    energy: 0.607,
    valence: 0.224,
    added_at: "2020-11-18T07:54:42Z",
    link: "https://open.spotify.com/track/2FUNBaa5DwItJtYEBgAblU",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap",
  },
  {
    id: "17UwOJOXHbggzJfAreIgII",
    name: "HEARTEATER",
    artists: "XXXTENTACION",
    duration_ms: 136613,
    popularity: 48,
    preview_url: "https://p.scdn.co/mp3-preview/f97d6925ffe7f3ce8d8324525784b661ced02d2e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026e6a540c318eeb0020a74642",
    danceability: 0.781,
    energy: 0.518,
    valence: 0.61,
    added_at: "2020-11-18T03:23:18Z",
    link: "https://open.spotify.com/track/17UwOJOXHbggzJfAreIgII",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "1lCRw5FEZ1gPDNPzy1K4zW",
    name: "We Are The Champions - Remastered 2011",
    artists: "Queen",
    duration_ms: 179200,
    popularity: 73,
    preview_url: "https://p.scdn.co/mp3-preview/53f9595b73edaa7af3eac56494b0cad5221e8563?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021f7077ae1018b5fbab08dfa8",
    danceability: 0.268,
    energy: 0.459,
    valence: 0.175,
    added_at: "2020-11-15T21:53:33Z",
    link: "https://open.spotify.com/track/1lCRw5FEZ1gPDNPzy1K4zW",
    userID: "royclaud",
    genres: "classic rock,glam rock,rock",
  },
  {
    id: "3E1tfdNkciVMmgA4OQNSPQ",
    name: "Drugs & Honey",
    artists: "Paper Jackets",
    duration_ms: 199636,
    popularity: 2,
    preview_url: "https://p.scdn.co/mp3-preview/ba429ac9b8cba23330177a53ab30418a219885c8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b4ecb2e401ff9e9e3a505fea",
    danceability: 0.722,
    energy: 0.504,
    valence: 0.474,
    added_at: "2020-11-15T21:42:16Z",
    link: "https://open.spotify.com/track/3E1tfdNkciVMmgA4OQNSPQ",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "00w2h9uySGZAaOApCbLDIW",
    name: "Paradise",
    artists: "ANTIBOY",
    duration_ms: 217714,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/6d33e53b18a3bffb54e4f28b6bbc93e048221ffb?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e85307e8d40d6c0b2c96848a",
    danceability: 0.557,
    energy: 0.743,
    valence: 0.15,
    added_at: "2020-11-15T21:41:15Z",
    link: "https://open.spotify.com/track/00w2h9uySGZAaOApCbLDIW",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "3DBVtnyVhc0WGT51K28TC9",
    name: "Movin' Out (Anthony's Song)",
    artists: "Billy Joel",
    duration_ms: 207906,
    popularity: 42,
    preview_url: "https://p.scdn.co/mp3-preview/1d144f45b1a4cabc2ad71c0b609707b5977dc642?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02649d4f282653ab8be56f447e",
    danceability: 0.675,
    energy: 0.811,
    valence: 0.845,
    added_at: "2020-11-15T21:40:33Z",
    link: "https://open.spotify.com/track/3DBVtnyVhc0WGT51K28TC9",
    userID: "royclaud",
    genres: "album rock,classic rock,mellow gold,piano rock,rock,singer-songwriter,soft rock",
  },
  {
    id: "1G391cbiT3v3Cywg8T7DM1",
    name: "Scar Tissue",
    artists: "Red Hot Chili Peppers",
    duration_ms: 215906,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/5aa4aff376bccc73f7fa7df2954649e30a56c4b1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0294d08ab63e57b0cae74e8595",
    danceability: 0.595,
    energy: 0.717,
    valence: 0.547,
    added_at: "2020-11-15T21:40:28Z",
    link: "https://open.spotify.com/track/1G391cbiT3v3Cywg8T7DM1",
    userID: "royclaud",
    genres: "alternative rock,funk metal,funk rock,permanent wave,rock",
  },
  {
    id: "5ceGigL7CZQ3Ih6W8SIbv8",
    name: "Blurry",
    artists: "Puddle Of Mudd",
    duration_ms: 304000,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/299268670b4fcdd957c222ce093fffd76832a5a5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ca861b77aab32e0ca69e2c34",
    danceability: 0.431,
    energy: 0.94,
    valence: 0.484,
    added_at: "2020-11-15T21:30:06Z",
    link: "https://open.spotify.com/track/5ceGigL7CZQ3Ih6W8SIbv8",
    userID: "royclaud",
    genres: "alternative metal,nu metal,post-grunge",
  },
  {
    id: "78PKCefXwDLbl4FVO1Pjzh",
    name: "Dreamer",
    artists: "Ozzy Osbourne",
    duration_ms: 284906,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/7142ca275b97edfe9a7e83a973707edb71968c60?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02962c158ac47845e7051fae4e",
    danceability: 0.493,
    energy: 0.636,
    valence: 0.279,
    added_at: "2020-11-15T21:29:13Z",
    link: "https://open.spotify.com/track/78PKCefXwDLbl4FVO1Pjzh",
    userID: "royclaud",
    genres: "album rock,alternative metal,birmingham metal,classic rock,glam metal,hard rock,metal,rock",
  },
  {
    id: "7cDxjUnMitNKQC5c8RQUko",
    name: "Black Magic Woman / Gypsy Queen",
    artists: "Santana",
    duration_ms: 319773,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/5da09e790bdbe9fbcca6c848d74a931a8e2d3004?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f1594eeff2ea90e0f3873919",
    danceability: 0.464,
    energy: 0.462,
    valence: 0.557,
    added_at: "2020-11-15T21:28:39Z",
    link: "https://open.spotify.com/track/7cDxjUnMitNKQC5c8RQUko",
    userID: "royclaud",
    genres: "blues rock,classic rock,mexican classic rock",
  },
  {
    id: "5wQnmLuC1W7ATsArWACrgW",
    name: "Welcome to the Black Parade",
    artists: "My Chemical Romance",
    duration_ms: 311106,
    popularity: 77,
    preview_url: "https://p.scdn.co/mp3-preview/1434f7350357c1d6a7eb7f5a84dd6d198a252ed1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0217f77fab7e8f18d5f9fee4a1",
    danceability: 0.217,
    energy: 0.905,
    valence: 0.236,
    added_at: "2020-11-15T21:28:29Z",
    link: "https://open.spotify.com/track/5wQnmLuC1W7ATsArWACrgW",
    userID: "royclaud",
    genres: "emo,modern rock,pop punk,pov: indie,rock",
  },
  {
    id: "0wvIGFIgbyz4JNwQhZgTv2",
    name: "Rooster (2022 Remaster)",
    artists: "Alice In Chains",
    duration_ms: 374333,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/a3f28e30b42a5f4ba83db1a56d9c781b2ff35d27?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021cd131e5e9ad887ad8c1ecb7",
    danceability: 0.263,
    energy: 0.562,
    valence: 0.213,
    added_at: "2020-11-15T21:28:09Z",
    link: "https://open.spotify.com/track/0wvIGFIgbyz4JNwQhZgTv2",
    userID: "royclaud",
    genres: "alternative metal,alternative rock,grunge,hard rock,nu metal,rock",
  },
  {
    id: "1f2V8U1BiWaC9aJWmpOARe",
    name: "By the Way",
    artists: "Red Hot Chili Peppers",
    duration_ms: 216933,
    popularity: 75,
    preview_url: "https://p.scdn.co/mp3-preview/9caddc9fdc2951fcdfe1f3fbda147c5d1871501b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02de1af2785a83cc660155a0c4",
    danceability: 0.451,
    energy: 0.97,
    valence: 0.198,
    added_at: "2020-11-15T21:26:26Z",
    link: "https://open.spotify.com/track/1f2V8U1BiWaC9aJWmpOARe",
    userID: "royclaud",
    genres: "alternative rock,funk metal,funk rock,permanent wave,rock",
  },
  {
    id: "1VGzxJnVQND7Cg5H5wGj14",
    name: "YEAH RIGHT",
    artists: "Joji",
    duration_ms: 174357,
    popularity: 16,
    preview_url: "https://p.scdn.co/mp3-preview/713e9b754b76996689a474d3476819fb4ad7ada8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0260ba1d6104d0475c7555a6b2",
    danceability: 0.672,
    energy: 0.588,
    valence: 0.204,
    added_at: "2020-11-14T23:59:24Z",
    link: "https://open.spotify.com/track/1VGzxJnVQND7Cg5H5wGj14",
    userID: "royclaud",
    genres: "viral pop",
  },
  {
    id: "4S2uhQE8L9V6p7rj7SiauJ",
    name: "Suicidal (feat. Juice WRLD) - Remix",
    artists: "YNW Melly, Juice WRLD",
    duration_ms: 225857,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/049ce33702cfee3908e3626e9b8a7422d6cfefe3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b458693b51ec72c6a1e9c8e4",
    danceability: 0.655,
    energy: 0.441,
    valence: 0.273,
    added_at: "2020-11-14T23:59:14Z",
    link: "https://open.spotify.com/track/4S2uhQE8L9V6p7rj7SiauJ",
    userID: "royclaud",
    genres: "melodic rap,rap,chicago rap,melodic rap,rap",
  },
  {
    id: "10yCqBhJHRSApHAOcI5RtM",
    name: "Sometimes Dancin'",
    artists: "Brownstone",
    duration_ms: 301933,
    popularity: 28,
    preview_url: "https://p.scdn.co/mp3-preview/503dd54aa15b8e54ba1e4162dbdd485732beb65a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0209f1bb99a848c77c21f4f121",
    danceability: 0.662,
    energy: 0.573,
    valence: 0.782,
    added_at: "2020-11-14T17:31:37Z",
    link: "https://open.spotify.com/track/10yCqBhJHRSApHAOcI5RtM",
    userID: "royclaud",
    genres: "contemporary r&b,girl group,r&b",
  },
  {
    id: "5hIg7J1i50XLG7bPKQM6O5",
    name: "Rollinem 7's",
    artists: "N.E.R.D, André 3000, Pharrell Williams",
    duration_ms: 309453,
    popularity: 37,
    preview_url: "https://p.scdn.co/mp3-preview/8c8b5fc7a3e979015740698da653b812851343cd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02be23f47224937c9b57a004b2",
    danceability: 0.488,
    energy: 0.684,
    valence: 0.284,
    added_at: "2020-11-13T18:56:55Z",
    link: "https://open.spotify.com/track/5hIg7J1i50XLG7bPKQM6O5",
    userID: "royclaud",
    genres: "hip hop,virginia hip hop,atl hip hop,dirty south rap,hip hop,southern hip hop,dance pop,pop",
  },
  {
    id: "2fjhrhx6b4B6GSaFhTkRkK",
    name: "Blossom",
    artists: "Coletta",
    duration_ms: 221687,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/e713a393fbdd242aec3b0a9ce1c59de321d94bec?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0261f4585758118c430cd58745",
    danceability: 0.512,
    energy: 0.788,
    valence: 0.13,
    added_at: "2020-11-12T18:46:25Z",
    link: "https://open.spotify.com/track/2fjhrhx6b4B6GSaFhTkRkK",
    userID: "royclaud",
    genres: "progressive post-hardcore,swancore",
  },
  {
    id: "2rE9fz2WTQ2PFhTHeXSwTf",
    name: "Mortal Projections",
    artists: "Djo",
    duration_ms: 188095,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/03cc5ed4213cff051afcf78e0ad23c81445ac4da?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021d65b13c3badde0a271957a6",
    danceability: 0.573,
    energy: 0.433,
    valence: 0.451,
    added_at: "2020-11-10T22:11:13Z",
    link: "https://open.spotify.com/track/2rE9fz2WTQ2PFhTHeXSwTf",
    userID: "royclaud",
    genres: "pov: indie,psychedelic pop",
  },
  {
    id: "0fAehaneUJdlSlWXYe1KZp",
    name: "Sunday (feat. Frank Ocean)",
    artists: "Earl Sweatshirt, Frank Ocean",
    duration_ms: 205800,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/c61176b580b99361709fcb7557890ee7684f181c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0246f9bf1154c7205b0e8b1667",
    danceability: 0.716,
    energy: 0.568,
    valence: 0.905,
    added_at: "2020-11-08T23:35:07Z",
    link: "https://open.spotify.com/track/0fAehaneUJdlSlWXYe1KZp",
    userID: "royclaud",
    genres: "alternative hip hop,drumless hip hop,experimental hip hop,hip hop,rap,underground hip hop,lgbtq+ hip hop,neo soul",
  },
  {
    id: "6MmtDonkpvSoRx9ACwrGDu",
    name: "Brotha Man (feat. French Montana)",
    artists: "A$AP Rocky, French Montana",
    duration_ms: 216080,
    popularity: 47,
    preview_url: "https://p.scdn.co/mp3-preview/64c4a17f373766c529427b34af12a70c09f2d93b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029feadc48ab0661e9b3a9170b",
    danceability: 0.57,
    energy: 0.398,
    valence: 0.437,
    added_at: "2020-11-08T23:34:51Z",
    link: "https://open.spotify.com/track/6MmtDonkpvSoRx9ACwrGDu",
    userID: "royclaud",
    genres: "east coast hip hop,hip hop,rap,hip hop,pop rap,rap,southern hip hop,trap",
  },
  {
    id: "2MF4HtZHBoUliOi9nOAbS0",
    name: "Purity (feat. Frank Ocean)",
    artists: "A$AP Rocky, Frank Ocean",
    duration_ms: 262360,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/c0862a1da7b10728ac3bdcbc320da03113f19ea3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029feadc48ab0661e9b3a9170b",
    danceability: 0.593,
    energy: 0.37,
    valence: 0.312,
    added_at: "2020-11-08T23:34:39Z",
    link: "https://open.spotify.com/track/2MF4HtZHBoUliOi9nOAbS0",
    userID: "royclaud",
    genres: "east coast hip hop,hip hop,rap,lgbtq+ hip hop,neo soul",
  },
  {
    id: "7KZ5MMVgBVox9ycroB2UrI",
    name: "CAROUSEL",
    artists: "Travis Scott",
    duration_ms: 180182,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/1d0d1d66e1fff4aefd1e7a3cd2a79337af24a9e8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02072e9faef2ef7b6db63834a3",
    danceability: 0.428,
    energy: 0.403,
    valence: 0.133,
    added_at: "2020-11-08T23:34:24Z",
    link: "https://open.spotify.com/track/7KZ5MMVgBVox9ycroB2UrI",
    userID: "royclaud",
    genres: "rap,slap house",
  },
  {
    id: "6kl1qtQXQsFiIWRBK24Cfp",
    name: "RAF (feat. A$AP Rocky, Playboi Carti, Quavo, Lil Uzi Vert & Frank Ocean)",
    artists: "A$AP Mob, A$AP Rocky, Playboi Carti, Quavo, Lil Uzi Vert, Frank Ocean",
    duration_ms: 255413,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/8dfe178261105e79fd86a1d559bc89c5d2dc9c42?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021b1ea8257496228138609eb6",
    danceability: 0.848,
    energy: 0.663,
    valence: 0.333,
    added_at: "2020-11-08T22:49:19Z",
    link: "https://open.spotify.com/track/6kl1qtQXQsFiIWRBK24Cfp",
    userID: "royclaud",
    genres: "hip hop,rap,southern hip hop,trap,east coast hip hop,hip hop,rap,atl hip hop,plugg,pluggnb,rage rap,rap,atl hip hop,melodic rap,rap,trap,hip hop,melodic rap,philly rap,rage rap,rap,trap,lgbtq+ hip hop,neo soul",
  },
  {
    id: "1BViPjTT585XAhkUUrkts0",
    name: "Seigfried",
    artists: "Frank Ocean",
    duration_ms: 334570,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/a96e5789373ae4ca0e61ade983d6acc3deab7513?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c5649add07ed3720be9d5526",
    danceability: 0.377,
    energy: 0.253,
    valence: 0.367,
    added_at: "2020-11-08T22:49:12Z",
    link: "https://open.spotify.com/track/1BViPjTT585XAhkUUrkts0",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "2dEgwtRjYl6TJkIwjRwn6z",
    name: "Heaven",
    artists: "Warrant",
    duration_ms: 236866,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/9cfd3358d96fb45ceffef8f7dd200173cdebe0ff?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027b3295756d991b0329acf882",
    danceability: 0.461,
    energy: 0.488,
    valence: 0.33,
    added_at: "2020-11-01T20:06:09Z",
    link: "https://open.spotify.com/track/2dEgwtRjYl6TJkIwjRwn6z",
    userID: "royclaud",
    genres: "album rock,glam metal,hard rock",
  },
  {
    id: "7Hr1Ignop7cymbE3FbOtXa",
    name: "So Far Away",
    artists: "Avenged Sevenfold",
    duration_ms: 326813,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/b0831fc004d8c3c2586d98f0d2445fbf39444f9a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c34064a3c5e4a25892a091f3",
    danceability: 0.286,
    energy: 0.703,
    valence: 0.259,
    added_at: "2020-11-01T19:49:44Z",
    link: "https://open.spotify.com/track/7Hr1Ignop7cymbE3FbOtXa",
    userID: "royclaud",
    genres: "alternative metal",
  },
  {
    id: "7vKzzxdQevFwflwfaz0nun",
    name: "1942",
    artists: "June3rd",
    duration_ms: 158048,
    popularity: 16,
    preview_url: "https://p.scdn.co/mp3-preview/685841f9f923fd90bebe3f2a70b4fd4e975aa064?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02552147c659632e438fc04a4f",
    danceability: 0.849,
    energy: 0.62,
    valence: 0.715,
    added_at: "2020-10-29T23:16:06Z",
    link: "https://open.spotify.com/track/7vKzzxdQevFwflwfaz0nun",
    userID: "royclaud",
    genres: "pop r&b,trap soul",
  },
  {
    id: "6Oe5tmI1bfguCp6dKHbSj6",
    name: "Paranoia",
    artists: "Chance the Rapper, Nosaj Thing",
    duration_ms: 275281,
    popularity: 47,
    preview_url: "https://p.scdn.co/mp3-preview/58c76a52da9487761b20a536c2e728d231ea5baa?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d95ab48a8a9de3c4a2cbfe80",
    danceability: 0.472,
    energy: 0.282,
    valence: 0.123,
    added_at: "2020-10-29T18:33:36Z",
    link: "https://open.spotify.com/track/6Oe5tmI1bfguCp6dKHbSj6",
    userID: "royclaud",
    genres: "chicago rap,conscious hip hop,hip hop,pop rap,rap,trap,chillwave,electronica,future garage,glitch hop,indietronica,intelligent dance music,wonky",
  },
  {
    id: "7wvtiQ3o8ELEmk2NjSSPpX",
    name: "Obstacle 1",
    artists: "Interpol",
    duration_ms: 251546,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/4f46ed4c631e8de960e2179c7578162d5f822783?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f7ae8ba8b335b781af9bf9d4",
    danceability: 0.371,
    energy: 0.941,
    valence: 0.386,
    added_at: "2020-10-29T16:17:32Z",
    link: "https://open.spotify.com/track/7wvtiQ3o8ELEmk2NjSSPpX",
    userID: "royclaud",
    genres: "alternative dance,alternative rock,indie rock,indietronica,modern rock,rock",
  },
  {
    id: "4iYLm12jWtCXAliKWKULeB",
    name: "Past Lives",
    artists: "DIIV",
    duration_ms: 141626,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/d26e35ea8019c358351ac3adf28e2062bff97f62?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027bc6a0c2b8d9393a2dd80cb7",
    danceability: 0.274,
    energy: 0.747,
    valence: 0.552,
    added_at: "2020-10-29T16:17:24Z",
    link: "https://open.spotify.com/track/4iYLm12jWtCXAliKWKULeB",
    userID: "royclaud",
    genres: "brooklyn indie,dream pop,dreamo,indie rock,indie surf,modern dream pop,neo-psychedelic,new york shoegaze,nu gaze,shoegaze",
  },
  {
    id: "5UMC0HkqpmwarA1HZ7z3Ck",
    name: "Pachuca Sunrise",
    artists: "Minus the Bear",
    duration_ms: 215373,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/999ea1283d65f5bec0251d6c4f2492eca79cd922?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02aff6b35992c7cb120f11a40d",
    danceability: 0.469,
    energy: 0.877,
    valence: 0.71,
    added_at: "2020-10-29T16:16:21Z",
    link: "https://open.spotify.com/track/5UMC0HkqpmwarA1HZ7z3Ck",
    userID: "royclaud",
    genres: "indie rock,math pop,post-hardcore,seattle indie",
  },
  {
    id: "7e18G6nh9PyfZuLxzR4QUn",
    name: "Do You Love Someone",
    artists: "GROUPLOVE",
    duration_ms: 231360,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/25f3fe5e27bf54cc6d7401dea224a8c30409b98e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02549455be004bde9253eb13eb",
    danceability: 0.521,
    energy: 0.879,
    valence: 0.714,
    added_at: "2020-10-29T16:16:16Z",
    link: "https://open.spotify.com/track/7e18G6nh9PyfZuLxzR4QUn",
    userID: "royclaud",
    genres: "indie rock,la indie,modern alternative rock,modern rock,pov: indie",
  },
  {
    id: "0s995gCthqnYJCWGvfKpNt",
    name: "One Bourbon, One Scotch, One Beer",
    artists: "George Thorogood & The Destroyers",
    duration_ms: 506720,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/9cdc348ae00ceda9889f60ddab7ce3c4c5a5116d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029fcd6ab029561198ac8b42bd",
    danceability: 0.552,
    energy: 0.643,
    valence: 0.763,
    added_at: "2020-10-29T16:16:03Z",
    link: "https://open.spotify.com/track/0s995gCthqnYJCWGvfKpNt",
    userID: "royclaud",
    genres: "album rock,blues rock,classic rock,country rock,hard rock,heartland rock,mellow gold,rock",
  },
  {
    id: "3pCc0a00cKo9o1qCsNDgiP",
    name: "Clean Sheets",
    artists: "Descendents",
    duration_ms: 193800,
    popularity: 19,
    preview_url: "https://p.scdn.co/mp3-preview/c5cd04db507fb808076c2936d0b36374d9be6577?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027cefde036ac8c88b3bd3aeef",
    danceability: 0.273,
    energy: 0.801,
    valence: 0.677,
    added_at: "2020-10-29T16:15:47Z",
    link: "https://open.spotify.com/track/3pCc0a00cKo9o1qCsNDgiP",
    userID: "royclaud",
    genres: "hardcore punk,punk,skate punk",
  },
  {
    id: "7IiwFMWe2att4K5dZx06PO",
    name: "Smoove",
    artists: "Zelliack",
    duration_ms: 247665,
    popularity: 22,
    preview_url: "https://p.scdn.co/mp3-preview/6560ee1301db39034d21a018e7cd8e95fcf8e645?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02044fd067d90ff6e1d8e97106",
    danceability: 0.46,
    energy: 0.736,
    valence: 0.507,
    added_at: "2020-10-29T16:15:43Z",
    link: "https://open.spotify.com/track/7IiwFMWe2att4K5dZx06PO",
    userID: "royclaud",
    genres: "fort worth indie,progressive post-hardcore",
  },
  {
    id: "2g43X9SyvLvxDGfHg1hFsa",
    name: "Circadian Deity",
    artists: "Narco Debut",
    duration_ms: 246853,
    popularity: 26,
    preview_url: "https://p.scdn.co/mp3-preview/45938fdde5ff9d255a1f3276f1c3fe4380c46b0a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029e322b926f2541d83cd9fa00",
    danceability: 0.326,
    energy: 0.737,
    valence: 0.218,
    added_at: "2020-10-29T16:15:26Z",
    link: "https://open.spotify.com/track/2g43X9SyvLvxDGfHg1hFsa",
    userID: "royclaud",
    genres: "progressive post-hardcore",
  },
  {
    id: "4ZYS7Afbj07SLNgc9VQIgS",
    name: "Old Flames",
    artists: "Coheed and Cambria",
    duration_ms: 350520,
    popularity: 13,
    preview_url: "https://p.scdn.co/mp3-preview/193c3c0070a496c40faee4450acd94b743446103?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022ca86a6e55fd474a41f53382",
    danceability: 0.442,
    energy: 0.897,
    valence: 0.174,
    added_at: "2020-10-29T16:15:19Z",
    link: "https://open.spotify.com/track/4ZYS7Afbj07SLNgc9VQIgS",
    userID: "royclaud",
    genres: "modern alternative rock",
  },
  {
    id: "2q6YXRcGHRM25TPrNfhRfH",
    name: "A Clean Break (Let's Work) - Live at WCOZ, Massachusetts; 2004 Remaster",
    artists: "Talking Heads",
    duration_ms: 305000,
    popularity: 38,
    preview_url: "https://p.scdn.co/mp3-preview/f1f79b8b170a69bce77c4a18c32c2cd822c3a213?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029960508d99ed0825d33e9ea8",
    danceability: 0.788,
    energy: 0.799,
    valence: 0.898,
    added_at: "2020-10-29T16:15:12Z",
    link: "https://open.spotify.com/track/2q6YXRcGHRM25TPrNfhRfH",
    userID: "royclaud",
    genres: "alternative rock,art punk,dance rock,funk rock,new wave,permanent wave,post-punk,rock,zolo",
  },
  {
    id: "0Jixcs5VNkKf2Sfrzm4rQm",
    name: "Not Done",
    artists: "Bloody Knees",
    duration_ms: 239602,
    popularity: 13,
    preview_url: "https://p.scdn.co/mp3-preview/94dfd23ce7e7a30fd1428cb28327b2a5f65e3242?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02623fe1477186f84d2e87dd06",
    danceability: 0.401,
    energy: 0.827,
    valence: 0.324,
    added_at: "2020-10-29T16:15:04Z",
    link: "https://open.spotify.com/track/0Jixcs5VNkKf2Sfrzm4rQm",
    userID: "royclaud",
    genres: "cambridgeshire indie,english indie rock,post-grunge",
  },
  {
    id: "2wn4We8TCkoejQdO3m5irm",
    name: "Where Are the Birds",
    artists: "Good Tiger",
    duration_ms: 211560,
    popularity: 39,
    preview_url: "https://p.scdn.co/mp3-preview/a3f3e72495f40665bb0336ad26928648a8d609ec?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0291a567aaa648854deac83866",
    danceability: 0.449,
    energy: 0.965,
    valence: 0.278,
    added_at: "2020-10-29T16:15:00Z",
    link: "https://open.spotify.com/track/2wn4We8TCkoejQdO3m5irm",
    userID: "royclaud",
    genres: "djent,progressive post-hardcore",
  },
  {
    id: "5EilUTEDk4Z2h3uANZPTO5",
    name: "Save a Little",
    artists: "Shordie Shordie",
    duration_ms: 192047,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/aa4d5706b67959403d967637c3c6d15d776c27e5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a31fef2ddb5fddeb37ae71bc",
    danceability: 0.852,
    energy: 0.55,
    valence: 0.765,
    added_at: "2020-10-28T00:00:25Z",
    link: "https://open.spotify.com/track/5EilUTEDk4Z2h3uANZPTO5",
    userID: "royclaud",
    genres: "baltimore hip hop,cali rap",
  },
  {
    id: "0c03Msz0N2XIiBEpwbdlGd",
    name: "Cupid's Curse (feat. Kehlani)",
    artists: "Phora, Kehlani",
    duration_ms: 212801,
    popularity: 40,
    preview_url: "https://p.scdn.co/mp3-preview/007a9dc95dd6840bb524bcbf4f68a4a57f0d396e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025151a51e71ca211330a7fb77",
    danceability: 0.685,
    energy: 0.4,
    valence: 0.543,
    added_at: "2020-10-24T21:37:10Z",
    link: "https://open.spotify.com/track/0c03Msz0N2XIiBEpwbdlGd",
    userID: "royclaud",
    genres: "deep underground hip hop,oc rap,pop,r&b,rap",
  },
  {
    id: "2tznHmp70DxMyr2XhWLOW0",
    name: "Cigarette Daydreams",
    artists: "Cage The Elephant",
    duration_ms: 208760,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/a7a72315d777f4ed078d955238f9db158c915fc1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fb1cb900d28642e668d77b12",
    danceability: 0.636,
    energy: 0.676,
    valence: 0.273,
    added_at: "2020-10-24T15:22:55Z",
    link: "https://open.spotify.com/track/2tznHmp70DxMyr2XhWLOW0",
    userID: "royclaud",
    genres: "modern alternative rock,modern rock,pov: indie,punk blues,rock",
  },
  {
    id: "5pP5pJ0ieXRn3N7dzHARov",
    name: "Up (feat. 6lack)",
    artists: "DeJ Loaf, 6LACK",
    duration_ms: 206941,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/9951b9a47810bd3937e8413f1f492788d28a3d93?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0278c68ae9564cffd523d648e3",
    danceability: 0.447,
    energy: 0.537,
    valence: 0.513,
    added_at: "2020-10-24T04:07:20Z",
    link: "https://open.spotify.com/track/5pP5pJ0ieXRn3N7dzHARov",
    userID: "royclaud",
    genres: "detroit hip hop,r&b,trap,trap queen,atl hip hop,melodic rap,r&b,rap,trap",
  },
  {
    id: "3ZFTkvIE7kyPt6Nu3PEa7V",
    name: "Hips Don't Lie (feat. Wyclef Jean)",
    artists: "Shakira, Wyclef Jean",
    duration_ms: 218093,
    popularity: 85,
    preview_url: "https://p.scdn.co/mp3-preview/374b492571c9ba59c2c4b455ab79ee7501adab93?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0227ddd747545c0d0cfe7595fa",
    danceability: 0.778,
    energy: 0.824,
    valence: 0.758,
    added_at: "2020-10-22T17:10:43Z",
    link: "https://open.spotify.com/track/3ZFTkvIE7kyPt6Nu3PEa7V",
    userID: "royclaud",
    genres: "colombian pop,dance pop,latin pop,pop,rap kreyol",
  },
  {
    id: "17HJffxYPB45erQxzYCS4I",
    name: "Turn Tablez",
    artists: "Shea Abshier & The Nighthowlers",
    duration_ms: 264800,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/3bf22f5e4262f896af0450091af7325c43d64398?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029ac492ecf29daff41931fc73",
    danceability: 0.411,
    energy: 0.914,
    valence: 0.158,
    added_at: "2020-10-19T03:58:39Z",
    link: "https://open.spotify.com/track/17HJffxYPB45erQxzYCS4I",
    userID: "royclaud",
    genres: "classic texas country,red dirt",
  },
  {
    id: "2hiuiI3ac0I5kJWtkeGHEL",
    name: "I Can Tell",
    artists: "Travis Scott",
    duration_ms: 235533,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/daae5c6bffc5bf5a407041deb207bba4da5b4f9b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026cfd9a7353f98f5165ea6160",
    danceability: 0.695,
    energy: 0.8,
    valence: 0.0635,
    added_at: "2020-10-19T01:43:11Z",
    link: "https://open.spotify.com/track/2hiuiI3ac0I5kJWtkeGHEL",
    userID: "royclaud",
    genres: "rap,slap house",
  },
  {
    id: "6SMrWEWukd0DWh5iHGWgks",
    name: "Dead Wrong (feat. Eminem) - 2007 Remaster",
    artists: "The Notorious B.I.G., Eminem",
    duration_ms: 297226,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/9cd2a2015cb4cb481b8cbfcbcd4d4c234971d56c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02639fea4197e676cfe9512e04",
    danceability: 0.648,
    energy: 0.895,
    valence: 0.817,
    added_at: "2020-09-23T05:53:20Z",
    link: "https://open.spotify.com/track/6SMrWEWukd0DWh5iHGWgks",
    userID: "royclaud",
    genres: "east coast hip hop,gangster rap,hardcore hip hop,hip hop,rap,detroit hip hop,hip hop,rap",
  },
  {
    id: "6XyxCBp6x3jvtxXvMN5sAA",
    name: "1539 N. Calvert",
    artists: "JPEGMAFIA",
    duration_ms: 157258,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/e6058c95093447d7c569800a27707db4d1e310f8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f8aaeba5e6dee1745371e822",
    danceability: 0.863,
    energy: 0.717,
    valence: 0.357,
    added_at: "2020-09-23T05:53:03Z",
    link: "https://open.spotify.com/track/6XyxCBp6x3jvtxXvMN5sAA",
    userID: "royclaud",
    genres: "alternative hip hop,experimental hip hop,industrial hip hop",
  },
  {
    id: "2K2M0TcglCRLLpFOzKeFZA",
    name: "Sunshine Of Your Love",
    artists: "Cream",
    duration_ms: 250400,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/e860117e795dc984f43d781541e932c16676f986?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02db1fb2dede292908e86d94d7",
    danceability: 0.683,
    energy: 0.594,
    valence: 0.734,
    added_at: "2020-09-23T05:52:40Z",
    link: "https://open.spotify.com/track/2K2M0TcglCRLLpFOzKeFZA",
    userID: "royclaud",
    genres: "album rock,blues rock,british blues,classic rock,folk rock,hard rock,psychedelic rock,rock,singer-songwriter,supergroup",
  },
  {
    id: "0zbu6qCs5kqT8q3Zo0QWym",
    name: "Ronnie Drake (feat. SZA)",
    artists: "Isaiah Rashad, SZA",
    duration_ms: 198298,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/4a27863c1891a97c000f00de0b116f9f4c79abd1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02986cd6b330f7405b17a604f4",
    danceability: 0.482,
    energy: 0.779,
    valence: 0.835,
    added_at: "2020-09-23T05:52:05Z",
    link: "https://open.spotify.com/track/0zbu6qCs5kqT8q3Zo0QWym",
    userID: "royclaud",
    genres: "hip hop,rap,tennessee hip hop,underground hip hop,pop,r&b,rap",
  },
  {
    id: "64XSAtXpCJUmadm3fkpH4z",
    name: "Back 2 Good",
    artists: "Matchbox Twenty",
    duration_ms: 340173,
    popularity: 54,
    preview_url: "https://p.scdn.co/mp3-preview/a1c616634983dd028a76fa9ab3e3d4f829c63622?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0223dbfb8b3b1be429587f5380",
    danceability: 0.559,
    energy: 0.504,
    valence: 0.479,
    added_at: "2020-09-23T05:51:45Z",
    link: "https://open.spotify.com/track/64XSAtXpCJUmadm3fkpH4z",
    userID: "royclaud",
    genres: "neo mellow,pop rock,post-grunge",
  },
  {
    id: "789obnYCXbfpF19LWBYL0Q",
    name: "Deebo",
    artists: "The Underachievers",
    duration_ms: 167045,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/c7d51f1f1065ef66af39fa986243d9768df20a6b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e11f400b4ba9622f90c6b641",
    danceability: 0.704,
    energy: 0.889,
    valence: 0.228,
    added_at: "2020-09-23T05:51:25Z",
    link: "https://open.spotify.com/track/789obnYCXbfpF19LWBYL0Q",
    userID: "royclaud",
    genres: "psychedelic hip hop,underground hip hop",
  },
  {
    id: "2gscB6kDOmrv1P6qs2KXE3",
    name: "Wait and Bleed",
    artists: "Slipknot",
    duration_ms: 147840,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/048641e64df740d2fba73110b673600c234bbeea?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02baf04a1d30db6ac9de26da7d",
    danceability: 0.382,
    energy: 0.996,
    valence: 0.327,
    added_at: "2020-09-23T05:51:08Z",
    link: "https://open.spotify.com/track/2gscB6kDOmrv1P6qs2KXE3",
    userID: "royclaud",
    genres: "alternative metal,nu metal,rap metal,rock",
  },
  {
    id: "1PNfhBdmFikFn4vkrwiq05",
    name: "Facts (feat. Jadakiss)",
    artists: "Flatbush Zombies, Jadakiss",
    duration_ms: 272624,
    popularity: 35,
    preview_url: "https://p.scdn.co/mp3-preview/fd3c4845ba1b5047a3d8b04d4c3b861c8e87f33c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b4e320aee99bd87662d85178",
    danceability: 0.689,
    energy: 0.538,
    valence: 0.316,
    added_at: "2020-09-23T05:50:45Z",
    link: "https://open.spotify.com/track/1PNfhBdmFikFn4vkrwiq05",
    userID: "royclaud",
    genres: "psychedelic hip hop,underground hip hop,battle rap,east coast hip hop,trap",
  },
  {
    id: "0AfIsqZ4gTUg9CwwW2jLeK",
    name: "Bother",
    artists: "Stone Sour",
    duration_ms: 240400,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/b68bc282a64a6b19c67d70daf9cc619e2c88cd95?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d2b113d2a75b3168ed9675e3",
    danceability: 0.272,
    energy: 0.281,
    valence: 0.141,
    added_at: "2020-09-23T05:50:28Z",
    link: "https://open.spotify.com/track/0AfIsqZ4gTUg9CwwW2jLeK",
    userID: "royclaud",
    genres: "alternative metal,nu metal,post-grunge",
  },
  {
    id: "6v4s7JosY38tu0hTZGpgrx",
    name: "Christ Conscious",
    artists: "Joey Bada$$",
    duration_ms: 173793,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/9072c02dc9ee1cb64ab8abdbe7be7294cdfc9786?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c89991543fdf82ca7374bd7b",
    danceability: 0.649,
    energy: 0.717,
    valence: 0.589,
    added_at: "2020-09-23T05:50:18Z",
    link: "https://open.spotify.com/track/6v4s7JosY38tu0hTZGpgrx",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap",
  },
  {
    id: "3arKRnq9OUd7yq6LRwVW8I",
    name: "Soul to Squeeze",
    artists: "Red Hot Chili Peppers",
    duration_ms: 289533,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/6767224dfe28e1171368d38f2ca4d6bec791b803?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025590b4ee88187cb06a5b102d",
    danceability: 0.663,
    energy: 0.546,
    valence: 0.656,
    added_at: "2020-09-23T05:50:07Z",
    link: "https://open.spotify.com/track/3arKRnq9OUd7yq6LRwVW8I",
    userID: "royclaud",
    genres: "alternative rock,funk metal,funk rock,permanent wave,rock",
  },
  {
    id: "6SDYPpLGLnr3VeyDFsiYG8",
    name: "Soul Reaver",
    artists: "A$AP ANT, A$AP Twelvyy",
    duration_ms: 168071,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/0d9966b78fe46ab6d55d7c240fa18ba11bf62a14?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e642e778793f72ade84cf512",
    danceability: 0.969,
    energy: 0.337,
    valence: 0.0847,
    added_at: "2020-09-23T05:49:56Z",
    link: "https://open.spotify.com/track/6SDYPpLGLnr3VeyDFsiYG8",
    userID: "royclaud",
    genres: "baltimore hip hop,drill,underground hip hop,underground hip hop",
  },
  {
    id: "35Rbqn962gEydsN1VKOC3T",
    name: "Soho (feat. JID)",
    artists: "WESTSIDE BOOGIE, JID",
    duration_ms: 133306,
    popularity: 47,
    preview_url: "https://p.scdn.co/mp3-preview/103c36edfce6c5ec5b3c6884c86170d2c397a1c5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e09935af9434c6e1c191d7e1",
    danceability: 0.792,
    energy: 0.603,
    valence: 0.515,
    added_at: "2020-09-23T05:49:48Z",
    link: "https://open.spotify.com/track/35Rbqn962gEydsN1VKOC3T",
    userID: "royclaud",
    genres: "canadian hip hop,deep underground hip hop,indie hip hop,underground hip hop,hip hop,pop rap,rap,underground hip hop",
  },
  {
    id: "7wxDKfgFtRTbupcG4xDhtj",
    name: "Oh Wow...Swerve (with J. Cole feat. Zoink Gang, KEY! & Maxo Kream)",
    artists: "Dreamville, J. Cole, Zoink Gang, KEY!, Maxo Kream",
    duration_ms: 275986,
    popularity: 44,
    preview_url: "https://p.scdn.co/mp3-preview/287593fb55f86a49b88f5b0aabb7dd767418f131?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c0ae820e3b64854de4642101",
    danceability: 0.509,
    energy: 0.703,
    valence: 0.301,
    added_at: "2020-09-23T05:49:27Z",
    link: "https://open.spotify.com/track/7wxDKfgFtRTbupcG4xDhtj",
    userID: "royclaud",
    genres: "hip hop,pop rap,rap,conscious hip hop,hip hop,north carolina hip hop,rap,atl trap,underground hip hop,houston rap,melodic rap,trap,underground hip hop",
  },
  {
    id: "5hOrYYtUd96bIOBjuSEArP",
    name: "How's It Goin' Down",
    artists: "DMX",
    duration_ms: 282906,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/c8fa7ad8ce19903e1c19a0035dbee4ef3e44fc79?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0211658b970e0518be491871df",
    danceability: 0.912,
    energy: 0.596,
    valence: 0.816,
    added_at: "2020-09-23T05:49:00Z",
    link: "https://open.spotify.com/track/5hOrYYtUd96bIOBjuSEArP",
    userID: "royclaud",
    genres: "detroit hip hop,east coast hip hop,gangster rap,hardcore hip hop,hip hop,rap",
  },
  {
    id: "3QHONiXGMGU3z68mQInncF",
    name: "I'll Be Missing You (feat. Faith Evans, 112)",
    artists: "Diddy, Faith Evans, 112",
    duration_ms: 301520,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/7a927e05125e0dad4b1786354f44b6b5cd878bf5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024ad6e5838f15401ff7d62856",
    danceability: 0.862,
    energy: 0.479,
    valence: 0.924,
    added_at: "2020-09-23T05:48:44Z",
    link: "https://open.spotify.com/track/3QHONiXGMGU3z68mQInncF",
    userID: "royclaud",
    genres: "dance pop,east coast hip hop,hip hop,hip pop,pop rap,rap,contemporary r&b,hip pop,r&b,urban contemporary,atl hip hop,boy band,contemporary r&b,hip pop,r&b,urban contemporary",
  },
  {
    id: "4nAk5rTFqrGcoAXLP2ppUl",
    name: "Hang",
    artists: "Matchbox Twenty",
    duration_ms: 227333,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/ef14a6cfe143a8ad5d889bd6111aa07792b08894?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0223dbfb8b3b1be429587f5380",
    danceability: 0.537,
    energy: 0.301,
    valence: 0.25,
    added_at: "2020-09-23T05:48:25Z",
    link: "https://open.spotify.com/track/4nAk5rTFqrGcoAXLP2ppUl",
    userID: "royclaud",
    genres: "neo mellow,pop rock,post-grunge",
  },
  {
    id: "4ODfj3jYXvffydHGRcEqx0",
    name: "Still Slummin'",
    artists: "Lute",
    duration_ms: 208385,
    popularity: 38,
    preview_url: "https://p.scdn.co/mp3-preview/6e01811b85a85326019b30920ff800766686492c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e0d49effb9cdbf9e34db4681",
    danceability: 0.837,
    energy: 0.843,
    valence: 0.795,
    added_at: "2020-09-23T05:48:15Z",
    link: "https://open.spotify.com/track/4ODfj3jYXvffydHGRcEqx0",
    userID: "royclaud",
    genres: "indie hip hop,north carolina hip hop,underground hip hop",
  },
  {
    id: "0XgpiStoxq1IJncYlPrvZ5",
    name: "Runnin'",
    artists: "The Pharcyde",
    duration_ms: 296266,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/005197e440b81a9accd4e3f9d1cae350c6ec9e0d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028248914bbbcdcba2d154c8de",
    danceability: 0.754,
    energy: 0.785,
    valence: 0.821,
    added_at: "2020-09-23T05:48:02Z",
    link: "https://open.spotify.com/track/0XgpiStoxq1IJncYlPrvZ5",
    userID: "royclaud",
    genres: "alternative hip hop,hardcore hip hop,hip hop,jazz rap,west coast rap",
  },
  {
    id: "7hZoTr4ffMGqm9opMiGmBp",
    name: "ONCE UPON A TIME (FREESTYLE)",
    artists: "IDK, Denzel Curry",
    duration_ms: 150819,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/9b6d2d4b20234122682eac6a9a3a75fe1a11d193?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021d779e27a3f9d4bf52412439",
    danceability: 0.762,
    energy: 0.807,
    valence: 0.711,
    added_at: "2020-09-23T05:47:45Z",
    link: "https://open.spotify.com/track/7hZoTr4ffMGqm9opMiGmBp",
    userID: "royclaud",
    genres: "pop rap,underground hip hop,dark trap,drill,hip hop,miami hip hop,rap,trap,underground hip hop",
  },
  {
    id: "56wkp0dkpjimGfX17Ofihm",
    name: "Decemba (Remix) (feat. $ilk Money & André Benjamin)",
    artists: "Divine Council, $ilkMoney, André Benjamin",
    duration_ms: 228550,
    popularity: 31,
    preview_url: "https://p.scdn.co/mp3-preview/4ff8b7916f4044d396836012d8350749942d6ed6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028f82ad1f4d1c3db2ea713315",
    danceability: 0.779,
    energy: 0.801,
    valence: 0.533,
    added_at: "2020-09-23T05:47:14Z",
    link: "https://open.spotify.com/track/56wkp0dkpjimGfX17Ofihm",
    userID: "royclaud",
    genres: "underground hip hop",
  },
  {
    id: "2fU2TSb3bghWYkPFuqgSkr",
    name: "Just Da Other Day",
    artists: "JID",
    duration_ms: 229045,
    popularity: 44,
    preview_url: "https://p.scdn.co/mp3-preview/7c10cf885fddfbabba16310b17a120630174d13b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f5991f6f1f0de2277bb8d948",
    danceability: 0.821,
    energy: 0.529,
    valence: 0.0622,
    added_at: "2020-09-23T05:46:41Z",
    link: "https://open.spotify.com/track/2fU2TSb3bghWYkPFuqgSkr",
    userID: "royclaud",
    genres: "hip hop,pop rap,rap,underground hip hop",
  },
  {
    id: "5VJjhHyG8NZ5xdgG6uTb3P",
    name: "Brick House",
    artists: "Commodores",
    duration_ms: 208893,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/8e3acac4d6f2cda5ad2b8e62cf6ebc8001bcf6f4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0240eea368f4fb5f5ee6dcd9a8",
    danceability: 0.832,
    energy: 0.782,
    valence: 0.63,
    added_at: "2020-09-21T00:14:08Z",
    link: "https://open.spotify.com/track/5VJjhHyG8NZ5xdgG6uTb3P",
    userID: "royclaud",
    genres: "adult standards,disco,funk,mellow gold,motown,quiet storm,soft rock,soul",
  },
  {
    id: "6ZmSr0MTJDst0jeq0ZPpa2",
    name: "Sweet Nothin'",
    artists: "Coletta",
    duration_ms: 235701,
    popularity: 15,
    preview_url: "https://p.scdn.co/mp3-preview/c915aafb6dd4868e0db7d237a2dea93c8ccfeb40?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f323a4e4dc393c7a21d19752",
    danceability: 0.346,
    energy: 0.799,
    valence: 0.427,
    added_at: "2020-09-19T07:41:32Z",
    link: "https://open.spotify.com/track/6ZmSr0MTJDst0jeq0ZPpa2",
    userID: "royclaud",
    genres: "progressive post-hardcore,swancore",
  },
  {
    id: "46IUSULlL2SAmyyHHpXQJz",
    name: "Lyrics Lie",
    artists: "Dance Gavin Dance",
    duration_ms: 236050,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/c7969ef540aad104d14c7379f9414a7c797e8dc4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0202d0198148ba9d52522456ab",
    danceability: 0.528,
    energy: 0.974,
    valence: 0.507,
    added_at: "2020-09-19T07:39:43Z",
    link: "https://open.spotify.com/track/46IUSULlL2SAmyyHHpXQJz",
    userID: "royclaud",
    genres: "pixie,sacramento indie,screamo,swancore",
  },
  {
    id: "4WCgIZ1n7I6yRaAQ9GjS07",
    name: "Nayhoo",
    artists: "Chon, Masego, lophiile",
    duration_ms: 171080,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/0c90827c182d4a88d9e2e468f89279c9644ae18f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a558127d4d7e41d35726fb9c",
    danceability: 0.812,
    energy: 0.567,
    valence: 0.616,
    added_at: "2020-09-19T07:36:28Z",
    link: "https://open.spotify.com/track/4WCgIZ1n7I6yRaAQ9GjS07",
    userID: "royclaud",
    genres: "instrumental math rock,instrumental rock,progressive jazz fusion,san diego indie,alternative r&b,indie soul",
  },
  {
    id: "1SNjLm2Tq4nntVQk7BSecs",
    name: "Pushing Daisies",
    artists: "Belmont",
    duration_ms: 168866,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/c89dac7229af431c7d6ff6012680f4f4ab75f41d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02904853209cc4e76f46b6f374",
    danceability: 0.637,
    energy: 0.939,
    valence: 0.592,
    added_at: "2020-09-19T07:32:31Z",
    link: "https://open.spotify.com/track/1SNjLm2Tq4nntVQk7BSecs",
    userID: "royclaud",
    genres: "alternative emo,anthem emo,chicago pop punk,easycore,pop punk",
  },
  {
    id: "5u1n1kITHCxxp8twBcZxWy",
    name: "Holy (feat. Chance The Rapper)",
    artists: "Justin Bieber, Chance the Rapper",
    duration_ms: 212093,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/f5f395935b92632ca49742786c32493475c0b493?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02572c68f79b356c21202e248c",
    danceability: 0.673,
    energy: 0.704,
    valence: 0.372,
    added_at: "2020-09-19T02:32:38Z",
    link: "https://open.spotify.com/track/5u1n1kITHCxxp8twBcZxWy",
    userID: "royclaud",
    genres: "canadian pop,pop,chicago rap,conscious hip hop,hip hop,pop rap,rap,trap",
  },
  {
    id: "4zzvMG8KrsykYtvFEJ99Cl",
    name: "Easy",
    artists: "Faith No More",
    duration_ms: 186960,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/1f75947e13fe58dba48b2fd8d5f0b79db8358962?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fba269c78920704d0a3a097d",
    danceability: 0.506,
    energy: 0.554,
    valence: 0.375,
    added_at: "2020-09-18T18:59:45Z",
    link: "https://open.spotify.com/track/4zzvMG8KrsykYtvFEJ99Cl",
    userID: "royclaud",
    genres: "alternative metal,alternative rock,funk metal,funk rock,hard rock,nu metal,post-grunge,rap metal,rock",
  },
  {
    id: "38PCpNNE5covoh1X06upVZ",
    name: "Mrs. Whoever",
    artists: "Saba",
    duration_ms: 171960,
    popularity: 37,
    preview_url: "https://p.scdn.co/mp3-preview/661035d30a39a76052f82c1af1c7035362427fcc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b9b960b71872fb3f7512a6e5",
    danceability: 0.663,
    energy: 0.536,
    valence: 0.394,
    added_at: "2020-09-18T06:40:52Z",
    link: "https://open.spotify.com/track/38PCpNNE5covoh1X06upVZ",
    userID: "royclaud",
    genres: "alternative r&b,chicago rap",
  },
  {
    id: "1jzDzZWeSDBg5fhNc3tczV",
    name: "Paranoid",
    artists: "Black Sabbath",
    duration_ms: 168440,
    popularity: 21,
    preview_url: "https://p.scdn.co/mp3-preview/c5efbed38649f0676181dc6bb6e3c8644925726e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029683e5d7361bb80bfb00f46d",
    danceability: 0.429,
    energy: 0.631,
    valence: 0.376,
    added_at: "2020-09-17T23:35:42Z",
    link: "https://open.spotify.com/track/1jzDzZWeSDBg5fhNc3tczV",
    userID: "royclaud",
    genres: "album rock,alternative metal,birmingham metal,classic rock,hard rock,metal,rock,stoner rock,uk doom metal",
  },
  {
    id: "2FZ0yrA5aPClG5ZPBlV7n4",
    name: "Sweet Leaf - 2014 Remaster",
    artists: "Black Sabbath",
    duration_ms: 304787,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/8afcadd5dd212a1ca366342c3cb4a80751eb5841?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c199494ba9ea2b73e9208f91",
    danceability: 0.362,
    energy: 0.6,
    valence: 0.658,
    added_at: "2020-09-17T23:30:40Z",
    link: "https://open.spotify.com/track/2FZ0yrA5aPClG5ZPBlV7n4",
    userID: "royclaud",
    genres: "album rock,alternative metal,birmingham metal,classic rock,hard rock,metal,rock,stoner rock,uk doom metal",
  },
  {
    id: "2nVHqZbOGkKWzlcy1aMbE7",
    name: "Kashmir - 1990 Remaster",
    artists: "Led Zeppelin",
    duration_ms: 508200,
    popularity: 2,
    preview_url: "https://p.scdn.co/mp3-preview/4d9bde799eec8913e53c184c45b9fd575690b917?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022abc2d86a442efb6cc631d0a",
    danceability: 0.48,
    energy: 0.536,
    valence: 0.581,
    added_at: "2020-09-17T23:26:13Z",
    link: "https://open.spotify.com/track/2nVHqZbOGkKWzlcy1aMbE7",
    userID: "royclaud",
    genres: "album rock,classic rock,hard rock,rock",
  },
  {
    id: "4K5VK3eLs1uUGCSwYUfQV3",
    name: "Have You Ever Seen The Rain",
    artists: "Creedence Clearwater Revival",
    duration_ms: 158533,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/9b5348b8d7c6bb14b63998903a46348657f70c2e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0286d663bfb3c19c57281b0473",
    danceability: 0.797,
    energy: 0.601,
    valence: 0.888,
    added_at: "2020-09-17T23:15:52Z",
    link: "https://open.spotify.com/track/4K5VK3eLs1uUGCSwYUfQV3",
    userID: "royclaud",
    genres: "album rock,classic rock,country rock,mellow gold,rock,southern rock,swamp rock",
  },
  {
    id: "5e9TFTbltYBg2xThimr0rU",
    name: "The Chain - 2004 Remaster",
    artists: "Fleetwood Mac",
    duration_ms: 270213,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/9cd01344e96f8dfaf0fb47fbb36d9da930daab2f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e52a59a28efa4773dd2bfe1b",
    danceability: 0.545,
    energy: 0.67,
    valence: 0.481,
    added_at: "2020-09-16T18:30:32Z",
    link: "https://open.spotify.com/track/5e9TFTbltYBg2xThimr0rU",
    userID: "royclaud",
    genres: "album rock,classic rock,rock,soft rock,yacht rock",
  },
  {
    id: "3kfXUGIdBNpyr2gBvU3Guj",
    name: "Angel",
    artists: "Aerosmith",
    duration_ms: 308906,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/8a3eb96a9474948918ce1fb9f256a0020b7dc07a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a8a965df6a845b265ee19106",
    danceability: 0.239,
    energy: 0.819,
    valence: 0.439,
    added_at: "2020-09-14T16:40:00Z",
    link: "https://open.spotify.com/track/3kfXUGIdBNpyr2gBvU3Guj",
    userID: "royclaud",
    genres: "album rock,classic rock,hard rock,rock",
  },
  {
    id: "7M7AwtGvWdMYudqx5Iuh1m",
    name: "Althea - 2013 Remaster",
    artists: "Grateful Dead",
    duration_ms: 412595,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/a78cbede8584dcf02018a0864ff8b275f9b4422e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02dd5ce8d59ef803b79aa3740e",
    danceability: 0.783,
    energy: 0.284,
    valence: 0.583,
    added_at: "2020-09-14T06:17:00Z",
    link: "https://open.spotify.com/track/7M7AwtGvWdMYudqx5Iuh1m",
    userID: "royclaud",
    genres: "cosmic american,jam band,psychedelic rock",
  },
  {
    id: "4YMQXzscifAREG0a7KNGhB",
    name: "Black Magic Woman - Single Version",
    artists: "Santana",
    duration_ms: 196466,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/0c6f2f23c5b8a2501444271043d57f2a9aca37c4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02930858249e7abbb9b5317929",
    danceability: 0.489,
    energy: 0.349,
    valence: 0.622,
    added_at: "2020-09-14T03:35:35Z",
    link: "https://open.spotify.com/track/4YMQXzscifAREG0a7KNGhB",
    userID: "royclaud",
    genres: "blues rock,classic rock,mexican classic rock",
  },
  {
    id: "4ZkhFcoS3apzze9w2yI9NO",
    name: "Thank You - 1990 Remaster",
    artists: "Led Zeppelin",
    duration_ms: 289466,
    popularity: 52,
    preview_url: "https://p.scdn.co/mp3-preview/6b08ad5c3f39a2aceb4e0d38b929a78cb8db01db?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fc4f17340773c6c3579fea0d",
    danceability: 0.316,
    energy: 0.27,
    valence: 0.0956,
    added_at: "2020-09-14T03:31:37Z",
    link: "https://open.spotify.com/track/4ZkhFcoS3apzze9w2yI9NO",
    userID: "royclaud",
    genres: "album rock,classic rock,hard rock,rock",
  },
  {
    id: "608xszaAxVh4m7NcKJiAbF",
    name: "One of These Nights - 2013 Remaster",
    artists: "Eagles",
    duration_ms: 291685,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/076bad35fd16728e29c5f0a33a7363745c775345?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025d0a8e54aba5181c79593b94",
    danceability: 0.655,
    energy: 0.606,
    valence: 0.765,
    added_at: "2020-09-14T02:01:18Z",
    link: "https://open.spotify.com/track/608xszaAxVh4m7NcKJiAbF",
    userID: "royclaud",
    genres: "album rock,classic rock,heartland rock,mellow gold,rock,soft rock,yacht rock",
  },
  {
    id: "1KZ3Ae1YUp33ue9tEaVcTr",
    name: "Wait Your Turn",
    artists: "NoCap",
    duration_ms: 147381,
    popularity: 27,
    preview_url: "https://p.scdn.co/mp3-preview/3d57306a2aff2670b9eeb31179fe76fe5eae19a3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029877703bafd9eac83d4ef5ef",
    danceability: 0.838,
    energy: 0.562,
    valence: 0.327,
    added_at: "2020-09-12T02:04:53Z",
    link: "https://open.spotify.com/track/1KZ3Ae1YUp33ue9tEaVcTr",
    userID: "royclaud",
    genres: "alabama rap,atl trap",
  },
  {
    id: "1Eolhana7nKHYpcYpdVcT5",
    name: "Little Wing",
    artists: "Jimi Hendrix",
    duration_ms: 145746,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/48aa97d5b07d401bc8e2bc11acdb82c54cb6c374?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0219dcd95d28b63d10164327f2",
    danceability: 0.515,
    energy: 0.534,
    valence: 0.426,
    added_at: "2020-09-11T22:55:26Z",
    link: "https://open.spotify.com/track/1Eolhana7nKHYpcYpdVcT5",
    userID: "royclaud",
    genres: "acid rock,album rock,alternative rock,classic rock,hard rock,proto-metal,psychedelic rock,rock",
  },
  {
    id: "3vzT5SNVL3vaDeAgnG9QhF",
    name: "Upside Down",
    artists: "Jeremy Zucker, Daniel James ",
    duration_ms: 169386,
    popularity: 33,
    preview_url: "https://p.scdn.co/mp3-preview/285cc3f87b1d7d55524f945592a3967df1ff027f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026eb668bd71d464cf1862b807",
    danceability: 0.666,
    energy: 0.404,
    valence: 0.765,
    added_at: "2020-09-11T19:46:44Z",
    link: "https://open.spotify.com/track/3vzT5SNVL3vaDeAgnG9QhF",
    userID: "royclaud",
    genres: "alt z,electropop,pop",
  },
  {
    id: "4osgfFTICMkcGbbigdsa53",
    name: "Novacane",
    artists: "Frank Ocean",
    duration_ms: 302346,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/c2c28d70cce9e5e7b212eb0d7bd951e231ff6034?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0259a428dc7ef8e0c12b0fe18a",
    danceability: 0.762,
    energy: 0.508,
    valence: 0.37,
    added_at: "2020-09-11T19:46:43Z",
    link: "https://open.spotify.com/track/4osgfFTICMkcGbbigdsa53",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "0LtsuNRz3IMRrHCYO9fKRk",
    name: "Beautiful Disaster",
    artists: "311",
    duration_ms: 238400,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/f5833edb13b9ea8f05b777ab3710141a2d3576e0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02dd41b133a7f4e5b2adbfe5d8",
    danceability: 0.576,
    energy: 0.738,
    valence: 0.675,
    added_at: "2020-09-10T16:44:39Z",
    link: "https://open.spotify.com/track/0LtsuNRz3IMRrHCYO9fKRk",
    userID: "royclaud",
    genres: "alternative metal,alternative rock,funk metal,funk rock,nu metal,pop rock,rap rock,reggae fusion",
  },
  {
    id: "5jYq5kj7kt6Iokzv7XeZhZ",
    name: "I Remember Everything",
    artists: "John Prine",
    duration_ms: 163413,
    popularity: 44,
    preview_url: "https://p.scdn.co/mp3-preview/f560ff89fe391b55109f8218a5326ab0a82f9b26?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0227d2963c9628faa56a349ae0",
    danceability: 0.557,
    energy: 0.189,
    valence: 0.408,
    added_at: "2020-09-08T18:29:16Z",
    link: "https://open.spotify.com/track/5jYq5kj7kt6Iokzv7XeZhZ",
    userID: "royclaud",
    genres: "folk,new americana,roots rock,singer-songwriter",
  },
  {
    id: "2sSXUCN0uBiRiTMll0A4kb",
    name: "Inner World",
    artists: "Dirty Projectors",
    duration_ms: 142573,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/3664ddf8ae0830ddd073b427b597eaef7b504e6b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0263a649a97e4fdf50706871a8",
    danceability: 0.712,
    energy: 0.462,
    valence: 0.324,
    added_at: "2020-09-08T18:28:59Z",
    link: "https://open.spotify.com/track/2sSXUCN0uBiRiTMll0A4kb",
    userID: "royclaud",
    genres: "art pop,brooklyn indie,indie rock,indietronica,noise pop",
  },
  {
    id: "5oZps6mYqU5s7A4WjUZggJ",
    name: "Lockdown",
    artists: "Anderson .Paak",
    duration_ms: 212613,
    popularity: 47,
    preview_url: "https://p.scdn.co/mp3-preview/94e8f59bbc6ded6d50d5718c4a2d6498732073b4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02684543025b8324b1476214c2",
    danceability: 0.928,
    energy: 0.437,
    valence: 0.496,
    added_at: "2020-09-08T18:23:25Z",
    link: "https://open.spotify.com/track/5oZps6mYqU5s7A4WjUZggJ",
    userID: "royclaud",
    genres: "escape room,hip hop,indie soul,neo soul",
  },
  {
    id: "47v4uUtj5AukJmCbMq4Kry",
    name: "Freeze Tag (feat. Phoelix)",
    artists: "Terrace Martin, Robert Glasper, 9th Wonder, Kamasi Washington, Dinner Party, Phoelix",
    duration_ms: 191936,
    popularity: 53,
    preview_url: "https://p.scdn.co/mp3-preview/945648b0d7488265e8a043892719a17c515a3ead?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025557786a9c1b65b81c018551",
    danceability: 0.653,
    energy: 0.598,
    valence: 0.137,
    added_at: "2020-09-08T17:53:58Z",
    link: "https://open.spotify.com/track/47v4uUtj5AukJmCbMq4Kry",
    userID: "royclaud",
    genres:
      "alternative r&b,indie soul,contemporary jazz,indie jazz,indie soul,jazz,modern jazz piano,neo r&b,neo soul,alternative hip hop,hip hop,instrumental hip hop,north carolina hip hop,afrofuturism,contemporary jazz,indie soul,jazz,jazz saxophone,indie hip hop",
  },
  {
    id: "1BLoghXkY06sQvHdJ1vOWO",
    name: "Clear Bones",
    artists: "Jean Dawson",
    duration_ms: 166711,
    popularity: 22,
    preview_url: "https://p.scdn.co/mp3-preview/9c907ca8e14624088329b8f7affa32424d586fc1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022e6d0d9fbf01cd8fa7367dd9",
    danceability: 0.56,
    energy: 0.867,
    valence: 0.567,
    added_at: "2020-09-08T17:49:29Z",
    link: "https://open.spotify.com/track/1BLoghXkY06sQvHdJ1vOWO",
    userID: "royclaud",
    genres: "indie hip hop,modern indie pop",
  },
  {
    id: "33EGYJLLwz56YIP5Ex9VKs",
    name: "West Ten",
    artists: "AJ Tracey, Mabel",
    duration_ms: 213692,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/cbf31d5e0066c159f212c3481959afce1ff3fb42?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025a4111d1fdeb9ca9d544a3ce",
    danceability: 0.843,
    energy: 0.852,
    valence: 0.845,
    added_at: "2020-09-08T17:49:10Z",
    link: "https://open.spotify.com/track/33EGYJLLwz56YIP5Ex9VKs",
    userID: "royclaud",
    genres: "grime,uk hip hop,ukg revival,pop,uk pop",
  },
  {
    id: "7mEUjhl6aTztdHCrxoTgBG",
    name: "Wash Us In The Blood",
    artists: "Kanye West, Travis Scott",
    duration_ms: 189827,
    popularity: 54,
    preview_url: "https://p.scdn.co/mp3-preview/67f762c0603fa8952cc7edf809fe5bc10b830ec3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022b2de7835bc93f1e368cee6d",
    danceability: 0.67,
    energy: 0.847,
    valence: 0.381,
    added_at: "2020-09-08T17:43:54Z",
    link: "https://open.spotify.com/track/7mEUjhl6aTztdHCrxoTgBG",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap,rap,slap house",
  },
  {
    id: "3cMgt6F6DrJYvxh2ruapPJ",
    name: "Antigone",
    artists: "Liturgy, LEYA",
    duration_ms: 316228,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/fbeb4bb6aae53ac3e346549f6a7dc730038ca1e3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025804bf93385682e8200c3880",
    danceability: 0.169,
    energy: 0.55,
    valence: 0.0382,
    added_at: "2020-09-08T17:27:46Z",
    link: "https://open.spotify.com/track/3cMgt6F6DrJYvxh2ruapPJ",
    userID: "royclaud",
    genres: "experimental black metal,nyc metal,technical black metal,totalism,usbm,ambient pop,microtonal,spectra",
  },
  {
    id: "6cx77dkY1krawmMHhekaBk",
    name: "Pigeons",
    artists: "Bill Callahan",
    duration_ms: 325093,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/284c7d6ef9329609624ac71f47f61771d0bd0d66?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024d636dd8d8d25df4d7d03812",
    danceability: 0.467,
    energy: 0.176,
    valence: 0.271,
    added_at: "2020-09-08T17:21:51Z",
    link: "https://open.spotify.com/track/6cx77dkY1krawmMHhekaBk",
    userID: "royclaud",
    genres: "alternative country,art pop,chamber pop,lo-fi,singer-songwriter,slowcore,solo wave",
  },
  {
    id: "62N0RkCCs4XQXk7hgnpOXo",
    name: "Woman",
    artists: "Rema",
    duration_ms: 238911,
    popularity: 54,
    preview_url: "https://p.scdn.co/mp3-preview/5a47f3aca96f0917a6a2ce236049191ee0a8da72?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c84ce4c193877589a613a579",
    danceability: 0.84,
    energy: 0.687,
    valence: 0.678,
    added_at: "2020-09-08T17:10:52Z",
    link: "https://open.spotify.com/track/62N0RkCCs4XQXk7hgnpOXo",
    userID: "royclaud",
    genres: "afrobeats,nigerian pop",
  },
  {
    id: "4bOLbZhJfBIB1WFONyUjtP",
    name: "yup!",
    artists: "Yung Gravy",
    duration_ms: 189000,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/363b6b49a2f7bf1aaf83db668c99711212744046?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0205d033e9bbdeb4bb63e68383",
    danceability: 0.769,
    energy: 0.745,
    valence: 0.585,
    added_at: "2020-09-08T15:37:30Z",
    link: "https://open.spotify.com/track/4bOLbZhJfBIB1WFONyUjtP",
    userID: "royclaud",
    genres: "meme rap,minnesota hip hop",
  },
  {
    id: "177Gk50xMUz4QMiEXLARFP",
    name: "If You Want Me To Stay (with Anthony Ramos)",
    artists: "Ari Lennox, Anthony Ramos",
    duration_ms: 187605,
    popularity: 34,
    preview_url: "https://p.scdn.co/mp3-preview/6321e08c909e1b0d5038e12fca2ea660561b7efe?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0242822749e71b5500a8625d59",
    danceability: 0.592,
    energy: 0.473,
    valence: 0.419,
    added_at: "2020-09-08T15:36:46Z",
    link: "https://open.spotify.com/track/177Gk50xMUz4QMiEXLARFP",
    userID: "royclaud",
    genres: "alternative r&b,show tunes",
  },
  {
    id: "4VyoOl99FlcemZr6WvzQed",
    name: "Woman (feat. Lianne La Havas)",
    artists: "Nao, Lianne La Havas",
    duration_ms: 191826,
    popularity: 36,
    preview_url: "https://p.scdn.co/mp3-preview/e4e6cd1c19f5ff9ca833b42b657bddd864a9b86b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027082447343167363976ca7e1",
    danceability: 0.701,
    energy: 0.644,
    valence: 0.416,
    added_at: "2020-09-08T15:31:31Z",
    link: "https://open.spotify.com/track/4VyoOl99FlcemZr6WvzQed",
    userID: "royclaud",
    genres: "alternative r&b,uk contemporary r&b,alternative r&b,british soul,indie soul,neo soul,pop soul,soul",
  },
  {
    id: "6lIk5tgKezTTpxq1do60Tz",
    name: "Slow Ride",
    artists: "Sublime",
    duration_ms: 263545,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/0b9b21a26a500020195149a7610be36fb354fb9b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e6f11f4c160143e5efb97651",
    danceability: 0.815,
    energy: 0.626,
    valence: 0.711,
    added_at: "2020-09-07T21:05:44Z",
    link: "https://open.spotify.com/track/6lIk5tgKezTTpxq1do60Tz",
    userID: "royclaud",
    genres: "rap rock,reggae fusion,ska mexicano,ska punk",
  },
  {
    id: "15VRO9CQwMpbqUYA7e6Hwg",
    name: "The Man Who Sold The World - Live",
    artists: "Nirvana",
    duration_ms: 261093,
    popularity: 74,
    preview_url: "https://p.scdn.co/mp3-preview/09bb6f50ecb3972434a6e2316afd886511cc04a0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0236c5417732e53e23cb219246",
    danceability: 0.483,
    energy: 0.753,
    valence: 0.42,
    added_at: "2020-09-07T19:49:56Z",
    link: "https://open.spotify.com/track/15VRO9CQwMpbqUYA7e6Hwg",
    userID: "royclaud",
    genres: "grunge,permanent wave,rock",
  },
  {
    id: "11LmqTE2naFULdEP94AUBa",
    name: "Heart-Shaped Box",
    artists: "Nirvana",
    duration_ms: 281160,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/71c2606b4c7b16f217c033319f155f265c0666ac?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02aca059cebc1841277db22d1c",
    danceability: 0.256,
    energy: 0.641,
    valence: 0.382,
    added_at: "2020-09-07T19:47:42Z",
    link: "https://open.spotify.com/track/11LmqTE2naFULdEP94AUBa",
    userID: "royclaud",
    genres: "grunge,permanent wave,rock",
  },
  {
    id: "5QLHGv0DfpeXLNFo7SFEy1",
    name: "1979 - Remastered 2012",
    artists: "The Smashing Pumpkins",
    duration_ms: 266200,
    popularity: 77,
    preview_url: "https://p.scdn.co/mp3-preview/5c2f38c90920f9d3db3784bc0205cf24ec7a1abe?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02431ac6e6f393acf475730ec6",
    danceability: 0.767,
    energy: 0.787,
    valence: 0.964,
    added_at: "2020-09-07T19:44:40Z",
    link: "https://open.spotify.com/track/5QLHGv0DfpeXLNFo7SFEy1",
    userID: "royclaud",
    genres: "alternative metal,alternative rock,grunge,permanent wave,rock,spacegrunge",
  },
  {
    id: "1ZMOcjwPSLMmdx6aBfSP3P",
    name: "Cherry Street",
    artists: "Tijuana Panthers",
    duration_ms: 187421,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/de8d9234709566b987e8cc71c9a3760ff09949de?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028300064cfb33ad7e16c6be42",
    danceability: 0.291,
    energy: 0.93,
    valence: 0.64,
    added_at: "2020-09-06T16:27:21Z",
    link: "https://open.spotify.com/track/1ZMOcjwPSLMmdx6aBfSP3P",
    userID: "royclaud",
    genres: "indie garage rock,indie surf",
  },
  {
    id: "2H3wWrnO758y0fPH7Ilerg",
    name: "Depression & Obsession",
    artists: "XXXTENTACION",
    duration_ms: 144733,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/f537b69194593ff7f15e384abcd1433aa3051272?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02203c89bd4391468eea4cc3f5",
    danceability: 0.769,
    energy: 0.156,
    valence: 0.527,
    added_at: "2020-09-06T06:33:23Z",
    link: "https://open.spotify.com/track/2H3wWrnO758y0fPH7Ilerg",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "6vnfObZ4Ys70SBAtti1xZ9",
    name: "COFFEE BEAN",
    artists: "Travis Scott",
    duration_ms: 209115,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/28847ddbe5dc809ebd71dacdc3174fd21cd7e84f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02072e9faef2ef7b6db63834a3",
    danceability: 0.81,
    energy: 0.582,
    valence: 0.503,
    added_at: "2020-09-04T16:06:16Z",
    link: "https://open.spotify.com/track/6vnfObZ4Ys70SBAtti1xZ9",
    userID: "royclaud",
    genres: "rap,slap house",
  },
  {
    id: "7lxOjQyVsHQ8toQeT7IgCc",
    name: "Wish",
    artists: "Diplo, Trippie Redd",
    duration_ms: 175273,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/339bb560f76a582759d06eb9cf73f8f2cb3be6ad?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02679122de6d6208d8e1314b00",
    danceability: 0.57,
    energy: 0.903,
    valence: 0.603,
    added_at: "2020-09-04T05:13:52Z",
    link: "https://open.spotify.com/track/7lxOjQyVsHQ8toQeT7IgCc",
    userID: "royclaud",
    genres: "dance pop,edm,electro house,moombahton,pop dance,melodic rap,rap,trap",
  },
  {
    id: "3DlgDXIYtnWtJKiB8bZQMv",
    name: "Photosynthesis",
    artists: "Saba, Jean Deaux",
    duration_ms: 201732,
    popularity: 29,
    preview_url: "https://p.scdn.co/mp3-preview/0f9766940d8b5f2b04ef050ae54c54de931ad10b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0262cd6013f4b68f16bbce3666",
    danceability: 0.777,
    energy: 0.662,
    valence: 0.634,
    added_at: "2020-09-04T04:40:33Z",
    link: "https://open.spotify.com/track/3DlgDXIYtnWtJKiB8bZQMv",
    userID: "royclaud",
    genres: "alternative r&b,chicago rap,alternative r&b",
  },
  {
    id: "3GXSywNvYLAVUCtjMHkKDh",
    name: "E. Coli (feat. Earl Sweatshirt)",
    artists: "The Alchemist, Earl Sweatshirt",
    duration_ms: 132147,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/e85b29af95642d33f46aff25f1ea2e2bec1ab86a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02795e7069de7cb188b7c821b4",
    danceability: 0.322,
    energy: 0.555,
    valence: 0.397,
    added_at: "2020-09-01T19:48:14Z",
    link: "https://open.spotify.com/track/3GXSywNvYLAVUCtjMHkKDh",
    userID: "royclaud",
    genres: "alternative hip hop,drumless hip hop,hip hop,instrumental hip hop,west coast rap,alternative hip hop,drumless hip hop,experimental hip hop,hip hop,rap,underground hip hop",
  },
  {
    id: "6VoIBz0VhCyz7OdEoRYDiA",
    name: "Say It Ain't So - Original Mix",
    artists: "Weezer",
    duration_ms: 258826,
    popularity: 74,
    preview_url: "https://p.scdn.co/mp3-preview/17adc497b3bfb73e83cf713f1dd6b9a711abb59b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02345536847e60f622ee0eae96",
    danceability: 0.634,
    energy: 0.551,
    valence: 0.453,
    added_at: "2020-08-31T16:24:19Z",
    link: "https://open.spotify.com/track/6VoIBz0VhCyz7OdEoRYDiA",
    userID: "royclaud",
    genres: "alternative rock,modern power pop,modern rock,permanent wave,rock",
  },
  {
    id: "5Ddlk6C2JVxb1SReZ6O1wk",
    name: "Drain You",
    artists: "Nirvana",
    duration_ms: 223880,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/ed7878b75097b89ad553b5f2a85430daf3d97a7e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e175a19e530c898d167d39bf",
    danceability: 0.325,
    energy: 0.929,
    valence: 0.198,
    added_at: "2020-08-31T16:15:51Z",
    link: "https://open.spotify.com/track/5Ddlk6C2JVxb1SReZ6O1wk",
    userID: "royclaud",
    genres: "grunge,permanent wave,rock",
  },
  {
    id: "3B6WDceNe6lUQnczntRIuf",
    name: "The Ghetto",
    artists: "Too $hort",
    duration_ms: 302133,
    popularity: 50,
    preview_url: "https://p.scdn.co/mp3-preview/4a580fbd47ea3e1c06873dee48339881cd04851e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b74df3122f2376c638d28667",
    danceability: 0.947,
    energy: 0.354,
    valence: 0.36,
    added_at: "2020-08-30T16:16:11Z",
    link: "https://open.spotify.com/track/3B6WDceNe6lUQnczntRIuf",
    userID: "royclaud",
    genres: "cali rap,gangster rap,golden age hip hop,hyphy,oakland hip hop,west coast rap",
  },
  {
    id: "25X4NuaaDkwmiZY3xFiLqh",
    name: "Primeramente Dios",
    artists: "Severo Benito",
    duration_ms: 152999,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/d7de58146d9ce8595736dd480db9653679075210?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d0a69f0929b56cd55591364d",
    danceability: 0.636,
    energy: 0.458,
    valence: 0.803,
    added_at: "2020-08-28T06:21:14Z",
    link: "https://open.spotify.com/track/25X4NuaaDkwmiZY3xFiLqh",
    userID: "royclaud",
    genres: "tierra caliente",
  },
  {
    id: "2lAvilKnQcFH4t0y6A1fqh",
    name: "Weight On My Shoulders",
    artists: "Rod Wave",
    duration_ms: 111875,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/5ae7c76df09d052cd27ae33097dcab92aa0131a1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02216d9569130ad67b9585d147",
    danceability: 0.732,
    energy: 0.62,
    valence: 0.885,
    added_at: "2020-08-28T05:54:25Z",
    link: "https://open.spotify.com/track/2lAvilKnQcFH4t0y6A1fqh",
    userID: "royclaud",
    genres: "florida rap",
  },
  {
    id: "5DBdAu8Zs7YMWODjuFq6EX",
    name: "Through The Wire",
    artists: "Rod Wave",
    duration_ms: 223973,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/7838ee96447e1541ec97247ef0c8dddf6a4ac1c9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0222ff91d8f39712b1c63bb0d4",
    danceability: 0.752,
    energy: 0.313,
    valence: 0.514,
    added_at: "2020-08-28T04:44:33Z",
    link: "https://open.spotify.com/track/5DBdAu8Zs7YMWODjuFq6EX",
    userID: "royclaud",
    genres: "florida rap",
  },
  {
    id: "3UxORWLWBMmpJiumQ0LNHu",
    name: "Til I Die",
    artists: "6o",
    duration_ms: 129115,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/a9c9d98446b2416e96a4df7da952e69a0d2e9559?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025794663e06267017e34c367a",
    danceability: 0.57,
    energy: 0.689,
    valence: 0.3,
    added_at: "2020-08-27T07:04:16Z",
    link: "https://open.spotify.com/track/3UxORWLWBMmpJiumQ0LNHu",
    userID: "royclaud",
    genres: "sad rap",
  },
  {
    id: "0PQftCkSQlJjneCXb7IC2C",
    name: "Unhealthy",
    artists: "Bakar",
    duration_ms: 153320,
    popularity: 4,
    preview_url: "https://p.scdn.co/mp3-preview/8be748f28d133019f06b352d1cf180fb8e53c122?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0217fa66a262af06296bbd9d83",
    danceability: 0.878,
    energy: 0.413,
    valence: 0.424,
    added_at: "2020-08-27T07:01:50Z",
    link: "https://open.spotify.com/track/0PQftCkSQlJjneCXb7IC2C",
    userID: "royclaud",
    genres: "uk alternative hip hop",
  },
  {
    id: "5DPASDOoVakOcD8EIhb46T",
    name: "High Right Now (feat. Wiz Khalifa) - Remix",
    artists: "Tyla Yaweh, Wiz Khalifa",
    duration_ms: 175759,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/9cb627eda8559320b575306d201122f0150f3934?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022dea10a6e67a8c1ab8b8566e",
    danceability: 0.737,
    energy: 0.548,
    valence: 0.156,
    added_at: "2020-08-27T06:57:55Z",
    link: "https://open.spotify.com/track/5DPASDOoVakOcD8EIhb46T",
    userID: "royclaud",
    genres: "cali rap,melodic rap,hip hop,pittsburgh rap,pop rap,rap,southern hip hop,trap",
  },
  {
    id: "0zWbYGQq5O1KQd0iqzgjP6",
    name: "Rehab (Winter In Paris)",
    artists: "Brent Faiyaz",
    duration_ms: 180303,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/cf43a1046678ade4ab8cb014e714895afba3458e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026a463f436bbf07f3c9e8c62a",
    danceability: 0.549,
    energy: 0.242,
    valence: 0.286,
    added_at: "2020-08-24T19:21:20Z",
    link: "https://open.spotify.com/track/0zWbYGQq5O1KQd0iqzgjP6",
    userID: "royclaud",
    genres: "r&b,rap",
  },
  {
    id: "4YdRnTnXYwgI48kkzWy6uk",
    name: "BUSY / SIRENS",
    artists: "Saba, theMIND",
    duration_ms: 329541,
    popularity: 29,
    preview_url: "https://p.scdn.co/mp3-preview/94f8c8ac8b1ace46d9f9b18b4737b849a98ea951?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024e924c0e8d0930c2655e3ce5",
    danceability: 0.667,
    energy: 0.465,
    valence: 0.474,
    added_at: "2020-08-24T18:01:25Z",
    link: "https://open.spotify.com/track/4YdRnTnXYwgI48kkzWy6uk",
    userID: "royclaud",
    genres: "alternative r&b,chicago rap,indie hip hop",
  },
  {
    id: "5bknBRjKJZ643DAN2w8Yoy",
    name: "FACE",
    artists: "BROCKHAMPTON",
    duration_ms: 259000,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/d38fa544144e18a9d8655ca0e84ce882f9b8b14c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a0c4a585a7d73b4943b9bf13",
    danceability: 0.945,
    energy: 0.702,
    valence: 0.678,
    added_at: "2020-08-24T17:45:36Z",
    link: "https://open.spotify.com/track/5bknBRjKJZ643DAN2w8Yoy",
    userID: "royclaud",
    genres: "boy band,rap",
  },
  {
    id: "3oyf4dalm17kzTE8LJDbgR",
    name: "Lo Que Siento",
    artists: "Cuco",
    duration_ms: 312000,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/343537cc1ec779e16654dc89cc13931c174413a7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02933e645815b3c470ea2af66a",
    danceability: 0.326,
    energy: 0.685,
    valence: 0.409,
    added_at: "2020-08-24T04:42:41Z",
    link: "https://open.spotify.com/track/3oyf4dalm17kzTE8LJDbgR",
    userID: "royclaud",
    genres: "bedroom pop,dream pop",
  },
  {
    id: "6IZvVAP7VPPnsGX6bvgkqg",
    name: "PRIDE.",
    artists: "Kendrick Lamar",
    duration_ms: 275253,
    popularity: 82,
    preview_url: "https://p.scdn.co/mp3-preview/a762ff9e61a34bf6695d0c181ea77738682d3fbe?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028b52c6b9bc4e43d873869699",
    danceability: 0.665,
    energy: 0.535,
    valence: 0.37,
    added_at: "2020-08-24T03:31:42Z",
    link: "https://open.spotify.com/track/6IZvVAP7VPPnsGX6bvgkqg",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "3Vj8RQkFwOmDvUrwu6MtQZ",
    name: 'Yo Love - From "Queen & Slim: The Soundtrack"',
    artists: "Vince Staples, 6LACK, Mereba",
    duration_ms: 143830,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/a380c5dfae4e8717ba99b116247131e19a542d07?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02aac370232c213219c3c9d47c",
    danceability: 0.703,
    energy: 0.573,
    valence: 0.491,
    added_at: "2020-08-24T03:17:39Z",
    link: "https://open.spotify.com/track/3Vj8RQkFwOmDvUrwu6MtQZ",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,underground hip hop,atl hip hop,melodic rap,r&b,rap,trap,alternative r&b",
  },
  {
    id: "2ZVRyUFQTI5K99wpJElGrD",
    name: "Love Is Only a Feeling",
    artists: "Joey Bada$$",
    duration_ms: 152041,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/f056dcc6458ac9a2099cacc0181a1609c194cd21?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023abc757400e983b632d1c950",
    danceability: 0.629,
    energy: 0.676,
    valence: 0.497,
    added_at: "2020-08-24T02:56:17Z",
    link: "https://open.spotify.com/track/2ZVRyUFQTI5K99wpJElGrD",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap",
  },
  {
    id: "7A0T4eb6jPhhQp3KQis6ww",
    name: "Love Is More Depressing Than Depression",
    artists: "prxz, Shiloh Dynasty",
    duration_ms: 133276,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/f99864770bd036770bf0036c96e7778bc2442410?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022b15c74668d85b91682d448a",
    danceability: 0.75,
    energy: 0.227,
    valence: 0.416,
    added_at: "2020-08-24T02:55:01Z",
    link: "https://open.spotify.com/track/7A0T4eb6jPhhQp3KQis6ww",
    userID: "royclaud",
    genres: "lo-fi chill,sad lo-fi,lo-fi chill,sad lo-fi",
  },
  {
    id: "6rqj2zeKhLy3exkuFi6mSz",
    name: "Answer",
    artists: "Tyler, The Creator",
    duration_ms: 230066,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/2b3bc1d5766d139a2e259b1f72234d6f9088d439?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0222007a4aa8a55fe7b1de5632",
    danceability: 0.352,
    energy: 0.838,
    valence: 0.475,
    added_at: "2020-08-24T02:45:21Z",
    link: "https://open.spotify.com/track/6rqj2zeKhLy3exkuFi6mSz",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "1fOkmYW3ZFkkjIdOZSf596",
    name: "Pink Matter",
    artists: "Frank Ocean, André 3000",
    duration_ms: 268813,
    popularity: 79,
    preview_url: "https://p.scdn.co/mp3-preview/9b20e40914a12dbd20a228ea79795f370213b539?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027aede4855f6d0d738012e2e5",
    danceability: 0.392,
    energy: 0.233,
    valence: 0.494,
    added_at: "2020-08-24T02:42:19Z",
    link: "https://open.spotify.com/track/1fOkmYW3ZFkkjIdOZSf596",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul,atl hip hop,dirty south rap,hip hop,southern hip hop",
  },
  {
    id: "2yZax79pOrYuyIFVW2cZY2",
    name: "Carry On",
    artists: "XXXTENTACION",
    duration_ms: 129720,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/ca588bbbe53e2aa04493ed8bd384dfabb1528940?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02203c89bd4391468eea4cc3f5",
    danceability: 0.735,
    energy: 0.197,
    valence: 0.383,
    added_at: "2020-08-23T07:57:21Z",
    link: "https://open.spotify.com/track/2yZax79pOrYuyIFVW2cZY2",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "50a8bKqlwDEqeiEknrzkTO",
    name: "ball w/o you",
    artists: "21 Savage",
    duration_ms: 195046,
    popularity: 79,
    preview_url: "https://p.scdn.co/mp3-preview/29092f27b687647064cb116289ac78ab2c4a2118?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02280689ecc5e4b2038bb5e4bd",
    danceability: 0.892,
    energy: 0.633,
    valence: 0.459,
    added_at: "2020-08-23T05:49:45Z",
    link: "https://open.spotify.com/track/50a8bKqlwDEqeiEknrzkTO",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap",
  },
  {
    id: "3dQR7lwVcVvRBzrR03ELzd",
    name: "Zeldas Lullaby",
    artists: "A L E X",
    duration_ms: 179147,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/bb2654854f8cbf7a43578646d699348a9f3c2280?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f09237d19caaa2f641654be7",
    danceability: 0.692,
    energy: 0.341,
    valence: 0.173,
    added_at: "2020-08-23T05:42:28Z",
    link: "https://open.spotify.com/track/3dQR7lwVcVvRBzrR03ELzd",
    userID: "royclaud",
    genres: "lo-fi beats",
  },
  {
    id: "7t5MUcFIIayP1M6TNhtWE0",
    name: "Summer Time High Time",
    artists: "Cuco, Kwe$t",
    duration_ms: 208067,
    popularity: 2,
    preview_url: "https://p.scdn.co/mp3-preview/a367842cf51bedc3702183be42d44727bf16f2fd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02597d069476bbc1c63ef5c170",
    danceability: 0.528,
    energy: 0.671,
    valence: 0.581,
    added_at: "2020-08-23T05:36:37Z",
    link: "https://open.spotify.com/track/7t5MUcFIIayP1M6TNhtWE0",
    userID: "royclaud",
    genres: "bedroom pop,dream pop",
  },
  {
    id: "3sNVsP50132BTNlImLx70i",
    name: "Bound 2",
    artists: "Kanye West",
    duration_ms: 229146,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/8467fcd7f77c2cdf4264ebf7321166723252b9ef?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021dacfbc31cc873d132958af9",
    danceability: 0.367,
    energy: 0.665,
    valence: 0.31,
    added_at: "2020-08-23T05:30:53Z",
    link: "https://open.spotify.com/track/3sNVsP50132BTNlImLx70i",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "5LyK4EaVofQjOvJ4OGhdfv",
    name: "The Light",
    artists: "Joey Bada$$",
    duration_ms: 125680,
    popularity: 43,
    preview_url: "https://p.scdn.co/mp3-preview/3ffd104ddea1b28a009435c85d5069382e4d81b5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0263d3d88060ff940456b89719",
    danceability: 0.497,
    energy: 0.595,
    valence: 0.232,
    added_at: "2020-08-23T05:28:37Z",
    link: "https://open.spotify.com/track/5LyK4EaVofQjOvJ4OGhdfv",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap",
  },
  {
    id: "7022xokeCJFSeZxw2npVkn",
    name: "It Takes Time",
    artists: "Trippie Redd",
    duration_ms: 282666,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/00c7f09d72a824a03e5a8a091651089ebe4235dc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028dca9cbf206c611b08a43c17",
    danceability: 0.849,
    energy: 0.304,
    valence: 0.419,
    added_at: "2020-08-23T05:21:09Z",
    link: "https://open.spotify.com/track/7022xokeCJFSeZxw2npVkn",
    userID: "royclaud",
    genres: "melodic rap,rap,trap",
  },
  {
    id: "11zf7m4vw9Ze7cer9Nyhk1",
    name: "Pleaser",
    artists: "Wallows",
    duration_ms: 187895,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/f45c1279490a2d44d9739773379362e4f6dfe5a5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022f9f935ad8d888a88c41d58c",
    danceability: 0.497,
    energy: 0.914,
    valence: 0.385,
    added_at: "2020-08-23T05:19:36Z",
    link: "https://open.spotify.com/track/11zf7m4vw9Ze7cer9Nyhk1",
    userID: "royclaud",
    genres: "pov: indie",
  },
  {
    id: "7FW4HUo39yRwGVxEoHqTa4",
    name: "No Explanation",
    artists: "Joey Bada$$, Pusha T",
    duration_ms: 204386,
    popularity: 35,
    preview_url: "https://p.scdn.co/mp3-preview/10428bf0ea6efe652e5d5bf971ee5c357c41f3fe?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0263d3d88060ff940456b89719",
    danceability: 0.665,
    energy: 0.7,
    valence: 0.604,
    added_at: "2020-08-23T05:17:32Z",
    link: "https://open.spotify.com/track/7FW4HUo39yRwGVxEoHqTa4",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,gangster rap,hip hop,pop rap,rap,southern hip hop,trap,virginia hip hop",
  },
  {
    id: "4qikXelSRKvoCqFcHLB2H2",
    name: "Mercy",
    artists: "Kanye West, Big Sean, Pusha T, 2 Chainz",
    duration_ms: 329320,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/e87a5b2c27552905144ef2d0bdd4b80fc4f13b90?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022127d038204bc32c416d55e2",
    danceability: 0.563,
    energy: 0.496,
    valence: 0.426,
    added_at: "2020-08-23T01:09:49Z",
    link: "https://open.spotify.com/track/4qikXelSRKvoCqFcHLB2H2",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap,detroit hip hop,hip hop,pop rap,r&b,rap,southern hip hop,trap,gangster rap,hip hop,pop rap,rap,southern hip hop,trap,virginia hip hop,atl hip hop,hip hop,pop rap,rap,southern hip hop,trap",
  },
  {
    id: "3YuaBvuZqcwN3CEAyyoaei",
    name: "Like a Stone",
    artists: "Audioslave",
    duration_ms: 293960,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/46d898c23fb28b9f52abf78b794aa500988d2132?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a7292b6863258e889b78d787",
    danceability: 0.614,
    energy: 0.568,
    valence: 0.516,
    added_at: "2020-08-21T21:35:04Z",
    link: "https://open.spotify.com/track/3YuaBvuZqcwN3CEAyyoaei",
    userID: "royclaud",
    genres: "alternative metal,alternative rock,grunge,hard rock,nu metal,permanent wave,post-grunge,rock,supergroup",
  },
  {
    id: "6jGB7sTynUWRnwiz8Lz87P",
    name: "Chains",
    artists: "Aaron May",
    duration_ms: 150256,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/dc575d8b4e4eb161a1286f5260775c2b4bd5838c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02386dcb22d55630ee37c64c9e",
    danceability: 0.968,
    energy: 0.382,
    valence: 0.69,
    added_at: "2020-08-21T07:08:23Z",
    link: "https://open.spotify.com/track/6jGB7sTynUWRnwiz8Lz87P",
    userID: "royclaud",
    genres: "conscious hip hop",
  },
  {
    id: "74irxdVWstNlEQjsvArITq",
    name: "Crazy",
    artists: "Aerosmith",
    duration_ms: 316693,
    popularity: 4,
    preview_url: "https://p.scdn.co/mp3-preview/b63e6855d444a0ce83fb867a53b81721f17e0e73?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02587b26d49596162397b29ec8",
    danceability: 0.37,
    energy: 0.866,
    valence: 0.427,
    added_at: "2020-08-19T16:14:48Z",
    link: "https://open.spotify.com/track/74irxdVWstNlEQjsvArITq",
    userID: "royclaud",
    genres: "album rock,classic rock,hard rock,rock",
  },
  {
    id: "7lrajkVWoVh57d2UcQTysM",
    name: "A Secret Door",
    artists: "Avatar",
    duration_ms: 366757,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/bf7b3ed4a047e4d644a5ea4f73f149879cc44fc9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b439055d17319005304ba358",
    danceability: 0.339,
    energy: 0.825,
    valence: 0.28,
    added_at: "2020-08-17T15:08:36Z",
    link: "https://open.spotify.com/track/7lrajkVWoVh57d2UcQTysM",
    userID: "royclaud",
    genres: "gothenburg metal,swedish melodeath",
  },
  {
    id: "2SAqBLGA283SUiwJ3xOUVI",
    name: "Laugh Now Cry Later (feat. Lil Durk)",
    artists: "Drake, Lil Durk",
    duration_ms: 261492,
    popularity: 74,
    preview_url: "https://p.scdn.co/mp3-preview/3467950d22bbb494c39ef3283d8e60e64080495c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0252c75ed37313b889447011ef",
    danceability: 0.761,
    energy: 0.518,
    valence: 0.522,
    added_at: "2020-08-15T14:55:21Z",
    link: "https://open.spotify.com/track/2SAqBLGA283SUiwJ3xOUVI",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap,chicago drill,chicago rap,drill,hip hop,pop rap,rap,trap",
  },
  {
    id: "3YRCqOhFifThpSRFJ1VWFM",
    name: "November Rain",
    artists: "Guns N' Roses",
    duration_ms: 536066,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/98deb9c370bbaa350be058b3470fbe3bc1e28d9d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e44963b8bb127552ac761873",
    danceability: 0.294,
    energy: 0.64,
    valence: 0.226,
    added_at: "2020-08-14T05:01:33Z",
    link: "https://open.spotify.com/track/3YRCqOhFifThpSRFJ1VWFM",
    userID: "royclaud",
    genres: "glam metal,hard rock,rock",
  },
  {
    id: "2Fxly6AusNFtTf8ZBaVWnh",
    name: "SONGS ABOUT YOU",
    artists: "CHIKA",
    duration_ms: 241612,
    popularity: 38,
    preview_url: "https://p.scdn.co/mp3-preview/03d521763064cba3f54b34b8c80de04b749d8b00?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a4bd1820c34c24c2f6a31514",
    danceability: 0.601,
    energy: 0.446,
    valence: 0.788,
    added_at: "2020-08-14T01:49:24Z",
    link: "https://open.spotify.com/track/2Fxly6AusNFtTf8ZBaVWnh",
    userID: "royclaud",
    genres: "alabama rap,alternative r&b",
  },
  {
    id: "6wQlQrTY5mVS8EGaFZVwVF",
    name: "Smile (with The Weeknd)",
    artists: "Juice WRLD, The Weeknd",
    duration_ms: 196180,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/93fe6e66d0e5f0a70f5bf4ff2e9977a273c49191?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023e0698e4ae5ffb82a005aeeb",
    danceability: 0.694,
    energy: 0.685,
    valence: 0.306,
    added_at: "2020-08-12T04:59:39Z",
    link: "https://open.spotify.com/track/6wQlQrTY5mVS8EGaFZVwVF",
    userID: "royclaud",
    genres: "chicago rap,melodic rap,rap,canadian contemporary r&b,canadian pop,pop",
  },
  {
    id: "1RI9Qpt8QbYtwfCW8npcO1",
    name: "I Didn't Know",
    artists: "Skinshape",
    duration_ms: 218626,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/82ae783c4781bde13630c98c425c5213da51b1f8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02464f8c954c863d38d2ef29ad",
    danceability: 0.646,
    energy: 0.399,
    valence: 0.581,
    added_at: "2020-08-05T06:01:52Z",
    link: "https://open.spotify.com/track/1RI9Qpt8QbYtwfCW8npcO1",
    userID: "royclaud",
    genres: "psychedelic soul",
  },
  {
    id: "6Y0kwGULORFXHzwOBUlMqf",
    name: "Had To",
    artists: "Kevin Gates",
    duration_ms: 219801,
    popularity: 39,
    preview_url: "https://p.scdn.co/mp3-preview/43bdd0796f545ef80953bc16e569358b7d2eaa26?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029d34eb283c35118bdcd2f18a",
    danceability: 0.742,
    energy: 0.689,
    valence: 0.207,
    added_at: "2020-08-05T05:48:28Z",
    link: "https://open.spotify.com/track/6Y0kwGULORFXHzwOBUlMqf",
    userID: "royclaud",
    genres: "baton rouge rap,pop rap,rap,southern hip hop,trap",
  },
  {
    id: "16umSNZfofRpDqTvf8DIAo",
    name: "Wings",
    artists: "Mac Miller",
    duration_ms: 250120,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/28cbe997a569682ae81cd3082573aaa41e65000b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02175c577a61aa13d4fb4b6534",
    danceability: 0.656,
    energy: 0.33,
    valence: 0.349,
    added_at: "2020-08-04T04:30:02Z",
    link: "https://open.spotify.com/track/16umSNZfofRpDqTvf8DIAo",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "6s1uObcOTyiLlm22qRf12y",
    name: "Terminal Sex",
    artists: "Shakewell, Pouya",
    duration_ms: 204965,
    popularity: 8,
    preview_url: "https://p.scdn.co/mp3-preview/e5aa1bb69faa1bae2385ca2ab766d383a5160327?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021954193399803432b65c9d8c",
    danceability: 0.917,
    energy: 0.418,
    valence: 0.486,
    added_at: "2020-08-01T02:37:49Z",
    link: "https://open.spotify.com/track/6s1uObcOTyiLlm22qRf12y",
    userID: "royclaud",
    genres: "dark trap,cloud rap,dark trap,miami hip hop,underground hip hop",
  },
  {
    id: "4paAidoE8VAemwU4jgCc6l",
    name: "all my friends",
    artists: "21 Savage",
    duration_ms: 211785,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/71a8762c8def55f8eafa469321b63de0e2b1490b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02280689ecc5e4b2038bb5e4bd",
    danceability: 0.713,
    energy: 0.62,
    valence: 0.206,
    added_at: "2020-07-31T23:37:59Z",
    link: "https://open.spotify.com/track/4paAidoE8VAemwU4jgCc6l",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap",
  },
  {
    id: "7zQX3lVGZ0GNYuudry6GnF",
    name: "Over Your Head",
    artists: "Future, Lil Uzi Vert",
    duration_ms: 186842,
    popularity: 25,
    preview_url: "https://p.scdn.co/mp3-preview/5b110d663a72c48df3194ef7634057af2e2d99fc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027e55d7d3126266a43f5733be",
    danceability: 0.775,
    energy: 0.749,
    valence: 0.387,
    added_at: "2020-07-31T23:08:46Z",
    link: "https://open.spotify.com/track/7zQX3lVGZ0GNYuudry6GnF",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap,southern hip hop,trap,hip hop,melodic rap,philly rap,rage rap,rap,trap",
  },
  {
    id: "51EC3I1nQXpec4gDk0mQyP",
    name: "90210 (feat. Kacy Hill)",
    artists: "Travis Scott, Kacy Hill",
    duration_ms: 339066,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/c357bde24bdd21336d2c218f4331909bc3a458ea?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026cfd9a7353f98f5165ea6160",
    danceability: 0.402,
    energy: 0.527,
    valence: 0.31,
    added_at: "2020-07-28T23:21:16Z",
    link: "https://open.spotify.com/track/51EC3I1nQXpec4gDk0mQyP",
    userID: "royclaud",
    genres: "rap,slap house,la pop,modern alternative pop",
  },
  {
    id: "3Ph7fws05DvPwpn5CQHTBy",
    name: "Midlife Crisis",
    artists: "Faith No More",
    duration_ms: 259866,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/97bc2ba877ef6a023756f222de0f1a0e68b66df1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0236842671366bfeb3d7c5f19e",
    danceability: 0.669,
    energy: 0.656,
    valence: 0.464,
    added_at: "2020-07-28T19:05:08Z",
    link: "https://open.spotify.com/track/3Ph7fws05DvPwpn5CQHTBy",
    userID: "royclaud",
    genres: "alternative metal,alternative rock,funk metal,funk rock,hard rock,nu metal,post-grunge,rap metal,rock",
  },
  {
    id: "2YodwKJnbPyNKe8XXSE9V7",
    name: "Lithium",
    artists: "Nirvana",
    duration_ms: 257053,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/bc669fd79c34b2ee7addf1c010c2a739f97a0242?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e175a19e530c898d167d39bf",
    danceability: 0.678,
    energy: 0.656,
    valence: 0.485,
    added_at: "2020-07-27T02:42:50Z",
    link: "https://open.spotify.com/track/2YodwKJnbPyNKe8XXSE9V7",
    userID: "royclaud",
    genres: "grunge,permanent wave,rock",
  },
  {
    id: "4Arjo1XR9qXsLstvU9fFV3",
    name: "Last Breath",
    artists: "Future",
    duration_ms: 239800,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/726bc6e1a79c298ce655023e880009e453433038?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02240501e829a9a109600cedd2",
    danceability: 0.675,
    energy: 0.758,
    valence: 0.152,
    added_at: "2020-07-25T17:39:00Z",
    link: "https://open.spotify.com/track/4Arjo1XR9qXsLstvU9fFV3",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap,southern hip hop,trap",
  },
  {
    id: "0FlfN5cbUUpIHCRH8X1M44",
    name: "t h e . c l i m b . b a c k",
    artists: "J. Cole",
    duration_ms: 304640,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/2638b60e6c4df19c1289e85e3ca2bd5c67e57585?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027a6aa18b884bea97981b7542",
    danceability: 0.61,
    energy: 0.569,
    valence: 0.287,
    added_at: "2020-07-23T15:50:47Z",
    link: "https://open.spotify.com/track/0FlfN5cbUUpIHCRH8X1M44",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,north carolina hip hop,rap",
  },
  {
    id: "27IZXTdeGYjU6m5lTyDpmr",
    name: "The Funeral",
    artists: "Band of Horses",
    duration_ms: 322173,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/a3d4810ce9c8ea8771bf5d2de93cadc44ec1ee77?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0216f55490f35f318dad8b0619",
    danceability: 0.318,
    energy: 0.76,
    valence: 0.104,
    added_at: "2020-07-21T05:06:21Z",
    link: "https://open.spotify.com/track/27IZXTdeGYjU6m5lTyDpmr",
    userID: "royclaud",
    genres: "chamber pop,indie folk,indie rock,indietronica,modern rock,seattle indie,stomp and holler",
  },
  {
    id: "5ArYnjwjHMDuAnttxCVgZh",
    name: "Mook In New Mexico",
    artists: "Bas",
    duration_ms: 197640,
    popularity: 24,
    preview_url: "https://p.scdn.co/mp3-preview/f8f9e4d7ec493b32d78d4430cb992c013239b7f0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bfb8819d6552d9b229c66e68",
    danceability: 0.543,
    energy: 0.61,
    valence: 0.229,
    added_at: "2020-07-14T23:53:42Z",
    link: "https://open.spotify.com/track/5ArYnjwjHMDuAnttxCVgZh",
    userID: "royclaud",
    genres: "queens hip hop,sudanese hip hop,sudanese pop,underground hip hop",
  },
  {
    id: "2fZTS0VboxRpF0FdfY7E5P",
    name: "Conversations",
    artists: "Juice WRLD",
    duration_ms: 181661,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/e30aff886987fe671eb380021041515d38c06703?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f09d6c6466f2c3cbc4271b28",
    danceability: 0.747,
    energy: 0.622,
    valence: 0.57,
    added_at: "2020-07-10T05:31:39Z",
    link: "https://open.spotify.com/track/2fZTS0VboxRpF0FdfY7E5P",
    userID: "royclaud",
    genres: "chicago rap,melodic rap,rap",
  },
  {
    id: "2w9htZtkcbR48NCWzXjfvb",
    name: "Fight the Feeling",
    artists: "Mac Miller, Kendrick Lamar, Iman Omari",
    duration_ms: 301769,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/3b44e63329b3b132eee35099d08886028608f0a3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02908afd2dae7320208da889fd",
    danceability: 0.569,
    energy: 0.881,
    valence: 0.811,
    added_at: "2020-07-08T17:55:17Z",
    link: "https://open.spotify.com/track/2w9htZtkcbR48NCWzXjfvb",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap,conscious hip hop,hip hop,rap,west coast rap,alternative r&b",
  },
  {
    id: "2I9foKseoFQh07p6sD2voE",
    name: "Many Men (Wish Death)",
    artists: "50 Cent",
    duration_ms: 256226,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/286d7529f799f0b180c80c37635fb4d1fffbe749?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f7f74100d5cc850e01172cbf",
    danceability: 0.654,
    energy: 0.826,
    valence: 0.417,
    added_at: "2020-07-07T19:47:02Z",
    link: "https://open.spotify.com/track/2I9foKseoFQh07p6sD2voE",
    userID: "royclaud",
    genres: "east coast hip hop,gangster rap,hip hop,pop rap,queens hip hop,rap",
  },
  {
    id: "0PvFJmanyNQMseIFrU708S",
    name: "For The Night (feat. Lil Baby & DaBaby)",
    artists: "Pop Smoke, Lil Baby, DaBaby",
    duration_ms: 190476,
    popularity: 73,
    preview_url: "https://p.scdn.co/mp3-preview/71bc9111945eb00ee5c3a292f9a393f1f31b1be5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0277ada0863603903f57b34369",
    danceability: 0.823,
    energy: 0.586,
    valence: 0.347,
    added_at: "2020-07-03T17:07:59Z",
    link: "https://open.spotify.com/track/0PvFJmanyNQMseIFrU708S",
    userID: "royclaud",
    genres: "brooklyn drill,hip hop,rap,atl hip hop,atl trap,rap,trap,hip hop,north carolina hip hop,pop rap,rap,trap",
  },
  {
    id: "6nO3tr47nr2P7f3hXb8JIo",
    name: "Champion (feat. Travis Scott)",
    artists: "NAV, Travis Scott",
    duration_ms: 195053,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/aac642032b371e3b5f8b78dd8dfac590511dd1e5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020231368c38cf69a722ed1d0b",
    danceability: 0.643,
    energy: 0.782,
    valence: 0.393,
    added_at: "2020-07-03T17:07:54Z",
    link: "https://open.spotify.com/track/6nO3tr47nr2P7f3hXb8JIo",
    userID: "royclaud",
    genres: "canadian hip hop,canadian trap,melodic rap,rap,trap,rap,slap house",
  },
  {
    id: "3eV2WT9YhfzkxbufnBF8wK",
    name: "Creature (feat. Swae Lee)",
    artists: "Pop Smoke, Swae Lee",
    duration_ms: 202815,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/a706480298aa6c6af492e70c7c16562f3c72d515?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0277ada0863603903f57b34369",
    danceability: 0.794,
    energy: 0.717,
    valence: 0.372,
    added_at: "2020-07-03T05:50:11Z",
    link: "https://open.spotify.com/track/3eV2WT9YhfzkxbufnBF8wK",
    userID: "royclaud",
    genres: "brooklyn drill,hip hop,rap,melodic rap,rap,trap",
  },
  {
    id: "0aMHIW1lqrulVCx0LLlr6a",
    name: "Cocoa Butter Kisses",
    artists: "Chance the Rapper, VIC MENSA, Twista",
    duration_ms: 307187,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/72d35c6b5fbf36b5bb32f5009efd9de4b35e7b17?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d95ab48a8a9de3c4a2cbfe80",
    danceability: 0.679,
    energy: 0.645,
    valence: 0.203,
    added_at: "2020-06-30T18:11:51Z",
    link: "https://open.spotify.com/track/0aMHIW1lqrulVCx0LLlr6a",
    userID: "royclaud",
    genres: "chicago rap,conscious hip hop,hip hop,pop rap,rap,trap,canadian hip hop,chicago rap,conscious hip hop,underground hip hop,chicago rap,dirty south rap,gangster rap,trap",
  },
  {
    id: "6Nle9hKrkL1wQpwNfEkxjh",
    name: "Chanel",
    artists: "Frank Ocean",
    duration_ms: 210285,
    popularity: 77,
    preview_url: "https://p.scdn.co/mp3-preview/0eb4e74aaf59feacf5bc4edeaf5e03360eb86385?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a0b780c23fc3c19bd412b234",
    danceability: 0.776,
    energy: 0.503,
    valence: 0.473,
    added_at: "2020-06-30T18:07:38Z",
    link: "https://open.spotify.com/track/6Nle9hKrkL1wQpwNfEkxjh",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "1hxVQkhDJN2UnkgeqX68D3",
    name: "a few words for the firing squad (radiation)",
    artists: "Run The Jewels, El-P, Killer Mike",
    duration_ms: 402200,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/d7bf9d8a63ac9182b1f6268f710570ea3daafa23?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026494393bd8d0fc0d338af3d6",
    danceability: 0.571,
    energy: 0.605,
    valence: 0.0859,
    added_at: "2020-06-26T03:06:51Z",
    link: "https://open.spotify.com/track/1hxVQkhDJN2UnkgeqX68D3",
    userID: "royclaud",
    genres:
      "alternative hip hop,escape room,hip hop,political hip hop,abstract hip hop,alternative hip hop,east coast hip hop,experimental hip hop,alternative hip hop,atl hip hop,conscious hip hop,hip hop,old school atlanta hip hop,political hip hop,southern hip hop",
  },
  {
    id: "5dv4YSgkpflUQ31vIW9qXg",
    name: "We Ball",
    artists: "Ace Hood",
    duration_ms: 200655,
    popularity: 53,
    preview_url: "https://p.scdn.co/mp3-preview/c6291ccfebfe227f1668b3be03f7872296914fae?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0265930858e3f6563c2dd2e5bc",
    danceability: 0.752,
    energy: 0.525,
    valence: 0.151,
    added_at: "2020-06-22T16:09:03Z",
    link: "https://open.spotify.com/track/5dv4YSgkpflUQ31vIW9qXg",
    userID: "royclaud",
    genres: "dirty south rap,miami hip hop,pop rap,rap,southern hip hop,trap",
  },
  {
    id: "1z667LebVh3DtYNrVJEao0",
    name: "All the Above (feat. T-Pain)",
    artists: "Maino, T-Pain",
    duration_ms: 319940,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/67bcbc67a2be22b7fc2060d2a7fe1d8c18510455?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d365183f20a7edea176cf4c2",
    danceability: 0.374,
    energy: 0.888,
    valence: 0.365,
    added_at: "2020-06-20T03:19:05Z",
    link: "https://open.spotify.com/track/1z667LebVh3DtYNrVJEao0",
    userID: "royclaud",
    genres: "dance pop,gangster rap,hip hop,pop rap,r&b,rap,southern hip hop,trap,urban contemporary",
  },
  {
    id: "0325GnRiA0zsve4i3bNVs0",
    name: "Halfway Off The Balcony",
    artists: "Big Sean",
    duration_ms: 230106,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/d057060741cd0ca1deed271e19a3a961179f7e24?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024bb9d270eb2a783c1be5a66d",
    danceability: 0.562,
    energy: 0.74,
    valence: 0.149,
    added_at: "2020-06-18T22:08:51Z",
    link: "https://open.spotify.com/track/0325GnRiA0zsve4i3bNVs0",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,pop rap,r&b,rap,southern hip hop,trap",
  },
  {
    id: "5rLqKi5grzujEQsuYmiWeh",
    name: "Hendrix",
    artists: "Wyclef Jean",
    duration_ms: 207684,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/a905dd7ac39090fcdd44ff8691b04839e11ca96a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022307c1cc5ec49144bf211392",
    danceability: 0.689,
    energy: 0.531,
    valence: 0.366,
    added_at: "2020-06-18T22:08:28Z",
    link: "https://open.spotify.com/track/5rLqKi5grzujEQsuYmiWeh",
    userID: "royclaud",
    genres: "rap kreyol",
  },
  {
    id: "3I0FBDc1c1BLNtXWKVjmFg",
    name: "untitled 02 | 06.23.2014.",
    artists: "Kendrick Lamar",
    duration_ms: 258826,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/b12a8859b702c4976761a7771e8a3ab6b9fdc707?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028c697f553a46006a5d8886b2",
    danceability: 0.667,
    energy: 0.508,
    valence: 0.093,
    added_at: "2020-06-18T22:08:20Z",
    link: "https://open.spotify.com/track/3I0FBDc1c1BLNtXWKVjmFg",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "70ATm56tH7OrQ1zurYssz0",
    name: "I Need A Doctor",
    artists: "Dr. Dre, Eminem, Skylar Grey",
    duration_ms: 283733,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/4baaf6c8f5c41afbc17120cf0c5272734ed1baac?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d27f8a33b8a8c0417cdca53f",
    danceability: 0.594,
    energy: 0.946,
    valence: 0.397,
    added_at: "2020-06-18T21:58:06Z",
    link: "https://open.spotify.com/track/70ATm56tH7OrQ1zurYssz0",
    userID: "royclaud",
    genres: "g funk,gangster rap,hip hop,rap,west coast rap,detroit hip hop,hip hop,rap,piano rock,viral pop",
  },
  {
    id: "3CgZCQyuyxHRMWB9BTwmni",
    name: "Swim Good",
    artists: "Frank Ocean",
    duration_ms: 257186,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/b5c2a89cf491b55f67512713c8042f48c9b23b11?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ebbff7725d3ce0739be01787",
    danceability: 0.653,
    energy: 0.668,
    valence: 0.711,
    added_at: "2020-06-18T21:57:37Z",
    link: "https://open.spotify.com/track/3CgZCQyuyxHRMWB9BTwmni",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "7L3YDa0Fd8pmzpAyu6EKOS",
    name: "Fragile",
    artists: "Tech N9ne, Kendrick Lamar, ¡MAYDAY!, Kendall Morgan",
    duration_ms: 236373,
    popularity: 54,
    preview_url: "https://p.scdn.co/mp3-preview/f6a5e609b4a19e23a8d072a545e835edc50ff08e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025021c52bfa5ca127b5d35d7f",
    danceability: 0.715,
    energy: 0.468,
    valence: 0.673,
    added_at: "2020-06-18T21:57:19Z",
    link: "https://open.spotify.com/track/7L3YDa0Fd8pmzpAyu6EKOS",
    userID: "royclaud",
    genres: "detroit hip hop,kansas city hip hop,pop rap,conscious hip hop,hip hop,rap,west coast rap,miami hip hop,nashville singer-songwriter",
  },
  {
    id: "3vWzyGTu6Ovo1GdrcJqH6e",
    name: "Scared of the Dark (feat. XXXTENTACION)",
    artists: "Lil Wayne, Ty Dolla $ign, XXXTENTACION",
    duration_ms: 233000,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/3f1f3795be0b6747b24ca281f4f9e2560d895c6b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e2e352d89826aef6dbd5ff8f",
    danceability: 0.549,
    energy: 0.625,
    valence: 0.158,
    added_at: "2020-04-29T22:26:23Z",
    link: "https://open.spotify.com/track/3vWzyGTu6Ovo1GdrcJqH6e",
    userID: "royclaud",
    genres: "hip hop,new orleans rap,pop rap,rap,trap,hip hop,pop rap,r&b,southern hip hop,trap,trap soul,emo rap,miami hip hop,rap",
  },
  {
    id: "7AzlLxHn24DxjgQX73F9fU",
    name: "No Idea",
    artists: "Don Toliver",
    duration_ms: 154424,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/3f83928fb66c8e92a3d5da85d914de54cf20658e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0245190a074bef3e8ce868b60c",
    danceability: 0.652,
    energy: 0.631,
    valence: 0.35,
    added_at: "2020-04-22T17:14:51Z",
    link: "https://open.spotify.com/track/7AzlLxHn24DxjgQX73F9fU",
    userID: "royclaud",
    genres: "rap",
  },
  {
    id: "6JyEh4kl9DLwmSAoNDRn5b",
    name: "4th Dimension",
    artists: "KIDS SEE GHOSTS, Louis Prima",
    duration_ms: 153351,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/7632901c53461c79efd4dda579b3e4ef5c0f05c5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02013c00ee367dd85396f79c82",
    danceability: 0.765,
    energy: 0.542,
    valence: 0.734,
    added_at: "2020-04-21T20:38:37Z",
    link: "https://open.spotify.com/track/6JyEh4kl9DLwmSAoNDRn5b",
    userID: "royclaud",
    genres: "rap,adult standards,jump blues,lounge,swing",
  },
  {
    id: "1y4jsQt7MjnZhiD1L6qFBC",
    name: "Cradles",
    artists: "Sub Urban",
    duration_ms: 209829,
    popularity: 18,
    preview_url: "https://p.scdn.co/mp3-preview/584c63beb79d87d11ed0cefbf158914b9d8730ec?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fb032fd9672efc5ac88bd03f",
    danceability: 0.534,
    energy: 0.589,
    valence: 0.632,
    added_at: "2020-04-16T08:52:17Z",
    link: "https://open.spotify.com/track/1y4jsQt7MjnZhiD1L6qFBC",
    userID: "royclaud",
    genres: "modern indie pop",
  },
  {
    id: "25yup6WYnPoITrfzhkBLmt",
    name: "I Can't Sleep",
    artists: "Powfu, Sarcastic Sounds",
    duration_ms: 152500,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/1bb5c045cf2bf383185caa7bcb814122bba3a319?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028bb2b5f766a9bac4496a584e",
    danceability: 0.805,
    energy: 0.479,
    valence: 0.363,
    added_at: "2020-04-16T08:05:20Z",
    link: "https://open.spotify.com/track/25yup6WYnPoITrfzhkBLmt",
    userID: "royclaud",
    genres: "sad lo-fi,sad rap,lo-fi chill,sad lo-fi",
  },
  {
    id: "3n69hLUdIsSa1WlRmjMZlW",
    name: "Breezeblocks",
    artists: "alt-J",
    duration_ms: 227080,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/3738a90803991c4aba1f2e0e9c8dd5cf706d8b62?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02609c89ad17eb28c2013c56c6",
    danceability: 0.615,
    energy: 0.658,
    valence: 0.293,
    added_at: "2020-04-16T03:19:22Z",
    link: "https://open.spotify.com/track/3n69hLUdIsSa1WlRmjMZlW",
    userID: "royclaud",
    genres: "indie rock,indietronica,modern alternative rock,modern rock,rock,shimmer pop",
  },
  {
    id: "72794Eag03xdy7TO0KNuid",
    name: "Cayendo (Side A - Acoustic)",
    artists: "Frank Ocean",
    duration_ms: 202200,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/f4bbff20e25e126179ed8161b9ac68228a2d7676?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0235d8be841e9f7f777fbca446",
    danceability: 0.35,
    energy: 0.12,
    valence: 0.121,
    added_at: "2020-04-03T08:31:56Z",
    link: "https://open.spotify.com/track/72794Eag03xdy7TO0KNuid",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "127QTOFJsJQp5LbJbu3A1y",
    name: "Toosie Slide",
    artists: "Drake",
    duration_ms: 247058,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/f0cd0adad3b40f3c20196817c1395d8acc9de1f6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026443676b54522a86f6323e65",
    danceability: 0.834,
    energy: 0.454,
    valence: 0.837,
    added_at: "2020-04-03T08:31:56Z",
    link: "https://open.spotify.com/track/127QTOFJsJQp5LbJbu3A1y",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "5S5u0EWtlblH00iTNExZ6X",
    name: "I Think I Like When It Rains",
    artists: "WILLIS",
    duration_ms: 278293,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/8d0242d7cbf42453b05071c78e050eb3ccc97fd3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a859970d75b34f4f4e87e420",
    danceability: 0.622,
    energy: 0.471,
    valence: 0.278,
    added_at: "2020-03-30T20:16:06Z",
    link: "https://open.spotify.com/track/5S5u0EWtlblH00iTNExZ6X",
    userID: "royclaud",
    genres: "alabama indie",
  },
  {
    id: "08AJ0xQv5LRZsvXf1Jkr2y",
    name: "Gym Class",
    artists: "Lil Peep",
    duration_ms: 217000,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/8e901377b8e6bbc7b38f2b3551acb8f59ca18437?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f29c7353407114a7e08330d4",
    danceability: 0.622,
    energy: 0.347,
    valence: 0.0757,
    added_at: "2020-03-29T06:47:49Z",
    link: "https://open.spotify.com/track/08AJ0xQv5LRZsvXf1Jkr2y",
    userID: "royclaud",
    genres: "cloud rap,emo rap,lgbtq+ hip hop",
  },
  {
    id: "0lSZh5W0wDeurkGzLYY6hf",
    name: "Turks (feat. Travis Scott)",
    artists: "NAV, Gunna, Travis Scott",
    duration_ms: 161377,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/00cbe1d7b4a0297ca283d2477aea086df0787555?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0219df39f54bf7f87a2897136e",
    danceability: 0.893,
    energy: 0.618,
    valence: 0.389,
    added_at: "2020-03-27T18:30:46Z",
    link: "https://open.spotify.com/track/0lSZh5W0wDeurkGzLYY6hf",
    userID: "royclaud",
    genres: "canadian hip hop,canadian trap,melodic rap,rap,trap,atl hip hop,melodic rap,rap,trap,rap,slap house",
  },
  {
    id: "011ZwIM48eD8ee2UBpG8nK",
    name: "12.38 (feat. 21 Savage, Ink & Kadhja Bonet)",
    artists: "Childish Gambino, 21 Savage, Ink, Kadhja Bonet",
    duration_ms: 392186,
    popularity: 5,
    preview_url: "null",
    image: "https://i.scdn.co/image/ab67616d00001e028c5cdd622b6b3acb4f26ba93",
    danceability: 0.691,
    energy: 0.524,
    valence: 0.41,
    added_at: "2020-03-22T08:34:49Z",
    link: "https://open.spotify.com/track/011ZwIM48eD8ee2UBpG8nK",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap,atl hip hop,hip hop,rap,alternative r&b,indie soul,psychedelic soul",
  },
  {
    id: "6H0AwSQ20mo62jGlPGB8S6",
    name: "It's A Vibe",
    artists: "2 Chainz, Ty Dolla $ign, Trey Songz, Jhené Aiko",
    duration_ms: 210200,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/a4d0171b5063a7583fb043c8a89dc90abcc8f635?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c0c05243a846dda6c84607f9",
    danceability: 0.82,
    energy: 0.502,
    valence: 0.527,
    added_at: "2020-03-21T22:22:39Z",
    link: "https://open.spotify.com/track/6H0AwSQ20mo62jGlPGB8S6",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,pop rap,rap,southern hip hop,trap,hip hop,pop rap,r&b,southern hip hop,trap,trap soul,dance pop,r&b,southern hip hop,trap,urban contemporary,r&b",
  },
  {
    id: "3OJ4LEE50iFELaHiZ09zif",
    name: "Purple Emoji (feat. J. Cole)",
    artists: "Ty Dolla $ign, J. Cole",
    duration_ms: 163754,
    popularity: 52,
    preview_url: "https://p.scdn.co/mp3-preview/b2ad339378a37579e0a0739eae903a2efefb23c6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e6ad2271964d98547145e723",
    danceability: 0.707,
    energy: 0.543,
    valence: 0.824,
    added_at: "2020-03-21T22:22:38Z",
    link: "https://open.spotify.com/track/3OJ4LEE50iFELaHiZ09zif",
    userID: "royclaud",
    genres: "hip hop,pop rap,r&b,southern hip hop,trap,trap soul,conscious hip hop,hip hop,north carolina hip hop,rap",
  },
  {
    id: "7iG6aXHLFg9tBzP6OIyuJa",
    name: "Shimmy",
    artists: "Aminé",
    duration_ms: 133995,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/41f7f70c0e3f21bfa8c386ab24a109838c876328?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0268e9c47061aa8e68a3146aa1",
    danceability: 0.467,
    energy: 0.879,
    valence: 0.795,
    added_at: "2020-03-13T23:35:52Z",
    link: "https://open.spotify.com/track/7iG6aXHLFg9tBzP6OIyuJa",
    userID: "royclaud",
    genres: "pop rap,portland hip hop,rap,underground hip hop",
  },
  {
    id: "3jHdKaLCkuNEkWcLVmQPCX",
    name: "BEST INTEREST",
    artists: "Tyler, The Creator",
    duration_ms: 127921,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/cf36ca28a947227ba28d7ac88bfd7aec8b49e55f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ab8995f18dc3dd9f34a6acd4",
    danceability: 0.596,
    energy: 0.575,
    valence: 0.34,
    added_at: "2020-03-03T23:56:41Z",
    link: "https://open.spotify.com/track/3jHdKaLCkuNEkWcLVmQPCX",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "79MSEdtXuudhGhC5AtG07g",
    name: "Break from Toronto",
    artists: "PARTYNEXTDOOR",
    duration_ms: 99213,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/89f708c823dc7d3827d2f063b9075b51ec47f446?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020a4fd8aa50652821b1a2a054",
    danceability: 0.596,
    energy: 0.678,
    valence: 0.259,
    added_at: "2020-03-03T19:20:50Z",
    link: "https://open.spotify.com/track/79MSEdtXuudhGhC5AtG07g",
    userID: "royclaud",
    genres: "r&b,rap,urban contemporary",
  },
  {
    id: "4GKcaqt6PFor4siHXMO42e",
    name: "Novocaine",
    artists: "The Unlikely Candidates",
    duration_ms: 245240,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/0c5e4e79814912d61b50f5725b2ea2764b91e974?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020490e22363113ee0f8262624",
    danceability: 0.652,
    energy: 0.833,
    valence: 0.231,
    added_at: "2020-02-25T05:31:16Z",
    link: "https://open.spotify.com/track/4GKcaqt6PFor4siHXMO42e",
    userID: "royclaud",
    genres: "fort worth indie,modern alternative rock,modern rock,pov: indie",
  },
  {
    id: "5nkUIVKqOqdpB6ApKgEMkv",
    name: "Stay Ready (What A Life)",
    artists: "Jhené Aiko, Kendrick Lamar",
    duration_ms: 382760,
    popularity: 75,
    preview_url: "https://p.scdn.co/mp3-preview/0422233b01e96c740ab79ed11af76ab4a86a5710?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a0fa057b8ad3f3c4969f0c97",
    danceability: 0.347,
    energy: 0.493,
    valence: 0.308,
    added_at: "2020-02-21T04:36:42Z",
    link: "https://open.spotify.com/track/5nkUIVKqOqdpB6ApKgEMkv",
    userID: "royclaud",
    genres: "r&b,conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "4ejyZ4uJEXUURcgKp1kP8v",
    name: "After Hours",
    artists: "The Weeknd",
    duration_ms: 361946,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/2375bc6998d765b02b229ff44e5c3d7b75fff74a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0280e1e80874a5b25317c380c5",
    danceability: 0.663,
    energy: 0.567,
    valence: 0.139,
    added_at: "2020-02-20T03:51:43Z",
    link: "https://open.spotify.com/track/4ejyZ4uJEXUURcgKp1kP8v",
    userID: "royclaud",
    genres: "canadian contemporary r&b,canadian pop,pop",
  },
  {
    id: "0ZAiqymvbQUZAh9y6ZT3Rm",
    name: "XO",
    artists: "EDEN",
    duration_ms: 159512,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/7c5c80d80a076ddef805d2ebefff7c371b6b20ef?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c61b46cf682d7f0183fcb87c",
    danceability: 0.611,
    energy: 0.39,
    valence: 0.617,
    added_at: "2020-02-19T20:03:30Z",
    link: "https://open.spotify.com/track/0ZAiqymvbQUZAh9y6ZT3Rm",
    userID: "royclaud",
    genres: "indie poptimism",
  },
  {
    id: "3UEnF6y5tyHVtMzldS3svp",
    name: "Everything Black",
    artists: "Unlike Pluto, Mike Taylor",
    duration_ms: 228979,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/4767dbf4100dc04ef1762c77557d689524e15c5a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0296957b0279e407485635f831",
    danceability: 0.62,
    energy: 0.864,
    valence: 0.395,
    added_at: "2020-02-14T07:29:25Z",
    link: "https://open.spotify.com/track/3UEnF6y5tyHVtMzldS3svp",
    userID: "royclaud",
    genres: "la pop",
  },
  {
    id: "7clyJIrLkEbXUDwj1tC9zz",
    name: "Blood // Water",
    artists: "grandson",
    duration_ms: 214520,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/08d5aa6d189997af124a2ed5e25ed3e63d10509f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fa78a51a07cb7bf1ce9febe4",
    danceability: 0.504,
    energy: 0.891,
    valence: 0.628,
    added_at: "2020-02-14T07:19:07Z",
    link: "https://open.spotify.com/track/7clyJIrLkEbXUDwj1tC9zz",
    userID: "royclaud",
    genres: "modern alternative rock,modern rock,pov: indie,rock",
  },
  {
    id: "4TnjEaWOeW0eKTKIEvJyCa",
    name: "Falling",
    artists: "Trevor Daniel",
    duration_ms: 159381,
    popularity: 2,
    preview_url: "https://p.scdn.co/mp3-preview/0d911f7650ed127fbbdd7ec6a44ec5d642d1896a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020e4bd9ce99988f3182bf771a",
    danceability: 0.785,
    energy: 0.431,
    valence: 0.236,
    added_at: "2020-02-09T22:24:03Z",
    link: "https://open.spotify.com/track/4TnjEaWOeW0eKTKIEvJyCa",
    userID: "royclaud",
    genres: "melodic rap",
  },
  {
    id: "364dI1bYnvamSnBJ8JcNzN",
    name: "Intentions",
    artists: "Justin Bieber, Quavo",
    duration_ms: 212868,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/411a8e58a0ac5f44eebb3600b5f6a9547be5a4a2?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0208e30ab6a058429303d75876",
    danceability: 0.811,
    energy: 0.553,
    valence: 0.86,
    added_at: "2020-02-09T22:22:54Z",
    link: "https://open.spotify.com/track/364dI1bYnvamSnBJ8JcNzN",
    userID: "royclaud",
    genres: "canadian pop,pop,atl hip hop,melodic rap,rap,trap",
  },
  {
    id: "6fvqvUQr12qghH4N8gpAch",
    name: "Believe (feat. Justin Timberlake)",
    artists: "Meek Mill, Justin Timberlake",
    duration_ms: 211303,
    popularity: 43,
    preview_url: "https://p.scdn.co/mp3-preview/8d8a47119a1015335a6c46cf4f480e0bcf18d286?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ac0968c5d2c6818c1a3a8637",
    danceability: 0.628,
    energy: 0.75,
    valence: 0.374,
    added_at: "2020-02-07T05:57:02Z",
    link: "https://open.spotify.com/track/6fvqvUQr12qghH4N8gpAch",
    userID: "royclaud",
    genres: "hip hop,philly rap,pop rap,rap,southern hip hop,trap,dance pop,pop",
  },
  {
    id: "0fhyEKQIXdk8HJv1nh4sWn",
    name: "2002 S500 Benz",
    artists: "Larry June",
    duration_ms: 221276,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/d2a7606c4c3694e7b949e3f96c05d80f277cb2a5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0267ba62a329fa39d2693697db",
    danceability: 0.505,
    energy: 0.662,
    valence: 0.56,
    added_at: "2020-02-02T03:34:37Z",
    link: "https://open.spotify.com/track/0fhyEKQIXdk8HJv1nh4sWn",
    userID: "royclaud",
    genres: "cali rap",
  },
  {
    id: "1bRO28yzxgO3y3UmNR29TZ",
    name: "I Do It (ft. Big Sean, Lil Baby)",
    artists: "Lil Wayne, Big Sean, Lil Baby",
    duration_ms: 184440,
    popularity: 48,
    preview_url: "https://p.scdn.co/mp3-preview/3e410c81edc04bfa606b9d2f10c411da0b11d74b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021fceb0586044165d23cb0197",
    danceability: 0.804,
    energy: 0.528,
    valence: 0.321,
    added_at: "2020-02-01T16:04:33Z",
    link: "https://open.spotify.com/track/1bRO28yzxgO3y3UmNR29TZ",
    userID: "royclaud",
    genres: "hip hop,new orleans rap,pop rap,rap,trap,detroit hip hop,hip hop,pop rap,r&b,rap,southern hip hop,trap,atl hip hop,atl trap,rap,trap",
  },
  {
    id: "3IZLxPxgG1evWHg0QvcVKd",
    name: "If I Ruled the World (Imagine That)",
    artists: "Nas, Ms. Lauryn Hill",
    duration_ms: 283053,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/7abb95c11258791deb81c381c14519fabb3abaf1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027bc80a923b4034b444ca10f5",
    danceability: 0.851,
    energy: 0.634,
    valence: 0.531,
    added_at: "2020-01-27T04:39:05Z",
    link: "https://open.spotify.com/track/3IZLxPxgG1evWHg0QvcVKd",
    userID: "royclaud",
    genres: "conscious hip hop,east coast hip hop,gangster rap,hardcore hip hop,hip hop,queens hip hop,rap,alternative r&b,conscious hip hop,hip hop,neo soul,new jersey rap,r&b",
  },
  {
    id: "3Ti0GdlrotgwsAVBBugv0I",
    name: "Can I Kick It?",
    artists: "A Tribe Called Quest",
    duration_ms: 251573,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/cb6c256d2212dd8e942b10216313ac1d2a5480c0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024ba1fe238a517021ebcc2ace",
    danceability: 0.848,
    energy: 0.666,
    valence: 0.744,
    added_at: "2020-01-27T00:56:25Z",
    link: "https://open.spotify.com/track/3Ti0GdlrotgwsAVBBugv0I",
    userID: "royclaud",
    genres: "conscious hip hop,east coast hip hop,golden age hip hop,hip hop,jazz rap,queens hip hop",
  },
  {
    id: "5oynsOy80DnodTslgaj3cr",
    name: "Marcy Me",
    artists: "JAY-Z",
    duration_ms: 174426,
    popularity: 52,
    preview_url: "https://p.scdn.co/mp3-preview/bd0b8b654bb354f3208bb0dd233d0184fb3a04db?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027513c4956ea5552caf02eab6",
    danceability: 0.518,
    energy: 0.678,
    valence: 0.604,
    added_at: "2020-01-25T18:22:51Z",
    link: "https://open.spotify.com/track/5oynsOy80DnodTslgaj3cr",
    userID: "royclaud",
    genres: "east coast hip hop,gangster rap,hip hop,pop rap,rap",
  },
  {
    id: "6Iuh3vNjpWhSeHM3RZgzQL",
    name: "Bam",
    artists: "JAY-Z, Damian Marley",
    duration_ms: 234973,
    popularity: 53,
    preview_url: "https://p.scdn.co/mp3-preview/dd0080b24c7ee77515434196ad5f5252a1076f9e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027513c4956ea5552caf02eab6",
    danceability: 0.639,
    energy: 0.788,
    valence: 0.847,
    added_at: "2020-01-25T18:15:28Z",
    link: "https://open.spotify.com/track/6Iuh3vNjpWhSeHM3RZgzQL",
    userID: "royclaud",
    genres: "east coast hip hop,gangster rap,hip hop,pop rap,rap,reggae,reggae fusion",
  },
  {
    id: "1t0P9nBKpnrh5MntGd3qw6",
    name: "El Chivo (feat. T3r Elemento)",
    artists: "Berner, T3R Elemento",
    duration_ms: 275400,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/335cb924737da111eff46638e84e38183a8ef6ab?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0216837cb74b93e5b33585ee2a",
    danceability: 0.53,
    energy: 0.828,
    valence: 0.471,
    added_at: "2020-01-25T16:14:03Z",
    link: "https://open.spotify.com/track/1t0P9nBKpnrh5MntGd3qw6",
    userID: "royclaud",
    genres: "cali rap,oakland hip hop,corrido,corridos tumbados,musica mexicana,norteno,sad sierreno,sierreno",
  },
  {
    id: "3Qa944OTMZkg8DHjET8JQv",
    name: "Woods",
    artists: "Mac Miller",
    duration_ms: 286386,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/c5e365c296615e391467047bfc04f9e6ba8550c2?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0226b7dd89810cc1a40ada642c",
    danceability: 0.779,
    energy: 0.362,
    valence: 0.46,
    added_at: "2020-01-17T06:27:02Z",
    link: "https://open.spotify.com/track/3Qa944OTMZkg8DHjET8JQv",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "71lyUtIPCMAYjTwwwxy2jW",
    name: "4r Da Squaw",
    artists: "Isaiah Rashad",
    duration_ms: 232571,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/48bbdaf243c705f0fe819d883efc34d4861eb34c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fbdec8696ff05ae19265dfa0",
    danceability: 0.667,
    energy: 0.523,
    valence: 0.409,
    added_at: "2020-01-14T06:12:13Z",
    link: "https://open.spotify.com/track/71lyUtIPCMAYjTwwwxy2jW",
    userID: "royclaud",
    genres: "hip hop,rap,tennessee hip hop,underground hip hop",
  },
  {
    id: "0QLuexVmR2wgNsekPKbv6c",
    name: "OSOM (feat. J. Cole)",
    artists: "Jay Rock, J. Cole",
    duration_ms: 323120,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/1f00a247a038c1dfd64350418c2b90193ad5a1ec?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c30d59775ce5ef3f59138f59",
    danceability: 0.749,
    energy: 0.674,
    valence: 0.396,
    added_at: "2020-01-14T06:06:53Z",
    link: "https://open.spotify.com/track/0QLuexVmR2wgNsekPKbv6c",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,southern hip hop,trap,conscious hip hop,hip hop,north carolina hip hop,rap",
  },
  {
    id: "3ClBKQkKoaUQ6UOhe2xlJK",
    name: "Good News",
    artists: "Mac Miller",
    duration_ms: 342040,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/b469f44c8cbdf55c5d073e79fae274b9978b6505?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025b8f30bc31faa93986a37d42",
    danceability: 0.794,
    energy: 0.32,
    valence: 0.241,
    added_at: "2020-01-10T00:50:33Z",
    link: "https://open.spotify.com/track/3ClBKQkKoaUQ6UOhe2xlJK",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "42XnD59SFcomCJu55EPhqJ",
    name: "Big Bidness (& Metro Boomin)",
    artists: "Big Sean, Metro Boomin, 2 Chainz",
    duration_ms: 273026,
    popularity: 39,
    preview_url: "https://p.scdn.co/mp3-preview/58e2bb05212b6a4dbb5ded74d6eaf29d41bf1149?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a921ed2b886e7d154e88d37b",
    danceability: 0.415,
    energy: 0.579,
    valence: 0.144,
    added_at: "2020-01-02T01:53:47Z",
    link: "https://open.spotify.com/track/42XnD59SFcomCJu55EPhqJ",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,pop rap,r&b,rap,southern hip hop,trap,rap,atl hip hop,hip hop,pop rap,rap,southern hip hop,trap",
  },
  {
    id: "0PXp9QxBfSKGGpWUNaws2X",
    name: "Drama (feat. Drake)",
    artists: "Roy Woods, Drake",
    duration_ms: 251859,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/5447308cbf67ded01668dbbd17f84dd317759906?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020b2ad05eb1f89183231cde0e",
    danceability: 0.709,
    energy: 0.362,
    valence: 0.113,
    added_at: "2020-01-02T00:54:07Z",
    link: "https://open.spotify.com/track/0PXp9QxBfSKGGpWUNaws2X",
    userID: "royclaud",
    genres: "canadian contemporary r&b,canadian hip hop,canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "0VZs2OQq4axr8GFRdC9nyD",
    name: "Guts Over Fear",
    artists: "Eminem, Sia",
    duration_ms: 300386,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/ca58a286e5df9ad2e3e5c3d8f44846d0edc2d59c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02634a66c2cb9778594a5c771a",
    danceability: 0.655,
    energy: 0.859,
    valence: 0.525,
    added_at: "2019-12-22T19:45:50Z",
    link: "https://open.spotify.com/track/0VZs2OQq4axr8GFRdC9nyD",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,rap,pop",
  },
  {
    id: "7bHT9osSq1rwT2yaImzqCi",
    name: "No Love",
    artists: "Eminem, Lil Wayne",
    duration_ms: 299506,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/b2bb35685b0c970fd5a1c19024f8cbc187c30b61?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c08d5fa5c0f1a834acef5100",
    danceability: 0.522,
    energy: 0.932,
    valence: 0.33,
    added_at: "2019-12-22T19:45:38Z",
    link: "https://open.spotify.com/track/7bHT9osSq1rwT2yaImzqCi",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,rap,hip hop,new orleans rap,pop rap,rap,trap",
  },
  {
    id: "2t16D9V5FmmRAJjsSpwvZf",
    name: "When I R.I.P.",
    artists: "Labrinth",
    duration_ms: 174567,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/d40cad7b963b3e4b434290a350f77446bdcf5cd5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0289c39ba1acdf33ed7acd3867",
    danceability: 0.417,
    energy: 0.551,
    valence: 0.444,
    added_at: "2019-12-18T20:49:49Z",
    link: "https://open.spotify.com/track/2t16D9V5FmmRAJjsSpwvZf",
    userID: "royclaud",
    genres: "indie poptimism,pop",
  },
  {
    id: "4sFzG7iUlyPmuyASCkre9A",
    name: "Hair Down (feat. Kendrick Lamar)",
    artists: "SiR, Kendrick Lamar",
    duration_ms: 216933,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/227ec6eaebe87366c7ce7eaef421c50884cc0fd8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bbee5481e94a03f4fd26fac4",
    danceability: 0.793,
    energy: 0.489,
    valence: 0.47,
    added_at: "2019-12-16T03:23:13Z",
    link: "https://open.spotify.com/track/4sFzG7iUlyPmuyASCkre9A",
    userID: "royclaud",
    genres: "alternative r&b,la pop,conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "40jxatV29fk82SAziDsPqN",
    name: "Rose Golden",
    artists: "Kid Cudi, WILLOW",
    duration_ms: 277813,
    popularity: 54,
    preview_url: "https://p.scdn.co/mp3-preview/7738b5a2ad83c937356193b61ee1180a9e0f5308?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025c8d9ad991d2a23eb039d8dd",
    danceability: 0.594,
    energy: 0.762,
    valence: 0.588,
    added_at: "2019-12-05T08:20:25Z",
    link: "https://open.spotify.com/track/40jxatV29fk82SAziDsPqN",
    userID: "royclaud",
    genres: "hip hop,ohio hip hop,pop rap,rap,afrofuturism,pop,post-teen pop,pov: indie",
  },
  {
    id: "4MW5jw8lASvumB1DinKxlF",
    name: "Oy Vey",
    artists: "Mac Miller",
    duration_ms: 208123,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/0672f4c6c65556b499b274ed2b13a6a61508d403?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023b49b217c9869908744fe7a9",
    danceability: 0.75,
    energy: 0.822,
    valence: 0.471,
    added_at: "2019-12-04T21:44:26Z",
    link: "https://open.spotify.com/track/4MW5jw8lASvumB1DinKxlF",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "5iUQMwxUPdJBFeGkePtM66",
    name: "Dang! (feat. Anderson .Paak)",
    artists: "Mac Miller, Anderson .Paak",
    duration_ms: 305350,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/f21c0400dffcc1fb0822a85801897e2bd966c080?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022e92f776279eaf45d14a33fd",
    danceability: 0.836,
    energy: 0.67,
    valence: 0.556,
    added_at: "2019-12-04T21:24:32Z",
    link: "https://open.spotify.com/track/5iUQMwxUPdJBFeGkePtM66",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap,escape room,hip hop,indie soul,neo soul",
  },
  {
    id: "4hBQtVSJt5fZ2VZGECVe3N",
    name: "I Am Who Am (Killin' Time)",
    artists: "Mac Miller, Niki Randa",
    duration_ms: 301096,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/531b42109f1d95e496f0bf41ee3ea40c92bd5c27?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d4300bedba646965fa7e1953",
    danceability: 0.368,
    energy: 0.619,
    valence: 0.181,
    added_at: "2019-12-04T19:06:24Z",
    link: "https://open.spotify.com/track/4hBQtVSJt5fZ2VZGECVe3N",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "57mhgOf3SLnrCQURECf9dU",
    name: "The Star Room",
    artists: "Mac Miller",
    duration_ms: 275283,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/22ebe7eef806e95ce5f7d80204f7d649a70f151a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d4300bedba646965fa7e1953",
    danceability: 0.44,
    energy: 0.675,
    valence: 0.355,
    added_at: "2019-12-04T18:49:09Z",
    link: "https://open.spotify.com/track/57mhgOf3SLnrCQURECf9dU",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "1nX9KhK3Fff27SnrIor2Yb",
    name: "4 AM",
    artists: "2 Chainz, Travis Scott",
    duration_ms: 255560,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/028326a937495cecb92f7e173c25c17448863bfd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c0c05243a846dda6c84607f9",
    danceability: 0.796,
    energy: 0.5,
    valence: 0.227,
    added_at: "2019-12-01T03:39:44Z",
    link: "https://open.spotify.com/track/1nX9KhK3Fff27SnrIor2Yb",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,pop rap,rap,southern hip hop,trap,rap,slap house",
  },
  {
    id: "7BMO7O7ImjV8HNTH74Tshv",
    name: "Cleanin' Out My Closet",
    artists: "Eminem",
    duration_ms: 297840,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/ccfd4b1431ecb301db1ba7f51f47c52c200c391e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026ca5c90113b30c3c43ffb8f4",
    danceability: 0.908,
    energy: 0.758,
    valence: 0.87,
    added_at: "2019-11-26T21:33:37Z",
    link: "https://open.spotify.com/track/7BMO7O7ImjV8HNTH74Tshv",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,rap",
  },
  {
    id: "3X4Oz9LjhhtgyOhVNlfoYa",
    name: "Marshall Mathers",
    artists: "Eminem",
    duration_ms: 321266,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/e46347de9c1e260c79ff195b97ab690d773c5d51?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02dbb3dd82da45b7d7f31b1b42",
    danceability: 0.729,
    energy: 0.759,
    valence: 0.268,
    added_at: "2019-11-26T21:27:34Z",
    link: "https://open.spotify.com/track/3X4Oz9LjhhtgyOhVNlfoYa",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,rap",
  },
  {
    id: "3guazByz82uD2zHf8q7As1",
    name: "Rock Bottom",
    artists: "Eminem",
    duration_ms: 214093,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/33a08c46bdd79cd398ab1432c694b6b4257e3207?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029bef45ee387f274ea3198c55",
    danceability: 0.859,
    energy: 0.642,
    valence: 0.353,
    added_at: "2019-11-26T21:07:51Z",
    link: "https://open.spotify.com/track/3guazByz82uD2zHf8q7As1",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,rap",
  },
  {
    id: "561jH07mF1jHuk7KlaeF0s",
    name: "Mockingbird",
    artists: "Eminem",
    duration_ms: 250760,
    popularity: 85,
    preview_url: "https://p.scdn.co/mp3-preview/43887419c9301358d28b7d487dfeb078f9668a6a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02726d48d93d02e1271774f023",
    danceability: 0.637,
    energy: 0.678,
    valence: 0.254,
    added_at: "2019-11-26T21:01:31Z",
    link: "https://open.spotify.com/track/561jH07mF1jHuk7KlaeF0s",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,rap",
  },
  {
    id: "1eQBEelI2NCy7AUTerX0KS",
    name: "Ultralight Beam",
    artists: "Kanye West",
    duration_ms: 320680,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/d597421ae3862047ba9f0dd0eb11e5137ef78858?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022a7db835b912dc5014bd37f4",
    danceability: 0.592,
    energy: 0.403,
    valence: 0.391,
    added_at: "2019-11-26T05:26:35Z",
    link: "https://open.spotify.com/track/1eQBEelI2NCy7AUTerX0KS",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "5Jd3mfA4OCkaqgzRxa1CIl",
    name: "Two Face",
    artists: "YNW Melly",
    duration_ms: 238656,
    popularity: 43,
    preview_url: "https://p.scdn.co/mp3-preview/eae32806588242444b4d2c7973c66da63ac992c9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020f47d89f02cd58a168b8193e",
    danceability: 0.825,
    energy: 0.506,
    valence: 0.265,
    added_at: "2019-11-25T20:04:26Z",
    link: "https://open.spotify.com/track/5Jd3mfA4OCkaqgzRxa1CIl",
    userID: "royclaud",
    genres: "melodic rap,rap",
  },
  {
    id: "6TL5uq31JarmrpEGTNNyKG",
    name: "Promises",
    artists: "Wiz Khalifa",
    duration_ms: 210466,
    popularity: 52,
    preview_url: "https://p.scdn.co/mp3-preview/aaf12ca4c663dd29111c7d434d35cd4867fb2564?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0227ed4f26fa2bfbd714dba7dd",
    danceability: 0.565,
    energy: 0.585,
    valence: 0.259,
    added_at: "2019-11-20T21:28:55Z",
    link: "https://open.spotify.com/track/6TL5uq31JarmrpEGTNNyKG",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,pop rap,rap,southern hip hop,trap",
  },
  {
    id: "67qn3OtziLlpOtVZ22EiQ4",
    name: "Smoke A Nigga (feat. Wiz Khalifa)",
    artists: "Juicy J, Wiz Khalifa",
    duration_ms: 257826,
    popularity: 54,
    preview_url: "https://p.scdn.co/mp3-preview/da82ffa20d08b9f85147352b75dd7f6f81d4ccb2?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02396bcd4043a871d830c848a3",
    danceability: 0.825,
    energy: 0.636,
    valence: 0.147,
    added_at: "2019-11-20T21:28:41Z",
    link: "https://open.spotify.com/track/67qn3OtziLlpOtVZ22EiQ4",
    userID: "royclaud",
    genres: "crunk,memphis hip hop,pop rap,rap,southern hip hop,trap,hip hop,pittsburgh rap,pop rap,rap,southern hip hop,trap",
  },
  {
    id: "4rMhnUBc5KjVa8oaj2mynQ",
    name: "All for You",
    artists: "French Montana, Wiz Khalifa, mgk, Snoop Dogg",
    duration_ms: 359836,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/cce7bc0f2e791824814b924810777fe24fac21af?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02527a75f366c2f3ef0b0d38fb",
    danceability: 0.623,
    energy: 0.606,
    valence: 0.243,
    added_at: "2019-11-20T21:28:28Z",
    link: "https://open.spotify.com/track/4rMhnUBc5KjVa8oaj2mynQ",
    userID: "royclaud",
    genres: "hip hop,pop rap,rap,southern hip hop,trap,hip hop,pittsburgh rap,pop rap,rap,southern hip hop,trap,ohio hip hop,pop rap,g funk,gangster rap,hip hop,pop rap,rap,west coast rap",
  },
  {
    id: "3SuzCeGoNOWmbWOoDMou0B",
    name: "Hate Bein' Sober",
    artists: "Chief Keef, 50 Cent, Wiz Khalifa",
    duration_ms: 280173,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/918f7a17c92437472ec8610bd17c8784c5e356bb?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022a8cbe9e04db628271fe9558",
    danceability: 0.82,
    energy: 0.47,
    valence: 0.313,
    added_at: "2019-11-20T21:00:29Z",
    link: "https://open.spotify.com/track/3SuzCeGoNOWmbWOoDMou0B",
    userID: "royclaud",
    genres: "chicago bop,chicago drill,chicago rap,drill,hip hop,rap,trap,east coast hip hop,gangster rap,hip hop,pop rap,queens hip hop,rap,hip hop,pittsburgh rap,pop rap,rap,southern hip hop,trap",
  },
  {
    id: "2lH43POZa4XHxydnZizET5",
    name: "TTM (feat. Wiz Khalifa & NGHTMRE)",
    artists: "PnB Rock, Wiz Khalifa, NGHTMRE",
    duration_ms: 212000,
    popularity: 44,
    preview_url: "https://p.scdn.co/mp3-preview/cad299b1755e472d640b08096899bd89327b742a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02673c1e3c47e3bd50ea78a3ef",
    danceability: 0.786,
    energy: 0.82,
    valence: 0.597,
    added_at: "2019-11-20T06:06:56Z",
    link: "https://open.spotify.com/track/2lH43POZa4XHxydnZizET5",
    userID: "royclaud",
    genres: "melodic rap,rap,trap,hip hop,pittsburgh rap,pop rap,rap,southern hip hop,trap,brostep,edm,electro house,electronic trap",
  },
  {
    id: "4LoMziEaxdkrSMXtavIJqL",
    name: "Just What I Am",
    artists: "Kid Cudi, King Chip",
    duration_ms: 228026,
    popularity: 2,
    preview_url: "https://p.scdn.co/mp3-preview/1fc2d18bf7c50cfd2a15c124209238c0bb43d191?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a0833b35c7f150ab9a3803ce",
    danceability: 0.654,
    energy: 0.95,
    valence: 0.351,
    added_at: "2019-11-17T03:57:51Z",
    link: "https://open.spotify.com/track/4LoMziEaxdkrSMXtavIJqL",
    userID: "royclaud",
    genres: "hip hop,ohio hip hop,pop rap,rap,ohio hip hop",
  },
  {
    id: "2eHk3S3zoW9xhtSHmJL4Xw",
    name: "Gold",
    artists: "Lund",
    duration_ms: 157084,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/1a457ace4b6c2cdd2124357dac194a11913802bc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0281d6e5fea788bbd1f33c6b17",
    danceability: 0.374,
    energy: 0.309,
    valence: 0.0606,
    added_at: "2019-11-17T03:57:37Z",
    link: "https://open.spotify.com/track/2eHk3S3zoW9xhtSHmJL4Xw",
    userID: "royclaud",
    genres: "emo rap",
  },
  {
    id: "72Z17vmmeQKAg8bptWvpVG",
    name: "Space Oddity - 2015 Remaster",
    artists: "David Bowie",
    duration_ms: 318026,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/9240f576fa464f8e3fe38f1c83fd23cd4e516638?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023395f3e809dfbc2b1101d464",
    danceability: 0.31,
    energy: 0.403,
    valence: 0.466,
    added_at: "2019-11-17T03:56:47Z",
    link: "https://open.spotify.com/track/72Z17vmmeQKAg8bptWvpVG",
    userID: "royclaud",
    genres: "art rock,classic rock,glam rock,permanent wave,rock",
  },
  {
    id: "7oK9VyNzrYvRFo7nQEYkWN",
    name: "Mr. Brightside",
    artists: "The Killers",
    duration_ms: 222586,
    popularity: 24,
    preview_url: "https://p.scdn.co/mp3-preview/848b1bd5544e82f62f9cfcec65362d0f5369781f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0256a6d6e493a8f338be63fc49",
    danceability: 0.356,
    energy: 0.924,
    valence: 0.232,
    added_at: "2019-11-15T17:45:08Z",
    link: "https://open.spotify.com/track/7oK9VyNzrYvRFo7nQEYkWN",
    userID: "royclaud",
    genres: "alternative rock,dance rock,modern rock,permanent wave,rock",
  },
  {
    id: "3aYBjxTMvrEOP0A0UXg9ER",
    name: "Final Masquerade",
    artists: "Linkin Park",
    duration_ms: 217613,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/219eed9e4ac28f6fb3337dcb2a8f0965a7bca17e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025721adac031512b903f10d03",
    danceability: 0.466,
    energy: 0.852,
    valence: 0.428,
    added_at: "2019-11-14T21:51:56Z",
    link: "https://open.spotify.com/track/3aYBjxTMvrEOP0A0UXg9ER",
    userID: "royclaud",
    genres: "alternative metal,nu metal,post-grunge,rap metal,rock",
  },
  {
    id: "1nFtiJxYdhtFfFtfXBv06s",
    name: "Something In The Way",
    artists: "Nirvana",
    duration_ms: 232146,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/754f72b8e7b76c22f0d758d1ab39bd7ebc841ed6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e175a19e530c898d167d39bf",
    danceability: 0.427,
    energy: 0.201,
    valence: 0.0668,
    added_at: "2019-11-14T05:00:27Z",
    link: "https://open.spotify.com/track/1nFtiJxYdhtFfFtfXBv06s",
    userID: "royclaud",
    genres: "grunge,permanent wave,rock",
  },
  {
    id: "0wEC0GNF74lAqrEFZJMl1m",
    name: "PUPPET",
    artists: "Tyler, The Creator",
    duration_ms: 179013,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/c2f3e3fb244d626651827c3e60781f2c96c46dd1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027005885df706891a3c182a57",
    danceability: 0.508,
    energy: 0.557,
    valence: 0.199,
    added_at: "2019-11-14T04:46:06Z",
    link: "https://open.spotify.com/track/0wEC0GNF74lAqrEFZJMl1m",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "2q0VexHJirnUPnEOhr2DxK",
    name: "Biking",
    artists: "Frank Ocean, JAY-Z, Tyler, The Creator",
    duration_ms: 277520,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/86e7c9d7393654e6f3e9ac48e42f963d01bcfdbd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0269a8328489e5e485514a8667",
    danceability: 0.673,
    energy: 0.463,
    valence: 0.268,
    added_at: "2019-11-14T04:23:35Z",
    link: "https://open.spotify.com/track/2q0VexHJirnUPnEOhr2DxK",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul,east coast hip hop,gangster rap,hip hop,pop rap,rap",
  },
  {
    id: "68RtrBr0Voa8Y69lXhqIbR",
    name: "Speedin’ Bullet 2 Heaven",
    artists: "Kid Cudi",
    duration_ms: 274853,
    popularity: 35,
    preview_url: "https://p.scdn.co/mp3-preview/a141ecc70ab227084e0cd372fccfeac812ad7660?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a4ea8c76f78b72ce3bda7625",
    danceability: 0.604,
    energy: 0.696,
    valence: 0.408,
    added_at: "2019-11-08T21:52:36Z",
    link: "https://open.spotify.com/track/68RtrBr0Voa8Y69lXhqIbR",
    userID: "royclaud",
    genres: "hip hop,ohio hip hop,pop rap,rap",
  },
  {
    id: "4WuITGWedQ4yMcxuAdR4VH",
    name: "Suplexes Inside of Complexes and Duplexes",
    artists: "Mac Miller",
    duration_ms: 167437,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/8a85e65096ffd31c0ff2454b948272a228c31804?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d4300bedba646965fa7e1953",
    danceability: 0.405,
    energy: 0.754,
    valence: 0.474,
    added_at: "2019-11-07T05:56:00Z",
    link: "https://open.spotify.com/track/4WuITGWedQ4yMcxuAdR4VH",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "3XiNC94b4Tq1xwv70sQJGN",
    name: "Sanguine Paradise",
    artists: "Lil Uzi Vert",
    duration_ms: 243976,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/be7bd6858e8b97904c76a1028954f57e995c6e2a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0234bf16d92ae11f8d3eadf295",
    danceability: 0.729,
    energy: 0.603,
    valence: 0.207,
    added_at: "2019-11-07T05:50:43Z",
    link: "https://open.spotify.com/track/3XiNC94b4Tq1xwv70sQJGN",
    userID: "royclaud",
    genres: "hip hop,melodic rap,philly rap,rage rap,rap,trap",
  },
  {
    id: "2INhKpUdzh7v0j041gZNsz",
    name: "DHL",
    artists: "Frank Ocean",
    duration_ms: 268893,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/bfe104caea1738ca92d850c5543923474bf830fe?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e9d76d49bcfdd620fe031148",
    danceability: 0.819,
    energy: 0.458,
    valence: 0.232,
    added_at: "2019-11-07T04:37:52Z",
    link: "https://open.spotify.com/track/2INhKpUdzh7v0j041gZNsz",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "40riOy7x9W7GXjyGp4pjAv",
    name: "Hotel California - 2013 Remaster",
    artists: "Eagles",
    duration_ms: 391376,
    popularity: 82,
    preview_url: "https://p.scdn.co/mp3-preview/6fedc11d0f55bef176cc1c5725ac1c57f9a2534a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024637341b9f507521afa9a778",
    danceability: 0.579,
    energy: 0.508,
    valence: 0.609,
    added_at: "2019-11-07T04:36:47Z",
    link: "https://open.spotify.com/track/40riOy7x9W7GXjyGp4pjAv",
    userID: "royclaud",
    genres: "album rock,classic rock,heartland rock,mellow gold,rock,soft rock,yacht rock",
  },
  {
    id: "5p7GiBZNL1afJJDUrOA6C8",
    name: "Hurt Feelings",
    artists: "Mac Miller",
    duration_ms: 245640,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/9862d428ddff3a32097998256eb6901c90ae6485?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02175c577a61aa13d4fb4b6534",
    danceability: 0.69,
    energy: 0.462,
    valence: 0.325,
    added_at: "2019-11-06T04:18:18Z",
    link: "https://open.spotify.com/track/5p7GiBZNL1afJJDUrOA6C8",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "30HCB1FoE77IfGRyNv4eFq",
    name: "In Bloom",
    artists: "Nirvana",
    duration_ms: 255080,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/e384739cd408793b9010b77f610909129e3e3afd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e175a19e530c898d167d39bf",
    danceability: 0.436,
    energy: 0.876,
    valence: 0.512,
    added_at: "2019-11-06T03:10:23Z",
    link: "https://open.spotify.com/track/30HCB1FoE77IfGRyNv4eFq",
    userID: "royclaud",
    genres: "grunge,permanent wave,rock",
  },
  {
    id: "78T9DulqSBWqkaxczcGBfk",
    name: "Man On The Moon",
    artists: "Kid Cudi",
    duration_ms: 210893,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/51eab3361190393723ee3c423a4c50391461de97?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a487deeecb334b6619489d74",
    danceability: 0.649,
    energy: 0.659,
    valence: 0.497,
    added_at: "2019-11-05T06:24:36Z",
    link: "https://open.spotify.com/track/78T9DulqSBWqkaxczcGBfk",
    userID: "royclaud",
    genres: "hip hop,ohio hip hop,pop rap,rap",
  },
  {
    id: "2ZWlPOoWh0626oTaHrnl2a",
    name: "Ivy",
    artists: "Frank Ocean",
    duration_ms: 249191,
    popularity: 83,
    preview_url: "https://p.scdn.co/mp3-preview/3eb4d77b749c0f49037e6aee78536eb35b5c88c7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c5649add07ed3720be9d5526",
    danceability: 0.575,
    energy: 0.386,
    valence: 0.466,
    added_at: "2019-11-03T17:36:57Z",
    link: "https://open.spotify.com/track/2ZWlPOoWh0626oTaHrnl2a",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "7w87IxuO7BDcJ3YUqCyMTT",
    name: "Pumped Up Kicks",
    artists: "Foster The People",
    duration_ms: 239600,
    popularity: 85,
    preview_url: "https://p.scdn.co/mp3-preview/2213bf4173a9b50a807a30121ed6b559a7778209?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02121d5f92cf90576907dfb1e5",
    danceability: 0.733,
    energy: 0.71,
    valence: 0.965,
    added_at: "2019-11-02T06:16:12Z",
    link: "https://open.spotify.com/track/7w87IxuO7BDcJ3YUqCyMTT",
    userID: "royclaud",
    genres: "indietronica,modern alternative rock,modern rock,rock",
  },
  {
    id: "5KDj6rPWsDVcIGukuz9g9D",
    name: "Laudy",
    artists: "Kodak Black",
    duration_ms: 241342,
    popularity: 30,
    preview_url: "https://p.scdn.co/mp3-preview/044df4bce852d78df02f707c5754a511892d5339?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e2d884211fae50ac5318a428",
    danceability: 0.785,
    energy: 0.51,
    valence: 0.543,
    added_at: "2019-10-31T23:00:33Z",
    link: "https://open.spotify.com/track/5KDj6rPWsDVcIGukuz9g9D",
    userID: "royclaud",
    genres: "florida drill,florida rap,melodic rap,miami hip hop,rap,trap,trap latino",
  },
  {
    id: "0kMxybaVSjw5oXbHG9sDdc",
    name: "Free At Last",
    artists: "S-X, Childish Gambino",
    duration_ms: 186226,
    popularity: 0,
    preview_url: "null",
    image: "https://i.scdn.co/image/ab67616d00001e021fdab0f1cdfea5b401fca58a",
    danceability: 0.582,
    energy: 0.617,
    valence: 0.306,
    added_at: "2019-10-31T05:40:06Z",
    link: "https://open.spotify.com/track/0kMxybaVSjw5oXbHG9sDdc",
    userID: "royclaud",
    genres: "instrumental grime,atl hip hop,hip hop,rap",
  },
  {
    id: "2P0NiuAfypaw5UZJoo0n0o",
    name: "It Might Be Time",
    artists: "Tame Impala",
    duration_ms: 273013,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/42031d4ce5295e8ca21fac0dc6a2b613b1da25ce?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022dcb0de7bd811b035ede58af",
    danceability: 0.586,
    energy: 0.786,
    valence: 0.326,
    added_at: "2019-10-30T20:42:54Z",
    link: "https://open.spotify.com/track/2P0NiuAfypaw5UZJoo0n0o",
    userID: "royclaud",
    genres: "australian psych,modern rock,neo-psychedelic,rock",
  },
  {
    id: "6WXdlIUbsqFSS6T63eCBxx",
    name: "Simple Man - Rock Version",
    artists: "Shinedown",
    duration_ms: 265800,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/a91ac151745c7ee74c37ac97dc9fcbd72fb45337?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d609342d04c349815b5fe099",
    danceability: 0.532,
    energy: 0.625,
    valence: 0.225,
    added_at: "2019-10-30T16:46:25Z",
    link: "https://open.spotify.com/track/6WXdlIUbsqFSS6T63eCBxx",
    userID: "royclaud",
    genres: "alternative metal,nu metal,post-grunge",
  },
  {
    id: "1HR2CTi0ytRJIcik1QKdOa",
    name: "Beautiful",
    artists: "Eminem",
    duration_ms: 392826,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/b6fb20bf0b8c39d0278804d7bbbfcae909bfdff3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02506c4cc93e5a6234164125e1",
    danceability: 0.727,
    energy: 0.696,
    valence: 0.467,
    added_at: "2019-10-29T08:38:21Z",
    link: "https://open.spotify.com/track/1HR2CTi0ytRJIcik1QKdOa",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,rap",
  },
  {
    id: "6K4t31amVTZDgR3sKmwUJJ",
    name: "The Less I Know The Better",
    artists: "Tame Impala",
    duration_ms: 216320,
    popularity: 84,
    preview_url: "https://p.scdn.co/mp3-preview/3ad63797e17bda6d46d081049c8c3710e3ab2797?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029e1cfc756886ac782e363d79",
    danceability: 0.64,
    energy: 0.74,
    valence: 0.785,
    added_at: "2019-10-28T08:17:35Z",
    link: "https://open.spotify.com/track/6K4t31amVTZDgR3sKmwUJJ",
    userID: "royclaud",
    genres: "australian psych,modern rock,neo-psychedelic,rock",
  },
  {
    id: "4f8Mh5wuWHOsfXtzjrJB3t",
    name: "I THINK",
    artists: "Tyler, The Creator",
    duration_ms: 212013,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/e3be3ca8e4b8f303b81714bc065d6bf74ef0abcf?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027005885df706891a3c182a57",
    danceability: 0.826,
    energy: 0.579,
    valence: 0.431,
    added_at: "2019-10-28T08:07:39Z",
    link: "https://open.spotify.com/track/4f8Mh5wuWHOsfXtzjrJB3t",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "0WKmVAD4VWk5EdTyCFILi4",
    name: "Needs",
    artists: "Verzache",
    duration_ms: 177479,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/139e1fe64656ef6afade95d0441f49698097972e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025ba2df72623080c184540c74",
    danceability: 0.813,
    energy: 0.601,
    valence: 0.855,
    added_at: "2019-10-28T08:07:31Z",
    link: "https://open.spotify.com/track/0WKmVAD4VWk5EdTyCFILi4",
    userID: "royclaud",
    genres: "sad lo-fi",
  },
  {
    id: "22z9GL53FudbuFJqa43Nzj",
    name: "I Wish (feat. Jay Jenner)",
    artists: "Infected Mushroom, Jetlag Music, HOT-Q, Jay Jenner, WhyNot Music",
    duration_ms: 171436,
    popularity: 32,
    preview_url: "https://p.scdn.co/mp3-preview/c62361f589efdb049284d47df241ea962a470c49?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0217bd4b75bb37ec9df8567a9b",
    danceability: 0.591,
    energy: 0.871,
    valence: 0.28,
    added_at: "2019-10-27T17:11:05Z",
    link: "https://open.spotify.com/track/22z9GL53FudbuFJqa43Nzj",
    userID: "royclaud",
    genres: "psychedelic trance,brazilian edm,brazilian edm",
  },
  {
    id: "7Gs3otHnZDq514kFHf0nx7",
    name: "Blue Notes",
    artists: "Meek Mill",
    duration_ms: 227808,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/09545a8b9e5b9c5090717b6a7f34d779dfa3b4a9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020a6f4e78a01fd779ebaace83",
    danceability: 0.515,
    energy: 0.431,
    valence: 0.184,
    added_at: "2019-10-25T01:27:44Z",
    link: "https://open.spotify.com/track/7Gs3otHnZDq514kFHf0nx7",
    userID: "royclaud",
    genres: "hip hop,philly rap,pop rap,rap,southern hip hop,trap",
  },
  {
    id: "5OcQkWk3CDI4uXnhwbflqt",
    name: "B Ok",
    artists: "Wiz Khalifa",
    duration_ms: 282775,
    popularity: 36,
    preview_url: "https://p.scdn.co/mp3-preview/0c1fc2f79abd3208383f44bd8bfe83e32feb136e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02393d29e88d493bdab8af1617",
    danceability: 0.804,
    energy: 0.583,
    valence: 0.281,
    added_at: "2019-10-24T23:55:41Z",
    link: "https://open.spotify.com/track/5OcQkWk3CDI4uXnhwbflqt",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,pop rap,rap,southern hip hop,trap",
  },
  {
    id: "0lnfcWRREvnGtXPP2ZDeq7",
    name: "Que Sera",
    artists: "Wax Tailor",
    duration_ms: 164093,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/dedfcd98cde42845a90410f39e45ecd0e531155b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c752cbb22a92bf15c67346ad",
    danceability: 0.714,
    energy: 0.721,
    valence: 0.419,
    added_at: "2019-10-20T23:50:24Z",
    link: "https://open.spotify.com/track/0lnfcWRREvnGtXPP2ZDeq7",
    userID: "royclaud",
    genres: "electronica,hip-hop experimental,nu jazz,trip hop",
  },
  {
    id: "7EX2yQXPn8tktvfAodfiIp",
    name: "Ricochet",
    artists: "Cayte Lee, Sullivan King",
    duration_ms: 197600,
    popularity: 15,
    preview_url: "https://p.scdn.co/mp3-preview/9c6c89aa0566caed98aa8353d172437dd2bd69ae?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029236b28120117bb066b5bc2d",
    danceability: 0.369,
    energy: 0.66,
    valence: 0.596,
    added_at: "2019-10-18T18:33:39Z",
    link: "https://open.spotify.com/track/7EX2yQXPn8tktvfAodfiIp",
    userID: "royclaud",
    genres: "virginia hip hop,dubstep",
  },
  {
    id: "5WnfnSpuNEGXyEt78PBA6d",
    name: "Costa Rica (with Bas & JID feat. Guapdad 4000, Reese LAFLARE, Jace, Mez, Smokepurpp, Buddy & Ski Mask The Slump God)",
    artists: "Dreamville, Bas, JID, Guapdad 4000, Reese LAFLARE, Jace, Mez, Smokepurpp, Buddy, Ski Mask The Slump God",
    duration_ms: 217866,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/acf0349f57f8eb06fff24497d14d235342860a3e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c0ae820e3b64854de4642101",
    danceability: 0.642,
    energy: 0.647,
    valence: 0.664,
    added_at: "2019-10-18T01:16:55Z",
    link: "https://open.spotify.com/track/5WnfnSpuNEGXyEt78PBA6d",
    userID: "royclaud",
    genres:
      "hip hop,pop rap,rap,queens hip hop,sudanese hip hop,sudanese pop,underground hip hop,hip hop,pop rap,rap,underground hip hop,cali rap,canadian hip hop,oakland hip hop,scam rap,underground hip hop,atl trap,indie hip hop,north carolina hip hop,melodic rap,miami hip hop,trap,underground hip hop,alternative r&b,cali rap,hip hop,miami hip hop,pop rap,rap,trap,underground hip hop",
  },
  {
    id: "4S7YHmlWwfwArgd8LfSPud",
    name: "L$D",
    artists: "A$AP Rocky",
    duration_ms: 238306,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/417aec38ee59dce9251b00f92763ba5496613504?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0281a6a2cafab2e7a4066b5eec",
    danceability: 0.598,
    energy: 0.252,
    valence: 0.0352,
    added_at: "2019-10-18T01:16:54Z",
    link: "https://open.spotify.com/track/4S7YHmlWwfwArgd8LfSPud",
    userID: "royclaud",
    genres: "east coast hip hop,hip hop,rap",
  },
  {
    id: "4jQqM4NI79HEcWHUJb8Hvf",
    name: "I Wonder",
    artists: "Kanye West",
    duration_ms: 243440,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/22169e0212f8e77e0bb753c4d5cf1befae619997?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029bbd79106e510d13a9a5ec33",
    danceability: 0.542,
    energy: 0.466,
    valence: 0.124,
    added_at: "2019-10-18T01:16:52Z",
    link: "https://open.spotify.com/track/4jQqM4NI79HEcWHUJb8Hvf",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "39NDBdU5Xkm5pCFGa5kZtI",
    name: "Ladders",
    artists: "Mac Miller",
    duration_ms: 287040,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/fac1365987dfd894cd6abaffd3b9c341241e9a94?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02175c577a61aa13d4fb4b6534",
    danceability: 0.802,
    energy: 0.463,
    valence: 0.291,
    added_at: "2019-10-18T01:16:44Z",
    link: "https://open.spotify.com/track/39NDBdU5Xkm5pCFGa5kZtI",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "4KW1lqgSr8TKrvBII0Brf8",
    name: "Father Stretch My Hands Pt. 1",
    artists: "Kanye West",
    duration_ms: 135920,
    popularity: 83,
    preview_url: "https://p.scdn.co/mp3-preview/92e9e2777d5300825d0f14e8afab4c693c4546bb?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022a7db835b912dc5014bd37f4",
    danceability: 0.724,
    energy: 0.573,
    valence: 0.438,
    added_at: "2019-10-18T01:16:41Z",
    link: "https://open.spotify.com/track/4KW1lqgSr8TKrvBII0Brf8",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "2Fw5S2gaOSZzdN5dFoC2dj",
    name: "A.D.H.D",
    artists: "Kendrick Lamar",
    duration_ms: 215509,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/373593dd102cf4caddd5f3404aa1150bc186adcd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02eddb2639b74ac6c202032ebe",
    danceability: 0.609,
    energy: 0.799,
    valence: 0.582,
    added_at: "2019-10-18T01:16:39Z",
    link: "https://open.spotify.com/track/2Fw5S2gaOSZzdN5dFoC2dj",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "62vpWI1CHwFy7tMIcSStl8",
    name: "No Role Modelz",
    artists: "J. Cole",
    duration_ms: 292986,
    popularity: 4,
    preview_url: "https://p.scdn.co/mp3-preview/062b69c85ad8361d6d7e484aa798b3596d174306?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026aca031ccc27d2e4dd829d14",
    danceability: 0.692,
    energy: 0.521,
    valence: 0.463,
    added_at: "2019-10-18T01:16:33Z",
    link: "https://open.spotify.com/track/62vpWI1CHwFy7tMIcSStl8",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,north carolina hip hop,rap",
  },
  {
    id: "5Z8HZM6iQMhhqyPcCGY5g9",
    name: "Hot (feat. Gunna)",
    artists: "Young Thug, Gunna",
    duration_ms: 193026,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/950d342474c987475e98ef86a51ed95c0181e480?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026fcd1b6e205d0d19d9efa0cc",
    danceability: 0.855,
    energy: 0.651,
    valence: 0.559,
    added_at: "2019-10-17T20:02:48Z",
    link: "https://open.spotify.com/track/5Z8HZM6iQMhhqyPcCGY5g9",
    userID: "royclaud",
    genres: "atl hip hop,atl trap,gangster rap,hip hop,melodic rap,rap,trap,atl hip hop,melodic rap,rap,trap",
  },
  {
    id: "7MiZjKawmXTsTNePyTfPyL",
    name: "Devil Eyes",
    artists: "Hippie Sabotage",
    duration_ms: 131271,
    popularity: 75,
    preview_url: "https://p.scdn.co/mp3-preview/712f2fe51bbaa666c423168f2c231098fbd09b4a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ecdad1ee6d530eb965d7c51e",
    danceability: 0.391,
    energy: 0.396,
    valence: 0.199,
    added_at: "2019-10-17T16:46:20Z",
    link: "https://open.spotify.com/track/7MiZjKawmXTsTNePyTfPyL",
    userID: "royclaud",
    genres: "edm,electronic trap",
  },
  {
    id: "7mPoCVGP752A5DtHf1SKkM",
    name: "Say What's Real",
    artists: "Drake",
    duration_ms: 230717,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/2ed44c1da3e1d2d2e777222ce6d58d4909534f93?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0254ef13c30a024a7f1ba79efd",
    danceability: 0.462,
    energy: 0.773,
    valence: 0.455,
    added_at: "2019-10-16T07:25:01Z",
    link: "https://open.spotify.com/track/7mPoCVGP752A5DtHf1SKkM",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "421leiR6jKlH5KDdwLYrOs",
    name: "Dance Monkey",
    artists: "Tones And I",
    duration_ms: 209754,
    popularity: 3,
    preview_url: "https://p.scdn.co/mp3-preview/faad7dce8e7deeb059c7c59645c594c40342812f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a0486913512ed5f9f62b5d56",
    danceability: 0.824,
    energy: 0.592,
    valence: 0.54,
    added_at: "2019-10-15T08:07:32Z",
    link: "https://open.spotify.com/track/421leiR6jKlH5KDdwLYrOs",
    userID: "royclaud",
    genres: "australian pop",
  },
  {
    id: "3pwa9U2xgq3nf5Hc72JwFe",
    name: "Fuck It",
    artists: "Hippie Sabotage",
    duration_ms: 192000,
    popularity: 33,
    preview_url: "https://p.scdn.co/mp3-preview/8ba5894f4b7e812ef127dc2da5bb4d9aa13e3073?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020e2d19b140fc2cfd1ff6ebf8",
    danceability: 0.804,
    energy: 0.492,
    valence: 0.269,
    added_at: "2019-10-13T18:42:57Z",
    link: "https://open.spotify.com/track/3pwa9U2xgq3nf5Hc72JwFe",
    userID: "royclaud",
    genres: "edm,electronic trap",
  },
  {
    id: "3TpXajg1nKzG3ngc9tBwrD",
    name: "Easily",
    artists: "Bruno Major",
    duration_ms: 210240,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/b0e4f7d90177840ab0afbb94c7bd4eaac5c8271b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0284930c5f264899c8519c567d",
    danceability: 0.772,
    energy: 0.256,
    valence: 0.357,
    added_at: "2019-10-08T05:36:11Z",
    link: "https://open.spotify.com/track/3TpXajg1nKzG3ngc9tBwrD",
    userID: "royclaud",
    genres: "singer-songwriter pop",
  },
  {
    id: "3eekarcy7kvN4yt5ZFzltW",
    name: "HIGHEST IN THE ROOM",
    artists: "Travis Scott",
    duration_ms: 175720,
    popularity: 86,
    preview_url: "https://p.scdn.co/mp3-preview/387b31c31b72f0c16e33d0c78bab869b0a0f4eb3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e42b5fea4ac4c3d6328b622b",
    danceability: 0.598,
    energy: 0.427,
    valence: 0.0605,
    added_at: "2019-10-04T05:13:07Z",
    link: "https://open.spotify.com/track/3eekarcy7kvN4yt5ZFzltW",
    userID: "royclaud",
    genres: "rap,slap house",
  },
  {
    id: "0dbTQYW3Ad1FTzIA9t90E8",
    name: "Mona Lisa (feat. Kendrick Lamar)",
    artists: "Lil Wayne, Kendrick Lamar",
    duration_ms: 324189,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/1428c24e96c4a11b86e40d47588273ebbb240681?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bb1138165ad4ec2306f4e886",
    danceability: 0.856,
    energy: 0.694,
    valence: 0.489,
    added_at: "2019-10-03T01:40:54Z",
    link: "https://open.spotify.com/track/0dbTQYW3Ad1FTzIA9t90E8",
    userID: "royclaud",
    genres: "hip hop,new orleans rap,pop rap,rap,trap,conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "2p4jcOrYpJn3f6FF8GZlIU",
    name: "Reelin",
    artists: "Iration",
    duration_ms: 272746,
    popularity: 47,
    preview_url: "https://p.scdn.co/mp3-preview/b26ae1a41962c557834b4a140372639d81319002?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027070f25e7601f5245ff4f301",
    danceability: 0.655,
    energy: 0.733,
    valence: 0.894,
    added_at: "2019-09-25T03:32:44Z",
    link: "https://open.spotify.com/track/2p4jcOrYpJn3f6FF8GZlIU",
    userID: "royclaud",
    genres: "reggae rock",
  },
  {
    id: "1MksGqIztTT6M9R1ErKVs8",
    name: "No Favors",
    artists: "Big Sean, Eminem",
    duration_ms: 325840,
    popularity: 50,
    preview_url: "https://p.scdn.co/mp3-preview/f9537f3ec5efff5d1242c5db34656acb8be06e33?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e572359074e5da958a922987",
    danceability: 0.482,
    energy: 0.781,
    valence: 0.336,
    added_at: "2019-09-15T05:57:37Z",
    link: "https://open.spotify.com/track/1MksGqIztTT6M9R1ErKVs8",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,pop rap,r&b,rap,southern hip hop,trap,detroit hip hop,hip hop,rap",
  },
  {
    id: "51MMC5DogGZAnHil5HQAXg",
    name: "Circles",
    artists: "Post Malone",
    duration_ms: 215280,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/193a0924b0f73d211131bf2fb0bddb7202176202?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bbf401121625a31ba1a6a4bb",
    danceability: 0.695,
    energy: 0.762,
    valence: 0.553,
    added_at: "2019-09-14T00:00:04Z",
    link: "https://open.spotify.com/track/51MMC5DogGZAnHil5HQAXg",
    userID: "royclaud",
    genres: "dfw rap,melodic rap,pop,rap",
  },
  {
    id: "0kiXxvKRXso1ZxRY2rin5n",
    name: "Feeling Good",
    artists: "Dirty Heads",
    duration_ms: 201847,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/621e968ab46a0d3b28db3ca95071919928ce360d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0266cf0477fe01d6a4c4925f30",
    danceability: 0.624,
    energy: 0.803,
    valence: 0.845,
    added_at: "2019-09-01T16:16:03Z",
    link: "https://open.spotify.com/track/0kiXxvKRXso1ZxRY2rin5n",
    userID: "royclaud",
    genres: "reggae fusion,reggae rock",
  },
  {
    id: "283LAlCpfArRZOXPfkOQj0",
    name: "Crave You - Adventure Club Remix",
    artists: "Flight Facilities",
    duration_ms: 236930,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/62fbe1de35b51054a562259f5707c70bcf61465a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027f582932a21844a195247258",
    danceability: 0.643,
    energy: 0.693,
    valence: 0.083,
    added_at: "2019-08-29T22:13:26Z",
    link: "https://open.spotify.com/track/283LAlCpfArRZOXPfkOQj0",
    userID: "royclaud",
    genres: "aussietronica,australian dance,australian indie,indietronica,nu disco",
  },
  {
    id: "7wTA0NKIm6T7nP2kaymU2a",
    name: "Sacrifices (with EARTHGANG & J. Cole feat. Smino & Saba)",
    artists: "Dreamville, EARTHGANG, J. Cole, Smino, Saba",
    duration_ms: 382306,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/f84e206751edbfe10bfda96802d39f9291baa613?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c0ae820e3b64854de4642101",
    danceability: 0.825,
    energy: 0.672,
    valence: 0.246,
    added_at: "2019-08-20T16:43:39Z",
    link: "https://open.spotify.com/track/7wTA0NKIm6T7nP2kaymU2a",
    userID: "royclaud",
    genres: "hip hop,pop rap,rap,atl hip hop,indie hip hop,psychedelic hip hop,rap,underground hip hop,conscious hip hop,hip hop,north carolina hip hop,rap,alternative r&b,alternative r&b,chicago rap",
  },
  {
    id: "66WcaWVGRFmpx0euxmw9yM",
    name: "Flight",
    artists: "Tristam, Braken",
    duration_ms: 219428,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/a45552e085be3bbce127fa0cb6334698a6448759?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022c47358ef6dcdce4acc82159",
    danceability: 0.434,
    energy: 0.755,
    valence: 0.217,
    added_at: "2019-08-15T21:07:30Z",
    link: "https://open.spotify.com/track/66WcaWVGRFmpx0euxmw9yM",
    userID: "royclaud",
    genres: "brostep,complextro,dancefloor dnb,edm,filthstep,gaming edm,gaming edm",
  },
  {
    id: "0SuLAslEMFZAXf0SwY7syi",
    name: "All Time Low",
    artists: "Jon Bellion",
    duration_ms: 217603,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/ca348df191ad7d7b93a662c1c8457b1c513849ec?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02804533fa6608d1c8d420dbeb",
    danceability: 0.617,
    energy: 0.567,
    valence: 0.505,
    added_at: "2019-08-15T21:06:41Z",
    link: "https://open.spotify.com/track/0SuLAslEMFZAXf0SwY7syi",
    userID: "royclaud",
    genres: "indie pop rap",
  },
  {
    id: "3jfomnmufV2Px34YndRvhj",
    name: "Fake Pods",
    artists: "99 Neighbors",
    duration_ms: 183204,
    popularity: 22,
    preview_url: "https://p.scdn.co/mp3-preview/999743c6745a399117c970b8f67b554da004566b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020d818af2c6cf90ee25096d10",
    danceability: 0.654,
    energy: 0.548,
    valence: 0.363,
    added_at: "2019-08-05T20:56:59Z",
    link: "https://open.spotify.com/track/3jfomnmufV2Px34YndRvhj",
    userID: "royclaud",
    genres: "indie hip hop,vermont indie",
  },
  {
    id: "4NZrZgrRoR4GwR7flWOXRI",
    name: "Go Fuck Yourself",
    artists: "Two Feet",
    duration_ms: 129599,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/4d4c9a35bb6ec667a90a4e764802e74481162ec8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02488f7916bcb14082e1acb101",
    danceability: 0.765,
    energy: 0.327,
    valence: 0.292,
    added_at: "2019-08-01T05:18:34Z",
    link: "https://open.spotify.com/track/4NZrZgrRoR4GwR7flWOXRI",
    userID: "royclaud",
    genres: "indie poptimism,modern rock",
  },
  {
    id: "4Tjh34RS4ACZ6f6srlDBg8",
    name: "Fire Squad",
    artists: "J. Cole",
    duration_ms: 288200,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/e773e92169bd743b85f9bb9d1d9c64ae9b4e6626?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026aca031ccc27d2e4dd829d14",
    danceability: 0.516,
    energy: 0.738,
    valence: 0.599,
    added_at: "2019-07-19T15:44:28Z",
    link: "https://open.spotify.com/track/4Tjh34RS4ACZ6f6srlDBg8",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,north carolina hip hop,rap",
  },
  {
    id: "6vBdBCoOhKHiYDDOcorfNo",
    name: "Goodbyes (Feat. Young Thug)",
    artists: "Post Malone, Young Thug",
    duration_ms: 174960,
    popularity: 3,
    preview_url: "https://p.scdn.co/mp3-preview/5eaefa30dfb2fef460bc377f933b570a0c931596?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023f154c97dfa06ee696bd687c",
    danceability: 0.58,
    energy: 0.653,
    valence: 0.175,
    added_at: "2019-07-18T20:53:35Z",
    link: "https://open.spotify.com/track/6vBdBCoOhKHiYDDOcorfNo",
    userID: "royclaud",
    genres: "dfw rap,melodic rap,pop,rap,atl hip hop,atl trap,gangster rap,hip hop,melodic rap,rap,trap",
  },
  {
    id: "6fTt0CH2t0mdeB2N9XFG5r",
    name: "Panini",
    artists: "Lil Nas X",
    duration_ms: 114893,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/e9bf27ec0415fedffcfd48953b80352709664140?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c0e7bf5cdd630f314f20586a",
    danceability: 0.703,
    energy: 0.594,
    valence: 0.475,
    added_at: "2019-07-13T04:27:57Z",
    link: "https://open.spotify.com/track/6fTt0CH2t0mdeB2N9XFG5r",
    userID: "royclaud",
    genres: "lgbtq+ hip hop",
  },
  {
    id: "3kxfsdsCpFgN412fpnW85Y",
    name: "Redbone",
    artists: "Childish Gambino",
    duration_ms: 326933,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/14918511e11a9e46fa170413821e5f89bd31872a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020b97e56ffad7c6211aa848f8",
    danceability: 0.745,
    energy: 0.356,
    valence: 0.607,
    added_at: "2019-07-02T22:23:07Z",
    link: "https://open.spotify.com/track/3kxfsdsCpFgN412fpnW85Y",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap",
  },
  {
    id: "6gpcs5eMhJwax4mIfKDYQk",
    name: "Slide (feat. Frank Ocean & Migos)",
    artists: "Calvin Harris, Frank Ocean, Migos",
    duration_ms: 230813,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/16f9a0b74ce7aa75cfcfcd2bd928cda87ba11045?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021e439ac0ed08d612808f7122",
    danceability: 0.736,
    energy: 0.795,
    valence: 0.511,
    added_at: "2019-07-02T22:22:32Z",
    link: "https://open.spotify.com/track/6gpcs5eMhJwax4mIfKDYQk",
    userID: "royclaud",
    genres: "dance pop,edm,electro house,house,pop,progressive house,uk dance,lgbtq+ hip hop,neo soul,atl hip hop,hip hop,pop rap,rap,southern hip hop,trap",
  },
  {
    id: "2lUirvUhqfBqJzUvk4tLoK",
    name: "It Was A Good Day",
    artists: "Ice Cube",
    duration_ms: 259946,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/ea4eb0210dd50f7b3e1c43136adab0afc86e418f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a934e914b113a071249b2fcc",
    danceability: 0.792,
    energy: 0.82,
    valence: 0.793,
    added_at: "2019-07-02T22:20:53Z",
    link: "https://open.spotify.com/track/2lUirvUhqfBqJzUvk4tLoK",
    userID: "royclaud",
    genres: "conscious hip hop,g funk,gangster rap,hip hop,rap,west coast rap",
  },
  {
    id: "0yb28j15yuI99JlYQbSpo2",
    name: "Watch Me Fall",
    artists: "Lil Xan",
    duration_ms: 167159,
    popularity: 31,
    preview_url: "https://p.scdn.co/mp3-preview/375a048d3213a3ebbd2248703023a6cedd69506c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023101a18f0fd052c91bbfa67f",
    danceability: 0.698,
    energy: 0.59,
    valence: 0.113,
    added_at: "2019-07-02T22:19:52Z",
    link: "https://open.spotify.com/track/0yb28j15yuI99JlYQbSpo2",
    userID: "royclaud",
    genres: "cloud rap",
  },
  {
    id: "14mHfNsSHN0FbLftyjrV9g",
    name: "Leave Me Alone",
    artists: "Flipp Dinero",
    duration_ms: 195637,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/a87f5eb0c5abca7b0575aeb3cd356b5564e3239c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0243db6f882b9d67079153c773",
    danceability: 0.792,
    energy: 0.743,
    valence: 0.742,
    added_at: "2019-07-02T22:19:21Z",
    link: "https://open.spotify.com/track/14mHfNsSHN0FbLftyjrV9g",
    userID: "royclaud",
    genres: "melodic rap,trap",
  },
  {
    id: "1Knctxx9vGZxpZfF66BIEa",
    name: "Legends",
    artists: "Juice WRLD",
    duration_ms: 191840,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/4b3e1da250c0fb155aa87a4f5693c4565cd7e220?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f9061a1abb0b9a27a78c9916",
    danceability: 0.594,
    energy: 0.798,
    valence: 0.381,
    added_at: "2019-07-02T22:19:11Z",
    link: "https://open.spotify.com/track/1Knctxx9vGZxpZfF66BIEa",
    userID: "royclaud",
    genres: "chicago rap,melodic rap,rap",
  },
  {
    id: "4vaxvNDaLSoD36iZX515ug",
    name: "Betrayed",
    artists: "Lil Xan",
    duration_ms: 187173,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/841dbee71fe0048cefcbd6ead5a970a919073116?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b1950512a3bc3b9a36ad317d",
    danceability: 0.821,
    energy: 0.434,
    valence: 0.241,
    added_at: "2019-07-02T22:18:54Z",
    link: "https://open.spotify.com/track/4vaxvNDaLSoD36iZX515ug",
    userID: "royclaud",
    genres: "cloud rap",
  },
  {
    id: "1A43TfohQt3H6K5zg28ExD",
    name: "Dead and Cold",
    artists: "SadBoyProlific",
    duration_ms: 89652,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/deb698983e5796b1e524f587b5b34467879fa27d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0279378616f1f52dcb4c27d862",
    danceability: 0.793,
    energy: 0.441,
    valence: 0.753,
    added_at: "2019-07-02T22:18:23Z",
    link: "https://open.spotify.com/track/1A43TfohQt3H6K5zg28ExD",
    userID: "royclaud",
    genres: "sad lo-fi,sad rap",
  },
  {
    id: "3gR5vEwHrvEntTYnzxZXEd",
    name: "Summertime (feat. Citizen Cope)",
    artists: "Stick Figure, Citizen Cope",
    duration_ms: 239633,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/5117b8420e48c79b6ad0d7a0229285821c97e653?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027045c505775b3d956929a9a3",
    danceability: 0.743,
    energy: 0.612,
    valence: 0.811,
    added_at: "2019-06-29T11:02:28Z",
    link: "https://open.spotify.com/track/3gR5vEwHrvEntTYnzxZXEd",
    userID: "royclaud",
    genres: "reggae fusion,reggae rock,alternative rock",
  },
  {
    id: "5qEOSPI0uJC1x1gVLph90I",
    name: "Pissed It All Away",
    artists: "Slaine",
    duration_ms: 218170,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/93621260e5e823d05c6aff759e29e7eebed2376b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a17908629c0e7f2e80a364c4",
    danceability: 0.818,
    energy: 0.702,
    valence: 0.269,
    added_at: "2019-06-27T06:50:16Z",
    link: "https://open.spotify.com/track/5qEOSPI0uJC1x1gVLph90I",
    userID: "royclaud",
    genres: "boston hip hop",
  },
  {
    id: "0lppL7OMGEnzruhQUT9fZq",
    name: "I'm Not Real",
    artists: "Mac Miller, Earl Sweatshirt",
    duration_ms: 203399,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/b9cddbf20019979d6f6a9c6165d89567da146da1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d4300bedba646965fa7e1953",
    danceability: 0.474,
    energy: 0.688,
    valence: 0.741,
    added_at: "2019-06-27T05:20:37Z",
    link: "https://open.spotify.com/track/0lppL7OMGEnzruhQUT9fZq",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap,alternative hip hop,drumless hip hop,experimental hip hop,hip hop,rap,underground hip hop",
  },
  {
    id: "6XkuklKiHYVTlVvWlTgQYP",
    name: "Erase Me - Main",
    artists: "Kid Cudi, Kanye West",
    duration_ms: 192253,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/152877a45dd39a9580111820b1ab77ca1366fa10?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0259e842b6a3566a141f27f815",
    danceability: 0.687,
    energy: 0.631,
    valence: 0.853,
    added_at: "2019-06-25T02:40:23Z",
    link: "https://open.spotify.com/track/6XkuklKiHYVTlVvWlTgQYP",
    userID: "royclaud",
    genres: "hip hop,ohio hip hop,pop rap,rap,chicago rap,hip hop,rap",
  },
  {
    id: "5h0Jgt873QtgL6nJRBGfT6",
    name: "That's Life (feat. Mac Miller & Sia)",
    artists: "88-Keys, Mac Miller, Sia",
    duration_ms: 202390,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/b5d63bbba6bfe66f8bae6a0b3343ea80a26f00ef?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02353c3e83eafd0cf06847af52",
    danceability: 0.525,
    energy: 0.774,
    valence: 0.526,
    added_at: "2019-06-20T15:31:51Z",
    link: "https://open.spotify.com/track/5h0Jgt873QtgL6nJRBGfT6",
    userID: "royclaud",
    genres: "alternative hip hop,hip hop,pittsburgh rap,rap,pop",
  },
  {
    id: "5gsW8TMmNVnevjq13h0Nlp",
    name: "DESPUES QUE TE PERDI",
    artists: "Jon Z, Enrique Iglesias",
    duration_ms: 247174,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/096352a35ab70b830bcca081b6d1c215327a4b79?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ddadca34a0e24f14a997a39a",
    danceability: 0.762,
    energy: 0.813,
    valence: 0.6,
    added_at: "2019-06-20T00:35:44Z",
    link: "https://open.spotify.com/track/5gsW8TMmNVnevjq13h0Nlp",
    userID: "royclaud",
    genres: "reggaeton,trap latino,urbano latino,dance pop,latin pop,mexican pop",
  },
  {
    id: "6BC5qv70s01qQpFyKSsuLV",
    name: "The Meaning",
    artists: "FKi 1st, Post Malone",
    duration_ms: 225001,
    popularity: 40,
    preview_url: "https://p.scdn.co/mp3-preview/34836112689581767a3598e269f475d6cb7d18ab?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0273fb19e6f7bd18f2474abceb",
    danceability: 0.678,
    energy: 0.455,
    valence: 0.571,
    added_at: "2019-06-20T00:24:22Z",
    link: "https://open.spotify.com/track/6BC5qv70s01qQpFyKSsuLV",
    userID: "royclaud",
    genres: "atl trap,dfw rap,melodic rap,pop,rap",
  },
  {
    id: "7sOf1HL29vtcJLO6MBNxnj",
    name: "So Much",
    artists: "Rah-C",
    duration_ms: 221797,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/457161addcf40f4b5b9f7c21bbf79060835e6c66?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ed4ff42fff49c8de3a168eac",
    danceability: 0.85,
    energy: 0.658,
    valence: 0.429,
    added_at: "2019-06-20T00:16:50Z",
    link: "https://open.spotify.com/track/7sOf1HL29vtcJLO6MBNxnj",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "01s0zl3P4iYR1BqfCgNQuC",
    name: "You Think You Know Me",
    artists: "Kid $Ixty, Syndrome",
    duration_ms: 182769,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/3a260c842a91d54b55b0ba1d97a4c09491f169ad?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028f542f135d99333561f3bbc0",
    danceability: 0.845,
    energy: 0.476,
    valence: 0.193,
    added_at: "2019-06-19T23:55:08Z",
    link: "https://open.spotify.com/track/01s0zl3P4iYR1BqfCgNQuC",
    userID: "royclaud",
    genres: "emo rap,lo-fi product",
  },
  {
    id: "6rbeWjEavBHvX2kr6lSogS",
    name: "Work Out",
    artists: "J. Cole",
    duration_ms: 234773,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/4324d2e1aa2397adee4f5ee9c7d971fd1bdaeee6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f8a619752d418f3d6d73b2b2",
    danceability: 0.831,
    energy: 0.427,
    valence: 0.216,
    added_at: "2019-06-18T18:45:29Z",
    link: "https://open.spotify.com/track/6rbeWjEavBHvX2kr6lSogS",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,north carolina hip hop,rap",
  },
  {
    id: "3mTpegrOwRn0oJjv4TSbEE",
    name: "Can't Get Enough",
    artists: "J. Cole, Trey Songz",
    duration_ms: 225960,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/145eaa33948fb9ed5e97f499e55c2536e43c759a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0299da48a530f6ca2ef86da3cc",
    danceability: 0.768,
    energy: 0.62,
    valence: 0.589,
    added_at: "2019-06-18T18:41:42Z",
    link: "https://open.spotify.com/track/3mTpegrOwRn0oJjv4TSbEE",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,north carolina hip hop,rap,dance pop,r&b,southern hip hop,trap,urban contemporary",
  },
  {
    id: "30P2nXxtLhULOVbELJnquf",
    name: "One Last Thing",
    artists: "Mac Miller",
    duration_ms: 178584,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/cd35e0f8f289842afad7426b92b8d001ad510bf9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e42f55227470cd6ac9d97718",
    danceability: 0.384,
    energy: 0.767,
    valence: 0.22,
    added_at: "2019-06-15T19:03:36Z",
    link: "https://open.spotify.com/track/30P2nXxtLhULOVbELJnquf",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "4C9nbUpvmXkJT5b9ZTEDm6",
    name: "Cinco De Mayo",
    artists: "MANSION ALLSTAR",
    duration_ms: 312894,
    popularity: 0,
    preview_url: "null",
    image: "https://i.scdn.co/image/ab67616d00001e02b6b4ca1d4dd4dca482a3c94f",
    danceability: 0.87,
    energy: 0.374,
    valence: 0.0863,
    added_at: "2019-06-12T05:34:36Z",
    link: "https://open.spotify.com/track/4C9nbUpvmXkJT5b9ZTEDm6",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "5cgEadiGKjtTP1Q6NJqVP5",
    name: "A-Team",
    artists: "Travis Scott",
    duration_ms: 176640,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/0c0345befc1923208f5801919461d4f9b7374ba6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d0c92f4f71a3c437f620a366",
    danceability: 0.626,
    energy: 0.526,
    valence: 0.17,
    added_at: "2019-06-07T15:44:32Z",
    link: "https://open.spotify.com/track/5cgEadiGKjtTP1Q6NJqVP5",
    userID: "royclaud",
    genres: "rap,slap house",
  },
  {
    id: "2nBI3iWLhupR7LyAJ5GGkE",
    name: "Champions",
    artists: "Kanye West, Gucci Mane, Big Sean, 2 Chainz, Travis Scott, Yo Gotti, Quavo, Desiigner",
    duration_ms: 334253,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/5ee9971fb7b6562fac5866b3510b84d9280e613f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bddf05707f93b37fdbfeaab5",
    danceability: 0.692,
    energy: 0.774,
    valence: 0.349,
    added_at: "2019-06-07T15:43:19Z",
    link: "https://open.spotify.com/track/2nBI3iWLhupR7LyAJ5GGkE",
    userID: "royclaud",
    genres:
      "chicago rap,hip hop,rap,atl hip hop,dirty south rap,hip hop,pop rap,rap,southern hip hop,trap,detroit hip hop,hip hop,pop rap,r&b,rap,southern hip hop,trap,atl hip hop,hip hop,pop rap,rap,southern hip hop,trap,rap,slap house,dirty south rap,gangster rap,memphis hip hop,rap,southern hip hop,tennessee hip hop,trap,atl hip hop,melodic rap,rap,trap,pop rap,rap,southern hip hop,trap,viral trap",
  },
  {
    id: "3oDkdAySo1VQQG0ptV7uwa",
    name: "Lean Wit Me",
    artists: "Juice WRLD",
    duration_ms: 175755,
    popularity: 77,
    preview_url: "https://p.scdn.co/mp3-preview/06755f4c18c933703bff62749fb6cd9b454b8ffe?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f7db43292a6a99b21b51d5b4",
    danceability: 0.665,
    energy: 0.651,
    valence: 0.237,
    added_at: "2019-06-03T20:25:31Z",
    link: "https://open.spotify.com/track/3oDkdAySo1VQQG0ptV7uwa",
    userID: "royclaud",
    genres: "chicago rap,melodic rap,rap",
  },
  {
    id: "0OguxdspxLLTf97lkA0Hpr",
    name: "Diamonds (feat. A$AP Rocky)",
    artists: "A$AP Twelvyy, A$AP Rocky",
    duration_ms: 180480,
    popularity: 40,
    preview_url: "https://p.scdn.co/mp3-preview/415d8be2278fd28d5a8acca60de1840d26fbad63?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02540280d46b1248420f7c79b2",
    danceability: 0.441,
    energy: 0.596,
    valence: 0.138,
    added_at: "2019-05-31T06:14:46Z",
    link: "https://open.spotify.com/track/0OguxdspxLLTf97lkA0Hpr",
    userID: "royclaud",
    genres: "underground hip hop,east coast hip hop,hip hop,rap",
  },
  {
    id: "01xhMrdCefADl7HSttJaf7",
    name: "Infinite",
    artists: "Eminem",
    duration_ms: 251707,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/8d38cb0ca74d3fd2f4c38f188990eff5dbb17ede?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e64677fa775648c4bcf964fa",
    danceability: 0.637,
    energy: 0.862,
    valence: 0.573,
    added_at: "2019-05-28T04:25:53Z",
    link: "https://open.spotify.com/track/01xhMrdCefADl7HSttJaf7",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,rap",
  },
  {
    id: "7DcvwMAiqKJQD1rrdfxSDx",
    name: "The London (feat. J. Cole & Travis Scott)",
    artists: "Young Thug, J. Cole, Travis Scott",
    duration_ms: 200106,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/71e6ced5da6095a57a34bbb1c2be0b010d1845d4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02137c2d3150118e7157800c5b",
    danceability: 0.796,
    energy: 0.586,
    valence: 0.179,
    added_at: "2019-05-26T16:52:58Z",
    link: "https://open.spotify.com/track/7DcvwMAiqKJQD1rrdfxSDx",
    userID: "royclaud",
    genres: "atl hip hop,atl trap,gangster rap,hip hop,melodic rap,rap,trap,conscious hip hop,hip hop,north carolina hip hop,rap,rap,slap house",
  },
  {
    id: "0nCjciaA68cHNBRA1C8GXN",
    name: "Do Not Disturb (feat. Kamaiyah, G-Eazy)",
    artists: "YG, Kamaiyah, G-Eazy",
    duration_ms: 185680,
    popularity: 38,
    preview_url: "https://p.scdn.co/mp3-preview/5a87cd7c7444a2bab23b60a6aa87f545211012b9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c47a4334e09516587959e7ef",
    danceability: 0.918,
    energy: 0.591,
    valence: 0.562,
    added_at: "2019-05-25T19:42:25Z",
    link: "https://open.spotify.com/track/0nCjciaA68cHNBRA1C8GXN",
    userID: "royclaud",
    genres: "cali rap,hip hop,pop rap,rap,southern hip hop,trap,alternative r&b,cali rap,oakland hip hop,indie pop rap,oakland hip hop,pop rap,rap",
  },
  {
    id: "5viCJ7GSQxVWUAPBVoV7IL",
    name: "Me Now",
    artists: "Denzel Curry",
    duration_ms: 279136,
    popularity: 39,
    preview_url: "https://p.scdn.co/mp3-preview/e1c422093c527907ecf239e8a4d5938c46f8ae88?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02166442984ba98f0a2dcaea5e",
    danceability: 0.726,
    energy: 0.619,
    valence: 0.485,
    added_at: "2019-05-25T04:05:26Z",
    link: "https://open.spotify.com/track/5viCJ7GSQxVWUAPBVoV7IL",
    userID: "royclaud",
    genres: "dark trap,drill,hip hop,miami hip hop,rap,trap,underground hip hop",
  },
  {
    id: "6rz0dTA0PdhXImFV5EjM0w",
    name: "Hide (feat. Seezyn)",
    artists: "Juice WRLD, Seezyn",
    duration_ms: 205800,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/0ff1d2328aa76200360ed4d183bdc43a157d0bd0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e2e352d89826aef6dbd5ff8f",
    danceability: 0.715,
    energy: 0.638,
    valence: 0.151,
    added_at: "2019-05-25T04:02:53Z",
    link: "https://open.spotify.com/track/6rz0dTA0PdhXImFV5EjM0w",
    userID: "royclaud",
    genres: "chicago rap,melodic rap,rap,cincinnati rap",
  },
  {
    id: "2BSbCCbaSCzkOEZa6N5901",
    name: "Flaws And Sins",
    artists: "Juice WRLD",
    duration_ms: 218348,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/24069f7e7a76cf44f561a5d62a1c40eba923e774?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02af149eb4002f65f83afc63c4",
    danceability: 0.709,
    energy: 0.662,
    valence: 0.439,
    added_at: "2019-05-25T03:57:48Z",
    link: "https://open.spotify.com/track/2BSbCCbaSCzkOEZa6N5901",
    userID: "royclaud",
    genres: "chicago rap,melodic rap,rap",
  },
  {
    id: "3d9DChrdc6BOeFsbrZ3Is0",
    name: "Under the Bridge",
    artists: "Red Hot Chili Peppers",
    duration_ms: 264306,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/46b0229c06712c5b5e143724c0617e51a9b6e432?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02153d79816d853f2694b2cc70",
    danceability: 0.559,
    energy: 0.345,
    valence: 0.458,
    added_at: "2019-05-15T16:52:34Z",
    link: "https://open.spotify.com/track/3d9DChrdc6BOeFsbrZ3Is0",
    userID: "royclaud",
    genres: "alternative rock,funk metal,funk rock,permanent wave,rock",
  },
  {
    id: "371VkfwKiXJxgH5ZPoQNHD",
    name: "The Joker",
    artists: "Steve Miller Band",
    duration_ms: 264503,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/6291f55a2e6ef6e126bb2c25cc4b62288ba804e0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0275da4b79907852f0c5d0cd9e",
    danceability: 0.596,
    energy: 0.448,
    valence: 0.796,
    added_at: "2019-05-15T16:52:29Z",
    link: "https://open.spotify.com/track/371VkfwKiXJxgH5ZPoQNHD",
    userID: "royclaud",
    genres: "album rock,classic rock,hard rock,heartland rock,mellow gold,rock,singer-songwriter,soft rock",
  },
  {
    id: "5ZgZhzQGCqVwPydT4CkH0W",
    name: "Like a Rolling Stone - Live at Royal Albert Hall, London, UK - May 26, 1966",
    artists: "Bob Dylan",
    duration_ms: 505240,
    popularity: 31,
    preview_url: "https://p.scdn.co/mp3-preview/93e6a4bb815ecc6b4286d5eb166cf42676ae8c9b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e2924c262e042aa0657efb61",
    danceability: 0.162,
    energy: 0.867,
    valence: 0.499,
    added_at: "2019-05-15T16:52:25Z",
    link: "https://open.spotify.com/track/5ZgZhzQGCqVwPydT4CkH0W",
    userID: "royclaud",
    genres: "classic rock,country rock,folk,folk rock,rock,roots rock,singer-songwriter",
  },
  {
    id: "6FRwDxXsvSasw0y2eDArsz",
    name: "Sunshine Of Your Love",
    artists: "Cream",
    duration_ms: 250000,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/54d045d7ba05e947710059710f18e80826f63e54?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f7d3ba2473d4452b83ba61c4",
    danceability: 0.684,
    energy: 0.481,
    valence: 0.784,
    added_at: "2019-05-15T16:51:50Z",
    link: "https://open.spotify.com/track/6FRwDxXsvSasw0y2eDArsz",
    userID: "royclaud",
    genres: "album rock,blues rock,british blues,classic rock,folk rock,hard rock,psychedelic rock,rock,singer-songwriter,supergroup",
  },
  {
    id: "2aoo2jlRnM3A0NyLQqMN2f",
    name: "All Along the Watchtower",
    artists: "Jimi Hendrix",
    duration_ms: 240800,
    popularity: 73,
    preview_url: "https://p.scdn.co/mp3-preview/4f5eb4f96894dcab22691ebc7605cdbc4a7e70aa?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02522088789d49e216d9818292",
    danceability: 0.438,
    energy: 0.805,
    valence: 0.564,
    added_at: "2019-05-15T16:51:34Z",
    link: "https://open.spotify.com/track/2aoo2jlRnM3A0NyLQqMN2f",
    userID: "royclaud",
    genres: "acid rock,album rock,alternative rock,classic rock,hard rock,proto-metal,psychedelic rock,rock",
  },
  {
    id: "1BECwm5qkaBwlbfo4kpYx8",
    name: "Death of a Bachelor",
    artists: "Panic! At The Disco",
    duration_ms: 203506,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/ce893e1dd98a3073ad8d7e427d94333b425e125b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0223152d9337d6c57b116ed13a",
    danceability: 0.462,
    energy: 0.538,
    valence: 0.405,
    added_at: "2019-05-11T17:47:26Z",
    link: "https://open.spotify.com/track/1BECwm5qkaBwlbfo4kpYx8",
    userID: "royclaud",
    genres: "pop",
  },
  {
    id: "1J9J5K8OX7CzCa7U72zZr5",
    name: "Nassau",
    artists: "Huseby",
    duration_ms: 215092,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/8ab76dfa1acf65c1045ff4693127c87d1fac9d0e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027f0331aef93f1a12177c8fc7",
    danceability: 0.742,
    energy: 0.604,
    valence: 0.217,
    added_at: "2019-05-10T06:01:45Z",
    link: "https://open.spotify.com/track/1J9J5K8OX7CzCa7U72zZr5",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "3OnOomd79OjqSYHchCQ6ht",
    name: "Notice Me",
    artists: "Migos, Post Malone",
    duration_ms: 233880,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/432d32a07341d99d249adc80925f43a66cf8ca06?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029c188c494d8bfaf895411890",
    danceability: 0.793,
    energy: 0.344,
    valence: 0.45,
    added_at: "2019-04-22T02:31:13Z",
    link: "https://open.spotify.com/track/3OnOomd79OjqSYHchCQ6ht",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,pop rap,rap,southern hip hop,trap,dfw rap,melodic rap,pop,rap",
  },
  {
    id: "0ghMMDAQchfy2C2NQk5InC",
    name: "Hold You Up",
    artists: "DeJ Loaf, Jacquees",
    duration_ms: 155637,
    popularity: 38,
    preview_url: "https://p.scdn.co/mp3-preview/eb5a71db04e60e9edceb9b2f1dd6ab296b18ded3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026d8414de8a94fbf46bc418af",
    danceability: 0.493,
    energy: 0.713,
    valence: 0.449,
    added_at: "2019-04-12T04:23:45Z",
    link: "https://open.spotify.com/track/0ghMMDAQchfy2C2NQk5InC",
    userID: "royclaud",
    genres: "detroit hip hop,r&b,trap,trap queen,atl hip hop,pop r&b,pop rap,r&b,rap,trap",
  },
  {
    id: "6jq6rcOikCZAmjliAgAmfT",
    name: "Light",
    artists: "San Holo",
    duration_ms: 239853,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/df4cd25e3fe93b489e81b0ace76cc112fedb0192?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a5c1582e2b2b087ee7862758",
    danceability: 0.57,
    energy: 0.763,
    valence: 0.43,
    added_at: "2019-04-05T00:13:01Z",
    link: "https://open.spotify.com/track/6jq6rcOikCZAmjliAgAmfT",
    userID: "royclaud",
    genres: "brostep,edm,electro house,electronic trap,future bass,pop dance",
  },
  {
    id: "0EA2RhRHL4KWeNa7JfD1Yw",
    name: "So It Goes",
    artists: "Mac Miller",
    duration_ms: 312960,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/e52d07fd81589eb79b5239d70f3529a23a8cef72?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02175c577a61aa13d4fb4b6534",
    danceability: 0.576,
    energy: 0.556,
    valence: 0.294,
    added_at: "2019-04-01T01:17:43Z",
    link: "https://open.spotify.com/track/0EA2RhRHL4KWeNa7JfD1Yw",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "23OXdR7YuUBVWh5hSnYJau",
    name: "Chlorine",
    artists: "Twenty One Pilots",
    duration_ms: 324466,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/08d5802772357860f04dfafedda7035bcc374bfa?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d1d301e737da4324479c6660",
    danceability: 0.609,
    energy: 0.674,
    valence: 0.315,
    added_at: "2019-03-30T05:31:14Z",
    link: "https://open.spotify.com/track/23OXdR7YuUBVWh5hSnYJau",
    userID: "royclaud",
    genres: "modern rock,pop,pov: indie,rock",
  },
  {
    id: "4RKnVvExDjV7s86GxlAvjB",
    name: "TiP TOE 3",
    artists: "Riff Raff, Chief Keef",
    duration_ms: 195200,
    popularity: 35,
    preview_url: "https://p.scdn.co/mp3-preview/5e0f1fc23bfac53a6f1dab080a6b2fd52858be64?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fb021a87b23ff60e00fde5cc",
    danceability: 0.771,
    energy: 0.656,
    valence: 0.142,
    added_at: "2019-03-25T23:50:30Z",
    link: "https://open.spotify.com/track/4RKnVvExDjV7s86GxlAvjB",
    userID: "royclaud",
    genres: "chicago bop,chicago drill,chicago rap,drill,hip hop,rap,trap",
  },
  {
    id: "1lWK70HfYRFZtWJUwUrY48",
    name: "Die Young",
    artists: "Roddy Ricch",
    duration_ms: 161128,
    popularity: 43,
    preview_url: "https://p.scdn.co/mp3-preview/07c48ca8b48febab456f01628d76f1ba65a7f4e8?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024a6568bd5b16dc79108fc5ec",
    danceability: 0.688,
    energy: 0.642,
    valence: 0.341,
    added_at: "2019-03-17T20:50:09Z",
    link: "https://open.spotify.com/track/1lWK70HfYRFZtWJUwUrY48",
    userID: "royclaud",
    genres: "melodic rap,rap,trap",
  },
  {
    id: "26OhjtaTamFocE08t83ml6",
    name: "Psycho, Pt. 2",
    artists: "Russ",
    duration_ms: 161985,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/d4798dfcdeba61c613865076b7ff5f6426554fe4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d76647a817d55638a50c863f",
    danceability: 0.766,
    energy: 0.548,
    valence: 0.424,
    added_at: "2019-03-12T05:33:18Z",
    link: "https://open.spotify.com/track/26OhjtaTamFocE08t83ml6",
    userID: "royclaud",
    genres: "hawaiian hip hop,pop rap",
  },
  {
    id: "1hWYT0w2R0J19rlVkiez7X",
    name: "Battle Scars (with Guy Sebastian)",
    artists: "Lupe Fiasco, Guy Sebastian",
    duration_ms: 250320,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/603e0d66208ae84a08f628c8f229456ad028c0ad?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024b9f3b34399b7ae4cc7a45be",
    danceability: 0.517,
    energy: 0.811,
    valence: 0.494,
    added_at: "2019-03-12T05:27:16Z",
    link: "https://open.spotify.com/track/1hWYT0w2R0J19rlVkiez7X",
    userID: "royclaud",
    genres: "chicago rap,conscious hip hop,hip hop,political hip hop,pop rap,rap,southern hip hop,australian pop",
  },
  {
    id: "4wJ8dJKBAxdDe5cUx8K779",
    name: "R.I.P. (feat. Trippie Redd)",
    artists: "Joji, Trippie Redd",
    duration_ms: 158726,
    popularity: 4,
    preview_url: "https://p.scdn.co/mp3-preview/baa9e1f9c75e859e69a5efab687897cd885feeea?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0260ba1d6104d0475c7555a6b2",
    danceability: 0.716,
    energy: 0.515,
    valence: 0.383,
    added_at: "2019-03-10T06:20:59Z",
    link: "https://open.spotify.com/track/4wJ8dJKBAxdDe5cUx8K779",
    userID: "royclaud",
    genres: "viral pop,melodic rap,rap,trap",
  },
  {
    id: "01TnVDiet1DFTsyWKUKovl",
    name: "NUMB",
    artists: "XXXTENTACION",
    duration_ms: 186267,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/beadf5d85b61d633c8bfc11fe0af91ed27e89a9b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02806c160566580d6335d1f16c",
    danceability: 0.414,
    energy: 0.416,
    valence: 0.196,
    added_at: "2019-03-04T05:14:57Z",
    link: "https://open.spotify.com/track/01TnVDiet1DFTsyWKUKovl",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "6DB2KOEwHnjkgEnBt5SdeJ",
    name: "Mask Off (feat. Kendrick Lamar) - Remix",
    artists: "Future, Kendrick Lamar",
    duration_ms: 257986,
    popularity: 53,
    preview_url: "https://p.scdn.co/mp3-preview/1b7157cc9e5d9b629c2534df30f3cdcfecce3d62?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020cf32251bdb54d0131f2eade",
    danceability: 0.727,
    energy: 0.431,
    valence: 0.223,
    added_at: "2019-02-27T07:03:19Z",
    link: "https://open.spotify.com/track/6DB2KOEwHnjkgEnBt5SdeJ",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap,southern hip hop,trap,conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "2v5JTeM6hSmi5wWy7jiwrI",
    name: "I've Been Waiting (w/ ILoveMakonnen & Fall Out Boy)",
    artists: "Lil Peep, ILOVEMAKONNEN, Fall Out Boy",
    duration_ms: 233613,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/a8f4faefe73214e4d465dde78d38e99f11925002?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029067de29733d1ecf1b87e583",
    danceability: 0.741,
    energy: 0.845,
    valence: 0.926,
    added_at: "2019-02-27T07:02:06Z",
    link: "https://open.spotify.com/track/2v5JTeM6hSmi5wWy7jiwrI",
    userID: "royclaud",
    genres: "cloud rap,emo rap,lgbtq+ hip hop,atl hip hop,lgbtq+ hip hop,trap,emo,modern rock,pop,rock",
  },
  {
    id: "2mYDDUkBcgFNBqkWctyyuc",
    name: "Overdue (with Travis Scott)",
    artists: "Metro Boomin, Travis Scott",
    duration_ms: 166093,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/68bf9014188a2e17be768f85083b65835847a3ef?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02184cc62dccee251dafefefee",
    danceability: 0.638,
    energy: 0.4,
    valence: 0.495,
    added_at: "2019-02-27T07:00:08Z",
    link: "https://open.spotify.com/track/2mYDDUkBcgFNBqkWctyyuc",
    userID: "royclaud",
    genres: "rap,rap,slap house",
  },
  {
    id: "7sO5G9EABYOXQKNPNiE9NR",
    name: "Ric Flair Drip (with Metro Boomin)",
    artists: "Offset, Metro Boomin",
    duration_ms: 172800,
    popularity: 84,
    preview_url: "https://p.scdn.co/mp3-preview/89cee86b4663ac093731d7a433491dd71e286f61?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020db43130a9aaa7bae56967ed",
    danceability: 0.88,
    energy: 0.428,
    valence: 0.333,
    added_at: "2019-02-27T06:55:12Z",
    link: "https://open.spotify.com/track/7sO5G9EABYOXQKNPNiE9NR",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,pop rap,rap,trap,rap",
  },
  {
    id: "4jvQ3EvoTkgeXiqTjLlhPv",
    name: "Caught Up",
    artists: "Caleborate",
    duration_ms: 214368,
    popularity: 39,
    preview_url: "https://p.scdn.co/mp3-preview/0c074c3e7e6314d9e62f90ce3243fe67d6c2706e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02568027b2ff0e21b08d2092a0",
    danceability: 0.535,
    energy: 0.216,
    valence: 0.246,
    added_at: "2019-02-26T05:48:26Z",
    link: "https://open.spotify.com/track/4jvQ3EvoTkgeXiqTjLlhPv",
    userID: "royclaud",
    genres: "bay area indie,cali rap,indie hip hop,underground hip hop",
  },
  {
    id: "4Nrg8EG7wWTiTOumtpw8gk",
    name: "Let The Drummer Kick",
    artists: "Citizen Cope",
    duration_ms: 257093,
    popularity: 53,
    preview_url: "https://p.scdn.co/mp3-preview/f696f8355d7c5c6278e7683aecdfb61ea7d5baa0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023e9c2c083f43176e9fa44133",
    danceability: 0.766,
    energy: 0.641,
    valence: 0.579,
    added_at: "2019-02-21T03:42:10Z",
    link: "https://open.spotify.com/track/4Nrg8EG7wWTiTOumtpw8gk",
    userID: "royclaud",
    genres: "alternative rock",
  },
  {
    id: "7uzmGiiJyRfuViKKK3lVmR",
    name: "Mine",
    artists: "Bazzi",
    duration_ms: 131064,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/c0a7b6ae1f9e5af8169b6aa870bf05c41933c8da?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f9f2d43ff44bdfbe8c556f8d",
    danceability: 0.71,
    energy: 0.789,
    valence: 0.717,
    added_at: "2019-02-19T22:15:35Z",
    link: "https://open.spotify.com/track/7uzmGiiJyRfuViKKK3lVmR",
    userID: "royclaud",
    genres: "pop",
  },
  {
    id: "0rTV5WefWd1J3OwIheTzxM",
    name: "Talk",
    artists: "Khalid, Disclosure",
    duration_ms: 197487,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/f63f47b2ed5c3f81c303c630af40b2fad0d14a9c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020eb47b2eec859a783ceb72c8",
    danceability: 0.9,
    energy: 0.4,
    valence: 0.338,
    added_at: "2019-02-19T05:39:15Z",
    link: "https://open.spotify.com/track/0rTV5WefWd1J3OwIheTzxM",
    userID: "royclaud",
    genres: "pop,pop r&b,edm,house,indietronica,uk dance",
  },
  {
    id: "43PuMrRfbyyuz4QpZ3oAwN",
    name: "Exchange",
    artists: "Bryson Tiller",
    duration_ms: 194613,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/5b6d7b159ec3ffc2c231621165eff5073e0459a5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d5f3cea8affdca01a0dc754f",
    danceability: 0.525,
    energy: 0.433,
    valence: 0.276,
    added_at: "2019-02-19T05:38:22Z",
    link: "https://open.spotify.com/track/43PuMrRfbyyuz4QpZ3oAwN",
    userID: "royclaud",
    genres: "kentucky hip hop,r&b,rap",
  },
  {
    id: "0AluA5RNsa4Cx6XRhf2hWZ",
    name: "10 Freaky Girls (with 21 Savage)",
    artists: "Metro Boomin, 21 Savage",
    duration_ms: 208293,
    popularity: 74,
    preview_url: "https://p.scdn.co/mp3-preview/0d9e8bffa4bbb265be0acff87d9d7f93b4467755?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022887f8c05b5a9f1cb105be29",
    danceability: 0.797,
    energy: 0.353,
    valence: 0.0731,
    added_at: "2019-02-12T02:40:18Z",
    link: "https://open.spotify.com/track/0AluA5RNsa4Cx6XRhf2hWZ",
    userID: "royclaud",
    genres: "rap,atl hip hop,hip hop,rap",
  },
  {
    id: "0hPLZrnDgtKxrym1BHjDhd",
    name: "can't leave without it",
    artists: "21 Savage",
    duration_ms: 205351,
    popularity: 72,
    preview_url: "https://p.scdn.co/mp3-preview/c1d36f40a98f79dac33174570f8dfca6f100c5d4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02280689ecc5e4b2038bb5e4bd",
    danceability: 0.872,
    energy: 0.591,
    valence: 0.696,
    added_at: "2019-02-07T01:01:57Z",
    link: "https://open.spotify.com/track/0hPLZrnDgtKxrym1BHjDhd",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap",
  },
  {
    id: "0rFpcfF0YfvFUYsl99Bw9d",
    name: "Soundtrack 2 My Life",
    artists: "Kid Cudi",
    duration_ms: 235626,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/ea5002d43c5f9c4344ef5d0e1210d9e08d544540?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a487deeecb334b6619489d74",
    danceability: 0.484,
    energy: 0.755,
    valence: 0.441,
    added_at: "2019-02-06T19:33:12Z",
    link: "https://open.spotify.com/track/0rFpcfF0YfvFUYsl99Bw9d",
    userID: "royclaud",
    genres: "hip hop,ohio hip hop,pop rap,rap",
  },
  {
    id: "5qHirGR7M9tdm6C17DlzSY",
    name: "REEL IT IN",
    artists: "Aminé",
    duration_ms: 121346,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/63c2cb574b1be75b7b90e13a3c615d3aad3d5d8b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a52fb68f00620e45ed7467c5",
    danceability: 0.754,
    energy: 0.342,
    valence: 0.423,
    added_at: "2019-02-05T08:38:23Z",
    link: "https://open.spotify.com/track/5qHirGR7M9tdm6C17DlzSY",
    userID: "royclaud",
    genres: "pop rap,portland hip hop,rap,underground hip hop",
  },
  {
    id: "2t8yVaLvJ0RenpXUIAC52d",
    name: "a lot",
    artists: "21 Savage",
    duration_ms: 288624,
    popularity: 77,
    preview_url: "https://p.scdn.co/mp3-preview/8e4bedd4dd85c00a3cd298e59f209fd23818b7d6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02280689ecc5e4b2038bb5e4bd",
    danceability: 0.837,
    energy: 0.636,
    valence: 0.274,
    added_at: "2019-02-05T05:06:22Z",
    link: "https://open.spotify.com/track/2t8yVaLvJ0RenpXUIAC52d",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap",
  },
  {
    id: "78QR3Wp35dqAhFEc2qAGjE",
    name: "Drip Too Hard (Lil Baby & Gunna)",
    artists: "Lil Baby, Gunna",
    duration_ms: 145542,
    popularity: 79,
    preview_url: "https://p.scdn.co/mp3-preview/613d9fe82d39123b5f37cfcbc9e3c22431fd3c8a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ce159a3ba2096e13fa9d4b4c",
    danceability: 0.897,
    energy: 0.662,
    valence: 0.39,
    added_at: "2019-01-31T19:46:58Z",
    link: "https://open.spotify.com/track/78QR3Wp35dqAhFEc2qAGjE",
    userID: "royclaud",
    genres: "atl hip hop,atl trap,rap,trap,atl hip hop,melodic rap,rap,trap",
  },
  {
    id: "3LySNPf6PRGhOVWJdZutow",
    name: "Crack A Bottle",
    artists: "Eminem, Dr. Dre, 50 Cent",
    duration_ms: 297520,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/d8f5600404e6ac7910bcee3911662dc42e91feb9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e023192b6bb91211e0f499b3fff",
    danceability: 0.516,
    energy: 0.874,
    valence: 0.391,
    added_at: "2019-01-31T19:46:34Z",
    link: "https://open.spotify.com/track/3LySNPf6PRGhOVWJdZutow",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,rap,g funk,gangster rap,hip hop,rap,west coast rap,east coast hip hop,gangster rap,hip hop,pop rap,queens hip hop,rap",
  },
  {
    id: "32iYwQ4OYiurnGnOfJ1aEh",
    name: "Lucid Dreams",
    artists: "Juice WRLD",
    duration_ms: 239946,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/85ba40ef782e993bac9ee1c945f90c123b11ffec?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025eb0085f2770818792c3c970",
    danceability: 0.437,
    energy: 0.481,
    valence: 0.216,
    added_at: "2019-01-30T23:11:48Z",
    link: "https://open.spotify.com/track/32iYwQ4OYiurnGnOfJ1aEh",
    userID: "royclaud",
    genres: "chicago rap,melodic rap,rap",
  },
  {
    id: "3DK6m7It6Pw857FcQftMds",
    name: "Runaway",
    artists: "Kanye West, Pusha T",
    duration_ms: 547733,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/a1f828c57ecf2b61cee888d956e5b59aebb14610?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d9194aa18fa4c9362b47464f",
    danceability: 0.374,
    energy: 0.568,
    valence: 0.111,
    added_at: "2019-01-25T16:16:29Z",
    link: "https://open.spotify.com/track/3DK6m7It6Pw857FcQftMds",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap,gangster rap,hip hop,pop rap,rap,southern hip hop,trap,virginia hip hop",
  },
  {
    id: "4RVbK6cV0VqWdpCDcx3hiT",
    name: "Reborn",
    artists: "KIDS SEE GHOSTS",
    duration_ms: 324674,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/f75ac65c50fcc81e642f58220e7f3abaf827e77a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02013c00ee367dd85396f79c82",
    danceability: 0.585,
    energy: 0.597,
    valence: 0.227,
    added_at: "2019-01-25T16:02:07Z",
    link: "https://open.spotify.com/track/4RVbK6cV0VqWdpCDcx3hiT",
    userID: "royclaud",
    genres: "rap",
  },
  {
    id: "3jg8bevUzKYONDLBBQquif",
    name: "Never Catch Me",
    artists: "Travis Scott",
    duration_ms: 176160,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/f8053b31922d5df7d0622539641080a8fbde26a3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026cfd9a7353f98f5165ea6160",
    danceability: 0.511,
    energy: 0.521,
    valence: 0.126,
    added_at: "2019-01-20T06:32:22Z",
    link: "https://open.spotify.com/track/3jg8bevUzKYONDLBBQquif",
    userID: "royclaud",
    genres: "rap,slap house",
  },
  {
    id: "5rYpwP1dHWGVMrSZbTmt3e",
    name: "Life is Beautiful",
    artists: "Lil Peep",
    duration_ms: 207586,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/c5f29133af79c8eb7e7efd7b870bb36025d1f7dc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0255e36b0dc5b0ef008fc85319",
    danceability: 0.533,
    energy: 0.638,
    valence: 0.324,
    added_at: "2018-12-22T23:08:29Z",
    link: "https://open.spotify.com/track/5rYpwP1dHWGVMrSZbTmt3e",
    userID: "royclaud",
    genres: "cloud rap,emo rap,lgbtq+ hip hop",
  },
  {
    id: "4axYWgXASdsCBHuwInAubT",
    name: "I don't let go",
    artists: "XXXTENTACION",
    duration_ms: 121630,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/cc467f73f29d6eaed43bdbfb43bcb35918587a57?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02da9e59639a9759d8952890c6",
    danceability: 0.797,
    energy: 0.293,
    valence: 0.478,
    added_at: "2018-12-14T03:28:00Z",
    link: "https://open.spotify.com/track/4axYWgXASdsCBHuwInAubT",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "237B0GS3Y8VwGGD9bGl1vG",
    name: "Red Dot Music",
    artists: "Mac Miller, Action Bronson",
    duration_ms: 367930,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/f2bb44eaa2b83a911625985f6b1ed29195cf9338?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d4300bedba646965fa7e1953",
    danceability: 0.537,
    energy: 0.686,
    valence: 0.284,
    added_at: "2018-11-19T18:26:48Z",
    link: "https://open.spotify.com/track/237B0GS3Y8VwGGD9bGl1vG",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap,alternative hip hop,east coast hip hop,hip hop,nyc rap,queens hip hop",
  },
  {
    id: "304CtPEPYQS6hbM4CubVti",
    name: "No Complaints (feat. Offset & Drake) (Bonus)",
    artists: "Metro Boomin, Offset, Drake",
    duration_ms: 265480,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/7e77efedb5dcf9fbb2cc57d621421e4a789dbcc6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02184cc62dccee251dafefefee",
    danceability: 0.914,
    energy: 0.572,
    valence: 0.203,
    added_at: "2018-11-02T19:35:45Z",
    link: "https://open.spotify.com/track/304CtPEPYQS6hbM4CubVti",
    userID: "royclaud",
    genres: "rap,atl hip hop,hip hop,pop rap,rap,trap,canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "1HLL8X0LH20F6Z9SLXLIjr",
    name: "No More (feat. Travis Scott, Kodak Black & 21 Savage)",
    artists: "Metro Boomin, Travis Scott, Kodak Black, 21 Savage",
    duration_ms: 265120,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/6936eb728003f4eacc99ab5f0025185028ffe217?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02184cc62dccee251dafefefee",
    danceability: 0.636,
    energy: 0.501,
    valence: 0.196,
    added_at: "2018-11-02T19:30:51Z",
    link: "https://open.spotify.com/track/1HLL8X0LH20F6Z9SLXLIjr",
    userID: "royclaud",
    genres: "rap,rap,slap house,florida drill,florida rap,melodic rap,miami hip hop,rap,trap,trap latino,atl hip hop,hip hop,rap",
  },
  {
    id: "7HLglWbzDheHZ3VZUbxGLC",
    name: "Seals Pills (Lil Baby & Gunna)",
    artists: "Lil Baby, Gunna",
    duration_ms: 176330,
    popularity: 43,
    preview_url: "https://p.scdn.co/mp3-preview/f4c3bfe087b2e13fabcfa39adc4d334a56f59ec0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ce159a3ba2096e13fa9d4b4c",
    danceability: 0.623,
    energy: 0.541,
    valence: 0.368,
    added_at: "2018-10-12T01:49:05Z",
    link: "https://open.spotify.com/track/7HLglWbzDheHZ3VZUbxGLC",
    userID: "royclaud",
    genres: "atl hip hop,atl trap,rap,trap,atl hip hop,melodic rap,rap,trap",
  },
  {
    id: "16QjPRt5HYK0dnLGmGRCvf",
    name: "Deep End (Lil Baby & Gunna)",
    artists: "Lil Baby",
    duration_ms: 174661,
    popularity: 47,
    preview_url: "https://p.scdn.co/mp3-preview/41799586624791c0f21bf2769fd36d1e98325ad1?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ce159a3ba2096e13fa9d4b4c",
    danceability: 0.66,
    energy: 0.535,
    valence: 0.39,
    added_at: "2018-10-12T00:37:34Z",
    link: "https://open.spotify.com/track/16QjPRt5HYK0dnLGmGRCvf",
    userID: "royclaud",
    genres: "atl hip hop,atl trap,rap,trap",
  },
  {
    id: "1yQvL8SzbF5JCroRKk9F07",
    name: "Belly (Lil Baby & Gunna)",
    artists: "Lil Baby, Gunna",
    duration_ms: 191724,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/c29cc80f674a0bd1df6439640726ee2e43baaf2d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02ce159a3ba2096e13fa9d4b4c",
    danceability: 0.765,
    energy: 0.692,
    valence: 0.426,
    added_at: "2018-10-12T00:32:43Z",
    link: "https://open.spotify.com/track/1yQvL8SzbF5JCroRKk9F07",
    userID: "royclaud",
    genres: "atl hip hop,atl trap,rap,trap,atl hip hop,melodic rap,rap,trap",
  },
  {
    id: "7lQWRAjyhTpCWFC0jmclT4",
    name: "Gangsta's Paradise",
    artists: "Coolio, L.V.",
    duration_ms: 240693,
    popularity: 4,
    preview_url: "https://p.scdn.co/mp3-preview/1454c63a66c27ac745f874d6c113270a9c62d28e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027632fe25cd368dc205927f0c",
    danceability: 0.647,
    energy: 0.514,
    valence: 0.387,
    added_at: "2018-10-10T00:20:32Z",
    link: "https://open.spotify.com/track/7lQWRAjyhTpCWFC0jmclT4",
    userID: "royclaud",
    genres: "g funk,gangster rap,hip hop,west coast rap",
  },
  {
    id: "1DRW9bQzt2SE8f7H4KJZyL",
    name: "Sky Walker (feat. Travis Scott)",
    artists: "Miguel, Travis Scott",
    duration_ms: 260077,
    popularity: 41,
    preview_url: "https://p.scdn.co/mp3-preview/c95b541168b5a8cb39c2d648060cee74cd68db7c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020821ead0f39992d8a67f1254",
    danceability: 0.629,
    energy: 0.717,
    valence: 0.129,
    added_at: "2018-10-04T15:15:24Z",
    link: "https://open.spotify.com/track/1DRW9bQzt2SE8f7H4KJZyL",
    userID: "royclaud",
    genres: "r&b,urban contemporary,rap,slap house",
  },
  {
    id: "5cGpjfgAvidLy6znwkCxkP",
    name: "Can't Be Broken",
    artists: "Lil Wayne",
    duration_ms: 193243,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/8797ee379fd45faf8eaeccf08212e108cccbf79a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bb1138165ad4ec2306f4e886",
    danceability: 0.58,
    energy: 0.577,
    valence: 0.153,
    added_at: "2018-09-28T17:29:13Z",
    link: "https://open.spotify.com/track/5cGpjfgAvidLy6znwkCxkP",
    userID: "royclaud",
    genres: "hip hop,new orleans rap,pop rap,rap,trap",
  },
  {
    id: "3FFcZZq3Z3EJrhUecwcMdG",
    name: "She Will",
    artists: "Lil Wayne, Drake",
    duration_ms: 305840,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/8166e89d0ad518cbdea4d154542f01522929bb20?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a1edbe4e3f3e3fe296816af4",
    danceability: 0.364,
    energy: 0.841,
    valence: 0.0837,
    added_at: "2018-09-27T06:27:51Z",
    link: "https://open.spotify.com/track/3FFcZZq3Z3EJrhUecwcMdG",
    userID: "royclaud",
    genres: "hip hop,new orleans rap,pop rap,rap,trap,canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "689uBlyIufk2LUhAwjny4w",
    name: "Nowadays (feat. Landon Cube)",
    artists: "Lil Skies, Landon Cube",
    duration_ms: 203907,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/ecf3cb2a2c36fc860fad841a4267f8a21039fffd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f944f5eb561b61b57250e599",
    danceability: 0.745,
    energy: 0.646,
    valence: 0.46,
    added_at: "2018-09-27T01:06:33Z",
    link: "https://open.spotify.com/track/689uBlyIufk2LUhAwjny4w",
    userID: "royclaud",
    genres: "melodic rap,pop rap",
  },
  {
    id: "1kxeWHF9PrCVZHvVskv8lg",
    name: "Love Lockdown",
    artists: "Kanye West",
    duration_ms: 270306,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/ab539aa695bb9847abcd931c9d10bcf82ac1903f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02346d77e155d854735410ed18",
    danceability: 0.76,
    energy: 0.524,
    valence: 0.112,
    added_at: "2018-09-26T23:52:25Z",
    link: "https://open.spotify.com/track/1kxeWHF9PrCVZHvVskv8lg",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "7w9bgPAmPTtrkt2v16QWvQ",
    name: 'Lose Yourself - From "8 Mile" Soundtrack',
    artists: "Eminem",
    duration_ms: 326466,
    popularity: 4,
    preview_url: "https://p.scdn.co/mp3-preview/f943c5b5954918a2dbeb13981861859f77978d12?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a8a32ae2a279b9bf03445738",
    danceability: 0.701,
    energy: 0.728,
    valence: 0.0591,
    added_at: "2018-09-26T23:45:30Z",
    link: "https://open.spotify.com/track/7w9bgPAmPTtrkt2v16QWvQ",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,rap",
  },
  {
    id: "5XJJdNPkwmbUwE79gv0NxK",
    name: "Gold Digger",
    artists: "Kanye West, Jamie Foxx",
    duration_ms: 207626,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/c07d8315fb8fc3a820a312e29961c96a2e7d7229?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024c7dd2b7fc516356e037bf68",
    danceability: 0.646,
    energy: 0.696,
    valence: 0.625,
    added_at: "2018-09-26T23:45:05Z",
    link: "https://open.spotify.com/track/5XJJdNPkwmbUwE79gv0NxK",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap,contemporary r&b,hip pop,pop rap,r&b,southern hip hop,trap,urban contemporary",
  },
  {
    id: "0I3q5fE6wg7LIfHGngUTnV",
    name: "Ms. Jackson",
    artists: "Outkast",
    duration_ms: 270506,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/06f8cc47c72dae9e9a66081b4dc912e9d9b430a9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022350e31bc346a6c20e9de166",
    danceability: 0.843,
    energy: 0.806,
    valence: 0.613,
    added_at: "2018-09-26T23:45:03Z",
    link: "https://open.spotify.com/track/0I3q5fE6wg7LIfHGngUTnV",
    userID: "royclaud",
    genres: "atl hip hop,dirty south rap,hip hop,old school atlanta hip hop,rap,southern hip hop",
  },
  {
    id: "0Dn40gxVCMo5TkgS8Ik18Q",
    name: "Falling Down - Bonus Track",
    artists: "Lil Peep, XXXTENTACION",
    duration_ms: 196400,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/9404f45725ef5d46e29a539c7da1fc4315cf5a69?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e020211ea0d326ca5340e04648b",
    danceability: 0.669,
    energy: 0.574,
    valence: 0.273,
    added_at: "2018-09-19T15:06:56Z",
    link: "https://open.spotify.com/track/0Dn40gxVCMo5TkgS8Ik18Q",
    userID: "royclaud",
    genres: "cloud rap,emo rap,lgbtq+ hip hop,emo rap,miami hip hop,rap",
  },
  {
    id: "6MtKObWYda2qnNIpJI21uD",
    name: "Pursuit Of Happiness (Nightmare)",
    artists: "Kid Cudi, MGMT, Ratatat",
    duration_ms: 295293,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/82ac85bf6bae24320158fc4d921ff2b48f72f42f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a487deeecb334b6619489d74",
    danceability: 0.629,
    energy: 0.606,
    valence: 0.288,
    added_at: "2018-09-18T04:37:44Z",
    link: "https://open.spotify.com/track/6MtKObWYda2qnNIpJI21uD",
    userID: "royclaud",
    genres: "hip hop,ohio hip hop,pop rap,rap,alternative rock,indie rock,indietronica,modern rock,rock,brooklyn indie,indietronica",
  },
  {
    id: "6gBFPUFcJLzWGx4lenP6h2",
    name: "goosebumps",
    artists: "Travis Scott",
    duration_ms: 243836,
    popularity: 85,
    preview_url: "https://p.scdn.co/mp3-preview/5c45fee2743d39984ed2c1e2493d04a42d626f81?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f54b99bf27cda88f4a7403ce",
    danceability: 0.841,
    energy: 0.728,
    valence: 0.43,
    added_at: "2018-09-18T04:24:50Z",
    link: "https://open.spotify.com/track/6gBFPUFcJLzWGx4lenP6h2",
    userID: "royclaud",
    genres: "rap,slap house",
  },
  {
    id: "2hnMS47jN0etwvFPzYk11f",
    name: "Santeria",
    artists: "Sublime",
    duration_ms: 182746,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/9bd14340e2c557e2e9cdc454f0820b20dd48100e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028fc4b0dcfb9509553f195c85",
    danceability: 0.682,
    energy: 0.765,
    valence: 0.567,
    added_at: "2018-09-16T17:52:45Z",
    link: "https://open.spotify.com/track/2hnMS47jN0etwvFPzYk11f",
    userID: "royclaud",
    genres: "rap rock,reggae fusion,ska mexicano,ska punk",
  },
  {
    id: "5rwOE5J3Y1A2NiRa6y3Yph",
    name: "Stainless",
    artists: "Logic, Dria",
    duration_ms: 199573,
    popularity: 48,
    preview_url: "https://p.scdn.co/mp3-preview/329097c4a07b8115c395cbfd6900db9c0a82c251?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f0f7649257d4b99460929ced",
    danceability: 0.705,
    energy: 0.899,
    valence: 0.641,
    added_at: "2018-09-11T04:39:16Z",
    link: "https://open.spotify.com/track/5rwOE5J3Y1A2NiRa6y3Yph",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,pop rap,rap",
  },
  {
    id: "20MuVazoNMv6xjKPnRFOxG",
    name: "WAKE UP",
    artists: "Travis Scott",
    duration_ms: 231963,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/aba2af9b263620f0ffcc2b32b80489c73a9daa47?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02072e9faef2ef7b6db63834a3",
    danceability: 0.741,
    energy: 0.645,
    valence: 0.488,
    added_at: "2018-08-03T05:24:22Z",
    link: "https://open.spotify.com/track/20MuVazoNMv6xjKPnRFOxG",
    userID: "royclaud",
    genres: "rap,slap house",
  },
  {
    id: "5YbrqNUUleF39Ai3b3ruQy",
    name: "Liquor Locker",
    artists: "VIC MENSA",
    duration_ms: 258120,
    popularity: 47,
    preview_url: "https://p.scdn.co/mp3-preview/0c53c89c92905b31fc295d990868bc939201c159?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02119a0c8637b4fff8d53cd9d4",
    danceability: 0.497,
    energy: 0.433,
    valence: 0.447,
    added_at: "2018-07-17T19:04:17Z",
    link: "https://open.spotify.com/track/5YbrqNUUleF39Ai3b3ruQy",
    userID: "royclaud",
    genres: "canadian hip hop,chicago rap,conscious hip hop,underground hip hop",
  },
  {
    id: "0ZBchpb4CLk5Qqu3895Etp",
    name: "Buttons",
    artists: "Mac Miller",
    duration_ms: 228440,
    popularity: 56,
    preview_url: "https://p.scdn.co/mp3-preview/c5dd04940eb8ab4409112de906dfdfbcecd4c771?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02700e5d5a2fcb6eee4a5778fc",
    danceability: 0.578,
    energy: 0.714,
    valence: 0.524,
    added_at: "2018-07-17T19:01:40Z",
    link: "https://open.spotify.com/track/0ZBchpb4CLk5Qqu3895Etp",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "0b2e9UjnyEhSFwPaJewaIR",
    name: "Got That",
    artists: "Tyrellskrt",
    duration_ms: 123350,
    popularity: 0,
    preview_url: "null",
    image: "https://i.scdn.co/image/ab67616d00001e0298b60123d3d80f2e3d8d5189",
    danceability: 0.6,
    energy: 0.71,
    valence: 0.415,
    added_at: "2018-07-09T04:56:58Z",
    link: "https://open.spotify.com/track/0b2e9UjnyEhSFwPaJewaIR",
    userID: "royclaud",
    genres: "",
  },
  {
    id: "1883mCuSqnfnrWoq8i3myH",
    name: "Programs",
    artists: "Mac Miller",
    duration_ms: 190186,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/eff9bf921893f04516a06efda36953f71d7e4c35?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0284bae70f69df07899a1f83c2",
    danceability: 0.88,
    energy: 0.569,
    valence: 0.474,
    added_at: "2018-07-08T16:57:00Z",
    link: "https://open.spotify.com/track/1883mCuSqnfnrWoq8i3myH",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "4PGXZqGreypA5uE9IMR8zz",
    name: "Small Worlds",
    artists: "Mac Miller",
    duration_ms: 274213,
    popularity: 35,
    preview_url: "https://p.scdn.co/mp3-preview/95f957c1e225c5c5a96ab181c696b0db9e29391a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02282b4edb9f7ac1af06c0f768",
    danceability: 0.502,
    energy: 0.595,
    valence: 0.517,
    added_at: "2018-07-05T16:51:47Z",
    link: "https://open.spotify.com/track/4PGXZqGreypA5uE9IMR8zz",
    userID: "royclaud",
    genres: "hip hop,pittsburgh rap,rap",
  },
  {
    id: "15k2nBQJ0teDmPZHrOXL2N",
    name: "Ordinary Life",
    artists: "The Weeknd",
    duration_ms: 221920,
    popularity: 60,
    preview_url: "https://p.scdn.co/mp3-preview/196e602d3b44f7b7ab660b111d3da688adb301bd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024718e2b124f79258be7bc452",
    danceability: 0.537,
    energy: 0.587,
    valence: 0.365,
    added_at: "2018-07-05T16:40:18Z",
    link: "https://open.spotify.com/track/15k2nBQJ0teDmPZHrOXL2N",
    userID: "royclaud",
    genres: "canadian contemporary r&b,canadian pop,pop",
  },
  {
    id: "3HoOJiD2QthEgo4ju41gNE",
    name: "Sin Corte",
    artists: "Zimple",
    duration_ms: 186746,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/b7e51fac7e11447cf1addf4af671fd10b7e848b4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028fad7489d8b0d3f930ccc532",
    danceability: 0.646,
    energy: 0.896,
    valence: 0.962,
    added_at: "2018-06-30T19:25:38Z",
    link: "https://open.spotify.com/track/3HoOJiD2QthEgo4ju41gNE",
    userID: "royclaud",
    genres: "mexican hip hop",
  },
  {
    id: "3X9OVlc4YaL9nowxAVcJex",
    name: "Stay Woke (feat. Miguel)",
    artists: "Meek Mill, Miguel",
    duration_ms: 212500,
    popularity: 37,
    preview_url: "https://p.scdn.co/mp3-preview/70bd685fb87591fe23ca6bdbb6f03c8bee1e34b9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f43b204c4a1c935b8e9615a3",
    danceability: 0.577,
    energy: 0.615,
    valence: 0.112,
    added_at: "2018-06-30T00:39:53Z",
    link: "https://open.spotify.com/track/3X9OVlc4YaL9nowxAVcJex",
    userID: "royclaud",
    genres: "hip hop,philly rap,pop rap,rap,southern hip hop,trap,r&b,urban contemporary",
  },
  {
    id: "2m3G4SXZiJEOvqf3s0s9d8",
    name: "OSOM (feat. J. Cole)",
    artists: "Jay Rock, J. Cole",
    duration_ms: 323120,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/1f00a247a038c1dfd64350418c2b90193ad5a1ec?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e026d7a10a53a81e70629be9aff",
    danceability: 0.749,
    energy: 0.674,
    valence: 0.396,
    added_at: "2018-06-23T03:39:22Z",
    link: "https://open.spotify.com/track/2m3G4SXZiJEOvqf3s0s9d8",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,southern hip hop,trap,conscious hip hop,hip hop,north carolina hip hop,rap",
  },
  {
    id: "5e90dsXJMBw9ndBRiKP1ZA",
    name: "Problems",
    artists: "Lil Peep",
    duration_ms: 209213,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/d24eed90e8468d57759223a98930991030c2a1b4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b006ef6c1ce94763bb407519",
    danceability: 0.64,
    energy: 0.558,
    valence: 0.208,
    added_at: "2018-06-22T21:43:32Z",
    link: "https://open.spotify.com/track/5e90dsXJMBw9ndBRiKP1ZA",
    userID: "royclaud",
    genres: "cloud rap,emo rap,lgbtq+ hip hop",
  },
  {
    id: "3kBD2xHIqKWXjLAGidDTSz",
    name: "Benz Truck (гелик)",
    artists: "Lil Peep",
    duration_ms: 159986,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/743d35302fda8edd6245f619ae9ef93340974225?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b006ef6c1ce94763bb407519",
    danceability: 0.602,
    energy: 0.762,
    valence: 0.113,
    added_at: "2018-06-22T21:25:17Z",
    link: "https://open.spotify.com/track/3kBD2xHIqKWXjLAGidDTSz",
    userID: "royclaud",
    genres: "cloud rap,emo rap,lgbtq+ hip hop",
  },
  {
    id: "1tlrTC3x7viJ1U30oOFGdF",
    name: "Better Off (Dying)",
    artists: "Lil Peep",
    duration_ms: 154814,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/8e7436804ecdf0cc3a5ea8917aa45e0edac5e9d4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b006ef6c1ce94763bb407519",
    danceability: 0.521,
    energy: 0.763,
    valence: 0.197,
    added_at: "2018-06-22T21:24:30Z",
    link: "https://open.spotify.com/track/1tlrTC3x7viJ1U30oOFGdF",
    userID: "royclaud",
    genres: "cloud rap,emo rap,lgbtq+ hip hop",
  },
  {
    id: "39JE6Xt36uZidHVrK9S8xK",
    name: "Slipknot",
    artists: "XXXTENTACION, Kin$oul, Killstation",
    duration_ms: 209098,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/16dd0467744f2689bc29e14c0ee10c08528b9a58?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0225b5901b8bcf902f54d466d9",
    danceability: 0.448,
    energy: 0.541,
    valence: 0.237,
    added_at: "2018-06-22T21:20:11Z",
    link: "https://open.spotify.com/track/39JE6Xt36uZidHVrK9S8xK",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap,cloud rap,dark trap",
  },
  {
    id: "71Zj7SeXOXHUsdLrwWlEg1",
    name: "SAFE",
    artists: "Tra$h",
    duration_ms: 176710,
    popularity: 0,
    preview_url: "null",
    image: "https://i.scdn.co/image/ab67616d00001e0295e63480f2360e4eaf7a67c7",
    danceability: 0.806,
    energy: 0.37,
    valence: 0.822,
    added_at: "2018-06-22T21:17:24Z",
    link: "https://open.spotify.com/track/71Zj7SeXOXHUsdLrwWlEg1",
    userID: "royclaud",
    genres: "lo-fi chill",
  },
  {
    id: "2UwbhMie1EAYuTZ0QXeMwl",
    name: "Save Me",
    artists: "XXXTENTACION",
    duration_ms: 163201,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/a7f7c4fd97914e706ba0d4900392dc174f065941?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02203c89bd4391468eea4cc3f5",
    danceability: 0.731,
    energy: 0.204,
    valence: 0.191,
    added_at: "2018-06-22T21:02:53Z",
    link: "https://open.spotify.com/track/2UwbhMie1EAYuTZ0QXeMwl",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "5SFTZsHW3HdCp9f1zbzitS",
    name: "Revenge",
    artists: "XXXTENTACION",
    duration_ms: 120058,
    popularity: 58,
    preview_url: "https://p.scdn.co/mp3-preview/b08b17da47b109acda291ca52fbf3954fa2743fa?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c620816e42af388305f68adf",
    danceability: 0.708,
    energy: 0.235,
    valence: 0.169,
    added_at: "2018-06-22T20:59:36Z",
    link: "https://open.spotify.com/track/5SFTZsHW3HdCp9f1zbzitS",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "0NTMtAO2BV4tnGvw9EgBVq",
    name: "Bitch Better Have My Money",
    artists: "Rihanna",
    duration_ms: 219305,
    popularity: 73,
    preview_url: "https://p.scdn.co/mp3-preview/4371c0f7f2f397ff0f522fd62032157ba7f37153?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c137319751a89295f921cce8",
    danceability: 0.781,
    energy: 0.728,
    valence: 0.395,
    added_at: "2018-06-19T21:53:55Z",
    link: "https://open.spotify.com/track/0NTMtAO2BV4tnGvw9EgBVq",
    userID: "royclaud",
    genres: "barbadian pop,pop,urban contemporary",
  },
  {
    id: "6rje9f1wRFJDO2iTORw0lH",
    name: "I Know",
    artists: "Big Sean, Jhené Aiko",
    duration_ms: 319973,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/45fff7e1f7670a77a8973831c06cc0677cd93330?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0250192d5f728fea13fb3af203",
    danceability: 0.733,
    energy: 0.334,
    valence: 0.267,
    added_at: "2018-06-17T20:39:01Z",
    link: "https://open.spotify.com/track/6rje9f1wRFJDO2iTORw0lH",
    userID: "royclaud",
    genres: "detroit hip hop,hip hop,pop rap,r&b,rap,southern hip hop,trap,r&b",
  },
  {
    id: "1zCi4cVFqe6ja16MeGZKRN",
    name: "Poetic Justice",
    artists: "Kendrick Lamar, Drake",
    duration_ms: 300160,
    popularity: 53,
    preview_url: "https://p.scdn.co/mp3-preview/c8b35f35fb237263a78da259dccebd1f3c253050?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d28d2ebdedb220e479743797",
    danceability: 0.779,
    energy: 0.572,
    valence: 0.647,
    added_at: "2018-06-13T03:22:41Z",
    link: "https://open.spotify.com/track/1zCi4cVFqe6ja16MeGZKRN",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap,canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "3qnoOm4fwZPBS116f5hpgF",
    name: "All Mine",
    artists: "Kanye West",
    duration_ms: 145506,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/6e00e9a2c2140aae73f876cf8b48cb9264b3ad05?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0297508a4b756763370510bd44",
    danceability: 0.925,
    energy: 0.308,
    valence: 0.265,
    added_at: "2018-06-02T04:52:16Z",
    link: "https://open.spotify.com/track/3qnoOm4fwZPBS116f5hpgF",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "6EuE9M1viu9gkdFSafia9o",
    name: "I Thought About Killing You",
    artists: "Kanye West",
    duration_ms: 274186,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/9c05f7e3de20a089c0db6a35f6ee01906b82aa80?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0297508a4b756763370510bd44",
    danceability: 0.742,
    energy: 0.407,
    valence: 0.39,
    added_at: "2018-06-01T21:52:03Z",
    link: "https://open.spotify.com/track/6EuE9M1viu9gkdFSafia9o",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "5N521RK66Z1FP0Oz6B4OlT",
    name: "Gunz N Butter (feat. Juicy J)",
    artists: "A$AP Rocky, Juicy J",
    duration_ms: 213573,
    popularity: 54,
    preview_url: "https://p.scdn.co/mp3-preview/e2830c7980ed5e0b054610e733f1ca4ce7e76fea?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029feadc48ab0661e9b3a9170b",
    danceability: 0.852,
    energy: 0.632,
    valence: 0.715,
    added_at: "2018-05-31T04:25:44Z",
    link: "https://open.spotify.com/track/5N521RK66Z1FP0Oz6B4OlT",
    userID: "royclaud",
    genres: "east coast hip hop,hip hop,rap,crunk,memphis hip hop,pop rap,rap,southern hip hop,trap",
  },
  {
    id: "7ycWLEP1GsNjVvcjawXz3z",
    name: "Praise The Lord (Da Shine) (feat. Skepta)",
    artists: "A$AP Rocky, Skepta",
    duration_ms: 205040,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/ba69e5d1385c353090c2c6c30d398501e611315d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029feadc48ab0661e9b3a9170b",
    danceability: 0.854,
    energy: 0.569,
    valence: 0.294,
    added_at: "2018-05-31T04:25:28Z",
    link: "https://open.spotify.com/track/7ycWLEP1GsNjVvcjawXz3z",
    userID: "royclaud",
    genres: "east coast hip hop,hip hop,rap,birmingham grime,grime,instrumental grime,uk hip hop",
  },
  {
    id: "1AqemxWsT0iGg5Lvkm16Bt",
    name: "Fukk Sleep (feat. FKA twigs)",
    artists: "A$AP Rocky, FKA twigs",
    duration_ms: 192973,
    popularity: 64,
    preview_url: "https://p.scdn.co/mp3-preview/f1b7a8a50ee9b8cd78a7bd6dc5793b9fa97e8c9c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029feadc48ab0661e9b3a9170b",
    danceability: 0.798,
    energy: 0.412,
    valence: 0.304,
    added_at: "2018-05-31T04:17:44Z",
    link: "https://open.spotify.com/track/1AqemxWsT0iGg5Lvkm16Bt",
    userID: "royclaud",
    genres: "east coast hip hop,hip hop,rap,afrofuturism,alternative r&b,art pop,escape room,experimental r&b,trip hop",
  },
  {
    id: "6Hgh47WXVKtXN5zGOu0hjI",
    name: "OTW",
    artists: "Khalid, 6LACK, Ty Dolla $ign",
    duration_ms: 263013,
    popularity: 67,
    preview_url: "https://p.scdn.co/mp3-preview/1d1ee7f7728856cbf44b2957b85fdfd69a83904b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029766f79668e76238a1c13e2c",
    danceability: 0.652,
    energy: 0.678,
    valence: 0.28,
    added_at: "2018-05-21T23:02:41Z",
    link: "https://open.spotify.com/track/6Hgh47WXVKtXN5zGOu0hjI",
    userID: "royclaud",
    genres: "pop,pop r&b,atl hip hop,melodic rap,r&b,rap,trap,hip hop,pop rap,r&b,southern hip hop,trap,trap soul",
  },
  {
    id: "5goGDc74vVREyN8al8CkPh",
    name: "Diplomatic Immunity",
    artists: "Drake",
    duration_ms: 255840,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/c11ba12180a8eddd0569e402617752df287b4a98?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025e8f046078b784e6f8502008",
    danceability: 0.655,
    energy: 0.839,
    valence: 0.55,
    added_at: "2018-05-19T01:13:23Z",
    link: "https://open.spotify.com/track/5goGDc74vVREyN8al8CkPh",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "1wsf1YFQSYND45y0GzJmf7",
    name: "Yes Indeed",
    artists: "Lil Baby, Drake",
    duration_ms: 142273,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/4707ac392292a5eeec89cfd28f9096ebd4574ebc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0280216aa2f866a038045776b0",
    danceability: 0.964,
    energy: 0.346,
    valence: 0.562,
    added_at: "2018-05-19T00:04:32Z",
    link: "https://open.spotify.com/track/1wsf1YFQSYND45y0GzJmf7",
    userID: "royclaud",
    genres: "atl hip hop,atl trap,rap,trap,canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "4H7WNRErSbONkM06blBoGc",
    name: "Stay",
    artists: "Post Malone",
    duration_ms: 204426,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/a4e38872a56b70c00e6ccd9404d10126dbf76857?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b1c4b76e23414c9f20242268",
    danceability: 0.506,
    energy: 0.48,
    valence: 0.351,
    added_at: "2018-05-05T21:52:21Z",
    link: "https://open.spotify.com/track/4H7WNRErSbONkM06blBoGc",
    userID: "royclaud",
    genres: "dfw rap,melodic rap,pop,rap",
  },
  {
    id: "7AQim7LbvFVZJE3O8TYgf2",
    name: "Fuck Love",
    artists: "XXXTENTACION, Trippie Redd",
    duration_ms: 146520,
    popularity: 84,
    preview_url: "https://p.scdn.co/mp3-preview/ad4d23afd3b92325c442f036322a05f5f35385dc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02203c89bd4391468eea4cc3f5",
    danceability: 0.797,
    energy: 0.533,
    valence: 0.329,
    added_at: "2018-04-28T23:40:47Z",
    link: "https://open.spotify.com/track/7AQim7LbvFVZJE3O8TYgf2",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap,melodic rap,rap,trap",
  },
  {
    id: "0JP9xo3adEtGSdUEISiszL",
    name: "Moonlight",
    artists: "XXXTENTACION",
    duration_ms: 135090,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/fc7faf8b857409dd3655ccbc084d90c4f2551797?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02806c160566580d6335d1f16c",
    danceability: 0.921,
    energy: 0.537,
    valence: 0.711,
    added_at: "2018-04-28T23:40:45Z",
    link: "https://open.spotify.com/track/0JP9xo3adEtGSdUEISiszL",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "1yxgsra98r3qAtxqiGZPiX",
    name: "BUTTERFLY EFFECT",
    artists: "Travis Scott",
    duration_ms: 190677,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/68cba40a7199c972c7a2ad0df063c4d1f5e3dc73?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b5e9358d0d07114609d9afab",
    danceability: 0.763,
    energy: 0.598,
    valence: 0.182,
    added_at: "2018-04-17T14:45:23Z",
    link: "https://open.spotify.com/track/1yxgsra98r3qAtxqiGZPiX",
    userID: "royclaud",
    genres: "rap,slap house",
  },
  {
    id: "0S0vWvyZ6Rc79TXkWxT9QA",
    name: "Save That Shit",
    artists: "Lil Peep",
    duration_ms: 231546,
    popularity: 3,
    preview_url: "https://p.scdn.co/mp3-preview/77f5dd5972549e559e4f7a5d3ca368de746d47c4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b006ef6c1ce94763bb407519",
    danceability: 0.534,
    energy: 0.583,
    valence: 0.145,
    added_at: "2018-04-10T21:14:16Z",
    link: "https://open.spotify.com/track/0S0vWvyZ6Rc79TXkWxT9QA",
    userID: "royclaud",
    genres: "cloud rap,emo rap,lgbtq+ hip hop",
  },
  {
    id: "0zO8ctW0UiuOefR87OeJOZ",
    name: "Collard Greens",
    artists: "ScHoolboy Q, Kendrick Lamar",
    duration_ms: 299960,
    popularity: 75,
    preview_url: "https://p.scdn.co/mp3-preview/4a19ec418966837d86c62eb4ba3c96fd62a796f0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0236977e237a8f70ec6c990636",
    danceability: 0.825,
    energy: 0.573,
    valence: 0.66,
    added_at: "2018-04-10T21:01:21Z",
    link: "https://open.spotify.com/track/0zO8ctW0UiuOefR87OeJOZ",
    userID: "royclaud",
    genres: "gangster rap,hip hop,pop rap,rap,southern hip hop,trap,conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "4jURQLDdnZp6l3PsvxJaZk",
    name: "Been On",
    artists: "G-Eazy",
    duration_ms: 208866,
    popularity: 48,
    preview_url: "https://p.scdn.co/mp3-preview/f97ac21259e92da3d8cbfabbc421d620aadeaf38?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bdfe4efd674482cf5ac88c99",
    danceability: 0.609,
    energy: 0.655,
    valence: 0.316,
    added_at: "2018-04-05T00:43:26Z",
    link: "https://open.spotify.com/track/4jURQLDdnZp6l3PsvxJaZk",
    userID: "royclaud",
    genres: "indie pop rap,oakland hip hop,pop rap,rap",
  },
  {
    id: "1xMT0HHQbQFmGJXDq1ApgX",
    name: "Drunk and Hot Girls",
    artists: "Kanye West, Mos Def",
    duration_ms: 313226,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/3ae715e45001d0a39b0e551f5275a05fcc4bdb73?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02675561f3defd1d5a551936a8",
    danceability: 0.662,
    energy: 0.443,
    valence: 0.285,
    added_at: "2018-04-02T18:33:10Z",
    link: "https://open.spotify.com/track/1xMT0HHQbQFmGJXDq1ApgX",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap,conscious hip hop,east coast hip hop,hip hop",
  },
  {
    id: "2iaCM7WvOknQI1230hA9eK",
    name: "Homecoming",
    artists: "Kanye West, Chris Martin",
    duration_ms: 203493,
    popularity: 20,
    preview_url: "https://p.scdn.co/mp3-preview/a7f9626c0fc7e67ef2bdc3ca1655cdfe723878c0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02675561f3defd1d5a551936a8",
    danceability: 0.679,
    energy: 0.754,
    valence: 0.905,
    added_at: "2018-04-02T18:20:36Z",
    link: "https://open.spotify.com/track/2iaCM7WvOknQI1230hA9eK",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "1fLdeDTrJWNkwOeFyAVLvF",
    name: "Good Life",
    artists: "Kanye West, T-Pain",
    duration_ms: 207000,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/bc34b195574e5684735b60ce124b057f84628636?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02675561f3defd1d5a551936a8",
    danceability: 0.435,
    energy: 0.802,
    valence: 0.454,
    added_at: "2018-04-02T18:20:31Z",
    link: "https://open.spotify.com/track/1fLdeDTrJWNkwOeFyAVLvF",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap,dance pop,gangster rap,hip hop,pop rap,r&b,rap,southern hip hop,trap,urban contemporary",
  },
  {
    id: "66Q3fAmSX5eHamgbKa9alP",
    name: "Real Friends",
    artists: "Kanye West",
    duration_ms: 251573,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/e0204e7cc5eb1c2fbad75c538e03bbd9ca503568?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022a7db835b912dc5014bd37f4",
    danceability: 0.441,
    energy: 0.744,
    valence: 0.124,
    added_at: "2018-04-02T18:12:12Z",
    link: "https://open.spotify.com/track/66Q3fAmSX5eHamgbKa9alP",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "30Zcjs7pgEkmJA1lEbGSWT",
    name: "FML",
    artists: "Kanye West",
    duration_ms: 236120,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/13b45e284cdb4459894566264187466d55711754?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e022a7db835b912dc5014bd37f4",
    danceability: 0.527,
    energy: 0.415,
    valence: 0.12,
    added_at: "2018-04-02T18:12:08Z",
    link: "https://open.spotify.com/track/30Zcjs7pgEkmJA1lEbGSWT",
    userID: "royclaud",
    genres: "chicago rap,hip hop,rap",
  },
  {
    id: "3I8MbKRkS2IBDuslhCS3iT",
    name: "Excuse Me",
    artists: "A$AP Rocky",
    duration_ms: 238053,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/96b7f63c3015ee198bbf238b16d0a7a9fbf000fe?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0281a6a2cafab2e7a4066b5eec",
    danceability: 0.653,
    energy: 0.71,
    valence: 0.41,
    added_at: "2018-04-02T18:10:54Z",
    link: "https://open.spotify.com/track/3I8MbKRkS2IBDuslhCS3iT",
    userID: "royclaud",
    genres: "east coast hip hop,hip hop,rap",
  },
  {
    id: "2N3U8uTYhjX2KwGT0pf5x0",
    name: "Everyday (feat. Rod Stewart, Miguel & Mark Ronson)",
    artists: "A$AP Rocky, Rod Stewart, Miguel, Mark Ronson",
    duration_ms: 260986,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/220febf485c664a356b31f667ac3dcdcb9ab33dd?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0281a6a2cafab2e7a4066b5eec",
    danceability: 0.394,
    energy: 0.661,
    valence: 0.335,
    added_at: "2018-04-02T18:10:28Z",
    link: "https://open.spotify.com/track/2N3U8uTYhjX2KwGT0pf5x0",
    userID: "royclaud",
    genres: "east coast hip hop,hip hop,rap,mellow gold,soft rock,r&b,urban contemporary,pop soul",
  },
  {
    id: "6HZILIRieu8S0iqY8kIKhj",
    name: "DNA.",
    artists: "Kendrick Lamar",
    duration_ms: 185946,
    popularity: 79,
    preview_url: "https://p.scdn.co/mp3-preview/107655a6c41a8d0f62a53b5573e1d9288a498e68?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028b52c6b9bc4e43d873869699",
    danceability: 0.638,
    energy: 0.523,
    valence: 0.422,
    added_at: "2018-04-02T18:02:51Z",
    link: "https://open.spotify.com/track/6HZILIRieu8S0iqY8kIKhj",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "53mCo1wDJ12iZNq9LgIeg2",
    name: "Ignorance Is Bliss",
    artists: "Kendrick Lamar",
    duration_ms: 210494,
    popularity: 52,
    preview_url: "https://p.scdn.co/mp3-preview/b53ccd90af23981e087a9bf8512bdb47443b82e5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029b035b031d9f0a6a75ae464e",
    danceability: 0.644,
    energy: 0.801,
    valence: 0.764,
    added_at: "2018-04-02T18:01:59Z",
    link: "https://open.spotify.com/track/53mCo1wDJ12iZNq9LgIeg2",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "5ujh1I7NZH5agbwf7Hp8Hc",
    name: "Swimming Pools (Drank) - Extended Version",
    artists: "Kendrick Lamar",
    duration_ms: 313786,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/e6d4e1096f33a07017c7694501ce571265364f19?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d58e537cea05c2156792c53d",
    danceability: 0.669,
    energy: 0.485,
    valence: 0.261,
    added_at: "2018-04-02T17:58:49Z",
    link: "https://open.spotify.com/track/5ujh1I7NZH5agbwf7Hp8Hc",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "712uvW1Vezq8WpQi38v2L9",
    name: "Bitch, Don’t Kill My Vibe",
    artists: "Kendrick Lamar",
    duration_ms: 310720,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/6cea1dd25b2f28fc4d13e12448fa585de955872a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02d58e537cea05c2156792c53d",
    danceability: 0.586,
    energy: 0.698,
    valence: 0.098,
    added_at: "2018-04-02T17:51:35Z",
    link: "https://open.spotify.com/track/712uvW1Vezq8WpQi38v2L9",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "6olUplztLFFfU7fMYmFXOP",
    name: "Sober",
    artists: "Childish Gambino",
    duration_ms: 252013,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/4b8809e41adc3feffa3ee2ae30b6e04585d54b84?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b81f8fd4b6c69c0eabb592ba",
    danceability: 0.699,
    energy: 0.601,
    valence: 0.434,
    added_at: "2018-04-02T17:33:23Z",
    link: "https://open.spotify.com/track/6olUplztLFFfU7fMYmFXOP",
    userID: "royclaud",
    genres: "atl hip hop,hip hop,rap",
  },
  {
    id: "51rXHuKN8Loc4sUlKPODgH",
    name: "King's Dead (with Kendrick Lamar, Future & James Blake)",
    artists: "Jay Rock, Kendrick Lamar, Future, James Blake",
    duration_ms: 229670,
    popularity: 69,
    preview_url: "https://p.scdn.co/mp3-preview/251f7469b9688485c2d74a39bb82be3cd6498a94?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e029edc391d911405ea9f5c320e",
    danceability: 0.645,
    energy: 0.705,
    valence: 0.376,
    added_at: "2018-04-02T17:01:31Z",
    link: "https://open.spotify.com/track/51rXHuKN8Loc4sUlKPODgH",
    userID: "royclaud",
    genres: "conscious hip hop,hip hop,rap,southern hip hop,trap,conscious hip hop,hip hop,rap,west coast rap,atl hip hop,hip hop,rap,southern hip hop,trap,art pop,indie soul,indietronica,uk alternative pop",
  },
  {
    id: "0lHAMNU8RGiIObScrsRgmP",
    name: "Titanium (feat. Sia)",
    artists: "David Guetta, Sia",
    duration_ms: 245040,
    popularity: 70,
    preview_url: "https://p.scdn.co/mp3-preview/4d3537bf2416e04dc90887c896adcd1c8da4c76e?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a197a2ef3ad6704d1f44aa4e",
    danceability: 0.604,
    energy: 0.788,
    valence: 0.301,
    added_at: "2018-04-02T17:00:28Z",
    link: "https://open.spotify.com/track/0lHAMNU8RGiIObScrsRgmP",
    userID: "royclaud",
    genres: "big room,dance pop,edm,pop,pop dance,pop",
  },
  {
    id: "7xmCwWVmWBawnIzRUdg3Nr",
    name: "Marilyn (feat. Dominique Le Jeune)",
    artists: "G-Eazy, Dominique Le Jeune",
    duration_ms: 196216,
    popularity: 51,
    preview_url: "https://p.scdn.co/mp3-preview/98b5da95219220000d1c53c36c8f6c4161cb655d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027181af52d89063ff89acf3e0",
    danceability: 0.435,
    energy: 0.641,
    valence: 0.156,
    added_at: "2018-04-01T21:22:18Z",
    link: "https://open.spotify.com/track/7xmCwWVmWBawnIzRUdg3Nr",
    userID: "royclaud",
    genres: "indie pop rap,oakland hip hop,pop rap,rap",
  },
  {
    id: "4WmS1ZuledEAlrpJv1E22s",
    name: "Remember You (feat. blackbear)",
    artists: "G-Eazy, blackbear",
    duration_ms: 215880,
    popularity: 44,
    preview_url: "https://p.scdn.co/mp3-preview/435f03d88e3940c04f8bdb69c58267141248cd5a?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bdfe4efd674482cf5ac88c99",
    danceability: 0.654,
    energy: 0.565,
    valence: 0.207,
    added_at: "2018-04-01T21:21:59Z",
    link: "https://open.spotify.com/track/4WmS1ZuledEAlrpJv1E22s",
    userID: "royclaud",
    genres: "indie pop rap,oakland hip hop,pop rap,rap,alt z,pop",
  },
  {
    id: "3qk4wRMDlaeagZGjSYbsOV",
    name: "Sober",
    artists: "G-Eazy, Charlie Puth",
    duration_ms: 203960,
    popularity: 52,
    preview_url: "https://p.scdn.co/mp3-preview/f5f50fa8845dfa274f6df52cad38416fcbfef339?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02046527a9c176f7c2916f3530",
    danceability: 0.558,
    energy: 0.766,
    valence: 0.182,
    added_at: "2018-04-01T21:21:48Z",
    link: "https://open.spotify.com/track/3qk4wRMDlaeagZGjSYbsOV",
    userID: "royclaud",
    genres: "indie pop rap,oakland hip hop,pop rap,rap,pop,viral pop",
  },
  {
    id: "7fOPVfABNLg3sxtgXBhBdp",
    name: "The Plan",
    artists: "G-Eazy",
    duration_ms: 250293,
    popularity: 44,
    preview_url: "https://p.scdn.co/mp3-preview/3ab8cd354505651fd499527875e5e350e4cfa344?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02046527a9c176f7c2916f3530",
    danceability: 0.826,
    energy: 0.597,
    valence: 0.35,
    added_at: "2018-04-01T21:21:39Z",
    link: "https://open.spotify.com/track/7fOPVfABNLg3sxtgXBhBdp",
    userID: "royclaud",
    genres: "indie pop rap,oakland hip hop,pop rap,rap",
  },
  {
    id: "40YcuQysJ0KlGQTeGUosTC",
    name: "Me, Myself & I",
    artists: "G-Eazy, Bebe Rexha",
    duration_ms: 251466,
    popularity: 80,
    preview_url: "https://p.scdn.co/mp3-preview/ea733ebf4d40c173af190a73c375b51d316e5442?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0298acfa8c055deedc25e6081d",
    danceability: 0.756,
    energy: 0.674,
    valence: 0.389,
    added_at: "2018-04-01T21:21:28Z",
    link: "https://open.spotify.com/track/40YcuQysJ0KlGQTeGUosTC",
    userID: "royclaud",
    genres: "indie pop rap,oakland hip hop,pop rap,rap,dance pop,pop",
  },
  {
    id: "6jmTHeoWvBaSrwWttr8Xvu",
    name: "I Mean It (feat. Remo)",
    artists: "G-Eazy, Remo the Hitmaker",
    duration_ms: 236480,
    popularity: 65,
    preview_url: "https://p.scdn.co/mp3-preview/65fc6e30e41d1253880731912a1e3d0f0033e3ad?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02bdfe4efd674482cf5ac88c99",
    danceability: 0.712,
    energy: 0.562,
    valence: 0.142,
    added_at: "2018-04-01T21:21:20Z",
    link: "https://open.spotify.com/track/6jmTHeoWvBaSrwWttr8Xvu",
    userID: "royclaud",
    genres: "indie pop rap,oakland hip hop,pop rap,rap,slow game",
  },
  {
    id: "1Bqxj0aH5KewYHKUg1IdrF",
    name: "Some Kind Of Drug (feat. Marc E. Bassy)",
    artists: "G-Eazy, Marc E. Bassy",
    duration_ms: 222466,
    popularity: 57,
    preview_url: "https://p.scdn.co/mp3-preview/d0cd49173d9fd0f980a3b77ad09fd667626c4592?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0298acfa8c055deedc25e6081d",
    danceability: 0.634,
    energy: 0.525,
    valence: 0.49,
    added_at: "2018-04-01T21:21:16Z",
    link: "https://open.spotify.com/track/1Bqxj0aH5KewYHKUg1IdrF",
    userID: "royclaud",
    genres: "indie pop rap,oakland hip hop,pop rap,rap,indie pop rap",
  },
  {
    id: "5k38wzpLb15YgncyWdTZE4",
    name: "Him & I (with Halsey)",
    artists: "G-Eazy, Halsey",
    duration_ms: 268866,
    popularity: 75,
    preview_url: "https://p.scdn.co/mp3-preview/f3b074fff3b73dfec619cf9a3ff72be8d6de2cb6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02046527a9c176f7c2916f3530",
    danceability: 0.589,
    energy: 0.731,
    valence: 0.191,
    added_at: "2018-04-01T21:21:10Z",
    link: "https://open.spotify.com/track/5k38wzpLb15YgncyWdTZE4",
    userID: "royclaud",
    genres: "indie pop rap,oakland hip hop,pop rap,rap,electropop,etherpop,indie poptimism,pop",
  },
  {
    id: "4L7jMAP8UcIe309yQmkdcO",
    name: "Lost",
    artists: "Frank Ocean",
    duration_ms: 234093,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/2d314c23e818fcd708b5224bf920520ccf69832c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f11f1c4ad183b7fa625f8534",
    danceability: 0.913,
    energy: 0.603,
    valence: 0.497,
    added_at: "2018-04-01T21:19:54Z",
    link: "https://open.spotify.com/track/4L7jMAP8UcIe309yQmkdcO",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "7DfFc7a6Rwfi3YQMRbDMau",
    name: "Thinkin Bout You",
    artists: "Frank Ocean",
    duration_ms: 200746,
    popularity: 84,
    preview_url: "https://p.scdn.co/mp3-preview/b551ddae26560865215d1083bc08364f8f6edc0b?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027aede4855f6d0d738012e2e5",
    danceability: 0.721,
    energy: 0.339,
    valence: 0.2,
    added_at: "2018-04-01T21:19:41Z",
    link: "https://open.spotify.com/track/7DfFc7a6Rwfi3YQMRbDMau",
    userID: "royclaud",
    genres: "lgbtq+ hip hop,neo soul",
  },
  {
    id: "7EqbX6KDCvWms0m8Rbmt2q",
    name: "Fly Away",
    artists: "G-Eazy, Ugochi",
    duration_ms: 211613,
    popularity: 35,
    preview_url: "https://p.scdn.co/mp3-preview/18a7b47da324e4d93a518193d7941d8432b27a19?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02046527a9c176f7c2916f3530",
    danceability: 0.517,
    energy: 0.552,
    valence: 0.127,
    added_at: "2018-04-01T21:19:13Z",
    link: "https://open.spotify.com/track/7EqbX6KDCvWms0m8Rbmt2q",
    userID: "royclaud",
    genres: "indie pop rap,oakland hip hop,pop rap,rap",
  },
  {
    id: "6u0dQik0aif7FQlrhycG1L",
    name: "The Morning",
    artists: "The Weeknd",
    duration_ms: 312426,
    popularity: 63,
    preview_url: "https://p.scdn.co/mp3-preview/cff342fcdf2e2f903ef203e5ee8ef868386cb7bc?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b298538481c52f7e217ed000",
    danceability: 0.656,
    energy: 0.549,
    valence: 0.185,
    added_at: "2018-04-01T21:18:46Z",
    link: "https://open.spotify.com/track/6u0dQik0aif7FQlrhycG1L",
    userID: "royclaud",
    genres: "canadian contemporary r&b,canadian pop,pop",
  },
  {
    id: "248OFOZef6ShXv6DGgbnxU",
    name: "Saved",
    artists: "Khalid",
    duration_ms: 206533,
    popularity: 62,
    preview_url: "https://p.scdn.co/mp3-preview/2d8a01f1bafb079b7a3795b160acb8ca66b64632?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02988ede5e1276e758b5f9e577",
    danceability: 0.739,
    energy: 0.448,
    valence: 0.553,
    added_at: "2018-04-01T21:18:42Z",
    link: "https://open.spotify.com/track/248OFOZef6ShXv6DGgbnxU",
    userID: "royclaud",
    genres: "pop,pop r&b",
  },
  {
    id: "3GVkPk8mqxz0itaAriG1L7",
    name: "Everybody Dies In Their Nightmares",
    artists: "XXXTENTACION",
    duration_ms: 95466,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/57ea372058336bcc4774b5dcee03d6712b16ae5c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02203c89bd4391468eea4cc3f5",
    danceability: 0.734,
    energy: 0.57,
    valence: 0.689,
    added_at: "2018-04-01T21:16:52Z",
    link: "https://open.spotify.com/track/3GVkPk8mqxz0itaAriG1L7",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "5TXDeTFVRVY7Cvt0Dw4vWW",
    name: "Revenge",
    artists: "XXXTENTACION",
    duration_ms: 120026,
    popularity: 85,
    preview_url: "https://p.scdn.co/mp3-preview/8f5fe8bb510463b2da20325c8600200bf2984d83?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02203c89bd4391468eea4cc3f5",
    danceability: 0.746,
    energy: 0.251,
    valence: 0.18,
    added_at: "2018-04-01T21:16:38Z",
    link: "https://open.spotify.com/track/5TXDeTFVRVY7Cvt0Dw4vWW",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "1B3TX5FbmcVROMv2riOwyE",
    name: "Looking for a Star",
    artists: "XXXTENTACION",
    duration_ms: 137173,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/b879ef8ca73eba454b49eb2d06f373b32963a6f4?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025485ea186d6971e18fabcc2f",
    danceability: 0.915,
    energy: 0.692,
    valence: 0.713,
    added_at: "2018-04-01T21:16:35Z",
    link: "https://open.spotify.com/track/1B3TX5FbmcVROMv2riOwyE",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "7floNISpH8VF4z4459Qo18",
    name: "Look At Me!",
    artists: "XXXTENTACION",
    duration_ms: 126345,
    popularity: 79,
    preview_url: "https://p.scdn.co/mp3-preview/7add0b609734fafbb2a8284bd5821203d41b46c2?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0271af28545729f60b3eca66f7",
    danceability: 0.763,
    energy: 0.726,
    valence: 0.349,
    added_at: "2018-04-01T21:15:51Z",
    link: "https://open.spotify.com/track/7floNISpH8VF4z4459Qo18",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
  {
    id: "57nQyFocXZrKvWg5ZPYSoG",
    name: "Malfunction",
    artists: "Lil Uzi Vert",
    duration_ms: 199211,
    popularity: 44,
    preview_url: "https://p.scdn.co/mp3-preview/e542c3065c8444b004bf9667efbd89b2d975ba44?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0241b41e6f7924e530a9b00f9c",
    danceability: 0.427,
    energy: 0.698,
    valence: 0.447,
    added_at: "2018-04-01T21:14:03Z",
    link: "https://open.spotify.com/track/57nQyFocXZrKvWg5ZPYSoG",
    userID: "royclaud",
    genres: "hip hop,melodic rap,philly rap,rage rap,rap,trap",
  },
  {
    id: "3VCYe6mCXebBgcfzaxCVNI",
    name: "Both (feat. Drake & Lil Wayne) - Remix",
    artists: "Gucci Mane, Drake, Lil Wayne",
    duration_ms: 252016,
    popularity: 46,
    preview_url: "https://p.scdn.co/mp3-preview/60efdd209d8302e5ef695dd6465d607ff52137b0?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e025dedc151ce7642bf802d1759",
    danceability: 0.834,
    energy: 0.493,
    valence: 0.28,
    added_at: "2018-04-01T21:12:03Z",
    link: "https://open.spotify.com/track/3VCYe6mCXebBgcfzaxCVNI",
    userID: "royclaud",
    genres: "atl hip hop,dirty south rap,hip hop,pop rap,rap,southern hip hop,trap,canadian hip hop,canadian pop,hip hop,pop rap,rap,hip hop,new orleans rap,pop rap,rap,trap",
  },
  {
    id: "7tGlzXJv6GD5e5qlu5YmDg",
    name: "Love Me",
    artists: "Lil Wayne, Drake, Future",
    duration_ms: 253440,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/7c107464757b8dd30f95a7ef5e3284ebede674b7?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e1a81870181de556d515d4cc",
    danceability: 0.674,
    energy: 0.608,
    valence: 0.464,
    added_at: "2018-04-01T21:11:49Z",
    link: "https://open.spotify.com/track/7tGlzXJv6GD5e5qlu5YmDg",
    userID: "royclaud",
    genres: "hip hop,new orleans rap,pop rap,rap,trap,canadian hip hop,canadian pop,hip hop,pop rap,rap,atl hip hop,hip hop,rap,southern hip hop,trap",
  },
  {
    id: "10VBBaul4zVD0reteuIHM2",
    name: "From Time",
    artists: "Drake, Jhené Aiko",
    duration_ms: 322160,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/4731f1bfdb1de04500a3b7ce74a05ef11f1d5324?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0267ffd8d6bbf399430ba9c044",
    danceability: 0.738,
    energy: 0.491,
    valence: 0.448,
    added_at: "2018-04-01T21:11:38Z",
    link: "https://open.spotify.com/track/10VBBaul4zVD0reteuIHM2",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap,r&b",
  },
  {
    id: "14Rcq31SafFBHNEwXrtR2B",
    name: "Hold On, We're Going Home",
    artists: "Drake, Majid Jordan",
    duration_ms: 227880,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/bb6ae7bd099ba9fe020f11bac6f381258974ec78?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0267ffd8d6bbf399430ba9c044",
    danceability: 0.776,
    energy: 0.413,
    valence: 0.287,
    added_at: "2018-04-01T21:11:31Z",
    link: "https://open.spotify.com/track/14Rcq31SafFBHNEwXrtR2B",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap,canadian contemporary r&b,canadian hip hop",
  },
  {
    id: "343YBumqHu19cGoGARUTsd",
    name: "Fake Love",
    artists: "Drake",
    duration_ms: 210937,
    popularity: 68,
    preview_url: "https://p.scdn.co/mp3-preview/bd060262b4e3ddbf99b5cb4cc998190c572c2927?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e024f0fd9dad63977146e685700",
    danceability: 0.928,
    energy: 0.481,
    valence: 0.613,
    added_at: "2018-04-01T21:10:45Z",
    link: "https://open.spotify.com/track/343YBumqHu19cGoGARUTsd",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "7kfTqGMzIHFWeBeOJALzRf",
    name: "Headlines",
    artists: "Drake",
    duration_ms: 235986,
    popularity: 34,
    preview_url: "https://p.scdn.co/mp3-preview/570ddea0adb2c0bff50a1f0447a0e643c084fbe6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02c12f4b7567671621abce2aaa",
    danceability: 0.636,
    energy: 0.566,
    valence: 0.425,
    added_at: "2018-04-01T21:10:30Z",
    link: "https://open.spotify.com/track/7kfTqGMzIHFWeBeOJALzRf",
    userID: "royclaud",
    genres: "canadian hip hop,canadian pop,hip hop,pop rap,rap",
  },
  {
    id: "7t2bFihaDvhIrd2gn2CWJO",
    name: "Or Nah (feat. The Weeknd, Wiz Khalifa & DJ Mustard) - Remix",
    artists: "Ty Dolla $ign, The Weeknd, Wiz Khalifa, Mustard",
    duration_ms: 242983,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/95329030db6af65082fd00f5ac7b122f324c5ad5?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e021c04efd2804b16cf689de7f0",
    danceability: 0.805,
    energy: 0.33,
    valence: 0.211,
    added_at: "2018-04-01T21:09:51Z",
    link: "https://open.spotify.com/track/7t2bFihaDvhIrd2gn2CWJO",
    userID: "royclaud",
    genres: "hip hop,pop rap,r&b,southern hip hop,trap,trap soul,canadian contemporary r&b,canadian pop,pop,hip hop,pittsburgh rap,pop rap,rap,southern hip hop,trap,cali rap,pop rap,rap,southern hip hop,trap",
  },
  {
    id: "5e9aFSdnkQUrm5y8Dhw0qN",
    name: "Might Not",
    artists: "Belly, The Weeknd",
    duration_ms: 224213,
    popularity: 59,
    preview_url: "https://p.scdn.co/mp3-preview/8e9b83c0ff541fe1f288295707b03ad1cf91d02f?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0208764105cf360a25dd40b53c",
    danceability: 0.792,
    energy: 0.563,
    valence: 0.321,
    added_at: "2018-04-01T21:09:07Z",
    link: "https://open.spotify.com/track/5e9aFSdnkQUrm5y8Dhw0qN",
    userID: "royclaud",
    genres: "canadian hip hop,canadian trap,canadian contemporary r&b,canadian pop,pop",
  },
  {
    id: "6VwBbL8CzPiC4QV66ay7oR",
    name: "Wicked Games",
    artists: "The Weeknd",
    duration_ms: 323746,
    popularity: 66,
    preview_url: "https://p.scdn.co/mp3-preview/f961b657ed976c3c566204d228a336b95d975423?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02b298538481c52f7e217ed000",
    danceability: 0.658,
    energy: 0.594,
    valence: 0.317,
    added_at: "2018-04-01T21:09:01Z",
    link: "https://open.spotify.com/track/6VwBbL8CzPiC4QV66ay7oR",
    userID: "royclaud",
    genres: "canadian contemporary r&b,canadian pop,pop",
  },
  {
    id: "22VdIZQfgXJea34mQxlt81",
    name: "Can't Feel My Face",
    artists: "The Weeknd",
    duration_ms: 213520,
    popularity: 81,
    preview_url: "https://p.scdn.co/mp3-preview/99b0d35b74903a1807c2f0a7b4e54f844c93fb68?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e027fcead687e99583072cc217b",
    danceability: 0.705,
    energy: 0.769,
    valence: 0.583,
    added_at: "2018-04-01T21:08:46Z",
    link: "https://open.spotify.com/track/22VdIZQfgXJea34mQxlt81",
    userID: "royclaud",
    genres: "canadian contemporary r&b,canadian pop,pop",
  },
  {
    id: "4ofvoCdXq99vpMZO4Cfp8x",
    name: "Pray For Me (with Kendrick Lamar)",
    artists: "The Weeknd, Kendrick Lamar",
    duration_ms: 211440,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/a928f2c9acb8d65643b17f38439abb626b738205?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e028ac2a58fbcab6a775e790b15",
    danceability: 0.728,
    energy: 0.668,
    valence: 0.162,
    added_at: "2018-04-01T21:08:42Z",
    link: "https://open.spotify.com/track/4ofvoCdXq99vpMZO4Cfp8x",
    userID: "royclaud",
    genres: "canadian contemporary r&b,canadian pop,pop,conscious hip hop,hip hop,rap,west coast rap",
  },
  {
    id: "3ssX20QT5c3nA9wk78V1LQ",
    name: "Ambitionz Az A Ridah",
    artists: "2Pac",
    duration_ms: 278386,
    popularity: 1,
    preview_url: "https://p.scdn.co/mp3-preview/5231787e74887a32ef97231fedebca7d26a251b2?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02a786ad0992cfe0b2719729bf",
    danceability: 0.801,
    energy: 0.908,
    valence: 0.805,
    added_at: "2018-04-01T21:06:36Z",
    link: "https://open.spotify.com/track/3ssX20QT5c3nA9wk78V1LQ",
    userID: "royclaud",
    genres: "g funk,gangster rap,hip hop,rap,west coast rap",
  },
  {
    id: "3AGawd3ZkLbYQlxe4nZIEK",
    name: "Ease My Mind (feat. Niki and the Dove)",
    artists: "Skrillex, Niki & The Dove",
    duration_ms: 302520,
    popularity: 50,
    preview_url: "https://p.scdn.co/mp3-preview/67103f01ce8b5e6ec8de51023152ed45da86646d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02f034b00d0a05cd6504b49beb",
    danceability: 0.404,
    energy: 0.753,
    valence: 0.207,
    added_at: "2018-04-01T21:05:58Z",
    link: "https://open.spotify.com/track/3AGawd3ZkLbYQlxe4nZIEK",
    userID: "royclaud",
    genres: "brostep,complextro,edm,electro,pop dance,indietronica,metropopolis,swedish electropop,swedish indie pop,swedish synthpop",
  },
  {
    id: "7lgvU7WnwcNZ8dbGUz8yhP",
    name: "Bitch Please II",
    artists: "Eminem, Dr. Dre, Snoop Dogg, Xzibit, Nate Dogg",
    duration_ms: 288200,
    popularity: 71,
    preview_url: "https://p.scdn.co/mp3-preview/4c8048e0aae78eaef74ce9604aef4ebbe73267e6?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02dbb3dd82da45b7d7f31b1b42",
    danceability: 0.918,
    energy: 0.586,
    valence: 0.779,
    added_at: "2018-04-01T21:03:05Z",
    link: "https://open.spotify.com/track/7lgvU7WnwcNZ8dbGUz8yhP",
    userID: "royclaud",
    genres:
      "detroit hip hop,hip hop,rap,g funk,gangster rap,hip hop,rap,west coast rap,g funk,gangster rap,hip hop,pop rap,rap,west coast rap,detroit hip hop,g funk,gangster rap,hardcore hip hop,hip hop,west coast rap,g funk,gangster rap,hardcore hip hop,hip hop,west coast rap",
  },
  {
    id: "3e21cX0CVwzkQXiHz7WUQZ",
    name: "Drop The World",
    artists: "Lil Wayne, Eminem",
    duration_ms: 229226,
    popularity: 61,
    preview_url: "https://p.scdn.co/mp3-preview/0f50b2c8b58e2e2bbc8eed152fc3d30ce8589b9c?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02801cf8805673d07eb26da351",
    danceability: 0.663,
    energy: 0.91,
    valence: 0.343,
    added_at: "2018-04-01T21:03:01Z",
    link: "https://open.spotify.com/track/3e21cX0CVwzkQXiHz7WUQZ",
    userID: "royclaud",
    genres: "hip hop,new orleans rap,pop rap,rap,trap,detroit hip hop,hip hop,rap",
  },
  {
    id: "3ebXMykcMXOcLeJ9xZ17XH",
    name: "Scared to Be Lonely",
    artists: "Martin Garrix, Dua Lipa",
    duration_ms: 220883,
    popularity: 78,
    preview_url: "https://p.scdn.co/mp3-preview/49e6a27ef1e85764d5758ae6238f003601a53d7d?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e0264f8a309aa3c0a66a31fc374",
    danceability: 0.584,
    energy: 0.54,
    valence: 0.195,
    added_at: "2018-03-30T23:17:34Z",
    link: "https://open.spotify.com/track/3ebXMykcMXOcLeJ9xZ17XH",
    userID: "royclaud",
    genres: "dutch edm,edm,pop,pop dance,progressive house,dance pop,pop,uk pop",
  },
  {
    id: "6NWl2m8asvH83xjuXVNsuG",
    name: "Betrayed",
    artists: "Lil Xan",
    duration_ms: 187453,
    popularity: 49,
    preview_url: "https://p.scdn.co/mp3-preview/1e6ec8c441cedb1e01905c2974a131b440c841a3?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02fd53c5b8a4787f283833db4d",
    danceability: 0.823,
    energy: 0.419,
    valence: 0.277,
    added_at: "2018-03-29T01:15:04Z",
    link: "https://open.spotify.com/track/6NWl2m8asvH83xjuXVNsuG",
    userID: "royclaud",
    genres: "cloud rap",
  },
  {
    id: "28luqgS4NCuFKP6YSOtia5",
    name: "Foreplay",
    artists: "Jalen Santoy",
    duration_ms: 173784,
    popularity: 55,
    preview_url: "https://p.scdn.co/mp3-preview/7fb622325d82571fd882b5a859408227c8e0b7c9?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02e45db56c3d64cff1da2effaa",
    danceability: 0.73,
    energy: 0.422,
    valence: 0.505,
    added_at: "2018-03-14T19:00:02Z",
    link: "https://open.spotify.com/track/28luqgS4NCuFKP6YSOtia5",
    userID: "royclaud",
    genres: "north carolina hip hop",
  },
  {
    id: "35ieKju5aLWFN5UgfJ27mL",
    name: "SAD!",
    artists: "XXXTENTACION",
    duration_ms: 166568,
    popularity: 0,
    preview_url: "https://p.scdn.co/mp3-preview/056a1074f4687ab09a2d548baf72ca3e2fa42d79?cid=0a9995dd76d44757a722335e07ca7844",
    image: "https://i.scdn.co/image/ab67616d00001e02258871cc2f0e5d66e28b30c6",
    danceability: 0.74,
    energy: 0.613,
    valence: 0.473,
    added_at: "2018-03-14T05:59:58Z",
    link: "https://open.spotify.com/track/35ieKju5aLWFN5UgfJ27mL",
    userID: "royclaud",
    genres: "emo rap,miami hip hop,rap",
  },
];
export function GroupGenres(genres) {
  // let genres = [
  //   ...new Set(
  //     tracks
  //       .flatMap(
  //         (artist) =>
  //           artist.genres
  //             ? artist.genres
  //                 .replace(/g /g, "g-")
  //                 .replace(/hip hop/g, "hip-hop")
  //                 .replace(/p /g, "p-")
  //                 .replace(/la /g, "la-")
  //                 .replace(/la /g, "la-")
  //                 .replace(/el /g, "el-")
  //                 .replace(/pov: /g, "pov:")
  //                 .replace(/san /g, "san-")
  //                 .replace(/boy /g, "boy-")
  //                 .replace(/art /g, "art-")
  //                 .replace(/oc /g, "oc-")
  //                 .split(",")
  //                 .map((g) => g.trim().replace(/^\s*$/, "other")) // Replace empty string with "Other"
  //             : ["other"] // If no genres, use "Other"
  //       )
  //       .map((g) => g.trim())
  //       .filter((g) => g !== "" && g.length > 3)
  //       .sort((a, b) => a.localeCompare(b))
  //   ),
  // ].map((genre) => genre);

  let genreGroups = {};

  genres.forEach((genre) => {
    // Default to the first word if no two-word combo match and not exactly two words
    let genreWords = genre.split(" ");
    let groupKey = genreWords[0];

    if (!groupKey) {
      groupKey = genreWords[0];
    }

    // Add to the genre group if the groupKey length is valid
    if (!genreGroups[groupKey]) {
      genreGroups[groupKey] = [];
    }
    genreGroups[groupKey].push(genre);
  });
  return Object.keys(genreGroups).sort((a, b) => a.localeCompare(b));
}
