import React from "react";
import "../assets/styles/Blog.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import MDViewer from "../components/MDViewer";

const Articles = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Articles</title>
        <meta property="og:title" content="Articles" />
        <meta property="og:url" content="https://royclaudio.pages.dev/" />
        <meta
          property="og:description"
          content="Roy Claudio's blog on culture, science, and anything interesting."
        />
        <meta property="og:site_name" content="RC Content" />
        <meta property="og:image:alt" content="" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:image" content="assets/RC1.png" />
        <meta property="og:image:width" content="1200px" />
        <meta property="og:image:height" content="628px" />
      </Helmet>
      <MDViewer content="TR" />
    </HelmetProvider>
  );
};
export default Articles;
