import React, { useEffect, useState, useCallback } from "react";
import "../assets/styles/Blog.css";
import "../assets/styles/NFT.css";
import "../sassy.scss";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Bar, Bar as Bar1 } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { GroupGenres as SetGroupGenres, Spotifylogin } from "../components/Spotify";
import CytoscapeComponent from "react-cytoscapejs";
//import Cytoscape from "cytoscape";
import { data, options, date_data, date_options, trackdata, setTrackGraph, setmaingraph } from "../components/Chartdata";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from "chart.js";
//TODO: refreshkey check, song analyzer 
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);
const ServicesSkills = () => {
  const [User, setUser] = useState();
  const [tracks, setTracks] = useState([]);
  const [Genres, setGenres] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [groupGenres, setGroupGenres] = useState([]);
  const [GraphNodes, setNodes] = useState([]);
  const [mPlaylist, setplaylist] = useState();
  const [loading, setLoading] = useState(true);
  const audioRefs = React.useRef({});
  const [selectedTrackId, setSelectedTrackId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(null);
  const cyRef = React.useRef(null);
  const tracksPerPage = 30;
  const maxPagesToShow = 3;
  const location = useLocation();
  const totalPages = Math.ceil(tracks.length / tracksPerPage);
  //Check url location for code
  useEffect(() => {
    const code = new URLSearchParams(location.search).get("code");
    if (code) {
      const GetKey = async () => {
        try {
          const response = await axios.post(`/GetKey/${code}`);
          let key = response.data;
          key.expires_in = Date.now() + 3600 * 1000;
          localStorage.setItem("userKey", JSON.stringify(key));
          cleanUrl();
        } catch (error) {
          console.error("Error fetching key:", error);
        }
      };
      GetKey();
    }
  }, [location]);
  // check for userkey to verify
  useEffect(() => {
    const userKey = JSON.parse(localStorage.getItem("userKey"));
    const GetUser = async () => {
      try {
        const Response = await fetchWebApi("me", "GET", null, userKey.access_token);
        const user = Response;
        user.userKey = userKey.access_token;
        setUser(user);
      } catch (error) {
        console.error("Error fetching music data:", error);
      }
    };
    if (userKey) {
      const GetRefreshKey = async (userKey) => {
        try {
          const response = await axios.post(`/GetRefreshKey/${userKey}`);
          const data = await response.data;
          let key = data;
          key.expires_in = Date.now() + 3600 * 1000;
          localStorage.setItem("userKey", JSON.stringify(key));
          GetUser(userKey.access_token);
        } catch (error) {
          console.error("Error fetching key:", error);
        }
      };

      if (Date.now() <= Number(userKey.expires_in)) {
        GetUser(userKey.access_token);
      } else {
        GetRefreshKey(userKey.access_token);
      }
    }
  }, []);
  // if no songs exist on local storage get songs
  useEffect(() => {
    const GetSongsJson = async () => {
      try {
        const response = await axios.get(`assets/songs.json`);
        let data = response.data || [];
        let genre = processArtistsGenres(data);
        let GroupGenre = SetGroupGenres(genre);
        data = data.map((a) => ({
          ...a,
          genres: a.genres.replace(/\b(\w{1,3})\s/g, "$1-"),
        }));
        localStorage.setItem("tracks", JSON.stringify(data));
        localStorage.setItem("GroupGenre", JSON.stringify(GroupGenre));
      } catch (error) {
        console.error("Error fetching music data:", error);
      }
    };

    if (!JSON.parse(localStorage.getItem("tracks"))) {
      localStorage.clear();
      GetSongsJson();
    }
  }, []);
  //get user profile
  useEffect(() => {
    const userKey = JSON.parse(localStorage.getItem("userKey"));
    const GetUser = async (accessToken) => {
      try {
        const Response = await fetchWebApi("me", "GET", null, accessToken);
        const user = Response;
        user.userKey = accessToken;
        setUser(user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    if (userKey) {
      GetUser(userKey.access_token);
    }
  }, []);
  //set items
  useEffect(() => {
    const tracks = JSON.parse(localStorage.getItem("tracks"));
    const GroupGenre = JSON.parse(localStorage.getItem("GroupGenre"));
    const playlist = JSON.parse(localStorage.getItem("playlist"));
    localStorage.removeItem("sorting");
    setGroupGenres(GroupGenre || []);
    updateTracks(tracks || []);
    setplaylist(playlist || "");
  }, []);
  async function fetchWebApi(endpoint, method, body, access_token) {
    const response = await fetch(`https://api.spotify.com/v1/${endpoint}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      method,
      body: body ? JSON.stringify(body) : null,
    });

    if (!response.ok) {
      const errorResponse = await response.json().catch(() => ({})); // Prevent crash on parsing errors
      return {
        error: "API_ERROR",
        message: `Spotify API responded with status ${response.status}`,
        details: errorResponse,
        status: response.status, // Include the status code
      };
    }
    const data = await response.json();
    return data;
  }
  function processArtistsGenres(tracks) {
    return [
      ...new Set(
        tracks
          .flatMap((artist) =>
            artist.genres
              .replace(/\b(\w{1,3})\s/g, "$1-")
              .split(",")
              .map((g) => g.trim().replace(/^\s*$/, "Other"))
          )
          .map((g) => g.replace(",", "").trim())
          .filter((g) => g !== "" && g.length > 3)
          .sort((a, b) => a.localeCompare(b))
      ),
    ];
  }
  const getTracks = useCallback(() => {
    const Asyncfun = async () => {
      try {
        const userKey = JSON.parse(localStorage.getItem("userKey"));
        if (userKey && Date.now() <= Number(userKey.expires_in)) {
          setLoading(true);
          const userTracksResponse = await fetchWebApi("me/tracks?limit=1&offset=0", "GET", null, userKey.access_token);
          const userTotal = userTracksResponse.total;
          if (userTotal === undefined) {
            throw new Error(`Failed to retrieve user total tracks: ${userTotal}`);
          }
          // Fetch user ID
          const userResponse = await fetchWebApi("me", "GET", null, userKey.access_token);
          const userId = userResponse.id;
          if (!userId) {
            throw new Error(`Failed to retrieve user ID: ${userId}`);
          }
          let totalsongs = [];
          let offset = 0;
          let limit = 50;
          while (offset < userTotal) {
            const songsResponse = await fetchWebApi(`me/tracks?limit=${limit}&offset=${offset}`, "GET", null, userKey.access_token);
            const songs = songsResponse.items;
            if (!songs) {
              throw new Error(`Failed to retrieve songs: ${JSON.stringify(songsResponse)}`);
            }
            const topTracksIds = songs.map((track) => track.track.id).join(",");
            const featuresResponse = await fetchWebApi(`audio-features?ids=${topTracksIds}`, "GET", null, userKey.access_token);
            const songFeatures = featuresResponse.audio_features;
            if (!songFeatures) {
              throw new Error(`Failed to retrieve audio features: ${JSON.stringify(featuresResponse)}`);
            }
            for (const track of songs) {
              const matchingFeat = songFeatures.find((audioFeature) => audioFeature.id === track.track.id);
              if (matchingFeat) {
                totalsongs.push({
                  id: track.track.id,
                  name: track.track.name || "unknown_name",
                  artists: track.track.artists.map((artist) => artist.name.replace(",", "")).join(",") || "unknown_artists",
                  duration_ms: track.track.duration_ms || 0,
                  popularity: track.track.popularity || 0,
                  preview_url: track.track.preview_url || "null",
                  image: track.track.album.images?.[1]?.url || "unknown_image",
                  danceability: matchingFeat.danceability || 0,
                  energy: matchingFeat.energy || 0,
                  valence: matchingFeat.valence || 0,
                  added_at: track.added_at || "1776-07-04",
                  link: track.track.external_urls?.spotify || "unknown_link",
                  userId: userId,
                  genres: "No Genres",
                  artistsIDs: track.track.artists.map((artist) => artist.id),
                });
              }
            }
            offset += limit;
          }
          const memoartist = [];
          offset = 0;
          var uniqueArtist = [...new Set(totalsongs.flatMap((track) => track.artistsIDs))];
          while (offset < uniqueArtist.length) {
            const calls = uniqueArtist.slice(offset, offset + 50).join(",");
            const artistResponse = await fetchWebApi(`artists?ids=${calls}`, "GET", null, userKey.access_token);
            if (!artistResponse) {
              throw new Error(`Failed to retrieve artist details: ${calls}`);
            }
            memoartist.push(...artistResponse.artists);
            offset += 50;
          }
          var final = totalsongs.map((a) => ({
            ...a,
            genres: memoartist
              .filter((b) => a.artistsIDs.includes(b.id))
              .map((b) => b.genres)
              .join(","),
          }));

          let genre = processArtistsGenres(final);
          let GroupGenre = SetGroupGenres(genre);
          final = final.map((a) => ({
            ...a,
            genres: a.genres.replace(/\b(\w{1,3})\s/g, "$1-"),
          }));
          localStorage.setItem("tracks", JSON.stringify(final));
          setGroupGenres(GroupGenre);
          updateTracks(final);
        }
      } catch (error) {
        console.error("Error retrieving songs:", error);
        setLoading(false);
      }
    };
    Asyncfun();
  }, []);
  const saveSongs = useCallback(
    (saveTracks, genre) => {
      let tracksToSave = saveTracks.map((track) => "spotify:track:" + track.id);
      let season = ((date) => `${["Winter", "Spring", "Summer", "Fall"][(((new Date(date).getMonth() + 1) % 12) / 3) | 0]} ${new Date().getFullYear()}`)(new Date().toISOString());
      var name = `${genre} ${season}`;
      var answer = window.confirm(`Are you sure you want to save: ${name}?`);
      var playlistDescription = `Playlist created on royclaudio.com for ${season}`;
      const Asyncfun = async () => {
        try {
          setLoading(true);
          const res1 = await fetchWebApi(`users/${User.id}/playlists`, "POST", { name: name, description: playlistDescription }, User.userKey);
          let offset = 0;
          let limit = 50;
          while (offset < tracksToSave.length) {
            let uriArray = tracksToSave.slice(offset, offset + limit);
            await fetchWebApi(`playlists/${res1.id}/tracks`, "POST", { uris: uriArray }, User.userKey);
            offset += limit;
          }
          const res3 = await fetchWebApi(`playlists/${res1.id}`, "GET", null, User.userKey);
          setplaylist(res3);
          localStorage.setItem("playlist", JSON.stringify(res3));
        } catch (error) {
          console.error("Error saving songs:", error);
          setLoading(false);
        }
      };
      if (answer) {
        Asyncfun();
      }
      setLoading(false);
    },
    [User]
  );
  const getTop = useCallback((type, length) => {
    const Asyncfun = async (tp, lng) => {
      try {
        const userKey = JSON.parse(localStorage.getItem("userKey"));
        if (userKey && Date.now() <= Number(userKey.expires_in)) {
          setLoading(true);
          const userTracksResponse = await fetchWebApi(`me/top/${tp}?time_range=${lng}&limit=1&offset=0`, "GET", null, userKey.access_token);
          let userTotal = userTracksResponse.total;
          const userResponse = await fetchWebApi("me", "GET", null, userKey.access_token);
          const userId = userResponse.id;
          let offset = 0;
          let totalsongs = [];
          let limit = 50;
          let topTracksIds;
          let featuresResponse;
          let songFeatures;
          let answer;
          if (userTotal > 1500) {
            answer = window.confirm(`The total number of songs exceeds 1,500 at ${userTotal}, the amount will be reduced to 1,500.`);
            userTotal = 1500;
          } else {
            answer = true;
          }
          while (offset < userTotal && answer) {
            const songsResponse = await fetchWebApi(`me/top/${tp}?time_range=${lng}&limit=${limit}&offset=${offset}`, "GET", null, userKey.access_token);
            const songs = songsResponse.items;
            if (!songs) {
              throw new Error(`Failed to retrieve songs: ${JSON.stringify(songsResponse)}`);
            }
            if (type === "tracks") {
              topTracksIds = songs.map((track) => track.id).join(",");
              featuresResponse = await fetchWebApi(`audio-features?ids=${topTracksIds}`, "GET", null, userKey.access_token);
              songFeatures = featuresResponse.audio_features;
            }
            for (const track of songs) {
              const matchingFeat = songFeatures?.find((audioFeature) => audioFeature.id === track.id);
              if (matchingFeat) {
                totalsongs.push({
                  id: track.id,
                  name: track.name || "unknown_name",
                  artists: track.artists.map((artist) => artist.name.replace(",", "")).join(",") || "Single Artist",
                  duration_ms: track.duration_ms || 0,
                  popularity: track.popularity || 0,
                  preview_url: track.preview_url || "null",
                  image: track.album.images?.[1]?.url || "unknown_image",
                  danceability: matchingFeat.danceability || 0,
                  energy: matchingFeat.energy || 0,
                  valence: matchingFeat.valence || 0,
                  added_at: track.album.release_date || "1776-07-04",
                  link: track.external_urls?.spotify || "unknown_link",
                  userId: userId,
                  genres: "music,musician,artist",
                  artistsIDs: track.artists?.map((artist) => artist.id) || "na",
                });
              } else if (type === "artists") {
                totalsongs.push({
                  id: track.id,
                  name: track.name || "unknown_name",
                  artists: "",
                  duration_ms: 0,
                  popularity: track.popularity || 0,
                  preview_url: track.preview_url || "null",
                  image: track.images?.[1]?.url || "unknown_image",
                  danceability: 0,
                  energy: 0,
                  valence: 0,
                  added_at: track.added_at || "From Birth",
                  link: track.external_urls?.spotify || "unknown_link",
                  userId: userId,
                  genres: "music,musician,artist",
                  artistsIDs: "na",
                });
              }
            }
            offset += limit;
          }
          const memoartist = [];
          offset = 0;
          let final;
          var uniqueArtist = [...new Set(totalsongs.flatMap((track) => track.artistsIDs))];
          if (type === "tracks") {
            while (offset < uniqueArtist.length) {
              const calls = uniqueArtist.slice(offset, offset + 50).join(",");
              const artistResponse = await fetchWebApi(`artists?ids=${calls}`, "GET", null, userKey.access_token);
              if (!artistResponse) {
                throw new Error(`Failed to retrieve artist details: ${calls}`);
              }
              memoartist.push(...artistResponse.artists);
              offset += 50;
            }
            final = totalsongs.map((a) => ({
              ...a,
              genres: memoartist
                .filter((b) => a.artistsIDs.includes(b.id))
                .map((b) => b.genres.map((a) => a.replace(/\b(\w{1,3})\s/g, "$1-")))
                .join(","),
            }));
            let genre = processArtistsGenres(final);
            let GroupGenre = SetGroupGenres(genre);
            localStorage.setItem("tracks", JSON.stringify(final));
            setGroupGenres(GroupGenre);
          } else {
            final = totalsongs;
          }
          updateTracks(final);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error retrieving songs:", error);
        setLoading(false);
      }
    };
    Asyncfun(type, length);
  }, []);
  const handleSeeAnalysis = useCallback(
    (trackId) => {
      const matchingTrack = tracks.find((a) => a.id === trackId);
      if (matchingTrack) {
        setTrackGraph(matchingTrack);
      }
      setSelectedTrackId(trackId);
    },
    [tracks]
  );
  const handleTrackEnd = () => {
    if (document.getElementById(currentTrackIndex)) {
      document.getElementById(currentTrackIndex)?.classList.remove("play1");
    }
    if (currentTrackIndex !== null && currentTrackIndex < tracks.length - 1) {
      playTrack(currentTrackIndex + 1);
      document.getElementById(currentTrackIndex + 1)?.classList.add("play1");
    } else {
      setCurrentTrackIndex(null);
    }
  };
  const playTrack = (index) => {
    if (document.getElementById(currentTrackIndex) && currentTrackIndex !== null && currentTrackIndex !== index) {
      audioRefs.current[currentTrackIndex]?.pause();
      audioRefs.current[currentTrackIndex].currentTime = 0;
      document.getElementById(currentTrackIndex)?.classList.remove("play1");
    }
    if (document.getElementById(index) && currentTrackIndex === index) {
      audioRefs.current[index]?.pause();
      document.getElementById(index)?.classList.remove("play1");
      setCurrentTrackIndex(null);
    } else {
      setCurrentTrackIndex(index);
      document.getElementById(index)?.classList.add("play1");
      audioRefs.current[index]?.play();
    }
  };
  const cleanUrl = () => {
    const urlObj = new URL(window.location.href);
    urlObj.search = "";
    window.location.href = urlObj.toString();
  };
  const getVisiblePages = () => {
    const pages = [];
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
    if (endPage - startPage + 1 < maxPagesToShow && totalPages > maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };
  const handleSongsSorted = (event, tracks1) => {
    const getSortValue = (track, key) => track[key] ?? 0; // Helper to safely get value or default to 0

    const sortFunctions = {
      Groovy: (a, b) => getSortValue(b, 'danceability') - getSortValue(a, 'danceability'),
      Popular: (a, b) => getSortValue(b, 'popularity') - getSortValue(a, 'popularity'),
      Energetic: (a, b) => getSortValue(b, 'energy') - getSortValue(a, 'energy'),
      Positive: (a, b) => getSortValue(b, 'valence') - getSortValue(a, 'valence'),
      Latest: (a, b) => new Date(b.added_at) - new Date(a.added_at), // Sort by date
    };

    const order = event.target.value;
    if (order && sortFunctions[order]) {
      const sortedTracks = [...tracks1].sort(sortFunctions[order]);
      updateTracks(sortedTracks);
      setSelectedTrackId(0);
    } else {
      setTracks([...tracks1]); // No sorting, just return original
    }
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const selectGenre = (genre) => {
    let allTracks = JSON.parse(localStorage.getItem("tracks")) || [];
    let orderTracks = [...allTracks];
    if (genre === "") {
      setGenres([]);
      setSelectedGenres([]);
      updateTracks(orderTracks);
      localStorage.removeItem("selected");
      return orderTracks;
    } else {
      localStorage.removeItem("selected");
      setSelectedGenres((prevSelected) => {
        let updatedGenres;
        if (prevSelected.includes(genre)) {
          updatedGenres = prevSelected.filter((g) => g !== genre);
        } else {
          updatedGenres = [...prevSelected, genre];
        }
        if (updatedGenres.length > 0) {
          orderTracks = orderTracks.filter((track) => {
            const trackGenres = track.genres.split(",").map((g) => g.trim().toLowerCase());
            const updatedGenresLower = updatedGenres.map((p) => p.toLowerCase());
            return trackGenres.some((g) => updatedGenresLower.some((updatedGenre) => g.includes(updatedGenre)));
          });
        }
        updateTracks(orderTracks);
        setGenres(updatedGenres);
        return updatedGenres;
      });
    }
  };
  const hideGenres = (genres) => {
    const genreIDs = genres.map((g) => g);
    genreIDs.forEach((elem) => {
      var element = document.getElementById(elem);
      if (element) {
        element.classList.toggle("toggle-hide");
      }
    });
  };
  const layout = {
    name: "grid",
    avoidOverlapPadding: 45,
  };
  const style = [
    {
      selector: "node",
      style: {
        "background-color": "#777",
        label: "data(label)",
      },
    },
    {
      selector: "edge",
      style: {
        width: 2,
        "line-color": "#ccc",
        label: "data(label)",
      },
    },
  ];
  const updateTracks = (tracks) => {
 
    const ArtistsUnique = new Set(
      tracks.flatMap((a) =>
        a.artists
          .replace("Tyler ,", "Tyler")
          .replace("Tyler, ", "Tyler ")
          .split(",")
          .flatMap((b) => b)
      )
    ); //JSON.parse(localStorage.getItem("Artists"));
    const edges = [];
    const ndes = [];
    let insertEdge = true;
    let insertNde = true;
    const connectionCounts = {};
    ArtistsUnique.forEach((artist) => {
      ndes.push({ data: { id: artist.trim(), label: artist.trim() } });
      tracks.forEach((song) => {
        if (song.artists.includes(artist)) {
          song.artists
            .replace("Tyler ,", "Tyler")
            .replace("Tyler, ", "Tyler ")
            .split(",")
            .forEach((a) => {
              if (a.trim() !== artist.trim()) {
                ndes.forEach((node) => {
                  if (node.data.id === a.trim()) {
                    insertNde = false;
                  }
                });
                if (insertNde) {
                  ndes.push({ data: { id: a.trim(), label: a.trim() } });
                }
                if (insertEdge) {
                  edges.push({
                    hidden: true,
                    data: { source: artist.trim(), target: a.trim(), label: `` },
                  });
                }
              }
            });
        }
      });
    });
    edges.forEach((edge) => {
      connectionCounts[edge.data.source] = (connectionCounts[edge.data.source] || 0) + 1;
      connectionCounts[edge.data.target] = (connectionCounts[edge.data.target] || 0) + 1;
    });
    ndes.forEach((node) => {
      const id = node.data.id;
      node.connectionCount = connectionCounts[id] || 0;
    });
    //const filteredEdges = edges.filter((edge) => connectionCounts[edge.data.source] > 0 && connectionCounts[edge.data.target] > 0);
    //const filteredNodes = ndes.filter((node) => node.connectionCount > 0);
    const andes = ndes.sort((a, b) => b.connectionCount - a.connectionCount);
    const elements = [...andes, ...edges];
    setTracks(tracks);
    setmaingraph(tracks);
    setLoading(false);
    setNodes(elements);
    console.log('update tracks');
  };
  const handleClick = useCallback((event) => {
    let allTracks = JSON.parse(localStorage.getItem("tracks")) || [];
    let orderTracks = [...allTracks];
    const nodeId = event.target.data("id").trim().toLowerCase();
    orderTracks = orderTracks.filter((track) => {
      const trackGroup = track.artists
        .replace(",", "")
        .split(",")
        .map((g) => g.trim().toLowerCase());
      return trackGroup.some((g) => g.includes(nodeId));
    });
    updateTracks(orderTracks);
  }, []);
  useEffect(() => {
    const cyInstance = cyRef.current;
    if (cyInstance) {
      cyInstance.json({ elements: GraphNodes });
      const connectedNodes = cyInstance.nodes().filter((node) => node.connectedEdges().length > 6);
      const disconnectedNodes = cyInstance.nodes().filter((node) => node.connectedEdges().length === 0 || node.connectedEdges().length <= 3);
      const bottomRightX = cyInstance.width() * 4;
      const bottomRightY = cyInstance.height() * 4;
      connectedNodes.layout({ name: "concentric",   sweep: 2, minNodeSpacing: 10 }).run();
      disconnectedNodes
        .layout({
          name: "grid",
          avoidOverlapPadding: 45,
          boundingBox: { x1: bottomRightX, y1: bottomRightY, w: 1000, h: 1000 }, // Restrict the layout to a 300x300 area at (100, 100)
        })
        .run();
     
      cyInstance.on("tap", "node", handleClick);
      return () => {
        cyInstance.off("tap", "node", handleClick);
      };
    } console.log("test");
  }, [GraphNodes, handleClick]);

  if (loading) {
    return (
      <div className="container-fluid d-flex flex-wrap justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <div className="container">
      <h1 className="text-center" style={{ borderBottom: "1px solid black" }}>
        Spotify
      </h1>
      <div className="row justify-content-center">
        <CytoscapeComponent
          cy={(cy) => {
            cyRef.current = cy;
          }}
          elements={GraphNodes}
          layout={layout}
          stylesheet={style}
          style={{ width: "90%", height: "600px", backgroundColor: "grey" }}
        />
      </div>
      <div className="d-flex flex-wrap justify-content-between align-items-center m-2" style={{ borderBottom: "1px solid black" }}>
        <h1>Hi {User ? User.display_name : "Stranger"}! </h1>
        <h1>{tracks.length} Songs</h1>
        <select key="451" aria-label="Order Selection" className="my-2" onChange={(event) => handleSongsSorted(event, tracks)}>
          <option value="Latest">Sort by Date Added: latest</option>
          <option value="Groovy">Sort by Danceability</option>
          <option value="Popular">Sort by Popularity</option>
          <option value="Energetic">Sort by Energy</option>
          <option value="Positive">Sort by Positiveness</option>
        </select>
      </div>
      <div className="d-flex justify-content-start align-items-leftbutton-container series-container">
        <button className="button-54" onClick={() => document.getElementById("offcanvasBottom").classList.toggle("show")}>
          toggle stats
        </button>
        <button id="rocket" className="button-54 col" onClick={() => hideGenres(groupGenres)}>
          <span>toggle</span> genres
        </button>{" "}
        <button id="" className="button-54" onClick={() => selectGenre("")}>
          Reset Songs
        </button>
        {!User && (
          <button className="button-54 float-end" onClick={Spotifylogin}>
            <img src="assets/pushpin.svg" alt="spotify logo" className="mx-1" />
            Login
          </button>
        )}
      </div>
      <div className="page-body">
        <ul className="track">
          {/* {[...new Set(tracks.flatMap(a=>a.artists.replace("Tyler ,", "Tyler").replace("Tyler, ", "Tyler ").split(",").flatMap(b=>b)))]} */}
          {mPlaylist !== "" && mPlaylist !== undefined && (
            <li className={"track-style d-flex flex-column justify-content-center align-content-center "}>
              <div className="d-flex flex-column justify-content-center align-content-center cursor-pointer ">
                <p className="t-name">{mPlaylist.name}</p>
                <p className="t-artist">{mPlaylist.artists}</p>
                <div className="t-img img-wrapper">
                  <img src={mPlaylist.images?.[0].url} alt={`${mPlaylist.name} playlist cover`} />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <button className={"genre t-stats "} onClick={() => window.open(mPlaylist.external_urls[0].spotify, "_blank")}>
                  <span>Visit Playlist</span>
                </button>
              </div>
            </li>
          )}
        </ul>
        <div className="Controls">
          {User && (
            <div className="d-flex justify-content-start align-items-leftbutton-container series-container  my-3 ">
              <button className="button-54" onClick={() => getTop("tracks", "long_term")}>
                <span>top </span>~1 year
              </button>
              <button className="button-54" onClick={() => getTop("tracks", "medium_term")}>
                <span>top </span>6 months
              </button>
              <button className="button-54" onClick={() => getTop("tracks", "short_term")}>
                <span>top </span>4 weeks
              </button>
              <button className="button-54" onClick={() => getTracks()}>
                <span>all </span> Songs
              </button>
              <button className="button-54" onClick={() => saveSongs(tracks, selectedGenres.length > 0 ? selectedGenres.join(" ") : "")}>
                <span>Save to</span> playlist
              </button>
            </div>
          )}
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <div className="button-container">
              {selectedGenres
                .filter((a) => (selectedGenres.length === 0 ? a : groupGenres.map((g) => g).some((g) => Genres.map((ug) => ug).includes(g))))
                .filter((a) => tracks.filter((track) => track.genres.includes(a)).length > 10)
                .map((genreCategory) => (
                  <button key={genreCategory} className={`button-active`} onClick={() => selectGenre(genreCategory)}>
                    {genreCategory} <span>{tracks.filter((track) => track.genres.includes(genreCategory)).length}</span>
                  </button>
                ))}
              {groupGenres
                .filter((a) => (selectedGenres.length === 0 ? a : groupGenres.map((g) => g).some((g) => Genres.map((ug) => ug).includes(g))))
                .filter((a) => tracks.filter((track) => track.genres.includes(a)).length > 10)
                .filter((a) => !selectedGenres.includes(a))
                .map((genreCategory) => (
                  <button id={genreCategory} key={genreCategory} className={`button-54 ${selectedGenres.includes(genreCategory) ? "active" : ""}`} onClick={() => selectGenre(genreCategory)}>
                    {genreCategory} <span>{tracks.filter((track) => track.genres.includes(genreCategory)).length}</span>
                  </button>
                ))}
            </div>
          </div>
        </div>
        <ul className="track">
          {tracks.slice((currentPage - 1) * tracksPerPage, (currentPage - 1) * tracksPerPage + tracksPerPage).map((track, index) => (
            <li id={index} className={"track-style d-flex flex-column justify-content-center align-content-center " + (selectedTrackId === track.id ? "active" : "")} key={index}>
              <div className="d-flex flex-column justify-content-center align-content-center cursor-pointer ">
                <p className="t-name">{track.name.substring(0, 35)}</p>
                <p className="t-artist">{track.artists.substring(0, 35)}</p>
                <div className="t-img img-wrapper">
                  <img loading="lazy" src={track.image} alt={`${track.name} track cover`} />
                </div>
              </div>
              <audio ref={(el) => (audioRefs.current[index] = el)} onEnded={handleTrackEnd} key={track.preview_url}>
                <source src={track.preview_url} type="audio/mpeg" />
              </audio>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <div role="button" onClick={() => playTrack(index)} className="genre t-ctrl ">
                  Play
                </div>
                <button className={"genre t-stats "} onClick={() => handleSeeAnalysis(selectedTrackId === track.id ? 0 : track.id)}>
                  <span>{selectedTrackId === track.id ? "Collapse" : "Expand"} Stats</span>
                </button>
              </div>
              {selectedTrackId === track.id && (
                <div className={"d-flex flex-column justify-content-center align-content-center mx-2"} style={{ marginTop: "50px", maxWidth: "350px" }}>
                  <div className="track-chart">
                    <Bar1 data={trackdata} options={options} />
                  </div>
                  <table style={{ maxWidth: "300px" }}>
                    <tbody>
                      <tr>
                        <th>Popularity</th>
                        <td align="right">{track.popularity}%</td>
                      </tr>
                      <tr>
                        <th>Danceability</th>
                        <td align="right">{(track.danceability * 100).toFixed(0)}%</td>
                      </tr>

                      <tr>
                        <th>Energy</th>
                        <td align="right">{(track.energy * 100).toFixed(0)}%</td>
                      </tr>
                      <tr>
                        <th>Positiveness</th>
                        <td align="right">{(track.valence * 100).toFixed(0)}%</td>
                      </tr>
                      <tr>
                        <th>Length</th>
                        <td align="right">
                          {Math.floor(track.duration_ms / 60000)}:{((track.duration_ms % 60000) / 1000).toFixed(0)}
                        </td>
                      </tr>
                      {/* <tr>
                        <th>Date Added</th>
                        <td align="right">{track.added_at.slice(0, 10)}</td>
                      </tr> */}
                      <tr>
                        <th>{track.genres.split(",").length} Genres</th>
                        <td align="right">{track.genres.split(",").slice(0, 2).join(", ")}</td>
                      </tr>
                      <tr>
                        <th>link</th>
                        <td align="right">
                          <a href={track.link}>Listen on spotify</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </li>
          ))}
        </ul>
        <div className="Pagination-Controls">
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
          {currentPage > Math.ceil(maxPagesToShow / 2) && <button onClick={() => handlePageChange(1)}>1</button>}
          {currentPage > Math.ceil(maxPagesToShow / 2) && <span>...</span>}
          {getVisiblePages().map((page) => (
            <button key={page} onClick={() => handlePageChange(page)} className={page === currentPage ? "play" : ""}>
              {page}
            </button>
          ))}
          {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && <span>...</span>}
          {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && <button onClick={() => handlePageChange(totalPages)}>{totalPages}</button>}
          <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
      <div className="offcanvas offcanvas-bottom" tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title text-center" id="offcanvasBottomLabel">
            Average Track Traits Summary Charts
          </h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => document.getElementById("offcanvasBottom").classList.toggle("show")}></button>
        </div>
        <div className="offcanvas-body ">
          <div key="totaltracks">
            Total Genres: {groupGenres.length} <br></br> {selectedGenres.length > 0 ? "Selected Genres: " + selectedGenres.join(", ") : ""}
          </div>
          <h5 className="text-center">Average Track Traits</h5>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Bar className="" style={{ width: "100%", height: "360px" }} data={data} options={options} />
          </div>
          <h5 className="text-center">Songs Added By Date</h5>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Line className="" style={{ width: "100%", height: "360px" }} data={date_data} options={date_options} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ServicesSkills;