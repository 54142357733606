import React from "react";
import {
  TwitterShareButton,
  LinkedinShareButton,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";
import "../assets/styles/Blog.css";

const ShareButtons = ({ url, title, description, image }) => {
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("Link copied to clipboard! " + url);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <>
      <div className="share-buttons-container">
        <div className="share-buttons">
          {/* Facebook Share Button */}

          {/* Twitter Share Button */}
          <TwitterShareButton url={url} title={title}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          {/* LinkedIn Share Button */}
          <LinkedinShareButton url={url} title={title} summary={description}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          {/* Copy Link Button */}
          <button
            onClick={handleCopyClick}
            className="copy-link-button"
            style={{
              borderRadius: "50%",
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
          >
            <svg
              className="link"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.61876 7.38125C5.87501 7.625 5.87501 8.025 5.61876 8.26875C5.37501 8.5125 4.97501 8.5125 4.73126 8.26875C3.51251 7.05 3.51251 5.06875 4.73126 3.85L6.94376 1.6375C8.16251 0.418747 10.1438 0.418747 11.3625 1.6375C12.5813 2.85625 12.5813 4.8375 11.3625 6.05625L10.4313 6.9875C10.4375 6.475 10.3563 5.9625 10.1813 5.475L10.475 5.175C11.2125 4.44375 11.2125 3.25625 10.475 2.525C9.74376 1.7875 8.55626 1.7875 7.82501 2.525L5.61876 4.73125C4.88126 5.4625 4.88126 6.65 5.61876 7.38125ZM7.38126 4.73125C7.62501 4.4875 8.02501 4.4875 8.26876 4.73125C9.48751 5.95 9.48751 7.93125 8.26876 9.15L6.05626 11.3625C4.83751 12.5812 2.85626 12.5812 1.63751 11.3625C0.418762 10.1437 0.418762 8.1625 1.63751 6.94375L2.56876 6.0125C2.56251 6.525 2.64376 7.0375 2.81876 7.53125L2.52501 7.825C1.78751 8.55625 1.78751 9.74375 2.52501 10.475C3.25626 11.2125 4.44376 11.2125 5.17501 10.475L7.38126 8.26875C8.11876 7.5375 8.11876 6.35 7.38126 5.61875C7.12501 5.375 7.12501 4.975 7.38126 4.73125Z"
                fill="#000"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default ShareButtons;
