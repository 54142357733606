import React, { useState, useEffect } from "react";

const ConsentBanner = () => {
  const [consentGiven, setConsentGiven] = useState(null);

  // Check localStorage for the consent status on page load
  useEffect(() => {
    const savedConsent = localStorage.getItem("userConsent");
    if (savedConsent) {
      setConsentGiven(savedConsent === "true");
    }
  }, []);

  // Handle consent acceptance
  const handleAccept = () => {
    localStorage.setItem("userConsent", "true");
    setConsentGiven(true);
    // You can also initialize tracking scripts here
    console.log("User consented to tracking");
  };

  // Handle consent rejection
  const handleReject = () => {
    localStorage.setItem("userConsent", "false");
    setConsentGiven(false);
    console.log("User rejected tracking");
  };

  // Display the banner only if consent has not been given
  if (consentGiven === null) {
    return (
      <div className="consent-banner">
         <p>We use cookies and similar technologies to enhance your experience.</p>
          <div className="d-flex flex-row justify-content-start end-items-center">
            <button onClick={handleAccept}>Accept</button>
            <button onClick={handleReject}>Reject</button>
          </div>
      </div>
    );
  }

  return null; // Don't show the banner if consent has already been given/rejected
};

// Basic inline styling for the banner (you can replace this with CSS classes)

export default ConsentBanner;
