import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home";
import Articles from "./pages/Articles";
import Services from "./pages/Services";
import Threads from "./pages/Threads";

function App() {
  const homeContent = [
  
    {
      Id: 1,
      Img: "RR",
      Title: "Articles",
      Byline: "",
      ToLink: "Articles",
    },
  ];

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home content={homeContent} />} />
          <Route path="/Articles" element={<Articles />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Threads" element={<Threads />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
//npm install react-chartjs-2 chart.js
//npm install react
